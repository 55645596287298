import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "../settings/AdminDashboard/components/Table";
import { ReactComponent as RequestedIcon } from "../../app/assets/PullRequestIcon.svg";
import { ReactComponent as AddIcon } from "../../app/assets/AddIcon.svg";
import RequestPanelPopUp from "./components/RequestPanelPopUp";

function RequestPanel() {
  const data = useSelector((state) => state.userProfile.requestPanelData);
  const module = useSelector((state) => state.userProfile.module);
  const sub_module = useSelector((state) => state.userProfile.sub_module);
  const functionality = useSelector((state) => state.userProfile.functionality);
  const [dataRows, setDataRows] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const headingsToDataKeyMap = {
    FUNCTIONALITY: "functionality",
    MODULE: "module",
    SUBMODULE: "sub_module",
    STATUS: "status",
    COMMENTS: "comments",
  };

  const generateCellHtml = (row, k) => {
    let cellHtml = null;
    switch (k) {
      case "delete":
        cellHtml = (
          <span className="w-full flex justify-center">
            {/* <DeleteIcon
                className="cursor-pointer"
                // onClick={() => handleEdit(row)}
              /> */}
          </span>
        );
        break;
      default:
        cellHtml = <span className="w-full flex justify-center">{row[k]}</span>;
        break;
    }
    return cellHtml;
  };
const handleSubmit=(request)=>{
    console.log(request)
}
  useEffect(() => {
    let transformedDataRows = data.map((row) => {
      let transformedDataRow = [];
      Object.values(headingsToDataKeyMap).forEach((k) => {
        if (k.toString().trim() !== "") {
          transformedDataRow.push(generateCellHtml(row, k));
        }
      });
      return transformedDataRow;
    });

    setDataRows(transformedDataRows);
  }, [data]);

  return (
    <div className="p-10 w-full">
      <div className="py-4 border border-b-0 rounded-t-lg flex justify-start">
        <div className="flex space-x-4 px-4">
          <span>Requested Panel</span> <RequestedIcon />
        </div>
      </div>
      <Table dataRows={dataRows} headings={Object.keys(headingsToDataKeyMap)} />
      <button
        className="p-2 px-3 bg-[#CEEFE2] text-[#898A8A] rounded-lg flex items-center justify-center text-center space-x-2"
        onClick={() => setShowPopup(true)}
      >
        <span>New Request</span> <AddIcon className="mt-1" />
      </button>
      {showPopup && (
        <RequestPanelPopUp
          functionality={functionality}
          module={module}
          submodule={sub_module}
          onSubmit={handleSubmit}
          setShowPopup={setShowPopup}
        />
      )}
      {/* Popup */}
      
    </div>
  );
}

export default RequestPanel;
