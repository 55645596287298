import React, { useEffect, useState } from "react";
import ConfirmPopup from "./ConfirmPopUp";
import MultiSelectComponent from "./MultiselectComponent";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
const TabContent = ({
  activeTab,
  roleTypeId,
  selectedEnergy,
  setSelectedEnergy,
  selectedScope3,
  setSelectedScope3,
  initialAdditionalData,
  initialIsEnabled,
  onSave,
  handleSubmit,
  isEnabled,
  setIsEnabled,
  isReadOnly,
  setIsReadOnly,
  initialIsReadonly,
  actionType,
}) => {
  const Modules = localStorage.getItem("accessToken")
    ? jwtDecode(localStorage.getItem("accessToken"))?.member?.access?.modules
    : {};

  const shouldEnableReporting = Modules.some(
    (m) => m === -1 || m === 4 || m === 5
  );
  const { module, subModule, isCreatingNewRole } = useSelector(
    (state) => state.setting
  );

  const [scope3Options, setScope3Options] = useState(subModule || []);
  const [setAdditionalData] = useState(
    initialAdditionalData || {}
  );
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isUpdated, setIsUpdated] = useState(true);
  const normalizedRoleTypeId = String(roleTypeId);

  const shouldShowPopup = () => {
    if (!shouldEnableReporting) {
      return (
        (activeTab === "roles" && normalizedRoleTypeId === "2") ||
        (activeTab === "dataIn" && normalizedRoleTypeId === "3") ||
        (activeTab === "visualization" &&
          (normalizedRoleTypeId === "6" ||
            normalizedRoleTypeId === "5" ||
            normalizedRoleTypeId === "11"))
      );
    } else {
      return (
        (activeTab === "roles" && normalizedRoleTypeId === "2") ||
        (activeTab === "dataIn" && normalizedRoleTypeId === "3") ||
        (activeTab === "visualization" && normalizedRoleTypeId === "6") ||
        (activeTab === "reporting" &&
          (normalizedRoleTypeId === "5" || normalizedRoleTypeId === "11"))
      );
    }
  };
  
  const handleEnergyChange = (selected) => {
    setSelectedEnergy(selected);
    setIsUpdated((prev) => !prev);
    setSelectedScope3((prevSelectedScope3) =>
      subModule.filter((scope3Item) =>
        selected.some(
          (option) => Number(option.value) === Number(scope3Item.module)
        )
      )
    );
  };

  const handleScope3Change = (selected) => {
    setSelectedScope3(selected);
    setIsUpdated((prev) => !prev);
  };

  // const handleAdditionalDataChange = (event) => {
  //   const { name, checked } = event.target;
  //   setAdditionalData((prevState) => ({
  //     ...prevState,
  //     [name]: checked,
  //   }));
  // };

  const handleIsEnabledChange = (event) => {
    setIsEnabled(event.target.checked);
  };
  const handleIsReadonlyChange = (event) => {
    setIsReadOnly(event.target.checked);
  };

  const handleSave = () => {
    if (shouldShowPopup()) {
      setIsPopupOpen(true);
    } else {
      handleSubmit();
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleConfirmPopup = () => {
    const selectedEnergyValues = selectedEnergy.map((i) => i.value);

    const selectedScope3Values = selectedScope3.map((i) => i.value);
    
    const permissionsData = {
      modules: selectedEnergyValues,
      submodules: selectedScope3Values,
      is_enabled: isEnabled,
      read_only: isReadOnly,
    };

    if (
      selectedEnergyValues.length === 0 ||
      selectedScope3Values.length === 0
    ) {
      toast.error("Please select all required fields", { theme: "dark" });
    } else {
      onSave(permissionsData);
    }
    setIsPopupOpen(false);
  };

  useEffect(() => {
    // Filter the subModules based on selected modules
    const filteredScope3Options = subModule.filter((subModuleItem) =>
      selectedEnergy.some((energy) => energy.value === subModuleItem.module)
    );

    setScope3Options(filteredScope3Options);

    // Filter the previously selected scope3 items based on the new filtered options
    setSelectedScope3((prevSelectedScope3) =>
      prevSelectedScope3.filter((scope3Item) =>
        filteredScope3Options.some(
          (option) => Number(option.value) === Number(scope3Item.value)
        )
      )
    );
  }, [selectedEnergy, isUpdated]); // Added subModule to dependencies

  useEffect(() => {
    setIsEnabled(initialIsEnabled);
    setIsReadOnly(initialIsReadonly);
  }, []);
  
  return (
    <div className="flex flex-col justify-between w-full h-full">
      {activeTab !== "roles" &&normalizedRoleTypeId === "11"&&
        ((actionType === "newEntry"&&!isCreatingNewRole) ||
          (isCreatingNewRole)) && (
          <div className="mb-2 flex gap-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="is_enabled"
                name="is_enabled"
                checked={isEnabled}
                onChange={handleIsEnabledChange}
                className="mr-2"
              />
              <label htmlFor="read_only">Enable</label>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="read_only"
                name="read_only"
                checked={isReadOnly}
                onChange={handleIsReadonlyChange}
                className="mr-2"
              />
              <label htmlFor="read_only">View only</label>
            </div>
          </div>
        )}
      <div className="mb-2">
        <label className="block mb-2 font-semibold">Module</label>
        <MultiSelectComponent
          options={module}
          selected={selectedEnergy.filter((scope3Item) =>
            module.some(
              (option) => Number(option.value) === Number(scope3Item.value)
            )
          )}
          onChange={handleEnergyChange}
          required
        />
      </div>
      <div className="mb-2">
        <label className="block mb-2 font-semibold">Sub Module</label>
        <MultiSelectComponent
          options={scope3Options}
          selected={selectedScope3}
          onChange={handleScope3Change}
          required
        />
      </div>

      <div className="mb-0 w-full flex justify-center flex-1 items-center mt-5 absolute bottom-5">
        <button
          onClick={handleSave}
          className="w-48 bg-green-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-opacity-50"
        >
          {shouldShowPopup() ? "Save changes" : "Save changes"}
        </button>
      </div>

      {isPopupOpen && (
        <ConfirmPopup
          mainHeading="Are you sure?"
          subHeading="Do you want to save the changes?"
          buttonText1="Cancel"
          buttonText2="Save changes"
          onClose={handleClosePopup}
          onConfirm={handleConfirmPopup}
        />
      )}
    </div>
  );
};

export default TabContent;
