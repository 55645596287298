import React, { useEffect } from 'react';

function Helpcenter() {
  useEffect(() => {
    // Create a container div for the scripts
    const scriptContainer = document.getElementById('script-container');

    // Adding the first script
    const script1 = document.createElement('script');
    script1.innerHTML = `
      window.fwSettings = {
        'widget_id': 1070000001240
      };
      !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}();
    `;
    scriptContainer.appendChild(script1);

    // Adding the second script
    const script2 = document.createElement('script');
    script2.src = 'https://ind-widget.freshworks.com/widgets/1070000001240.js';
    script2.async = true;
    script2.defer = true;
    scriptContainer.appendChild(script2);

    // Cleanup function to remove the scripts when the component unmounts
    return () => {
      scriptContainer.removeChild(script1);
      scriptContainer.removeChild(script2);
    };
  }, []);

  return (
    <div>
      <div id="script-container"></div>
    </div>
  );
}

export default Helpcenter;
