import React from 'react';

const EqualOpportunity = ({question}) => {
    const data = [
        {
          gender: 'Male',
          permanentEmployee: {
            returnToWorkRate: 'x',
            retentionRate: 'x',
          },
          permanentWorker: {
            returnToWorkRate: 'x',
            retentionRate: 'x',
          },
          materiality: 'High, Sector Dependent',
        },
        {
          gender: 'Female',
          permanentEmployee: {
            returnToWorkRate: 'x',
            retentionRate: 'x',
          },
          permanentWorker: {
            returnToWorkRate: 'x',
            retentionRate: 'x',
          },
          materiality: 'High, Sector Dependent',
        },
        {
          gender: 'Total',
          permanentEmployee: {
            returnToWorkRate: 'x',
            retentionRate: 'x',
          },
          permanentWorker: {
            returnToWorkRate: 'x',
            retentionRate: 'x',
          },
          materiality: 'High, Sector Dependent',
        },
      ];
  return (
    <table className="min-w-full border border-gray-300">
      <thead className="bg-gray-100 text-gray-700">
        <tr className="bg-gray-300">
          <th colSpan={6} className="border border-gray-300 px-4 py-2 text-start font-semibold text-base">
          {question}          </th>
        </tr>
        <tr className="text-sm text-gray-700">
          <th rowSpan={2} className="border border-gray-300 px-4 py-2 text-center">
            Gender
          </th>
          <th colSpan={2} className="border border-gray-300 px-4 py-2 text-center">
            Permanent Employees
          </th>
          <th colSpan={2} className="border border-gray-300 px-4 py-2 text-center">
            Permanent Workers
          </th>
          {/* <th rowSpan={2} className="border border-gray-300 px-4 py-2 text-center">
            MATERIALITY
          </th> */}
        </tr>
        <tr className="text-sm text-gray-700">
          <th className="border border-gray-300 px-4 py-2">Return to Work Rate</th>
          <th className="border border-gray-300 px-4 py-2">Retention Rate</th>
          <th className="border border-gray-300 px-4 py-2">Return to Work Rate</th>
          <th className="border border-gray-300 px-4 py-2">Retention Rate</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index} className="text-center text-sm text-gray-700">
            <td className="border border-gray-300 px-4 py-2">{item.gender}</td>
            <td className="border border-gray-300 px-4 py-2">{item.permanentEmployee.returnToWorkRate}</td>
            <td className="border border-gray-300 px-4 py-2">{item.permanentEmployee.retentionRate}</td>
            <td className="border border-gray-300 px-4 py-2">{item.permanentWorker.returnToWorkRate}</td>
            <td className="border border-gray-300 px-4 py-2">{item.permanentWorker.retentionRate}</td>
            {/* <td className="border border-gray-300 px-4 py-2">{item.materiality}</td> */}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default EqualOpportunity;
