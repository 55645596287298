import React from 'react';

const DataDisplay = ({ config, data }) => {
  const year = data?.financialYear; // Getting the financial year from the data
  const sections = config?.sections || [];
  const section = sections[0] || {};
  const fields = section?.fields || [];
  const rows = section?.rows || [];
  const site=data?.site
  function handleDropdownChange(){

  }
  
  return (
    <div className="p-4">
  

      <h3 className=" font-semibold mb-2">{year}</h3>
  {site&& <div className="mb-4 w-1/4 float-right">
          <select
            value={site}
            onChange={(e) => handleDropdownChange('site', e.target.value)}
            className="border rounded p-2 w-full"
          >
            <option value="">Select Site</option>
            {config.siteOptions.map((site) => (
              <option key={site} value={site}>
                {site}
              </option>
            ))}
          </select>
        </div>}
      <table className="min-w-full border-collapse border border-gray-200">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-300 p-2">{section?.label}</th>
            {fields.map((field) => (
              <th key={field.key} className="border border-gray-300 p-2">
                {field.label || ''}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => {
            const rowData = data?.[row.key] || {};
            return (
              <tr key={row.key} className="hover:bg-gray-100 w-full">
                <td className="border border-gray-300 p-2 max-w-48">{row.label || ''}</td>
                {fields.map((field) => (
                  <td key={field.key} className="border border-gray-300 p-2">
                    {rowData[field.key] || 0} 
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DataDisplay;
