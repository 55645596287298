import React from "react";

function Scope3Card({ title, value, percentage = "NA" ,year=2023}) {
  const isIncrease = percentage.endsWith("↑");
  const percentageColorClass = !isIncrease ? "text-green-600" : "text-red-600";
  const backgroundClass = percentage !== "NA" ? "bg-white bg-opacity-80" : "";

  return (
    <div className="h-[135px] flex-shrink-0 shadow-md p-4 border border-gray-300 rounded-lg bg-green-600 text-white flex flex-col justify-between">
       <div className="flex gap-2 justify-between">
        <div className="flex gap-2 item-start">
          <h3 className="font-semibold "> {title}</h3>
          {/* <IIcon className="w-4 h-4 flex flex-shrink-0  mt-1" /> */}
        </div>
        {year&&<div className="bg-[#a8d7c6] text-[#1BA370] w-fit px-3 flex-shrink-0 rounded-full text-sm text-center flex justify-center items-center shadow-lg font-medium">{year||""}</div>}
      </div>
      <div className="flex justify-between items-center mt-2 gap-3">
        <p className="text-2xl font-bold">{value}</p>
        <p
          className={`text-sm ${percentageColorClass} ${backgroundClass} rounded-full shadow-2xl px-2 py-1`}
        >
          {percentage}
        </p>
      </div>
    </div>
  );
}

export default Scope3Card;
