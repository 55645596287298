import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  wasteMaterialOptions: [],
  wasteTreatmentOptions: [],
  financialYear: [],
  dataForCurrentYearChange: [],
  siteOptions: [],
  filter: {},
};

const wasteSlice = createSlice({
  name: "waste",
  initialState,
  reducers: {
    setFinancialYear(state, action) {
      state.financialYear = action.payload;
    },

    setCat5Data(state, action) {
      state.data = action.payload;
    },
    setSiteOptions(state, action) {
      state.siteOptions = action.payload;
    },
    setwasteMaterialOptions(state, action) {
      state.wasteMaterialOptions = action.payload;
    },
    setwasteTreatmentOptions(state, action) {
      state.wasteTreatmentOptions = action.payload;
    },
    setdataForCurrentYearChange(state, action) {
      state.dataForCurrentYearChange = action.payload;
    },

    setfuelFilter(state, action) {
      state.filter = { ...state.filter, ...action.payload };
    },
  },
});

export const {
  setFinancialYear,
  setCat5Data,
  setSiteOptions,
  setdataForCurrentYearChange,
  setfuelFilter,
  setwasteMaterialOptions,
  setwasteTreatmentOptions,
} = wasteSlice.actions;

export default wasteSlice.reducer;
