import { ReactComponent as CloseIcon } from "../../../app/assets/CloseIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { setformData } from "../Redux/GeneralSlices";
import useApi from "../../../customHooks/useApi";

const RevenueInsertDataFormEditCopy = ({
  selectedRowData,
  handleCloseForm,
  actionType,
}) => {
  const { formData } = useSelector((state) => state.general);
  const loading = useSelector((state) => state.common.isLoading);
  const dispatch = useDispatch();
  const api = useApi();
  const handleFormChange = (e) => {
    const { name, value } = e.target;

    dispatch(setformData({ ...formData, [name]: value }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = document.querySelector("#insert-data-form");
    if (form.length && form.checkValidity()) {
      if (actionType === "edit") {
        // dispatch(updateSitesMasterData({id:selectedRowData.id,data:{...formData,site_id:selectedRowData.site_id},api}))
        handleCloseForm();
      } else {
        // dispatch(postSitesData({formData,api}))
        handleCloseForm();
      }
    } else if (form.length) {
      form.querySelector('input[type="submit"]').click();
    }
  };
  useEffect(() => {
    if (selectedRowData) {
      const updatedData = {
        year: selectedRowData.year,
        quarter: selectedRowData.quarter,
        revenue: selectedRowData.revenue,
      };
      dispatch(setformData({ ...formData, ...updatedData }));
    }
  }, [selectedRowData, actionType, dispatch]);
  const generateFinancialYears = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 1950;
    const years = [];

    for (let year = startYear; year <= currentYear; year++) {
      const nextYear = (year + 1).toString().slice(2);
      // years.push(`FY ${year}-${nextYear}`);
      years.push(`${year}`);
    }

    return years.reverse();
  };
  return (
    <>
      <div className="mainContainer flex w-full h-screen overflow-hidden justify-center items-center z-[100] fixed bg-black bg-opacity-50 top-0 left-0 bottom-0">
        <div className="flex flex-col justify-center items-center w-9/12 text-xs bg-white absolute z-[1000] border border-transparent rounded-t-md">
          <div className="flex justify-between w-full bg-gray-50 py-2 px-4 border-b border-gray-300 mb-3">
            <span className="cursor-pointer">Revenue Input</span>
            <span className="cursor-pointer" onClick={handleCloseForm}>
              <CloseIcon />
            </span>
          </div>
          <form
            style={{
              opacity: loading ? 0.5 : 1,
              pointerEvents: loading ? "none" : "auto",
            }}
            id="insert-data-form"
            className="flex flex-col w-full py-4"
            onSubmit={handleFormSubmit}
          >
            <div className="grid grid-cols-4 gap-4 px-8 mb-4 justify-around">
              <div className="col-span-1 flex flex-col">
                <label htmlFor="quarter" className="text-xs py-1.5">
                  Quarter<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="quarter"
                  id="quarter"
                  value={formData?.quarter || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="year" className="text-xs py-1.5">
                  Item Name<span className="text-red-500">*</span>
                </label>
                <select
                  name="year"
                  value={formData?.year || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="" disabled>
                    Choose the Year
                  </option>
                  {generateFinancialYears().map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="revenue" className="text-xs py-1.5">
                  Revenue<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="revenue"
                  id="revenue"
                  value={formData?.revenue || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
            </div>
            <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
              <div className="w-1/6 flex flex-col">
                <button
                  type="button"
                  disabled={loading}
                  className="py-2 px-6 text-green-600 text-sm rounded-md"
                  onClick={handleCloseForm}
                >
                  Cancel
                </button>
              </div>
              <div className="w-1/6 flex flex-col">
                <button
                  type="submit"
                  disabled={loading}
                  className={`py-2 px-6 text-white text-sm ${
                    loading ? "bg-green-500 cursor-wait" : "bg-green-600"
                  } rounded-md`}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default RevenueInsertDataFormEditCopy;
