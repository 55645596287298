const recoveredKeys = (treatment_mechanism) =>
  treatment_mechanism?.toLowerCase().includes("recycling");
export function getCurrentFinancialYear() {
  var today = new Date();
  var currentMonth = today.getMonth() + 1;

  if (currentMonth <= 3) {
    return today.getFullYear() - 1;
  } else {
    return today.getFullYear();
  }
}
export const calculateWasteTreatedPercentage = (
  data,
  wastetype = "treated"
) => {
  const treatedPercentageBySite = {};
  const dataBySite = [...data]
    .filter((i) =>
      wastetype === "treated"
        ? !recoveredKeys(i.treatment_mechanism)
        : recoveredKeys(i.treatment_mechanism) || !i.treatment_mechanism
    )
    .reduce((acc, entry) => {
      const { site, produced_quantity, treated_quantity } = entry;

      if (!acc[site]) {
        acc[site] = {
          produced_quantity: 0,
          treated_quantity: 0,
          recovered_quantity: 0,
        };
      }

      acc[site].produced_quantity += produced_quantity;
      acc[site].treated_quantity += treated_quantity;
      if (wastetype !== "treated") {
        acc[site].recovered_quantity += treated_quantity;
      } else {
        acc[site].recovered_quantity += 0;
      }
      return acc;
    }, {});

  Object.entries(dataBySite).forEach(
    ([site, { produced_quantity, treated_quantity, recovered_quantity }]) => {
      if (produced_quantity > 0) {
        const treatedPercentage = (treated_quantity / produced_quantity) * 100;
        treatedPercentageBySite[site] = {
          treated_percentage: treatedPercentage?.toFixed() || 0,
          produced_quantity: produced_quantity?.toFixed() || 0,
          treated_quantity: treated_quantity?.toFixed() || 0,
          recovered_quantity: recovered_quantity?.toFixed() || 0,
        };
      } else {
        treatedPercentageBySite[site] = {
          treated_percentage: 0,
          produced_quantity: produced_quantity?.toFixed() || 0,
          treated_quantity: treated_quantity?.toFixed() || 0,
          recovered_quantity: recovered_quantity?.toFixed() || 0,
        };
      }
    }
  );

  return treatedPercentageBySite;
};

export const processStackedChartData = (data, year, typebreakdown, key) => {
  let processedData = {};

  let filteredData = data.filter((entry) => entry.year === Number(year));
  if (key === "recovered_quantity") {
    filteredData = filteredData
      ?.filter((i) =>
        i?.treatment_mechanism?.toLowerCase().includes("recycling")
      )
      ?.map((i) => {
        return {
          ...i,
          recovered_quantity: i.treated_quantity,
        };
      });
  }
  if (key === "treated_quantity") {
    filteredData = filteredData
      ?.filter(
        (i) => !i?.treatment_mechanism?.toLowerCase().includes("recycling")
      )
      ?.map((i) => {
        return {
          ...i,
          recovered_quantity: i.treated_quantity,
        };
      });
  }

  filteredData.forEach((entry) => {
    let { site } = entry;
    if (!entry[typebreakdown] && typebreakdown !== "") {
      return;
    }
    const breakdown = entry[typebreakdown] || "Total";

    if (!processedData[site]) {
      processedData[site] = {
        site: site,
        breakdown: {},
      };
    }

    if (!processedData[site].breakdown[breakdown]) {
      processedData[site].breakdown[breakdown] = {
        value: 0,
        percentage: 0,
      };
    }

    processedData[site].breakdown[breakdown].value += Number(entry[key]);
  });

  Object.values(processedData).forEach((entry) => {
    let total = 0;

    Object.values(entry.breakdown).forEach((breakdown) => {
      total += breakdown.value;
    });

    Object.keys(entry.breakdown).forEach((breakdown) => {
      const breakdownValue = entry.breakdown[breakdown].value;
      entry.breakdown[breakdown].percentage =
        total !== 0 ? (breakdownValue / total) * 100 : 0;
    });
  });

  return processedData;
};
export const generateSeriesDataForStackedChart = (
  processedData,
  wasteTreatedPercentageData,
  wasteRecoveredPercentageData,
  key
) => {
  const xAxisData = Object.keys(processedData);
  const legends = new Set();

  Object.values(processedData).forEach((entry) => {
    Object.keys(entry?.breakdown).forEach((breakdown) => {
      legends.add(breakdown);
    });
  });

  const series = [...legends].map((legend) => ({
    name: legend,
    type: "bar",
    stack: "total",
    label: {
      fontSize: 10,
        fontWeight: "bold",
      show: true,
      formatter: (params) => {
        const site = params.name;
        const entry = processedData[site]?.breakdown[params.seriesName];
       
        const percentage = entry?.percentage.toFixed();
        const value = entry?.value.toFixed();
        if (
          percentage !== undefined &&
          !isNaN(percentage) &&
          percentage !== 0
        ) {
          // return `${percentage.toFixed()}%`;
          return legend.trim() === "Total"
            ? `${value > 10 ? value : ""}`
            : `${percentage > 5 ? percentage + "%" : ""}`;
        } else {
          return "";
        }
      },
    },
    data: xAxisData.map(
      (site) => processedData[site]?.breakdown[legend]?.value || 0
    ),
  }));

  if (wasteTreatedPercentageData && key === "produced_quantity") {
    series.push({
      name: "Waste Disposed %",
      type: "line",
      label: { show: true, formatter: `{c}%` },
      data: xAxisData.map((site) => ({
        value: wasteTreatedPercentageData[site]?.treated_percentage || 0,
        treated_quantity:
          wasteTreatedPercentageData[site]?.treated_quantity || 0,
        produced_quantity:
          wasteTreatedPercentageData[site]?.produced_quantity || 0,
        recovered_quantity:
          wasteRecoveredPercentageData[site]?.recovered_quantity || 0,
      })),
    });
  }
  if (wasteRecoveredPercentageData && key === "produced_quantity") {
    series.push({
      name: "Waste Recovered %",
      type: "line",
      label: { show: true, formatter: `{c}%` },

      data: xAxisData.map((site) => ({
        value: wasteRecoveredPercentageData[site]?.treated_percentage || 0,
        treated_quantity:
          wasteTreatedPercentageData[site]?.treated_quantity || 0,
        produced_quantity:
          wasteTreatedPercentageData[site]?.produced_quantity || 0,
        recovered_quantity:
          wasteRecoveredPercentageData[site]?.recovered_quantity || 0,
      })),
    });
  }

  return { series, legends: Array.from(legends) };
};
export const formatText = (text) => {
  if (text === "module") {
    text = "Scope";
  } else if (text === "sub_module") {
    text = "Category";
  }
  const words = text.split("_");

  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  const formattedText = capitalizedWords.join(" ");

  return formattedText;
};

// current year and last year total emission
function currentYearLastYearEmissionDetail(
  data,
  currentYear,
  key = "produced_quantity"
) {
  if (!currentYear) {
    return {
      currentYear: 0,
      previousYear: 0,
      percentageChange: 0,
    };
  }
  const currentYearData = data?.filter((entry) => entry.year === currentYear);
  const previousYearData = data?.filter(
    (entry) => entry.year === currentYear - 1
  );
  const totalKeyQuantitysCurrentYear = currentYearData?.reduce(
    (total, entry) => total + Number(entry[key]),
    0
  );
  const totalKeyQuantitysPreviousYear = previousYearData?.reduce(
    (total, entry) => total + Number(entry[key]),
    0
  );

  const percentageChange =
    ((totalKeyQuantitysCurrentYear - totalKeyQuantitysPreviousYear) /
      totalKeyQuantitysPreviousYear) *
    100;

  const roundedPercentageChange = Math.floor(percentageChange);

  return {
    currentYear: totalKeyQuantitysCurrentYear.toFixed(),
    previousYear: Math.floor(totalKeyQuantitysPreviousYear),
    percentageChange: roundedPercentageChange,
  };
}

// key=produced_quantity,treated_quantity,recovered_quantity
// data, timeInterval, breakPoint,Filterkey
// recoverted quantity means treated_quantity for treatment_mechanism: "Re-use",
function processData(Inputdata, timeInterval, breakpoint, year, key) {
  let dataCopy = JSON.parse(JSON.stringify(Inputdata));
  let data;

  if (year) {
    data = dataCopy.filter((entry) => entry.year === year);
    data.sort((a, b) => a.quarter - b.quarter);
  } else {
    if (timeInterval == "Quarter") {
      function sortByYearAndQuarter(a, b) {
        if (a.year !== b.year) {
          return a.year - b.year;
        } else {
          return a.quarter - b.quarter;
        }
      }

      dataCopy.sort(sortByYearAndQuarter);
      data = dataCopy;
    } else if (timeInterval == "Month") {
      function sortByYearAndMonth(a, b) {
        if (a.year !== b.year) {
          return a.year - b.year;
        } else {
          const monthsOrder = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          return monthsOrder.indexOf(a.month) - monthsOrder.indexOf(b.month);
        }
      }

      // Adjust the year for entries with quarter 4
      dataCopy.forEach((item) => {
        if (item.quarter == 4) {
          item.year += 1;
        }
      });

      dataCopy.sort(sortByYearAndMonth);
      data = dataCopy;
    } else if (timeInterval == "Year") {
      function sortByYearAndQuarter(a, b) {
        if (a.year !== b.year) {
          return a.year - b.year;
        } else {
          return b.year - a.year;
        }
      }

      dataCopy.sort(sortByYearAndQuarter);
      data = dataCopy;
    }
  }
  let processedData = {};

  switch (key) {
    case "treated_quantity":
      data
        .filter(
          (i) => !i?.treatment_mechanism?.toLowerCase().includes("recycling")
        )
        .forEach((entry) => {
          let { year, quarter, produced_quantity, month, treated_quantity } =
            entry;
          produced_quantity = Number(produced_quantity);
          treated_quantity = Number(treated_quantity);
          if (!entry[breakpoint] && breakpoint !== "") {
            return;
          }
          let filter = entry[breakpoint] || "Total";

          switch (timeInterval) {
            case "Year":
              if (!processedData[year]) {
                processedData[year] = {};
              }
              if (!processedData[year][filter]) {
                processedData[year][filter] = {
                  produced_quantity: 0,
                  totalKeyQuantity: 0,
                  treated_quantity: 0,
                };
              }

              processedData[year][filter].produced_quantity += Number(
                entry["produced_quantity"]
              );
              processedData[year][filter].totalKeyQuantity += treated_quantity;
              processedData[year][filter].treated_quantity += treated_quantity;
              break;

            case "Quarter":
              const quarterKey = `${year}-Q${quarter}`;
              if (!processedData[quarterKey]) {
                processedData[quarterKey] = {};
              }
              if (!processedData[quarterKey][filter]) {
                processedData[quarterKey][filter] = {
                  produced_quantity: 0,
                  totalKeyQuantity: 0,
                  treated_quantity: 0,
                };
              }

              processedData[quarterKey][filter].produced_quantity += Number(
                entry["produced_quantity"]
              );
              processedData[quarterKey][filter].totalKeyQuantity +=
                treated_quantity;
              processedData[quarterKey][filter].treated_quantity +=
                treated_quantity;
              break;

            case "Month":
              const monthKey = `${year}-${month}`;
              if (!processedData[monthKey]) {
                processedData[monthKey] = {};
              }
              if (!processedData[monthKey][filter]) {
                processedData[monthKey][filter] = {
                  produced_quantity: 0,
                  totalKeyQuantity: 0,
                  treated_quantity: 0,
                };
              }

              processedData[monthKey][filter].produced_quantity += Number(
                entry["produced_quantity"]
              );
              processedData[monthKey][filter].totalKeyQuantity +=
                treated_quantity;
              processedData[monthKey][filter].treated_quantity +=
                treated_quantity;
              break;

            default:
              break;
          }
        });

      break;
    case "produced_quantity":
      data
        .map((i) => {
          return {
            ...i,
            treated_quantity: i.recovered_quantity ? 0 : i.treated_quantity,
          };
        })
        .forEach((entry) => {
          let {
            year,
            quarter,
            produced_quantity,
            month,
            treated_quantity,
            recovered_quantity,
          } = entry;

          produced_quantity = Number(produced_quantity);
          treated_quantity = Number(treated_quantity);
          recovered_quantity = Number(recovered_quantity);
          if (!entry[breakpoint] && breakpoint !== "") {
            return;
          }
          let filter = entry[breakpoint] || "Total";

          switch (timeInterval) {
            case "Year":
              if (!processedData[year]) {
                processedData[year] = {};
              }
              if (!processedData[year][filter]) {
                processedData[year][filter] = {
                  produced_quantity: 0,
                  totalKeyQuantity: 0,
                  treated_quantity: 0,
                  percentTreated: 0,
                  recovered_quantity: 0,
                  percentRecovered: 0,
                };
              }
              processedData[year][filter].recovered_quantity +=
                recovered_quantity;
              processedData[year][filter].treated_quantity += treated_quantity;
              processedData[year][filter].produced_quantity += Number(
                entry["produced_quantity"]
              );
              processedData[year][filter].totalKeyQuantity += produced_quantity;
              break;

            case "Quarter":
              const quarterKey = `${year}-Q${quarter}`;
              if (!processedData[quarterKey]) {
                processedData[quarterKey] = {};
              }
              if (!processedData[quarterKey][filter]) {
                processedData[quarterKey][filter] = {
                  produced_quantity: 0,
                  totalKeyQuantity: 0,
                  treated_quantity: 0,
                  percentTreated: 0,
                  percentRecovered: 0,
                  recovered_quantity: 0,
                };
              }
              processedData[quarterKey][filter].recovered_quantity +=
                recovered_quantity;
              processedData[quarterKey][filter].produced_quantity += Number(
                entry["produced_quantity"]
              );
              processedData[quarterKey][filter].totalKeyQuantity +=
                produced_quantity;
              processedData[quarterKey][filter].treated_quantity +=
                treated_quantity;
              break;

            case "Month":
              const monthKey = `${year}-${month}`;
              if (!processedData[monthKey]) {
                processedData[monthKey] = {};
              }
              if (!processedData[monthKey][filter]) {
                processedData[monthKey][filter] = {
                  produced_quantity: 0,
                  totalKeyQuantity: 0,
                  treated_quantity: 0,
                  percentTreated: 0,
                  recovered_quantity: 0,
                  percentRecovered: 0,
                };
              }

              processedData[monthKey][filter].recovered_quantity +=
                recovered_quantity;

              processedData[monthKey][filter].produced_quantity += Number(
                entry["produced_quantity"]
              );
              processedData[monthKey][filter].totalKeyQuantity +=
                produced_quantity;
              processedData[monthKey][filter].treated_quantity +=
                treated_quantity;
              break;

            default:
              break;
          }
        });

      for (const timeKey in processedData) {
        for (const key in processedData[timeKey]) {
          const dataItem = processedData[timeKey][key];
          if (dataItem.treated_quantity > 0) {
            dataItem.percentTreated = (
              (dataItem.treated_quantity / dataItem.produced_quantity) *
              100
            ).toFixed();
          } else {
            dataItem.percentTreated = 0;
          }
          if (dataItem.recovered_quantity > 0) {
            dataItem.percentRecovered = (
              (dataItem.recovered_quantity / dataItem.produced_quantity) *
              100
            ).toFixed();
          } else {
            dataItem.percentRecovered = 0;
          }
        }
      }

      break;
    case "recovered_quantity":
      data
        .filter((i) =>
          i?.treatment_mechanism?.toLowerCase().includes("recycling")
        )
        .forEach((entry) => {
          let { year, quarter, produced_quantity, month, treated_quantity } =
            entry;
          produced_quantity = Number(produced_quantity);
          treated_quantity = Number(treated_quantity);

          if (!entry[breakpoint] && breakpoint !== "") {
            return;
          }
          let filter = entry[breakpoint] || "Total";

          switch (timeInterval) {
            case "Year":
              if (!processedData[year]) {
                processedData[year] = {};
              }
              if (!processedData[year][filter]) {
                processedData[year][filter] = {
                  produced_quantity: 0,
                  totalKeyQuantity: 0,
                  treated_quantity: 0,
                  percentTreated: 0,
                };
              }

              processedData[year][filter].produced_quantity += Number(
                entry["produced_quantity"]
              );
              processedData[year][filter].totalKeyQuantity += treated_quantity;
              processedData[year][filter].treated_quantity += treated_quantity;
              break;

            case "Quarter":
              const quarterKey = `${year}-Q${quarter}`;
              if (!processedData[quarterKey]) {
                processedData[quarterKey] = {};
              }
              if (!processedData[quarterKey][filter]) {
                processedData[quarterKey][filter] = {
                  produced_quantity: 0,
                  totalKeyQuantity: 0,
                  treated_quantity: 0,
                  percentTreated: 0,
                };
              }

              processedData[quarterKey][filter].produced_quantity += Number(
                entry["produced_quantity"]
              );
              processedData[quarterKey][filter].totalKeyQuantity +=
                treated_quantity;
              processedData[quarterKey][filter].treated_quantity +=
                treated_quantity;
              break;

            case "Month":
              const monthKey = `${year}-${month}`;
              if (!processedData[monthKey]) {
                processedData[monthKey] = {};
              }
              if (!processedData[monthKey][filter]) {
                processedData[monthKey][filter] = {
                  produced_quantity: 0,
                  totalKeyQuantity: 0,
                  treated_quantity: 0,
                  percentTreated: 0,
                };
              }

              processedData[monthKey][filter].produced_quantity += Number(
                entry["produced_quantity"]
              );
              processedData[monthKey][filter].totalKeyQuantity +=
                treated_quantity;
              processedData[monthKey][filter].treated_quantity +=
                treated_quantity;
              break;

            default:
              break;
          }
        });
      break;

    default:
      break;
  }

  return processedData;
}
function sumQuantities(data) {
  const result = {};

  // Loop through each top-level key (e.g., "2023-Jul", "2023-Aug")
  for (const period in data) {
    let totalProduced = 0;
    let totalTreated = 0;
    let totalRecovered = 0;

    // Loop through each second-level key (e.g., "Site 1", "Site 3")
    for (const key in data[period]) {
      const siteData = data[period][key];

      // Sum the quantities
      totalProduced += siteData.produced_quantity || 0;
      totalTreated += siteData.treated_quantity || 0;
      totalRecovered += siteData.recovered_quantity || 0;
    }

    // Store the summed data for the period
    result[period] = {
      produced_quantity: totalProduced,
      treated_quantity: totalTreated,
      recovered_quantity: totalRecovered,
    // Calculate percentages
  };
  const totalProduce = result[period].produced_quantity;
  const percentTreated = totalProduce > 0 ? (result[period].treated_quantity / totalProduce) * 100 : 0;
  const percentRecovered = totalProduce > 0 ? (result[period].recovered_quantity / totalProduce) * 100 : 0;
  
  // Store the percentages in the result
  result[period].percentTreated = percentTreated.toFixed();
  result[period].percentRecovered = percentRecovered.toFixed();
}

  return result;
}
function processDataToLablesAndDataSets(data, key) {
  let series = [];
  let legends = [];
  let xAxisLabels = Object.keys(data);
const sum=sumQuantities(data)

  // Extract unique legends (site names)
  for (const timeKey in data) {
    for (const siteKey in data[timeKey]) {
      if (!legends.includes(siteKey)) {
        legends.push(siteKey);
      }
    }
  }

  // Initialize series data arrays
  let treatedPercentageSeriesData = [];
  let recoveredPercentageSeriesData = [];
  let treatedSeriesData = [];
  let ProducedSeriesData = [];
  let RecoveredSeriesData = [];

  for (const legend of legends) {
    let seriesData = [];
    let produced_quantity = [];
    let recovered_quantity = [];
    let treated_quantity_data = [];
    let treatedPercentageData = [];
    let recoveredPercentageData = [];
    for (const timeKey of xAxisLabels) {
      if (sum[timeKey]) {
        seriesData.push(data[timeKey][legend]?.totalKeyQuantity?.toFixed()||0);
        produced_quantity.push(sum[timeKey]?.produced_quantity || 0);
        treated_quantity_data.push(sum[timeKey]?.treated_quantity || 0);
        recovered_quantity.push(sum[timeKey]?.recovered_quantity || 0);

        treatedPercentageData.push(
          isFinite(sum[timeKey]?.percentTreated) && !isNaN(sum[timeKey]?.percentTreated)
            ? sum[timeKey]?.percentTreated
            : 0
        );
        recoveredPercentageData.push(
          isFinite(sum[timeKey]?.percentRecovered) && !isNaN(sum[timeKey]?.percentRecovered)
            ? sum[timeKey]?.percentRecovered
            : 0
        );
      } else {
        // Handle missing data for the specific site/legend in that time period
        seriesData.push(0);
        treated_quantity_data.push(0);
        produced_quantity.push(0);
        recovered_quantity.push(0);
        treatedPercentageData.push(0);
        recoveredPercentageData.push(0);
      }
    }

    // Accumulate data for each legend
    treatedPercentageSeriesData.push(...treatedPercentageData);
    recoveredPercentageSeriesData.push(...recoveredPercentageData);
    ProducedSeriesData.push(...produced_quantity);
    RecoveredSeriesData.push(...recovered_quantity);
    treatedSeriesData.push(...treated_quantity_data);

    // Prepare bar series
    let totalKeyQuantitysByTimeKey = xAxisLabels.reduce((acc, timeKey) => {
      acc[timeKey] = Object.values(data[timeKey]).reduce((total, siteData) => {
        return total + (siteData.totalKeyQuantity || 0);
      }, 0);
      return acc;
    }, {});

    series.push({
      produced_quantity,
      name: legend,
      type: "bar",
      stack: "total",
      barWidth: "20%",
      label: {
        show: true,
        position: legend.trim() === "Total" ? "inside" : "", // Different label positions for "Total"
        formatter: (params) => {
          const timeKeyIndex = params.dataIndex; // Get timeKey index
          const timeKey = xAxisLabels[timeKeyIndex]; // Get the current timeKey
          const totalKeyQuantityForTimeKey = totalKeyQuantitysByTimeKey[timeKey]; // Total quantity for current timeKey
          const value = Number(params.value); // Current bar value

          // Calculate the percentage of the current block relative to the total for the timeKey
          let percentage = ((value / totalKeyQuantityForTimeKey) * 100).toFixed();

          // Handle edge cases
          if (isNaN(percentage) || !isFinite(percentage)) {
            percentage = 0;
          }

          return legend.trim() === "Total"
            ? `${value > 10 ? value : ""}`
            : `${percentage > 5 ? percentage + "%" : ""}`;
        },
        fontSize: 10,
        fontWeight: "bold",
      },
      data: seriesData.map((value, index) => ({
        value,
        treated_quantity: treated_quantity_data[index],
        percentTreated: treatedPercentageData[index],
      })),
    });
  }

  // Add line series for treated and recovered percentages
  if (key == "produced_quantity") {
    series.push({
      name: "Waste Disposed %",
      type: "line",
      yAxisIndex: 1, // Secondary y-axis for percentage
      label: { show: true, formatter: `{c}%` },
      data: xAxisLabels.map((_, index) => ({
        treatedQuantity: treatedSeriesData[index],
        producedQuantity: ProducedSeriesData[index],
        value: treatedPercentageSeriesData[index],
      })),
    });

    series.push({
      name: "Waste Recovered %",
      type: "line",
      yAxisIndex: 1, // Secondary y-axis for percentage
      label: { show: true, formatter: `{c}%` },
      data: xAxisLabels.map((_, index) => ({
        treatedQuantity: treatedSeriesData[index],
        producedQuantity: ProducedSeriesData[index],
        recoveredQuantity: RecoveredSeriesData[index],
        value: recoveredPercentageSeriesData[index],
      })),
    });
  }
  return { xAxisLabels, series };
}

function processDataToLablesAndDataSetsPie(data) {
 
  let series = [];
  let legends = [];
  let xAxisLabels = Object.keys(data);

  for (const timeKey in data) {
    for (const key in data[timeKey]) {
      if (!legends.includes(key)) {
        legends.push(key);
      }
    }
  }
  let treatedPercentageSeriesData = [];
  let treatedSeriesData = [];
  let ProducedSeriesData = [];
  for (const legend of legends) {
    let seriesData = [];
    let produced_quantity = [];
    let treated_quantity_data = [];
    let treatedPercentageData = [];
    for (const timeKey of xAxisLabels) {
      if (data[timeKey][legend]) {
        seriesData.push(data[timeKey][legend].totalKeyQuantity.toFixed());
        produced_quantity.push(data[timeKey][legend]?.produced_quantity);
        treated_quantity_data.push(
          data[timeKey][legend]?.treated_quantity || 0
        );
        treatedPercentageData.push(
          isFinite(data[timeKey][legend]?.percentTreated) &&
            !isNaN(data[timeKey][legend]?.percentTreated)
            ? data[timeKey][legend]?.percentTreated
            : 0
        );
      } else {
        seriesData.push(0); // Placeholder for missing data
        treated_quantity_data.push(0); // Placeholder for missing consumed fuel data
        treatedPercentageData.push(0);
      }
    }
    treatedPercentageSeriesData = treatedPercentageData; // Store treated percentages for the line chart
    ProducedSeriesData = produced_quantity;
    treatedSeriesData = treated_quantity_data;
    let totalKeyQuantitysByTimeKey = xAxisLabels.reduce((acc, timeKey) => {
      acc[timeKey] = Object.values(data[timeKey]).reduce((total, siteData) => {
        return total + (siteData.totalKeyQuantity || 0);
      }, 0);
      return acc;
    }, {});
    series.push({
      produced_quantity,
      name: legend,
      type: "bar",
      stack: "total",
      barWidth: "20%",
      label: {
        show: true,
        position: legend.trim() === "Total" ? "top" : "", // Different label positions
        formatter: (params) => {
          const timeKeyIndex = params.dataIndex; // Current timeKey index
          const timeKey = xAxisLabels[timeKeyIndex]; // Get the current timeKey
          const totalKeyQuantityForTimeKey =
            totalKeyQuantitysByTimeKey[timeKey]; // Total emission across all sites for the current timeKey

          const value = Number(params.value); // Current block value

          // Calculate the percentage of the current block relative to the total emission for the timeKey
          let percentage = (
            (value / totalKeyQuantityForTimeKey) *
            100
          ).toFixed();

          // Handle edge cases for NaN, undefined, or 0
          if (isNaN(percentage) || !isFinite(percentage)) {
            percentage = 0;
          }

          // Show value or percentage based on legend name
          return legend.trim() === "Total"
            ? `${value > 10 ? value : ""}`
            : `${percentage > 5 ? percentage + "%" : ""}`;
        },
        fontSize: 10,
      },
      data: seriesData.map((value, index) => ({
        value,
        treated_quantity: treated_quantity_data[index],
        percentTreated: treatedPercentageData[index], // Attach consumed fuel to each data point
      })),
    });
  }

  return { xAxisLabels, series };
}
const generateChartOption = (
  titleText,
  legendData,
  xAxisLabels,
  series,
  key
) => {
  return {
    color: [
      "#5470C6",
      "#91CC75",
      "#EE6666",
      "#FAC858",
      "#73C0DE",
      "#3BA272",
      "#FC8452",
      "#9A60B4",
      "#EA7CCC",
      "#D14A61",
      "#675BBA",
      "#F5994E",
      "#A0A7E6",
      "#7F9EC6",
      "#91C7AE",
      "#D48265",
      "#61A0A8",
      "#C4CCD3",
      "#D53A35",
      "#4E91D5",
      "#6B4A3E",
      "#CA8622",
      "#BDA29A",
      "#CC3C5D",
      "#00A29B",
      "#FF7F50",
      "#6495ED",
      "#FFD700",
      "#32CD32",
      "#FFA07A",
      "#20B2AA",
      "#87CEEB",
      "#778899",
      "#B0E0E6",
      "#FF6347",
      "#4682B4",
      "#8B0000",
      "#008080",
      "#FFD700",
      "#4B0082",
      "#DC143C",
      "#2E8B57",
      "#FF69B4",
      "#FF4500",
      "#808000",
      "#FF1493",
      "#00CED1",
      "#1E90FF",
      "#8A2BE2",
      "#A52A2A",
      "#5F9EA0",
      "#FF8C00",
      "#B22222",
      "#DAA520",
      "#228B22",
      "#FFDAB9",
      "#FF00FF",
      "#7FFF00",
      "#FA8072",
      "#F08080",
      "#FFB6C1",
      "#E0FFFF",
      "#FFE4E1",
      "#7B68EE",
      "#D2691E",
      "#9ACD32",
      "#B8860B",
      "#9932CC",
      "#00FF7F",
      "#40E0D0",
      "#FFFAF0",
      "#8FBC8F",
      "#BDB76B",
      "#FFDEAD",
      "#7CFC00",
      "#F0E68C",
      "#E6E6FA",
      "#FFF0F5",
      "#00FF00",
      "#F4A460",
      "#D8BFD8",
      "#FF7F50",
      "#FF6347",
      "#FFDAB9",
      "#FFE4B5",
      "#FFFACD",
      "#00FA9A",
      "#DDA0DD",
      "#FFF5EE",
      "#B0C4DE",
      "#B0E0E6",
      "#87CEEB",
      "#00BFFF",
      "#FFD700",
      "#32CD32",
      "#FFA500",
      "#CD5C5C",
      "#D3D3D3",
      "#4682B4",
      "#FF00FF",
      "#00CED1",
      "#808080",
    ],

    title: {
      text: titleText,
      left: "center",
    },
    // label:{
    // show:true
    // },
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: {
          show: true,
          readOnly: true,
          option: {
            lang: ['Quantity', 'Percentage'],
            contentToOption: (opt) => {
              const seriesName = opt.seriesName;
              const data = opt.data;
              const value = opt.value;
              const treatedQuantity = data?.treatedQuantity || 0;
              const producedQuantity = data?.producedQuantity || 0;
              const recoveredQuantity = data?.recoveredQuantity || 0;
              const wasteDisposalPercentage = (treatedQuantity / producedQuantity) * 100 || 0;
              const wasteRecoveryPercentage = (recoveredQuantity / producedQuantity) * 100 || 0;

              if (seriesName === 'Waste Disposed %') {
                return { value, lang: ['Quantity', 'Percentage'], content: `${value} Tonnes (${wasteDisposalPercentage.toFixed(2)}%)` };
              } else if (seriesName === 'Waste Recovered %') {
                return { value, lang: ['Quantity', 'Percentage'], content: `${value} Tonnes (${wasteRecoveryPercentage.toFixed(2)}%)` };
              } else {
                return { value, lang: ['Quantity', 'Percentage'], content: `${value} Tonnes` };
              }
            },
          }
        },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    responsive: true,
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        const { seriesName, value, data } = params;

        let tooltipText;
        if (params.seriesType === "line") {
          if (params.seriesName === "Waste Disposed %") {
            if (data) {
              tooltipText = `${seriesName} <br/>Treated Quantity: ${data.treatedQuantity?.toFixed()} Tonnes`;
            } else {
              console.warn(
                `No data found for Waste Disposed percentage at site ${params.name}.`
              );
            }
          } else if (params.seriesName === "Waste Recovered %") {
            tooltipText = `${seriesName} <br/>Recovered Quantity: ${data.recoveredQuantity?.toFixed()} Tonnes`;
          } else {
            tooltipText = `${seriesName} <br/>${formatText(
              key
            )}: ${value} Tonnes`;
          }
        } else {
          tooltipText = `${seriesName} <br/>${formatText(
            key
          )}: ${value} Tonnes`;
        }

        // Add consumed fuel data if available and relevant to the series
        // if (data && data.treated_quantity) {
        //   tooltipText += `<br/> Treated: ${data.treated_quantity.toFixed()} Tonnes`;
        // }

        return tooltipText;
      },
    },
    legend: {
      top: "bottom",
      padding: [30, 10, 10, 10], // top, right, bottom, left
      itemGap: 10,
    },
    labelLine: {
      show: true,
    },
    grid: {
      left: 60,
      right: 60,
      top: 50,
      bottom: series?.length > 6 ? series.length * 10 - 10 : 60, // Adjust bottom space based on series length
      containLabel: false,
    },
    yAxis: [
      {
        type: "value",
        name: "(in Tonnes)",
        axisLabel: {
          formatter: (value) => {
            if (value >= 1000000) {
              return (value / 1000000).toFixed() + "M ";
            } else if (value >= 1000) {
              return (value / 1000).toFixed() + "K ";
            } else {
              return (
                value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " "
              );
            }
          },
        },
      },
      {
        type: "value",
        // name: "Waste Disposed %",
        position: "right",
        axisLabel: {
          formatter: "{value}%",
        },
        splitLine: {
          show: false,
        },
        // max: 100,
      },
      {
        type: "value",
        // name: "Waste Recovered %",
        position: "right",
        axisLabel: {
          formatter: "{value}%",
        },
        splitLine: {
          show: false,
        },
        // max: 100,
      },
    ],
    xAxis: {
      type: "category",
      data: xAxisLabels,
    },
    series: series.map((item) => ({
      ...item,
      data: item.data.map((dataPoint) => ({
        ...dataPoint,
        treated_quantity: dataPoint.treated_quantity || 0, // Ensure treated_quantity is included
      })),
    })),
  };
};
const generatePieChartOptions = (
  text,
  filteredData,
  breaktype,
  currentFinancialYear,
  key
) => {
  const { series } = processDataToLablesAndDataSetsPie(
    processData(
      filteredData,
      "Year",
      breaktype,
      Number(currentFinancialYear),
      key
    )
  );

  // console.log({series,breaktype})
  const pieChartData =
    series &&
    series.map((dataItem) => ({
      value: dataItem?.data
        ?.reduce((acc, curr) => acc + Number(curr?.value), 0)
        .toFixed(),
      name: dataItem.name,
    }));

  return {
    responsive: true,
    color: [
      "#5470C6",
      "#91CC75",
      "#EE6666",
      "#FAC858",
      "#73C0DE",
      "#3BA272",
      "#FC8452",
      "#9A60B4",
      "#EA7CCC",
      "#D14A61",
      "#675BBA",
      "#F5994E",
      "#A0A7E6",
      "#7F9EC6",
      "#91C7AE",
      "#D48265",
      "#61A0A8",
      "#C4CCD3",
      "#D53A35",
      "#4E91D5",
      "#6B4A3E",
      "#CA8622",
      "#BDA29A",
      "#CC3C5D",
      "#00A29B",
      "#FF7F50",
      "#6495ED",
      "#FFD700",
      "#32CD32",
      "#FFA07A",
      "#20B2AA",
      "#87CEEB",
      "#778899",
      "#B0E0E6",
      "#FF6347",
      "#4682B4",
      "#8B0000",
      "#008080",
      "#FFD700",
      "#4B0082",
      "#DC143C",
      "#2E8B57",
      "#FF69B4",
      "#FF4500",
      "#808000",
      "#FF1493",
      "#00CED1",
      "#1E90FF",
      "#8A2BE2",
      "#A52A2A",
      "#5F9EA0",
      "#FF8C00",
      "#B22222",
      "#DAA520",
      "#228B22",
      "#FFDAB9",
      "#FF00FF",
      "#7FFF00",
      "#FA8072",
      "#F08080",
      "#FFB6C1",
      "#E0FFFF",
      "#FFE4E1",
      "#7B68EE",
      "#D2691E",
      "#9ACD32",
      "#B8860B",
      "#9932CC",
      "#00FF7F",
      "#40E0D0",
      "#FFFAF0",
      "#8FBC8F",
      "#BDB76B",
      "#FFDEAD",
      "#7CFC00",
      "#F0E68C",
      "#E6E6FA",
      "#FFF0F5",
      "#00FF00",
      "#F4A460",
      "#D8BFD8",
      "#FF7F50",
      "#FF6347",
      "#FFDAB9",
      "#FFE4B5",
      "#FFFACD",
      "#00FA9A",
      "#DDA0DD",
      "#FFF5EE",
      "#B0C4DE",
      "#B0E0E6",
      "#87CEEB",
      "#00BFFF",
      "#FFD700",
      "#32CD32",
      "#FFA500",
      "#CD5C5C",
      "#D3D3D3",
      "#4682B4",
      "#FF00FF",
      "#00CED1",
      "#808080",
    ],
    title: {
      text:
        key === "produced_quantity"
          ? `Waste Generated ${text}`
          : key === "recovered_quantity"
          ? `Waste Recovered ${text}`
          : key === "treated_quantity"
          ? `Waste Disposed ${text}`
          : "",
      left: "center",
    },
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        let tooltipText = "";
        tooltipText += `${params.name}<br/>${formatText(key)}: ${parseFloat(
          params.value
        ).toFixed()} Tonnes`;

        return tooltipText;
      },
    },
    legend: {
      // type: 'scroll', // Enable scrollable legend
      // orient: 'horizontal', // Orient legend vertically
      // right: 10,
      // top: 20,
      // bottom: 20,
      // type: 'scroll', // Enable scrollable legend
      orient: "horizontal", // Orient legend vertically
      bottom: "5%",
      padding: [0, 0, 0, 0],
      top: "bottom",
      formatter: (name) => {
        // Shorten the legend name if it's too long
        // return name.length > 15 ? `${name.slice(0, 15)}...` : name;
        return name;
      },
      pageIconSize: [12, 12], // Set the size of the page icons
      pageTextStyle: {
        fontSize: 12,
      },
    },
    series: [
      {
        type: "pie",
        radius: ["40%", "70%"],
        center: ["50%", "50%"], // Center the pie chart
        avoidLabelOverlap: false,
        label: {
          position: "inside",
          show: true,
          fontSize: 10,
          fontWeight: "bold",
          formatter: (params) => {
            return `${params?.percent?.toFixed()}%`;
          },
        },
        labelLine: {
          show: true,
        },
        data: pieChartData,
      },
    ],
  };
};

const generateStackedChartOptions = (
  chartData,
  currentFinancialYear,
  typeBreakdown,
  legends,
  xAxisData,
  series,
  key
) => {
  const currentYearChartData = chartData.filter(
    (item) => item.year === Number(currentFinancialYear)
  );

  if (!currentYearChartData || !currentYearChartData.length) {
    console.warn("No data available for the current financial year.");
    return {};
  }

  const yAxis = [
    {
      type: "value",
      name: "(in Tonnes)",
      axisLabel: {
        formatter: (value) => {
          if (value >= 1000000) {
            return (value / 1000000).toFixed() + "M ";
          } else if (value >= 1000) {
            return (value / 1000).toFixed() + "K ";
          } else {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "";
          }
        },
      },
    },
    {
      type: "value",
      max: 100,
      axisLabel: {
        formatter: "{value}%",
      },
      splitLine: {
        show: false,
      },
    },
  ];
  const text = "Facilitywise";
  return {
    title: {
      text:
        key === "produced_quantity"
          ? `${text} Waste Generated `
          : key === "recovered_quantity"
          ? `${text} Waste Recovered `
          : key === "treated_quantity"
          ? `${text} Waste Disposed `
          : "",
      left: "center",
    },
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },

    tooltip: {
      trigger: "item",
      formatter: function (params) {
        let tooltipContent = `${params.seriesName}<br/>`;

        // Bar chart (emissions breakdown)
        if (params.seriesType === "bar") {
          tooltipContent += `${formatText(
            key
          )}: ${params.value.toFixed()} Tonnes<br/>`;

          const entry = currentYearChartData?.find(
            (item) =>
              item[typeBreakdown] === params.seriesName &&
              item.site === params.name
          );
          if (entry) {
            const wasteProduced = entry["produced_quantity"];
            // tooltipContent += `Waste Produced: ${wasteProduced.toFixed()} tonns<br/>`;
          } else {
            console.warn(
              `No data found for ${params.seriesName} at site ${params.name}.`
            );
          }
        }
        // Line chart (Waste Disposed percentage)
        else if (
          params.seriesType === "line" &&
          (params.seriesName === "Waste Disposed %" ||
            params.seriesName === "Waste Recovered %")
        ) {
          if (params.seriesName === "Waste Recovered %") {
            tooltipContent += `Recovered Quantity:  ${params?.data?.recovered_quantity}tonnes<br/>`;
          } else {
            tooltipContent += `Treated Quantity: ${params?.data?.treated_quantity} tonnes<br/>`;
          }
        }

        return tooltipContent;
      },
    },
    legend: {
      type: "scroll",
      data: [...legends, "Waste Disposed %", "Waste Recovered %"], // Include legend for line series
      top: "bottom",
    },
    grid: {
      left: 60,
      right: 60,
      top: 50,
      // bottom: series?.length > 6 ? series.length * 10 - 10 : 60, // Adjust bottom space based on series length
      containLabel: false,
    },
    xAxis: {
      type: "category",
      data: xAxisData,
    },
    label: {
      show: true,
      
      fontSize: 10,
      fontWeight: "bold",
    },
    yAxis: yAxis,
    series: series.map((s) => {
      if (s.type === "line") {
        return {
          ...s,
          yAxisIndex: 1, // Assign to the secondary y-axis for percentage
          name:
            s.name === "Waste Disposed Quantity"
              ? "Waste Disposed %"
              : s.name === "Waste Recovered Quantity"
              ? "Waste Recovered %"
              : s.name,
        };
      }
      return s;
    }),
  };
};

function calculateWasteStatistics(data, currentYear) {
  const aggregateBySite = (data) => {
    const aggregatedData = {};
    data.forEach((entry) => {
      const { site, produced_quantity, treated_quantity, treatment_mechanism } =
        entry;

      if (!aggregatedData[site]) {
        aggregatedData[site] = {
          wasteProduced: 0,
          wasteTreated: 0,
          wasteRecovered: 0,
        };
      }

      aggregatedData[site].wasteProduced += produced_quantity;

      if (treatment_mechanism?.toLowerCase().includes("recycling")) {
        aggregatedData[site].wasteRecovered += treated_quantity;
      } else if (!treatment_mechanism?.toLowerCase().includes("recycling")) {
        aggregatedData[site].wasteTreated += treated_quantity;
      }
    });
    return aggregatedData;
  };

  const calculatePercentage = (aggregatedData) => {
    const percentages = {};
    Object.keys(aggregatedData).forEach((site) => {
      const { wasteProduced, wasteTreated, wasteRecovered } =
        aggregatedData[site];

      const wasteTreatedPercentage =
        wasteProduced > 0 ? (wasteTreated / wasteProduced) * 100 : 0;
      const wasteRecoveredPercentage =
        wasteProduced > 0 ? (wasteRecovered / wasteProduced) * 100 : 0;

      percentages[site] = {
        wasteProduced,
        wasteTreated,
        wasteRecovered,
        wasteTreatedPercentage: wasteTreatedPercentage.toFixed(),
        wasteRecoveredPercentage: wasteRecoveredPercentage.toFixed(),
      };
    });
    return percentages;
  };

  const calculateYearlyChange = (currentYearData, previousYearData) => {
    Object.keys(currentYearData).forEach((site) => {
      const current = currentYearData[site];
      const previous = previousYearData[site] || {
        wasteTreatedPercentage: 0,
        wasteRecoveredPercentage: 0,
      };

      const calculateChange = (currentValue, previousValue) => {
        if (
          previousValue === 0 ||
          isNaN(currentValue) ||
          !isFinite(currentValue)
        ) {
          return 0;
        }
        return ((currentValue - previousValue) / previousValue) * 100;
      };

      const wasteTreatedPercentageChange = calculateChange(
        parseFloat(current.wasteTreatedPercentage),
        parseFloat(previous.wasteTreatedPercentage)
      );

      const wasteRecoveredPercentageChange = calculateChange(
        parseFloat(current.wasteRecoveredPercentage),
        parseFloat(previous.wasteRecoveredPercentage)
      );

      currentYearData[site].wasteTreatedPercentageChange =
        wasteTreatedPercentageChange.toFixed();
      currentYearData[site].wasteRecoveredPercentageChange =
        wasteRecoveredPercentageChange.toFixed();
    });
    return currentYearData;
  };

  const currentYearData = aggregateBySite(
    data.filter((entry) => entry.year === currentYear)
  );
  const previousYearData = aggregateBySite(
    data.filter((entry) => entry.year === currentYear - 1)
  );

  const currentYearPercentages = calculatePercentage(currentYearData);
  const previousYearPercentages = calculatePercentage(previousYearData);

  return calculateYearlyChange(currentYearPercentages, previousYearPercentages);
}

const calculateAggregateWasteMetrics = (data, currentYear) => {
  // Aggregate data for all sites in a year
  const aggregateYearData = (data, year) => {
    let totalProduced = 0;
    let totalTreated = 0;
    let totalRecovered = 0;
    let totalRevenue = 0;

    data.forEach((entry) => {
      if (entry.year === year) {
        totalProduced += entry.produced_quantity;
        totalRevenue += entry?.revenue || 0;

        if (entry?.treatment_mechanism?.toLowerCase().includes("recycling")) {
          totalRecovered += entry.treated_quantity;
        } else if (
          !entry?.treatment_mechanism?.toLowerCase().includes("recycling")
        ) {
          totalTreated += entry.treated_quantity;
        }
      }
    });

    return {
      totalProduced,
      totalTreated: totalTreated.toFixed(),
      totalRecovered: totalRecovered.toFixed(),
      totalRevenue,
    };
  };

  // Calculate percentages and waste intensity for the aggregated data
  const calculateMetrics = ({
    totalProduced,
    totalTreated,
    totalRecovered,
    totalRevenue,
  }) => {
    const treatedPercentage =
      totalProduced > 0 ? (totalTreated / totalProduced) * 100 : 0;
    const recoveredPercentage =
      totalProduced > 0 ? (totalRecovered / totalProduced) * 100 : 0;
    const wasteIntensity =
      totalProduced > 0 && totalRevenue > 0
        ? totalProduced / (totalRevenue / 100000)
        : 0;

    return {
      treatedPercentage: treatedPercentage.toFixed(),
      recoveredPercentage: recoveredPercentage.toFixed(),
      wasteIntensity: wasteIntensity.toFixed(),
    };
  };

  // Calculate percentage change
  const calculateChange = (current, previous) => {
    if (previous === 0 || isNaN(current) || !isFinite(current)) {
      return 0;
    }
    return (((current - previous) / previous) * 100).toFixed();
  };

  // Aggregating the data for the current and previous year
  const currentYearData = aggregateYearData(data, currentYear);
  const previousYearData = aggregateYearData(data, currentYear - 1);

  // Calculate metrics for the current and previous year
  const currentMetrics = calculateMetrics(currentYearData);
  const previousMetrics = calculateMetrics(previousYearData);

  // Calculate year-over-year changes
  const changeProduced = calculateChange(
    currentYearData.totalProduced,
    previousYearData.totalProduced
  );
  const changeTreated = calculateChange(
    currentMetrics.treatedPercentage,
    previousMetrics.treatedPercentage
  );
  const changeRecovered = calculateChange(
    currentMetrics.recoveredPercentage,
    previousMetrics.recoveredPercentage
  );
  const changeIntensity = calculateChange(
    currentMetrics.wasteIntensity,
    previousMetrics.wasteIntensity
  );

  return {
    totalProduced: currentYearData.totalProduced,
    totalTreated: currentYearData.totalTreated,
    totalRecovered: currentYearData.totalRecovered,
    totalRevenue: currentYearData.totalRevenue,
    treatedPercentage: currentMetrics.treatedPercentage,
    recoveredPercentage: currentMetrics.recoveredPercentage,
    wasteIntensity: currentMetrics.wasteIntensity,
    changes: {
      producedChange: isFinite(changeProduced) ? changeProduced : 0,
      treatedPercentageChange: isFinite(changeTreated) ? changeTreated : 0,
      recoveredPercentageChange: isFinite(changeRecovered)
        ? changeRecovered
        : 0,
      intensityChange: changeIntensity,
    },
  };
};
export const generateSingleCandleChartOptions = (
  chartData,
  currentFinancialYear
) => {
  // Filter data for the current financial year
  const currentYearChartData = chartData.filter(
    (item) => item.year === Number(currentFinancialYear)
  );

  if (!currentYearChartData || !currentYearChartData.length) {
    console.warn("No data available for the current financial year.");
    return {};
  }

  // Calculate total emissions for the current year
  const totalEmissions = currentYearChartData.reduce(
    (total, item) => total + Number(item.produced_quantity),
    0
  );

  const seriesData = currentYearChartData.reduce((acc, item) => {
    const material = item.waste_material;
    const wasteProduced = Number(item.produced_quantity);
    const value = wasteProduced ? (wasteProduced / totalEmissions) * 100 : 0;

    const existingMaterial = acc.find((entry) => entry.name === material);
    if (existingMaterial) {
      existingMaterial.wasteProduced += wasteProduced;
      existingMaterial.value += value;
    } else {
      acc.push({
        name: material,
        wasteProduced,
        value,
      });
    }

    return acc;
  }, []);
  const series = seriesData.map((item) => ({
    name: item.name,
    type: "bar",
    stack: "total",
    label: {
      show: true,
      formatter: "{c}%", // Show percentage inside the bar
    },
    data: [item.value.toFixed()],
  }));
  // Chart Options
  return {
    title: { text: "Waste Generated by Waste Material", left: "center" },
    barWidth: 60,
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        const materialData = seriesData.find((m) => m.name === params.seriesName);
        if (materialData) {
          return `
            <b>${params.seriesName}</b><br/>
            Waste Produced: ${materialData.wasteProduced?.toFixed()} tonnes
          `;
        }
        return `Data not found for ${params.name}`;
      },
    },
    legend: {
      // data: seriesData.map((s) => s.name), // Legend based on site names
      top: "bottom",
    },
    label: {
      show: true,
      
      fontSize: 10,
      fontWeight: "bold",
    },
    // grid: {
    //   left: "1%",
    //   right: "1%",
    //   bottom: "30%",
    //   containLabel: true,
    // },
    xAxis: {
      type: "value",
      // name: "Waste Material Share (%)",
    },
    yAxis: {
      type: "category",
      data: ["Waste Materials"], // Single bar with no y-axis label
      // position: "top",
       axisLabel: {
    rotate: 90, // Rotate the text 90 degrees
  },


    },
    series: series,
  };
};

export {
  processData,
  generateStackedChartOptions,
  processDataToLablesAndDataSets,
  currentYearLastYearEmissionDetail,
  generatePieChartOptions,
  generateChartOption,
  calculateWasteStatistics,
  calculateAggregateWasteMetrics,
};

// fuel cases validation

export function validateFuelData(data) {
  // Validate Case 1: quantity * carbon_content_of_fuel
  // const case1Valid = (data.quantity && data.carbon_content_of_fuel);

  // // Validate Case 2: quantity * heat_content_of_fuel * ef_of_fuel
  // const case2Valid = (data.quantity && data.heat_content_of_fuel && data.ef_of_fuel);

  // // Validate Case 3: quantity * ef_of_fuel
  // const case3Valid = (data.quantity && data.ef_of_fuel);

  // // // Validate Case 4: paid_amount * ef_of_fuel
  // const case4Valid = (data.amount_paid && data.ef_of_fuel);

  // Return true if any of the cases are valid and no extra fields are present
  return true;
}
// Electricity cases validation

export function validateElectricityData(data) {
  // Validate Case 1: quantity * ef_of_fuel
  const case1Valid = data.quantity && data.ef_of_fuel;

  // Return true if any of the cases are valid and no extra fields are present
  return case1Valid;
}

export function removeEmptyProperties(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([key, value]) => value !== null && value !== undefined && value !== ""
    )
  );
}
