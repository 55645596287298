import React from "react";
import {ReactComponent as CalenderIcon} from "../../../../app/assets/CalenderIcon.svg"
import {ReactComponent as SitesIcon} from "../../../../app/assets/SitesIcon.svg"
const GenericTiles = ({ data, title, categoryHeaders, dataKey }) => {

  return (
    <div className="container mx-auto space-y-8 w-full p-3">
      {Object.keys(data).map((year) => (
        <div key={year} className="border border-gray-300 p-3 rounded bg-gray-100">
          <div className="flex justify-start gap-5 border-b border-gray-300 pb-4 mb-4">
          <div className="flex items-center gap-2 justify-center">
                {/* <SitesIcon className="w-6 h-6" /> */}
            <CalenderIcon className="w-5 h-5" />
                <label>Financial Year</label>
          <div className="flex items-center gap-2 justify-center border bg-white p-1 px-2 rounded">
         
            <div className="flex items-center gap-2">
              <select
                className="font-semibold  text-center"
                disabled
              >
                <option selected>{` ${year}`}</option>
              </select>
            </div>
            </div>
            </div>
           
          </div>
          <table className="w-full table-auto border-collapse">
            <thead>
              <tr className="bg-gray-200">
                <th className="p-2 border border-gray-300">{dataKey}</th>
                {categoryHeaders.map((header) => (
                  <th
                    key={header}
                    className="p-2 border border-gray-300 text-center"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(data[year]).map((category) => (
                <tr key={category} className="hover:bg-gray-100">
                  <td className="p-2 border border-gray-300 font-medium">
                    {category.replace(/_/g, " ").charAt(0).toUpperCase() +  category.replace(/_/g, " ").slice(1)}
                  </td>
                  {categoryHeaders.map((header) => (
                    <td
                      key={`${category}-${header}`}
                      className="p-2 border border-gray-300 text-center"
                    >
                      {data[year][category][header.toLowerCase()] || 0}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default GenericTiles;
