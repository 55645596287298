import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import SuccessPopUp from "../../UserAuthPages/Components/SuccessPopUp";
import OTPPopup from "../../UserAuthPages/Components/OtpPopup";
import Modal from "./Modal";
import { createPassword } from "../../../features/auth/authThunks";


const ChangePassword = ({isModalOpen, setModalOpen}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpenPopUp, setisOpenPopUp] = useState(false);
  const [isOTPVisible, setOTPVisible] = useState(false);
  const [otp, setOTP] = useState("");
  
  const { mandatoryStepData} = useSelector((state) => state.auth);
  const openOTPModal = () => {
    setOTPVisible(true);
  };

  const closeOTPModal = () => {
    setOTPVisible(false);
  };

  // const handleOTPSubmit = async (otp) => {
  //   if (otp === "1234") {
  //     try {
  //       await dispatch(
  //         createPassword({
  //           currentPassword: passwordData.currentPassword,
  //           password: passwordData.password,
  //           confirmPassword: passwordData.confirmPassword,
  //         })
  //       );
  //       closeOTPModal();
  //       setisOpenPopUp(true);
        
  //     } catch (error) {
  //       closeOTPModal();
  //       console.error("Password update failed:", error);
  //     }
  //   } else {
  //     toast("Wrong Otp");
  //   }
  //   setOTP(otp);
  // };

  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    if (!passwordRegex.test(passwordData.password)) {
      toast.error(
        "Password must contain at least 1 uppercase letter, 1 special character, 1 number, and be at least 8 characters long"
      );
      return;
    }
    if (passwordData.password !== passwordData.confirmPassword) {
      toast.error("New password and confirm password do not match");
      return;
    }
    try {
      setModalOpen(false)
      await dispatch(
        createPassword({
          currentPassword: passwordData.currentPassword,
          password: passwordData.password,
          confirmPassword: passwordData.confirmPassword,
          step_id: mandatoryStepData?.Step?.id,
        })
      );
      setisOpenPopUp(true);
      
      setTimeout(() => {
        setisOpenPopUp(false)
      }, 1000);
    } catch (error) {
      closeOTPModal();
      console.error("Password update failed:", error);
    }
  };

  return (
    <>
    
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <div className="flex flex-col items-center justify-center">
        <div className="flex justify-between px-4 items-center  w-full">

          <h1 className="text-2xl font-semibold  text-left w-full">
            Update Password
          </h1>
          <button
              className="  ml-auto bg-transparent flex items-center justify-center text-center  text-gray-500 float-right text-2xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => setModalOpen(false)}
            >
              <span className="bg-transparent flex items-center justify-center text-center text-gray-500 h-6 w-6 text-xl  outline-none focus:outline-none">
                ×
              </span>
            </button>
        </div>
          <form
            onSubmit={handleUpdatePassword}
            className="space-y-4 p-4 w-full"
          >
            <div>
              <label
                htmlFor="currentPassword"
                className="block mb-1 text-gray-600"
              >
                Current Password<span className="text-red-500">*</span>
              </label>
              <input
                type="password"
                id="currentPassword"
                name="currentPassword"
                value={passwordData.currentPassword}
                onChange={handleInputChange}
                className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
                placeholder="Current Password"
                required
              />
            </div>
            <div>
              <label htmlFor="password" className="block mb-1 text-gray-600">
                New Password<span className="text-red-500">*</span>
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={passwordData.password}
                onChange={handleInputChange}
                className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
                placeholder="New Password"
                required
              />
            </div>
            <div>
              <label
                htmlFor="confirmPassword"
                className="block mb-1 text-gray-600"
              >
                Confirm Password<span className="text-red-500">*</span>
              </label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={passwordData.confirmPassword}
                onChange={handleInputChange}
                className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
                placeholder="Confirm Password"
                required
              />
            </div>
            <div className="flex flex-col justify-start items-center space-y-2">
              <button
                type="submit"
                className="w-full px-10 py-3 bg-[#02AB6C] text-white rounded hover:bg-[#02985C] font-bold transition-all duration-300 ease-in-out transform hover:scale-105"
              >
                Update Password
              </button>
            </div>
          </form>
        </div>
      </Modal>
      {isOpenPopUp && (
        <SuccessPopUp
          text={"Password Updated"}
          textColor={"#02AB6C"}
          bgColor={"#EEFFF9"}
        />
      )}
      {/* <OTPPopup
        isOpen={isOTPVisible}
        onClose={closeOTPModal}
        onSubmit={handleOTPSubmit}
      /> */}
    </>
  );
};

export default ChangePassword;
