/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ReactComponent as Scope3Icon } from "../../app/assets/BussinessTravel.svg";
import { ReactComponent as CopyIcon } from "../../app/assets/CopyIcon.svg";
import { ReactComponent as DeleteIcon } from "../../app/assets/DeleteIcon.svg";
import { ReactComponent as PencilIcon } from "../../app/assets/PencilIcon.svg";

import { ReactComponent as AddIconBig } from "../../app/assets/AddIconBig.svg";
import { ReactComponent as DownloadIcon } from "../../app/assets/DownloadIcon.svg";
import { ReactComponent as UserIcon } from "../../app/assets/UserIcon.svg";

import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import {
  resetscope3Category6Form,
  setCurrentPage,
  setFilters,
  setItemsPerPage,
  setSubtypeDataFiltred,
  setfilteredTypeData,
  setfiltredFuelTypeData,
  setscope3Category6Form,
} from "../../features/scope3/category6/Scope3Category6Slice";
import Header from "../../components/common/Header";
import TableMeta from "../../components/common/Table/TableMeta";
import Table from "../../components/common/Table/Table";
import TablePagination from "../../components/common/Table/TablePagination";
import Purchase_s3c6_Form from "../../components/scope3/category6/Purchases3c6Form";
import Purchases3c6EditAndCopyForm from "../../components/scope3/category6/Purchases3c6EditAndCopyForm";
import {
  fetchExpenseUnitData,
  fetchFuelTypeData,
  fetchmodeOfTravelMappingData,
  fetchTravelSubTypeData,
  fetchTravelTypeData,
  fetchscope3Category6InputData,
  fetchSiteData,
  deletescope3Category6Data,
} from "../../features/scope3/category6/Scope3Category6Thunk";

import useApi from "../../customHooks/useApi";

import { generatemodeOfTravelsData, getUniquemodeOfTravelData } from "./function/UniqueLogisticMode";
import { removeDuplicates } from "./function/removeDuplicates";
import FilterComponent from "../../components/common/FilterComponent";
import { useSearchParams } from "react-router-dom";

const Scope3Category6 = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const api = useApi();
  const dispatch = useDispatch();
  const scope3Category6InputRecords = useSelector(
    (state) => state.scope3Category6.scope3Category6InputRecords
  );
  const [files, setfiles] = useState(null);
  const itemsPerPage = useSelector(
    (state) => state?.scope3Category6?.itemsPerPage
  );
  const scope3Category6Form = useSelector(
    (state) => state?.scope3Category6?.scope3Category6Form
  );
  const {totalPages,siteDataForFilter} = useSelector((state) => state?.scope3Category6);
  const totalCount = useSelector((state) => state?.scope3Category6?.totalCount);
  const read_only = useSelector((state) => state?.auth?.read_only);
  const currentPage = useSelector(
    (state) => state?.scope3Category6?.currentPage
  );
  const [modeOfTravel, setmodeOfTravel] = useState([]);
  const [radiativeForcing, setradiativeForcing] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  const [isPurchaseFormOpen, setPurchaseFormOpen] = useState(false);
  const [manualEntry, setManualEntry] = useState(true);
  const [apiIntegration, setApiIntegration] = useState(false);
  const [trendLine, setTrendLine] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [actionType, setActionType] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isEditCopyFormOpen, setIsEditCopyFormOpen] = useState(false);
  const [itemselected, setItemSelected] = useState({});
  const { typeData, subType, modeOfTravelMapping, fuelType } = useSelector(
    (state) => state.scope3Category6
  );
  const headingsToDataKeyMap = {
    " ": "",
    DATE: "date",
    FACILITY: "site",
    "SOURCE ": "source",
    "DESTINATION ": "destination",
    "DISTANCE (km)": "distance",
    PASSENGERS: "number_of_employees",
    "MODE OF TRAVEL": "mode_of_travel",
    "TRAVEL TYPE": "travel_type",
    "TRAVEL SUBTYPE": "travel_subtype",
    "FUEL TYPE": "fuel_type",
    "RADIATIVE FORCING": "air_conditioned",

    EXPENSE: "expense",
    "EXPENSE UNIT": "expense_unit",

    EVIDENCE: "evidence",
    STATUS: "status",
    // COMMENTS: "comments",
    // EDIT: "edit",
    // DELETE:""
  };
  const filters = [
    // {
    //   name: "date",
    //   label: "Date",
    //   type:"date",
    //   options:[],
    // },
    {
      name: "site_id",
      label: "Facility",
      options:siteDataForFilter&&siteDataForFilter.map(site=>{
        return {
          value:site.id,
          label:site.site
        }
      }),
    },
    {
      name: "mode_of_travel",
      label: "Mode of travel",
      options:modeOfTravelMapping&&getUniquemodeOfTravelData(modeOfTravelMapping).map(site=>{
        return {
          value:site.id,
          label:site.mode_of_travel
        }
      }),
    },
    // {
    //   name: "item_carried",
    //   label: "Item carried",
    //   options:scope3Category4ItemData&&scope3Category4ItemData.map(site=>{
    //     return {
    //       value:site.id,
    //       label:site.combination
    //     }
    //   }),
    // },
  ];
  const handleFilterChange = (newFilters) => {
    // fetchData(newFilters);
    dispatch(fetchscope3Category6InputData({ api,params:newFilters }));
    const updatedParams = new URLSearchParams(newFilters).toString();
    dispatch(setFilters(updatedParams))
    setSearchParams(updatedParams);
  };
  if(!read_only){
    headingsToDataKeyMap.EDIT="edit"
    headingsToDataKeyMap.DELETE="delete"
  }
  const handleDelete = (row) => {
    if(row.id){

      dispatch(deletescope3Category6Data({api,id:row.id}))
    }else{
      toast.error("Failed please try again")
    }
  };
  const handleEdit = (row) => {
    dispatch(resetscope3Category6Form());
    dispatch(setfilteredTypeData([]));
    dispatch(setSubtypeDataFiltred([]));
    dispatch(setfiltredFuelTypeData([]));
    setradiativeForcing([])
    setActionType("edit");
    setSelectedRowData(row);
    setIsEditCopyFormOpen(true);
  };

  const handleCopy = (row) => {
    dispatch(resetscope3Category6Form());
    dispatch(setfilteredTypeData([]));
    dispatch(setSubtypeDataFiltred([]));
    dispatch(setfiltredFuelTypeData([]));
    setradiativeForcing([])
    setActionType("copy");
    setSelectedRowData(row);
    setIsEditCopyFormOpen(true);
  };

  const handleCloseEditCopyForms = () => {
    setIsEditCopyFormOpen(false);
  };

  const switchTab = (tabId) => {
    switch (tabId) {
      case 1:
        setManualEntry(true);
        setApiIntegration(false);
        setTrendLine(false);
        break;
      case 2:
        setManualEntry(false);
        setApiIntegration(true);
        setTrendLine(false);
        break;
      case 3:
        setManualEntry(false);
        setApiIntegration(false);
        setTrendLine(true);
        break;
      default:
        setManualEntry(true);
        setApiIntegration(false);
        setTrendLine(false);
        break;
    }
    setSelectedTab(tabId);
  };

  const openPopupForm = () => {
    dispatch(resetscope3Category6Form());
    dispatch(setfilteredTypeData([]));
    dispatch(setSubtypeDataFiltred([]));
    dispatch(setfiltredFuelTypeData([]));
    setradiativeForcing([])
    setPurchaseFormOpen(true);
  };

  const closePurchasePopupForm = () => {
    setPurchaseFormOpen(false);
    dispatch(setSubtypeDataFiltred([]));
    dispatch(setfiltredFuelTypeData([]));
    dispatch(resetscope3Category6Form());
    dispatch(setfilteredTypeData([]));
    dispatch(setSubtypeDataFiltred([]));
    dispatch(setfiltredFuelTypeData([]));
    setradiativeForcing([])
  };

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const handleItemsPerPage = (itemCount) => {
    dispatch(setItemsPerPage(itemCount));
  };

  const handleFileDownload = (filePath) => {
    if (filePath) {
      const link = document.createElement("a");
      link.href = filePath;
      link.download = "filename.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast("No evidence attached");
    }
  };

  const generateCellHtml = (row, k) => {
    let cellHtml = null;
    switch (k) {
      case "Scope3_type":
        cellHtml = (
          <span className="w-full flex justify-center">
            <span
              className={`px-2 py-1 border border-transparent rounded-md ${
                row["source_type"].toString().toLowerCase() ===
                "Renewable".toLowerCase()
                  ? "bg-green-200 text-green-600"
                  : "bg-red-200 text-red-600"
              }`}
            >
              {row[k]}
            </span>
          </span>
        );
        break;
        case "edit":
        cellHtml = (
          <span className="w-full flex justify-center">
            <PencilIcon
              className="cursor-pointer"
              onClick={() => handleEdit(row)}
            />
          </span>
        );
        break;
        case "delete":
          cellHtml = (
            <span className="w-full flex justify-center">
            <DeleteIcon
              className="cursor-pointer"
              onClick={() => handleDelete(row)}
            />
          </span>
          );
          break;
      case "status":
        cellHtml = (
          <span className="w-full flex justify-center">
            <span
              className={`px-2 py-1 border border-transparent rounded-md ${
                row[k] === "Accepted"
                  ? "bg-green-200 text-green-600"
                  : row[k] === "Submitted"
                  ? "bg-blue-200 text-blue-600"
                  : "bg-red-200 text-red-600"
              }`}
            >
              {row[k]}
            </span>
          </span>
        );
        break;
      case "evidence":
        cellHtml = (
          <span className="w-full flex justify-center">
            <DownloadIcon
              className="cursor-pointer fill-green-500"
              onClick={() => handleFileDownload(row[k])}
            />
          </span>
        );
        break;
      case "submitted_by":
      case "approved_by":
        cellHtml = (
          <span className="w-full flex justify-center">
            <img
              src={`${UserIcon}`}
              className="cursor-pointer w-[32px] h-[32px] border border-transparent rounded-[50%]"
              alt={`${k}`}
            />
          </span>
        );
        break;
      default:
        cellHtml = <span className="w-full flex justify-center">{row[k]}</span>;
        break;
    }
    return cellHtml;
  };
 
  useEffect(() => {
    if (modeOfTravelMapping && fuelType && subType && typeData) {
      let modeOfTravel = generatemodeOfTravelsData(
        modeOfTravelMapping,
        fuelType,
        subType,
        typeData
      );
      setmodeOfTravel(modeOfTravel);
    }
  }, [modeOfTravelMapping, fuelType, subType, typeData]);
  const handleFormChange = async (e) => {
    const { name, type, value } = e.target;

    if (type === "file") {
      const files = e.target.files;
      if (!files) {
        toast("Please select a file");
        return;
      }
      setfiles(files);
    } else if (name === "mode_of_travel" && modeOfTravelMapping) {
      let selected = modeOfTravelMapping?.find(
        (item) => item.mode_of_travel === value
      );
      setItemSelected(selected, modeOfTravelMapping);

      const filtered = modeOfTravel.find(
        (item) => item.mode_of_travel === value
      );
      dispatch(
        setscope3Category6Form({
          ...scope3Category6Form,
          [name]: value,
          travel_type:"",
          travel_subtype:"",
          air_conditioned: "",
          fuel_type:""
        })
      );
      dispatch(setfilteredTypeData(filtered.travel_type));
      dispatch(setSubtypeDataFiltred([]));
      dispatch(setfiltredFuelTypeData([]));
      setradiativeForcing([]);
    } else if (name === "travel_type" && modeOfTravelMapping) {
      dispatch(
        setscope3Category6Form({
          ...scope3Category6Form,
          [name]: value,
          travel_subtype:"",
          air_conditioned: "",
          fuel_type: ""
        })
      );
      const filtered = modeOfTravel?.find(
          (item) => item.mode_of_travel === scope3Category6Form.mode_of_travel
        )
        .travel_subtype.filter(
          (item) => Number(item.travel_type_id) === Number(value)
        )
        .map((i) => {
          return {
            ...i,
            id: i.travel_subtype_id,
          };
        });

      dispatch(setSubtypeDataFiltred(filtered));
      dispatch(setfiltredFuelTypeData([]));
      setradiativeForcing([]);
    } else if (
      name === "travel_subtype" &&
      modeOfTravelMapping &&
      scope3Category6Form.mode_of_travel
    ) {
      dispatch(
        setscope3Category6Form({
          ...scope3Category6Form,
          [name]: value,
          air_conditioned: "",
          fuel_type: ""
        })
      );
      const filtered = removeDuplicates(modeOfTravelMapping?.filter((item) => {
       
        if (
          Number(item.travel_subtype_id)===Number(value)&&
          item.mode_of_travel === scope3Category6Form.mode_of_travel &&
          Number(item.travel_type_id) ===
            Number(scope3Category6Form.travel_type)
        ) {
          return true;
        }
      })?.map(i=>{
        return {
          fuel_type:i.fuel_type,
          id:i.fuel_type_id
        }
      }),"id");
    
      setradiativeForcing([]);
      dispatch(setfiltredFuelTypeData(filtered));
    } else if (name === "fuel_type") {
      dispatch(
        setscope3Category6Form({
          ...scope3Category6Form,
          [name]: value,
          air_conditioned: ""
         
        })
      );
      const filtredType = modeOfTravelMapping
        .filter((item) => {
          if (
            Number(item.travel_subtype_id) ===
              Number(scope3Category6Form.travel_subtype) &&
            item.mode_of_travel === scope3Category6Form.mode_of_travel &&
            Number(item.fuel_type_id) === Number(value)
          ) {
            return true;
          }
        })
        .map((item) => {
          return {
            air_conditioned: item.air_conditioned,
          };
        });

      let radiativeForceData = [
        ...new Set(filtredType.map((i) => i.air_conditioned)),
      ];

      setradiativeForcing(radiativeForceData);
    } else {
      dispatch(
        setscope3Category6Form({ ...scope3Category6Form, [name]: value })
      );
    }
  };

  useEffect(() => {
    let transformedDataRows = scope3Category6InputRecords?.map((row) => {
      let transformedDataRow = [];
      transformedDataRow.push(
        <span className="w-full flex justify-center">
        <CopyIcon
          className={`${read_only ? 'cursor-not-allowed opacity-15' : 'cursor-pointer'}`}
          onClick={() => !read_only && handleCopy(row)}
        />
      </span>
      );
      Object.values(headingsToDataKeyMap).forEach((k) => {
        if (k.toString().trim() !== "") {
          transformedDataRow.push(generateCellHtml(row, k));
        }
      });
      transformedDataRow
        .push
        (
        //   <span className="w-full flex justify-center">
        //   <PencilIcon
        //     className="cursor-pointer"
        //     onClick={() => handleEdit(row)}
        //   />
        // </span>,
        //   <span className="w-full flex justify-center">
        //   <DeleteIcon
        //     className="cursor-pointer"
        //     onClick={() => handleDelete(row)}
        //   />
        // </span>
        )
      
      return transformedDataRow;
    });

    setDataRows(transformedDataRows);
  }, [scope3Category6InputRecords]);
  useEffect(() => {
    const initializeData = async () => {
      const filters = Object.fromEntries(searchParams.entries());
      await dispatch(fetchmodeOfTravelMappingData({ api }));
      await dispatch(fetchFuelTypeData({ api }));
      await dispatch(fetchTravelTypeData({ api }));
      await dispatch(fetchTravelSubTypeData({ api }));
      await dispatch(fetchExpenseUnitData({ api }));
      await dispatch(fetchscope3Category6InputData({ api,params:filters }));
      await dispatch(fetchSiteData({ api }));
    };

    initializeData();
  }, [api, dispatch]);

  return (
    <>
      <Header PageIcon={Scope3Icon} pageTitle={"Business Travel"} />

      <div className="flex flex-col main-container w-full px-10 py-6">
      <div className="flex  justify-between items-center text-xs">
          <div className="flex">
          
          </div>
          <div className="flex">
            <FilterComponent
              filters={filters}
              onFilterChange={handleFilterChange}
            />
          </div>
        </div>
        <div className="flex flex-col border border-gray-300 rounded-md mt-[10px] relative">
          <TableMeta
            recordType={1}
            totalCount={totalCount}
            openPopUpForm={openPopupForm}
            switchTab={switchTab}
            selectedTab={selectedTab}
          />
          {manualEntry && (
            <>
              <Table
                headings={Object.keys(headingsToDataKeyMap)}
                dataRows={dataRows}
                paginationObject={{
                  paginationEnabled: true,
                  currentPage: currentPage,
                  totalPages: totalPages,
                  itemsPerPage: itemsPerPage,
                  handleItemsPerPage: handleItemsPerPage,
                  handlePageChange: handlePageChange,
                }}
              />
             {!read_only&& <div className="flex  w-fit py-4 px-2 absolute bottom-8 right-6 cursor-pointer">
                <AddIconBig onClick={() => openPopupForm(1)} />
              </div>}
              <TablePagination
                paginationObject={{
                  paginationEnabled: true,
                  currentPage: currentPage,
                  totalPages: totalPages,
                  itemsPerPage: itemsPerPage,
                  handleItemsPerPage: handleItemsPerPage,
                  handlePageChange: handlePageChange,
                }}
              />
            </>
          )}
          {apiIntegration && (
            <>
              <h1>API Integration tab</h1>
            </>
          )}
          {trendLine && (
            <>
              <h1>TrendLine tab</h1>
            </>
          )}
        </div>
      </div>

      {isPurchaseFormOpen && (
        <Purchase_s3c6_Form
          files={files}
          handleFormChange={handleFormChange}
          modeOfTravel={modeOfTravel}
          itemSelected={itemselected}
          closePurchasePopupForm={closePurchasePopupForm}
          radiativeForceData={radiativeForcing}
        />
      )}
      {isEditCopyFormOpen && (
        <Purchases3c6EditAndCopyForm
          closePurchasePopupForm={() => setIsEditCopyFormOpen(false)}
          selectedRowData={selectedRowData}
          modeOfTravel={modeOfTravel}
          handleCloseEditCopyForms={handleCloseEditCopyForms}
          actionType={actionType}
        />
      )}
    </>
  );
};

export default Scope3Category6;
