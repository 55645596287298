import React from 'react'

function RetirementBenifitDiscloser({question}) {
    const data = [
        {
          benefit: 'PF',
          currentYear: {
            employeeCoverage: 'x%',
            workerCoverage: 'x%',
            deposited: 'Y',
          },
          previousYear: {
            employeeCoverage: 'x%',
            workerCoverage: 'x%',
            deposited: 'Y',
          },
        },
        {
          benefit: 'Gratuity',
          currentYear: {
            employeeCoverage: 'x%',
            workerCoverage: 'x%',
            deposited: 'N.A.',
          },
          previousYear: {
            employeeCoverage: 'x%',
            workerCoverage: 'x%',
            deposited: 'N.A.',
          },
        },
        {
          benefit: 'ESI',
          currentYear: {
            employeeCoverage: 'x%',
            workerCoverage: 'x%',
            deposited: 'Y',
          },
          previousYear: {
            employeeCoverage: 'x%',
            workerCoverage: 'x%',
            deposited: 'Y',
          },
        },
        {
          benefit: 'Others – please specify',
          currentYear: {
            employeeCoverage: 'x%',
            workerCoverage: 'x%',
            deposited: 'N',
          },
          previousYear: {
            employeeCoverage: 'x%',
            workerCoverage: 'x%',
            deposited: 'N',
          },
        },
      ];
  return (
    <div>
      <table className="min-w-full border border-gray-300 text-sm text-gray-700">
      <thead>
        <tr className="bg-gray-300">
          <th colSpan={7} className="border border-gray-300 px-4 py-2 text-start text-base font-semibold">
          {question}          </th>
        </tr>
        <tr className="bg-gray-100">
          <th className="border border-gray-300 px-4 py-2 text-center" rowSpan={2}>
            Benefits
          </th>
          <th className="border border-gray-300 px-4 py-2 text-center" colSpan={3}>
            Current Reporting Year
          </th>
          <th className="border border-gray-300 px-4 py-2 text-center" colSpan={3}>
            Previous Reporting Year
          </th>
        </tr>
        <tr className="bg-gray-100 ">
          <th className="border border-gray-300 px-4 py-2 text-pretty">No. of employees covered as a % of total employees</th>
          <th className="border border-gray-300 px-4 py-2 text-pretty">No. of workers covered as a % of total workers</th>
          <th className="border border-gray-300 px-4 py-2 text-pretty">Deducted and deposited with the authority (Y/N/N.A.)</th>
          <th className="border border-gray-300 px-4 py-2 text-pretty">No. of employees covered as a % of total employees</th>
          <th className="border border-gray-300 px-4 py-2 text-pretty">No. of workers covered as a % of total workers</th>
          <th className="border border-gray-300 px-4 py-2 text-pretty">Deducted and deposited with the authority (Y/N/N.A.)</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index} className="text-center">
            <td className="border border-gray-300 px-4 py-2">{item.benefit}</td>
            <td className="border border-gray-300 px-4 py-2">{item.currentYear.employeeCoverage}</td>
            <td className="border border-gray-300 px-4 py-2">{item.currentYear.workerCoverage}</td>
            <td className="border border-gray-300 px-4 py-2">{item.currentYear.deposited}</td>
            <td className="border border-gray-300 px-4 py-2">{item.previousYear.employeeCoverage}</td>
            <td className="border border-gray-300 px-4 py-2">{item.previousYear.workerCoverage}</td>
            <td className="border border-gray-300 px-4 py-2">{item.previousYear.deposited}</td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  )
}

export default RetirementBenifitDiscloser
