import React from 'react';
const SavedAlertPopup = ({ text, theme, icon,onClose }) => {
  console.log(text,theme)
  const themeStyles = {
    success: {
      bgColor: 'bg-[#EEFFF9]',
      textColor: 'text-green-600',
      borderColor: 'border-green-500'
    },
    error: {
      bgColor: 'bg-[#FFF6F6]',
      textColor: 'text-red-600',
      borderColor: 'border-red-500'
    },
   
  };

  const styles = themeStyles[theme] || themeStyles.success;

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
      <div className={`rounded-3xl w-[30vw] text-center px-4 py-5 shadow-md ${styles.bgColor} ${styles.borderColor}`}>
      <div className="flex  justify-end items-center ">
            
            <button
              className="  ml-auto bg-transparent flex items-center justify-center text-center  text-gray-500 float-right text-2xl leading-none font-semibold outline-none focus:outline-none"
              onClick={onClose}
            >
              <span className="bg-transparent flex items-center justify-center text-center text-gray-500 h-6 w-6 text-xl  outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
        {icon && (
          <div className="mb-3">
            <div className={`inline-block rounded-full`}>
              {icon}
            </div>
          </div>
        )}
        <span className={`text-2xl ${themeStyles[theme].textColor}`}>{text}</span>
      </div>
    </div>
  );
};

export default SavedAlertPopup;
