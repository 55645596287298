import { createAsyncThunk } from "@reduxjs/toolkit";
import { setIsLoading } from "../../../features/common/commonSlice";
import {
  setElecricityData,
  setElectricitySourceOptions,
  setFuelTypeOptions,
  setModuleOptions,
  setOverAllData,
  setSiteOptions,
  setSubModuleOptions,
  setTransactionTypeOptions,
  setUseTypeOptions,
  setdataForCurrentYearChange,
  setfuelData,
  setFinancialYear,
  setSupplierOptions,
  setItemOptions,
  setcategory1Data,
  setS3c1FinancialYear,
  sets3c1SiteOptions,
  setScope3Category2Data,
  setScope3Category2DataForCurrentYearChange,
  sets3c4SiteOptions,
  setScope3Category4Data,
  setS3c4FinancialYear,
  setScope3Category4DataForCurrentYearChange,
  sets3c9SiteOptions,
  setScope3Category9Data,
  setScope3Category9DataForCurrentYearChange,
  setModeOfTravelOptions,
  settravelTypeOptions,
  setScope3Category6Data,
  setS3c6FinancialYear,
  setScope3Category6DataForCurrentYearChange,
  settravelSubTypeOptions,
  sets3c6SiteOptions,
  setScope3Category7DataForCurrentYearChange,
  setScope3Category7Data,
  setS3c7FinancialYear,
  sets3c7SiteOptions,
  sets3c7pincodeOptions,
  setS3c9FinancialYear,
  setlogisticModeOptions,
} from "./chartSlice";

import { toast } from "react-toastify";
import { getOrganizationId } from "../../../components/common/parseJwt";

export const fetchChartDataThunk = createAsyncThunk(
  "chart/fetchData",
  async ({ api }, { getState, dispatch }) => {
    const organization_id = getOrganizationId();
    const apiUrl = `/energy/fuel/fuel-data/${organization_id}`;
    dispatch(setIsLoading(true));
    try {
      dispatch(setIsLoading(true));

      const response = await api.get(`${apiUrl}`);
      // console.log(response)
      const data = response?.data?.data.map((item) => {
        return {
          ...item,
          site: item?.site_master?.site,
          fuel_type: item?.FuelType?.fuel_type,
          use_type: item?.use_type_master?.use_type,
          source_type:
            item.source_type === "non-renewable"
              ? "Non Renewable"
              : item.source_type === "renewable"
              ? "Renewable"
              : item.source_type,
          year: Number(item.year),
          quarter: Number(item.quarter),
          usage_in_kwh: Number(item.usage_in_kwh),
          total_co2e_kg: Number(item.total_co2e_kg),
        };
      });

      dispatch(setIsLoading(false));

      const siteOptions = Array.from(new Set(data?.map((entry) => entry.site)));
      const financialYearOptions = Array.from(
        new Set(data?.map((entry) => entry.year))
      );
      const useTypeOptions = Array.from(
        new Set(data?.map((entry) => entry.use_type))
      );
      const fuelTypeOptions = Array.from(
        new Set(data?.map((entry) => entry.fuel_type))
      );

      dispatch(setdataForCurrentYearChange(data));
      dispatch(setfuelData(data));
      dispatch(setFuelTypeOptions(fuelTypeOptions));
      dispatch(setUseTypeOptions(useTypeOptions));
      dispatch(setSiteOptions(siteOptions));
      dispatch(setFinancialYear(financialYearOptions.sort((a, b) => b - a)));
    } catch (error) {
      dispatch(setIsLoading(false));
      console.error("Error fetching chart data:", error);
      toast.error(error?.response?.data?.message || "Error getting information");
      return error?.response?.data;
    }
  }
);
export const fetchChartElectricityDataThunk = createAsyncThunk(
  "chart/fetchChartElectricityDataThunk",

  async ({ api }, { getState, dispatch }) => {
    const organization_id = getOrganizationId();
    const apiUrl = `/energy/electricity/electricity-data/${organization_id}`;
    dispatch(setdataForCurrentYearChange([]));
    dispatch(setIsLoading(true));
    try {
      dispatch(setIsLoading(true));

      // const accessToken = getState().auth.accessToken;
      const response = await api.get(`${apiUrl}`);
      let mapeddata = response?.data?.data.map((item) => {
        // console.log(item.bill_date,new Date(item.bill_date).toISOString().split("T"))
        return {
          ...item,
          bill_date: new Date(item.bill_date).toISOString().split("T")[0],
          site: item.site_master.site,
          electricity_source: item.electricity_source?.electricity_source,
          year: parseFloat(item?.year),
          quarter: parseFloat(item?.quarter),
          usage_in_kwh: parseFloat(item?.usage_in_kwh).toFixed(6),
          total_co2e_kg: parseFloat(item?.total_co2e_kg).toFixed(6),
        };
      });

      function transformData(data) {
        return {
          month: data.month,
          quarter: data.quarter,
          year: data.year,
          site: data.site,
          electricity_source: data.electricity_source,
          source_type: data.source_type,
          transaction_type: data.transaction_type,
          usage_in_kwh: Number(data.usage_in_kwh),
          total_co2e_kg: Number(data.total_co2e_kg),
          // consumed_fuel:Number(data.consumed_value).toFixed()
        };
      }
      let dataTrans = mapeddata.map((item) => transformData(item));
      const financialYearOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.year))
      );
      const siteOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.site))
      );
      const useElectricitySource = Array.from(
        new Set(dataTrans?.map((entry) => entry.electricity_source))
      );
      const transactionTypeOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.transaction_type))
      );
      dispatch(setFinancialYear(financialYearOptions.sort((a, b) => b - a)));
      dispatch(setElectricitySourceOptions(useElectricitySource));
      dispatch(setTransactionTypeOptions(transactionTypeOptions));
      dispatch(setSiteOptions(siteOptions));

      dispatch(setdataForCurrentYearChange([...dataTrans].reverse()));
      dispatch(setElecricityData([...dataTrans].reverse()));


      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(setIsLoading(false));
      console.error("Error fetching chart data:", error);
      toast.error(error?.response?.data?.message || "Error getting information");
      return error?.response?.data;
    }
  }
);
export const fetchChartScope3DataThunk = createAsyncThunk(
  "chart/fetchChartScope3DataThunk",

  async ({ api }, { getState, dispatch }) => {
    const organization_id = getOrganizationId();
    const apiUrl = `/scope3/category1/data/${organization_id}`;

    dispatch(setdataForCurrentYearChange([]));
    dispatch(setIsLoading(true));
    try {
      dispatch(setIsLoading(true));

      const response = await api.get(`${apiUrl}`);
console.log(response)
      let mapeddata = response?.data?.data.map((item) => {
        return {
          ...item,
          total_co2e_kg: !item.total_kg_co2e_per_unit
            ? 0
            : parseFloat(item.total_kg_co2e_per_unit),
        };
      });

      function transformData(data) {
        return {
          amount_paid: data.amount_paid,
          month: data.month,
          quarter: Number(data.quarter),
          year: Number(data.year),
          supplier:
          data?.supplier?(data?.supplier?.toUpperCase() == "NA"||data?.supplier?.toUpperCase() == " " ? "other" : data.supplier):"other",
          item: data.item_name,
          site: data.site,
          country: data.country,
          production_type: data.production_type,
          total_co2e_kg: Number(data.total_co2e_kg),
        };
      }
      let dataTrans = mapeddata.map((item) => transformData(item));

      const financialYearOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.year))
      );
      const itemOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.item))
      );
      const supplierOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.supplier))
      );
      console.log(supplierOptions)
      const siteOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.site))
      );
      // console.log(supplierOptions,dataTrans)
      dispatch(setdataForCurrentYearChange(dataTrans));
      dispatch(sets3c1SiteOptions(siteOptions));
      dispatch(setSupplierOptions(supplierOptions));
      dispatch(setItemOptions(itemOptions));
      dispatch(setcategory1Data(dataTrans));
      dispatch(
        setS3c1FinancialYear(financialYearOptions.sort((a, b) => b - a))
      );
      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(setIsLoading(false));
      console.error("Error fetching chart data:", error);
      toast.error(error?.response?.data?.message || "Error getting information");
      return error?.response?.data;
    }
  }
);
export const fetchChartScope3Category2DataThunk = createAsyncThunk(
  "chart/fetchChartScope3Category2DataThunk",

  async ({ api }, { getState, dispatch }) => {
    const organization_id = getOrganizationId();
    const apiUrl = `/scope3/category2/data/${organization_id}`;

    // dispatch(setdataForCurrentYearChange([]))
    dispatch(setIsLoading(true));
    try {
      dispatch(setIsLoading(true));

      // const accessToken = getState().auth.accessToken;
      const response = await api.get(`${apiUrl}`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      let mapeddata = response?.data?.data.map((item) => {
        // let mapeddata = category2Data.map((item) => {
        return {
          ...item,
          total_co2e_kg: !item.total_kg_co2e_per_unit
            ? 0
            : parseFloat(item.total_kg_co2e_per_unit),
        };
      });
      // console.log(response)
      function transformData(data) {
        return {
          amount_paid: data.amount_paid,
          month: data.month,
          quarter: Number(data.quarter),
          year: Number(data.year),
          supplier:
          data?.supplier?(data?.supplier?.toUpperCase() == "NA" ? "other" : data.supplier):"other",
          item: data.item_name,
          site: data.site,
          country: data.country,
          // production_type: data.production_type,
          total_co2e_kg: Number(data.total_co2e_kg),
        };
      }
      let dataTrans = mapeddata.map((item) => transformData(item));
      // console.log(dataTrans,mapeddata)
      const financialYearOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.year))
      );
      const itemOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.item))
      );
      const supplierOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.supplier))
      );
      const siteOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.site))
      );
      // console.log({itemOptions,supplierOptions,siteOptions})
      dispatch(sets3c1SiteOptions(siteOptions));
      dispatch(setSupplierOptions(supplierOptions));
      dispatch(setItemOptions(itemOptions));
      dispatch(setScope3Category2Data(dataTrans));
      dispatch(
        setS3c1FinancialYear(financialYearOptions.sort((a, b) => b - a))
      );
      dispatch(setScope3Category2DataForCurrentYearChange(dataTrans));
      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(setIsLoading(false));
      console.error("Error fetching chart data:", error);
      toast.error(error?.response?.data?.message || "Error getting information");
      return error?.response?.data;
    }
  }
);
export const fetchChartScope3Category4DataThunk = createAsyncThunk(
  "chart/fetchChartScope3Category4DataThunk",

  async ({ api }, { getState, dispatch }) => {
    const organization_id = getOrganizationId();
    const apiUrl = `/scope3/category4/data/${organization_id}`;

   
    dispatch(setIsLoading(true));
    try {
      dispatch(setIsLoading(true));

      // const accessToken = getState().auth.accessToken;
      const response = await api.get(`${apiUrl}`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      let mapeddata = response?.data?.data.map((item) => {
        // let mapeddata = category2Data.map((item) => {
        return {
          ...item,
          // total_co2e_kg: !item.total_kg_co2e_per_unit?0:parseFloat(item.total_kg_co2e_per_unit),
        };
      });
      // console.log(response)
      function transformData(data) {
        return {
          ...data,
          amount_paid: data.amount_paid,
          year: Number(data.year) || new Date(data.date).getFullYear(),
          month: data.month || new Date(data.date).getMonth() + 1,
          quarter:
            Number(data.quarter) ||
            Math.floor((new Date(data.date).getMonth() + 3) / 3), // 3 + 1
          supplier:
          data?.supplier?(data?.supplier?.toUpperCase() == "NA" ? "other" : data.supplier):"other",
          item: data.item_carried,
          site: data.site,

          production_type: data.production_type,
          total_co2e_kg: Number(data.total_co2e_kg),
        };
      }
      let dataTrans = mapeddata.map((item) => transformData(item));
      // console.log(dataTrans,mapeddata)
      const financialYearOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.year))
      );
      const itemOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.item))
      );
      const supplierOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.supplier))
      );
      const siteOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.site))
      );
      const logisticModeOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.logistic_mode))
      );
      dispatch(setlogisticModeOptions(logisticModeOptions));
      dispatch(sets3c4SiteOptions(siteOptions));
      dispatch(setSupplierOptions(supplierOptions));
      dispatch(setItemOptions(itemOptions));
      dispatch(setScope3Category4Data(dataTrans));
      dispatch(
        setS3c4FinancialYear(financialYearOptions.sort((a, b) => b - a))
      );
      dispatch(setScope3Category4DataForCurrentYearChange(dataTrans));
      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(setIsLoading(false));
      console.error("Error fetching chart data:", error);
      toast.error(error?.response?.data?.message || "Error getting information");
      return error?.response?.data;
    }
  }
);
export const fetchChartScope3Category6DataThunk = createAsyncThunk(
  "chart/fetchChartScope3Category6DataThunk",

  async ({ api }, { getState, dispatch }) => {
    const organization_id = getOrganizationId();
    const apiUrl = `/scope3/category6/data/${organization_id}`;

    // dispatch(setdataForCurrentYearChange([]))
    dispatch(setIsLoading(true));
    try {
      dispatch(setIsLoading(true));

      // const accessToken = getState().auth.accessToken;
      const response = await api.get(`${apiUrl}`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      let mapeddata = response?.data?.data.map((item) => {
        // let mapeddata = category2Data.map((item) => {
        return {
          ...item,
          total_co2e_kg: !item.total_kg_co2e_per_unit
            ? 0
            : parseFloat(item.total_kg_co2e_per_unit),
        };
      });
      function transformData(data) {
        return {
          ...data,

      
          year: Number(data.year) || new Date(data.date).getFullYear(),
          month: data.month || new Date(data.date).getMonth() + 1,
          quarter:
            Number(data.quarter) ||
            Math.floor((new Date(data.date).getMonth() + 3) / 3), // 3 + 1
          total_co2e_kg: Number(data.total_co2e_kg),
        };
      }
      let dataTrans = mapeddata.map((item) => transformData(item));
      const financialYearOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.year))
      );
      const modeofTravel = Array.from(
        new Set(dataTrans?.map((entry) => entry.mode_of_travel))
      );
      const travelTypeOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.travel_type))
      );
      const travelSubTypeOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.travel_subtype))
      );
      const siteOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.site))
      );
      dispatch(sets3c6SiteOptions(siteOptions));
      dispatch(setModeOfTravelOptions(modeofTravel));
      dispatch(settravelTypeOptions(travelTypeOptions));
      dispatch(settravelSubTypeOptions(travelSubTypeOptions));
      dispatch(setScope3Category6Data(dataTrans));
      dispatch(
        setS3c6FinancialYear(financialYearOptions.sort((a, b) => b - a))
      );
      dispatch(setScope3Category6DataForCurrentYearChange(dataTrans));
      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(setIsLoading(false));
      console.error("Error fetching chart data:", error);
      toast.error(error?.response?.data?.message || "Error getting information");
      return error?.response?.data;
    }
  }
);
export const fetchChartScope3Category7DataThunk = createAsyncThunk(
  "chart/fetchChartScope3Category7DataThunk",

  async ({ api }, { getState, dispatch }) => {
    const organization_id = getOrganizationId();
    const apiUrl = `/scope3/category7/data/${organization_id}`;

    dispatch(setIsLoading(true));
    try {
      dispatch(setIsLoading(true));

      const response = await api.get(`${apiUrl}`);
      console.log(response)
      let mapeddata = response?.data?.data.map((item) => {
        return {
          ...item,
          total_co2e_kg: !item.total_kg_co2e_per_unit
            ? 0
            : parseFloat(item.total_kg_co2e_per_unit),
        };
      });
      function transformData(data) {
        return {
          ...data,

          year: Number(data.year) || new Date(data.date).getFullYear(),
          month: data.month || new Date(data.date).getMonth() + 1,
          quarter:
            Number(data.quarter) ||
            Math.floor((new Date(data.date).getMonth() + 3) / 3), // 3 + 1
          total_co2e_kg: Number(data.total_co2e_kg),
          agregated_total_kg_co2e_per_unit: Number(data.agregated_total_kg_co2e_per_unit),
        };
      }
      let dataTrans = mapeddata.map((item) => transformData(item));

      const financialYearOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.year))
      );
      const modeofTravel = Array.from(
        new Set(dataTrans?.map((entry) => entry.mode_of_travel))
      );
      const travelTypeOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.travel_type))
      );
      const travelSubTypeOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.travel_subtype))
      );
      const siteOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.site))
      );
      const pincodeOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.source_pincode))
      );
      dispatch(sets3c7SiteOptions(siteOptions));
      dispatch(sets3c7pincodeOptions(pincodeOptions));
      dispatch(setModeOfTravelOptions(modeofTravel));
      dispatch(settravelTypeOptions(travelTypeOptions));
      dispatch(settravelSubTypeOptions(travelSubTypeOptions));
      dispatch(setScope3Category7Data(dataTrans));
      dispatch(
        setS3c7FinancialYear(financialYearOptions.sort((a, b) => b - a))
      );
      dispatch(setScope3Category7DataForCurrentYearChange(dataTrans));
      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(setIsLoading(false));
      console.error("Error fetching chart data:", error);
      toast.error(error?.response?.data?.message || "Error getting information");
      return error?.response?.data;
    }
  }
);
export const fetchChartScope3Category9DataThunk = createAsyncThunk(
  "chart/fetchChartScope3Category9DataThunk",

  async ({ api }, { getState, dispatch }) => {
    const organization_id = getOrganizationId();
    const apiUrl = `/scope3/category9/data/${organization_id}`;

    // dispatch(setdataForCurrentYearChange([]))
    dispatch(setIsLoading(true));
    try {
      dispatch(setIsLoading(true));

      // const accessToken = getState().auth.accessToken;
      const response = await api.get(`${apiUrl}`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      let mapeddata = response?.data?.data.map((item) => {
        // let mapeddata = category2Data.map((item) => {
        return {
          ...item,
          // total_co2e_kg: !item.total_kg_co2e_per_unit?0:parseFloat(item.total_kg_co2e_per_unit),
        };
      });
      // console.log(response)
      function transformData(data) {
        return {
          ...data,
          amount_paid: data.amount_paid,
          year: Number(data.year) || new Date(data.date).getFullYear(),
          month: data.month || new Date(data.date).getMonth() + 1,
          quarter:
            Number(data.quarter) ||
            Math.floor((new Date(data.date).getMonth() + 3) / 3), // 3 + 1
          supplier:
          data?.supplier?(data?.supplier?.toUpperCase() == "NA" ? "other" : data.supplier):"other",
          item: data.item_carried,
          site: data.site,

          production_type: data.production_type,
          total_co2e_kg: Number(data.total_co2e_kg),
        };
      }
      let dataTrans = mapeddata.map((item) => transformData(item));
      const logisticModeOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.logistic_mode))
      );
      dispatch(setlogisticModeOptions(logisticModeOptions));
      const financialYearOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.year))
      );
      const itemOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.item))
      );
      const supplierOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.supplier))
      );
      const siteOptions = Array.from(
        new Set(dataTrans?.map((entry) => entry.site))
      );
      console.log(siteOptions);
      dispatch(sets3c9SiteOptions(siteOptions));
      dispatch(setSupplierOptions(supplierOptions));
      dispatch(setItemOptions(itemOptions));
      dispatch(setScope3Category9Data(dataTrans));
      dispatch(
        setS3c9FinancialYear(financialYearOptions.sort((a, b) => b - a))
      );
      dispatch(setScope3Category9DataForCurrentYearChange(dataTrans));
      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(setIsLoading(false));
      console.error("Error fetching chart data:", error);
      toast.error(error?.response?.data?.message || "Error getting information");
      return error?.response?.data;
    }
  }
);
export const fetchChartOverAllDataThunk = createAsyncThunk(
  "chart/fetchChartOverAllDataThunk",
  async ({ api }, { getState, dispatch }) => {
    const organization_id = getOrganizationId();
    const apiUrl = `/energy/${organization_id}/summary-data`;
    dispatch(setIsLoading(true));
    try {
      dispatch(setIsLoading(true));

      // const accessToken = getState().auth.accessToken;
      const response = await api.get(`${apiUrl}`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });
      // console.log(response)
      if (response.status == 200 && response.data.success) {
        const data = response.data.data.map((item) => {
          return transformData(item);
        });
        function transformData(data) {
          return {
            organization_id: parseInt(data.organization_id),
            module: data.ModuleMaster.module,
            sub_module: data?.SubModuleMaster?.sub_module,
            month: data.month,
            quarter: parseInt(data.quarter),
            year: parseInt(data?.year),
            distance_coverd: data.distance_coverd,
            source: data.source,
            site: data?.site_master?.site || "Other",
            usage_in_kwh: parseFloat(data?.usage_in_kwh) || 0,
            total_co2e_kg: parseFloat(data?.total_co2e_kg) || 0,
            source_type:
              data?.source_type === "non-renewable"
                ? "Non Renewable"
                : data?.source_type === "renewable"
                ? "Renewable"
                : data?.source_type || "others",
          };
        }

        dispatch(setIsLoading(false));

        const siteOptions = Array.from(
          new Set(data?.map((entry) => entry.site))
        );
        const useSubModuleOptions = Array.from(
          new Set(data?.map((entry) => entry.sub_module))
        );
        const moduleOptions = Array.from(
          new Set(data?.map((entry) => entry.module))
        );
        const financialYearOptions = Array.from(
          new Set(data?.map((entry) => entry.year))
        );
        dispatch(setFinancialYear(financialYearOptions.sort((a, b) => b - a)));
        dispatch(setdataForCurrentYearChange(data));
        dispatch(setOverAllData(data));
        dispatch(setSiteOptions(siteOptions));
        dispatch(setSubModuleOptions(useSubModuleOptions));
        dispatch(setModuleOptions(moduleOptions));
      }
    } catch (error) {
      dispatch(setIsLoading(false));
      console.error("Error fetching chart data:", error);
      toast.error(error?.response?.data?.message || "Error getting information");
      return error?.response?.data;
    }
  }
);
