import React from 'react';

const EmployeeUnionTable = ({question}) => {
  const data = [
    {
      category: 'Total Permanent Employees',
      currentYear: {
        totalEmployees: 'x',
        associationMembers: 'x',
        percentage: 'x',
      },
      previousYear: {
        totalEmployees: 'x',
        associationMembers: 'x',
        percentage: 'x',
      },
      materiality: 'High, Sector Dependent',
    },
    {
      category: 'Male Permanent Employees',
      currentYear: {
        totalEmployees: 'x',
        associationMembers: 'x',
        percentage: 'x',
      },
      previousYear: {
        totalEmployees: 'x',
        associationMembers: 'x',
        percentage: 'x',
      },
      materiality: 'High, Sector Dependent',
    },
    {
      category: 'Female Permanent Employees',
      currentYear: {
        totalEmployees: 'x',
        associationMembers: 'x',
        percentage: 'x',
      },
      previousYear: {
        totalEmployees: 'x',
        associationMembers: 'x',
        percentage: 'x',
      },
      materiality: 'High, Sector Dependent',
    },
    {
      category: 'Total Permanent Workers',
      currentYear: {
        totalEmployees: 'x',
        associationMembers: 'x',
        percentage: 'x',
      },
      previousYear: {
        totalEmployees: 'x',
        associationMembers: 'x',
        percentage: 'x',
      },
      materiality: 'High, Sector Dependent',
    },
    {
      category: 'Male Permanent Workers',
      currentYear: {
        totalEmployees: 'x',
        associationMembers: 'x',
        percentage: 'x',
      },
      previousYear: {
        totalEmployees: 'x',
        associationMembers: 'x',
        percentage: 'x',
      },
      materiality: 'High, Sector Dependent',
    },
    {
      category: 'Female Permanent Workers',
      currentYear: {
        totalEmployees: 'x',
        associationMembers: 'x',
        percentage: 'x',
      },
      previousYear: {
        totalEmployees: 'x',
        associationMembers: 'x',
        percentage: 'x',
      },
      materiality: 'High, Sector Dependent',
    },
  ];
  return (
    <table className="min-w-full border border-gray-300 text-gray-700">
      <thead className="bg-gray-100">

        <tr className="bg-gray-300">
          <th colSpan={9} className="border border-gray-300 px-4 py-2 text-start text-base font-semibold">
{question}          </th>
        </tr>
        <tr className="text-sm text-gray-700">
          <th rowSpan={2} className="border border-gray-300 px-4 py-2 text-center">
            Category
          </th>
          <th colSpan={3} className="border border-gray-300 px-4 py-2 text-center">
            Current Reporting Year (Current Financial Year)
          </th>
          <th colSpan={3} className="border border-gray-300 px-4 py-2 text-center">
            Previous Reporting Year (Previous Financial Year)
          </th>
          {/* <th rowSpan={2} className="border border-gray-300 px-4 py-2 text-center">
            MATERIALITY
          </th> */}
        </tr>
        <tr className="text-sm text-gray-700">
          <th className="border border-gray-300 px-4 py-2">Total Employees/Workers (A)</th>
          <th className="border border-gray-300 px-4 py-2">No. of employees / workers in respective category, who are part of association(s) or Union (B)</th>
          <th className="border border-gray-300 px-4 py-2">% (B/A)</th>
          <th className="border border-gray-300 px-4 py-2">Total Employees/Workers (C)</th>
          <th className="border border-gray-300 px-4 py-2">No. of employees / workers in respective category, who are part of association(s) or Union (D)</th>
          <th className="border border-gray-300 px-4 py-2">% (D/C)</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index} className="text-center text-sm text-gray-700 bg-white">
            <td className="border border-gray-300 px-4 py-2">{item.category}</td>
            <td className="border border-gray-300 px-4 py-2">{item.currentYear.totalEmployees}</td>
            <td className="border border-gray-300 px-4 py-2">{item.currentYear.associationMembers}</td>
            <td className="border border-gray-300 px-4 py-2">{item.currentYear.percentage}</td>
            <td className="border border-gray-300 px-4 py-2">{item.previousYear.totalEmployees}</td>
            <td className="border border-gray-300 px-4 py-2">{item.previousYear.associationMembers}</td>
            <td className="border border-gray-300 px-4 py-2">{item.previousYear.percentage}</td>
            {/* <td className="border border-gray-300 px-4 py-2">{item.materiality}</td> */}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default EmployeeUnionTable;
