import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "../../../app/assets/CloseIcon.svg";
import { useDispatch, useSelector } from "react-redux";

import {
  postscope3Category7Data,
  scope3Category7UploadEvidence,
} from "../../../features/scope3/category7/Scope3Category7Thunk";
import useApi from "../../../customHooks/useApi";
import { toast } from "react-toastify";

const Purchases3c7Form = ({
  handleFormChange,
  closePurchasePopupForm,
  financialYearData,
  formInput,modeOfTravel,
  files,
}) => {
  let {scope3Category7Form} = useSelector(
    (state) => state.scope3Category7
  );
  const api = useApi();
  const loading = useSelector((state) => state.common.isLoading);
  const dispatch = useDispatch();
  const {
    filteredTypeData,
    expenseUnit,
    filtredFuelTypeData,
    siteData,
    subTypeFiltred,
  } = useSelector((state) => state.scope3Category7);
  


  function getCurrentFinancialYear() {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1; 

    let startYear, endYear;

    if (currentMonth >= 4) {
        startYear = currentYear;
        endYear = currentYear + 1;
    } else { 
        startYear = currentYear - 1;
        endYear = currentYear;
    }

    return {startYear,endYear};
}
const financialYear = getCurrentFinancialYear();
const minDate = `${financialYear.startYear}-04-01`;
const maxDate = `${financialYear.endYear}-03-31`;
  
 
  const validatePincode = (pincode) => {
    const re = /^\d{6}$/;
    return re.test(String(pincode));
};

  const handlePurchaseFormSubmit = (e) => {
    e.preventDefault();

  if(!validatePincode(scope3Category7Form.pincode)){
    toast.error("Invalid pincode")
    return
  }
    const form = document.querySelector("#purchase-scope3Category7-form");
    if (form.length && form.checkValidity()) {
      if (files && !formInput) {
        dispatch(scope3Category7UploadEvidence({ files, api }))
          .then((result) => {
            if (result?.payload?.msg === "File uploaded.") {
              dispatch(postscope3Category7Data({ api })).then(() => {
                closePurchasePopupForm();
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
              }
    } else if (form.length) {
      form.querySelector('input[type="submit"]').click();
    }
  };
 return (
    <>
      <div className="mainContainer flex w-full h-screen overflow-hidden justify-center items-center z-[100] fixed bg-black bg-opacity-50 top-0 left-0 bottom-0">
        <div className="flex flex-col justify-center items-center w-9/12 text-xs bg-white absolute z-[1000] border border-transparent rounded-t-md">
          <div className="flex justify-between w-full bg-gray-50 py-2 px-4 border-b border-gray-300 mb-3">
            <span className="cursor-pointer"> Employee Commute</span>
            <span className="cursor-pointer" onClick={closePurchasePopupForm}>
              <CloseIcon />
            </span>
          </div>
        
            <form
              style={{
                opacity: loading ? 0.5 : 1,
                pointerEvents: loading ? "none" : "auto",
              }}
              id="purchase-scope3Category7-form"
              className="flex flex-col w-full py-4"
              onSubmit={handlePurchaseFormSubmit}
            >
              <div className="grid grid-cols-4 gap-4 px-8 mb-4 justify-around">
              <div className="col-span-1 flex flex-col">
                <label htmlFor="joining_date" className="text-xs py-1.5">
                Joining date 
                </label>
                <input
                  // max={maxDate}
                  // min={minDate}
                  max={new Date().toISOString().split("T")[0]}
                  type="date"
                  name="joining_date"
                  id="joining_date"
                  value={scope3Category7Form?.joining_date || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="pincode" className="text-xs py-1.5">
                Pincode travelling from  <span className="text-red-500">*</span>
                </label>
                <input
                  maxLength={6}
                  type="text"
                  name="pincode"
                  id="pincode"
                  value={scope3Category7Form?.pincode || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="site_id" className="text-xs py-1.5">
                Facility travelling to <span className="text-red-500">*</span>
                </label>
                <select
                  name="site_id"
                  value={scope3Category7Form.site_id}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="">Choose the Facility</option>
                  {siteData &&
                    siteData.map((site) => (
                      <option key={site.id} value={site.id}>
                        {site.site}
                      </option>
                    ))}
                </select>
              </div>
                <div className="col-span-1 flex flex-col">
                  <label htmlFor="date" className="text-xs py-1.5">
                    Financial Year<span className="text-red-500">*</span>
                  </label>
                  <select
                    name="financial_year"
                    value={scope3Category7Form.financial_year}
                    onChange={handleFormChange}
                    className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                    required
                  >
                    <option value="">Choose the Financial Year</option>
                    {financialYearData &&
                      financialYearData.map((financial_year) => (
                        <option key={financial_year} value={financial_year}>
                          {financial_year}
                        </option>
                      ))}
                  </select>
                </div>
               
                <div className="flex flex-col">
                  <label htmlFor="employee_id" className="text-xs py-1.5">
                    Employee ID <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="employee_id"
                    value={scope3Category7Form.employee_id}
                    onChange={handleFormChange}
                    className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                    required
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="number_of_working_days" className="text-xs py-1.5">
                    Number of working days per week <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    min={1}
                    max={7}
                    name="number_of_working_days"
                    value={scope3Category7Form.number_of_working_days}
                    onChange={handleFormChange}
                    className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                    required
                  />
                </div>
                <div className="col-span-1 flex flex-col">
                <label htmlFor="mode_of_travel" className="text-xs py-1.5">
                  Mode of Travel<span className="text-red-500">*</span>
                </label>
                <select
                  name="mode_of_travel"
                  value={scope3Category7Form.mode_of_travel}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="">Choose the Mode of Travel</option>
                  {modeOfTravel &&
                    modeOfTravel.map((mode) => (
                      <option
                        key={mode.id + "_" + mode.mode_of_travel}
                        value={mode.mode_of_travel}
                      >
                        {mode.mode_of_travel}
                      </option>
                    ))}
                </select>
              </div>
              <div className="flex flex-col">
                <label htmlFor="type" className="text-xs py-1.5">
                  Type<span className="text-red-500">*</span>
                </label>
                <select
                  name="travel_type"
                  value={scope3Category7Form.travel_type}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="">Choose the Type</option>
                  {filteredTypeData?.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.travel_type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col">
                <label htmlFor="travel_subtype" className="text-xs py-1.5">
                  Sub Type<span className="text-red-500">*</span>
                </label>
                <select
                  name="travel_subtype"
                  value={scope3Category7Form.travel_subtype}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="">Select Sub Type</option>
                  {subTypeFiltred &&
                    subTypeFiltred?.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.travel_subtype}
                        </option>
                      );
                    })}
                </select>
              </div>
             
              <div className="flex flex-col">
                <label htmlFor="fuel_type" className="text-xs py-1.5">
                  Fuel Type<span className="text-red-500">*</span>
                </label>
                <select
                  name="fuel_type"
                  value={scope3Category7Form.fuel_type}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="">Choose the Fuel type</option>
                  {filtredFuelTypeData?.map((type,i) => (
                    <option key={type.id+"_"+i} value={type.id}>
                      {type.fuel_type}
                    </option>
                  ))}
                </select>
              </div>
              
                <div className="flex flex-col">
                  <label htmlFor="distance" className="text-xs py-1.5">
                    Distance (km)<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    name="distance"
                    step="0.0001"
                    value={scope3Category7Form.distance}
                    onChange={handleFormChange}
                    className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                    required
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="expense" className="text-xs py-1.5">
                    Expense<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    name="expense"
                    step="0.0001"
                    value={scope3Category7Form.expense}
                    onChange={handleFormChange}
                    className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                    required
                  />
                </div>
                <div className="col-span-1 flex flex-col">
                  <label htmlFor="expense_unit" className="text-xs py-1.5">
                    Expense Unit<span className="text-red-500">*</span>
                  </label>
                  <select
                    name="expense_unit"
                    value={scope3Category7Form?.expense_unit || ""}
                    onChange={handleFormChange}
                    className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  >
                    <option value="" disabled>
                      Choose the Expense unit
                    </option>
                    {expenseUnit.map((expense_unit) => (
                      <option key={expense_unit.id} value={expense_unit.id}>
                        {expense_unit.unit}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-span-1 flex flex-col">
                  <label htmlFor="evidence" className="text-xs py-1.5">
                    Evidence<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="file"
                    name="evidence"
                    id="evidence"
                    multiple
                    accept=".jpg, .jpeg, .png, .pdf, .zip"
                    onChange={handleFormChange}
                    className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                    required
                  />
                </div>
              </div>
              <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
                <div className="w-1/6 flex flex-col">
                  <button
                    type="button"
                    disabled={loading}
                    className="py-2 px-6 text-green-600 text-sm rounded-md"
                    onClick={closePurchasePopupForm}
                  >
                    Cancel
                  </button>
                </div>
                <div className="w-1/6 flex flex-col">
                  <button
                    type="submit"
                    disabled={loading}
                    className={`py-2 px-6 text-white text-sm ${
                      loading ? "bg-green-500 cursor-wait" : "bg-green-600"
                    } rounded-md`}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          
        </div>
      </div>
    </>
  );
};

export default Purchases3c7Form;
