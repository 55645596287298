// /features/auth/authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

// Assume you have thunks for login and logout (similar to the previous example)

const authSlice = createSlice({
  name: "auth",
  initialState: {
    firstLogin: localStorage.getItem("firstLogin")
      ? localStorage.getItem("firstLogin")
      : localStorage.getItem("accessToken")
      ? jwtDecode(localStorage.getItem("accessToken")).user.firstLogin
      : false,
    user: localStorage.getItem("accessToken")
      ? jwtDecode(localStorage.getItem("accessToken")).user
      : {},
    isAuthenticated: localStorage.getItem("accessToken") ? true : false,
    accessToken: localStorage.getItem("accessToken"),
    refreshToken: localStorage.getItem("refreshToken"),
    mandatoryStepsCompleted: false,
    mandatoryStep: true,
    role_type: localStorage.getItem("accessToken")
      ? jwtDecode(localStorage.getItem("accessToken"))?.member?.role_type
      : "",
    mandatoryStepData: {},
    permissionsConfig:localStorage.getItem("accessToken")
    ? jwtDecode(localStorage.getItem("accessToken"))?.member?.access: {},
    read_only: localStorage.getItem("accessToken")
      ? jwtDecode(localStorage.getItem("accessToken"))?.member?.access
          ?.data_entry?.read_only
      : false,
    leftSideBarScreenShow: false,
    country: [
      { id: 1, country: "India", sortName: "AF" },
      { id: 2, country: "UnitedState", sortName: "US" },
    ],
  },
  reducers: {
    setTokens: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.isAuthenticated = true;
      localStorage.setItem("accessToken", action.payload.accessToken);
      localStorage.setItem("refreshToken", action.payload.refreshToken);

      state.user = localStorage.getItem("accessToken")
        ? jwtDecode(localStorage.getItem("accessToken"))?.user
        : {};
      state.role_type = localStorage.getItem("accessToken")
        ? jwtDecode(localStorage.getItem("accessToken"))?.member?.role_type
        : "";
      state.read_only = localStorage.getItem("accessToken")
        ? jwtDecode(localStorage.getItem("accessToken"))?.member?.access
            ?.data_entry?.read_only
        : state.read_only;
      state.firstLogin = localStorage.getItem("firstLogin")
        ? localStorage.getItem("firstLogin")
        : localStorage.getItem("accessToken")
        ? jwtDecode(localStorage.getItem("accessToken")).user.firstLogin
        : false;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
      console.log(jwtDecode(action.payload.accessToken)?.member);
      state.read_only = jwtDecode(
        action.payload.accessToken
      )?.member?.access?.data_entry?.read_only;
      localStorage.setItem("accessToken", action.payload.accessToken);
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
      localStorage.setItem("refreshToken", action.payload.refreshToken);
    },
    setfirstLogin: (state, action) => {
      state.firstLogin = action.payload;
    },
    setroleType: (state, action) => {
      state.role_type = action.payload;
    },
    setleftSideBarScreenShow: (state, action) => {
      state.leftSideBarScreenShow = action.payload;
    },
    setmandatoryStepsCompleted: (state, action) => {
      state.mandatoryStepsCompleted = action.payload;
    },
    setmandatoryStep: (state, action) => {
      state.mandatoryStep = action.payload;
    },
    setmandatoryStepData: (state, action) => {
      state.mandatoryStepData = action.payload;
    },
    clearTokens: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
      localStorage.clear();
    },
  },
});

export const {
  setTokens,
  setmandatoryStep,
  setmandatoryStepsCompleted,
  setmandatoryStepData,
  clearTokens,
  setAccessToken,
  setroleType,
  setRefreshToken,
  setleftSideBarScreenShow,
  setfirstLogin,
} = authSlice.actions;
export default authSlice.reducer;
