


import React, { useState } from 'react';
import GenericForm from './GenericForm';
import DataDisplay from '../../../pages/BRSR/BRSRContent/DataView/DataDisplay';
import useApi from '../../../customHooks/useApi';
import { useDispatch, useSelector } from 'react-redux';
import { postSocialEmpWellBeingInputs } from '../../../features/social/socialThunk';

const EmployeeWellBeingForm = ({closePopup}) => {
  const [selectedTab, setSelectedTab] = useState('form');
  const [formData,setFormData]=useState({})
  const {employementType,siteData,financialYearData}=useSelector(state=>state.social)
  const api=useApi()
  const dispatch=useDispatch()
  const employeeWellBeingConfig = {
    title: 'Employee Well-being Form',
    financialYearOptions: financialYearData, // Example years, you can modify them as needed
    siteOptions: siteData, // Add site options here if needed
    isSiteOption:false,
    sections: [
      {
        key: 'wellBeingMetrics',
        label: 'Employee Well-being Metrics',
        rows: [
          { key: 'life_insurance', label: 'Employees covered by Life Insurance' },
          { key: 'disability_coverage', label: 'Employees covered by Disability and Invalidity Coverage' },
          { key: 'stock_ownership', label: 'Employees covered by Stock Ownership' },
          { key: 'health_insurance', label: 'Employees covered by Health Insurance' },
          { key: 'accident_insurance', label: 'Employees covered by Accident Insurance' },
          { key: 'maternity_benefits', label: 'Employees covered by Maternity Benefits' },
          { key: 'paternity_benefits', label: 'Employees covered by Paternity Benefits' },
          { key: 'day_care_facilities', label: 'Employees covered by Day Care Facilities' },
        ],
        fields: [
          { key: 'male', label: 'Male', type: 'number' },
          { key: 'female', label: 'Female', type: 'number' },
          { key: 'other', label: 'Other', type: 'number' },
          { key: 'not disclosed', label: 'Not Disclosed', type: 'number' },
        ],
      },
    ],
  };
  
  function handleSubmitData(data) {
 
    setFormData(data) 
    dispatch(postSocialEmpWellBeingInputs({api,formData:{...data,employement_category:employementType}}))
    closePopup()
  }
  const renderTab = () => {
    switch (selectedTab) {
      case "form":
        return <GenericForm config={employeeWellBeingConfig} handleSubmitData={handleSubmitData} />;
      case "display":
        return <DataDisplay config={employeeWellBeingConfig} data={formData}/>;
      default:
        return null;
    }
  };
  return (
    <div className='h-full w-full p-2 px-6 overflow-auto'>
        {renderTab()}
    </div>
  );
};

export default EmployeeWellBeingForm;

