import React, { useEffect, useState } from "react";
import ChartComponent from "./ChartComponents";
import StackedBarChart from "./StackedBarChart";
import PieChart from "./PieChart";
import {
  calculateRenewableEnergyData,
  formatText,
  generatePieChartOptions,
  generateSeriesDataForStackedChart,
  generateStackedChartOptions,
  processStackedChartData,
} from "../Redux/Processdata";

function Main({
  width,
  height,
  filterBy,
  data,
  chartDataArray,
  Options,
  StackBreakDown,
  currentFinancialYear,
}) {
  const [filtredDataByGlobleFilter, setfiltredDataByGlobleFilter] = useState(
    []
  );
  const [typeBreakdown, setTypeBreakdown] = useState("");

  const [options, setOptions] = useState(null); // Initialize options as null

  const updateOptions = (filteredData) => {
    if (filteredData.length > 0) {
      const processed = processStackedChartData(
        filteredData,
        currentFinancialYear,
        typeBreakdown
      );

      const renewableEnergyData = calculateRenewableEnergyData(
        filteredData,
        currentFinancialYear
      );
      const { series, legends } = generateSeriesDataForStackedChart(
        processed,
        renewableEnergyData
      );
      const xAxisData = Object.keys(processed);
      const updatedOptions = generateStackedChartOptions(
        filteredData,
        currentFinancialYear,
        typeBreakdown,
        legends,
        xAxisData,
        series
      );
      setOptions(updatedOptions);
    } else {
      // Handle case where filtered data is empty
      setOptions(null);
    }
  };

  const handleTypeBreakdownChange = (e) => {
    setTypeBreakdown(e.target.value);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const filteredData = data.filter((entry) => {
        for (let key in filterBy) {
          if (filterBy[key] && entry[key] !== filterBy[key]) {
            return false;
          }
        }
        return true;
      });
      setfiltredDataByGlobleFilter(filteredData);
      updateOptions(filteredData);
    }
  }, [data, filterBy, currentFinancialYear]);

  useEffect(() => {
    if (filtredDataByGlobleFilter.length > 0) {
      updateOptions(filtredDataByGlobleFilter);
    }
  }, [filtredDataByGlobleFilter, typeBreakdown, data, currentFinancialYear]);

  if (!Array.isArray(data) || data.length === 0) {
    return <div>No data available for Charts</div>;
  }

  return (
    <div className="px-5">
      <div className="border rounded-md mt-[18px] px-2 py-2 w-full shadow-md">
        <ChartComponent
          height={height}
          width={width}
          BreakDownOptions={Options}
          data={filtredDataByGlobleFilter}
        />
      </div>

      <div className="grid grid-cols-2 gap-2 w-full py-3">
        {chartDataArray.length &&
          chartDataArray
            .map((item) =>
              generatePieChartOptions(
                item.title,
                filtredDataByGlobleFilter,
                item.dataKey,
                Number(currentFinancialYear)
              )
            )
            ?.map((options, index) => (
              <div
                key={options?.title?.text + index}
                className="border rounded-md mt-[18px] px-2 py-2 w-full shadow-md"
              >
                <PieChart option={options} />
              </div>
            ))}
      </div>

      <div className="border rounded-md mt-[18px] px-2 py-2 w-full shadow-md">
        <div className=" w-full flex justify-end px-3 gap-3 items-center">
          <label htmlFor="breakpoint">Breakdown :</label>
          <select
            name="breakpoint"
            value={typeBreakdown}
            onChange={handleTypeBreakdownChange}
            className=" py-2 px-4 ml-1 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm "
          >
            <option value="">No Breakdown</option>
            {StackBreakDown.map((option, index) => (
              <option key={`${option}_${index}`} value={option}>
                {formatText(option)}
              </option>
            ))}
          </select>
        </div>
        {options ? ( // Check if options is not null before rendering StackedBarChart
          <StackedBarChart options={options} />
        ) : (
          <div>No data available for Stacked Bar Chart</div>
        )}
      </div>
    </div>
  );
}

export default Main;
