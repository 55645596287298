import React from "react";
import {ReactComponent as CalenderIcon} from "../../../../app/assets/CalenderIcon.svg"
import {ReactComponent as SitesIcon} from "../../../../app/assets/SitesIcon.svg"
const GenericTiles = ({ data, categoryHeaders, dataKey }) => {

  return (
    <div className="container mx-auto space-y-8 p-3">
      {/* Iterate over years */}
      {Object.keys(data).map((year) => (
        <div key={year} className="space-y-8 ">
          {/* Year Header */}

          {/* Iterate over sites */}
          {Object.keys(data[year]).map((site) => (
            <div key={site} className="space-y-4  border border-gray-300 p-3 rounded">
             

         
<div className="flex justify-start gap-5 border-b border-gray-300 pb-4 ">
<div className="flex items-center gap-2 justify-center">
                {/* <SitesIcon className="w-6 h-6" /> */}
            <CalenderIcon className="w-5 h-5" />
                <label>Financial Year</label>
          <div className="flex items-center gap-2 justify-center border bg-white p-1 px-2 rounded">
            <div className="flex items-center gap-2">
              <select
                className="font-semibold  text-center"
                disabled
              >
                <option selected>{` ${year}`}</option>
              </select>
            </div>
            </div>
            </div>
            {site && (
              <div className="flex items-center gap-2 justify-center">
                <SitesIcon className="w-6 h-6" />
                <label>Facility</label>
                 <div className="flex items-center gap-2 justify-center border bg-white p-1 px-2 rounded w-24">
                <select
                  className="font-semibold  text-center w-full"
                  disabled
                >
                  <option selected>{` ${site}`}</option>
                </select>
              </div>
              </div>
             
            )}
          </div>
          
              {/* Table */}
              <table className="w-full table-auto border-collapse border border-gray-400">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="border border-gray-300 p-2">{dataKey}</th>
                    {categoryHeaders.map((header) => (
                      <th key={header} className="border border-gray-300 p-2 text-center">
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {/* Iterate over employee types */}
                  {Object.keys(data[year][site]).map((employeeType) => (
                    <tr key={employeeType} className="hover:bg-gray-100">
                      <td className="border border-gray-300 p-2 font-medium">
                        {employeeType.replace(/_/g, " ").replace(/^(.)/, ($1) => $1.toUpperCase())}
                      </td>
                      {categoryHeaders.map((header) => (
                        <td key={`${employeeType}-${header}`} className="border border-gray-300 p-2 text-center">
                          {data[year][site][employeeType][header.toLowerCase()] || 0}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default GenericTiles;

