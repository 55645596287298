import React from 'react';

const HealthAndSafetyAssessmentTable = ({question}) => {
  const data = [
    {
      category: 'Health and safety practices',
      assessmentPercentage: 'x%', // Replace 'x%' with actual data if available
    },
    {
      category: 'Working conditions',
      assessmentPercentage: 'x%', // Replace 'x%' with actual data if available
    },
  ];
  return (
    <table className="min-w-full border border-gray-300  text-gray-700 text-sm">
      <thead >
        <tr  className="bg-gray-50 font-semibold text-base" >
        
          <th colSpan={3} className="border border-gray-300 px-4 py-2 bg-gray-300">{question}</th>
        </tr>
        <tr className="bg-gray-100">
          <th className="border border-gray-300 px-4 py-2 w-1/4"></th>
          <th className="border border-gray-300 px-4 py-2">% of plants and offices that were assessed (by the entity or statutory authorities or third parties)</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index} className="text-center bg-white">
            <td className="border border-gray-300 px-4 py-2">{item.category}</td>
            <td className="border border-gray-300 px-4 py-2">{item.assessmentPercentage}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default HealthAndSafetyAssessmentTable;
