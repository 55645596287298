import { ReactComponent as CloseIcon } from "../../../app/assets/CloseIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { postScope3Data } from "../../../features/scope3/category1/Scope3Thunk";
import { postSitesData } from "../Redux/GeneralThunk";
import useApi from "../../../customHooks/useApi";
import { resetFormData } from "../Redux/GeneralSlices";

const SIteDetailsInsertDataForm = ({
  handleFormChange,
  handleCloseForm,
}) => {
  const { formData,ownershipsStatusData, siteTypeData } = useSelector((state) => state.general);
  const loading = useSelector((state) => state.common.isLoading);
  const dispatch = useDispatch();
const api=useApi()
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = document.querySelector("#insert-data-form");
    if (form.length && form.checkValidity()) {
        dispatch(postSitesData({api}));
      handleCloseForm();
    } else if (form.length) {
      form.querySelector('input[type="submit"]').click();
    }
  };
 useEffect(()=>{
  dispatch(resetFormData())
 },[])
  return (
    <>
      <div className="mainContainer flex w-full h-screen overflow-hidden justify-center items-center z-[100] fixed bg-black bg-opacity-50 top-0 left-0 bottom-0">
        <div className="flex flex-col justify-center items-center w-9/12 text-xs bg-white absolute z-[1000] border border-transparent rounded-t-md">
          <div className="flex justify-between w-full bg-gray-50 py-2 px-4 border-b border-gray-300 mb-3">
            <span className="cursor-pointer">Site Details Input</span>
            <span className="cursor-pointer" onClick={handleCloseForm}>
              <CloseIcon />
            </span>
          </div>
          <form
            style={{ opacity: loading ? 0.5 : 1, pointerEvents: loading ? 'none' : 'auto' }}
            id="insert-data-form"
            className="flex flex-col w-full py-4"
            onSubmit={handleFormSubmit}
          >
            <div className="grid grid-cols-4 gap-4 px-8 mb-4 justify-around">
            
              {/* <div className="col-span-1 flex flex-col">
                <label htmlFor="organisation" className="text-xs py-1.5">
                Level 1<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  disabled
                  name="organisation"
                  id="organisation"
                  value={1}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div> */}
              <div className="col-span-1 flex flex-col">
                <label htmlFor="level_2" className="text-xs py-1.5">
                Level 2<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="level_2"
                  id="level_2"
                  value={formData?.level_2 || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="level_3" className="text-xs py-1.5">
                Level 3<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="level_3"
                  id="level_3"
                  value={formData?.level_3 || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="site" className="text-xs py-1.5">
                Facility<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="site"
                  id="site"
                  value={formData?.site || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="facility_type" className="text-xs py-1.5">
                Facility Type<span className="text-red-500">*</span>
                </label>
                <select
                  name="facility_type"
                  value={formData?.facility_type || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="" >
                    Choose the facility Type
                  </option>
                  {siteTypeData && siteTypeData.map(item => <option key={item.id} value={item.site_type}>{item.site_type}</option>)}
                </select>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="area" className="text-xs py-1.5">
                  Area (Sq meters) <span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  name="area"
                  id="area"
                  value={formData?.area || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="ownership_status" className="text-xs py-1.5">
                  Ownership Status<span className="text-red-500">*</span>
                </label>
                <select
                  name="ownership_status"
                  value={formData?.ownership_status || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="" disabled>
                    Choose the Ownership Status
                  </option>
                  {ownershipsStatusData && ownershipsStatusData.map(item => <option key={item.id} value={item.ownership_status}>{item.ownership_status}</option>)}
                </select>
              </div>
              {/* <div className="col-span-1 flex flex-col ">
                <label htmlFor="lat_lng" className="text-xs py-1.5">
                  Google Map Location<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="lat_lng"
                  id="lat_lng"
                  value={formData?.lat_lng || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="country" className="text-xs py-1.5">
                  Country<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="country"
                  id="country"
                  value={formData?.country || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="state" className="text-xs py-1.5">
                  State<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  value={formData?.state || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="city" className="text-xs py-1.5">
                  City<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="city"
                  id="city"
                  value={formData?.city || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div> */}
              {/* <div className="col-span-1 hidden flex-col">
                <label htmlFor="data_entrant" className="text-xs py-1.5">
                  Data Entrant<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="data_entrant"
                  id="data_entrant"
                  value={formData?.data_entrant || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="col-span-1  flex-col hidden">
                <label htmlFor="data_approver" className="text-xs py-1.5">
                  Data Approver<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="data_approver"
                  id="data_approver"
                  value={formData?.data_approver || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div> */}
            </div>
            <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
              <div className="w-1/6 flex flex-col">
                <button
                  type="button"
                  disabled={loading}
                  className="py-2 px-6 text-green-600 text-sm rounded-md"
                  onClick={handleCloseForm}
                >
                  Cancel
                </button>
              </div>
              <div className="w-1/6 flex flex-col">
                <button
                  type="submit"
                  disabled={loading}
                  className={`py-2 px-6 text-white text-sm ${loading ? 'bg-green-500 cursor-wait' : 'bg-green-600'} rounded-md`}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SIteDetailsInsertDataForm;
