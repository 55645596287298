import React, { useState } from "react";

const Tooltip = ({ message, children }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className=" relative flex items-center">
      {/* Element with tooltip */}
      <div
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
        className="cursor-pointer"
      >
        {children}
      </div>

      {/* Tooltip */}
      {visible && (
        <div
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
          className={`absolute bottom-full w-[50vw] flex max-h-36 right-4 mb-0 px-3 py-1 rounded-md bg-gray-900 text-[#fff] text-xs font-thin 
          transition-all duration-300 ease-in-out flex-wrap transform ${visible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-2'}`}
        //   style={{ whiteSpace: "nowrap" }}
        >
          {message}
          {/* <div className="absolute top-full left-full transform -translate-x-1/2 w-2 h-2 bg-gray-700 rotate-45"></div> */}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
