import React, { useEffect, useState } from 'react'
import Header from '../../../components/common/Header'
import AnalyzeHeader from '../../../components/Analyze/AnalyzeHeader'

import { useDispatch, useSelector } from 'react-redux'
import Filter from '../../VisualizationFeature/components/Filter'
import { ReactComponent as FilterMenuIcon } from "../../../app/assets/FilterMenuIcon.svg";
import { ReactComponent as EmissionIcon } from "../../../app/assets/GreenhouseEffectEmissions.svg";
import { fetchChartOverAllDataThunk } from '../../VisualizationFeature/Redux/chartthunk'
import { calculateEnergyUsageChange, calculateRenewableEnergyUsageChange, currentYearLastYearEmissionDetail, getCurrentFinancialYear } from '../../VisualizationFeature/Redux/Processdata'
import MainOverAll from '../../VisualizationFeature/components/MainOverAll'
import useApi from '../../../customHooks/useApi'





function Emissions() {
  const api=useApi()
  const dispatch = useDispatch();
  const {siteOptions,overAllData,dataForCurrentYearChange,sub_moduleOptions,moduleOptions,financialYear}=useSelector(state=>state.chart)
  const [emissionChange,setEmissonChange]=useState({})
  const [renewableEnergyChange,setrenewableEnergy]=useState({})
  const [EnergyChange,setEnergyChange]=useState({})
  const [currentFinancialYear, setCurrentFinancialYear] = useState(financialYear.length ? financialYear[0] : '');

    const [chartDataArray] = useState([
      { title: "Emissions contribution by Scope", dataKey: "module" },
      { title: "Emissions contribution in Scope 1", dataKey: "sub_module" },// sequence matter
      { title: "Emissions contribution in Scope 2", dataKey: "sub_module" },
      { title: "Emissions contribution in Scope 3", dataKey: "sub_module" },
      { title: "Emissions contribution by Facility", dataKey: "site" },
      { title: "Energy Usage", dataKey: "source_type" },
    ]);
    const [BreakDownOptions] = useState([

      { value: "module", label: "Module" },
      { value: "site", label: "Facility" },
      { value: "sub_module", label: "Sub Module" },

    ]);
    const [StackBreakDown] = useState(["sub_module", "module"]);
    const [filterBy, setFilterBy] = useState({
      site: '',
      fuel_type: '',
      use_type: ''
    });
    const handleFilterChange = (name, value) => {
      if(name==="financialYear"){
        setCurrentFinancialYear(value)
      }else{
        setFilterBy(prevFilterBy => ({
          ...prevFilterBy,
          [name]: value
        }));
  
      }
    };
    const emissionData = [
      {
        title: "Emissions",
        info: !isNaN(emissionChange?.currentYear) ? `${emissionChange?.currentYear || 0} Kg Co2e` : "0 Kg Co2e",
        time: "",
        status: !isNaN(emissionChange?.percentageChange) && (emissionChange?.percentageChange || 0) > 0 ? "positive" : "negative",
        per: !isNaN(emissionChange?.percentageChange) && emissionChange?.percentageChange !== Infinity 
          ? `${Math.abs(emissionChange?.percentageChange)}% YOY${emissionChange?.percentageChange > 0 ? "↑" : "↓"}` 
          : "", year:currentFinancialYear,
        theme: "red",
      },
    ];
    
      const energyData = [
        {
            status: (renewableEnergyChange?.percentageChange || 0) > 0 ? "positive" : "negative",
            title: "Renewable Energy Use",
            info: `${renewableEnergyChange?.currentYearPercentage !== Infinity ? `${Math.abs(renewableEnergyChange?.currentYearPercentage)||0}%` : ""}`,
            percentage: `${renewableEnergyChange?.percentageChange !== Infinity ?
               `${Math.abs(renewableEnergyChange?.percentageChange)||0}% YOY${renewableEnergyChange?.percentageChange > 0 ? "↑" : "↓"}`
                : ""}`
        }
    ];
  
    useEffect(()=>{
      // console.log(currentFinancialYear)
       setEmissonChange(currentYearLastYearEmissionDetail(dataForCurrentYearChange,Number(currentFinancialYear)))   ///  Number(currentFinancialYear) 
       setrenewableEnergy(calculateRenewableEnergyUsageChange(dataForCurrentYearChange,Number(currentFinancialYear)))
       setEnergyChange(calculateEnergyUsageChange(dataForCurrentYearChange,Number(currentFinancialYear)))
  

        setEmissonChange(currentYearLastYearEmissionDetail(dataForCurrentYearChange,Number(currentFinancialYear)))   ///  Number(currentFinancialYear) 
        setrenewableEnergy(calculateRenewableEnergyUsageChange(dataForCurrentYearChange,Number(currentFinancialYear)))
        setEnergyChange(calculateEnergyUsageChange(dataForCurrentYearChange,Number(currentFinancialYear)))
      
    },[overAllData,dataForCurrentYearChange,currentFinancialYear])
    useEffect(() => {
    
     dispatch(fetchChartOverAllDataThunk({api}));
        
      
   
    }, [dispatch]);

    useEffect(() => {
      if (financialYear.length > 0) {
        setCurrentFinancialYear(financialYear[0]);
      }
    }, [financialYear]);
  return (
    <div>
       <Header PageIcon={EmissionIcon} pageTitle={"Emissions"} />
       <div className="flex flex-col main-container w-full px-10 py-6">
        <AnalyzeHeader emissionData={emissionData} energyData={energyData} />
        <div className="flex flex-col  rounded-md mt-[18px]  w-full border p-2">
          <div className="flex justify-between py-2 items-center  bg-gray-100 rounded">
            <span className="text-xl px-2"></span>
            <div className="flex justify-between items-center gap-2 px-2">
            <FilterMenuIcon className="cursor-pointer" />
            <Filter
                label="Financial Year"
                options={financialYear}
                selectedValue={currentFinancialYear}
                onChange={(value) => handleFilterChange('financialYear', value)}
              />
            <Filter
                label="Facility"
                options={siteOptions}
                selectedValue={filterBy.site}
                onChange={(value) => handleFilterChange('site', value)}
              />
             
              <Filter
                label="Scope"
                options={moduleOptions}
                selectedValue={filterBy.module}
                onChange={(value) => handleFilterChange('module', value)}
              />
              <Filter
                label="Category"
                options={sub_moduleOptions}
                selectedValue={filterBy.sub_module}
                onChange={(value) => handleFilterChange('sub_module', value)}
              />
             
            </div>
          </div>
          <div>
            <MainOverAll currentFinancialYear={isNaN(Number(currentFinancialYear))?financialYear[0]:currentFinancialYear} height={"500px"} width={"100%"} filterBy={filterBy} data={overAllData} chartDataArray={chartDataArray} Options={BreakDownOptions} StackBreakDown={StackBreakDown}/>
            
          </div>
         
         
        </div>
        </div>
    </div>
  )
}

export default Emissions
