// // /app/App.js
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "../components/common/PrivateRoute";
import PublicRoute from "../components/common/PublicRoute";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/UserAuthPages/LoginPage";
import DashboardPage from "../pages/DashboardPage";
import { useSelector } from "react-redux";

import FuelPage from "../pages/energy/FuelPage";
import ElectricityPage from "../pages/energy/ElectricityPage";
import Spinner from "../components/common/Spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Main from "../pages/settings/Main";
import Index from "../pages/BRSR/Index";
import Scope3Category1 from "../pages/inputScope3/Scope3Category1";

import AnalyzeScope2 from "../pages/energy/AnalyzeScope/AnalyzeScope2";
import AnalyzeScope3category1 from "../pages/Scope3/AnalyzeScope3category1";
import Offset from "../pages/Offset";
import Reduce from "../pages/Reduce";
import AnalyzeScope1 from "../pages/energy/AnalyzeScope/AnalyzeScope1";
import Emissions from "../pages/energy/AnalyzeScope/Emissions";
import CompanyDetailsInputPage from "../pages/UserAuthPages/CompanyDetailsInputPage";
import CreatePasswordPage from "../pages/UserAuthPages/CreatePasswordPage";
import UserAssignPage from "../pages/UserAuthPages/UserAssignPage";
import ForgotPassword from "../pages/UserAuthPages/ForgotPassword";
import CheckInboxPage from "../pages/UserAuthPages/CheckInboxPage";
import ResetPassword from "../pages/UserAuthPages/ResetPassword";
// import UserProfile from "../pages/UserProfile/UserProfile";
import UserProfileMain from "../pages/UserProfile/UserProfileMain";
import AssignLevelTab from "../pages/UserAuthPages/AssignLevelTab";
import GeneralMain from "../pages/GeneralInfo/GeneralMain";
import Scope3Category2 from "../pages/inputScope3/Scope3Category2";
import AnalyzeScope3Category2 from "../pages/Scope3/AnalyzeScope3Category2";
import Scope3Category4 from "../pages/inputScope3/Scope3Category4";
import AnalyzeScope3Category4 from "../pages/Scope3/AnalyzeScope3Category4";
// import { fetchWhitelabelingData } from "../features/common/commonThunk";
import RegisterPage from "../pages/UserAuthPages/RegisterPage";
import RegisterSuccessPage from "../components/auth/RegisterSuccessPage";
import AnalyzeScope3Category9 from "../pages/Scope3/AnalyzeScope3Category9";
import Scope3Category9 from "../pages/inputScope3/Scope3Category9";
import Helpcenter from "../pages/Helpcenter";
import Scope3Category6 from "../pages/inputScope3/Scope3Category6";
import Scope3Category7 from "../pages/inputScope3/Scope3Category7";
import AnalyzeScope3Category6 from "../pages/Scope3/AnalyzeScope3Category6";
import Scope3Category7Form from "../components/scope3/category7/Scope3Category7Form";
import AnalyzeScope3Category7 from "../pages/Scope3/AnalyzeScope3Category7";
import Scope3Category5 from "../pages/inputScope3/Scope3Category5";
import ProtectedRoute from "../components/common/ProtectRoutes";
import AccessDenied from "../pages/AccessDenied";
import CDP from "../pages/CDP/CDP";
import GRI from "../pages/GRI/GRI";
import GreenReports from "../pages/Green-report/GreenReports";
import CustomReports from "../pages/Custom-reports/CustomReports";
import AnalyzeScope3Category5 from "../pages/Scope3/AnalyzeScope3Category5";
import UnderConstruction from "../pages/UnderConstruction";
import Waste from "../pages/wasteAnalyze/Waste";
import SocialPage from "../pages/Social/DataEntryPage/Social";

function App() {
  const loading = useSelector((state) => state.common.isLoading);

  return (
    <Router>
      <ToastContainer autoClose={1500} />
      {loading && <Spinner />}
      <Routes>
        <Route
          path="/"
          element={<PublicRoute element={<HomePage />} restricted={false} />}
        />
        <Route
          path="/login"
          element={<PublicRoute element={<LoginPage />} restricted={false} />}
        />

        <Route
          path="/register-user"
          element={
            <PublicRoute element={<RegisterPage />} restricted={false} />
          }
        />
        <Route
          path="/forgotPassword"
          element={
            <PublicRoute element={<ForgotPassword />} restricted={false} />
          }
        />
        <Route
          path="/register-success-page"
          element={
            <PublicRoute element={<RegisterSuccessPage />} restricted={false} />
          }
        />
        <Route
          path="/resetpassword"
          element={
            <PublicRoute element={<ResetPassword />} restricted={false} />
          }
        />
        <Route
          path="/checkInbox"
          element={
            <PublicRoute element={<CheckInboxPage />} restricted={false} />
          }
        />
        <Route
          path="/dashboard"
          element={<PrivateRoute element={DashboardPage} />}
        />
        <Route
          path="/create-password"
          element={<PrivateRoute element={CreatePasswordPage} />}
        />
        <Route
          path="/email-form"
          element={<PrivateRoute element={Scope3Category7Form} />}
        />
        <Route
          path="/userasign"
          element={<PrivateRoute element={UserAssignPage} />}
        />
        <Route
          path="/assign-level"
          element={<PrivateRoute element={AssignLevelTab} />}
        />
        <Route
          path="/company-profile"
          element={<PrivateRoute element={CompanyDetailsInputPage} />}
        />
        <Route
          path="/profile"
          element={<PrivateRoute element={UserProfileMain} />}
        />
        <Route
          path="/access-denied"
          element={<PrivateRoute element={AccessDenied} />}
        />
        <Route path="*" element={<PrivateRoute element={AccessDenied} />} />

        <Route
          path="/energy/fuel"
          element={
            <ProtectedRoute
              element={FuelPage}
              module={1}
              path="/energy/fuel"
              submodule={1}
            />
          }
        />
        <Route
          path="/energy/electricity"
          element={
            <ProtectedRoute
              element={ElectricityPage}
              module={2}
              path="/energy/electricity"
              submodule={2}
            />
          }
        />
        <Route
          path="/scope3/category-1"
          element={
            <ProtectedRoute
              path="/scope3/category-1"
              element={Scope3Category1}
              module={3}
              submodule={3}
            />
          }
        />
        <Route
          path="/scope3/category-2"
          element={
            <ProtectedRoute
              path="/scope3/category-2"
              element={Scope3Category2}
              module={3}
              submodule={4}
            />
          }
        />
        <Route
          path="/scope3/category-4"
          element={
            <ProtectedRoute
              path="/scope3/category-4"
              element={Scope3Category4}
              module={3}
              submodule={5}
            />
          }
        />
        {/* <Route
          path="/scope3/category-5"
          element={<PrivateRoute element={Scope3Category5} />}
        /> */}
        <Route
          path="/scope3/category-5"
          element={
            <ProtectedRoute
              path="/scope3/category-5"
              element={Scope3Category5}
              module={8}
              submodule={21}
            />
          }
        />
        <Route
          path="/scope3/category-9"
          element={
            <ProtectedRoute
              path="/scope3/category-9"
              element={Scope3Category9}
              module={3}
              submodule={6}
            />
          }
        />
        <Route
          path="/scope3/category-6"
          element={
            <ProtectedRoute
              path="/scope3/category-6"
              element={Scope3Category6}
              module={3}
              submodule={7}
            />
          }
        />
        <Route
          path="/scope3/category-7"
          element={
            <ProtectedRoute
              path="/scope3/category-7"
              element={Scope3Category7}
              module={3}
              submodule={8}
            />
          }
        />
        {/* this is overall tab which show for all */}
        <Route
          path="/energy/emissions"
          element={
            <ProtectedRoute path="/energy/emissions" element={Emissions} />
          }
        />

        <Route
          path="/energy/fuelAnalyze/scope1"
          element={
            <ProtectedRoute
              module={1}
              submodule={1}
              path={"/energy/fuelAnalyze/scope1"}
              element={AnalyzeScope1}
            />
          }
        />
        <Route
          path="/energy/fuelAnalyze/scope2"
          element={
            <ProtectedRoute
              module={2}
              submodule={2}
              path={"/energy/fuelAnalyze/scope2"}
              element={AnalyzeScope2}
            />
          }
        />
        <Route
          path="/scope3/Analyze/category-1"
          element={
            <ProtectedRoute
              module={3}
              submodule={3}
              path={"/scope3/Analyze/category-1"} ///perch
              element={AnalyzeScope3category1}
            />
          }
        />

        <Route
          path="/scope3/Analyze/category-2"
          element={
            <ProtectedRoute
              module={3}
              submodule={4}
              path={"/scope3/Analyze/category-2"}
              element={AnalyzeScope3Category2}
            />
          }
        />
        <Route
          path="/scope3/Analyze/category-4"
          element={
            <ProtectedRoute
              module={3}
              submodule={5}
              path={"/scope3/Analyze/category-4"}
              element={AnalyzeScope3Category4}
            />
          }
        />

        <Route
          path="/scope3/Analyze/category-7"
          element={
            <ProtectedRoute
              module={3}
              submodule={8}
              path={"/scope3/Analyze/category-7"}
              element={AnalyzeScope3Category7}
            />
          }
        />
        <Route
          path="/scope3/Analyze/category-6"
          element={
            <ProtectedRoute
              module={3}
              submodule={7}
              path={"/scope3/Analyze/category-6"}
              element={AnalyzeScope3Category6}
            />
          }
        />
        <Route
          path="/scope3/Analyze/Waste from Operations"
          element={
            <ProtectedRoute
              module={3}
              submodule={22}
              path={"/scope3/Analyze/Waste from Operations"}
              element={AnalyzeScope3Category5}
            />
          }
        />
        <Route
          path="/scope3/Analyze/waste-material"
          element={
            <ProtectedRoute
              module={8}
              submodule={23}
              path={"/scope3/Analyze/waste-material"}
              element={Waste}
            />
          }
        />

        <Route
          path="/scope3/Analyze/category-9"
          element={
            <ProtectedRoute
              module={3}
              submodule={6}
              path={"/scope3/Analyze/category-9"}
              element={AnalyzeScope3Category9}
            />
          }
        />
        <Route
          path="/general-info"
          element={
            <ProtectedRoute
              module={-1}
              submodule={-1}
              path={"/general-info"}
              element={GeneralMain}
            />
          }
        />

        {/* <Route
          path="/general-info"
          element={<PrivateRoute element={GeneralMain} />}
        /> */}
        <Route
          path="/settings"
          element={
            <ProtectedRoute
              module={""}
              submodule={""}
              path={"/settings"}
              element={Main}
            />
          }
        />
        <Route
          path="/reduce"
          element={
            <ProtectedRoute
              module={7}
              submodule={15}
              path={"/reduce"}
              element={Reduce}
            />
          }
        />
        <Route
          path="/custom-report"
          element={
            <ProtectedRoute
              module={4}
              submodule={9}
              path={"/custom-report"}
              element={CustomReports}
            />
          }
        />
        <Route
          path="/scope3/Downstream leased assets"
          element={
            <ProtectedRoute
              module={3}
              submodule={19}
              path="/scope3/Downstream leased assets"
              element={UnderConstruction}
            />
          }
        />
        <Route
          path="scope3/End-of-life treatment of sold products"
          element={
            <ProtectedRoute
              module={3}
              submodule={18}
              path="scope3/End-of-life treatment of sold products"
              element={UnderConstruction}
            />
          }
        />
        <Route
          path="/scope3/Analyze/waste-material"
          element={
            <ProtectedRoute
              path="/scope3/Analyze/waste-material"
              element={UnderConstruction}
              module={8}
              submodule={21}
            />
          }
        />
        <Route
          path="/scope3/Water and Effluents"
          element={
            <ProtectedRoute
              path="/scope3/Water and Effluents"
              element={AccessDenied}
              module={11}
              submodule={24}
            />
          }
        />
        <Route
          path="/scope3/social"
          element={
            <ProtectedRoute
              path="/scope3/social"
              element={SocialPage}
              module={10}
              submodule={24}
            />
          }
        />
        <Route
          path="/scope3/governance"
          element={
            <ProtectedRoute
              path="/scope3/governance"
              element={AccessDenied}
              module={12}
              submodule={26}
            />
          }
        />
        <Route
          path="/scope3/Analyze/Water and Effluents"
          element={
            <ProtectedRoute
              path="/scope3/Analyze/Water and Effluents"
              element={AccessDenied}
              module={11}
              submodule={25}
            />
          }
        />
        <Route
          path="/scope3/Analyze/social"
          element={
            <ProtectedRoute
              path="/scope3/Analyze/social"
              element={UnderConstruction}
              module={10}
              submodule={24}
            />
          }
        />
        <Route
          path="/scope3/Analyze/governance"
          element={
            <ProtectedRoute
              path="/scope3/Analyze/governance"
              element={AccessDenied}
              module={12}
              submodule={26}
            />
          }
        />
        <Route
          path="/scope3/Analyze/Upstream leased assets"
          element={
            <ProtectedRoute
              module={3}
              submodule={17}
              path="/scope3/Analyze/Upstream leased assets"
              element={UnderConstruction}
            />
          }
        />
        <Route
          path="/scope3/Analyze/End-of-life treatment of sold products"
          element={
            <ProtectedRoute
              module={3}
              submodule={18}
              path="/scope3/Analyze/End-of-life treatment of sold products"
              element={UnderConstruction}
            />
          }
        />
        <Route
          path="/scope3/Analyze/Downstream leased assets"
          element={
            <ProtectedRoute
              module={3}
              submodule={19}
              path="/scope3/Analyze/Downstream leased assets"
              element={UnderConstruction}
            />
          }
        />
        <Route
          path="/scope3/Analyze/Franchises"
          element={
            <ProtectedRoute
              module={3}
              submodule={20}
              path="/scope3/Analyze/Franchises"
              element={UnderConstruction}
            />
          }
        />
        <Route
          path="/scope3/Analyze/Fuel and Energy related activities"
          element={
            <ProtectedRoute
              module={3}
              submodule={16}
              path="/scope3/Analyze/Fuel and Energy related activities"
              element={UnderConstruction}
            />
          }
        />
        <Route
          path="/green-report"
          element={
            <ProtectedRoute
              module={4}
              submodule={10}
              path={"/green-report"}
              element={GreenReports}
            />
          }
        />
        <Route
          path="/GRI"
          element={
            <ProtectedRoute
              module={5}
              submodule={11}
              path={"/GRI"}
              element={GRI}
            />
          }
        />
        <Route
          path="/BRSR"
          element={
            <ProtectedRoute
              module={5}
              submodule={12}
              path={"/BRSR"}
              element={Index}
            />
          }
        />
        <Route
          path="/CDP"
          element={
            <ProtectedRoute
              module={5}
              submodule={13}
              path={"/CDP"}
              element={CDP}
            />
          }
        />
        <Route
          path="/offset"
          element={
            <ProtectedRoute
              module={6}
              submodule={14}
              path={"/offset"}
              element={Offset}
            />
          }
        />

        <Route
          path="/help-center"
          element={<PrivateRoute element={Helpcenter} />}
        />
        {/* <Route path="/offset" element={<PrivateRoute element={Offset} />} /> */}
        {/* <Route path="/BRSR" element={<PrivateRoute element={Index} />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
// /app/App.js
// import React from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import PrivateRoute from "../components/common/PrivateRoute";
// import PublicRoute from "../components/common/PublicRoute";
// import { useSelector } from "react-redux";
// import ProtectedRoute from "../components/common/ProtectRoutes";
// import Spinner from "../components/common/Spinner";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// // Import pages
// import HomePage from "../pages/HomePage";
// import LoginPage from "../pages/UserAuthPages/LoginPage";
// import DashboardPage from "../pages/DashboardPage";
// import FuelPage from "../pages/energy/FuelPage";
// import ElectricityPage from "../pages/energy/ElectricityPage";
// import CompanyDetailsInputPage from "../pages/UserAuthPages/CompanyDetailsInputPage";
// import CreatePasswordPage from "../pages/UserAuthPages/CreatePasswordPage";
// import ForgotPassword from "../pages/UserAuthPages/ForgotPassword";
// import CheckInboxPage from "../pages/UserAuthPages/CheckInboxPage";
// import ResetPassword from "../pages/UserAuthPages/ResetPassword";
// import RegisterPage from "../pages/UserAuthPages/RegisterPage";
// import RegisterSuccessPage from "../components/auth/RegisterSuccessPage";
// import UserAssignPage from "../pages/UserAuthPages/UserAssignPage";
// import AssignLevelTab from "../pages/UserAuthPages/AssignLevelTab";
// import UserProfileMain from "../pages/UserProfile/UserProfileMain";
// import AccessDenied from "../pages/AccessDenied";
// import Helpcenter from "../pages/Helpcenter";

// // Energy-related pages
// import AnalyzeScope1 from "../pages/energy/AnalyzeScope/AnalyzeScope1";
// import AnalyzeScope2 from "../pages/energy/AnalyzeScope/AnalyzeScope2";
// import Emissions from "../pages/energy/AnalyzeScope/Emissions";

// // Scope 3 input and analysis
// import Scope3Category1 from "../pages/inputScope3/Scope3Category1";
// import AnalyzeScope3category1 from "../pages/Scope3/AnalyzeScope3category1";
// import Scope3Category2 from "../pages/inputScope3/Scope3Category2";
// import AnalyzeScope3Category2 from "../pages/Scope3/AnalyzeScope3Category2";
// import Scope3Category4 from "../pages/inputScope3/Scope3Category4";
// import AnalyzeScope3Category4 from "../pages/Scope3/AnalyzeScope3Category4";
// import Scope3Category5 from "../pages/inputScope3/Scope3Category5";
// import AnalyzeScope3Category5 from "../pages/Scope3/AnalyzeScope3Category5";
// import Scope3Category6 from "../pages/inputScope3/Scope3Category6";
// import AnalyzeScope3Category6 from "../pages/Scope3/AnalyzeScope3Category6";
// import Scope3Category7 from "../pages/inputScope3/Scope3Category7";
// import AnalyzeScope3Category7 from "../pages/Scope3/AnalyzeScope3Category7";
// import Scope3Category9 from "../pages/inputScope3/Scope3Category9";
// import AnalyzeScope3Category9 from "../pages/Scope3/AnalyzeScope3Category9";

// // Reports
// import CDP from "../pages/CDP/CDP";
// import GRI from "../pages/GRI/GRI";
// import GreenReports from "../pages/Green-report/GreenReports";
// import CustomReports from "../pages/Custom-reports/CustomReports";

// // Miscellaneous
// import Offset from "../pages/Offset";
// import Reduce from "../pages/Reduce";
// import Main from "../pages/settings/Main";
// import Index from "../pages/BRSR/Index";
// import GeneralMain from "../pages/GeneralInfo/GeneralMain";
// import UnderConstruction from "../pages/UnderConstruction";

// function App() {
//   const loading = useSelector((state) => state.common.isLoading);

//   // Group routes for better structure
//   const publicRoutes = [
//     { path: "/", element: <HomePage />, restricted: false },
//     { path: "/login", element: <LoginPage />, restricted: false },
//     { path: "/register-user", element: <RegisterPage />, restricted: false },
//     { path: "/register-success-page", element: <RegisterSuccessPage />, restricted: false },
//     { path: "/forgotPassword", element: <ForgotPassword />, restricted: false },
//     { path: "/resetpassword", element: <ResetPassword />, restricted: false },
//     { path: "/checkInbox", element: <CheckInboxPage />, restricted: false },
//   ];

//   const privateRoutes = [
//     { path: "/dashboard", element: DashboardPage },
//     { path: "/create-password", element: CreatePasswordPage },
//     { path: "/company-profile", element: CompanyDetailsInputPage },
//     { path: "/userasign", element: UserAssignPage },
//     { path: "/assign-level", element: AssignLevelTab },
//     { path: "/profile", element: UserProfileMain },
//     { path: "/help-center", element: Helpcenter },
//     { path: "/access-denied", element: AccessDenied },
//   ];

//   const protectedRoutes = [
//     { path: "/energy/fuel", element: FuelPage, module: 1, submodule: 1 },
//     { path: "/energy/electricity", element: ElectricityPage, module: 2, submodule: 2 },
//     { path: "/scope3/category-1", element: Scope3Category1, module: 3, submodule: 3 },
//     { path: "/scope3/category-2", element: Scope3Category2, module: 3, submodule: 4 },
//     { path: "/scope3/category-4", element: Scope3Category4, module: 3, submodule: 5 },
//     { path: "/scope3/category-5", element: Scope3Category5 ,module: 3, submodule: 14},
//     { path: "/scope3/category-6", element: Scope3Category6, module: 3, submodule: 7 },
//     { path: "/scope3/category-7", element: Scope3Category7, module: 3, submodule: 8 },
//     { path: "/scope3/category-9", element: Scope3Category9, module: 3, submodule: 6 },
//     { path: "/scope3/Analyze/category-1", element: AnalyzeScope3category1, module: 3, submodule: 3 },
//     { path: "/scope3/Analyze/category-2", element: AnalyzeScope3Category2, module: 3, submodule: 4 },
//     { path: "/scope3/Analyze/category-4", element: AnalyzeScope3Category4, module: 3, submodule: 5 },
//     { path: "/scope3/Analyze/Waste from Operations", element: AnalyzeScope3Category5, module: 3, submodule: 14 },
//     { path: "/scope3/Analyze/category-6", element: AnalyzeScope3Category6, module: 3, submodule: 7 },
//     { path: "/scope3/Analyze/category-7", element: AnalyzeScope3Category7, module: 3, submodule: 8 },
//     { path: "/scope3/Analyze/category-9", element: AnalyzeScope3Category9, module: 3, submodule: 6 },
//     { path: "/scope3/Downstream leased assets", element: UnderConstruction, module: 3, submodule: 10 },
//     { path: "scope3/End-of-life treatment of sold products", element: UnderConstruction, module: 3, submodule: 9 },
//     { path: "/scope3/Analyze/waste-material", element: UnderConstruction },
//     { path: "/scope3/Analyze/Upstream leased assets", element: UnderConstruction, module: 3, submodule: 12 },
//     { path: "/scope3/Analyze/Endtoendguarantee", element: UnderConstruction, module: 3, submodule: 9 },
//     { path: "/scope3/Analyze/Downstream leased assets", element: UnderConstruction, module: 3, submodule: 10 },
//     { path: "/scope3/Analyze/Franchises", element: UnderConstruction, module: 3, submodule: 13 },
//     { path: "/scope3/Analyze/Fuel and Energy related activities", element: UnderConstruction, module: 3, submodule: 11 },
//     { path: "/energy/emissions", element: Emissions },
//     { path: "/energy/fuelAnalyze/scope1", element: AnalyzeScope1, module: 1, submodule: 1 },
//     { path: "/energy/fuelAnalyze/scope2", element: AnalyzeScope2, module: 2, submodule: 2 },
//   ];

//   const reportRoutes = [
//     { path: "/CDP", element: CDP, module: 5, submodule: 13 },
//     { path: "/GRI", element: GRI, module: 5, submodule: 11 },
//     { path: "/green-report", element: GreenReports, module: 4, submodule: 10 },
//     { path: "/custom-report", element: CustomReports, module: 4, submodule: 9 },
//     { path: "/BRSR", element: Index, module: 5, submodule: 12 },
//   ];
//   const miscellaneousRoutes = [
//     { path: "/offset", element: Offset, module:6,submodule:14 },
//     { path: "/reduce", element: Reduce, module:6,submodule:15 },
//     { path: "/settings", element: Main, module:"",submodule:""},
//     { path: "/general-info", element: GeneralMain, module:-1,submodule:-1 },
//   ];
//   return (
//     <Router>
//       <ToastContainer autoClose={1500} />
//       {loading && <Spinner />}

//       <Routes>
//         {publicRoutes.map((route) => (
//           <Route
//             key={route.path}
//             path={route.path}
//             element={<PublicRoute element={route.element} restricted={route.restricted} />}
//           />
//         ))}

//         {privateRoutes.map((route) => (
//           <Route
//             key={route.path}
//             path={route.path}
//             element={<PrivateRoute element={route.element} />}
//           />
//         ))}

//         {protectedRoutes.map((route) => (
//           <Route
//             key={route.path}
//             path={route.path}
//             element={
//               <ProtectedRoute
//                 element={route.element}
//                 module={route.module}
//                 submodule={route.submodule}
//               />
//             }
//           />
//         ))}

//         {reportRoutes.map((route) => (
//           <Route
//             key={route.path}
//             path={route.path}
//             element={
//               <ProtectedRoute
//                 element={route.element}
//                 module={route.module}
//                 submodule={route.submodule}
//               />
//             }
//           />
//         ))}
//         {miscellaneousRoutes.map((route) => (
//           <Route
//             key={route.path}
//             path={route.path}
//             element={
//               <ProtectedRoute
//                 element={route.element}
//                 module={route?.module}
//                 submodule={route?.submodule}
//               />
//             }
//           />
//         ))}

//         {/* Default route for access denied */}
//         {/* <Route path="*" element={<PrivateRoute element={AccessDenied} />} /> */}
//       </Routes>
//     </Router>
//   );
// }

// export default App;
