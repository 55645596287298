import React, { useState } from 'react';
import { MultiSelect } from "react-multi-select-component";

const MultiSelectComponent = ({ options, selected, onChange, required = true }) => {
  const [hasError, setHasError] = useState(false);

  const handleOnChange = (selectedOptions) => {
    setHasError(required && selectedOptions.length === 0);
    onChange(selectedOptions);
  };

  return (
    <div className='h-full'>
      <MultiSelect
    
        options={options}
        value={selected}
        onChange={handleOnChange}
        labelledBy="Select"
      />
      {required && hasError && (
        <span className="text-red-500 text-sm">This field is required</span>
      )}
    </div>
  );
};

export default MultiSelectComponent;
