import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../../customHooks/useApi';

import { fetchSocialRetirementBenefitsInputs } from '../../../../features/social/socialThunk';
import { populateRetirementBenefitData } from '../../function/functionProcess';

import EmployeeBenefitsDisplay from '../../../BRSR/BRSRContent/DataView/EmployeeBenefitsDisplay';

function RetirementBenefits() {
  const dispatch = useDispatch();
  const api = useApi();
  const { socialRetirementBenefitsRecords, employementType } = useSelector(state => state.social);

  // Memoize the populated data
  const populatedData = useMemo(() => 
    populateRetirementBenefitData(socialRetirementBenefitsRecords, employementType), 
    [socialRetirementBenefitsRecords, employementType]
  );

  useEffect(() => {
    // dispatch(resetSocialFormData({}));
    dispatch(fetchSocialRetirementBenefitsInputs({ api, params: {} }));
  }, []);
console.log({populatedData})
  return (
    <div className='flex flex-col overflow-auto h-full w-full p-2 gap-5'>
      {Object.keys(populatedData).length > 0 ? (
        Object.keys(populatedData).map(year => (
          <EmployeeBenefitsDisplay key={year} data={ populatedData[year] } employementType={employementType}/>
        ))
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
}

export default RetirementBenefits;