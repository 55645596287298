export const Table = ({ title, data, headers }) => (
    <div className="flex flex-col">
      <h2 className="text-xl font-bold mb-4">{title}</h2>
      <div className="overflow-auto">
        <table className=" overflow-auto min-w-full max-h-96">
          <thead>
            <tr className="bg-gray-200 rounded-t border ">
              {headers.map(header => (
                <th key={header.key} className="text-left py-3 px-4">{header.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map(item => (
              <tr key={item[headers[0].key]}>
                {headers.map(header => (
                  <td key={header.key} className={`border align-middle py-3 px-4 ${header.key === 'change' ? (parseFloat(item[header.key]) >= 0 ? 'text-red-500' : 'text-green-500') : ''}`}>{item[header.key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );