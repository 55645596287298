import { useDispatch, useSelector } from 'react-redux';
import TabContent from './TabContent';
import { setadminDashboardForm, setfiltredModules, setfiltredSubModules } from '../../Redux/SettingSlices';
import { useEffect, useState } from 'react';
import useApi from '../../../../customHooks/useApi';
import { createMember, updateMember } from '../../Redux/SettingThunk';
import { toast } from 'react-toastify';
import TabContentForReporting from './TabContentForReports';

const TabContentReporting = ({ handleSubmit,
  actionType,
  roleTypeId,
  activeTab,
  onClose}) => {
    const api = useApi();
    const dispatch = useDispatch();
    const { Reportingmodule, ReportingsubModule, adminDashboardForm } = useSelector(
      (state) => state.setting
    );
    const [isReadOnly, setIsReadOnly] = useState( false);
    const [isEnabled, setIsEnabled] = useState( false);
    const [selectedEnergy, setSelectedEnergy] = useState([]);
    const [selectedScope3, setSelectedScope3] = useState([]);
  
    const additionalDataOptions = [
      { label: "Water and Affluents", value: "water and affluents" },
      { label: "Waste", value: "waste" },
    ];
  
    // Function to handle member creation or update............
    async function handleMemberCreation() {
      if (actionType === "newEntry") {
        await dispatch(createMember({ api }));
        onClose();
      } else {
        await dispatch(updateMember({ api }));
        onClose();
      }
      // handleSubmit();
    }
  
    const handleAnalyzeSave = (data) => {
      const updatedData = { ...data, read_only: false };
  
      let updatedPermissions = { ...adminDashboardForm.permissions };
      if (Number(roleTypeId) === 5) {
        updatedPermissions = {
          ...updatedPermissions,
          management: updatedData,
          reporting: updatedData,
        };
      } else if (
        Number(roleTypeId) === 4 ||
        Number(roleTypeId) === 6 ||
        Number(roleTypeId) === 11
      ) {
        updatedPermissions = {
          ...updatedPermissions,
          reporting: updatedData,
        };
      } else {
        toast.warning(`No action defined for roleTypeId: ${roleTypeId}`);
        return;
      }
  
      dispatch(
        setadminDashboardForm({
          permissions: updatedPermissions,
        })
      );
  
      if (
        adminDashboardForm?.role_type_id &&
        adminDashboardForm?.permissions?.sites?.length > 0 &&
        adminDashboardForm?.permissions?.modules?.length > 0 &&
        adminDashboardForm?.permissions?.submodules?.length > 0 &&
        data?.modules?.length > 0 &&
        data?.submodules?.length > 0
      ) {
        handleMemberCreation();
      } else {
        toast.error("Please fill all required tabs details", { theme: "dark" });
      }
    };
  
    useEffect(() => {
      if (Reportingmodule.length > 0 && ReportingsubModule.length > 0) {
        const existingData = adminDashboardForm?.permissions?.reporting || {};
  
        const initializeSelectedModules = Reportingmodule.filter((i) =>
          existingData.modules?.includes(-1)
            ? true
            : existingData.modules?.includes(Number(i.value))
        );
        const initializeSelectedSubmodules = ReportingsubModule.filter((i) =>
          existingData.submodules?.includes(-1)
            ? true
            : existingData.submodules?.includes(Number(i.value))
        );
  
        setSelectedEnergy(initializeSelectedModules);
        setSelectedScope3(initializeSelectedSubmodules);
       
        if (actionType !== "newEntry") {
          dispatch(
            setadminDashboardForm({
              permissions: {
                ...adminDashboardForm.permissions,
                reporting: {
                  modules: initializeSelectedModules.map((i) => i.value),
                  submodules: initializeSelectedSubmodules.map((i) => i.value),
                  is_enabled: existingData.is_enabled,
                  read_only: existingData.read_only || false,
                },
              },
            })
          );
        }
      }
    }, [Reportingmodule, ReportingsubModule, actionType, dispatch]);
    useEffect(() => {
      const data = {
        modules: selectedEnergy.map((i) => i.value),
        submodules: selectedScope3.map((i) => i.value),
      };
  
      const updatedData = {
        ...data,
        read_only: Number(roleTypeId) === 6,
        is_enabled: isEnabled,
      };
  
      let updatedPermissions = { ...adminDashboardForm.permissions };
  
      if (Number(roleTypeId) === 5) {
        updatedPermissions = {
          ...updatedPermissions,
          // management: updatedData,
          reporting: updatedData,
        };
      } else if (
        Number(roleTypeId) === 4 ||
        Number(roleTypeId) === 6 ||
        Number(roleTypeId) === 11
      ) {
        updatedPermissions = {
          ...updatedPermissions,
          reporting: updatedData,
        };
      } else {
        toast.warning(`No action defined for roleTypeId: ${roleTypeId}`);
        return;
      }
  
      dispatch(
        setadminDashboardForm({
          permissions: updatedPermissions,
        })
      );
    }, [selectedEnergy, selectedScope3,isEnabled]);
    return (
      <div className="flex flex-col justify-start w-full min-h-full overflow-auto">
        <TabContentForReporting
        isReadOnly={isReadOnly}
        setIsReadOnly={setIsReadOnly}
          isEnabled={isEnabled}
          setIsEnabled={setIsEnabled}
          activeTab={activeTab}
          roleTypeId={roleTypeId}
          selectedEnergy={selectedEnergy}
          setSelectedEnergy={setSelectedEnergy}
          selectedScope3={selectedScope3}
          setSelectedScope3={setSelectedScope3}
          initialAdditionalData={{
            "water and affluents": false,
            waste: false,
          }}
          actionType={actionType}
          initialIsEnabled={
            adminDashboardForm?.permissions?.reporting?.is_enabled
          }
          initialIsReadonly={
            adminDashboardForm?.permissions?.reporting?.read_only || false
          }
          additionalDataOptions={additionalDataOptions}
          energyOptions={Reportingmodule}
          scope3Options={ReportingsubModule}
          onSave={handleAnalyzeSave}
          handleSubmit={handleSubmit}
        />
      </div>
    );
};

export default TabContentReporting;