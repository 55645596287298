export const cat5Data = [
 
];
const recoveredKeys=(treatment_mechanism)=>treatment_mechanism?.toLowerCase().includes("recycling")
export function getCurrentFinancialYear() {
  var today = new Date();
  var currentMonth = today.getMonth() + 1;

  if (currentMonth <= 3) {
    return today.getFullYear() - 1;
  } else {
    return today.getFullYear();
  }
}
// export const calculateWasteTreatedPercentage = (data, splitBy="site",wasteType) => {
//   let filtredData=[]
//   if(wasteType==="treated"){
//     filtredData = [...data].filter(i=>!recoveredKeys(i.treatment_mechanism));
//  }else{
//     filtredData = [...data].filter(i=>recoveredKeys(i.treatment_mechanism));

//  }
//   if (!splitBy) {
//     const totalProduced = data.reduce((acc, curr) => acc + Number(curr.produced_quantity), 0);
//        const totalTreated = filtredData.reduce((acc, curr) => acc + Number(curr.treated_quantity), 0);
  

//     return {
//       total: totalProduced > 0 ? (totalTreated / totalProduced) * 100 : 0,
//     };
//   } else {
//     const splitData = data.reduce((acc, curr) => {
//       const key = curr[splitBy];
//       if (!acc[key]) {
//         acc[key] = { produced_quantity: 0, treated_quantity: 0 };
//       }
//       acc[key].produced_quantity += Number(curr.produced_quantity);
//       acc[key].treated_quantity += Number(curr.treated_quantity);
//       return acc;
//     }, {});

//     const treatedPercentageBySplit = Object.entries(splitData).reduce(
//       (acc, [key, { produced_quantity, treated_quantity }]) => {
//         if (produced_quantity > 0) {
//           const treatedPercentage = (treated_quantity / produced_quantity) * 100;
//           acc[key] = treatedPercentage.toFixed(); // Round to 2 decimals
//         } else {
//           acc[key] = 0;
//         }
//         return acc;
//       },
//       {}
//     );
  
//     return treatedPercentageBySplit;
//   }
// };
export const calculateWasteTreatedPercentage = (data, wastetype="treated") => {
  const treatedPercentageBySite = {};

  const dataBySite = data.reduce((acc, entry) => {
  // const dataBySite = data.filter(i=>wastetype==="treated"?!recoveredKeys(i.treatment_mechanism):recoveredKeys(i.treatment_mechanism)).reduce((acc, entry) => {
    const { site, produced_quantity, treated_quantity } = entry;

    if (!acc[site]) {
      acc[site] = { produced_quantity: 0, treated_quantity: 0 ,recovered_quantity:0};
    }

    acc[site].produced_quantity += produced_quantity;
    acc[site].treated_quantity += treated_quantity;
    if(wastetype!=="treated"){
    acc[site].recovered_quantity += treated_quantity;
  }else{
    acc[site].recovered_quantity += 0;

  }
    return acc;
  }, {});

  Object.entries(dataBySite).forEach(([site, { produced_quantity, treated_quantity ,recovered_quantity}]) => {
    if (produced_quantity > 0) {
      const treatedPercentage = (treated_quantity / produced_quantity) * 100;
      treatedPercentageBySite[site] = {
        treated_percentage: treatedPercentage?.toFixed()||0,
        produced_quantity: produced_quantity?.toFixed()||0,
        treated_quantity: treated_quantity?.toFixed()||0,
        recovered_quantity: recovered_quantity?.toFixed()||0,
      };
    } else {
      treatedPercentageBySite[site] = {
        treated_percentage: 0,
        produced_quantity: produced_quantity?.toFixed()||0,
        treated_quantity: treated_quantity?.toFixed()||0,
        recovered_quantity: recovered_quantity?.toFixed()||0,
      };
    }
  });

  return treatedPercentageBySite;
};
export const processStackedChartData = (data, year, typebreakdown) => {
  let processedData = {};

  const filteredData = data.filter((entry) => entry.year === Number(year));

  filteredData.forEach((entry) => {
    let { site, total_co2e_kg } = entry;
    total_co2e_kg = Number(total_co2e_kg);
    if(!entry[typebreakdown]&& typebreakdown!==""){
      return
    }
    const breakdown = entry[typebreakdown]||"Total";

    if (!processedData[site]) {
      processedData[site] = {
        site: site,
        breakdown: {},
      };
    }

    if (!processedData[site].breakdown[breakdown]) {
      processedData[site].breakdown[breakdown] = {
        value: 0,
        percentage: 0,
      };
    }

    processedData[site].breakdown[breakdown].value += total_co2e_kg;
  });

  Object.values(processedData).forEach((entry) => {
    let total = 0;

    Object.values(entry.breakdown).forEach((breakdown) => {
      total += Number(breakdown.value);
    });

    Object.keys(entry.breakdown).forEach((breakdown) => {
      const breakdownValue = Number(entry.breakdown[breakdown].value);
      entry.breakdown[breakdown].percentage =
        total !== 0 ? (breakdownValue / total) * 100 : 0;
    });
  });
 
  return processedData;
};



export function generateHalfPieChartOptions(chartData,currentFinancialYear){
  const currentYearChartData = chartData.filter(
    (item) => item.year === Number(currentFinancialYear)
  );

  if (!currentYearChartData || !currentYearChartData.length) {
    console.warn("No data available for the current financial year.");
    return {};
  }

  // Calculate total emissions for the current year
  const totalEmissions = currentYearChartData.reduce(
    (total, item) => total + Number(item.total_co2e_kg),
    0
  );

  const seriesData = currentYearChartData.reduce((acc, item) => {
    const site = item.site;
    const emission = Number(item.total_co2e_kg);
    const wasteProduced = Number(item?.produced_quantity?.toFixed());
    const value = emission ? ((emission / totalEmissions) * 100) : 0;

    const existingSite = acc.find((entry) => entry.name === site);
    if (existingSite) {
      existingSite.emission += emission;
      existingSite.value += value;
      existingSite.wasteProduced += wasteProduced;
    } else {
      acc.push({
        name: site,
        emission,
        value,
        wasteProduced,
      });
    }

    return acc;
  }, []);
  const option = {
    title: { text: "Emission Share by Facility", left: "center" },
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: {
          show: true,
          readOnly: true,
          optionToContent: function (opt) {
            let seriesData = opt.series[0].data;
            let table = '<table style="width:100%; text-align:center"><tbody><tr>'
                      + '<td><b>Site</b></td>'
                      + '<td><b>Emission (CO2)</b></td>'
                      + '<td><b>Waste Produced (tons)</b></td>'
                      + '</tr>';
  
            seriesData.forEach(item => {
              table += '<tr>'
                    + `<td>${item.name}</td>`
                    + `<td>${item.emission?.toFixed()}</td>`
                    + `<td>${item.wasteProduced?.toFixed()}</td>`
                    + '</tr>';
            });
  
            table += '</tbody></table>';
            return table;
          }},
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      trigger: 'item',
      formatter:(params)=>{
        
        return  `
        <b>${params.name}</b><br/>
        Emission: ${params.data.emission?.toFixed()} Kg Co2e<br/>
        Waste Produced: ${params.data.wasteProduced?.toFixed()} tonnes
      `
      }
    },
    legend: {
      // data: seriesData.map((s) => s.name), // Legend based on site names
      top: "bottom",
    },
    grid: {
      left: 1,
      right: 1,
      top: 10,
      // bottom: series?.length > 6 ? series.length * 10 - 10 : 60, // Adjust bottom space based on series length
      containLabel: true,
    },
    series: [
      {
        name: 'Site',
        type: 'pie',
        radius: ['40%', '70%'],
        center: ['50%', '70%'],
        startAngle: 180,
        endAngle: 360,

        label: {
          formatter:(params)=>{
            return `${params.percent.toFixed()}%`
          },  // Show the percentage next to the name
          position: 'inside'
        },
        data: seriesData
      }
    ]
  };
   return option
}

export const generateSingleCandleChartOptions = (
  chartData,
  currentFinancialYear
) => {
  // Filter data for the current financial year
  const currentYearChartData = chartData.filter(
    (item) => item.year === Number(currentFinancialYear)
  );

  if (!currentYearChartData || !currentYearChartData.length) {
    console.warn("No data available for the current financial year.");
    return {};
  }

  // Calculate total emissions for the current year
  const totalEmissions = currentYearChartData.reduce(
    (total, item) => total + Number(item.total_co2e_kg),
    0
  );

  const seriesData = currentYearChartData.reduce((acc, item) => {
    const site = item.site;
    const emission = Number(item.total_co2e_kg);
    const wasteProduced = Number(item?.produced_quantity?.toFixed());
    const value = emission ? (emission / totalEmissions) * 100 : 0;

    const existingSite = acc.find((entry) => entry.name === site);
    if (existingSite) {
      existingSite.emission += emission;
      existingSite.value += value;
      existingSite.wasteProduced += wasteProduced;
    } else {
      acc.push({
        name: site,
        emission,
        value,
        wasteProduced,
      });
    }

    return acc;
  }, []);
  // const series = seriesData.map(item => ({
  //   name: item.name,
  //   type: 'bar',
  //   stack: 'total',
  //    label: {
  //     show: true,
  //     formatter: '{c}%' // Show percentage inside the bar
  //   },
  //   data: [item.value]
  // }));
  // console.log({series,seriesData})
  const series = seriesData.map((item) => ({
    name: item.name,
    type: "bar",
    stack: "total",
    label: {
      show: true,
      formatter: "{c}%", // Show percentage inside the bar
    },
    data: [item.value.toFixed()], // Only one value per category (e.g., Sunday)
  }));
  // Chart Options
  return {
    title: { text: "Emission Share by Facility", left: "center" },
    barWidth: 60,
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    label: {
      show: true,
      
      fontSize: 10,
      fontWeight: "bold",
    },
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        const siteData = seriesData.find((s) => s.name === params.seriesName);
        if (siteData) {
          return `
            <b>${params.seriesName}</b><br/>
            Emission: ${siteData.emission?.toFixed()} Kg Co2e<br/>
            Waste Produced: ${siteData.wasteProduced?.toFixed()} tonnes
          `;
        }
        return `Data not found for ${params.name}`;
      },
    },
    legend: {
      // data: seriesData.map((s) => s.name), // Legend based on site names
      top: "bottom",
    },
    grid: {
      left: "3%",
      right: "14%",
      bottom: "30%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      name: "Emission Share (%)",
    },
    yAxis: {
      type: "category",
      data: ["Sites"], // Single bar with no y-axis label
    },
    series: series,
  };
};

export const generateSeriesDataForStackedChart = (
  processedData,
  wasteTreatedPercentageData
) => {
  
  const xAxisData = Object.keys(processedData);
  const legends = new Set();

  Object.values(processedData).forEach((entry) => {
    Object.keys(entry?.breakdown).forEach((breakdown) => {
      legends.add(breakdown);
    });
  });

  const series = [...legends].map((legend) => ({
    name: legend,
    type: "bar",
    stack: "total",
    // label: {
    //   show: true,
    //   formatter: (params) => {
    //     const site = params.name;
    //     const entry = processedData[site]?.breakdown[params.seriesName];
    //     const percentage = entry?.percentage;
    //     if (
    //       percentage !== undefined &&
    //       !isNaN(percentage) &&
    //       percentage !== 0
    //     ) {
    //       return `${percentage.toFixed()}%`;
    //     } else {
    //       return "";
    //     }
    //   },
    // },
    label: {
      fontSize: 10,
        fontWeight: "bold",
      show: true,
      formatter: (params) => {
        const site = params.name;
        const entry = processedData[site]?.breakdown[params.seriesName];
       
        const percentage = entry?.percentage.toFixed();
        const value = entry?.value.toFixed();
        if (
          percentage !== undefined &&
          !isNaN(percentage) &&
          percentage !== 0
        ) {
          // return `${percentage.toFixed()}%`;
          return legend.trim() === "Total"
            ? `${value > 10 ? value : ""}`
            : `${percentage > 5 ? percentage + "%" : ""}`;
        } else {
          return "";
        }
      },
    },
    data: xAxisData.map(
      (site) => processedData[site]?.breakdown[legend]?.value || 0
    ),
  }));

  if (wasteTreatedPercentageData) {
    series.push({
      name: "Waste Treated %",
      type: "line",
      label: { show: true, formatter: `{c}%` },
      data: xAxisData.map((site) => ({
        value: wasteTreatedPercentageData[site]?.treated_percentage || 0,
        treated_quantity: wasteTreatedPercentageData[site]?.treated_quantity || 0,
        produced_quantity: wasteTreatedPercentageData[site]?.produced_quantity || 0,
     
      })),
    });
  }

  return { series, legends: Array.from(legends) };
};

export const formatText = (text) => {
  if (text === "module") {
    text = "Scope";
  } else if (text === "sub_module") {
    text = "Category";
  }
  const words = text.split("_");

  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  const formattedText = capitalizedWords.join(" ");

  return formattedText;
};

// current year and last year total emission
function currentYearLastYearEmissionDetail(data, currentYear) {
  if (!currentYear) {
    return {
      currentYear: 0,
      previousYear: 0,
      percentageChange: 0,
    };
  }
  const currentYearData = data?.filter((entry) => entry.year === currentYear);
  const previousYearData = data?.filter(
    (entry) => entry.year === currentYear - 1
  );
  const totalEmissionsCurrentYear = currentYearData?.reduce(
    (total, entry) => total + Number(entry.total_co2e_kg),
    0
  );
  const totalEmissionsPreviousYear = previousYearData?.reduce(
    (total, entry) => total + Number(entry.total_co2e_kg),
    0
  );

  const percentageChange =
    ((totalEmissionsCurrentYear - totalEmissionsPreviousYear) /
      totalEmissionsPreviousYear) *
    100;

  const roundedPercentageChange = Math.floor(percentageChange);

  return {
    currentYear: totalEmissionsCurrentYear.toFixed(),
    previousYear: Math.floor(totalEmissionsPreviousYear),
    percentageChange: roundedPercentageChange,
  };
}
function getMonthName(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString("en-US", {
    month: "short",
  });
}

function processData(Inputdata, timeInterval, breakpoint, year) {
  // console.log(Inputdata)
  // Create a deep copy of Inputdata to avoid modifying the original data
  let dataCopy = JSON.parse(JSON.stringify(Inputdata));
  let data;

  if (year) {
    data = dataCopy.filter((entry) => entry.year === year);
    data.sort((a, b) => a.quarter - b.quarter);
  } else {
    if (timeInterval == "Quarter") {
      function sortByYearAndQuarter(a, b) {
        if (a.year !== b.year) {
          return a.year - b.year;
        } else {
          return a.quarter - b.quarter;
        }
      }

      dataCopy.sort(sortByYearAndQuarter);
      data = dataCopy;
    } else if (timeInterval == "Month") {
      function sortByYearAndMonth(a, b) {
        if (a.year !== b.year) {
          return a.year - b.year;
        } else {
          const monthsOrder = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          return monthsOrder.indexOf(a.month) - monthsOrder.indexOf(b.month);
        }
      }

      // Adjust the year for entries with quarter 4
      dataCopy.forEach((item) => {
        if (item.quarter == 4) {
          item.year += 1;
        }
      });

      dataCopy.sort(sortByYearAndMonth);
      data = dataCopy;
     
    } else if (timeInterval == "Year") {
      function sortByYearAndQuarter(a, b) {
        if (a.year !== b.year) {
          return a.year - b.year;
        } else {
          return b.year - a.year;
        }
      }

      dataCopy.sort(sortByYearAndQuarter);
      data = dataCopy;
    }
  }

  let processedData = {};
  data.forEach((entry) => {
  
    let { year, quarter, total_co2e_kg, month, consumed_fuel, produced_quantity, treated_quantity } = entry;
    produced_quantity = Number(produced_quantity);
    treated_quantity = Number(treated_quantity);
    total_co2e_kg = Number(total_co2e_kg);
    consumed_fuel = Number(consumed_fuel);
   
    let filter = entry[breakpoint] || "Total";
     if(!entry[breakpoint]&& breakpoint!==""){
      filter ="Produced";
    }
// console.log({breakpoint,entry})
    switch (timeInterval) {
      case "Year":
        if (!processedData[year]) {
          processedData[year] = {};
        }
        if (!processedData[year][filter]) {
          processedData[year][filter] = {
            emissionFactors: [],
            totalEmissionFactor: "",
            totalEmission: 0,
            usage_in_kwh_data: 0,
            consumed_fuel: 0,
            treatedQuantity: 0,
            producedQuantity: 0,
            percentTreated: 0
          };
        }
        processedData[year][filter].treatedQuantity += treated_quantity;
        processedData[year][filter].producedQuantity += produced_quantity;
        processedData[year][filter].emissionFactors.push(total_co2e_kg);
        processedData[year][filter].usage_in_kwh_data += Number(
          entry["usage_in_kwh"]
        );
        processedData[year][filter].totalEmission += total_co2e_kg;
        processedData[year][filter].consumed_fuel += consumed_fuel;
        break;

      case "Quarter":
        const quarterKey = `${year}-Q${quarter}`;
        if (!processedData[quarterKey]) {
          processedData[quarterKey] = {};
        }
        if (!processedData[quarterKey][filter]) {
          processedData[quarterKey][filter] = {
            emissionFactors: [],
            totalEmissionFactor: "",
            usage_in_kwh_data: 0,
            totalEmission: 0,
            consumed_fuel: 0,
            treatedQuantity: 0,
            producedQuantity: 0,
            percentTreated: 0
          };
        }
        processedData[quarterKey][filter].treatedQuantity += treated_quantity;
        processedData[quarterKey][filter].producedQuantity += produced_quantity;
        processedData[quarterKey][filter].emissionFactors.push(total_co2e_kg);
        processedData[quarterKey][filter].usage_in_kwh_data += Number(
          entry["usage_in_kwh"]
        );
        processedData[quarterKey][filter].totalEmission += total_co2e_kg;
        processedData[quarterKey][filter].consumed_fuel += consumed_fuel;
        break;

      case "Month":
        const monthKey = `${year}-${month}`;
        if (!processedData[monthKey]) {
          processedData[monthKey] = {};
        }
        if (!processedData[monthKey][filter]) {
          processedData[monthKey][filter] = {
            emissionFactors: [],
            totalEmissionFactor: "",
            usage_in_kwh_data: 0,
            totalEmission: 0,
            consumed_fuel: 0,
            treatedQuantity: 0,
            producedQuantity: 0,
            percentTreated: 0
          };
        }
        processedData[monthKey][filter].treatedQuantity += treated_quantity;
        processedData[monthKey][filter].producedQuantity += produced_quantity;
        processedData[monthKey][filter].emissionFactors.push(total_co2e_kg);
        processedData[monthKey][filter].usage_in_kwh_data += Number(
          entry["usage_in_kwh"]
        );
        processedData[monthKey][filter].totalEmission += total_co2e_kg;
        processedData[monthKey][filter].consumed_fuel += consumed_fuel;
        break;

      default:
        break;
    }
  });
  
  for (const timeKey in processedData) {
    for (const key in processedData[timeKey]) {
      const dataItem = processedData[timeKey][key];
      const factors = dataItem.emissionFactors;
      let sum = factors.reduce((acc, curr) => acc + curr, 0);
      if (dataItem.producedQuantity > 0) {
        dataItem.percentTreated = ((dataItem.treatedQuantity / dataItem.producedQuantity) * 100).toFixed();
      } else {
        dataItem.percentTreated = 0;
      }

      dataItem.totalEmissionFactor = sum.toFixed();
    }
  }

//  Object.keys(processedData).forEach(key => {
//   if (processedData[key].hasOwnProperty('Total')) {
//       delete processedData[key]['Total'];
//   }
// });
  return processedData;
}
function sumQuantities(data) {
  const result = {};

  // Loop through each top-level key (e.g., "2023-Jul", "2023-Aug")
  for (const period in data) {
    let totalProduced = 0;
    let totalTreated = 0;
    let totalRecovered = 0;

    // Loop through each second-level key (e.g., "Site 1", "Site 3")
    for (const key in data[period]) {
      const siteData = data[period][key];

      // Sum the quantities
      totalProduced += siteData.producedQuantity || 0;
      totalTreated += siteData.treatedQuantity|| 0;
      totalRecovered += siteData.treatedQuantity || 0;
    }

    // Store the summed data for the period
    result[period] = {
      produced_quantity: totalProduced,
      treated_quantity: totalTreated,
      recovered_quantity: totalRecovered,
    // Calculate percentages
  };
  const totalProduce = result[period].produced_quantity;
  const percentTreated = totalProduce > 0 ? (result[period].treated_quantity / totalProduce) * 100 : 0;
  const percentRecovered = totalProduce > 0 ? (result[period].recovered_quantity / totalProduce) * 100 : 0;
  
  // Store the percentages in the result
  result[period].percentTreated = percentTreated.toFixed();
  result[period].percentRecovered = percentRecovered.toFixed();
}

  return result;
}
function processDataToLablesAndDataSets(data,barChart) {
 
  let series = [];
  let legends = [];
  let xAxisLabels = Object.keys(data);
  const sum=sumQuantities(data)

  for (const timeKey in data) {
    for (const key in data[timeKey]) {
      if (!legends.includes(key)) {
        legends.push(key);
      }
    }
  }

  let treatedPercentageSeriesData = [];
  let treatedSeriesData = [];
  let ProducedSeriesData = [];

  for (const legend of legends) {
    let seriesData = [];
    let treatedQuantityData = [];
    let producedQuantityData = [];
    let treatedPercentageData = [];

    for (const timeKey of xAxisLabels) {
      if (data[timeKey]) {
        seriesData.push(data[timeKey][legend]?.totalEmission?.toFixed());
        treatedQuantityData.push(sum[timeKey].treated_quantity || 0);
        producedQuantityData.push(sum[timeKey].produced_quantity || 0);
        treatedPercentageData.push(sum[timeKey].percentTreated|| 0);
      } else {
        seriesData.push(0); // Placeholder for missing data
        treatedQuantityData.push(0); // Placeholder for missing treated quantity
        producedQuantityData.push(0); // Placeholder for missing produced quantity
        treatedPercentageData.push(0); // Placeholder for missing treated percentage
      }
    }
    treatedPercentageSeriesData = treatedPercentageData; // Store treated percentages for the line chart
    ProducedSeriesData=producedQuantityData
    treatedSeriesData=treatedQuantityData
    let totalEmissionsByTimeKey = xAxisLabels.reduce((acc, timeKey) => {
      acc[timeKey] = Object.values(data[timeKey]).reduce((total, siteData) => {
        return total + (siteData.totalEmission || 0);
      }, 0);
      return acc;
    }, {});

    series.push({
      name: legend,
      type: "bar",
      stack: "total",
      barWidth: "20%",
      label: {
        show: true,
        position: legend.trim() === "Total" ? "inside" : "", // Different label positions
        formatter: (params) => {
          const timeKeyIndex = params.dataIndex; // Current timeKey index
          const timeKey = xAxisLabels[timeKeyIndex]; // Get the current timeKey
          const totalEmissionForTimeKey = totalEmissionsByTimeKey[timeKey]; // Total emission across all sites for the current timeKey

          const value = Number(params.value); // Current block value

          // Calculate the percentage of the current block relative to the total emission for the timeKey
          let percentage = ((value / totalEmissionForTimeKey) * 100).toFixed();

          // Handle edge cases for NaN, undefined, or 0
          if (isNaN(percentage) || !isFinite(percentage)) {
            percentage = 0;
          }

          // Show value or percentage based on legend name
          return legend.trim() === "Total"
            ? `${value > 10 ? value : ""}`
            : `${percentage > 5 ? percentage + "%" : ""}`;
        },
        fontSize:10,
        fontWeight: "bold",
      },
      data: seriesData.map((value, index) => ({
        value,
        treatedQuantity: treatedQuantityData[index], // Attach treated quantity to each data point
        producedQuantity: producedQuantityData[index], // Attach produced quantity to each data point
        percentTreated: treatedPercentageData[index], // Attach treated percentage to each data point
      })),
    });
  }

  // Add Waste Treated % line chart series
  if(barChart){
    series.push({
    name: "Waste Treated %",
    type: "line",
    yAxisIndex: 1, // Secondary y-axis for percentage
    label: { show: true, formatter: `{c}%` },
    // data: treatedPercentageSeriesData, // Data for the line chart
    data: xAxisLabels.map((value, index) => ({
      
        treatedQuantity: treatedSeriesData[index], // Attach treated quantity to each data point
        producedQuantity: ProducedSeriesData[index], // Attach produced quantity to each data point
        value: treatedPercentageSeriesData[index], // Attach treated percentage to each data point
      })),
  });
  }
  

  return { xAxisLabels, series };
}

const generateChartOption = (
  titleText,
  legendData,
  xAxisLabels,
  series,
  
) => {
  return {
    color: [
      "#5470C6",
      "#91CC75",
      "#EE6666",
      "#FAC858",
      "#73C0DE",
      "#3BA272",
      "#FC8452",
      "#9A60B4",
      "#EA7CCC",
      "#D14A61",
      "#675BBA",
      "#F5994E",
      "#A0A7E6",
      "#7F9EC6",
      "#91C7AE",
      "#D48265",
      "#61A0A8",
      "#C4CCD3",
      "#D53A35",
      "#4E91D5",
      "#6B4A3E",
      "#CA8622",
      "#BDA29A",
      "#CC3C5D",
      "#00A29B",
      "#FF7F50",
      "#6495ED",
      "#FFD700",
      "#32CD32",
      "#FFA07A",
      "#20B2AA",
      "#87CEEB",
      "#778899",
      "#B0E0E6",
      "#FF6347",
      "#4682B4",
      "#8B0000",
      "#008080",
      "#FFD700",
      "#4B0082",
      "#DC143C",
      "#2E8B57",
      "#FF69B4",
      "#FF4500",
      "#808000",
      "#FF1493",
      "#00CED1",
      "#1E90FF",
      "#8A2BE2",
      "#A52A2A",
      "#5F9EA0",
      "#FF8C00",
      "#B22222",
      "#DAA520",
      "#228B22",
      "#FFDAB9",
      "#FF00FF",
      "#7FFF00",
      "#FA8072",
      "#F08080",
      "#FFB6C1",
      "#E0FFFF",
      "#FFE4E1",
      "#7B68EE",
      "#D2691E",
      "#9ACD32",
      "#B8860B",
      "#9932CC",
      "#00FF7F",
      "#40E0D0",
      "#FFFAF0",
      "#8FBC8F",
      "#BDB76B",
      "#FFDEAD",
      "#7CFC00",
      "#F0E68C",
      "#E6E6FA",
      "#FFF0F5",
      "#00FF00",
      "#F4A460",
      "#D8BFD8",
      "#FF7F50",
      "#FF6347",
      "#FFDAB9",
      "#FFE4B5",
      "#FFFACD",
      "#00FA9A",
      "#DDA0DD",
      "#FFF5EE",
      "#B0C4DE",
      "#B0E0E6",
      "#87CEEB",
      "#00BFFF",
      "#FFD700",
      "#32CD32",
      "#FFA500",
      "#CD5C5C",
      "#D3D3D3",
      "#4682B4",
      "#FF00FF",
      "#00CED1",
      "#808080",
    ],

    title: {
      text: titleText,
      left: "center",
    },
    grid: {
      left: '1%',   // Adjust the left gap
      right: '1%'   // Adjust the right gap
    },
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    responsive: true,
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        const { seriesName, value, data } = params;
        let tooltipText = ``;

        if (
          params.seriesType === "line" &&
          params.seriesName === "Waste Treated %"
        ) {
          
          if (data) {
            tooltipText += `Treated Quantity: ${data.treatedQuantity?.toFixed()} tons<br/>`;
          } else {
            console.warn(
              `No data found for Waste Treated percentage at site ${params.name}.`
            );
          }
        }else{
          tooltipText = `${seriesName} <br/> Emission: ${value} kg Co2e`;
        }
        return tooltipText;
      },
    },
    legend: {
      top: "bottom",
      padding: [30, 10, 10, 10],
      itemGap: 10,
      data: series,
    },
    grid: {
      left: 20,
      right: 20,
      top: 50,
      bottom: series?.length > 6 ? series.length * 10 - 10 : 50,
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        axisLabel: {
          fontSize: 10,
        },
        axisTick: { alignWithLabel: true },
        data: xAxisLabels,
      },
    ],
    yAxis: [
   {
        type: "value",
        axisLabel: {
          formatter: (value) => {
            if (value >= 1000000) {
              return (value / 1000000).toFixed() + "M Kg Co2e";
            } else if (value >= 1000) {
              return (value / 1000).toFixed() + "K Kg Co2e";
            } else {
              return (
                value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                " Kg Co2e"
              );
            }
          },
        },
        
        
      },
      {
        type: "value",
        // name: "Waste Treated %",
        position: "right",
        axisLabel: {
          formatter: "{value}%",
        },
        splitLine: {
        show: false,
      },
        // max: 100,
      },
    ],
    series: series.filter((item) => item.name !== "Produced"),
  };
};

const generatePieChartOptions = (
  text,
  filteredData,
  breaktype,
  currentFinancialYear
) => {
  const { series } = processDataToLablesAndDataSets(
    processData(filteredData, "Year", breaktype, Number(currentFinancialYear))
  );

  // console.log({series,breaktype})
  const pieChartData =
    series &&
    series.map((dataItem) => ({
      value: dataItem?.data
        ?.reduce((acc, curr) => acc + Number(curr?.value), 0)
        .toFixed(),
      name: dataItem.name,
    }));

  return {
    responsive: true,
    color: [
      "#5470C6",
      "#91CC75",
      "#EE6666",
      "#FAC858",
      "#73C0DE",
      "#3BA272",
      "#FC8452",
      "#9A60B4",
      "#EA7CCC",
      "#D14A61",
      "#675BBA",
      "#F5994E",
      "#A0A7E6",
      "#7F9EC6",
      "#91C7AE",
      "#D48265",
      "#61A0A8",
      "#C4CCD3",
      "#D53A35",
      "#4E91D5",
      "#6B4A3E",
      "#CA8622",
      "#BDA29A",
      "#CC3C5D",
      "#00A29B",
      "#FF7F50",
      "#6495ED",
      "#FFD700",
      "#32CD32",
      "#FFA07A",
      "#20B2AA",
      "#87CEEB",
      "#778899",
      "#B0E0E6",
      "#FF6347",
      "#4682B4",
      "#8B0000",
      "#008080",
      "#FFD700",
      "#4B0082",
      "#DC143C",
      "#2E8B57",
      "#FF69B4",
      "#FF4500",
      "#808000",
      "#FF1493",
      "#00CED1",
      "#1E90FF",
      "#8A2BE2",
      "#A52A2A",
      "#5F9EA0",
      "#FF8C00",
      "#B22222",
      "#DAA520",
      "#228B22",
      "#FFDAB9",
      "#FF00FF",
      "#7FFF00",
      "#FA8072",
      "#F08080",
      "#FFB6C1",
      "#E0FFFF",
      "#FFE4E1",
      "#7B68EE",
      "#D2691E",
      "#9ACD32",
      "#B8860B",
      "#9932CC",
      "#00FF7F",
      "#40E0D0",
      "#FFFAF0",
      "#8FBC8F",
      "#BDB76B",
      "#FFDEAD",
      "#7CFC00",
      "#F0E68C",
      "#E6E6FA",
      "#FFF0F5",
      "#00FF00",
      "#F4A460",
      "#D8BFD8",
      "#FF7F50",
      "#FF6347",
      "#FFDAB9",
      "#FFE4B5",
      "#FFFACD",
      "#00FA9A",
      "#DDA0DD",
      "#FFF5EE",
      "#B0C4DE",
      "#B0E0E6",
      "#87CEEB",
      "#00BFFF",
      "#FFD700",
      "#32CD32",
      "#FFA500",
      "#CD5C5C",
      "#D3D3D3",
      "#4682B4",
      "#FF00FF",
      "#00CED1",
      "#808080",
    ],
    title: {
      text,
      left: "center",
    },
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        let tooltipText = "";

        tooltipText += `${params.name}<br/>Emissions: ${parseFloat(
          params.value
        ).toFixed()} Kg Co2e`;

        return tooltipText;
      },
    },
    legend: {
      // type: 'scroll', // Enable scrollable legend
      // orient: 'horizontal', // Orient legend vertically
      // right: 10,
      // top: 20,
      // bottom: 20,
      // type: 'scroll', // Enable scrollable legend
      orient: "horizontal", // Orient legend vertically
      bottom: "5%",
      padding: [0, 0, 0, 0],
      top: "bottom",
      formatter: (name) => {
        // Shorten the legend name if it's too long
        // return name.length > 15 ? `${name.slice(0, 15)}...` : name;
        return name;
      },
      pageIconSize: [12, 12], // Set the size of the page icons
      pageTextStyle: {
        fontSize: 12,
      },
    },
    series: [
      {
        type: "pie",
        radius: ["40%", "70%"],
        center: ["50%", "50%"], // Center the pie chart
        avoidLabelOverlap: false,
        label: {
          position: "inside",
          show: true,
          fontSize: 10,
          fontWeight: "bold",
          formatter: (params) => {
            return `${params?.percent?.toFixed()}%`;
          },
        },
        labelLine: {
          show: true,
        },
        data: pieChartData?.filter((item) => item.name !== "Produced"),
      },
    ],
  };
};
const generateStackedChartOptions = (
  chartData,
  currentFinancialYear,
  typeBreakdown,
  legends,
  xAxisData,
  series
) => {
  const currentYearChartData = chartData.filter(
    (item) => item.year === Number(currentFinancialYear)
  );

  if (!currentYearChartData || !currentYearChartData.length) {
    console.warn("No data available for the current financial year.");
    return {};
  }

  const yAxis = [
    {
      type: "value",
      axisLabel: {
        formatter: (value) => {
          if (value >= 1000000) {
            return (value / 1000000).toFixed() + "M Kg CO2e";
          } else if (value >= 1000) {
            return (value / 1000).toFixed() + "K Kg CO2e";
          } else {
            return (
              value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
              " Kg CO2e"
            );
          }
        },
      },
    },
    {
      type: "value",
      // max: 100,
      axisLabel: {
        formatter: "{value}%",
      },
      splitLine: {
        show: false,
      },
    },
  ];

  return {
    title: { text: "Facilitywise Emissions", left: "center" },
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        let tooltipContent = `${params.seriesName}<br/>`;
        // Bar chart (emissions breakdown)
        if (params.seriesType === "bar") {
          tooltipContent += `Emission: ${params.value.toFixed()} Kg CO2e<br/>`;

          // const entry = currentYearChartData?.filter(
          //   (item) =>
          //     item[typeBreakdown] === params.seriesName &&
          //     item.site === params.name
          //   );
          //   if (entry) {
          //   const totalProducedQuantity = entry.reduce((acc, curr) => acc + curr.produced_quantity, 0);
          //   tooltipContent += `Total Waste Produced: ${totalProducedQuantity.toFixed(2)} tonnes<br/>`;
          //   const wasteProduced = entry["produced_quantity"];
          //   tooltipContent += `Waste Produced: ${params?.data?.produced_quantity} tonnes<br/>`;
          // } else {
          //   console.warn(
          //     `No data found for ${params.seriesName} at site ${params.name}.`
          //   );
          // }
        }
        // Line chart (Waste Treated percentage)
        else if (
          params.seriesType === "line" &&
          params.seriesName === "Waste Treated %"
        ) {
       
         
          if (params?.data[
            "treated_quantity"
          ]) {
            // tooltipContent += `Waste Treated: ${params?.value?.toFixed(2)}%<br/>`;
            tooltipContent += `Treated Quantity: ${params?.data[
              "treated_quantity"
            ]} tonnes<br/>`;
          } else {
            console.warn(
              `No data found for Waste Treated percentage at site ${params.name}.`
            );
          }
        }

        return tooltipContent;
      },
    },
    legend: {
      type: "scroll",
      data: [...legends, "Waste Treated %"], // Include legend for line series
      top: "bottom",
    },
    label: {
      show: true,
      
      fontSize: 10,
      fontWeight: "bold",
    },
    xAxis: {
      type: "category",
      data: xAxisData,
    },
    yAxis: yAxis,
    series: series.map((s) => {
      if (s.type === "line") {
        return {
          ...s,
          yAxisIndex: 1, // Assign to the secondary y-axis for percentage
          name: "Waste Treated %", // Assign the correct name for the legend
        };
      }
      return s;
    }),
  };
};
export {
  processData,
 
  generateStackedChartOptions,
  processDataToLablesAndDataSets,
  currentYearLastYearEmissionDetail,
  generatePieChartOptions,
  generateChartOption
};

 
export function calculateWasteTreatedPercentageTile(data, currentYear) {
  const currentYearData = data.filter(
    (entry) => entry.year === currentYear
  );
  const wasteProduced = currentYearData.reduce(
    (acc, entry) => acc + entry.produced_quantity,
    0
  );
  const wasteTreated = currentYearData.reduce(
    (acc, entry) => acc + entry.treated_quantity,
    0
  );
  const wasteRecovered = currentYearData.reduce(
    (acc, entry) =>
      acc +
      (recoveredKeys(entry.treatment_mechanism)
        ? entry.treated_quantity
        : 0),
    0
  );

  const wasteTreatedPercentage =
    wasteProduced > 0
      ? ((wasteTreated) / wasteProduced) * 100
      : 0;

  const lastYearData = data.filter(
    (entry) => entry.year === currentYear - 1
  );
  const lastYearWasteProduced = lastYearData.reduce(
    (acc, entry) => acc + entry.produced_quantity,
    0
  );
  const lastYearWasteTreated = lastYearData.reduce(
    (acc, entry) => acc + entry.treated_quantity,
    0
  );
  const lastYearWasteRecovered = lastYearData.reduce(
    (acc, entry) =>
      acc +
      (recoveredKeys(entry.treatment_mechanism)
        ? entry.treated_quantity
        : 0),
    0
  );

  const lastYearWasteTreatedPercentage =
    lastYearWasteProduced > 0
      ? ((lastYearWasteTreated + lastYearWasteRecovered) /
          lastYearWasteProduced) *
        100
      : 0;

  const wasteTreatedPercentageChange =
    lastYearWasteTreatedPercentage > 0
      ? ((wasteTreatedPercentage - lastYearWasteTreatedPercentage) /
          lastYearWasteTreatedPercentage) *
        100
      : 0;
  return {
    currentYear: wasteTreatedPercentage.toFixed(),
    lastYear: lastYearWasteTreatedPercentage.toFixed(),
    percentageChange: wasteTreatedPercentageChange.toFixed(),
  };
}
export function removeEmptyProperties(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([key, value]) => value !== null && value !== undefined && value !== ""
    )
  );
}







const aggregateYearData = (data, year) => {
  let totalProduced = 0;
  let totalTreated = 0;
  let totalRecovered = 0;
  let totalRevenue = 0;

  data.forEach((entry) => {
    if (entry.year === year) {
      totalProduced += entry.produced_quantity;
      totalTreated += entry.treated_quantity;
      totalRevenue += entry?.revenue || 0;

      if (entry?.treatment_mechanism?.toLowerCase().includes("recycling")) {
        totalRecovered += entry.treated_quantity;
      }
    }
  });

  return {
    totalProduced,
    totalTreated,
    totalRecovered,
    totalRevenue,
  };
};