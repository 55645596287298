import { createAsyncThunk } from "@reduxjs/toolkit";

import { setIsLoading } from "../../common/commonSlice.js";

import { toast } from "react-toastify";



import {
  resetscope3Category4Form,
  setCurrencyData,
  setSiteData,
  setSiteDataForFilter,
  setUnitData,
  setdeletes3c4Data,
  setenergySourceData,
  setlogisticModeData,
  setscope3Category4Form,
  setscope3Category4InputRecords,
  setscope3Category4ItemData,
  setscope3Category4ItemDataFilter,
  setvehicleSpecificatioonData,
  setvehicleTypeData,
} from "./Scope3Category4Slice.js"
import { getOrganizationId } from "../../../components/common/parseJwt.js";
import { removeFalsyValues } from "../../../components/common/removeFaslyValues.js";
export const fetchscope3Category4InputData = createAsyncThunk(
  "scope3Category4/fetchscope3Category4InputData",
  async ({api,params}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    const organization_id=getOrganizationId()
    let apiUrl = "/scope3/category4/inputdata/"+organization_id;

    try {
      const response = await api.get(`${apiUrl}` ,{
        params:removeFalsyValues(params)
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setscope3Category4InputRecords(response?.data?.data.map(item=>{
          return {
            ...item,
            // currency: item.item_currency_id,
            //     production_type: item.item_production_type,
            //     site: item.site_id,
            //     unit: item.item_unit_id,
            //     supplier: item.item_supplier,
            //     supplier_code: item.item_supplier_code,
          }
        })));
        // dispatch(setCurrentPage(response.data.currentPage));
        // dispatch(setItemsPerPage(response.data.itemsPerPage));
        // dispatch(setTotalPages(response.data.totalPages));
        // dispatch(setTotalCount(response.data.totalCount));
        // return response
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchscope3Category4ItemData = createAsyncThunk(
  "scope3Category4/fetchscope3Category4ItemData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    const organization_id=getOrganizationId()
    let apiUrl = "/scope3/category4/client_item_supply_chain_master/"+organization_id;

    try {
      const response = await api.get(`${apiUrl}`, {
        //   headers: {
        //     Authorization: `Bearer ${accessToken}`,
        //   },
      });

      if (response.status === 200 && response.data.success) {
        dispatch(
          setscope3Category4ItemData(
            response?.data?.data.map((item) => {
              return {
                ...item,
                // item_code:item.organization_item_code,
                // supplier_code:item.organization_supplier_code
                
              };
            })
          )
        );
        dispatch(setscope3Category4ItemDataFilter(response.data.Filters))
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchSiteData = createAsyncThunk(
  "scope3Category4/fetchSiteData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id=getOrganizationId()
    try {
      const response = await api.get(
        `/scope3/category4/${organization_id}/sites`,
        {
          // headers: {
          //   Authorization: `Bearer ${accessToken}`,
          // },
        }
      );

      if (response.status === 200 && response.data.success) {
        dispatch(setSiteData(response.data.data));
        dispatch(setSiteDataForFilter(response.data.Filters));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchUnitData = createAsyncThunk(
  "scope3Category4/fetchUnitData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    try {
      const response = await api.get(`/scope3/category4/units`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setUnitData(response.data.data.filter(item=>{
       
          return item.id !== 18 && item.id !== 19;
        })));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchVehicleTypeData = createAsyncThunk(
  "scope3Category4/fetchVehicleTypeData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    try {
      const response = await api.get(`/scope3/category4/vehicle-type`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setvehicleTypeData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchLogisticmodeData = createAsyncThunk(
  "scope3Category4/fetchLogisticmodeData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    try {
      const response = await api.get(`/scope3/category4/logistic-mode-mapping`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setlogisticModeData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchEnergySourceTypeData = createAsyncThunk(
  "scope3Category4/fetchEnergySourceTypeData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    try {
      const response = await api.get(`/scope3/category4/energy-source`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setenergySourceData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchVehicleSpecificationData = createAsyncThunk(
  "scope3Category4/fetchVehicleSpecificationData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    try {
      const response = await api.get(`/scope3/category4/vehicle-specification`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setvehicleSpecificatioonData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchCurrencyData = createAsyncThunk(
  "scope3Category4/fetchCurrencyData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    try {
      const response = await api.get(`/scope3/category4/currencies`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      // console.log(response.data.data);
      if (response.status === 200 && response.data.success) {
        dispatch(setCurrencyData(response?.data?.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const postscope3Category4Data = createAsyncThunk(
  "scope3Category4/postscope3Category4Data",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    const { id, ...scope3Category4Form } = getState().scope3Category4.scope3Category4Form;
    const organization_id=getOrganizationId()
    try {
      const response = await api.post(
        `/scope3/category4/${organization_id}/data`,
        {
          ...scope3Category4Form,
         
        },
     
      );
      // console.log(response);
      if (response.status === 200 || response.status === 201) {
        dispatch(resetscope3Category4Form());
        dispatch(fetchscope3Category4InputData({api,params:{}}));
        toast.success("Record created successfully", {
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error(error?.response?.data?.error || "Record creation failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const scope3Category4UploadEvidence = createAsyncThunk(
  "scope3Category4/scope3Category4UploadEvidence",
  async ({files,api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    // const accessToken = getState().auth.accessToken;
    const accessToken=localStorage.getItem("accessToken")
    const organization_id=getOrganizationId()
    try {
      const response = await api.post(
        `/scope3/category4/${organization_id}/evidence`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        dispatch(setIsLoading(false));

        dispatch(setscope3Category4Form({ evidence: response?.data?.path }));
        toast.success("Evidence uploded successfully", {
          theme: "dark",
        });
      }
      return response.data
    } catch (error) {
      console.error("Error uploading evidence", error);
      toast.error("uploading evidence failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const updatescope3Category4Data = createAsyncThunk(
  "scope3Category4/updatescope3Category4Data",
  async ({api,id}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id=getOrganizationId()
    const scope3Category4Form  = getState().scope3Category4.scope3Category4Form;
   

    try {
      const response = await api.put(
        `/scope3/category4/${organization_id}/data/${id}`,
        scope3Category4Form
        // {
        //   headers: {
        //     Authorization: `Bearer ${accessToken}`,
        //   },
        // }
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Record updated successfully", {
          theme: "dark",
        });
        dispatch(fetchscope3Category4InputData({api,params:{}}))
      }
    } catch (error) {
      console.error("Error updating data:", error);
      toast.error("Record update failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const deletescope3Category4Data = createAsyncThunk(
  "scope3Category4/deletescope3Category4Data",
  async ({api,id}, { _, dispatch }) => {
    dispatch(setIsLoading(true));
  
    const organization_id=getOrganizationId()
    try {
      const response = await api.delete(
        `/scope3/category4/${organization_id}/data/${id}`
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Record deleted successfully", {
          theme: "dark",
        });
        dispatch(setdeletes3c4Data(id))
      }
    } catch (error) {
      console.error("Error deleting data:", error);
      toast.error("Record delete failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
