import React from "react";

// Sample Data Structure (replace with dynamic data as needed)
const sampleData = {
  employees: {
    FY23_24: {
      male: { total: "x", reviewed: "x", percentage: "x" },
      female: { total: "x", reviewed: "x", percentage: "x" },
      total: { total: "x", reviewed: "x", percentage: "x" },
    },
    FY22_23: {
      male: { total: "x", reviewed: "x", percentage: "x" },
      female: { total: "x", reviewed: "x", percentage: "x" },
      total: { total: "x", reviewed: "x", percentage: "x" },
    },
  },
  workers: {
    FY23_24: {
      male: { total: "x", reviewed: "x", percentage: "x" },
      female: { total: "x", reviewed: "x", percentage: "x" },
      total: { total: "x", reviewed: "x", percentage: "x" },
    },
    FY22_23: {
      male: { total: "x", reviewed: "x", percentage: "x" },
      female: { total: "x", reviewed: "x", percentage: "x" },
      total: { total: "x", reviewed: "x", percentage: "x" },
    },
  },
};

const PerformanceReviewTable = ({ data = sampleData ,question}) => {
  return (
    <div className="overflow-auto">
      <table className="min-w-full border-collapse border border-gray-300 text-sm text-center text-gray-700">
        <thead className="bg-gray-100">
        <tr className="bg-gray-300">
          <th colSpan={7} className="border border-gray-300 px-4 py-2 text-start font-semibold text-base">
          {question}       </th>
        </tr>
          <tr className="bg-gray-100 text-sm font-semibold ">
            <th rowSpan="2" className="border border-gray-300 p-2">Category</th>
            <th colSpan="3" className="border border-gray-300 p-2">FY 23-24</th>
            <th colSpan="3" className="border border-gray-300 p-2">FY 22-23</th>
          </tr>
          <tr className="bg-gray-100 text-sm font-semibold text-gray-700">
            {["Total No. (A)", "No. reviewed (B)", "% (B/A)", "Total No. (A)", "No. reviewed (B)", "% (B/A)"].map(
              (header, index) => (
                <th key={index} className="border border-gray-300 p-2">
                  {header}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {/* Employees Section */}
          <tr>
            <td colSpan="7" className="border border-gray-300 p-2 font-semibold bg-gray-50">
              Employees
            </td>
          </tr>
          {["male", "female", "total"].map((category) => (
            <TableRow
              key={`employees-${category}`}
              category={category}
              data={data.employees}
            />
          ))}

          {/* Workers Section */}
          <tr>
            <td colSpan="7" className="border border-gray-300 p-2 font-semibold bg-gray-50">
              Workers
            </td>
          </tr>
          {["male", "female", "total"].map((category) => (
            <TableRow
              key={`workers-${category}`}
              category={category}
              data={data.workers}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

// Reusable row component
const TableRow = ({ category, data }) => {
  return (
    <tr className="text-sm text-gray-700">
      <td className="border border-gray-300 p-2 capitalize">{category}</td>
      {/* FY 23-24 Data */}
      <td className="border border-gray-300 p-2">{data.FY23_24[category].total}</td>
      <td className="border border-gray-300 p-2">{data.FY23_24[category].reviewed}</td>
      <td className="border border-gray-300 p-2">{data.FY23_24[category].percentage}</td>
      {/* FY 22-23 Data */}
      <td className="border border-gray-300 p-2">{data.FY22_23[category].total}</td>
      <td className="border border-gray-300 p-2">{data.FY22_23[category].reviewed}</td>
      <td className="border border-gray-300 p-2">{data.FY22_23[category].percentage}</td>
    </tr>
  );
};

export default PerformanceReviewTable;
