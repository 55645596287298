import React, { useEffect, useState } from "react";


import ChartComponent from "../../VisualizationFeature/components/ChartComponents";


// import StackedBarChart from "../../VisualizationFeature/components/StackedBarChart";

import { SunburstChart } from "../../VisualizationFeature/components/SunBurstChartEmission";
import PieChart from "../../VisualizationFeature/components/PieChart";
import { generateSunburstChartDataCategory6,generatePieChartOptions } from "../../VisualizationFeature/Redux/processData/ProcessDataforCategory6";


function Category4Main({
  width,
  height,
  filterBy,
  data,
  chartDataArray,
  Options,
  
  piechartDataArray,
  currentFinancialYear,
}) {
 
  const [filtredDataByGlobleFilter, setfiltredDataByGlobleFilter] = useState(
    []
  );

  const [typeBreakdown, setTypeBreakdown] = useState("");

  


  
  const handleTypeBreakdownChange = (e) => {
    setTypeBreakdown(e.target.value);
  };
 
  

  useEffect(() => {
    if (data && data.length > 0) {
      const filteredData = data.filter((entry) => {
        for (let key in filterBy) {
          if (filterBy[key] && entry[key] !== filterBy[key]) {
            return false;
          }
        }
        return true;
      });
      setfiltredDataByGlobleFilter(filteredData); // filter data according to globle  filter by site , useType and fuel type
     
    }
  }, [data, filterBy, currentFinancialYear]);


  if (!Array.isArray(data) || data.length === 0) {
    return <div>No data available for Charts</div>;
  }

  return (
    <div>
      <div className="border rounded-md mt-[18px] px-2 py-2  w-full shadow-md">
        <ChartComponent
          height={height}
          width={width}
          BreakDownOptions={Options}
          data={filtredDataByGlobleFilter}
        />
      </div>
      
      <div className="grid grid-cols-2 gap-2  w-full py-3">
      {piechartDataArray.length &&
          piechartDataArray
            .map((item) =>
              generatePieChartOptions(
                item.title,
                filtredDataByGlobleFilter,
                item.dataKey,
                Number(currentFinancialYear)
              )
            )
            ?.map((options, index) => (
              <div
                key={options?.title?.text + index}
                className="border rounded-md mt-[18px] px-2 py-2 w-full shadow-md"
              >
                {options?.option&&<PieChart option={options?.option} />}
              </div>
            ))}
        {chartDataArray.length &&
          chartDataArray
            .map((item) =>
              generateSunburstChartDataCategory6(
                filtredDataByGlobleFilter,
                item.title,
                item.L1,
                item.L2,
                item.mode_of_travel
                
              )
            )
            ?.map((options, index) => (
              <>

              <div
                key={ index}
                className=" border rounded-md mt-[18px] px-2 py-2  w-full shadow-md"
              >
                {options&&<SunburstChart option={options} />}
              </div>
              </>
            ))}
           
      </div>
     
    </div>
  );
}

export default Category4Main;
