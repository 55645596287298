import React from 'react'
import EmissionCard from './HeaderCard/EmissionCard'
import EnergyUseCard from './HeaderCard/EnergyUseCard'
import Scope3Card from './HeaderCard/Scope3Card'

function AnalyzeHeader({emissionData,energyData,category}) {
 
  return (
    <div className='flex justify-start items-center overflow-auto gap-4'>
      {emissionData.map((item,index)=><EmissionCard key={index} {...item} />)}
      {energyData.map((item,index)=><EnergyUseCard key={index} {...item}/>)}
      {category&&category.length>0?category.map((item,index)=><Scope3Card key={index} {...item}/>):""}
     
    </div>
  )
}

export default AnalyzeHeader
