import { ReactComponent as CloseIcon } from "../../../app/assets/CloseIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { postCapitalGoodsData } from "../Redux/GeneralThunk";
import { toast } from "react-toastify";
import useApi from "../../../customHooks/useApi";
import { useEffect } from "react";
import { resetFormData } from "../Redux/GeneralSlices";

const CapitalGoodsDataForm = ({ handleFormChange, handleCloseForm }) => {
  const { formData, supplierTier, CapitalGoodsItemData } = useSelector(
    (state) => state.general
  );
  const api=useApi()
  const loading = useSelector((state) => state.common.isLoading);
  const dispatch = useDispatch();
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (
      (
        parseFloat(formData.co2e_co2_kg || 0) +
        parseFloat(formData.co2e_ch4_kg || 0) +
        parseFloat(formData.co2e_n2o_kg || 0) +
        0
      ) <= parseFloat(formData.total_co2e_kg || 0)
    ) {
      
      dispatch(postCapitalGoodsData({api})).then((result) => {
        // console.log(result);
        handleCloseForm();
      });
    }else{
      toast.error("total should be greater then sum of all three emission");

    }
  };
useEffect(()=>{
dispatch(resetFormData())
},[])
  return (
    <>
      <div className="mainContainer flex w-full h-screen overflow-hidden justify-center items-center z-[100] fixed bg-black bg-opacity-50 top-0 left-0 bottom-0">
        <div className="flex flex-col justify-center items-center w-9/12 text-xs bg-white absolute z-[1000] border border-transparent rounded-t-md">
          <div className="flex justify-between w-full bg-gray-50 py-2 px-4 border-b border-gray-300 mb-3">
            <span className="cursor-pointer">Capital and Goods</span>
            <span className="cursor-pointer" onClick={handleCloseForm}>
              <CloseIcon />
            </span>
          </div>
          <form
            style={{
              opacity: loading ? 0.5 : 1,
              pointerEvents: loading ? "none" : "auto",
            }}
            id="insert-data-form"
            className="flex flex-col w-full py-4"
            onSubmit={handleFormSubmit}
          >
            <div className="grid grid-cols-4 gap-4 px-8 mb-4 justify-around">
              <div className="col-span-1 flex flex-col">
                <label htmlFor="item_name" className="text-xs py-1.5">
                  Item Name<span className="text-red-500">*</span>
                </label>
                <select
                  name="item_name"
                  value={formData?.item_name || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="" disabled>
                    Choose the Item Name
                  </option>
                  {CapitalGoodsItemData &&
                    CapitalGoodsItemData.map((item) => (
                      <option key={item.id} value={item.item_name}>
                        {item.item_name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="item_description" className="text-xs py-1.5">
                  Item Description<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="item_description"
                  id="item_description"
                  value={formData?.item_description || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="item_code" className="text-xs py-1.5">
                  Company Item Code
                </label>
                <input
                  type="text"
                  name="item_code"
                  id="item_code"
                  value={formData?.item_code || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>

              <div className="col-span-1 flex flex-col">
                <label htmlFor="co2e_co2_kg" className="text-xs py-1.5">
                  Emission Factor-kg CO2e of CO2 per unit
                </label>
                <input
                  required={
                    formData.total_co2e_kg ||
                    formData.co2e_ch4_kg ||
                    formData.co2e_n2o_kg
                      ? true
                      : false
                  }
                  type="text"
                  name="co2e_co2_kg"
                  id="co2e_co2_kg"
                  value={formData?.co2e_co2_kg || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="co2e_ch4_kg" className="text-xs py-1.5">
                  Emission Factor-kg CO2e of CH4 per unit
                </label>
                <input
                  required={
                    formData.total_co2e_kg ||
                    formData.co2e_co2_kg ||
                    formData.co2e_n2o_kg
                      ? true
                      : false
                  }
                  type="text"
                  name="co2e_ch4_kg"
                  id="co2e_ch4_kg"
                  value={formData?.co2e_ch4_kg || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="co2e_n2o_kg" className="text-xs py-1.5">
                  Emission Factor-kg CO2e of N2O per unit
                </label>
                <input
                  required={
                    formData.total_co2e_kg ||
                    formData.co2e_co2_kg ||
                    formData.co2e_ch4_kg
                      ? true
                      : false
                  }
                  type="text"
                  name="co2e_n2o_kg"
                  id="co2e_n2o_kg"
                  value={formData?.co2e_n2o_kg || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="total_co2e_kg" className="text-xs py-1.5">
                  Emission Factor - Total Kg CO2e per unit
                </label>
                <input
                  required={
                    formData.co2e_co2_kg ||
                    formData.co2e_ch4_kg ||
                    formData.co2e_n2o_kg
                      ? true
                      : false
                  }
                  min={
                    parseFloat(formData.co2e_co2_kg || 0) +
                    parseFloat(formData.co2e_ch4_kg || 0) +
                    parseFloat(formData.co2e_n2o_kg || 0)
                  }
                  type="text"
                  name="total_co2e_kg"
                  id="total_co2e_kg"
                  value={formData?.total_co2e_kg || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label
                  htmlFor="emission_factor_unit"
                  className="text-xs py-1.5"
                >
                  Emission Factor Unit
                </label>
                <select
                  disabled={
                    formData.total_co2e_kg ||
                    formData.co2e_co2_kg ||
                    formData.co2e_ch4_kg ||
                    formData.co2e_n2o_kg
                      ? false
                      : true
                  }
                  name="emission_factor_unit"
                  value={formData?.emission_factor_unit || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required={
                    formData.total_co2e_kg ||
                    formData.co2e_co2_kg ||
                    formData.co2e_ch4_kg ||
                    formData.co2e_n2o_kg
                      ? true
                      : false
                  }
                >
                  <option value="">Choose the Emission Factor Unit</option>
                  <option value="USD">USD</option>
                  <option value="Quantity">Quantity</option>
                </select>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="supplier" className="text-xs py-1.5">
                  Supplier
                </label>
                <input
                  type="text"
                  name="supplier"
                  id="supplier"
                  value={formData?.supplier || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label
                  htmlFor="organization_supplier_code"
                  className="text-xs py-1.5"
                >
                  Organization Supplier Code
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <input
                  type="text"
                  name="organization_supplier_code"
                  id="organization_supplier_code"
                  value={formData?.organization_supplier_code || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label
                  htmlFor="supplier_registration_number"
                  className="text-xs py-1.5"
                >
                  Supplier Registration Number
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <input
                  type="text"
                  name="supplier_registration_number"
                  id="supplier_registration_number"
                  value={formData?.supplier_registration_number || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="supplier_tier" className="text-xs py-1.5">
                  Supplier Tier
                </label>
                <select
                  name="supplier_tier"
                  value={formData?.supplier_tier || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                >
                  <option value="">Choose the supplier tier</option>
                  {supplierTier &&
                    supplierTier.map((item) => (
                      <option key={item.id} value={item.supplier_tier}>
                        {item.supplier_tier}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
              <div className="w-1/6 flex flex-col">
                <button
                  type="button"
                  disabled={loading}
                  className="py-2 px-6 text-green-600 text-sm rounded-md"
                  onClick={handleCloseForm}
                >
                  Cancel
                </button>
              </div>
              <div className="w-1/6 flex flex-col">
                <button
                  type="submit"
                  disabled={loading}
                  className={`py-2 px-6 text-white text-sm ${
                    loading ? "bg-green-500 cursor-wait" : "bg-green-600"
                  } rounded-md`}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CapitalGoodsDataForm;
