import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
export const parseJwt = (token) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Invalid token:", error);
    return null;
  }
};

export const isTokenValid = (token) => {
  if (!token) return false;

  const decodedToken = parseJwt(token);

  if (!decodedToken) return false;

  // Check for token expiration
  const currentTime = Math.floor(Date.now() / 1000);
  if (decodedToken.exp < currentTime) {
    toast.error("Token has expired. Please log in again.");
    return false;
  }

  return true;
};


export  function getOrganizationId(){
  const token = localStorage.getItem("accessToken");

  if (!token) return null;

  const decodedToken = jwtDecode(token);;
  if (!decodedToken) return null;

  return decodedToken.organization.id;
}