import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { ReactComponent as UserIcon } from "../../app/assets/UserIcon.svg";
// import { ReactComponent as BellIcon } from "../../app/assets/BellIcon.svg";
import { ReactComponent as SettingsIcon } from "../../app/assets/SettingsIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../customHooks/useApi";
import { logoutUser } from "../../features/auth/authThunks";
import { fetchUserProfile } from "../../pages/UserProfile/Redux/UserProfileThunk";
import { jwtDecode } from "jwt-decode";
import { setroleType } from "../../features/auth/authSlice";

const Header = ({ PageIcon, pageTitle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profile_pic_url } = useSelector(
    (state) => state.userProfile.userProfileForm
  );
  const  role_type  = jwtDecode(localStorage.getItem("accessToken")).member.role_type;
  // console.log(role_type)
  const api = useApi();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  async function handleLogout() {
    await dispatch(logoutUser({ api }));
    navigate("/login");
  }
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  useEffect(() => {
    dispatch(fetchUserProfile({ api }));
    dispatch(setroleType(role_type))
  }, []);
  return (
    <nav
      className="flex items-center justify-between p-4 relative bg-white"
      id="nav-bar"
    >
      <div className="flex items-center relative">
        <PageIcon className="colored-svg fill-current mr-4 w-6 h-6" />
        {pageTitle}
      </div>

      <div className="flex group items-center space-x-2 relative">
        {/* <BellIcon className="cursor-pointer" /> */}
       { role_type==="Admin"&&<Link to="/settings" style={{ textDecoration: "none" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: ".5rem",
            }}
          >
            <SettingsIcon className="cursor-pointer" />
          </div>
        </Link>}
        {profile_pic_url ? (
          <img
            className="w-10 h-10 cursor-pointer rounded-full flex flex-shrink-0"
            src={profile_pic_url}
            alt="profile"
            onClick={toggleDropdown}
          />
        ) : (
          <img
            className="w-10 h-10 cursor-pointer rounded-full flex flex-shrink-0"
            src={
              "https://th.bing.com/th/id/OIP.iYpFSu2O2kVP1OptEdJ-uwHaHx?rs=1&pid=ImgDetMain"
            }
            alt="profile"
            onClick={toggleDropdown}
          />
        )}
        {/* <UserIcon className="cursor-pointer"  /> */}
        {isDropdownOpen && (
          <div className="absolute top-10 right-0 mt-2 w-48 z-50 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="py-1 bg-white ">
              <Link to="/profile" style={{ textDecoration: "none" }}>
                <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                  User Profile
                </div>
              </Link>
              <div
                style={{ textDecoration: "none" }}
                onClick={handleLogout}
                className="cursor-pointer"
              >
                <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                  Logout
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
