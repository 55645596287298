import React, { useState } from 'react';

const InputField = ({ label, startPlaceholder, endPlaceholder }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [maxEndDate, setMaxEndDate] = useState('');

  // Function to calculate end of financial year based on startDate
  const calculateFinancialYearEnd = (start) => {
    const startMonth = start.getMonth();
    const startYear = start.getFullYear();
    
    if (startMonth < 3) { // Jan, Feb, Mar (treated as previous financial year)
      return new Date(startYear, 2, 31); // March 31 of the same year
    } else { // April and beyond
      return new Date(startYear + 1, 2, 31); // March 31 of the following year
    }
  };

  const handleStartDateChange = (e) => {
    const selectedStartDate = new Date(e.target.value + '-01'); // Convert month input to Date
    setStartDate(e.target.value);

    // Calculate the max end date for the financial year based on selected start date
    const calculatedEndDate = calculateFinancialYearEnd(selectedStartDate);
    setMaxEndDate(calculatedEndDate.toISOString().slice(0, 7)); // Format as 'yyyy-mm'
    
    // Reset endDate if it exceeds the new financial year end
    if (endDate && endDate > maxEndDate) {
      setEndDate('');
    }
  };

  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    
    // Ensure end date is within the max allowed date and not before start date
    if (selectedEndDate <= maxEndDate && selectedEndDate >= startDate) {
      setEndDate(selectedEndDate);
    } else {
      alert('Please select an end date within the financial year range.');
    }
  };

  return (
    <div className="flex flex-col">
      <label className="text-sm font-medium mb-2">{label}</label>
      <div className="flex items-center space-x-4">
        <input
          type="month"
          value={startDate}
          onChange={handleStartDateChange}
          placeholder={startPlaceholder}
          className="border rounded-md p-2 w-40 text-gray-700 focus:outline-none"
        />
        <span className="text-gray-500">to</span>
        <input
          type="month"
          min={startDate}
          value={endDate}
          onChange={handleEndDateChange}
          placeholder={endPlaceholder}
          max={maxEndDate} // Set the max attribute dynamically based on start date
          className="border rounded-md p-2 w-40 text-gray-700 focus:outline-none"
        />
      </div>
    </div>
  );
};

export default InputField;
