import React, { useEffect, useState } from "react";
import Header from "../../components/common/Header";
import AnalyzeHeader from "../../components/Analyze/AnalyzeHeader";
import { ReactComponent as Scope3Icon } from "../../app/assets/RowMaterial.svg";
import { ReactComponent as FilterMenuIcon } from "../../app/assets/FilterMenuIcon.svg";

import {
  currentYearLastYearEmissionDetail,
  getCurrentFinancialYear,
} from "../VisualizationFeature/Redux/Processdata";
import { useDispatch, useSelector } from "react-redux";
import { fetchChartScope3DataThunk } from "../VisualizationFeature/Redux/chartthunk";
import Filter from "../VisualizationFeature/components/Filter";
import Category1Main from "./scope3/Category1Main";
import useApi from "../../customHooks/useApi";
import { calculateYOYChangeForCat6Data } from "../VisualizationFeature/Redux/processData/ProcessDataforCategory6";
import { calculateYOYChangeForCat1Data } from "../VisualizationFeature/Redux/processData/ProcessDataforCategory1";
function AnalyzeScope3category1() {
  const dispatch = useDispatch();
  const [category1cardData, setcategory1CardData] = useState([]);
  const api = useApi();
  const {
    s3c1financialYear,
    s3c1siteOptions,
    category1Data,
    dataForCurrentYearChange,
    itemOptions,
    supplierOptions,
  } = useSelector((state) => state.chart);
  const [currentFinancialYear, setCurrentFinancialYear] = useState(
    s3c1financialYear.length ? s3c1financialYear[0] : ""
  );
  const [emissionChange, setEmissonChange] = useState({});
  const [filterBy, setFilterBy] = useState({
    supplier: "",
    item: "",
    site: "",
  });

  const [StackBreakDown] = useState(["production_type", "item"]);
  const [BreakDownOptions] = useState([
    { value: "production_type", label: "Production Type" },
    { value: "site", label: "Facility" },
    { value: "item", label: "Item name" },
    { value: "supplier", label: "Supplier" },
    { value: "country", label: "Country of origin" },
  ]);
  const [chartDataArray] = useState([
    { title: "Emissions by Item", dataKey: "item" },
    { title: "Emissions by Supplier", dataKey: "supplier" },

    { title: "Emissions by Facility", dataKey: "site" },
    { title: "Emissions by Production type", dataKey: "production_type" },
    { title: "Emissions by Country of origin", dataKey: "country" },
  ]);

  const emissionData = [
    {
      title: "Emissions",
      info: (emissionChange?.currentYear || 0) + " Kg Co2e",
      time: "due to Purchased Goods and Services",
      status:
        (emissionChange?.percentageChange || 0) > 0 ? "positive" : "negative",
      per: `${
        emissionChange?.percentageChange !== Infinity
          ? `${Math.abs(emissionChange?.percentageChange) || 0}% YOY${
              emissionChange?.percentageChange > 0 ? "↑" : "↓"
            }`
          : ""
      }`,
      year: currentFinancialYear,
      theme: "red",
    },
    // {
    //   title: "Purchesed goods and Services to revenue ratio",
    //   info: (emissionChange?.currentYear || 0) + " Co2e",
    //   time: "",
    //   status:
    //     (emissionChange?.percentageChange || 0) > 0 ? "positive" : "negative",
    //   per: `${
    //     emissionChange?.percentageChange !== Infinity
    //       ? `${Math.abs(emissionChange?.percentageChange) || 0}% YOY${
    //           emissionChange?.percentageChange > 0 ? "↑" : "↓"
    //         }`
    //       : ""
    //   }`,
    //   theme: "yellow",
    // },
  ];

  // console.log(emissionChange)

  const handleFilterChange = (name, value) => {
    if (name === "financialYear") {
      setCurrentFinancialYear(value);
    } else {
      setFilterBy((prevFilterBy) => ({
        ...prevFilterBy,
        [name]: value,
      }));
    }
  };
  useEffect(() => {
    setEmissonChange(
      currentYearLastYearEmissionDetail(
        dataForCurrentYearChange,
        Number(currentFinancialYear)
      )
    );
    const data=calculateYOYChangeForCat1Data(category1Data, isNaN(Number(currentFinancialYear))
    ? Number(s3c1financialYear[0])
    : Number(currentFinancialYear))
 
    const safeValue = (value) => (isFinite(value) && !isNaN(value) ? value : 0);
 
    const formatPercentage = (percentageChange) => {
      if (!isFinite(percentageChange) || isNaN(percentageChange)) {
        return "0% YOY↓";
      }
      const direction = percentageChange > 0 ? "↑" : "↓";
      return `${Math.abs(percentageChange)}% YOY${direction}`;
    };
    setcategory1CardData([
      {
        title: "Number of Items",
        value: safeValue(data?.item?.currentYear),
        percentage: formatPercentage(data?.item?.percentageChange),
        year:currentFinancialYear
      },
      {
        title: "Number of Suppliers",
        value: `${safeValue(data?.supplier?.currentYear)}  `,
        percentage: formatPercentage(data?.supplier?.percentageChange),
        year:currentFinancialYear
      },
    ]);
  }, [category1Data, currentFinancialYear]);
  useEffect(() => {
    dispatch(fetchChartScope3DataThunk({ api }));
  }, []);

  useEffect(() => {
    if (s3c1financialYear.length > 0) {
      setCurrentFinancialYear(s3c1financialYear[0]);
    }
  }, [s3c1financialYear]);
  return (
    <>
      <Header
        PageIcon={Scope3Icon}
        pageTitle={"Purchased Goods and Services"}
      />
      <div className="flex flex-col main-container w-full px-10 py-6">
        <AnalyzeHeader
          emissionData={emissionData}
          energyData={[]}
          category={category1cardData}
        />
        <div className="flex flex-col  rounded-md mt-[18px]  w-full border p-2">
          <div className="flex justify-between py-2 items-center  bg-gray-100 rounded">
            <span className="text-xl px-2"></span>
            <div className="flex justify-between items-center gap-2 px-2">
              <FilterMenuIcon className="cursor-pointer" />
              <Filter
                label="Financial Year"
                options={s3c1financialYear}
                selectedValue={currentFinancialYear}
                onChange={(value) => handleFilterChange("financialYear", value)}
              />
              <Filter
                label="Facility"
                options={s3c1siteOptions}
                selectedValue={filterBy.site}
                onChange={(value) => handleFilterChange("site", value)}
              />
              <Filter
                label="Item"
                options={itemOptions}
                selectedValue={filterBy.item}
                onChange={(value) => handleFilterChange("item", value)}
              />
              <Filter
                label="Supplier"
                options={supplierOptions}
                selectedValue={filterBy.supplier}
                onChange={(value) => handleFilterChange("supplier", value)}
              />
            </div>
          </div>
          <div>
            <Category1Main
              StackBreakDown={StackBreakDown}
              Options={BreakDownOptions}
              currentFinancialYear={
                isNaN(Number(currentFinancialYear))
                  ? Number(s3c1financialYear[0])
                  : Number(currentFinancialYear)
              }
              height={"600px"}
              width={"100%"}
              filterBy={filterBy}
              data={category1Data}
              chartDataArray={chartDataArray}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AnalyzeScope3category1;
