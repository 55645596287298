/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { ReactComponent as Scope3Icon } from "../../app/assets/Category4.svg";
import { ReactComponent as CopyIcon } from "../../app/assets/CopyIcon.svg";
import { ReactComponent as PencilIcon } from "../../app/assets/PencilIcon.svg";
import { ReactComponent as DeleteIcon } from "../../app/assets/DeleteIcon.svg";
import { ReactComponent as AddIconBig } from "../../app/assets/AddIconBig.svg";
import { ReactComponent as DownloadIcon } from "../../app/assets/DownloadIcon.svg";
import { ReactComponent as UserIcon } from "../../app/assets/UserIcon.svg";

import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import {
  resetscope3Category9Form,
  setCurrentPage,
  setFilters,
  setItemsPerPage,
  setenergySourceData,
  setfiltredenergySourceData,
  setfiltredvehicleSpecificatioonData,
  setfiltredvehicleTypeData,
  setlogisticModeData,
  setscope3Category9Form,
  setvehicleSpecificatioonData,
  setvehicleTypeData,
} from "../../features/scope3/category9/Scope3Category9Slice";
import Header from "../../components/common/Header";
import TableMeta from "../../components/common/Table/TableMeta";
import Table from "../../components/common/Table/Table";
import TablePagination from "../../components/common/Table/TablePagination";
import Purchase_s3c2_Form from "../../components/scope3/category9/Purchases3c9Form";
import Purchases3c9EditAndCopyForm from "../../components/scope3/category9/Purchases3c9EditAndCopyForm";
import {
  deletescope3Category9Data,
  fetchCurrencyData,
  fetchEnergySourceTypeData,
  fetchLogisticmodeData,
  fetchSiteData,
  fetchUnitData,
  fetchVehicleSpecificationData,
  fetchVehicleTypeData,
  fetchscope3Category9InputData,
  fetchscope3Category9ItemData,
} from "../../features/scope3/category9/Scope3Category9Thunk";
import {
  generateLogisticsData,
  getUniqueLogisticData,
} from "./function/UniqueLogisticMode";
import useApi from "../../customHooks/useApi";
import { filterEnergySourceType, filterSpecificationsByVehicleType } from "./function/filterspecifications";
import FilterComponent from "../../components/common/FilterComponent";
import { useSearchParams } from "react-router-dom";
// import { getUniqeLogisticData } from "./function/UniqueLogisticMode";

const Scope3Category9 = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const api = useApi();
  const dispatch = useDispatch();
  const scope3Category9InputRecords = useSelector(
    (state) => state.scope3Category9.scope3Category9InputRecords
  );
  const [files, setfiles] = useState(null);
  const itemsPerPage = useSelector(
    (state) => state?.scope3Category9?.itemsPerPage
  );
  const scope3Category9Form = useSelector(
    (state) => state?.scope3Category9?.scope3Category9Form
  );
  const totalPages = useSelector((state) => state?.scope3Category9?.totalPages);
  const read_only = useSelector((state) => state?.auth?.read_only);
  const totalCount = useSelector((state) => state?.scope3Category9?.totalCount);
  const currentPage = useSelector(
    (state) => state?.scope3Category9?.currentPage
  );
  let logisticModeData = useSelector(
    (state) => state.scope3Category9.logisticModeData
  );
  let {
    scope3Category9ItemData,
    vehicleTypeData,
    vehicleSpecificatioonData,
    energySourceData,
    siteDataForFilter,
    scope3Category9ItemDataFilter,
  } = useSelector((state) => state.scope3Category9);
  const [logisticData, setlogisticData] = useState([]);
  const [itemselected, setItemSelected] = useState({});
  const [dataRows, setDataRows] = useState([]);
  const [isPurchaseFormOpen, setPurchaseFormOpen] = useState(false);
  const [manualEntry, setManualEntry] = useState(true);
  const [apiIntegration, setApiIntegration] = useState(false);
  const [trendLine, setTrendLine] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [actionType, setActionType] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isEditCopyFormOpen, setIsEditCopyFormOpen] = useState(false);

  const headingsToDataKeyMap = {
    " ": "",
    DATE: "date",
    SITE: "site",
    "SOURCE LOCATION": "source_location",
    "DESTINATION LOCATION": "destination_location",
    "DISTANCE (km)": "distance",
    "LOGISTIC MODE": "logistic_mode",
    "VEHICLE TYPE": "vehicle_type",
    "VEHICLE SPECIFICATION": "vehicle_specification",
    "Volume/ Mass Carried": "volume_mass_carried",
    "Volume/ Mass Unit": "volume_mass_unit",
    "VOLUME/MASS ALLOCATION %": "mass_allocation",
    "ENERGY SOURCE": "energy_source",
    "LOGISTIC SPEND": "logistic_spend",
    CURRENCY: "currency",
    "ITEM CARRIED": "item_carried",
    // SUPPLIER: "supplier",
    // "PRODUCTION TYPE": "item_production_type",
    // 'SUPPLIER CODE': "supplier_code",
    EVIDENCE: "evidence",
    STATUS: "status",
    // COMMENTS: "comments",
    // EDIT: "edit",
    // DELETE:""
  };
  const filters = [
    // {
    //   name: "date",
    //   label: "Date",
    //   type:"date",
    //   options:[],
    // },
    {
      name: "site_id",
      label: "Facility",
      options:
        siteDataForFilter &&
        siteDataForFilter.map((site) => {
          return {
            value: site.id,
            label: site.site,
          };
        }),
    },
    {
      name: "logistic_mode",
      label: "Logistic mode",
      options:
        logisticModeData &&
        getUniqueLogisticData(logisticModeData).map((site) => {
          return {
            value: site.id,
            label: site.logistic_mode,
          };
        }),
    },
    {
      name: "item_carried",
      label: "Item carried",
      options:
        scope3Category9ItemDataFilter &&
        scope3Category9ItemDataFilter.map((site) => {
          return {
            value: site.id,
            label: site.combination,
          };
        }),
    },
  ];
  const handleFilterChange = (newFilters) => {
    dispatch(fetchscope3Category9InputData({ api, params: newFilters }));
    const updatedParams = new URLSearchParams(newFilters).toString();
    dispatch(setFilters(updatedParams));
    setSearchParams(updatedParams);
  };
  if (!read_only) {
    headingsToDataKeyMap.EDIT = "edit";
    headingsToDataKeyMap.DELETE = "delete";
  }
  const handleDelete = (row) => {
    if (row.id) {
      dispatch(deletescope3Category9Data({ api, id: row.id }));
    } else {
      toast.error("Failed please try again");
    }
  };
  const handleEdit = (row) => {
    dispatch(resetscope3Category9Form());
    setActionType("edit");
    setSelectedRowData(row);
    setIsEditCopyFormOpen(true);
  };

  const handleCopy = (row) => {
    dispatch(resetscope3Category9Form());
    setActionType("copy");
    setSelectedRowData(row);
    setIsEditCopyFormOpen(true);
  };

  const handleCloseEditCopyForms = () => {
    setIsEditCopyFormOpen(false);
  };

  const switchTab = (tabId) => {
    switch (tabId) {
      case 1:
        setManualEntry(true);
        setApiIntegration(false);
        setTrendLine(false);
        break;
      case 2:
        setManualEntry(false);
        setApiIntegration(true);
        setTrendLine(false);
        break;
      case 3:
        setManualEntry(false);
        setApiIntegration(false);
        setTrendLine(true);
        break;
      default:
        setManualEntry(true);
        setApiIntegration(false);
        setTrendLine(false);
        break;
    }
    setSelectedTab(tabId);
  };

  const openPopupForm = () => {
    dispatch(resetscope3Category9Form());
    setPurchaseFormOpen(true);
  };

  const closePurchasePopupForm = () => {
    setPurchaseFormOpen(false);
  };

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const handleItemsPerPage = (itemCount) => {
    dispatch(setItemsPerPage(itemCount));
  };

  const handleFileDownload = (filePath) => {
    if (filePath) {
      const link = document.createElement("a");
      link.href = filePath;
      link.download = "filename.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast("No evidence attached");
    }
  };

  const generateCellHtml = (row, k) => {
    let cellHtml = null;
    switch (k) {
      case "Scope3_type":
        cellHtml = (
          <span className="w-full flex justify-center">
            <span
              className={`px-2 py-1 border border-transparent rounded-md ${
                row["source_type"].toString().toLowerCase() ===
                "Renewable".toLowerCase()
                  ? "bg-green-200 text-green-600"
                  : "bg-red-200 text-red-600"
              }`}
            >
              {row[k]}
            </span>
          </span>
        );
        break;
      case "edit":
        cellHtml = (
          <span className="w-full flex justify-center">
            <PencilIcon
              className="cursor-pointer"
              onClick={() => handleEdit(row)}
            />
          </span>
        );
        break;
      case "delete":
        cellHtml = (
          <span className="w-full flex justify-center">
            <DeleteIcon
              className="cursor-pointer"
              onClick={() => handleDelete(row)}
            />
          </span>
        );
        break;
      case "status":
        cellHtml = (
          <span className="w-full flex justify-center">
            <span
              className={`px-2 py-1 border border-transparent rounded-md ${
                row[k] === "Accepted"
                  ? "bg-green-200 text-green-600"
                  : row[k] === "Submitted"
                  ? "bg-blue-200 text-blue-600"
                  : "bg-red-200 text-red-600"
              }`}
            >
              {row[k]}
            </span>
          </span>
        );
        break;
      case "evidence":
        cellHtml = (
          <span className="w-full flex justify-center">
            <DownloadIcon
              className="cursor-pointer fill-green-500"
              onClick={() => handleFileDownload(row[k])}
            />
          </span>
        );
        break;
      case "submitted_by":
      case "approved_by":
        cellHtml = (
          <span className="w-full flex justify-center">
            <img
              src={`${UserIcon}`}
              className="cursor-pointer w-[32px] h-[32px] border border-transparent rounded-[50%]"
              alt={`${k}`}
            />
          </span>
        );
        break;
      default:
        cellHtml = <span className="w-full flex justify-center">{row[k]}</span>;
        break;
    }
    return cellHtml;
  };

  const handleFormChange = async (e) => {
    const { name, type, value } = e.target;
    // console.log(value,scope3Category9ItemData)
    if (name === "logistic_mode" && logisticModeData) {
      let selected = logisticModeData?.find(
        (item) => item.logistic_mode === value
      );
      setItemSelected(selected, logisticModeData);
      // console.log(getUniqeLogisticData(logisticModeData))
      dispatch(
        setscope3Category9Form({
          ...scope3Category9Form,
          [name]: value,
        })
      );

      const filtered = logisticData.find((item) => item?.logistic_mode == value);

      dispatch(setfiltredvehicleTypeData(filtered?.vehicle_types||[]));
      dispatch(setfiltredvehicleSpecificatioonData([]));
      dispatch(setfiltredenergySourceData([]));
    } else if (name === "vehicle_type" && logisticModeData) {
    
      dispatch(
        setscope3Category9Form({
          ...scope3Category9Form,
          [name]: value,
          // vehicle_type: selected?.vehicle_type_id,
          // vehicle_specification: selected?.specification_id,
          // energy_source: selected?.fuel_id,
        })
      );

      const filteredSpecs = filterSpecificationsByVehicleType(
        logisticData,
        scope3Category9Form.logistic_mode,
        value
      );
      dispatch(setfiltredvehicleSpecificatioonData(filteredSpecs));
      dispatch(setfiltredenergySourceData([]))
    }else if(name === "vehicle_specification"){
      dispatch(
        setscope3Category9Form({
          ...scope3Category9Form,
          [name]: value,
          // vehicle_type: selected?.vehicle_type_id,
          // vehicle_specification: selected?.specification_id,
          // energy_source: selected?.fuel_id,
        })
      );
    
    

    const filteredSpecs = filterEnergySourceType(logisticModeData ,
      scope3Category9Form.logistic_mode,
      scope3Category9Form.vehicle_type,
      value);
    
        dispatch(setfiltredenergySourceData(filteredSpecs))
       
    } else if (name === "item_carried" && scope3Category9ItemData) {
      let selected = scope3Category9ItemData.find((item) => item.id === +(value));


      dispatch(
        setscope3Category9Form({
          ...scope3Category9Form,
          [name]: value,
          production_type: selected.production_type,
          supplier: selected.supplier,
          supplier_code: selected.supplier_code,
          supplier_registration_number: selected.supplier_registration_number,
          item_code: selected.item_code,
          item_description: selected.item_description,
        })
      );
    } else if (type === "file") {
      const files = e.target.files;
      if (!files) {
        toast("Please select a file");
        return;
      }
      setfiles(files);
    } else {
      dispatch(
        setscope3Category9Form({ ...scope3Category9Form, [name]: value })
      );
    }
  };
  useEffect(() => {
    let logisticsData = generateLogisticsData(
      logisticModeData,
      energySourceData,
      vehicleSpecificatioonData,
      vehicleTypeData
    );
    setlogisticData(logisticsData);
  }, [
    logisticModeData,
    energySourceData,
    vehicleSpecificatioonData,
    vehicleTypeData,
  ]);
  useEffect(() => {
    let transformedDataRows = scope3Category9InputRecords?.map((row) => {
      let transformedDataRow = [];
      transformedDataRow.push(
        <span className="w-full flex justify-center">
          <CopyIcon
            className={`${
              read_only ? "cursor-not-allowed opacity-15" : "cursor-pointer"
            }`}
            onClick={() => !read_only && handleCopy(row)}
          />
        </span>
      );
      Object.values(headingsToDataKeyMap).forEach((k) => {
        if (k.toString().trim() !== "") {
          transformedDataRow.push(generateCellHtml(row, k));
        }
      });
     
      return transformedDataRow;
    });

    setDataRows(transformedDataRows);
  }, [scope3Category9InputRecords]);
  // console.log(scope3Category9Form);
  useEffect(() => {
    const filters = Object.fromEntries(searchParams.entries());
    dispatch(fetchscope3Category9InputData({ api, params: filters }));
    dispatch(fetchscope3Category9ItemData({ api }));
    dispatch(fetchCurrencyData({ api }));
    dispatch(fetchSiteData({ api }));
    dispatch(fetchVehicleSpecificationData({ api }));
    dispatch(fetchVehicleTypeData({ api }));
    dispatch(fetchUnitData({ api }));
    dispatch(fetchLogisticmodeData({ api }));
    dispatch(fetchEnergySourceTypeData({ api }));
  }, []);

  return (
    <>
      <Header
        PageIcon={Scope3Icon}
        pageTitle={"Downstream Transportation and Distribution"}
      />

      <div className="flex flex-col main-container w-full px-10 py-6">
        <div className="flex  justify-between items-center text-xs">
          <div className="flex"></div>
          <div className="flex">
            <FilterComponent
              filters={filters}
              onFilterChange={handleFilterChange}
            />
          </div>
        </div>
        <div className="flex flex-col border border-gray-300 rounded-md mt-[10px] relative">
          <TableMeta
            recordType={1}
            totalCount={totalCount}
            openPopUpForm={openPopupForm}
            switchTab={switchTab}
            selectedTab={selectedTab}
          />
          {manualEntry && (
            <>
              <Table
                headings={Object.keys(headingsToDataKeyMap)}
                dataRows={dataRows}
                paginationObject={{
                  paginationEnabled: true,
                  currentPage: currentPage,
                  totalPages: totalPages,
                  itemsPerPage: itemsPerPage,
                  handleItemsPerPage: handleItemsPerPage,
                  handlePageChange: handlePageChange,
                }}
              />
              {!read_only && (
                <div className="flex  w-fit py-4 px-2 absolute bottom-8 right-6 cursor-pointer">
                  <AddIconBig onClick={() => openPopupForm(1)} />
                </div>
              )}
              <TablePagination
                paginationObject={{
                  paginationEnabled: true,
                  currentPage: currentPage,
                  totalPages: totalPages,
                  itemsPerPage: itemsPerPage,
                  handleItemsPerPage: handleItemsPerPage,
                  handlePageChange: handlePageChange,
                }}
              />
            </>
          )}
          {apiIntegration && (
            <>
              <h1>API Integration tab</h1>
            </>
          )}
          {trendLine && (
            <>
              <h1>TrendLine tab</h1>
            </>
          )}
        </div>
      </div>

      {isPurchaseFormOpen && (
        <Purchase_s3c2_Form
          files={files}
          logisticData={logisticData}
          handleFormChange={handleFormChange}
          itemSelected={itemselected}
          closePurchasePopupForm={closePurchasePopupForm}
        />
      )}
      {isEditCopyFormOpen && (
        <Purchases3c9EditAndCopyForm
          closePurchasePopupForm={() => setIsEditCopyFormOpen(false)}
          logisticData={logisticData}
          selectedRowData={selectedRowData}
          handleCloseEditCopyForms={handleCloseEditCopyForms}
          actionType={actionType}
        />
      )}
    </>
  );
};

export default Scope3Category9;
