/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { ReactComponent as Scope3Icon } from "../../app/assets/EmployeeCommuting.svg";
import { ReactComponent as CopyIcon } from "../../app/assets/CopyIcon.svg";
import { ReactComponent as PencilIcon } from "../../app/assets/PencilIcon.svg";
import { ReactComponent as DeleteIcon } from "../../app/assets/DeleteIcon.svg";
import { ReactComponent as AddIconBig } from "../../app/assets/AddIconBig.svg";
import { ReactComponent as DownloadIcon } from "../../app/assets/DownloadIcon.svg";
import { ReactComponent as UserIcon } from "../../app/assets/UserIcon.svg";

import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import {
  resetscope3Category7Form,
  setCurrentPage,
  setFilters,
  setItemsPerPage,
  setSubtypeDataFiltred,
  setfilteredTypeData,
  setfiltredFuelTypeData,
  setscope3Category7Form,
} from "../../features/scope3/category7/Scope3Category7Slice";
import Header from "../../components/common/Header";
import TableMeta from "../../components/common/Table/TableMeta";
import Table from "../../components/common/Table/Table";
import TablePagination from "../../components/common/Table/TablePagination";
import Purchase_s3c7_Form from "../../components/scope3/category7/Purchases3c7Form";
import Purchases3c7EditAndCopyForm from "../../components/scope3/category7/Purchases3c7EditAndCopyForm";
import {
  deletescope3Category7Data,
  fetchExpenseUnitData,
  fetchFuelTypeData,
  fetchSiteData,
  fetchTravelSubTypeData,
  fetchTravelTypeData,
  fetchmodeOfTravelMappingData,
  fetchscope3Category7InputData,
  fetchscope3Category7ScaledData,
} from "../../features/scope3/category7/Scope3Category7Thunk";

import useApi from "../../customHooks/useApi";
import { generatemodeOfTravelsData, getUniquemodeOfTravelData } from "./function/UniqueLogisticMode";
import SendEmailForm from "../../components/scope3/category7/SendEmailForm";
import { removeDuplicates } from "./function/removeDuplicates";
import FilterComponent from "../../components/common/FilterComponent";
import { useSearchParams } from "react-router-dom";

const Scope3Category7 = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const api = useApi();
  const dispatch = useDispatch();
  const scope3Category7InputRecords = useSelector(
    (state) => state.scope3Category7.scope3Category7InputRecords
  );
  const [files, setfiles] = useState(null);
  const itemsPerPage = useSelector(
    (state) => state?.scope3Category7?.itemsPerPage
  );
  const scope3Category7Form = useSelector(
    (state) => state?.scope3Category7?.scope3Category7Form
  );
  const { typeData, subType, modeOfTravelMapping, fuelType ,siteDataForFilter,pincodes} = useSelector(
    (state) => state.scope3Category7
  );
  const totalPages = useSelector((state) => state?.scope3Category7?.totalPages);
  const read_only = useSelector((state) => state?.auth?.read_only);
  const totalCount = useSelector((state) => state?.scope3Category7?.totalCount);
  const currentPage = useSelector(
    (state) => state?.scope3Category7?.currentPage
  );
  const [modeOfTravel, setmodeOfTravel] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  const [financialYearData, setfinancialYearData] = useState([]);
  const [financialYear, setfinancialYear] = useState("");
  const [isPurchaseFormOpen, setPurchaseFormOpen] = useState(false);
  const [manualEntry, setManualEntry] = useState(true);
  const [apiIntegration, setApiIntegration] = useState(false);
  const [trendLine, setTrendLine] = useState(false);
  const [formInput, setformInput] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [actionType, setActionType] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isEditCopyFormOpen, setIsEditCopyFormOpen] = useState(false);
  const [itemselected, setItemSelected] = useState({});

  const headingsToDataKeyMap = {
    " ": "",
    "EMPLOYEE ID": "employee_id",
    "FINANCIAL YEAR": "financial_year",
    FACILITY: "site",
    "JOINING DATE": "joining_date",
    PINCODE: "pincode",
    "MODE OF TRAVEL": "mode_of_travel",
    "TRAVEL TYPE": "travel_type",
    "TRAVEL SUBTYPE": "travel_subtype",
    "FUEL TYPE": "fuel_type",
    "NUMBER OF WORKING DAYS": "number_of_working_days",
    DISTANCE: "distance",
    EXPENSE: "expense",
    "EXPENSE UNIT": "expense_unit",
    EVIDENCE: "evidence",
    COMMENTS: "comments",
    STATUS: "status",
    // EDIT: "edit",
    // DELETE: "",
  };
  if(!read_only){
    headingsToDataKeyMap.EDIT="edit"
    headingsToDataKeyMap.DELETE="delete"
  }
  const handleDelete = (row) => {
    if (row.id) {
      dispatch(deletescope3Category7Data({ api, id: row.id }));
    } else {
      toast.error("Failed please try again");
    }
  };
  const handleEdit = (row) => {
    dispatch(resetscope3Category7Form());
    dispatch(setfilteredTypeData([]));
    dispatch(setSubtypeDataFiltred([]));
    dispatch(setfiltredFuelTypeData([]));
    setActionType("edit");
    setSelectedRowData(row);
    setIsEditCopyFormOpen(true);
  };

  const handleCopy = (row) => {
    dispatch(resetscope3Category7Form());
    dispatch(setfilteredTypeData([]));
    dispatch(setSubtypeDataFiltred([]));
    dispatch(setfiltredFuelTypeData([]));
    setActionType("copy");
    setSelectedRowData(row);
    setIsEditCopyFormOpen(true);
  };

  const handleCloseEditCopyForms = () => {
    setIsEditCopyFormOpen(false);
  };

  const switchTab = (tabId) => {
    switch (tabId) {
      case 1:
        setManualEntry(true);
        setApiIntegration(false);
        setTrendLine(false);
        setformInput(false);
        break;
      case 2:
        setManualEntry(false);
        setApiIntegration(true);
        setTrendLine(false);
        setformInput(false);
        break;
      case 3:
        setManualEntry(false);
        setApiIntegration(false);
        setTrendLine(true);
        setformInput(false);
        break;

      case 4:
        setManualEntry(false);
        setApiIntegration(false);
        setTrendLine(false);
        setformInput(true);
        break;
    }
    setSelectedTab(tabId);
  };

  const openPopupForm = () => {
    dispatch(resetscope3Category7Form());
    dispatch(setfilteredTypeData([]));
    dispatch(setSubtypeDataFiltred([]));
    dispatch(setfiltredFuelTypeData([]));
    setPurchaseFormOpen(true);
  };

  const closePurchasePopupForm = () => {
    setPurchaseFormOpen(false);
  };

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const handleItemsPerPage = (itemCount) => {
    dispatch(setItemsPerPage(itemCount));
  };

  const handleFileDownload = (filePath) => {
    if (filePath) {
      const link = document.createElement("a");
      link.href = filePath;
      link.download = "filename.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast("No evidence attached");
    }
  };

  const generateCellHtml = (row, k) => {
    let cellHtml = null;
    switch (k) {
      case "Scope3_type":
        cellHtml = (
          <span className="w-full flex justify-center">
            <span
              className={`px-2 py-1 border border-transparent rounded-md ${
                row["source_type"].toString().toLowerCase() ===
                "Renewable".toLowerCase()
                  ? "bg-green-200 text-green-600"
                  : "bg-red-200 text-red-600"
              }`}
            >
              {row[k]}
            </span>
          </span>
        );
        break;
        case "edit":
        cellHtml = (
          <span className="w-full flex justify-center">
            <PencilIcon
              className="cursor-pointer"
              onClick={() => handleEdit(row)}
            />
          </span>
        );
        break;
        case "delete":
          cellHtml = (
            <span className="w-full flex justify-center">
            <DeleteIcon
              className="cursor-pointer"
              onClick={() => handleDelete(row)}
            />
          </span>
          );
          break;
      case "status":
        cellHtml = (
          <span className="w-full flex justify-center">
            <span
              className={`px-2 py-1 border border-transparent rounded-md ${
                row[k] === "Accepted"
                  ? "bg-green-200 text-green-600"
                  : row[k] === "Submitted"
                  ? "bg-blue-200 text-blue-600"
                  : "bg-red-200 text-red-600"
              }`}
            >
              {row[k]}
            </span>
          </span>
        );
        break;
      case "evidence":
        cellHtml = (
          <span className="w-full flex justify-center">
            <DownloadIcon
              className="cursor-pointer fill-green-500"
              onClick={() => handleFileDownload(row[k])}
            />
          </span>
        );
        break;
      case "submitted_by":
      case "approved_by":
        cellHtml = (
          <span className="w-full flex justify-center">
            <img
              src={`${UserIcon}`}
              className="cursor-pointer w-[32px] h-[32px] border border-transparent rounded-[50%]"
              alt={`${k}`}
            />
          </span>
        );
        break;
      default:
        cellHtml = <span className="w-full flex justify-center">{row[k]}</span>;
        break;
    }
    return cellHtml;
  };

  useEffect(() => {
    if (modeOfTravelMapping && fuelType && subType && typeData) {
      let modeOfTravel = generatemodeOfTravelsData(
        modeOfTravelMapping,
        fuelType,
        subType,
        typeData
      );
      setmodeOfTravel(modeOfTravel);
    }
  }, [modeOfTravelMapping, fuelType, subType, typeData]);
  const handleFormChange = async (e) => {
    const { name, type, value } = e.target;

    if (type === "file") {
      const files = e.target.files;
      if (!files) {
        toast("Please select a file");
        return;
      }
      setfiles(files);
    } else if (name === "mode_of_travel" && modeOfTravelMapping) {
      let selected = modeOfTravelMapping?.find(
        (item) => item.mode_of_travel === value
      );
      setItemSelected(selected, modeOfTravelMapping);

      const filtered = modeOfTravel.find(
        (item) => item.mode_of_travel === value
      );
      dispatch(
        setscope3Category7Form({
          ...scope3Category7Form,
          [name]: value,
          travel_type: "",
          travel_subtype: "",
          air_conditioned: "",
          fuel_type: "",
        })
      );
      dispatch(setfilteredTypeData(filtered.travel_type));
      dispatch(setSubtypeDataFiltred([]));
      dispatch(setfiltredFuelTypeData([]));
    } else if (name === "travel_type" && modeOfTravelMapping) {
      dispatch(
        setscope3Category7Form({
          ...scope3Category7Form,
          [name]: value,
          travel_subtype: "",
          air_conditioned: "",
          fuel_type: "",
        })
      );
      const filtered = modeOfTravel
        ?.find(
          (item) => item.mode_of_travel === scope3Category7Form.mode_of_travel
        )
        .travel_subtype.filter(
          (item) => Number(item.travel_type_id) === Number(value)
        )
        .map((i) => {
          return {
            ...i,
            id: i.travel_subtype_id,
          };
        });

      dispatch(setSubtypeDataFiltred(filtered));
      dispatch(setfiltredFuelTypeData([]));
    } else if (
      name === "travel_subtype" &&
      modeOfTravelMapping &&
      scope3Category7Form.mode_of_travel
    ) {
      dispatch(
        setscope3Category7Form({
          ...scope3Category7Form,
          [name]: value,
          air_conditioned: "",
          fuel_type: "",
        })
      );
      const filtered = removeDuplicates(
        modeOfTravelMapping
          ?.filter((item) => {
            if (
              Number(item.travel_subtype_id) === Number(value) &&
              item.mode_of_travel === scope3Category7Form.mode_of_travel &&
              Number(item.travel_type_id) ===
                Number(scope3Category7Form.travel_type)
            ) {
              return true;
            }
          })
          ?.map((i) => {
            return {
              fuel_type: i.fuel_type,
              id: i.fuel_type_id,
            };
          }),
        "id"
      );

      dispatch(setfiltredFuelTypeData(filtered));
    } else {
      dispatch(
        setscope3Category7Form({ ...scope3Category7Form, [name]: value })
      );
    }
  };
  useEffect(() => {
    let transformedDataRows = scope3Category7InputRecords?.map((row) => {
      let transformedDataRow = [];
      transformedDataRow.push(
        <span className="w-full flex justify-center">
        <CopyIcon
          className={`${read_only ? 'cursor-not-allowed opacity-15' : 'cursor-pointer'}`}
          onClick={() => !read_only && handleCopy(row)}
        />
      </span>
      );
      Object.values(headingsToDataKeyMap).forEach((k) => {
        if (k.toString().trim() !== "") {
          transformedDataRow.push(generateCellHtml(row, k));
        }
      });
      transformedDataRow.push(
        // <span className="w-full flex justify-center">
        //   <PencilIcon
        //     className="cursor-pointer"
        //     onClick={() => handleEdit(row)}
        //   />
        // </span>,
        // <span className="w-full flex justify-center">
        //   <DeleteIcon
        //     className="cursor-pointer"
        //     onClick={() => handleDelete(row)}
        //   />
        // </span>
      );
      return transformedDataRow;
    });

    setDataRows(transformedDataRows);
  }, [scope3Category7InputRecords]);
  useEffect(() => {
    const initializeData = async () => {
      const filters = Object.fromEntries(searchParams.entries());
      await dispatch(fetchmodeOfTravelMappingData({ api }));
      await dispatch(fetchscope3Category7InputData({ api,params:filters }));
      await dispatch(fetchFuelTypeData({ api }));
      await dispatch(fetchTravelTypeData({ api }));
      await dispatch(fetchTravelSubTypeData({ api }));
      await dispatch(fetchExpenseUnitData({ api }));
      await dispatch(fetchSiteData({ api }));
    };
    initializeData();
    const generateFinancialYears = () => {
      const currentYear = new Date().getFullYear();
      const startYear = 1950;
      const years = [];

      for (let year = startYear; year <= currentYear; year++) {
        const nextYear = (year + 1).toString().slice(2);
        // years.push(`FY ${year}-${nextYear}`);
        years.push(`${year}`);
      }

      return years.reverse();
    };
    setfinancialYearData(generateFinancialYears());
  }, []);
  const handleChangeFinancialYear = (e) => {
    const { value } = e.target;
    setfinancialYear(value);
  };
  function fetchscaledData() {
    if (financialYear) {
      dispatch(fetchscope3Category7ScaledData({ api, financialYear }));
    } else {
      toast.error("Please select financial year", {
        theme: "dark",
      });
    }
  }
  const filters = [
    // {
    //   name: "date",
    //   label: "Date",
    //   options:[],
    //   type:'date'
    // },
    {
      name: "financial_year",
      label: "Financial year",
      options:financialYearData&&financialYearData.map(fin=>{
        return {
          value:fin,
          label:fin
        }
      }),
    },
    {
      name: "site_id",
      label: "Facility",
      options:siteDataForFilter&&siteDataForFilter.map(site=>{
        return {
          value:site.id,
          label:site.site
        }
      }),
    },
    {
      name: "pincode",
      label: "Pincode",
      options:pincodes&&pincodes?.map(pincode=>{
        return {
          value:pincode,
          label:pincode
        }
      }),
    },
    {
      name: "mode_of_travel",
      label: "Mode of travel",
      options:["Rail","Road"].map(pin=>{
        return {
          value:pin,
          label:pin
        }
      }),
    },
  ];
  const handleFilterChange = (newFilters) => {
    // fetchData(newFilters);
    dispatch(fetchscope3Category7InputData({ api,params:newFilters }));
    const updatedParams = new URLSearchParams(newFilters).toString();
    dispatch(setFilters(updatedParams))
    setSearchParams(updatedParams);
  };
  return (
    <>
      <Header PageIcon={Scope3Icon} pageTitle={"Employee Commute"} />

      <div className="flex flex-col main-container w-full px-10 py-6">
        <div className="flex  justify-between items-center text-xs">
          <div className="flex gap-2">
            <div className="flex">
              <select
                onChange={handleChangeFinancialYear}
                name="financial_year"
                className="flex justify-center p-1 border cursor-pointer border-green-600 rounded-md"
              >
                <option value="">Select financial year </option>
                {scope3Category7InputRecords &&
                  [
                    ...new Set(
                      scope3Category7InputRecords.map((i) => i.financial_year)
                    ),
                  ].map((year) => <option key={year} value={year}>{year}</option>)}{" "}
              </select>
            </div>
            <button
            disabled={read_only}
              className={`p-2 px-2 bg-green-600 text-white rounded font-medium ${read_only?"cursor-not-allowed opacity-15":"cursor-pointer"}`}
              onClick={fetchscaledData}
            >
              Calculate Emissions
            </button>
          </div>
       
          <div className="flex">
          
         
          <div className="flex mb-3">
            <FilterComponent
              filters={filters}
              onFilterChange={handleFilterChange}
            />
          </div>
        </div>
        
        </div>
        <div className="flex flex-col border border-gray-300 rounded-md mt-[10px] relative">
          <TableMeta
            recordType={4}
            totalCount={totalCount}
            openPopUpForm={openPopupForm}
            switchTab={switchTab}
            selectedTab={selectedTab}
          />
          {manualEntry && (
            <>
              <Table
                headings={Object.keys(headingsToDataKeyMap)}
                dataRows={dataRows}
                paginationObject={{
                  paginationEnabled: true,
                  currentPage: currentPage,
                  totalPages: totalPages,
                  itemsPerPage: itemsPerPage,
                  handleItemsPerPage: handleItemsPerPage,
                  handlePageChange: handlePageChange,
                }}
              />
              {!read_only&& <div className="flex  w-fit py-4 px-2 absolute bottom-8 right-6 cursor-pointer">
                <AddIconBig onClick={() => openPopupForm(1)} />
              </div>}
              <TablePagination
                paginationObject={{
                  paginationEnabled: true,
                  currentPage: currentPage,
                  totalPages: totalPages,
                  itemsPerPage: itemsPerPage,
                  handleItemsPerPage: handleItemsPerPage,
                  handlePageChange: handlePageChange,
                }}
              />
            </>
          )}
          {apiIntegration && (
            <>
              <h1>API Integration tab</h1>
            </>
          )}
          {formInput && (
            <>
              <SendEmailForm handleFormChange={handleFormChange} />
            </>
          )}
          {trendLine && (
            <>
              <h1>TrendLine tab</h1>
            </>
          )}
        </div>
      </div>

      {isPurchaseFormOpen && (
        <Purchase_s3c7_Form
          formInput={formInput}
          files={files}
          modeOfTravel={modeOfTravel}
          handleFormChange={handleFormChange}
          itemSelected={itemselected}
          financialYearData={financialYearData}
          closePurchasePopupForm={closePurchasePopupForm}
        />
      )}
      {isEditCopyFormOpen && (
        <Purchases3c7EditAndCopyForm
          closePurchasePopupForm={() => setIsEditCopyFormOpen(false)}
          selectedRowData={selectedRowData}
          modeOfTravel={modeOfTravel}
          financialYearData={financialYearData}
          handleCloseEditCopyForms={handleCloseEditCopyForms}
          actionType={actionType}
        />
      )}
    </>
  );
};

export default Scope3Category7;
