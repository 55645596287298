export function processData(Inputdata, timeInterval, breakpoint, year) {
  let data;
  // console.log("Inputdata",Inputdata,Inputdata, timeInterval, breakpoint, year)
  if (year) {
    data = Inputdata.filter((entry) => entry.year === year);
    data.sort((a, b) => a.quarter - b.quarter);
  } else {
    if (timeInterval === "Quarter") {
      function sortByYearAndQuarter(a, b) {
        if (a.year !== b.year) {
          return a.year - b.year;
        } else {
          return a.quarter - b.quarter;
        }
      }

      Inputdata.sort(sortByYearAndQuarter);
      data = Inputdata;
    } else {
      function sortByYearAndMonth(a, b) {
        if (a?.year !== b?.year) {
          return a.year - b.year;
        } else {
          const monthsOrder = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sepr",
            "Oct",
            "Nov",
            "Dec",
          ];
          return (
            monthsOrder?.indexOf(a?.month) - monthsOrder?.indexOf(b?.month)
          );
        }
      }

      Inputdata.sort(sortByYearAndMonth);
      data = Inputdata;
    }
  }

  let processedData = {};
  // console.log(data)
  data.forEach((entry) => {
    let { year, quarter, total_co2e_kg, month } = entry;
    total_co2e_kg = Number(total_co2e_kg);
    year = Number(year);
    let filter = entry[breakpoint] || "Total";

    switch (timeInterval) {
      case "Year":
        if (!processedData[year]) {
          processedData[year] = {};
        }
        if (!processedData[year][filter]) {
          processedData[year][filter] = {
            totalEmission: 0,
          };
        }
        processedData[year][filter].totalEmission += total_co2e_kg;
        break;

      case "Quarter":
        const quarterKey = `${year}-Q${quarter}`;
        if (!processedData[quarterKey]) {
          processedData[quarterKey] = {};
        }
        if (!processedData[quarterKey][filter]) {
          processedData[quarterKey][filter] = {
            totalEmission: 0,
          };
        }
        processedData[quarterKey][filter].totalEmission += total_co2e_kg;
        break;

      case "Month":
        const monthKey = `${year}-${month}`;
        if (!processedData[monthKey]) {
          processedData[monthKey] = {};
        }
        if (!processedData[monthKey][filter]) {
          processedData[monthKey][filter] = {
            totalEmission: 0,
          };
        }
        processedData[monthKey][filter].totalEmission += total_co2e_kg;
        break;

      default:
        break;
    }
  });

  return processedData;
}

export function processDataToLablesAndDataSets(data) {
  // console.log(105,data)
  let series = [];
  let legends = [];
  let xAxisLabels = Object.keys(data);
  for (const timeKey in data) {
    for (const key in data[timeKey]) {
      if (!legends.includes(key)) {
        legends.push(key);
      }
    }
  }

  for (const legend of legends) {
    let seriesData = [];

    for (const timeKey of xAxisLabels) {
      if (data[timeKey][legend]) {
        seriesData.push(data[timeKey][legend].totalEmission);
      } else {
        seriesData.push(0); // Placeholder for missing data
      }
    }

    series.push({
      name: legend,
      type: "pie",
      radius: ["50%", "70%"], // Inner and outer radius for donut chart
      center: ["50%", "50%"],
      label: {
        formatter: "{b}: {d}%",
      },
      data: xAxisLabels.map((label, index) => ({
        name: label,
        value: seriesData[index],
      })),
    });
  }

  return { xAxisLabels, series };
}

export const calculateChange = (current, previous) => {
  if (previous === null || previous === 0 || current === null || current === 0)
    return 0;
  return (
    ((parseFloat(current) - parseFloat(previous)) / parseFloat(previous)) * 100
  );
};

export const calculateEmissionToRevenueRatio = (emissions, revenue) => {
  if (revenue === null || revenue === 0) return 0;
  return (emissions / revenue).toFixed(2);
};

export const getMonthName = (monthNumber) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[monthNumber - 1] || "";
};

export const getEmissionsForSameMonthAndYear = (
  data,
  month,
  year,
  breakdownType,
  breakdownKey
) => {
  // console.log(year);
  return data
    ?.filter((item) => {
      return (
        parseInt(item.year) === Number(year) &&
        item[breakdownType] === breakdownKey
      );
    })
    .reduce((total, item) => total + item.total_co2e_kg, 0);
};

// Function to aggregate data by item and supplier
export function calculateAggregatedData(data) {
  const aggregatedDataByItem = {};
  const aggregatedDataBySupplier = {};

  data.forEach((entry) => {
    // Aggregate by item
    if (!aggregatedDataByItem[entry.item]) {
      aggregatedDataByItem[entry.item] = { ...entry };
    } else {
      aggregatedDataByItem[entry.item].total_co2e_kg += entry.total_co2e_kg;
    }

    // Aggregate by supplier
    if (!aggregatedDataBySupplier[entry.supplier]) {
      aggregatedDataBySupplier[entry.supplier] = { ...entry };
    } else {
      aggregatedDataBySupplier[entry.supplier].total_co2e_kg +=
        entry.total_co2e_kg;
    }
  });

  return { aggregatedDataByItem, aggregatedDataBySupplier };
}

export function calculateEmissionByitemandSupplier(
  data,
  currentMonth,
  currentYear
) {
  const Data = calculateAggregatedData(data);
  const { aggregatedDataByItem, aggregatedDataBySupplier } = Data;

  const itemsWithMetrics = Object.values(aggregatedDataByItem).map((item) => {
    const previousYearEmissions = getEmissionsForSameMonthAndYear(
      data,
      currentMonth,
      currentYear - 1,
      "item",
      item.item
    );
    // console.log(Data, previousYearEmissions);
    return {
      year: currentYear,
      item: item.item,
      total_co2e_kg: parseFloat(item.total_co2e_kg).toFixed(2),
      emission_to_revenue_ratio: calculateEmissionToRevenueRatio(
        item.total_co2e_kg,
        item.amount_paid
      ),
      change: parseFloat(
        calculateChange(
          getEmissionsForSameMonthAndYear(
            data,
            currentMonth,
            currentYear,
            "item",
            item.item
          ),
          previousYearEmissions
        )
      ).toFixed(2),
    };
  });

  const suppliersWithMetrics = Object.values(aggregatedDataBySupplier).map(
    (supplier) => {
      const previousYearEmissions = getEmissionsForSameMonthAndYear(
        data,
        currentMonth,
        currentYear - 1,
        "supplier",
        supplier.supplier
      );
      return {
        supplier: supplier.supplier,
        total_co2e_kg: parseFloat(supplier.total_co2e_kg).toFixed(2),
        emission_to_revenue_ratio: calculateEmissionToRevenueRatio(
          supplier.total_co2e_kg,
          supplier.amount_paid
        ),
        change: parseFloat(
          calculateChange(
            getEmissionsForSameMonthAndYear(
              data,
              currentMonth,
              currentYear,
              "supplier",
              supplier.supplier
            ),
            previousYearEmissions
          )
        ).toFixed(2),
      };
    }
  );

  const sortedItems = [...itemsWithMetrics].sort((a, b) => b.change - a.change);
  const sortedSuppliers = [...suppliersWithMetrics].sort(
    (a, b) => b.change - a.change
  );
  // console.log({ sortedItems, sortedSuppliers });
  return {
    sortedItems,
    sortedSuppliers,
  };
}
export function generateSunburstChartDataCategory7(
  data,
  text = "title",
  level1Key,
  level2Key,
  mode_of_travel,
  currentFinancialYear
) {
  const chartData = {};
  let totalValue = 0;
  const valueKey = "total_co2e_kg";
  const distanceKey = "scaled_distance";
  const agregatedKey = "agregated_total_kg_co2e_per_unit";
  // Calculate total value and filter data based on mode_of_travel
  if (mode_of_travel) {
    data = data.filter((item) => item.mode_of_travel === mode_of_travel);
  }
  if (currentFinancialYear) {
    data = data.filter((item) => item.year === currentFinancialYear);
  }

  data.forEach((entry) => {
    totalValue += parseFloat(entry[valueKey]);
  });

  // const allEmployees = new Set(data.map(i => i.employee_id)).size;
  const marchMonthData = [...data.filter((entry) => entry.month === "Mar")];
  const aggregatedMarchData = {};

  marchMonthData.forEach((entry) => {
    const level1 = entry[level1Key];
    const level2 = entry[level2Key];
    const value = parseFloat(entry[valueKey]);
    // const distance = parseFloat(entry["yearly_aggregated_distance"]);
    const employee_count = Number(entry["count_total_employee"]);
    const count_wrt_combination = Number(entry["count_wrt_combination"]);
    const count_wrt_submitted_form = Number(entry["count_wrt_submitted_form"]);
    const yearly_aggregated_total_emission = parseFloat(
      entry["yearly_aggregated_total_emission"]
    );
   
    if (!aggregatedMarchData[level1]) {
      aggregatedMarchData[level1] = {
        // value: 0,
        employee_count,
        children: {},
        distance: 0,
        yearly_aggregated_total_emission: 0,
        count_wrt_combination: 0,
        count_wrt_submitted_form,
        employees: employee_count,
      };
    }

    if (!aggregatedMarchData[level1]?.children[level2]) {
      aggregatedMarchData[level1].children[level2] = {
        employee_count,
        distance: 0,
        yearly_aggregated_total_emission: 0,
        count_wrt_combination: 0,
        count_wrt_submitted_form,

        employees: employee_count,
      };
    }

    // aggregatedMarchData[level1].value += value;
    // aggregatedMarchData[level1].distance += distance;
    aggregatedMarchData[level1].count_wrt_combination += count_wrt_combination;
    aggregatedMarchData[level1].yearly_aggregated_total_emission +=
      yearly_aggregated_total_emission;

    // aggregatedMarchData[level1].children[level2].value += value;
    // aggregatedMarchData[level1].children[level2].distance += distance;
    aggregatedMarchData[level1].children[level2].count_wrt_combination +=
      count_wrt_combination;
    aggregatedMarchData[level1].children[
      level2
    ].yearly_aggregated_total_emission += yearly_aggregated_total_emission;
  });
  const totalDistance = data.reduce(
    (acc, current) => acc + Number(current.distance_covered),
    0
  );
  data.forEach((entry) => {
    const level1 = entry[level1Key];
    const level2 = entry[level2Key];
    const value = parseFloat(entry[valueKey]);
    const distance = parseFloat(entry[distanceKey]);


    const agregated_total_kg_co2e = parseFloat(entry[agregatedKey]);
    const percentage = (value / totalValue) * 100;
    if (!chartData[level1]) {
      chartData[level1] = {
        value: 0,
        percentage: 0,
        children: {},
        distance: 0,
        totalDistance,
        employee_count:aggregatedMarchData[level1]
        ? aggregatedMarchData[level1]?.employee_count:0,
        employees: aggregatedMarchData[level1]
          ? aggregatedMarchData[level1].employees *
            (aggregatedMarchData[level1].count_wrt_combination /
              aggregatedMarchData[level1].count_wrt_submitted_form)
          : 0,
      };
    }

    if (!chartData[level1].children[level2]) {
      chartData[level1].children[level2] = {
        value,
        percentage,
        employee_count:aggregatedMarchData[level1]?.children[level2]
        ? aggregatedMarchData[level1]?.children[level2]?.employee_count:0,
        distance: distance,
        agregated_total_kg_co2e,
        totalDistance,
        employees: aggregatedMarchData[level1]?.children[level2]
          ? aggregatedMarchData[level1]?.children[level2]?.employees *
            (aggregatedMarchData[level1]?.children[level2]
              ?.count_wrt_combination /
              aggregatedMarchData[level1].children[level2]
                ?.count_wrt_submitted_form)
          : 0,
      };
    }else{
      chartData[level1].children[level2].value += value;
      chartData[level1].children[level2].percentage += percentage;
      chartData[level1].children[level2].distance += distance;
    }

    chartData[level1].value += value;
    chartData[level1].percentage = (chartData[level1].value / totalValue) * 100;
    chartData[level1].distance += distance;
  });

  const echartsData = [];
  Object.keys(chartData).forEach((level1) => {
    const { value, percentage, children, distance, employees,totalDistance,employee_count } =
      chartData[level1];
    const level1EmployeesCount = employees;

    const level1Data = {
      name: `${level1 || "total"}`,
      totalDistance,employee_count,
      children: [],
      percentage: `${percentage.toFixed()}%`,
      label: `${level1 || "total"}`,
      value,
      distance,
      employees: level1EmployeesCount,
    };

    Object.keys(children).forEach((level2) => {
      const {
        value: childValue,
        percentage: childPercentage,
        distance: childDistance,
        employees: childEmployees,

      } = children[level2];
      const level2EmployeesCount = childEmployees;
      const level2Data = {
        name: `${level2 || "total"}`,
        value: childValue,
        totalDistance,employee_count,
        percentage: `${childPercentage.toFixed()}%`,
        label: `${level2 || "total"}`,
        distance: childDistance,

        employees: level2EmployeesCount,
      };
      level1Data.children.push(level2Data);
    });
    echartsData.push(level1Data);
  });

  const sunburstOptions = {
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
  tooltip: {
  trigger: "item",
  formatter: (params) => {
    const { value, percentage, distance, employees } = params.data;
  
      const {totalDistance, employee_count}= params?.data?.children?params?.data?.children[0]:params.data;

    // console.log({value, percentage, distance, employees, totalDistance, employee_count});
    
    const actualEmployees = employees ?? employee_count ?? 0;  // Use employees, if undefined then employee_count, if 
    const actualDistance = distance ?? totalDistance ?? 0;    // Use distance, if undefined then totalDistance, if 
    
    return `${
      params.data.label || "Total Emission"
    } (${percentage || "100%"})<br/> Emission: ${value?.toFixed() || 0} kg CO2e <br/> Emission per employee: ${(
      value / actualEmployees
    )?.toFixed() || 0} kg CO2e <br/> Employees: ${actualEmployees.toFixed()} <br/> Distance covered per employee: ${(
      actualDistance / actualEmployees
    )?.toFixed() || 0} km`;
  },
},

    title: {
      text,
      left: "center",
    },
    series: {
      type: "sunburst",
      data: echartsData,
      radius: [70, "80%"],
      label: {
        show: true,
        formatter: (params) => {
          return `${params.data.label || "Total"} (${params.data.percentage || "100%"})`;
        },
        rotate: 45,
        fontWeight: 600,
      },
      itemStyle: {
        borderRadius: 5,
        borderWidth: 2,
      },
    },
  };

  return sunburstOptions;
}

export function calculatePublicTransportData(data, year) {
  // data=data.filter(i=>i.month==="Mar")
  const filteredDataCurrent = data.filter((i) => i.year == year);
  const filteredDataLastYear = data.filter((i) => i.year == year - 1);

  const publicTransportCurrent = filteredDataCurrent
    .filter((i) => i.travel_type === "Bus" || i.travel_type === "Train")
    .reduce((acc, current) => acc + Number(current.distance_covered), 0);

  const publicTransportLast = filteredDataLastYear
    .filter((i) => i.travel_type === "Bus" || i.travel_type === "Train")
    .reduce((acc, current) => acc + Number(current.distance_covered), 0);

  const totalDistanceCurrent = data.reduce(
    (acc, current) => acc + Number(current.distance_covered),
    0
  );

  const resultCurrent =
    isFinite(publicTransportCurrent) &&
    isFinite(totalDistanceCurrent) &&
    totalDistanceCurrent !== 0
      ? (publicTransportCurrent / totalDistanceCurrent) * 100
      : 0;

  const resultLast =
    isFinite(publicTransportLast) &&
    isFinite(totalDistanceCurrent) &&
    totalDistanceCurrent !== 0
      ? publicTransportLast / totalDistanceCurrent
      : 0;
  const percentageChange =
    resultLast !== 0 ? ((resultCurrent - resultLast) / resultLast) * 100 : 0;

  // console.log({ resultCurrent, percentageChange, totalDistanceCurrent });
  return {
    resultCurrent: resultCurrent.toFixed(1),
    percentage: percentageChange.toFixed(1),
  };
}
export function calculateYOYChangeForCat6Data(data, year) {
  const filteredDataCurrent = data.filter((i) => i.year == year);
  const filteredDataLastYear = data.filter((i) => i.year == year - 1);
  const numberOfTripsCurrent = filteredDataCurrent.length;
  const numberOfTripsLastYear = filteredDataLastYear.length;

  const tripsChangePercentage =
    numberOfTripsLastYear !== 0
      ? ((numberOfTripsCurrent - numberOfTripsLastYear) /
          numberOfTripsLastYear) *
        100
      : 0;

  const allEmployees = Number(data[0]?.number_of_employee) || 0;

  let currentYear = year;
  if (!currentYear) {
    return {
      currentYear: 0,
      previousYear: 0,
      percentageChange: 0,
    };
  }

  const currentYearData = data?.filter((entry) => entry.year === currentYear);
  const previousYearData = data?.filter(
    (entry) => entry.year === currentYear - 1
  );

  const totalEmissionsCurrentYear =
    currentYearData?.reduce(
      (total, entry) => total + Number(entry.total_co2e_kg),
      0
    ) / allEmployees;

  const totalEmissionsPreviousYear =
    previousYearData?.reduce(
      (total, entry) => total + Number(entry.total_co2e_kg),
      0
    ) /
    (numberOfTripsLastYear * allEmployees);

  const percentageChange =
    totalEmissionsPreviousYear !== 0
      ? ((totalEmissionsCurrentYear - totalEmissionsPreviousYear) /
          totalEmissionsPreviousYear) *
        100
      : 0;

  const roundedPercentageChange = isFinite(percentageChange)
    ? Math.floor(percentageChange)
    : 0;

  return {
    perEmployeeTripRate: {
      currentYear: isFinite(totalEmissionsCurrentYear)
        ? totalEmissionsCurrentYear.toFixed()
        : "0",
      previousYear: isFinite(totalEmissionsPreviousYear)
        ? Math.floor(totalEmissionsPreviousYear)
        : "0",
      percentageChange: roundedPercentageChange,
    },
    trips: {
      numberOfTripsCurrent,
      percentageChange: isFinite(tripsChangePercentage)
        ? tripsChangePercentage.toFixed()
        : "0",
    },
  };
}
