
import React, { useState } from 'react';
import GenericForm from './GenericForm';
import DataDisplay from '../../../pages/BRSR/BRSRContent/DataView/DataDisplay';
import { useDispatch, useSelector } from 'react-redux';
import { postSocialEmpDemographicInputs } from '../../../features/social/socialThunk';
import useApi from '../../../customHooks/useApi';

const EmployeeTypeFormPage = ({closePopup}) => {
  const [selectedTab, setSelectedTab] = useState('form');
  const {employementType,siteData,financialYearData}=useSelector(state=>state.social)
  const api=useApi()
  const [formData,setFormData]=useState({})
  const dispatch=useDispatch()
  const employeeTypeConfig = {
    title: 'Employee Type Form',
    siteOptions: siteData, 
    isSiteOption:true,
    financialYearOptions:financialYearData, 
    sections: [
      {
        key: 'employee_type',
        label: 'Employee Type',
        category_label: 'Gender',
        rows: [
          { key: 'permanent_employees', label: 'Permanent Employees' },
          { key: 'temporary_employees', label: 'Temporary Employees' },
          { key: 'non_guaranteed_hours_employees', label: 'Non-Guaranteed Hours Employees' },
          { key: 'full_time_employees', label: 'Full-time Employees' },
          { key: 'part_time_employees', label: 'Part-time Employees' },
        ],

        fields:  [
          { key: 'male', label: 'Male', type: 'number' },
          { key: 'female', label: 'Female', type: 'number' },
          { key: 'other', label: 'Other', type: 'number' },
          { key: 'not disclosed', label: 'Not Disclosed', type: 'number' },
        ],
      },
    ],
  };
  function handleSubmitData(data) {
   
    setFormData(data) 
    dispatch(postSocialEmpDemographicInputs({api,formData:{...data,employement_type:employementType}}))
    closePopup()
    // setSelectedTab('display');
  }
  const renderTab = () => {
    switch (selectedTab) {
      case "form":
        return <GenericForm config={employeeTypeConfig} handleSubmitData={handleSubmitData} />;
      case "display":
        return <DataDisplay config={employeeTypeConfig} data={formData}/>;
      default:
        return null;
    }
  };
  return (
    <div className='h-full w-full p-2 px-6 overflow-auto'>
        {renderTab()}
    </div>
  );
};

export default EmployeeTypeFormPage;
