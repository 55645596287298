import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const HiringAndTurnoverForm = ({handleSubmitData,siteOptions}) => {
  const loading = useSelector((state) => state.common.isLoading);
  const {financialYearData} = useSelector((state) => state.social);
  
  
  const initialData = {
    site: "",
    financialYear: "",
    male: {
      '<20': { recruits: '0', left: '0', total: '0' },
      '20-30': { recruits: '0', left: '0', total: '0' },
      '31-50': { recruits: '0', left: '0', total: '0' },
      '>50': { recruits: '0', left: '0', total: '0' },
    },
    female: {
      '<20': { recruits: '0', left: '0', total: '0' },
      '20-30': { recruits: '0', left: '0', total: '0' },
      '31-50': { recruits: '0', left: '0', total: '0' },
      '>50': { recruits: '0', left: '0', total: '0' },
    },
    other: {
      '<20': { recruits: '0', left: '0', total: '0' },
      '20-30': { recruits: '0', left: '0', total: '0' },
      '31-50': { recruits: '0', left: '0', total: '0' },
      '>50': { recruits: '0', left: '0', total: '0' },
    },
    notDisclosed: {
      '<20': { recruits: '0', left: '0', total: '0' },
      '20-30': { recruits: '0', left: '0', total: '0' },
      '31-50': { recruits: '0', left: '0', total: '0' },
      '>50': { recruits: '0', left: '0', total: '0' },
    },
  };
// console.log(handleSubmitData)
  const [formData, setFormData] = useState(initialData);

  const handleInputChange = (gender, ageGroup, field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [gender]: {
        ...prevState[gender],
        [ageGroup]: {
          ...prevState[gender][ageGroup],
          [field]: value,
        },
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSubmitData(formData)
 
  };

  const renderTable = (gender) => {
    return (
      <>
        <div className="grid grid-cols-4 gap-4 font-semibold text-gray-700 mt-6">
          <div>Age Group</div>
          <div>New Recruits</div>
          <div>Number of Employees Left</div>
          <div>Total Employees</div>
        </div>
        {['<20', '20-30', '31-50', '>50'].map((ageGroup) => (
          <div className="grid grid-cols-4 gap-4 mt-4" key={ageGroup}>
            <div className="text-gray-700">{ageGroup} years</div>
            {['recruits', 'left', 'total'].map((field) => (
              <input
              required
                key={field}
                type="number"
                className="bg-gray-200 text-neutral-700 text-xs py-1.5 px-1 border rounded w-full"
                value={formData[gender][ageGroup][field]}
                onChange={(e) =>
                  handleInputChange(gender, ageGroup, field, e.target.value)
                }
              />
            ))}
          </div>
        ))}
      </>
    );
  };

  return (
    <div className=" bg-white rounded-md w-full h-screen  p-2 px-3">

      <form onSubmit={handleSubmit}>
        <div className='flex justify-between gap-5 mb-4' >
      <div className="w-full ">
          <label className="block text-gray-700 font-medium mb-2" htmlFor="site">
            Choose Site
          </label>
          <select
        
            id="site"
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
            value={formData.site}
            required
            onChange={(e) => setFormData({ ...formData, site: e.target.value })}
          >
            <option value="">Select Site</option>
            {siteOptions&&siteOptions.map(({site,id})=><option value={id}>{site}</option>)}
          </select>
        </div>
        <div className="w-full">
          <label
            className="block text-gray-700 font-medium mb-2"
            htmlFor="financialYear"
          >
            Financial Year
          </label>
          <select
      className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
      value={formData.financialYear}
      required
      onChange={(e) => setFormData({ ...formData, financialYear: e.target.value })}
    >
      <option value="">Select financial year</option>
      {financialYearData.map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </select>

        </div>

        </div>
        {/* Male Section */}
        <div className="mb-6">
          <h3 className="text-xl font-medium text-center">Male</h3>
          {renderTable('male')}
        </div>

        {/* Female Section */}
        <div className="mb-6">
          <h3 className="text-xl font-medium text-center">Female</h3>
          {renderTable('female')}
        </div>

        {/* Other Section */}
        <div className="mb-6">
          <h3 className="text-xl font-medium text-center">Other</h3>
          {renderTable('other')}
        </div>

        {/* Not Disclosed Section */}
        <div className="mb-6">
          <h3 className="text-xl font-medium text-center">Not Disclosed</h3>
          {renderTable('notDisclosed')}
        </div>

        <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
        <button
          type="submit"
          disabled={loading}
          className={`py-2 px-6 text-white text-sm ${
            loading ? 'bg-green-500 cursor-wait' : 'bg-green-600'
          } rounded-md`}
        >
          Save and Continue
        </button>
      </div>
      </form>
    </div>
  );
};

export default HiringAndTurnoverForm;

// import React, { useState } from 'react';
// import GenericForm from './GenericForm';
// import DataDisplay from '../DataView/DataDisplay';

// const HiringAndTurnoverForm = () => {
//   const [selectedTab, setSelectedTab] = useState("form");
//   const [formData, setFormData] = useState({});
//   const maleConfig = {
//     title: "Male",
//     sections: [
//       {
//         key: "employees",
//         label: "Age Group",
//         rows: [
//           { key: "under_20", label: "< 20 years" },
//           { key: "20_to_29", label: "20 ≤ age < 30 years" },
//           { key: "30_to_50", label: "31 ≤ age ≤ 50 years" },
//           { key: "over_50", label: "> 50 years old" },
//         ],
//         fields: [
//           { key: "new_recruits", label: "New Recruits", type: "number" },
//           { key: "employees_left", label: "Employees left", type: "number" },
//           { key: "total_employees", label: "Total Employees", type: "number" },
//         ],
//       },
//     ],
//   };
//   const femaleConfig = {
//     title: "Female",
//     sections: [
//       {
//         key: "employees",
//         label: "Age Group",
//         rows: [
//           { key: "under_20", label: "< 20 years" },
//           { key: "20_to_29", label: "20 ≤ age < 30 years" },
//           { key: "30_to_50", label: "31 ≤ age ≤ 50 years" },
//           { key: "over_50", label: "> 50 years old" },
//         ],
//         fields: [
//           { key: "new_recruits", label: "New Recruits", type: "number" },
//           { key: "employees_left", label: "Employees left", type: "number" },
//           { key: "total_employees", label: "Total Employees", type: "number" },
//         ],
//       },
//     ],
//   };
//   const othersConfig = {
//     title: "Others",
//     sections: [
//       {
//         key: "employees",
//         label: "Age Group",
//         rows: [
//           { key: "under_20", label: "< 20 years" },
//           { key: "20_to_29", label: "20 ≤ age < 30 years" },
//           { key: "30_to_50", label: "31 ≤ age ≤ 50 years" },
//           { key: "over_50", label: "> 50 years old" },
//         ],
//         fields: [
//           { key: "new_recruits", label: "New Recruits", type: "number" },
//           { key: "employees_left", label: "Employees left", type: "number" },
//           { key: "total_employees", label: "Total Employees", type: "number" },
//         ],
//       },
//     ],
//   };
//   const notDisclosedConfig = {
//     title: "Not disclosed",
//     sections: [
//       {
//         key: "employees",
//         label: "Age Group",
//         rows: [
//           { key: "under_20", label: "< 20 years" },
//           { key: "20_to_29", label: "20 ≤ age < 30 years" },
//           { key: "30_to_50", label: "31 ≤ age ≤ 50 years" },
//           { key: "over_50", label: "> 50 years old" },
//         ],
//         fields: [
//           { key: "new_recruits", label: "New Recruits", type: "number" },
//           { key: "employees_left", label: "Employees left", type: "number" },
//           { key: "total_employees", label: "Total Employees", type: "number" },
//         ],
//       },
//     ],
//   };
//   const employeeMetricsConfig = {
//     title: "Employee Age Group Metrics",
//     sections: [
//       {
//         key: "male_employees",
//         label: "Age Group",
//         sub_label: "Male Employees",
//         rows: [
//           { key: "under_20", label: "< 20 years" },
//           { key: "20_to_29", label: "20 ≤ age < 30 years" },
//           { key: "30_to_50", label: "31 ≤ age ≤ 50 years" },
//           { key: "over_50", label: "> 50 years old" },
//         ],
//         fields: [
//           { key: "new_recruits", label: "New recruits", type: "number" },
//           {
//             key: "employees_left",
//             label: "Number of Employees who left",
//             type: "number",
//           },
//           { key: "total_employees", label: "Total Employees", type: "number" },
//         ],
//       },
//       {
//         key: "female_employees",
//         label: "Age Group",
//         sub_label: "Female",
//         rows: [
//           { key: "under_20", label: "< 20 years" },
//           { key: "20_to_29", label: "20 ≤ age < 30 years" },
//           { key: "30_to_50", label: "31 ≤ age ≤ 50 years" },
//           { key: "over_50", label: "> 50 years old" },
//         ],
//         fields: [
//           { key: "new_recruits", label: "New recruits", type: "number" },
//           {
//             key: "employees_left",
//             label: "Number of Employees who left",
//             type: "number",
//           },
//           { key: "total_employees", label: "Total Employees", type: "number" },
//         ],
//       },
//       {
//         key: "other_employees",
//         sub_label: "Other",
//         label: "Age Group",
//         rows: [
//           { key: "under_20", label: "< 20 years" },
//           { key: "20_to_29", label: "20 ≤ age < 30 years" },
//           { key: "30_to_50", label: "31 ≤ age ≤ 50 years" },
//           { key: "over_50", label: "> 50 years old" },
//         ],
//         fields: [
//           { key: "new_recruits", label: "New recruits", type: "number" },
//           {
//             key: "employees_left",
//             label: "Number of Employees who left",
//             type: "number",
//           },
//           { key: "total_employees", label: "Total Employees", type: "number" },
//         ],
//       },
//       {
//         key: "not_disclosed_employees",
//         sub_label: "Not Disclosed",
//         label: "Age Group",
//         rows: [
//           { key: "under_20", label: "< 20 years" },
//           { key: "20_to_29", label: "20 ≤ age < 30 years" },
//           { key: "30_to_50", label: "31 ≤ age ≤ 50 years" },
//           { key: "over_50", label: "> 50 years old" },
//         ],
//         fields: [
//           { key: "new_recruits", label: "New recruits", type: "number" },
//           {
//             key: "employees_left",
//             label: "Number of Employees who left",
//             type: "number",
//           },
//           { key: "total_employees", label: "Total Employees", type: "number" },
//         ],
//       },
//     ],
//   };
//   function handleSubmitData(data) {
//     console.log(data);
//     setFormData(data);
//     setSelectedTab("display");
//   }
//   const renderTab = () => {
//     switch (selectedTab) {
//       case "form":
//         return (
//           <>
//             <GenericForm
//               config={maleConfig}
//               handleSubmitData={handleSubmitData}
//             />
//             <GenericForm
//               config={femaleConfig}
//               handleSubmitData={handleSubmitData}
//             />
//             <GenericForm
//               config={othersConfig}
//               handleSubmitData={handleSubmitData}
//             />
//             <GenericForm
//               config={notDisclosedConfig}
//               handleSubmitData={handleSubmitData}
//             />
//           </>
//         );
//       case "display":
//         return <DataDisplay config={employeeMetricsConfig} data={formData} />;
//       default:
//         return null;
//     }
//   };
//   return (
//     <div className="h-full w-full p-2 px-6 overflow-auto">{renderTab()}</div>
//   );
// };

// export default HiringAndTurnoverForm;
