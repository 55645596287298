
import React, { useState } from 'react';
import GenericForm from './GenericForm';
import DataDisplay from '../../../pages/BRSR/BRSRContent/DataView/DataDisplay';
import useApi from '../../../customHooks/useApi';
import { useDispatch, useSelector } from 'react-redux';
import { postSocialGovernanceDiversityInputs } from '../../../features/social/socialThunk';

const GovernanceDiversityForm = ({closePopup}) => {
  const [selectedTab, setSelectedTab] = useState('form');
  const {employementType,financialYearData}=useSelector(state=>state.social)
  const api=useApi()
  const dispatch=useDispatch()
  const [formData,setFormData]=useState({})
  const governanceDiversityConfig = {
    title: 'Governance Diversity Form',
    financialYearOptions: financialYearData, // Adjust the years as needed
    sections: [
      {
        key: 'governance_metrics',
        label: 'Governance Diversity Metrics',
        rows: [
          { key: 'board_of_directors', label: 'Board of Directors' },
          { key: 'key_management_personnel', label: 'Key Management Personnel' },
        ],
        fields: [
          { key: 'male', label: 'Male', type: 'number' },
          { key: 'female', label: 'Female', type: 'number' },
          { key: 'other', label: 'Other', type: 'number' },
          { key: 'not disclosed', label: 'Not Disclosed', type: 'number' },
        ],
      },
    ],
  };
  
  function handleSubmitData(data) {
    dispatch(postSocialGovernanceDiversityInputs({api,formData:data,employementType}))
    setFormData(data) 
    closePopup()
    // setSelectedTab('display');
  }
  const renderTab = () => {
    switch (selectedTab) {
      case "form":
        return <GenericForm config={governanceDiversityConfig} handleSubmitData={handleSubmitData} />;
      case "display":
        return <DataDisplay config={governanceDiversityConfig} data={formData}/>;
      default:
        return null;
    }
  };
  return (
    <div className='h-full w-full p-2 px-6 overflow-auto'>
        {renderTab()}
    </div>
  );
};

export default GovernanceDiversityForm;
