import React, { useState } from 'react';
import CommentPopup from './CommentPopup';
import { ReactComponent as CheckedIcon } from '../../../../app/assets/RightCheckIcon.svg';
import { ReactComponent as RejectIcon } from '../../../../app/assets/RejectedIcon.svg';
import SavedAlertPopup from './SavedAlertPopUp';

function RequestPanelCard({ user, request, onReject, onGrant, id, onClose }) {
  const [showCommentPopup, setShowCommentPopup] = useState(false);
  const [comment, setComment] = useState('');
  const [isGranting, setIsGranting] = useState(false);
  const [showSavedAlert, setShowSavedAlert] = useState(false);

  const handleAccept = () => {
    setIsGranting(true);
    setShowCommentPopup(true);
  };

  const handleReject = () => {
    setIsGranting(false);
    setShowCommentPopup(true);
  };

  const handleSubmit = () => {
    if (isGranting) {
      onGrant(id, comment);
      setShowSavedAlert(true);
    } else {
      onReject(id, comment);
      setShowSavedAlert(true);
    }
    setShowCommentPopup(false);
  };

  const handleCloseCommentPopup = () => {
    setShowCommentPopup(false);
    setShowSavedAlert(false);
    // onClose();
  };

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  return (
    <div className="flex rounded-3xl w-full justify-between items-center p-2 px-4 border-2 bg-white">
      <p>
        <span>{user}</span> has requested access to{' '}
        <span className="text-green-600">{request}</span>
      </p>
      <div className="space-x-4">
        <button onClick={handleAccept} className="px-8 p-1 rounded-2xl text-white bg-green-500">
          Grant Access
        </button>
        <button onClick={handleReject} className="px-8 p-1 rounded-2xl text-white bg-red-500">
          Reject Access
        </button>
      </div>
      {showCommentPopup && (
        <CommentPopup
          mainText={
            <span>
              Are you sure you want to{' '}
              {isGranting ? (
                <span className="text-green-600">grant access</span>
              ) : (
                <span className="text-red-600">reject access</span>
              )}{' '}
              to {user} for {request}?
            </span>
          }
          
          name={user}
          request={request}
          handleChange={handleChange}
          onClose={handleCloseCommentPopup}
          onSubmit={handleSubmit}
          isGranting={isGranting}
        />
      )}
      {showSavedAlert && (
        <SavedAlertPopup
          text={
            isGranting
              ? 'Access granted'
              : 'Access rejected'
          }
          theme={isGranting ? 'success' : 'error'}
          icon={
            isGranting ? (
              <CheckedIcon fill='green' className=" colored-svg" />
            ) : (
              <RejectIcon />
            )
          }
          onClose={() => setShowSavedAlert(false)}
        />
      )}
    </div>
  );
}

export default RequestPanelCard;
