import React, { useEffect, useState } from "react";
import Header from "../../components/common/Header";
import AnalyzeHeader from "../../components/Analyze/AnalyzeHeader";
import { ReactComponent as Scope3Icon } from "../../app/assets/EmployeeCommuting.svg";
import { ReactComponent as FilterMenuIcon } from "../../app/assets/FilterMenuIcon.svg";

import {
  currentYearLastYearEmissionDetail,
  getCurrentFinancialYear,
} from "../VisualizationFeature/Redux/Processdata";
import { useDispatch, useSelector } from "react-redux";
import { fetchChartScope3Category7DataThunk } from "../VisualizationFeature/Redux/chartthunk";
import Filter from "../VisualizationFeature/components/Filter";
import Category7Main from "./scope3/Category7Main";
import useApi from "../../customHooks/useApi";
import { calculatePublicTransportData } from "../VisualizationFeature/Redux/processData/ProcessDataforCategory7";

function AnalyzeScope3Category7() {
  const api = useApi();
  const [category7Data, setcategory7Data] = useState([]);
  const dispatch = useDispatch();
  const [emissionChange, setEmissonChange] = useState({});
 
  const {
    s3c7financialYear,
    s3c7siteOptions,
    modeOfTravelOptions,
    Scope3Category7Data,
    Scope3Category7DataForCurrentYearChange,
    s3c7pincodeOptions,
  } = useSelector((state) => state.chart);
  const [currentFinancialYear, setCurrentFinancialYear] = useState(s3c7financialYear.length ? s3c7financialYear[0] : '');
    const [filterBy, setFilterBy] = useState({
    mode_of_travel: "",
    travel_type: "",
    site: "",
    source_pincode: "",
  });
  const [StackBreakDown] = useState(["item"]);
  const [BreakDownOptions] = useState([
    { value: "mode_of_travel", label: "Mode of Travel" },
    { value: "site", label: "Facility" },
  ]);
  const [chartDataArray] = useState([
    {
      title: "Emission by Site and Source Pincode",
      L1: "site",
      L2: "source_pincode",
    },
    {
      title: "Emission by Travel Type and SubType",
      L1: "travel_type",
      L2: "travel_subtype",
    },
  ]);
  const [piechartDataArray] = useState([
    { title: "Emissions by mode of travel", dataKey: "mode_of_travel" },
    { title: "Emissions by Site", dataKey: "site" },
  
    { title: "Emission by Travel Type",dataKey:"vehicle_type" },
    { title: "Emission by Travel SubType",dataKey:"travel_subtype" },
    { title: "Emission by Source Pincode",dataKey:"source_pincode" },
  ]);

  const emissionData = [
    {
      title: "Emissions",
      info: (emissionChange?.currentYear || 0) + " Kg Co2e",
      time: "",
      status:
        (emissionChange?.percentageChange || 0) > 0 ? "positive" : "negative",
      per: `${
        emissionChange?.percentageChange !== Infinity
          ? `${Math.abs(emissionChange?.percentageChange) || 0}% YOY${
              emissionChange?.percentageChange > 0 ? "↑" : "↓"
            }`
          : ""
      }`, year:currentFinancialYear,
      theme: "red",
    },
  ];

  const handleFilterChange = (name, value) => {
    if (name === "financialYear") {
      setCurrentFinancialYear(value);
    } else {
      setFilterBy((prevFilterBy) => ({
        ...prevFilterBy,
        [name]: value,
      }));
    }
  };


  useEffect(() => {
    const filtredData = Scope3Category7Data.filter((i) => {
      if (
        i.year ==
        (isNaN(Number(currentFinancialYear))
          ? Number(s3c7financialYear[0])
          : Number(currentFinancialYear))
      ) {
        return true;
      }
    });

    let data = calculatePublicTransportData(
      Scope3Category7DataForCurrentYearChange,
      isNaN(Number(currentFinancialYear))
        ? Number(s3c7financialYear[0])
        : Number(currentFinancialYear)
    );
// console.log({data})
    let currentEmissionData = currentYearLastYearEmissionDetail(
      Scope3Category7DataForCurrentYearChange,
      isNaN(Number(currentFinancialYear))
        ? Number(s3c7financialYear[0])
        : Number(currentFinancialYear)
    );
    setEmissonChange(currentEmissionData);

    setcategory7Data([
      {
        title: "Emission per employee",
        value: isNaN(Number(
          currentEmissionData?.currentYear /filtredData[0]?.count_total_employee
        ))
          ? 0
          : (currentEmissionData?.currentYear /filtredData[0]?.count_total_employee).toFixed() || 0,
        percentage: `${
          currentEmissionData?.percentageChange !== Infinity
            ? `${Math.abs(currentEmissionData?.percentageChange) || 0}% YOY${
                currentEmissionData?.percentageChange > 0 ? "↑" : "↓"
              }`
            : "0% YOY↓"
        }`,
      },
      {
        title: "%Distance by Public Transport",
        value: data.resultCurrent + "%",
        percentage: `${
          data?.percentage !== Infinity
            ? `${Math.abs(data?.percentage) || 0}% YOY${
                data?.percentage > 0 ? "↑" : "↓"
              }`
            : ""
        }`,
      },
    ]);
  }, [Scope3Category7Data, s3c7financialYear,currentFinancialYear]);

  useEffect(() => {
    dispatch(fetchChartScope3Category7DataThunk({ api }));
  }, []);
  useEffect(() => {
    if (s3c7financialYear.length > 0) {
      setCurrentFinancialYear(s3c7financialYear[0]);
    }
  }, [s3c7financialYear]);
  return (
    <>
      <Header PageIcon={Scope3Icon} pageTitle={"Employee commute"} />
      <div className="flex flex-col main-container w-full px-10 py-6">
        <AnalyzeHeader
          emissionData={emissionData}
          energyData={[]}
          category={category7Data}
        />
        <div className="flex flex-col  rounded-md mt-[18px]  w-full border p-2">
          <div className="flex justify-between py-2 items-center  bg-gray-100 rounded">
            <span className="text-xl px-2"></span>
            <div className="flex justify-between items-center gap-2 px-2">
              <FilterMenuIcon className="cursor-pointer" />
              <Filter
                label="Financial Year"
                options={s3c7financialYear}
                selectedValue={currentFinancialYear}
                onChange={(value) => handleFilterChange("financialYear", value)}
              />
              <Filter
                label="Mode of travel"
                options={modeOfTravelOptions}
                selectedValue={filterBy.mode_of_travel}
                onChange={(value) =>
                  handleFilterChange("mode_of_travel", value)
                }
              />
              <Filter
                label="Facility"
                options={s3c7siteOptions}
                selectedValue={filterBy.site}
                onChange={(value) => handleFilterChange("site", value)}
              />
              <Filter
                label="Pincode"
                options={s3c7pincodeOptions}
                selectedValue={filterBy.source_pincode}
                onChange={(value) =>
                  handleFilterChange("source_pincode", value)
                }
              />
            </div>
          </div>
          <div>
            <Category7Main
              piechartDataArray={piechartDataArray}
              StackBreakDown={StackBreakDown}
              Options={BreakDownOptions}
              currentFinancialYear={
                isNaN(Number(currentFinancialYear))
                  ? Number(s3c7financialYear[0])
                  : Number(currentFinancialYear)
              }
              height={"500px"}
              width={"100%"}
              filterBy={filterBy}
              data={Scope3Category7Data}
              chartDataArray={chartDataArray}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AnalyzeScope3Category7;
