import { useDispatch, useSelector } from "react-redux";
import TabContentForSite from "./TabContentForSite";
import { setadminDashboardForm } from "../../Redux/SettingSlices";
import { useEffect, useCallback } from "react";

const TabContentSite = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const { siteData, adminDashboardForm } = useSelector(
    (state) => state.setting
  );

  const siteOptions = siteData.map((i) => ({
    label: i.site,
    value: i.id,
  }));

  const handleSiteSave = useCallback(
    (data) => {
      dispatch(
        setadminDashboardForm({
          permissions: {
            ...adminDashboardForm.permissions,
            sites: data.site,
          },
        })
      );
    
      handleSubmit();
    },
    [dispatch]
  );

  useEffect(() => {
    if (siteData.length > 0 && adminDashboardForm?.permissions?.sites) {
      const selectedSites = siteData
        .filter((i) =>
          adminDashboardForm?.permissions?.sites?.includes(-1)
            ? true
            : adminDashboardForm?.permissions?.sites?.includes(Number(i.id))
        )
        .map((i) => Number(i.id));

      dispatch(
        setadminDashboardForm({

          permissions: {
            ...adminDashboardForm.permissions,
            sites:
              selectedSites.length === siteOptions.length
                ? [-1]
                : selectedSites,
          },
        })
      );
    }
  }, [dispatch]);
 
  return (
    <div>
      <TabContentForSite
        siteOptions={siteOptions}
        onSave={handleSiteSave}
        initialSelectedSites={siteData
          .filter((i) =>
            adminDashboardForm?.permissions?.sites?.includes(-1)
              ? true
              : adminDashboardForm?.permissions?.sites?.includes(Number(i.id))
          )
          .map((i) => ({
            label: i.site,
            value: i.id,
          }))}
      />
    </div>
  );
};

export default TabContentSite;
