export function getUniqueLogisticData(logisticsArray) {
    const output = [];
    logisticsArray.forEach(item => {
        const { id, logistic_mode, specification_id, vehicle_type_id, fuel_id } = item;

        let logisticsUnit = output.find(unit => unit.logistic_mode === logistic_mode);

        if (!logisticsUnit) {
            logisticsUnit = {
                logistic_mode,
                specifications: [],
                vehicle_types: [],
                fuel_types: []
            };
            output.push(logisticsUnit);
        }

        if (!logisticsUnit.specifications.includes(specification_id)) {
            logisticsUnit.specifications.push(specification_id);
        }

        if (!logisticsUnit.vehicle_types.includes(vehicle_type_id)) {
            logisticsUnit.vehicle_types.push(vehicle_type_id);
        }

        if (!logisticsUnit.fuel_types.includes(fuel_id)) {
            logisticsUnit.fuel_types.push(fuel_id);
        }
    });
    return output;
}


export function generateLogisticsData(logisticModeArray, fuelTypesData, vehicleSpecificationsData, vehicleTypesData) {
    // console.log(logisticModeArray, fuelTypesData, vehicleSpecificationsData, vehicleTypesData)
    logisticModeArray = getUniqueLogisticData(logisticModeArray);

    
    const filterByIds = (dataArray, idArray) => {
      
        return dataArray?.filter(item => idArray?.includes(""+item.id));
    };


    const output = [];

  
    logisticModeArray.forEach(mode => {
        const filteredFuelTypes = filterByIds(fuelTypesData, mode.fuel_types);
        const filteredVehicleSpecifications = filterByIds(vehicleSpecificationsData, mode.specifications);
        const filteredVehicleTypes = filterByIds(vehicleTypesData, mode.vehicle_types);

        
        output.push({
            logistic_mode: mode.logistic_mode,
            specifications: filteredVehicleSpecifications,
            vehicle_types: filteredVehicleTypes,
            fuel_types: filteredFuelTypes
        });
    });

    return output;
}
export function getUniquemodeOfTravelData(DataArray) {
    const output = [];
    DataArray.forEach(item => {
        const { id, mode_of_travel, travel_subtype_id, travel_type_id, fuel_type_id ,air_conditioned} = item;
//  console.log(mode_of_travel,item)
        let logisticsUnit = output.find(unit => unit.mode_of_travel === mode_of_travel);

        if (!logisticsUnit) {
            logisticsUnit = {
                mode_of_travel,
                travel_subtype: [],
                travel_type: [],
                fuel_types: [],
                air_conditioned:""
            };
            output.push(logisticsUnit);
        }

        if (!logisticsUnit.travel_subtype.includes(travel_subtype_id)) {
            logisticsUnit.travel_subtype.push(travel_subtype_id);
        }

        if (!logisticsUnit.travel_type.includes(travel_type_id)) {
            logisticsUnit.travel_type.push(travel_type_id);
        }

        if (!logisticsUnit.fuel_types.includes(fuel_type_id)) {
            logisticsUnit.fuel_types.push(fuel_type_id);
        }

        logisticsUnit.air_conditioned = air_conditioned
    });
   
    return output;
}
export function generatemodeOfTravelsData(modeOfTravelModeArray, fuelTypesData, traveSubType, traveType) {
   traveSubType=traveSubType.map(i=>{
    return {...i,
        id:i.travel_subtype_id
    }

})
    let modeOfTravelMode = getUniquemodeOfTravelData(modeOfTravelModeArray);

    
    const filterByIds = (dataArray, idArray) => {
      idArray=idArray.map(Number)
       
        return dataArray?.filter(item => idArray?.includes(item.id));
    };

    const output = [];
    
    
    modeOfTravelMode.forEach(mode => {
        const filteredFuelTypes = filterByIds(fuelTypesData, mode.fuel_types);
        const filteredTravelSubType = filterByIds(traveSubType, mode.travel_subtype);
        const filteredTypes = filterByIds(traveType, mode.travel_type);

      
        output.push({
            mode_of_travel: mode.mode_of_travel,
            travel_subtype: filteredTravelSubType.map(i=>{
                return {...i,
                    id:i.travel_subtype_id
                }

            }),
            travel_type: filteredTypes,
            fuel_types: filteredFuelTypes,
            air_conditioned:mode.air_conditioned
        });
    });

    return output;
}
