import React, { useState } from "react";

// Sample Data (replace with dynamic data as needed)
const sampleData = [
  {
    question: "a. Whether an occupational health & safety management system has been implemented by the entity? (Yes/No). If yes, the coverage of such system?",
    materiality: "High, Sector Dependent",
  },
  {
    question: "b. What are the processes used to identify work-related hazards & assess risks on a routine & non-routine basis by the entity?",
    materiality: "High, Sector Dependent",
  },
  {
    question: "c. Whether you have processes for workers to report the work-related hazards & to remove themselves from such risks. (Y/N)",
    materiality: "High, Sector Dependent",
  },
  {
    question: "d. Do the employees/workers of the entity have access to non-occupational medical & healthcare services? (Yes/No)",
    materiality: "High, Sector Dependent",
  },
];

const HealthSafetyTable = ({ data = sampleData,question }) => {
  // State to store the answers
  const [answers, setAnswers] = useState({});

  // Handler for changing the answer in textarea
  const handleAnswerChange = (index, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [index]: value,

    }));
  };

  // Handler for submitting the answers
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Collected Answers:", answers);
    // Here you can handle the collected answers (e.g., send to API)
  };

  return (
    <form onSubmit={handleSubmit} className="overflow-auto">
      <table className="min-w-full border-collapse border border-gray-300 text-sm text-left">
        <thead>
          <tr className="bg-gray-300 text-xs font-semibold text-gray-700">
            <th className="border border-gray-300 p-2 text-start text-base font-semibold">{question}</th>
            {/* <th className="border border-gray-300 p-2 w-1/4">MATERIALITY</th> */}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <tr className="text-gray-700">
                <td className="border bg-gray-100 border-gray-300 p-2 font-medium">{item.question}</td>
                {/* <td className="border border-gray-300 p-2">{item.materiality}</td> */}
              </tr>
              <tr>
                <td colSpan="2" className="border border-gray-300 p-2">
                  <span className="mr-4 font-semibold">Answer:</span>
                  <textarea
                    value={answers[index] || ""}
                    onChange={(e) => handleAnswerChange(index, e.target.value)}
                    placeholder="Enter your answer here..."
                    className="border rounded-md p-2 w-full text-gray-700 focus:outline-none resize-none"
                    rows="3"
                  />
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <div className="mt-4">
        <button
          type="submit"
          className="bg-green-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-green-600"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default HealthSafetyTable;

// import React from "react";

// // Sample Data (replace with dynamic data as needed)
// const sampleData = [
//   {
//     question: "a. Whether an occupational health & safety management system has been implemented by the entity? (Yes/No). If yes, the coverage of such system?",
//     materiality: "High, Sector Dependent",
//     answer: "Yes", // Static answer data
//   },
//   {
//     question: "b. What are the processes used to identify work-related hazards & assess risks on a routine & non-routine basis by the entity?",
//     materiality: "High, Sector Dependent",
//     answer: "No", // Static answer data
//   },
//   {
//     question: "c. Whether you have processes for workers to report the work-related hazards & to remove themselves from such risks. (Y/N)",
//     materiality: "High, Sector Dependent",
//     answer: "Yes", // Static answer data
//   },
//   {
//     question: "d. Do the employees/workers of the entity have access to non-occupational medical & healthcare services? (Yes/No)",
//     materiality: "High, Sector Dependent",
//     answer: "No", // Static answer data
//   },
// ];

// const HealthSafetyTable = ({ data = sampleData }) => {
//   return (
//     <div className="overflow-auto">
//       <table className="min-w-full border-collapse border border-gray-300 text-sm text-left">
//         <thead>
//           <tr className="bg-gray-100 text-xs font-semibold text-gray-700">
//             <th className="border border-gray-300 p-2 w-3/4">Health and safety management system:</th>
//             <th className="border border-gray-300 p-2 w-1/4">MATERIALITY</th>
//           </tr>
//         </thead>
//         <tbody>
//           {data.map((item, index) => (
//             <React.Fragment key={index}>
//               {/* Question and Materiality Row */}
//               <tr className="text-gray-700">
//                 <td className="border border-gray-300 p-2">{item.question}</td>
//                 <td className="border border-gray-300 p-2">{item.materiality}</td>
//               </tr>
//               {/* Static Answer Row */}
//               <tr>
//                 <td colSpan="1" className="border border-gray-300 p-2 text-gray-600">
//                   {/* <span className="font-semibold">Answer:</span> {item.answer} */}
//                 </td>
//                 <td className="border border-gray-300 p-2">{item.materiality}</td>
//               </tr>
//             </React.Fragment>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default HealthSafetyTable;
