import { ReactComponent as CloseIcon } from "../../app/assets/CloseIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ReactComponent as InfoIcon } from "../../app/assets/InfoIcon.svg";

import { toast } from "react-toastify";
import useApi from "../../customHooks/useApi";


const SocialEditAndCopyForm = ({
  closePurchasePopupForm,
  selectedRowData,
  actionType,
 
}) => {
  const api = useApi();

  const {
 

    socialForm,
  } = useSelector((state) => state.social);
  
  const dispatch = useDispatch();
  const [file, setfile] = useState(null);

  const loading = useSelector((state) => state.common.isLoading);

  const handleFormChange = (e) => {
    const { name, value, type } = e.target;
  
  };
  const handlePurchaseFormSubmit = (e) => {
    e.preventDefault();
    if (!file) {
      toast.warning("Please Upload evidence");
    }
    const form = document.querySelector("#purchase-social-form");

    if (form.length && form.checkValidity()) {
      switch (actionType) {
        case "edit":
          // dispatch(socialUploadEvidence({files: file,api})).then((res) => {
          //   if (res?.payload?.msg === "File uploaded.") {
          //     dispatch(updatesocialData({api,id:selectedRowData.id}));
          //   }
          // });
          // break;
        case "copy":
          // console.log(file);
          // dispatch(socialUploadEvidence({ files: file, api })).then(
          //   (res) => {
          //     if (res?.payload?.msg === "File uploaded.") {
          //       dispatch(postsocialData({ api }));
          //     }
          //   }
          // );
          break;
        default:
          break;
      }
      closePurchasePopupForm();
    } else if (form.length) {
      form?.querySelector('input[type="submit"]').click();
    }
  };

 
  useEffect(() => {
  
  }, [
   
    dispatch,
  ]);

  return (
    <>
      <div className="mainContainer flex w-full h-screen overflow-hidden justify-center items-center z-[100] fixed bg-black bg-opacity-50 top-0 left-0 bottom-0">
        <div className="flex flex-col justify-center items-center w-9/12 text-xs bg-white absolute z-[1000] border border-transparent rounded-t-md">
          <div className="flex justify-between w-full bg-gray-50 py-2 px-4 border-b border-gray-300 mb-3">
            <span className="cursor-pointer">Social</span>
            <span
              className="cursor-pointer"
              onClick={() => closePurchasePopupForm()}
            >
              <CloseIcon />
            </span>
          </div>
          <form
            style={{
              opacity: loading ? 0.5 : 1,
              pointerEvents: loading ? "none" : "auto",
            }}
            id="purchase-social-form"
            className="flex flex-col w-full py-4"
            onSubmit={handlePurchaseFormSubmit}
          >
            <div className="grid grid-cols-4 gap-4 px-8 mb-4 justify-around">
             {/* Form goes here */}
            </div>
            <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
              <div className="w-1/6 flex flex-col">
                <button
                  type="button"
                  disabled={loading}
                  className="py-2 px-6 text-green-600 text-sm rounded-md"
                  onClick={closePurchasePopupForm}
                >
                  Cancel
                </button>
              </div>
              <div className="w-1/6 flex flex-col">
                <button
                  type="submit"
                  disabled={loading}
                  className={`py-2 px-6 text-white text-sm ${
                    loading ? "bg-green-500 cursor-wait" : "bg-green-600"
                  } rounded-md`}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SocialEditAndCopyForm;
