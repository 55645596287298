

import React, { useState } from 'react';
import GenericForm from './GenericForm';
import DataDisplay from '../../../pages/BRSR/BRSRContent/DataView/DataDisplay';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../customHooks/useApi';
import { postSocialUnionAssociationParticipationInputs } from '../../../features/social/socialThunk';

const UnionAssociationParticipationForm = ({closePopup}) => {
  const {employementType,financialYearData}=useSelector(state=>state.social)
  const api=useApi()
  const dispatch=useDispatch()
  const [selectedTab, setSelectedTab] = useState('form');
  const [formData,setFormData]=useState({})
  const config = {
    financialYearOptions:financialYearData, // Example financial year options
    sections: [
      {
        key: 'accountingMetrics',
        label: 'Metrics',
        rows: [
          {
            key: 'Number of Employees who are part of Association(s) or Union',
            label: 'Number of Employees who are part of Association(s) or Union',
          },
          {
            key: 'Number of Workers who are part of Association(s) or Union',
            label: 'Number of Workers who are part of Association(s) or Union',
          },
        ],
        fields: [
          {
            key: 'male',
            label: 'Male',
            type: 'number',
           
          },
          {
            key: 'female',
            label: 'Female',
            type: 'number',
           
          },
          {
            key: 'other',
            label: 'Other',
            type: 'number',
        
          },
          {
            key: 'not disclosed',
            label: 'Not Disclosed',
            type: 'number',
          
          }
        
        ],
      },
    ],
  };
  
 
  
  function handleSubmitData(data) {
    dispatch(postSocialUnionAssociationParticipationInputs({api,formData:data,employementType}))
    setFormData(data) 
    closePopup()
  }
  const renderTab = () => {
    switch (selectedTab) {
      case "form":
        return <GenericForm config={config} handleSubmitData={handleSubmitData} />;
      case "display":
        return <DataDisplay config={config} data={formData}/>;
      default:
        return null;
    }
  };
  return (
    <div className='h-full w-full p-2 px-6 overflow-auto'>
        {renderTab()}
    </div>
  );
};

export default UnionAssociationParticipationForm;
