import { ReactComponent as CloseIcon } from "../../../app/assets/CloseIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { postScope3Data } from "../../../features/scope3/category1/Scope3Thunk";
import { setformData } from "../Redux/GeneralSlices";
import { postPurchasedGoodsandServicesData, updateSupplychainMasterData } from "../Redux/GeneralThunk";
import { toast } from "react-toastify";
import useApi from "../../../customHooks/useApi";

const PurchasedGoodsandServicesDataFormEditcopy = ({
  handleCloseForm,
  actionType,
  selectedRowData,
}) => {
  const { formData, productionTypeData, supplierTier, PurchasedGoodsandServicesItemData,unitData } = useSelector(
    (state) => state.general
  );
  const api=useApi()
  const loading = useSelector((state) => state.common.isLoading);
  const dispatch = useDispatch();

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name === "item_name") {
      let selected = PurchasedGoodsandServicesItemData.find((item) => item.item_name === value);
    
      dispatch(
        setformData({

          ...formData,[name]: value ,
          production_type: selected?.production_type,
          item_description: selected?.item_description,
          supplier_registration_number:
          selected?.supplier_registration_number,
      
          supplier: selected?.supplier,
        })
      );
    } else {
      dispatch(setformData({ ...formData, [name]: value }));
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const co2e_co2_kg = parseFloat(formData.co2e_co2_kg || 0);
  const co2e_ch4_kg = parseFloat(formData.co2e_ch4_kg || 0);
  const co2e_n2o_kg = parseFloat(formData.co2e_n2o_kg || 0);
  const total_co2e_kg = parseFloat(formData.total_co2e_kg || 0);
 
  if (co2e_co2_kg + co2e_ch4_kg + co2e_n2o_kg <= total_co2e_kg) {
    if (actionType === "copy") {
      dispatch(postPurchasedGoodsandServicesData({api}));
      handleCloseForm();
    } else {
      dispatch(updateSupplychainMasterData({id:selectedRowData.id,data:formData,api})).then(()=>{

        handleCloseForm();
      })
    }
  } else {
    toast.error("total should be greater then sum of all three emission");
  }
   
  };
  useEffect(() => {
    if (selectedRowData) {
      const updatedData = {
        item_name: selectedRowData?.item_name,
        item_description: selectedRowData?.item_description,
        item_code: selectedRowData?.item_code,
        supplier: selectedRowData?.supplier,
        supplier_code: selectedRowData?.supplier_code,
        co2e_co2_kg: selectedRowData.co2e_co2_kg,
        total_co2e_kg: selectedRowData.total_co2e_kg,
        co2e_ch4_kg: selectedRowData.co2e_ch4_kg,
        co2e_n2o_kg: selectedRowData.co2e_n2o_kg,
        // unit: unitData.find(item=>selectedRowData?.unit===item.unit)?.id,
        supplier_registration_number:
          selectedRowData.supplier_registration_number,
        supplier_tier: selectedRowData.supplier_tier,
        emission_factor_unit: selectedRowData.emission_factor_unit,
        production_type: selectedRowData.production_type,
        item_density: selectedRowData.item_density,
      };
      dispatch(setformData({ ...formData, ...updatedData }));
    }
  }, [selectedRowData, actionType, dispatch]);

 
  return (
    <>
      <div className="mainContainer flex w-full h-screen overflow-hidden justify-center items-center z-[100] fixed bg-black bg-opacity-50 top-0 left-0 bottom-0">
        <div className="flex flex-col justify-center items-center w-9/12 text-xs bg-white absolute z-[1000] border border-transparent rounded-t-md">
          <div className="flex justify-between w-full bg-gray-50 py-2 px-4 border-b border-gray-300 mb-3">
            <span className="cursor-pointer"> Goods and Services</span>
            <span className="cursor-pointer" onClick={handleCloseForm}>
              <CloseIcon />
            </span>
          </div>
          <form
            style={{
              opacity: loading ? 0.5 : 1,
              pointerEvents: loading ? "none" : "auto",
            }}
            id="insert-data-form"
            className="flex flex-col w-full py-4"
            onSubmit={handleFormSubmit}
          >
            <div className="grid grid-cols-4 gap-4 px-8 mb-4 justify-around">
              <div className="col-span-1 flex flex-col">
                <label htmlFor="item_name" className="text-xs py-1.5">
                  Item Name<span className="text-red-500">*</span>
                </label>
                <select
                  name="item_name"
                  value={formData?.item_name || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="" disabled>
                    Choose the Item Name
                  </option>
                  {PurchasedGoodsandServicesItemData &&
                    PurchasedGoodsandServicesItemData.map((item) => (
                      <option key={item.id} value={item.item_name}>
                        {item.item_name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="item_description" className="text-xs py-1.5">
                  Item Description<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="item_description"
                  id="item_description"
                  value={formData?.item_description || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              
              <div className="col-span-1 flex flex-col">
                <label htmlFor="item_code" className="text-xs py-1.5">
                Company Item Code
                </label>
                <input
                  type="text"
                  name="item_code"
                  id="item_code"
                  value={formData?.item_code || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="production_type" className="text-xs py-1.5">
                  Production Type<span className="text-red-500">*</span>
                </label>
                <select
                  name="production_type"
                  value={formData?.production_type || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="" disabled>
                    Choose the Production Type
                  </option>
                  {productionTypeData &&
                    productionTypeData.map((item) => (
                      <option key={item.id} value={item.production_type}>
                        {item.production_type}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="item_density" className="text-xs py-1.5">
                  Item Density (kg/m3)
                </label>
                <input
                  type="text"
                  name="item_density"
                  id="item_density"
                  value={formData?.item_density || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>
              
              <div className="col-span-1 flex flex-col">
                <label htmlFor="co2e_co2_kg" className="text-xs py-1.5">
                Emission Factor-kg CO2e of CO2 per unit
                 
                </label>
                <input
                required={formData.total_co2e_kg||formData.co2e_ch4_kg||formData.co2e_n2o_kg?true:false}
                  type="text"
                  name="co2e_co2_kg"
                  id="co2e_co2_kg"
                  value={formData?.co2e_co2_kg || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="co2e_ch4_kg" className="text-xs py-1.5">
                  Emission Factor-kg CO2e of CH4 per unit
                
                </label>
                <input
                required={formData.total_co2e_kg||formData.co2e_co2_kg||formData.co2e_n2o_kg?true:false}
                  type="text"
                  name="co2e_ch4_kg"
                  id="co2e_ch4_kg"
                  value={formData?.co2e_ch4_kg || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="co2e_n2o_kg" className="text-xs py-1.5">
                  Emission Factor-kg CO2e of N2O per unit
                 
                </label>
                <input
                required={formData.total_co2e_kg||formData.co2e_co2_kg||formData.co2e_ch4_kg?true:false}
                  type="text"
                  name="co2e_n2o_kg"
                  id="co2e_n2o_kg"
                  value={formData?.co2e_n2o_kg || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="total_co2e_kg" className="text-xs py-1.5">
                Emission Factor - Total Kg CO2e per unit
                </label>
                <input
                 required={formData.co2e_co2_kg||formData.co2e_ch4_kg||formData.co2e_n2o_kg?true:false}

                  type="text"
                  name="total_co2e_kg"
                  id="total_co2e_kg"
                  value={formData?.total_co2e_kg || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label
                  htmlFor="emission_factor_unit"
                  className="text-xs py-1.5"
                >
                  Emission Factor Unit
                </label>
                <select
                 disabled={formData.total_co2e_kg||formData.co2e_co2_kg||formData.co2e_ch4_kg||formData.co2e_n2o_kg?false:true}
 required={formData.total_co2e_kg||formData.co2e_co2_kg||formData.co2e_ch4_kg||formData.co2e_n2o_kg?true:false}
                  name="emission_factor_unit"
                  value={formData?.emission_factor_unit || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                 
                >
                  <option value="">Choose the Emission Factor Unit</option>
                  <option value="USD">USD</option>
                  <option value="Tonnes">Tonnes</option>
                </select>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="supplier" className="text-xs py-1.5">
                  Supplier
                </label>
                <input
                  type="text"
                  name="supplier"
                  id="supplier"
                  value={formData?.supplier || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="supplier_code" className="text-xs py-1.5">
                  Company Supplier Code
                </label>
                <input
                  type="text"
                  name="supplier_code"
                  id="supplier_code"
                  value={formData?.supplier_code || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label
                  htmlFor="supplier_registration_number"
                  className="text-xs py-1.5"
                >
                  Supplier Registration Number
                 
                </label>
                <input
                  type="text"
                  name="supplier_registration_number"
                  id="supplier_registration_number"
                  value={formData?.supplier_registration_number || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="supplier_tier" className="text-xs py-1.5">
                  Supplier Tier
                </label>
                <select
                  name="supplier_tier"
                  value={formData?.supplier_tier || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  
                >
                  <option value="" >
                    Choose the supplier tier
                  </option>
                  {supplierTier &&
                    supplierTier.map((item) => (
                      <option key={item.id} value={item.supplier_tier}>
                        {item.supplier_tier}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
              <div className="w-1/6 flex flex-col">
                <button
                  type="button"
                  disabled={loading}
                  className="py-2 px-6 text-green-600 text-sm rounded-md"
                  onClick={handleCloseForm}
                >
                  Cancel
                </button>
              </div>
              <div className="w-1/6 flex flex-col">
                <button
                  type="submit"
                  disabled={loading}
                  className={`py-2 px-6 text-white text-sm ${
                    loading ? "bg-green-500 cursor-wait" : "bg-green-600"
                  } rounded-md`}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PurchasedGoodsandServicesDataFormEditcopy;
