import React from "react";
import { ReactComponent as SEBIlogoIcon } from "../../app/assets/SEBI.svg";
import { ReactComponent as NETZero } from "../../app/assets/Net Zero .svg";
import { ReactComponent as TSFClogoIcon } from "../../app/assets/VecteezyGoodworldlogotemplateillustrationsustainable.svg";
import { ReactComponent as GHGProtocol } from "../../app/assets/GHG Protocol.svg";
import netzero from "../../app/assets/netZero.png"
function CommonLeftBlock() {
  return (
    <div className="bg-[#02AB6C] w-full h-screen ">
      <div className="flex w-full text-left  flex-col">
        <h1 className="text-4xl lg:text-7xl text-white p-4 lg:p-10 text- float-left capitalize">
          {window.location.hostname.split(".")[0]}
        </h1>

        <p className="flex flex-wrap text-start px-10 text-white font-extrabold text-xl ">
          
          Empower your sustainability journey with data-driven decisions
        </p>

        <div className="grid grid-cols-2 gap-4 mt-5 p-10 ml-4 justify-center items-center">
          <TSFClogoIcon className="w-48 h-auto"/>
          <GHGProtocol className="w-64 h-auto" />
          <SEBIlogoIcon className="w-36 h-auto ml-4" />
          <img src={netzero} className="w-48 h-auto" />
        </div>
      </div>
    </div>
  );
}

export default CommonLeftBlock;
