export function processData(Inputdata, timeInterval, breakpoint, year) {
  let data;

  if (year) {
    data = Inputdata.filter((entry) => entry.year === year);
    data.sort((a, b) => a.quarter - b.quarter);
  } else {
    if (timeInterval === "Quarter") {
      function sortByYearAndQuarter(a, b) {
        if (a.year !== b.year) {
          return a.year - b.year;
        } else {
          return a.quarter - b.quarter;
        }
      }

      Inputdata.sort(sortByYearAndQuarter);
      data = Inputdata;
    } else {
      function sortByYearAndMonth(a, b) {
        if (a?.year !== b?.year) {
          return a.year - b.year;
        } else {
          const monthsOrder = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep", // Fixed typo "Sepr" to "Sep"
            "Oct",
            "Nov",
            "Dec",
          ];
          return (
            monthsOrder?.indexOf(a?.month) - monthsOrder?.indexOf(b?.month)
          );
        }
      }

      Inputdata.sort(sortByYearAndMonth);
      data = Inputdata;
    }
  }

  let processedData = {};

  console.log(data);
  data.forEach((entry) => {
    let {
      year,
      quarter,
      total_co2e_kg,
      month,
      no_of_passenger,
      distance_covered,
    } = entry;
    total_co2e_kg = Number(total_co2e_kg);
    year = Number(year);
    no_of_passenger = Number(no_of_passenger || 0); // Ensure no_of_passenger is a number
    distance_covered = Number(distance_covered || 0); // Ensure no_of_passenger is a number
    let filter = entry[breakpoint] || "Total";

    switch (timeInterval) {
      case "Year":
        if (!processedData[year]) {
          processedData[year] = {};
        }
        if (!processedData[year][filter]) {
          processedData[year][filter] = {
            totalEmission: 0,
            no_of_passenger: 0,
            distance_covered: 0,
            trips: 0,
          };
        }
        processedData[year][filter].totalEmission += total_co2e_kg;
        processedData[year][filter].no_of_passenger += no_of_passenger;
        processedData[year][filter].distance_covered += distance_covered;
        processedData[year][filter].trips += 1;
        break;

      case "Quarter":
        const quarterKey = `${year}-Q${quarter}`;
        if (!processedData[quarterKey]) {
          processedData[quarterKey] = {};
        }
        if (!processedData[quarterKey][filter]) {
          processedData[quarterKey][filter] = {
            totalEmission: 0,
            no_of_passenger: 0,
            distance_covered: 0,
            trips: 0,
          };
        }
        processedData[quarterKey][filter].totalEmission += total_co2e_kg;
        processedData[quarterKey][filter].no_of_passenger += no_of_passenger;
        processedData[quarterKey][filter].distance_covered += distance_covered;
        processedData[quarterKey][filter].trips += 1;
        break;

      case "Month":
        const monthKey = `${year}-${month}`;
        if (!processedData[monthKey]) {
          processedData[monthKey] = {};
        }
        if (!processedData[monthKey][filter]) {
          processedData[monthKey][filter] = {
            totalEmission: 0,
            no_of_passenger: 0,
            distance_covered: 0,
            trips: 0,
          };
        }
        processedData[monthKey][filter].totalEmission += total_co2e_kg;
        processedData[monthKey][filter].no_of_passenger += no_of_passenger;
        processedData[monthKey][filter].distance_covered += distance_covered;
        processedData[monthKey][filter].trips += 1;
        break;

      default:
        break;
    }
  });

  return processedData;
}

function processDataToLablesAndDataSets(data) {
  let series = [];
  let legends = [];
  let xAxisLabels = Object.keys(data);

  for (const timeKey in data) {
    for (const key in data[timeKey]) {
      if (!legends.includes(key)) {
        legends.push(key);
      }
    }
  }

  for (const legend of legends) {
    let seriesData = [];

    for (const timeKey of xAxisLabels) {
      if (data[timeKey][legend]) {
        seriesData.push({
          value: data[timeKey][legend].totalEmission,
          no_of_passenger: data[timeKey][legend].no_of_passenger || 0, // Ensure no_of_passengers is included
          distance_covered: data[timeKey][legend].distance_covered || 0, // Ensure no_of_passengers is included
          trips: data[timeKey][legend].trips || 0, // Ensure no_of_passengers is included
        });
      } else {
        seriesData.push({
          value: 0,
          no_of_passenger: 0, // Placeholder for missing data
          distance_covered: 0,
          trips: 0,
        });
      }
    }

    series.push({
      name: legend,
      type: "pie",
      radius: ["50%", "70%"], // Inner and outer radius for donut chart
      center: ["50%", "50%"],
      label: {
        formatter: "{b}: {d}%",
      },
      data: xAxisLabels.map((label, index) => ({
        name: label,
        value: seriesData[index].value,
        no_of_passenger: seriesData[index].no_of_passenger,
        distance_covered: seriesData[index].distance_covered,
        trips: seriesData[index].trips,
      })),
    });
  }

  return { xAxisLabels, series };
}

export const generatePieChartOptions = (
  text,
  filteredData,
  breaktype,
  currentFinancialYear
) => {
  if (
    !filteredData ||
    !Array.isArray(filteredData) ||
    filteredData.length === 0
  ) {
    console.error("Invalid or empty data provided.");
    return {};
  }

  const { series } = processDataToLablesAndDataSets(
    processData(filteredData, "Year", breaktype, Number(currentFinancialYear))
  );

  if (!series || !Array.isArray(series)) {
    console.error("Error processing data into series.");
    return {};
  }

  const pieChartData = series.map((dataItem) => {
    return {
      value: dataItem.data
        .reduce((acc, curr) => acc + Number(curr.value), 0)
        .toFixed(),
      name: dataItem.name,
      no_of_passenger: dataItem.data
        .reduce((acc, curr) => acc + Number(curr.no_of_passenger), 0)
        .toFixed(),
      distance_covered: dataItem.data
        .reduce((acc, curr) => acc + Number(curr.distance_covered), 0)
        .toFixed(),
      trips: dataItem.data
        .reduce((acc, curr) => acc + Number(curr.trips), 0)
        .toFixed(),
    };
  });

  let categorytext = text.split("by ")[1]?.trim();

  const numberOfTrips = filteredData.length;

  return {
    text: categorytext,

    option: {
      title: {
        text,
        left: "center",
      },
      toolbox: {
        show: true,
        orient: "vertical",
        left: "right",
        top: "center",
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: true },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      tooltip: {
        trigger: "item",
        formatter: (params) => {
          //  console.log(params)
          const emissionValue = parseFloat(params.value).toFixed();
          let tooltipText = `${params.name}<br/>Emissions: ${emissionValue} Kg Co2e`;

          if (params?.data?.no_of_passenger && params?.data?.trips) {
            let emissionsPerTripPerEmployee = (
              params.value /
              params?.data?.trips /
              params?.data?.no_of_passenger
            ).toFixed(2);
            if (
              params.name === "Road" ||
              params.name === "Air" ||
              params.name === "Sea" ||
              params.name === "Rail"
            ) {
              emissionsPerTripPerEmployee = (
                params.value /
                params?.data?.trips /
                params?.data?.no_of_passenger /
                params?.data?.distance_covered
              ).toFixed(4);
              tooltipText += `<br/>Trips: ${params?.data?.trips}`;
              tooltipText += `<br/>Distance: ${params?.data?.distance_covered}`;
              tooltipText += `<br/>Emission intensity: ${emissionsPerTripPerEmployee}`;
            } else {
              emissionsPerTripPerEmployee = (
                params.value /
                params?.data?.trips /
                params?.data?.no_of_passenger
              ).toFixed(4);
              tooltipText += `<br/>Trips: ${params?.data?.trips}`;
              // tooltipText += `<br/>Distance: ${params?.data?.distance_covered}`;
              tooltipText += `<br/>Emission per employee per trip: ${emissionsPerTripPerEmployee}`;
            }
          } else {
            tooltipText += `<br/>Trips: ${0}`;
            // tooltipText += `<br/>Employees: ${params?.data?.no_of_passenger}`;
            tooltipText += `<br/>Emission intensity: ${0}`;
          }

          return tooltipText;
        },
      },
      legend: {
        type: "scroll",
        bottom: "5%",
        top: "bottom",
      },
      series: [
        {
          type: "pie",
          radius: ["40%", "70%"],
          center: ["50%", "50%"],
          avoidLabelOverlap: false,
          label: {
            position: "inside",
            show: true,
            fontSize: 10,
            fontWeight: "bold",
            formatter: (params) => {
              return `${params.percent.toFixed()}%`;
            },
          },
          labelLine: {
            show: true,
          },
          data: pieChartData,
        },
      ],
    },
  };
};

export const calculateChange = (current, previous) => {
  if (previous === null || previous === 0 || current === null || current === 0)
    return 0;
  return (
    ((parseFloat(current) - parseFloat(previous)) / parseFloat(previous)) * 100
  );
};

export const calculateEmissionToRevenueRatio = (emissions, revenue) => {
  if (revenue === null || revenue === 0) return 0;
  return (emissions / revenue).toFixed(2);
};

export const getMonthName = (monthNumber) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[monthNumber - 1] || "";
};

export const getEmissionsForSameMonthAndYear = (
  data,
  month,
  year,
  breakdownType,
  breakdownKey
) => {
  // console.log(year);
  return data
    ?.filter((item) => {
      return (
        parseInt(item.year) === Number(year) &&
        item[breakdownType] === breakdownKey
      );
    })
    .reduce((total, item) => total + item.total_co2e_kg, 0);
};

// Function to aggregate data by item and supplier
export function calculateAggregatedData(data) {
  const aggregatedDataByItem = {};
  const aggregatedDataBySupplier = {};

  data.forEach((entry) => {
    // Aggregate by item
    if (!aggregatedDataByItem[entry.item]) {
      aggregatedDataByItem[entry.item] = { ...entry };
    } else {
      aggregatedDataByItem[entry.item].total_co2e_kg += entry.total_co2e_kg;
    }

    // Aggregate by supplier
    if (!aggregatedDataBySupplier[entry.supplier]) {
      aggregatedDataBySupplier[entry.supplier] = { ...entry };
    } else {
      aggregatedDataBySupplier[entry.supplier].total_co2e_kg +=
        entry.total_co2e_kg;
    }
  });

  return { aggregatedDataByItem, aggregatedDataBySupplier };
}

export function calculateEmissionByitemandSupplier(
  data,
  currentMonth,
  currentYear
) {
  const Data = calculateAggregatedData(data);
  const { aggregatedDataByItem, aggregatedDataBySupplier } = Data;

  const itemsWithMetrics = Object.values(aggregatedDataByItem).map((item) => {
    const previousYearEmissions = getEmissionsForSameMonthAndYear(
      data,
      currentMonth,
      currentYear - 1,
      "item",
      item.item
    );
    // console.log(Data, previousYearEmissions);
    return {
      year: currentYear,
      item: item.item,
      total_co2e_kg: parseFloat(item.total_co2e_kg).toFixed(2),
      emission_to_revenue_ratio: calculateEmissionToRevenueRatio(
        item.total_co2e_kg,
        item.amount_paid
      ),
      change: parseFloat(
        calculateChange(
          getEmissionsForSameMonthAndYear(
            data,
            currentMonth,
            currentYear,
            "item",
            item.item
          ),
          previousYearEmissions
        )
      ).toFixed(2),
    };
  });

  const suppliersWithMetrics = Object.values(aggregatedDataBySupplier).map(
    (supplier) => {
      const previousYearEmissions = getEmissionsForSameMonthAndYear(
        data,
        currentMonth,
        currentYear - 1,
        "supplier",
        supplier.supplier
      );
      return {
        supplier: supplier.supplier,
        total_co2e_kg: parseFloat(supplier.total_co2e_kg).toFixed(2),
        emission_to_revenue_ratio: calculateEmissionToRevenueRatio(
          supplier.total_co2e_kg,
          supplier.amount_paid
        ),
        change: parseFloat(
          calculateChange(
            getEmissionsForSameMonthAndYear(
              data,
              currentMonth,
              currentYear,
              "supplier",
              supplier.supplier
            ),
            previousYearEmissions
          )
        ).toFixed(2),
      };
    }
  );

  const sortedItems = [...itemsWithMetrics].sort((a, b) => b.change - a.change);
  const sortedSuppliers = [...suppliersWithMetrics].sort(
    (a, b) => b.change - a.change
  );
  // console.log({ sortedItems, sortedSuppliers });
  return {
    sortedItems,
    sortedSuppliers,
  };
}

export function generateSunburstChartDataCategory7(
  data,
  text = "title",
  level1Key,
  level2Key,
  mode_of_travel,
  currentFinancialYear
) {
  const chartData = {};
  let totalValue = 0;
  const valueKey = "total_co2e_kg";
  const distanceKey = "distance_covered";

  // Calculate total value and filter data based on mode_of_travel
  if (mode_of_travel) {
    data = data.filter((item) => item.mode_of_travel === mode_of_travel);
  }
  if (currentFinancialYear) {
    data = data.filter((item) => item.year === currentFinancialYear);
  }

  data.forEach((entry) => {
    totalValue += parseFloat(entry[valueKey]);
  });

  // const allEmployees = new Set(data.map(i => i.employee_id)).size;

  data.forEach((entry) => {
    const level1 = entry[level1Key];
    const level2 = entry[level2Key];
    const value = parseFloat(entry[valueKey]);
    const distance = parseFloat(entry[distanceKey]);
    const percentage = (value / totalValue) * 100;

    if (!chartData[level1]) {
      chartData[level1] = {
        value: 0,
        percentage: 0,
        children: {},
        distance: 0,
        employees: new Set(),
      };
    }

    chartData[level1].employees.add(entry.employee_id);

    if (!chartData[level1].children[level2]) {
      chartData[level1].children[level2] = {
        value,
        percentage,
        distance,
        employees: new Set(),
      };
    } else {
      chartData[level1].children[level2].value += value;
      chartData[level1].children[level2].percentage += percentage;
      chartData[level1].children[level2].distance += distance;
    }

    chartData[level1].children[level2].employees.add(entry.employee_id);

    // Update L1 total value, percentage, and distance
    chartData[level1].value += value;
    chartData[level1].percentage = (chartData[level1].value / totalValue) * 100;
    chartData[level1].distance += distance;
  });

  const echartsData = [];
  Object.keys(chartData).forEach((level1) => {
    const { value, percentage, children, distance, employees } =
      chartData[level1];
    const level1EmployeesCount = employees.size;
    const level1Data = {
      name: `${level1 || "total"}`,
      children: [],
      percentage: `${percentage.toFixed()}%`,
      label: `${level1 || "total"}`,
      value,
      distance,
      employees: level1EmployeesCount,
    };

    Object.keys(children).forEach((level2) => {
      const {
        value: childValue,
        percentage: childPercentage,
        distance: childDistance,
        employees: childEmployees,
      } = children[level2];
      const level2EmployeesCount = childEmployees.size;
      const level2Data = {
        name: `${level2 || "total"}`,
        value: childValue,
        percentage: `${childPercentage.toFixed()}%`,
        label: `${level2 || "total"}`,
        distance: childDistance,
        employees: level2EmployeesCount,
      };
      level1Data.children.push(level2Data);
    });
    echartsData.push(level1Data);
  });

  const sunburstOptions = {
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        const { value, percentage, distance, employees } = params.data;
        return `${
          params.data.label || "Total Emission"
        } (${percentage})<br/> Emission: ${value.toFixed()} kg CO2e <br/> Emission per employee: ${(
          value / employees
        ).toFixed()} kg CO2e <br/> Employees: ${employees} <br/> Distance covered per employee: ${(
          distance / employees
        ).toFixed()} km`;
      },
    },
    title: {
      text,
      left: "center",
    },
    series: {
      type: "sunburst",
      data: echartsData,
      radius: [70, "80%"],
      label: {
        show: true,
        formatter: (params) => {
          return `${params.data.label || ""} (${params.data.percentage || ""})`;
        },
        rotate: 45,
        fontWeight: 600,
      },
      itemStyle: {
        borderRadius: 5,
        borderWidth: 2,
      },
    },
  };

  return sunburstOptions;
}

export function calculatePublicTransportData(data, year) {
  const filteredDataCurrent = data.filter((i) => i.year == year);
  const filteredDataLastYear = data.filter((i) => i.year == year - 1);

  const publicTransportCurrent = filteredDataCurrent
    .filter((i) => i.travel_type === "Bus" || i.travel_type === "Train")
    .reduce((acc, current) => acc + Number(current.distance_covered), 0);

  const publicTransportLast = filteredDataLastYear
    .filter((i) => i.travel_type === "Bus" || i.travel_type === "Train")
    .reduce((acc, current) => acc + Number(current.distance_covered), 0);

  const totalDistanceCurrent = data.reduce(
    (acc, current) => acc + Number(current.distance_covered),
    0
  );

  const resultCurrent =
    isFinite(publicTransportCurrent) &&
    isFinite(totalDistanceCurrent) &&
    totalDistanceCurrent !== 0
      ? publicTransportCurrent / totalDistanceCurrent
      : 0;

  const resultLast =
    isFinite(publicTransportLast) &&
    isFinite(totalDistanceCurrent) &&
    totalDistanceCurrent !== 0
      ? publicTransportLast / totalDistanceCurrent
      : 0;

  const percentageChange =
    resultLast !== 0 ? ((resultCurrent - resultLast) / resultLast) * 100 : 0;

  return {
    resultCurrent: resultCurrent.toFixed(),
    percentage: percentageChange.toFixed(),
  };
}


export function generateSunburstChartDataCategory6(
  data,
  text = "title",
  level1Key,
  level2Key,
  mode_of_travel,
  currentFinancialYear
) {
  // Filter data based on mode_of_travel and currentFinancialYear
  if (mode_of_travel) {
    data = data.filter((item) => item.mode_of_travel === mode_of_travel);
  }
  if (currentFinancialYear) {
    data = data.filter((item) => item.year === currentFinancialYear);
  }

  const totalTrips = data.length;
  const chartData = {};
  let totalValue = 0;
  const valueKey = "total_co2e_kg";
  const distanceKey = "distance_covered";

  data.forEach((entry) => {
    totalValue += parseFloat(entry[valueKey]);
  });

  data.forEach((entry) => {
    const level1 = entry[level1Key];
    const level2 = entry[level2Key];
    const value = parseFloat(entry[valueKey]);
    const distance = parseFloat(entry[distanceKey]);
    const no_of_passenger = parseFloat(entry["no_of_passenger"]);
    const percentage = (value / totalValue) * 100;

    if (!chartData[level1]) {
      chartData[level1] = {
        value: 0,
        percentage: 0,
        children: {},
        distance: 0,
        trips: 0,
        no_of_passenger: 0,
      };
    }
    chartData[level1].trips += 1;

    if (!chartData[level1].children[level2]) {
      chartData[level1].children[level2] = {
        value,
        percentage,
        distance,
        no_of_passenger,
        trips: 0,
      };
    } else {
      chartData[level1].children[level2].value += value;
      chartData[level1].children[level2].percentage += percentage;
      chartData[level1].children[level2].distance += distance;
      chartData[level1].children[level2].no_of_passenger += no_of_passenger;
    }
    chartData[level1].children[level2].trips += 1;

    // Update L1 total value, percentage, and distance
    chartData[level1].value += value;
    chartData[level1].percentage = (chartData[level1].value / totalValue) * 100;
    chartData[level1].distance += distance;
    chartData[level1].no_of_passenger += no_of_passenger;
  });

  const echartsData = [];
  Object.keys(chartData).forEach((level1) => {
    const { value, percentage, children, distance, trips, no_of_passenger } =
      chartData[level1];
    const level1Data = {
      name: `${level1 || "total"}`,
      children: [],
      percentage: `${percentage.toFixed()}%`,
      label: `${level1 || "total"}`,
      value,
      distance,
      no_of_passenger,
      trips,
    };
    Object.keys(children).forEach((level2) => {
      const {
        value: childValue,
        percentage: childPercentage,
        distance: childDistance,
        trips: childTrips,
        no_of_passenger: childno_of_passenger,
      } = children[level2];
      const level2Data = {
        name: `${level2 || "total"}`,
        value: childValue,
        percentage: `${childPercentage.toFixed()}%`,
        label: `${level2 || "total"}`,
        distance: childDistance,
        trips: childTrips,
        no_of_passenger: childno_of_passenger,
      };
      level1Data.children.push(level2Data);
    });
    echartsData.push(level1Data);
  });

  const sunburstOptions = {
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        // console.log(params)
        return `${params.data.label || "Total Emission"} (${
          params.data.percentage
        }) <br/> Emission: ${params.data.value.toFixed()} kg CO2e <br/> Trips: ${
          params.data.trips
        }  
           <br/> Distance: ${params.data.distance} km
          <br/> Emission intensity: ${(
          params.data.value /
          params.data.distance /
          params.data.trips /
          params.data.no_of_passenger
        ).toFixed(4)}
       `;
      },
    },
    title: {
      text: `${text}`,
      left: "center",
    },
    series: {
      type: "sunburst",
      data: echartsData,
      radius: [70, "80%"],
      label: {
        show: true,
        formatter: (params) => {
          return `${params.data.label || ""} (${params.data.percentage || ""})`;
        },
        rotate: 45,
        fontWeight: 600,
      },
      itemStyle: {
        borderRadius: 5,
        borderWidth: 2,
      },
    },
  };

  return sunburstOptions;
}


export function calculateYOYChangeForCat6Data(data, year) {
  const filteredDataCurrent = data.filter((i) => i.year == year);
  const filteredDataLastYear = data.filter((i) => i.year == year - 1);
  const numberOfTripsCurrent = filteredDataCurrent.length;
  const numberOfTripsLastYear = filteredDataLastYear.length;
  
  const tripsChangePercentage =
    numberOfTripsLastYear !== 0
      ? ((numberOfTripsCurrent - numberOfTripsLastYear) /
          numberOfTripsLastYear) *
        100
      : 0;

  const allEmployees = Number(data[0]?.number_of_employee) || 0;

  let currentYear = year;
  if (!currentYear) {
    return {
      currentYear: 0,
      previousYear: 0,
      percentageChange: 0,
    };
  }

  const currentYearData = data?.filter((entry) => entry.year === currentYear);
  const previousYearData = data?.filter(
    (entry) => entry.year === currentYear - 1
  );

  const totalEmissionsCurrentYear =
    currentYearData?.reduce(
      (total, entry) => total + Number(entry.total_co2e_kg),
      0
    ) /allEmployees;

  const totalEmissionsPreviousYear =
    previousYearData?.reduce(
      (total, entry) => total + Number(entry.total_co2e_kg),
      0
    ) / (numberOfTripsLastYear * allEmployees);

  const percentageChange = 
    totalEmissionsPreviousYear !== 0
      ? ((totalEmissionsCurrentYear - totalEmissionsPreviousYear) /
          totalEmissionsPreviousYear) *
        100
      : 0;

  const roundedPercentageChange = isFinite(percentageChange) ? Math.floor(percentageChange) : 0;

  return {
    perEmployeeTripRate: {
      currentYear: isFinite(totalEmissionsCurrentYear) ? totalEmissionsCurrentYear.toFixed() : "0",
      previousYear: isFinite(totalEmissionsPreviousYear) ? Math.floor(totalEmissionsPreviousYear) : "0",
      percentageChange: roundedPercentageChange,
    },
    trips: {
      numberOfTripsCurrent,
      percentageChange: isFinite(tripsChangePercentage) ? tripsChangePercentage.toFixed() : "0",
    },
  };
}