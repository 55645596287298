import { useDispatch, useSelector } from "react-redux";
import SidebarMenu from "./SidebarMenu";
import { useEffect } from "react";
import { validateMandotrySteps } from "../../features/auth/authThunks";
import api from "../../services/api";
import { setmandatoryStep } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";

const MainLayout = ({ children }) => {
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const { isAuthenticated, mandatoryStepsCompleted ,firstLogin} = useSelector(
    (state) => state.auth
  );
 
  useEffect(() => {
  
    async function validate(){

      if (isAuthenticated&&(!mandatoryStepsCompleted)) {
        const res = await dispatch(validateMandotrySteps({ api }));
         
        const stepsCompleted = res.payload?.data?.mandatory_steps_completed;
        const stepUrl = res.payload?.data?.Step?.step_url;
        dispatch(setmandatoryStep(res.payload?.data?.Step?.is_mandatory));
  
        if (!stepsCompleted) {
          navigate(stepUrl);
        } else {
          // navigate("/energy/emissions")
        }
      }
    }
    validate()
  }, []);

  return (
    <div className="flex flex-1 flex-row flex-grow w-full">
      {mandatoryStepsCompleted ? (
        <div className=" flex flex-col w-52 min-h-screen">
          <SidebarMenu />
        </div>
      ) : (
        ""
      )}
      
        <div
          style={{ width: "calc(100vw - 208px)" }}
          className="flex flex-col flex-grow h-full overflow-y-auto max-h-screen bg-gray-50"
        >
          {children}
        </div>
      
    </div>
  );
};

export default MainLayout;
