import React from 'react';

const GrievanceTable = ({question}) => {
    const data = [
        {
          category: 'Working Conditions',
          currentFiled: 'x', // Replace 'x' with actual data if available
          currentPending: 'x',
          currentRemarks: 'x',
          previousFiled: 'x',
          previousPending: 'x',
          previousRemarks: 'x',
        },
        {
          category: 'Health & Safety',
          currentFiled: 'x', // Replace 'x' with actual data if available
          currentPending: 'x',
          currentRemarks: 'x',
          previousFiled: 'x',
          previousPending: 'x',
          previousRemarks: 'x',
        },
      ];
  return (
    <table className="min-w-full border border-gray-300 text-sm text-gray-700">
      <thead>
        <tr className="bg-gray-300">
          <th className="border border-gray-300 px-4 py-2" colSpan="7">{question}</th>
          
        </tr>
        <tr className="bg-gray-300">
          <th className="border border-gray-300 px-4 py-2 text-center" rowSpan="2">Category</th>
          <th colSpan="3" className="border border-gray-300 px-4 py-2">Current Reporting Year (Current Financial Year)</th>
          <th colSpan="3" className="border border-gray-300 px-4 py-2">Previous Reporting Year (Previous Financial Year)</th>
        </tr>
        <tr className="bg-gray-100">
          <th className="border border-gray-300 px-4 py-2">Filed during the year</th>
          <th className="border border-gray-300 px-4 py-2">Pending resolution at the end of year</th>
          <th className="border border-gray-300 px-4 py-2">Remarks</th>
          <th className="border border-gray-300 px-4 py-2">Filed during the year</th>
          <th className="border border-gray-300 px-4 py-2">Pending resolution at the end of year</th>
          <th className="border border-gray-300 px-4 py-2">Remarks</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index} className="text-center">
            <td className="border border-gray-300 px-4 py-2">{item.category}</td>
            <td className="border border-gray-300 px-4 py-2">{item.currentFiled}</td>
            <td className="border border-gray-300 px-4 py-2">{item.currentPending}</td>
            <td className="border border-gray-300 px-4 py-2">{item.currentRemarks}</td>
            <td className="border border-gray-300 px-4 py-2">{item.previousFiled}</td>
            <td className="border border-gray-300 px-4 py-2">{item.previousPending}</td>
            <td className="border border-gray-300 px-4 py-2">{item.previousRemarks}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default GrievanceTable;
