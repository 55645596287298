import { ReactComponent as CloseIcon } from "../../../app/assets/CloseIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as InfoIcon } from "../../../app/assets/InfoIcon.svg";
import {
  postscope3Category6Data,
  scope3Category6UploadEvidence,
} from "../../../features/scope3/category6/Scope3Category6Thunk";
import useApi from "../../../customHooks/useApi";

const Purchases3c4Form = ({
  handleFormChange,
  closePurchasePopupForm,
  modeOfTravel,
  radiativeForceData,
  files,
}) => {
  let formValue = useSelector(
    (state) => state.scope3Category6?.scope3Category6Form
  );
  const api = useApi();
  const loading = useSelector((state) => state.common.isLoading);
  const dispatch = useDispatch();

  const {
    filteredTypeData,
    expenseUnit,
    filtredFuelTypeData,
    subType,
    subTypeFiltred,siteData
  } = useSelector((state) => state.scope3Category6);

  const handlePurchaseFormSubmit = (e) => {
    e.preventDefault();
    // console.log({files})
    const form = document.querySelector("#purchase-scope3Category6-form");
    if (form.length && form.checkValidity()) {
      if (files) {
        dispatch(scope3Category6UploadEvidence({ files, api }))
          .then((result) => {
            // console.log(result);
            if (result?.payload?.msg === "File uploaded.") {
              dispatch(postscope3Category6Data({ api })).then(() => {
                closePurchasePopupForm();
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else if (form.length) {
      form.querySelector('input[type="submit"]').click();
    }
  };

  return (
    <>
      <div className="mainContainer flex w-full h-screen overflow-hidden justify-center items-center z-[100] fixed bg-black bg-opacity-50 top-0 left-0 bottom-0">
        <div className="flex flex-col justify-center items-center w-9/12 text-xs bg-white absolute z-[1000] border border-transparent rounded-t-md">
          <div className="flex justify-between w-full bg-gray-50 py-2 px-4 border-b border-gray-300 mb-3">
            <span className="cursor-pointer">Business Travel</span>
            <span className="cursor-pointer" onClick={closePurchasePopupForm}>
              <CloseIcon />
            </span>
          </div>
          <form
            style={{
              opacity: loading ? 0.5 : 1,
              pointerEvents: loading ? "none" : "auto",
            }}
            id="purchase-scope3Category6-form"
            className="flex flex-col w-full py-4"
            onSubmit={handlePurchaseFormSubmit}
          >
            <div className="grid grid-cols-4 gap-4 px-8 mb-4 justify-around">
              <div className="col-span-1 flex flex-col">
                <label htmlFor="date" className="text-xs py-1.5">
                  Date<span className="text-red-500">*</span>
                </label>
                <input
                  max={new Date().toISOString().split("T")[0]}
                  type="date"
                  name="date"
                  id="date"
                  value={formValue?.date || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="site_id" className="text-xs py-1.5">
                Facility travelling from <span className="text-red-500">*</span>
                </label>
                <select
                  name="site_id"
                  value={formValue.site_id||""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="">Choose the facility</option>
                  {siteData &&
                    siteData.map((site) => (
                      <option key={site.id} value={site.id}>
                        {site.site}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="mode_of_travel" className="text-xs py-1.5">
                  Mode of Travel<span className="text-red-500">*</span>
                </label>
                <select
                  name="mode_of_travel"
                  value={formValue.mode_of_travel||""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="">Choose the mode of travel</option>
                  {modeOfTravel &&
                    modeOfTravel.map((site) => (
                      <option
                        key={site.id + "_" + site.mode_of_travel}
                        value={site.mode_of_travel}
                      >
                        {site.mode_of_travel}
                      </option>
                    ))}
                </select>
              </div>
              <div className="flex flex-col">
                <label htmlFor="type" className="text-xs py-1.5">
                  Type<span className="text-red-500">*</span>
                </label>
                <select
                  name="travel_type"
                  value={formValue.travel_type||""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="">Choose the Type</option>
                  {filteredTypeData?.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.travel_type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col">
                <label htmlFor="travel_subtype" className="text-xs py-1.5">
                  Sub Type<span className="text-red-500">*</span>
                </label>
                <select
                  name="travel_subtype"
                  value={formValue.travel_subtype||""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="">Select Sub type</option>
                  {subTypeFiltred &&
                    subTypeFiltred?.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.travel_subtype}
                        </option>
                      );
                    })}
                </select>
              </div>
             
              <div className="flex flex-col">
                <label htmlFor="fuel_type" className="text-xs py-1.5">
                  Fuel Type<span className="text-red-500">*</span>
                </label>
                <select
                  name="fuel_type"
                  value={formValue.fuel_type||""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="">Choose the Fuel type</option>
                  {filtredFuelTypeData?.map((type,i) => (
                    <option key={type.id+"_"+i} value={type.id}>
                      {type.fuel_type}
                    </option>
                  ))}
                </select>
              </div>
               <div className="col-span-1 flex flex-col">
                <label htmlFor="air_conditioned" className="text-xs py-1.5">
                  Radiative Forcing<span className="text-red-500">*</span>
                </label>
                <select
                  name="air_conditioned"
                  // disabled={formValue.mode_of_travel ===||"" "Air" ? false : true}
                  value={formValue.air_conditioned||""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="">Choose the Radiative Forcing</option>

                  {radiativeForceData &&
                    radiativeForceData.map((item) => (
                      <option key={item} value={item}>{item}</option>
                    ))}
                </select>
              </div>
              <div className="flex flex-col">
                <label htmlFor="number_of_employees" className="text-xs py-1.5">
                 Number of Passengers <span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  name="number_of_employees"
                  value={formValue.number_of_employees||""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="source" className="text-xs py-1.5">
                  Source<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="source"
                  value={formValue.source||""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="destination" className="text-xs py-1.5">
                  Destination<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="destination"
                  value={formValue.destination||""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="distance"
                  className="text-xs py-1.5 flex justify-between items-center px-2"
                >
                  <span>
                    Distance (km)<span className="text-red-500">*</span>
                  </span>{" "}
                  <a
                    className="flex justify-center gap-1 items-center font-thin text-xs"
                    target="_blank"
                    href="https://www.searates.com/services/distances-time"
                  >
                    <InfoIcon className="w-4 h-4 font-thin cursor-pointer" />
                    Distance calculator
                  </a>
                </label>
                <input
                  type="number"
                  name="distance"
                  step="0.0001"
                  value={formValue.distance||""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="expense" className="text-xs py-1.5">
                  Expense<span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  name="expense"
                  step="0.0001"
                  value={formValue.expense||""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>

              <div className="col-span-1 flex flex-col">
                <label htmlFor="expense_unit" className="text-xs py-1.5">
                  Expense Unit<span className="text-red-500">*</span>
                </label>
                <select
                  name="expense_unit"
                  value={formValue?.expense_unit||""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                >
                  <option value="" disabled>
                    Choose the expense unit
                  </option>
                  {expenseUnit.map((expense_unit) => (
                    <option key={expense_unit.id} value={expense_unit.id}>
                      {expense_unit.unit}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-span-1 flex flex-col">
                <label htmlFor="evidence" className="text-xs py-1.5">
                  Evidence<span className="text-red-500">*</span>
                </label>
                <input
                  type="file"
                  name="evidence"
                  id="evidence"
                  multiple
                  accept=".jpg, .jpeg, .png, .pdf, .zip"
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
            </div>
            <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
              <div className="w-1/6 flex flex-col">
                <button
                  type="button"
                  disabled={loading}
                  className="py-2 px-6 text-green-600 text-sm rounded-md"
                  onClick={closePurchasePopupForm}
                >
                  Cancel
                </button>
              </div>
              <div className="w-1/6 flex flex-col">
                <button
                  type="submit"
                  disabled={loading}
                  className={`py-2 px-6 text-white text-sm ${
                    loading ? "bg-green-500 cursor-wait" : "bg-green-600"
                  } rounded-md`}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Purchases3c4Form;
