import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Mail } from "../../../app/assets/Mail.svg";
// import { ReactComponent as User } from "../../../app/assets/User.svg";
import { ReactComponent as Number123Icon } from "../../../app/assets/Number123Icon.svg";
// import { ReactComponent as Phone } from "../../../app/assets/Phone.svg";
import { ReactComponent as Website } from "../../../app/assets/Website.svg";
import { ReactComponent as StockMarket } from "../../../app/assets/StockMarket.svg";
import { ReactComponent as ListedYear } from "../../../app/assets/ListedYearIcon.svg";
import { ReactComponent as CorporateIcon } from "../../../app/assets/CorporateIcon.svg";

import { useDispatch, useSelector } from "react-redux";
import {
  resetCompanyProfileForm,
  setCompanyProfileForm,
} from "../Redux/SettingSlices";
// import { toast } from "react-toastify";
import { InputField } from "../components/InputField";
import { SelectField } from "../components/SelectField";
import { fetchCompanyProfile, updateCompanyProfile } from "../Redux/SettingThunk";
import useApi from "../../../customHooks/useApi";
import { UploadLogo } from "../../../features/auth/authThunks";
function CompanyProfile() {
  const api=useApi()
  const { mandatoryStepData } = useSelector((state) => state.auth);
  const { companyProfileForm,country } = useSelector((state) => state.setting);
  const dispatch = useDispatch();
  const [yearOfIncorporationoptions,setyearOfIncorporationoptions]=useState([])
  const fileInputRef = useRef(null);

  const handleUpload = async(event) => {
    const file = event.target.files[0];
    const res = await dispatch(UploadLogo({file,api}));
    // console.log(res.payload.path)
    dispatch(setCompanyProfileForm({ ...companyProfileForm, logo_path: res?.payload?.path }));
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  function onChange(e) {
    const { name, value } = e.target;
    dispatch(setCompanyProfileForm({ ...companyProfileForm, [name]: value }));
  }
  
  function handleSubmit() {
   
    dispatch(updateCompanyProfile({id:companyProfileForm['organization_id']
      ,data:companyProfileForm,api, step_id:mandatoryStepData?.Step?.id}))
  }

  useEffect(() => {
    const options = [];
    for (let year = 1800; year <= new Date().getFullYear(); year++) {
      options.push({ label: year, value: String(year) });
    }
    
    setyearOfIncorporationoptions(options.reverse());
    dispatch(fetchCompanyProfile({api}))
  }, []); 
//  console.log(companyProfileForm)
  return (
    <div className="w-full border h-full">
      <div className="p-5 w-full h-full">
        <div className="flex justify-between w-full">
          <div className="flex justify-center items-center text-left space-x-2">
            <div className="w-36 h-auto object-cover p-2">
             { companyProfileForm?.logo_path&&<img
                className="rounded-full flex flex-shrink-0 object-contain"
                src={
                  companyProfileForm?.logo_path||""
                }
                alt="user"
              />}
            </div>
          </div>
          <div className="p-2 px-4 flex space-x-4 items-center">
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleUpload}
            />
            <button
              onClick={handleButtonClick}
              className="text-white p-2 px-[2vw] flex flex-shrink-0  rounded-md bg-green-600"
            >
              Upload New Logo
            </button>
            
          </div>
        </div>
        <div className=" mt-4 grid grid-cols-1 md:grid-cols-2 gap-2">
          <InputField
          disabled={true}
            label={"Company Name"}
            name={"company_name"}
            placeholder={"Enter your company name"}
            value={companyProfileForm.company_name||""}
            onChange={onChange}
            type="text"
            Decoration={""}
            decorationClassName="hidden"
          />

          <InputField
          disabled={true}
            label={"Company Registration Number"}
            name={"registration_number"}
            placeholder={"Enter your company  registration number"}
            value={companyProfileForm.registration_number||""}
            onChange={onChange}
            type="text"
            Decoration={Number123Icon}
            inputClassName="border-l-0 rounded-tl-none rounded-bl-none"
          />
          <SelectField
           disabled={true}
            label={"Country of Registration"}
            name={"country_id"}
            placeholder={"Select country of registration"}
            value={companyProfileForm.country_id||""}
            onChange={onChange}
            options={country.map(item=>({label:item.country,value:item?.id}))}
            Decoration={Mail}
            inputClassName="border-l-0 rounded-tl-none rounded-bl-none"
          />
          <SelectField
           disabled={true}
            label={"Year of  Incorporation"}
            name={"year_of_incorporation"}
            placeholder={"YYYY"}
            value={companyProfileForm.year_of_incorporation||""}
            onChange={onChange}
            options={yearOfIncorporationoptions}
            Decoration={ListedYear}
            inputClassName="border-l-0 rounded-tl-none rounded-bl-none"
          />

          <InputField
            label={"NIC/ISIC Code"}
            name={"industry"}
            placeholder={"Enter NIC/ISIC Code"}
            value={companyProfileForm.industry||""}
            onChange={onChange}
            type="text"
            maxLength={4}
            Decoration={StockMarket}
            inputClassName="border-l-0 rounded-tl-none rounded-bl-none"
          />
         
          <InputField
            label={"Number of employee"}
            name={"number_of_employee"}
            placeholder={"Enter employee"}
            value={companyProfileForm.number_of_employee||""}
            onChange={onChange}
            type="number"
            Decoration={Number123Icon}
            inputClassName="border-l-0 rounded-tl-none rounded-bl-none"
          />
          <InputField
            label={"Listed Year ( Optional) "}
            name={"listed_year"}
            placeholder={"Enter Listed Year ( Optional) "}
            value={companyProfileForm.listed_year||""}
            onChange={onChange}
            type="date"
            Decoration={ListedYear}
            inputClassName="border-l-0 rounded-tl-none rounded-bl-none"
          />
          <InputField
            label={"Stock Exchange where company is listed ( Optional)"}
            name={"stock_exchange"}
            placeholder={"Enter Stock Exchange"}
            value={companyProfileForm.stock_exchange||""}
            onChange={onChange}
            type="text"
            Decoration={StockMarket}
            inputClassName="border-l-0 rounded-tl-none rounded-bl-none"
          />
          <SelectField
            label={"Fiscal Year"}
            name={"fiscal_year"}
            placeholder={"Enter Fiscal Year"}
            value={companyProfileForm.fiscal_year||""}
            onChange={onChange}
            options={[{value:"JAN-DEC",label:"APR-MAR"},{value:"APR-MAR",label:"JAN-DEC"}]}
            Decoration={ListedYear}
            inputClassName="border-l-0 rounded-tl-none rounded-bl-none"
          />
          <InputField
            label={"Website"}
            name={"website"}
            placeholder={"Enter Website"}
            value={companyProfileForm.website||""}
            onChange={onChange}
            type="text"
            Decoration={Website}
            inputClassName="border-l-0 rounded-tl-none rounded-bl-none"
          />
          <InputField
            label={"Corporate office"}
            name={"corporate_office"}
            placeholder={"Enter Corporate office"}
            value={companyProfileForm.corporate_office||""}
            onChange={onChange}
            type="text"
            Decoration={CorporateIcon}
            inputClassName="border-l-0 rounded-tl-none rounded-bl-none"
          />
        <div className="flex justify-center items-center">
          <button
            onClick={handleSubmit}
            className="p-2 px-6 rounded-md bg-green-600 text-white"
          >
            Update Changes
          </button>
        </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyProfile;
