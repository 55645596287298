import React, { useEffect, useState } from "react";
import Header from "../../components/common/Header";
import AnalyzeHeader from "../../components/Analyze/AnalyzeHeader";
import { ReactComponent as Scope3Icon } from "../../app/assets/BussinessTravel.svg";
import { ReactComponent as FilterMenuIcon } from "../../app/assets/FilterMenuIcon.svg";

import {
  currentYearLastYearEmissionDetail,
  getCurrentFinancialYear,
} from "../VisualizationFeature/Redux/Processdata";
import { useDispatch, useSelector } from "react-redux";
import { fetchChartScope3Category6DataThunk } from "../VisualizationFeature/Redux/chartthunk";
import Filter from "../VisualizationFeature/components/Filter";
import Category6Main from "./scope3/Category6Main";
import useApi from "../../customHooks/useApi";
import { calculateYOYChangeForCat6Data } from "../VisualizationFeature/Redux/processData/ProcessDataforCategory6";

function AnalyzeScope3Category6() {
  const api=useApi()
  const [category6Data,setcategory6Data]=useState([])
  const dispatch = useDispatch();
  const [emissionChange, setEmissonChange] = useState({});

  const {
    s3c6financialYear,
    s3c6siteOptions,
    modeOfTravelOptions,
    Scope3Category6Data,
    Scope3Category6DataForCurrentYearChange,
  } = useSelector((state) => state.chart);
  const [currentFinancialYear, setCurrentFinancialYear] = useState(s3c6financialYear.length ? s3c6financialYear[0] : '');
  const [filterBy, setFilterBy] = useState({
    mode_of_travel: "",
    travel_type: "",
    site:""
  });
  const [StackBreakDown] = useState(["item"]);
  const [BreakDownOptions] = useState([
    { value: "mode_of_travel", label: "Mode of Travel" },
    { value: "travel_type", label: "Travel Type" },
    { value: "source", label: "Source Type" },
    { value: "site", label: "Facility" },
  ]);
  const [chartDataArray] = useState([
    { title: "Emissions by Air travel", L1: "travel_type",L2:"travel_subtype",mode_of_travel:"Air" },
    { title: "Emissions by Road travel", L1: "travel_type",L2:"travel_subtype",mode_of_travel:"Road" },
    { title: "Emissions by Rail travel", L1: "travel_type",L2:"travel_subtype",mode_of_travel:"Rail" },
    { title: "Emissions by Sea travel", L1: "travel_type",L2:"travel_subtype",mode_of_travel:"Sea" },
  ]);
  const [piechartDataArray] = useState([
    { title: "Emissions by Mode of Travel", dataKey: "mode_of_travel" },
    { title: "Emissions by Facility", dataKey: "site" }

    
  ]);

  const emissionData = [
    {
      title: "Emissions",
      info: (emissionChange?.currentYear || 0) + " Kg Co2e",
      time: "",
      status:
        (emissionChange?.percentageChange || 0) > 0 ? "positive" : "negative",
      per: `${
        emissionChange?.percentageChange !== Infinity
          ? `${Math.abs(emissionChange?.percentageChange) || 0}% YOY${
              emissionChange?.percentageChange > 0 ? "↑" : "↓"
            }`
          : ""
      }`,
      year:currentFinancialYear,
      theme: "red",
    },
  ];

  const handleFilterChange = (name, value) => {
    if (name === "financialYear") {
      setCurrentFinancialYear(value);
    } else {
      setFilterBy((prevFilterBy) => ({
        ...prevFilterBy,
        [name]: value,
      }));
    }
  };


  useEffect(() => {
   
    setEmissonChange(
      currentYearLastYearEmissionDetail(
        Scope3Category6DataForCurrentYearChange,
        isNaN(Number(currentFinancialYear))
          ? Number(s3c6financialYear[0])
          : Number(currentFinancialYear)
      )
    );
   const data=calculateYOYChangeForCat6Data(Scope3Category6Data, isNaN(Number(currentFinancialYear))
   ? Number(s3c6financialYear[0])
   : Number(currentFinancialYear))

   const safeValue = (value) => (isFinite(value) && !isNaN(value) ? value : 0);

   const formatPercentage = (percentageChange) => {
     if (!isFinite(percentageChange) || isNaN(percentageChange)) {
       return "0% YOY↓";
     }
     const direction = percentageChange > 0 ? "↑" : "↓";
     return `${Math.abs(percentageChange)}% YOY${direction}`;
   };
   
   setcategory6Data([
     {
       title: "Number of Trips",
       value: safeValue(data?.trips?.numberOfTripsCurrent),
       percentage: formatPercentage(data?.trips?.percentageChange),
     },
     {
       title: "Emission per employee",
       value: `${safeValue(data?.perEmployeeTripRate?.currentYear)}  `,
       percentage: formatPercentage(data?.perEmployeeTripRate?.percentageChange),
     },
   ]);
  }, [Scope3Category6Data, currentFinancialYear, s3c6financialYear]);
  useEffect(() => {
    dispatch(fetchChartScope3Category6DataThunk({api}));
  }, []);
  useEffect(() => {
    if (s3c6financialYear.length > 0) {
      setCurrentFinancialYear(s3c6financialYear[0]);
    }
  }, [s3c6financialYear]);
  return (
    <>
      <Header PageIcon={Scope3Icon} pageTitle={"Business Travel"} />
      <div className="flex flex-col main-container w-full px-10 py-6">
        <AnalyzeHeader emissionData={emissionData} energyData={[]} category={category6Data}/>
        <div className="flex flex-col  rounded-md mt-[18px]  w-full border p-2">
          <div className="flex justify-between py-2 items-center  bg-gray-100 rounded">
            <span className="text-xl px-2"></span>
            <div className="flex justify-between items-center gap-2 px-2">
              <FilterMenuIcon className="cursor-pointer" />
              <Filter
                label="Financial Year"
                options={s3c6financialYear}
                selectedValue={currentFinancialYear}
                onChange={(value) => handleFilterChange("financialYear", value)}
              />
              <Filter
                label="Mode of travel"
                options={modeOfTravelOptions}
                selectedValue={filterBy.mode_of_travel}
                onChange={(value) => handleFilterChange("mode_of_travel", value)}
              />
              <Filter
                label="Facility"
                options={s3c6siteOptions}
                selectedValue={filterBy.site}
                onChange={(value) => handleFilterChange("site", value)}
              />
            </div>
          </div>
          <div>
            <Category6Main
            piechartDataArray={piechartDataArray}
              StackBreakDown={StackBreakDown}
              Options={BreakDownOptions}
              currentFinancialYear={
                isNaN(Number(currentFinancialYear))
                  ? s3c6financialYear[0]
                  : currentFinancialYear
              }
              height={"500px"}
              width={"100%"}
              filterBy={filterBy}
              data={Scope3Category6Data}
              chartDataArray={chartDataArray}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AnalyzeScope3Category6;
