import React, { useEffect, useState } from "react";
import { formatText, generateSeriesDataForStackedChart, generateStackedChartOptions, processStackedChartData } from "../../VisualizationFeature/Redux/Processdata";
import ChartComponent from "../../VisualizationFeature/components/ChartComponents";
import PieChart from "../../VisualizationFeature/components/PieChart";

import StackedBarChart from "../../VisualizationFeature/components/StackedBarChart";
import { calculateEmissionByitemandSupplier, generatePieChartOptions } from "../../VisualizationFeature/Redux/processData/ProcessDataforCategory1";
import { Table } from "../../VisualizationFeature/components/Table";






function Category1Main({
  width,
  height,
  filterBy,
  data,
  chartDataArray,
  Options,
  StackBreakDown,
  currentFinancialYear,
}) {
 
  const [filtredDataByGlobleFilter, setfiltredDataByGlobleFilter] = useState(
    []
  );
  const [sortedItems, setsortedItems] = useState([]);
  const [sortedSuppliers, setsortedSuppliers] = useState([]);

  const itemTableHeaders = [
    { key: "item", label: "Item Name" },
    { key: "total_co2e_kg", label: "Emissions (kg CO2e)" },
    { key: 'contribution', label: 'Contibution (%)' },
    { key: "change", label: "Change (%)" },
  ];

  const supplierTableHeaders = [
    { key: "supplier", label: "Supplier Name" },
    { key: "total_co2e_kg", label: "Emissions (kg CO2e)" },
    { key: 'contribution', label: 'Contribution (%)' },
    { key: "change", label: "Change (%)" },
  ];
  const [typeBreakdown, setTypeBreakdown] = useState("");

  const [options, setOptions] = useState(null); // Initialize options as null

  const updateOptions = (filteredData) => {
    if (filteredData.length > 0) {
      const processed = processStackedChartData(
        filteredData,
        currentFinancialYear,
        typeBreakdown
      );

      const { series, legends } = generateSeriesDataForStackedChart(processed);
      const xAxisData = Object.keys(processed);
      const updatedOptions = generateStackedChartOptions(
        filteredData,
        currentFinancialYear,
        typeBreakdown,
        legends,
        xAxisData,
        series
      );
      setOptions(updatedOptions);
    } else {
      // Handle case where filtered data is empty
      setOptions(null);
    }
  };

  const handleTypeBreakdownChange = (e) => {
    setTypeBreakdown(e.target.value);
  };
  useEffect(() => {
    
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
   


    const getItemandSuppliers = calculateEmissionByitemandSupplier(
      data,
      currentMonth,
      currentFinancialYear
    );
    // console.log({getItemandSuppliers})
    const { sortedItems, sortedSuppliers } = getItemandSuppliers;
    setsortedItems(sortedItems);
    setsortedSuppliers(sortedSuppliers);
  }, [data,currentFinancialYear]);
 
  
  useEffect(() => {
    if (data && data.length > 0) {
      const filteredData = data.filter((entry) => {
        for (let key in filterBy) {
          if (filterBy[key] && entry[key] !== filterBy[key]) {
            return false;
          }
        }
        return true;
      });
      setfiltredDataByGlobleFilter(filteredData); // filter data according to globle  filter by site , useType and fuel type
      updateOptions(filteredData);
    }
  }, [data, filterBy, currentFinancialYear]);
  useEffect(() => {
    if (filtredDataByGlobleFilter.length > 0) {
      updateOptions(filtredDataByGlobleFilter);
    }
  }, [filtredDataByGlobleFilter, typeBreakdown, data, currentFinancialYear]);

  if (!Array.isArray(data) || data.length === 0) {
    return <div>No data available for Charts</div>;
  }

  return (
    <div>
      <div className="border rounded-md mt-[18px] px-2 py-2  w-full shadow-md">
        <ChartComponent
          height={height}
          width={width}
          BreakDownOptions={Options}
          data={filtredDataByGlobleFilter}
        />
      </div>
      <div className="grid grid-cols-2 gap-2  w-full py-3">
        {chartDataArray.length &&
          chartDataArray
            .map((item) =>
              generatePieChartOptions(
                item.title,
                filtredDataByGlobleFilter,
                item.dataKey,
                currentFinancialYear
              )
            )
            ?.map((options, index) => (
              <>

              <div
                key={options.option?.title?.text + index}
                className=" border rounded-md mt-[18px] px-2 py-2  w-full shadow-md"
              >
                {options?.topThree?.length>0?<div className="border-b-2 p-2 mb-4 w-full">
                  <p className="font-bold mb-2">
                    Top {options?.topThree?.length} {options?.text} Contributing to Category 1 Emission:
                  </p>
                  <div className="grid grid-cols-2 justify-between">
                  {options?.topThree.map((item) => (
                    <div key={item.name} className="flex items-center mb-1 w-full ">
                      <div
                        className="w-3 h-3 rounded-full mr-2 flex flex-shrink-0"
                        style={{ backgroundColor: "green" }}
                      ></div>
                      <div className="flex  overflow-ellipsis">

                      <p className="mr-2 overflow-hidden whitespace-nowrap overflow-ellipsis max-w-48">{item.name}</p>
                      <p>({item.percent}%)</p>
                      </div>
                    </div>
                  ))}

                  </div>
                </div>:""}
             
                {options?.option&&<PieChart option={options.option} />}
              </div>
              </>
            ))}
      </div>
      <div className="border rounded-md mt-[18px] px-2 py-2  w-full shadow-md">
        <div className="container mx-auto ">
          <div className="grid grid-cols-2 gap-8 h-96 overflow-y-auto">
            <Table
              title="Item wise emissions change"
              data={sortedItems}
              headers={itemTableHeaders}
            />
            <Table
              title="Supplier wise emissions change"
              data={sortedSuppliers}
              headers={supplierTableHeaders}
            />
          </div>
        </div>
      </div>
      <div className="border rounded-md mt-[18px] px-2 py-2  w-full shadow-md">
        <div className=" w-full flex justify-end px-3 gap-3 items-center">
          <label htmlFor="breakpoint">Breakdown :</label>
          <select
            name="breakpoint"
            value={typeBreakdown}
            onChange={handleTypeBreakdownChange}
            className=" py-2 px-4 ml-1 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm "
          >
            <option value="">No Breakdown</option>
            {StackBreakDown.map((option, index) => (
              <option key={`${option}_${index}`} value={option}>
                {formatText(option)}
              </option>
            ))}
          </select>
        </div>
        {options ? ( // Check if options is not null before rendering StackedBarChart
          <StackedBarChart options={options} />
        ) : (
          <div>No data available for Stacked Bar Chart</div>
        )}
      </div>
    </div>
  );
}

export default Category1Main;
