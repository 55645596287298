import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  userProfileForm: {},
  userProfile:[],
  module:[
    {
      id:"1",
      module:"module 1"
    },
    {
      id:"2",
      module:"module 3"
    },
  ],
  sub_module:[ {
    id:"1",
    sub_module:"sub_module 1"
  },
  {
    id:"2",
    sub_module:"sub_module 3"
  },],
  functionality:[ {
    id:"1",
    functionality:"feature 1"
  },
  {
    id:"2",
    functionality:"feature 3"
  },],
  requestPanelData:  [
    {
        functionality: "Feature 1",
        module: "Authentication",
        sub_module: "Login",
        status: "Completed",
        comments: "Login functionality implemented successfully."
    },
    {
        functionality: "Feature 2",
        module: "Dashboard",
        sub_module: "Charts",
        status: "In Progress",
        comments: "Working on integrating chart libraries."
    },
    {
        functionality: "Feature 3",
        module: "Messaging",
        sub_module: "Notifications",
        status: "Pending",
        comments: "Designing notification UI."
    },
    {
        functionality: "Feature 4",
        module: "Settings",
        sub_module: "Profile",
        status: "Completed",
        comments: "Profile settings implemented and tested."
    },
    {
        functionality: "Feature 5",
        module: "Administration",
        sub_module: "User Management",
        status: "In Progress",
        comments: "Adding user roles functionality."
    }
],
};

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    setuserProfileForm(state, action) {
      return {
        ...state,
        userProfileForm: {
          ...state.userProfileForm,
          ...action.payload,
        },
      };
    },
   
    setRequestPanelData(state, action) {
      state.requestPanelData = action.payload;
    },
    setuserProfile(state, action) {
      state.userProfile = action.payload;
      state.userProfileForm=action.payload;
    },
    resetuserProfileForm(state, action) {
      state.userProfileForm = {};
    },
  },
});

export const {

  setuserProfileForm,setuserProfile,
  setRequestPanelData,
  resetuserProfileForm,

} = userProfileSlice.actions;
export default userProfileSlice.reducer;
