// src/pages/UnderConstruction.js
import React from "react";

const UnderConstruction = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
          Page Under Construction
        </h1>
        <p className="text-lg text-gray-600 mb-8">
          We're working hard to finish the development of this page.
        </p>
        <div className="text-6xl">🚧</div>
      </div>
    </div>
  );
};

export default UnderConstruction;
