import React, { useState } from 'react';
import GenericForm from './GenericForm';
import DataDisplay from '../../../pages/BRSR/BRSRContent/DataView/DataDisplay';
import { useDispatch, useSelector } from 'react-redux';
import { postSocialComplaintsInputs } from '../../../features/social/socialThunk';
import useApi from '../../../customHooks/useApi';

const ComplaintTrackingPage = ({closePopup}) => {
  const dispatch=useDispatch()
  const api=useApi()
  const {employementType,financialYearData}=useSelector(state=>state.social)
  const [selectedTab, setSelectedTab] = useState('form');
const [formData,setFormData]=useState({})
  const complaintTrackingConfig = {
    title: 'Complaint Tracking Form',
    financialYearOptions:financialYearData,
    siteOptions: [],
    sections: [
      {
        key: 'complaints',
        label: 'Complaint Types',
        rows: [
          { key: 'working_conditions', label: 'Working Conditions' },
          { key: 'health_and_safety', label: 'Health and Safety' },
        ],
        fields: [
          { key: 'filedDuringYear', label: 'Filed during the year', type: 'number' },
          { key: 'pendingAtEndYear', label: 'Pending resolution at the end of year', type: 'number' },
          { key: 'remark', label: 'Remark', type: 'text', placeholder: 'Enter remarks' },
        ],
      },
    ],
  };

  const handleSubmitData = (data) => {
   
    dispatch(postSocialComplaintsInputs({api,formData:data,employementType,}))
    setFormData(data)
    closePopup()
    // setSelectedTab('display');
  };

  const renderTab = () => {
    switch (selectedTab) {
      case "form":
        return <GenericForm config={complaintTrackingConfig} handleSubmitData={handleSubmitData} />;
      case "display":
        return <DataDisplay config={complaintTrackingConfig} data={formData}/>;
      default:
        return null;
    }
  };

  return (
    <div className='h-full w-full p-2 px-6'>
      {/* <div className='flex justify-center'>
        <button className={`py-2 px-4 ${selectedTab === 'form' ? 'bg-gray-700 text-white' : 'bg-white text-gray-700'} rounded-l-md`} onClick={() => setSelectedTab('form')}>Form</button>
        <button className={`py-2 px-4 ${selectedTab === 'display' ? 'bg-gray-700 text-white' : 'bg-white text-gray-700'} rounded-r-md`} onClick={() => setSelectedTab('display')}>Display</button>
      </div> */}
      {renderTab()}
    </div>
  );
};

export default ComplaintTrackingPage;

