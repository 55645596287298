import { createAsyncThunk } from "@reduxjs/toolkit";

import { setIsLoading } from "../../common/commonSlice";
import {
  setElectricityRecords,
 
  setCurrencyData,
  setSiteData,
  setUnitData,
 
  setElectricitySourceTypeData,
  setElectricityTransactionTypeData,

  setElectricityForm,
  resetElectricityForm,
  setdeleteElectricityData,
  setSiteDataForFilter,
  setElectricitySourceFilter,
} from "./electricitySlice";
import { toast } from "react-toastify";

import { PASSWORD, USERNAME } from "../../../appconfig";
import { getOrganizationId } from "../../../components/common/parseJwt";
import { removeFalsyValues } from "../../../components/common/removeFaslyValues";


export const fetchElectricityInputData = createAsyncThunk(
  "electricity/fetchElectricityInputData",
  async ({api,params}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id=getOrganizationId()
    let apiUrl = "/energy/electricity/electricity-input-data/"+organization_id;

    try {
      const response = await api.get(`${apiUrl}`,{
        params:params?removeFalsyValues(params):{}
      });
   
      if (response.status === 200 && response.data.success) {
        dispatch(setElectricityRecords(response.data));
          // dispatch(setCurrentPage(response.data.currentPage));
        // dispatch(setItemsPerPage(response.data.itemsPerPage));
        // dispatch(setTotalPages(response.data.totalPages));
        // dispatch(setTotalCount(response.data.totalCount));
        // return response
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchSiteData = createAsyncThunk(
  "electricity/fetchSiteData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id=getOrganizationId()
    try {
      const response = await api.get(
        `/energy/electricity/${organization_id}/sites`
      );

      if (response.status === 200 && response.data.success) {
        dispatch(setSiteData(response.data.data));
        dispatch(setSiteDataForFilter(response.data.Filters));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchElectricitySourcesTypeData = createAsyncThunk(
  "electricity/fetchElectricitySourcesTypeData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    // const accessToken = getState().auth.accessToken;
    try {
      const response = await api.get(
        `/energy/electricity/electricity-sources`
      );

      if (response.status === 200 && response.data.success) {
        dispatch(setElectricitySourceTypeData(response.data.data));
        dispatch(setElectricitySourceFilter(response.data.Filters))
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchUnitData = createAsyncThunk(
  "electricity/fetchUnitData",
  async ({api, id}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`/energy/electricity/units/${id}`);

      if (response.status === 200 && response.data.success) {
        dispatch(setUnitData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchTransactionTypeData = createAsyncThunk(
  "electricity/fetchTransactionTypeData",
  async ({api, electricity_source_id}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const { id, ...electricityForm } = getState().electricity.electricityForm;
    // let electricity_source_id=electricityForm.electricity_source
    try {
      const response = await api.get(
        `/energy/electricity/${electricity_source_id}/transaction-types`
      );


      if (response.status === 200 && response.data.success) {
        dispatch(setElectricityTransactionTypeData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchCurrencyData = createAsyncThunk(
  "electricity/fetchCurrencyData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`/energy/electricity/currencies`);

   
      if (response.status === 200 && response.data.success) {
        dispatch(setCurrencyData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const postElectricityData = createAsyncThunk(
  "electricity/postElectricityData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const { id,source_type, ...electricityForm } = getState().electricity.electricityForm;
    const organization_id=getOrganizationId()
    try {
      const response = await api.post(
        `/energy/electricity/${organization_id}/data/`,
        { ...electricityForm}
      );
      // console.log(response);
      if (response.status === 200 || response.status === 201) {
        dispatch(resetElectricityForm())
        dispatch(fetchElectricityInputData({api,params:{}}));
        toast.success("Record created successfully", {
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error(error?.response?.data?.error||"Record creation failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const electricityUploadEvidence = createAsyncThunk(
  "electricity/electricityUploadEvidence",
  async ({api, files}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken=localStorage.getItem("accessToken")
    const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      const organization_id=getOrganizationId()
    try {
      const response = await api.post(
        `/energy/electricity/${organization_id}/data/evidence`,
        formData,{headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        }}
      );
  
      if (response.status === 200 || response.status === 201) {
       
        dispatch(setIsLoading(false));

        dispatch(setElectricityForm({ evidence: response?.data?.path }));
        toast.success("Evidence uploded successfully", {
          theme: "dark",
        });
        return response.data
      }

    } catch (error) {
      console.error("Error uploading evidence", error);
      toast.error("uploading evidence failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const updateElectricityData = createAsyncThunk(
  "electricity/updateElectricityData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const {id,...electricityForm } = getState().electricity.electricityForm;
   
    const organization_id=getOrganizationId()
    try {
      const response = await api.put(
        `/energy/electricity/${organization_id}/data/${id}`,
        electricityForm
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Record updated successfully", {
          theme: "dark",
        });
        dispatch(fetchElectricityInputData({api,params:{}}))
      }
    } catch (error) {
      console.error("Error updating data:", error);
      toast.error("Record update failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const deleteElectricityData = createAsyncThunk(
  "electricity/deleteElectricityData",
  async ({api,id}, { _, dispatch }) => {
    dispatch(setIsLoading(true));
   
   
    const organization_id=getOrganizationId()
    try {
      const response = await api.delete(
        `/energy/electricity/${organization_id}/data/${id}`
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Record deleted successfully", {
          theme: "dark",
        });
        // console.log("Success")
        // dispatch(fetchElectricityInputData({api}))
        dispatch(setdeleteElectricityData(id))

      }
    } catch (error) {
      console.error("Error deleting data:", error);
      toast.error("Record delete failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
