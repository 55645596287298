import { ReactComponent as CloseIcon } from "../../app/assets/CloseIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../customHooks/useApi";
import {
  fetchSiteData,
  postSocialData,
  uploadSocialEvidence,
} from "../../features/social/socialThunk";
import EmployeeDemographics from "./Forms/EmployeeForm";
import HiringAndTurnoverForm from "./Forms/HiringAndTurnoverForm";
import GovernanceDiversityForm from "./Forms/GovernanceDiversityForm";
import PaternityForm from "./Forms/PaternityForm";
import EmployeeWellBeingForm from "./Forms/EmployeeWellBeing";
import UnionAssociationParticipationForm from "./Forms/UnionAssociationParticipationForm";
import TrainingMetricsForm from "./Forms/TrainingForm";
import WorkplaceSafetyMetricsForm from "./Forms/WorkplaceSafetyMetricsForm";
import ComplaintTrackingPage from "./Forms/ComplainForm";
import EmployeeRehabilitationPage from "./Forms/EmployeeRehabilitationPage";
import RetirementBenefitsForm from "./Forms/RetirementBenefitsForm";
import HiringAndTurnOver from "./Forms/HiringAndTurnOver";
import RetirementBenefits from "./Forms/RetirementBenefits";
import ContractualEmployeeForm from "./Forms/ContractualEmployeeForm";
import ContractualEmployee from "./Forms/ContractualEmployee";
import OHSForm from "./Forms/OHSForm";
import { useEffect } from "react";


const SocialForm = ({
  closePopup,
  tabId,tabName,subTabname

}) => {
  const {employementType}=useSelector(state=>state.social)
  const dispatch = useDispatch();
  const api = useApi();
  const formComponents = {
    1:<EmployeeDemographics closePopup={closePopup} />,
    2:<HiringAndTurnOver closePopup={closePopup} />,
    3:<PaternityForm closePopup={closePopup}/>,
    4:<GovernanceDiversityForm closePopup={closePopup}/>,
    5:<EmployeeWellBeingForm closePopup={closePopup}/>,
    6:<RetirementBenefits closePopup={closePopup}/>,
    7:<UnionAssociationParticipationForm closePopup={closePopup}/>,
    8:<TrainingMetricsForm closePopup={closePopup}/>,
    9:<WorkplaceSafetyMetricsForm closePopup={closePopup}/>,
    10:<ComplaintTrackingPage closePopup={closePopup}/>,
    11:<EmployeeRehabilitationPage closePopup={closePopup}/>,
    12:<ContractualEmployee closePopup={closePopup}/>,
    13:<OHSForm closePopup={closePopup}/>,
  };
useEffect(()=>{
  dispatch(fetchSiteData({
    api}))
},[])
  return (
    <>
      <div className="mainContainer flex w-full h-screen overflow-hidden justify-center items-center z-[100] fixed bg-black bg-opacity-50 top-0 left-0 bottom-0">
        <div className="flex flex-col justify-center items-center w-9/12 text-xs bg-white absolute z-[1000] border border-transparent rounded-t-md h-4/5">
          <div className="flex justify-between w-full bg-gray-50 py-2 px-4 border-b border-gray-300 mb-3">
            <span className="cursor-pointer">{tabName}-{employementType}</span>
            <span className="cursor-pointer" onClick={closePopup}>
              <CloseIcon />
            </span>
          </div>
          {formComponents[tabId]}
        </div>
      </div>
    </>
  );
};

export default SocialForm;
