import { DisclosureSubRow } from "./DisclosureSubRow";
import { ReactComponent as IIcon } from "../../../app/assets/InfoIcon.svg";
import { ReactComponent as ArrowIcon } from "../../../app/assets/ExpandIcon.svg";
import Tooltip from "./Tooltip";
import { useState } from "react";

export const DisclosureRow = ({
    disclosure,
    expanded,
    toggleExpand,
    handleDiscloserType,
    handleStatus,
    openCommentModal,
  }) => {
    const isExpanded = expanded[disclosure.id];
    const [isExpanded_essential, setExpanded_essential] = useState(false);
    const [isExpanded_leadership, setExpanded_leadership] = useState(false);
    const handleExpand = (type) => {
      if (type === "essential") {
        setExpanded_essential(!isExpanded_essential);
      } else if (type === "leadership") {
        setExpanded_leadership(!isExpanded_leadership);
      }
    };
    return (
      <>
        <tr className="border-b cursor-pointer transition-all duration-300 text-sm">
          <td onClick={() => toggleExpand(disclosure.id)} colSpan={7} className="p-4 font-semibold flex items-center">
            <span
              
              className={`flex justify-center items-center text-center mr-2 `}
              style={{
                transform: `rotate(${isExpanded ? 90 : 0}deg)`,
                transition: "transform 0.2s ease-in-out",
              }}
            >
              <ArrowIcon className={`w-3 h-3 font-bold text-center` } />
            </span>
            {disclosure.id}
          </td>
          <td onClick={() => toggleExpand(disclosure.id)} className="px-1  py-4 max-w-60 min-w-60  overflow-hidden text-ellipsis whitespace-nowrap">
            <span className="flex">{disclosure.title}</span>
          </td>
          <td className="p-4 w-fit">
            
            {/* <div className="flex items-center justify-center">
              <select value={disclosure.disclosure_type} onChange={(e)=>handleDiscloserType(e,disclosure.id,true)} className="border-none rounded-md p-2 w-36">
                <option value={""}>Select option</option>
                <option value={"Relevant"}>Relevant</option>
                <option value={"Irrelevant"}>Irrelevant</option>
              </select>
            </div> */}
          </td>
          <td className="p-4">
            <div className="flex items-center justify-center">
              <select disabled  value={disclosure.status} onChange={(e)=>handleStatus(e,disclosure.id,true,"disclosure")} className="border-none rounded-md p-2 w-36">
                <option value={""}>Select option</option>
                <option value={"Completed"}>Completed</option>
                <option value={"Pending"}>Pending</option>
              </select>
            </div>
          </td>
          <td className="p-4">
            <button
              onClick={(e) => {
                e.stopPropagation();
                openCommentModal();
              }}
              className="bg-green-500 text-white px-2 py-1 rounded"
            >
              Comment
            </button>
          </td>
          <td>
          <Tooltip message={disclosure?.guidance}>
  {/* <button className="p-2 bg-blue-500 text-white rounded-md">Hover me</button> */}
          <IIcon className="w-4 h-4"/>
</Tooltip>
          </td>
        </tr>
        {isExpanded &&
        <tr onClick={() => handleExpand("essential")}  className="border-b cursor-pointer transition-all duration-300 text-sm">
          <td className="p-4 font-semibold flex items-center w-fit ">
            <span
             
              className="flex justify-center items-center text-center mr-2"
              style={{
                transform: `rotate(${isExpanded_essential ? 90 : 0}deg)`,
                transition: "transform 0.2s ease-in-out",
              }}
            >
              <ArrowIcon className={`w-3 h-3  font-bold`} />
            </span>
            Essential Indicators
          </td>
        </tr>}
        {isExpanded_essential &&isExpanded&&
          disclosure.subDisclosure_essential &&
          disclosure.subDisclosure_essential.map((sub) => (
            <DisclosureSubRow
            subDisclosure={"subDisclosure_essential"}
              key={sub.id}
              sub={sub}
              expanded={expanded}
              toggleExpand={toggleExpand}
              openCommentModal={openCommentModal}
              handleDiscloserType={handleDiscloserType}
              handleStatus={handleStatus}
              id={disclosure.id}
            />
          ))}
        {isExpanded &&
        <tr   onClick={() => handleExpand("leadership")} className="border-b cursor-pointer transition-all duration-300 text-sm">

          <td colSpan={7} className="p-4 font-semibold flex items-center w-fit">
            <span
            
              className="flex justify-center items-center text-center mr-2"
              style={{
                transform: `rotate(${isExpanded_leadership ? 90 : 0}deg)`,
                transition: "transform 0.2s ease-in-out",
              }}
            >
              <ArrowIcon className={`w-3 h-3  font-bold`} />
            </span>
            Leadership Indicators
          </td>
        </tr>}
        {isExpanded_leadership &&isExpanded&&
          disclosure.subDisclosure_leadership &&
          disclosure.subDisclosure_leadership.map((sub) => (
            <DisclosureSubRow
            subDisclosure={"subDisclosure_leadership"}
              key={sub.id}
              sub={sub}
              expanded={expanded}
              toggleExpand={toggleExpand}
              openCommentModal={openCommentModal}
              handleDiscloserType={handleDiscloserType}
              handleStatus={handleStatus}
              id={disclosure.id}
            />
          ))}
      </>
    );
  };
  const ExpandIcon = () => (
    <svg
      className="w-4 h-4 mr-2"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g>
        {" "}
        <path
          d="M10 7L15 12L10 17"
          stroke="#000000"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>{" "}
      </g>
    </svg>
  );
  
  const CollapseIcon = () => (
    <svg
      className="w-4 h-4 mr-2"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M7 10L12 15L17 10"
          stroke="#000000"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>{" "}
      </g>
    </svg>
  );
