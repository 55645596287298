
import React, { useState } from 'react';
import GenericForm from './GenericForm';
import DataDisplay from '../../../pages/BRSR/BRSRContent/DataView/DataDisplay';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../customHooks/useApi';
import { postSocialTrainingInputs } from '../../../features/social/socialThunk';

const TrainingMetricsForm = ({closePopup}) => {
  const [selectedTab, setSelectedTab] = useState('form');
  const [formData,setFormData]=useState({})
  const {employementType,siteData,financialYearData}=useSelector(state=>state.social)
  const api=useApi()
  const dispatch=useDispatch()
  const config = {
  
    financialYearOptions: financialYearData, // Example financial year options
    sections: [
      {
        key: 'trainingMetrics',
        label: 'Training Metrics',
        rows: [
          {
            key: 'healthSafetyTrainings',
            label: 'Trainings on Health and Safety Measures',
          },
          {
            key: 'skillUpgradationTrainings',
            label: 'Trainings on Skill Upgradation',
          },
        ],
        fields: [
        
          {
            key: 'male',
            label: 'Male',
            type: 'number', 
           
          },
          {
            key: 'female',
            label: 'Female',
            type: 'number',
         
          },
          {
            key: 'other',
            label: 'Other',
            type: 'number',
          
          },
          {
            key: 'not disclosed',
            label: 'Not Disclosed',
            type: 'number',
           
          },
         
        ],
      },
    ],
  };

  
  function handleSubmitData(data) {
    dispatch(postSocialTrainingInputs({api,formData:data,employementType}))
   
    closePopup()
  }
  const renderTab = () => {
    switch (selectedTab) {
      case "form":
        return <GenericForm config={config} handleSubmitData={handleSubmitData} />;
      case "display":
        return <DataDisplay config={config} data={formData}/>;
      default:
        return null;
    }
  };
  return (
    <div className='h-full w-full p-2 px-6 overflow-auto'>
        {renderTab()}
    </div>
  );
};

export default TrainingMetricsForm;
