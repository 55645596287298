import { createAsyncThunk } from "@reduxjs/toolkit";
import commonSlice, { setIsLoading } from "../../../features/common/commonSlice";
import api from "../../../services/api";
import { setCapitalGoodsData, setCapitalGoodsItemData, setPurchasedGoodsandServicesData, setPurchasedGoodsandServicesItemData, setsiteDetailsData, setunitData } from "./GeneralSlices";
import { toast } from "react-toastify";
import { removeEmptyProperties } from "../../VisualizationFeature/Redux/Processdata";
import { getOrganizationId } from "../../../components/common/parseJwt";
import { jwtDecode } from "jwt-decode";


export const postPurchasedGoodsandServicesData = createAsyncThunk(
  "general/postPurchasedGoodsandServicesData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    const { id, ...formData } = getState().general.formData;
    const organization_id=getOrganizationId()
    try {
      const response = await api.post(
        `/scope3/category1/${organization_id}/supply-chain-master`,
        removeEmptyProperties({
          ...formData,
          is_active:true,modified_by:organization_id,
          // supplier_tier:formData.supplier_tier||0,
          // co2e_n2o_kg: formData.co2e_n2o_kg||0, co2e_ch4_kg: formData.co2e_ch4_kg||0, co2e_co2_kg: formData.co2e_co2_kg||0, total_co2e_kg: formData.total_co2e_kg||0,
          emission_factor_unit:(formData.total_co2e_kg||formData.co2e_co2_kg||formData.co2e_ch4_kg||formData.co2e_n2o_kg)?formData.emission_factor_unit:"",
          unit:1
        }),
        {
          // headers: {
          //   Authorization: `Bearer ${accessToken}`,
          // },
        }
      );
     
      if ((response.status === 200||response.status === 201)&& response.data.success) {
        // dispatch(resetgeneralForm());
        dispatch(getPurchasedGoodsandServicesData({api}));
        toast.success("Record created successfully", {
          theme: "dark",
        });
      }
      return response.data
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error(error?.response?.data?.error || "Record creation failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const getPurchasedGoodsandServicesData = createAsyncThunk(
  "general/getPurchasedGoodsandServicesData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id=getOrganizationId()
  
    try {
      const response = await api.get(
        `/scope3/category1/client_item_supply_chain_master/${organization_id}`,
       
        {
          // headers: {
          //   Authorization: `Bearer ${accessToken}`,
          // },
        }
      );
      if ((response.status === 200||response.status === 201) &&  response.data.success) {
        // console.log(response);
        dispatch(setPurchasedGoodsandServicesData(response?.data?.data.map((item) => {
          return {
            ...item,
            supplier_tier:item.supplier_tier==0?"":item.supplier_tier,
            co2e_n2o_kg: item.co2e_n2o_kg==0?"":item.co2e_n2o_kg, co2e_ch4_kg: item.co2e_ch4_kg==0?"":item.co2e_ch4_kg, co2e_co2_kg: item.co2e_co2_kg==0?"":item.co2e_co2_kg, total_co2e_kg: item.total_co2e_kg==0?"":item.total_co2e_kg
          //  unit:item?.unit_master?.unit
          };
        })));
     
      }
    } catch (error) {
      console.error("Error geting data:", error);
      toast.error(error?.response?.data?.error || "Error fetching data", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const updateSupplychainMasterData = createAsyncThunk(
  "general/updateSupplychainMasterData",
  async ({id,data,api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id=getOrganizationId()
    let apiUrl = "/scope3/category1/client_item_supply_chain_master/"+id;

    try {
      const response = await api.put(`${apiUrl}`,data, {
        //   headers: {
        //     Authorization: `Bearer ${accessToken}`,
        //   },
      });

      if (response.status === 200 || response.data.success) {
        // console.log(response)
        dispatch(
          getPurchasedGoodsandServicesData({api})
        );
        toast.success("data updated")
      }
    } catch (error) {
      console.error("Error fetching data:", error?.response?.data);
      toast.error(error?.response?.data?.error || "Record creation failed", {
        theme: "dark",
      });
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const getCapitalGoodsData = createAsyncThunk(
  "general/getCapitalGoodsData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id=getOrganizationId()
   
    try {
      const response = await api.get(
        `/scope3/category2/client-item-capialAndgood-master/${organization_id}`,
      
        {
          // headers: {
          //   Authorization: `Bearer ${accessToken}`,
          // },
        }
      );
      if ((response.status === 200||response.status === 201) &&  response.data.success) {
        dispatch(setCapitalGoodsData(response?.data?.data.map((item) => {
          return {
            ...item,
        
            supplier_code:item.organization_supplier_code,
            item_code:item.organization_item_code,
           
          };
        })));
      
      }
    } catch (error) {
      console.error("Error geting data:", error);
      toast.error(error?.response?.data?.error || "Error fetching data", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const updateCapitalGooodsMasterData = createAsyncThunk(
  "general/updateCapitalGooodsMasterData",
  async ({id,data,api}, { getState, dispatch }) => {
    console.log(data)
    dispatch(setIsLoading(true));
    const organization_id=getOrganizationId()

    let apiUrl = "/scope3/category2/client-item-capialAndgood-master/"+id;

    try {
      const response = await api.put(`${apiUrl}`,{...data,
        supplier_registration_code:data.supplier_registration_number,
        organization_supplier_code:data.organization_supplier_code,
        organization_item_code:data.item_code,

      }, {
        //   headers: {
        //     Authorization: `Bearer ${accessToken}`,
        //   },
      });

      if (response.status === 200 && response.data.success) {
        // console.log(response)
        dispatch(
          getCapitalGoodsData({api})
        );
        toast.success("data updated")
      }
    } catch (error) {
      console.error("Error fetching data:", error?.response?.data);
      toast.error(error?.response?.data?.error || "Record creation failed", {
        theme: "dark",
      });
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const postCapitalGoodsData = createAsyncThunk(
  "general/postCapitalGoodsData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    // const accessToken = getState().auth.accessToken;
    const { id, ...formdata } = getState().general.formData;
    const organization_id=getOrganizationId()
    try {
      const response = await api.post(
        `/scope3/category2/${organization_id}/client-item-capialAndgood-master`,
        removeEmptyProperties({
          ...formdata,
          is_active:true,modified_by:1,
          supplier_registration_code:formdata.supplier_registration_number,
          organization_supplier_code:formdata.organization_supplier_code,
          organization_item_code:formdata.item_code,
        
        }),
        {
          // headers: {
          //   Authorization: `Bearer ${accessToken}`,
          // },
        }
      );
     
      if ((response.status === 200||response.status === 201) && response.data.success) {
       
        dispatch(getCapitalGoodsData({api}));
        
        toast.success("Record created successfully", {
          theme: "dark",
        });
      }
      return response.data
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error(error?.response?.data?.error || "Record creation failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchCapitalGoodsItemData = createAsyncThunk(
  "general/fetchCapitalGoodsItemData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id=getOrganizationId()
    let apiUrl = "/scope3/category2/greenmentor-item-master";

    try {
      const response = await api.get(`${apiUrl}`, {
        //   headers: {
        //     Authorization: `Bearer ${accessToken}`,
        //   },
      });

      if ((response.status === 200||response.status === 201) && response.data.success) {
        dispatch(
          setCapitalGoodsItemData(
            response?.data?.data.map((item) => {
              return {
                ...item,
                supplier_registration_number:item.supplier_registration_code
              
              };
            })
          )
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const updateSitesMasterData = createAsyncThunk(
  "general/updateSitesMasterData",
  async ({id,data,api}, { getState, dispatch }) => {
    // console.log(data,id)
    dispatch(setIsLoading(true));
    // const accessToken = getState().auth.accessToken;
    const organization_id=getOrganizationId()
    let apiUrl = "/site-meta-data/"+id;
// console.log(id,data,api)
    try {
      const response = await api.put(`${apiUrl}`,{
        site:data.site,
        site_id:data.site_id,
        facility_type:data.facility_type,
        // level_1:data.level_1,
        level_2:data.level_2,
        level_3:data.level_3,
        area:data.area,
        ownership_status:data.ownership_status

      }, {
        //   headers: {
        //     Authorization: `Bearer ${accessToken}`,
        //   },
      });

      if (response.status === 200 && response.data.success) {
        // console.log(response)
        dispatch(
          fetchSitesData({api})
        );
        toast.success("data updated")
      }
    } catch (error) {
      console.error("Error updating data:", error?.response?.data);
      toast.error(error?.response?.data?.error || "Record creation failed", {
        theme: "dark",
      });
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const postSitesData = createAsyncThunk(
  "general/postSitesData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    // const accessToken = getState().auth.accessToken;
    const { id, ...formdata } = getState().general.formData;
    const organization_id=getOrganizationId()

    try {
      const response = await api.post(
        `/site-meta-data/${organization_id}`,
        removeEmptyProperties({
          ...formdata,
          is_active:true,modified_by:1,
         
        
        }),
        {
          // headers: {
          //   Authorization: `Bearer ${accessToken}`,
          // },
        }
      );
     
      if ((response.status === 200||response.status === 201) && response.data.success) {
       
        dispatch(fetchSitesData({api}));
        
        toast.success("Record created successfully", {
          theme: "dark",
        });
      }
      return response.data
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error(error?.response?.data?.error || "Record creation failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchSitesData = createAsyncThunk(
  "general/fetchSitesData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    // const accessToken = getState().auth.accessToken;
    const organization_id=getOrganizationId()
    let apiUrl = "/site-meta-data/"+organization_id;

    try {
      const response = await api.get(`${apiUrl}`, {
        //   headers: {
        //     Authorization: `Bearer ${accessToken}`,
        //   },
      });
const token=localStorage.getItem("accessToken")
let organization;
if(token){
 organization=jwtDecode(token)?.organization?.name

}

dispatch(setIsLoading(false));
      if ((response.status === 200||response.status === 201) && response.data.success) {
        dispatch(
          setsiteDetailsData(
            response?.data?.data.map((item) => {
              return {
                ...item,
                level_1:organization
                // supplier_registration_number:item.supplier_registration_code
              
              };
            })
          )
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchPurchasedGoodsandServicesItemData = createAsyncThunk(
  "general/fetchPurchasedGoodsandServicesItemData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id=getOrganizationId()

    let apiUrl = "/scope3/category1/greenmentor-item-master/";

    try {
      const response = await api.get(`${apiUrl}`, {
        //   headers: {
        //     Authorization: `Bearer ${accessToken}`,
        //   },
      });
      if ((response.status === 200||response.status === 201) && response.data.success) {
      
        dispatch(
          setPurchasedGoodsandServicesItemData(
            response?.data?.data.map((item) => {
              return {
                ...item,
                
              };
            })
          )
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchUnitData = createAsyncThunk(
  "general/fetchUnitData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    try {
      const response = await api.get(`/scope3/category2/units`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setunitData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);



