export function processData(Inputdata, timeInterval, breakpoint, year) {
  let data;
  // console.log("Inputdata",Inputdata,Inputdata, timeInterval, breakpoint, year)
  if (year) {
    data = Inputdata.filter((entry) => entry.year === year);
    data.sort((a, b) => a.quarter - b.quarter);
  } else {
    if (timeInterval === "Quarter") {
      function sortByYearAndQuarter(a, b) {
        if (a.year !== b.year) {
          return a.year - b.year;
        } else {
          return a.quarter - b.quarter;
        }
      }

      Inputdata.sort(sortByYearAndQuarter);
      data = Inputdata;
    } else {
      function sortByYearAndMonth(a, b) {
        if (a?.year !== b?.year) {
          return a.year - b.year;
        } else {
          const monthsOrder = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sepr",
            "Oct",
            "Nov",
            "Dec",
          ];
          return (
            monthsOrder?.indexOf(a?.month) - monthsOrder?.indexOf(b?.month)
          );
        }
      }

      Inputdata.sort(sortByYearAndMonth);
      data = Inputdata;
    }
  }

  let processedData = {};
  // console.log(data)
  data.forEach((entry) => {
    let { year, quarter, total_co2e_kg, month } = entry;
    total_co2e_kg = Number(total_co2e_kg);
    year = Number(year);
    let filter = entry[breakpoint] || "Total";

    switch (timeInterval) {
      case "Year":
        if (!processedData[year]) {
          processedData[year] = {};
        }
        if (!processedData[year][filter]) {
          processedData[year][filter] = {
            totalEmission: 0,
          };
        }
        processedData[year][filter].totalEmission += total_co2e_kg;
        break;

      case "Quarter":
        const quarterKey = `${year}-Q${quarter}`;
        if (!processedData[quarterKey]) {
          processedData[quarterKey] = {};
        }
        if (!processedData[quarterKey][filter]) {
          processedData[quarterKey][filter] = {
            totalEmission: 0,
          };
        }
        processedData[quarterKey][filter].totalEmission += total_co2e_kg;
        break;

      case "Month":
        const monthKey = `${year}-${month}`;
        if (!processedData[monthKey]) {
          processedData[monthKey] = {};
        }
        if (!processedData[monthKey][filter]) {
          processedData[monthKey][filter] = {
            totalEmission: 0,
          };
        }
        processedData[monthKey][filter].totalEmission += total_co2e_kg;
        break;

      default:
        break;
    }
  });

  return processedData;
}

function processDataToLablesAndDataSets(data) {
  
  let series = [];
  let legends = [];
  let xAxisLabels = Object.keys(data);
  for (const timeKey in data) {
    for (const key in data[timeKey]) {
      if (!legends.includes(key)) {
        legends.push(key);
      }
    }
  }

  for (const legend of legends) {
    let seriesData = [];

    for (const timeKey of xAxisLabels) {
      if (data[timeKey][legend]) {
        seriesData.push(data[timeKey][legend].totalEmission);
      } else {
        seriesData.push(0); // Placeholder for missing data
      }
    }

    series.push({
      name: legend,
      type: "pie",
      radius: ["50%", "70%"], // Inner and outer radius for donut chart
      center: ["50%", "45%"],
      label: {
        formatter: "{b}: {d}%",
      },
      data: xAxisLabels.map((label, index) => ({
        name: label,
        value: seriesData[index],
      })),
    });
  }

  return { xAxisLabels, series };
}

export const generatePieChartOptions = (
  text,
  filteredData,
  breaktype,
  currentFinancialYear
) => {
  if (
    !filteredData ||
    !Array.isArray(filteredData) ||
    filteredData.length === 0
  ) {
    console.error("Invalid or empty data provided.");
    return {};
  }

  const { series } = processDataToLablesAndDataSets(
    processData(filteredData, "Year", breaktype, Number(currentFinancialYear))
  );

  if (!series || !Array.isArray(series)) {
    console.error("Error processing data into series.");
    return {};
  }

  const pieChartData = series.map((dataItem) => ({
    value: dataItem.data
      .reduce((acc, curr) => acc + Number(curr.value), 0)
      .toFixed(),
    name: dataItem.name,
  }));

  let topThree = series
    .sort(
      (a, b) =>
        b.data.reduce((acc, curr) => acc + Number(curr.value), 0) -
        a.data.reduce((acc, curr) => acc + Number(curr.value), 0)
    )
    .slice(0, 4)
    .map((dataItem) => {
      const totalValue = pieChartData.reduce(
        (acc, curr) => acc + Number(curr.value),
        0
      );
      const dataValue = dataItem.data.reduce(
        (acc, curr) => acc + Number(curr.value),
        0
      );
      const percent =
        totalValue !== 0 ? ((dataValue / totalValue) * 100).toFixed() : 0;

      return {
        name: dataItem.name,
        percent: percent,
      };
    });

  let categorytext = text.split("by ")[1]?.trim();

  if (
    categorytext === "Facility" ||
    categorytext === "Production type" ||
    categorytext === "Country of origin"
  ) {
    topThree = [];
  }
console.log({pieChartData})
  return {
    
    text: categorytext,
    topThree,
    option: {
      responsive: true,
      title: {
        text,
        left: "center",
      },
      toolbox: {
        show: true,
        orient: "vertical",
        left: "right",
        top: "center",
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: true },
          restore: { show: true },
          saveAsImage: { show: true ,
            title: "Save as Image",
            pixelRatio: 2
          },
        },
      },
      tooltip: {
        trigger: "item",
        formatter: (params) => {
          const emissionValue = parseFloat(params.value).toFixed();
          let tooltipText = `${params.name}<br/>Emissions: ${emissionValue} Kg Co2e`;

          // if (numberOfEmployees && numberOfTrips) {
          //   const emissionsPerTripPerEmployee = (
          //     params.value / numberOfTrips / numberOfEmployees
          //   ).toFixed(2);
          //   tooltipText += `<br/>Emissions per trip per employee: ${emissionsPerTripPerEmployee}`;
          //   tooltipText += `<br/>Trips: ${numberOfTrips}`;
          //   // tooltipText += `<br/>Employees: ${numberOfEmployees}`;
          // }

          return tooltipText;
        },
      },
     legend: {
      type:"scroll",
        bottom: 0, // Align legend to the bottom
        left: "center", // Center the legend horizontally
        padding: [10, 0], // Add some padding to ensure it doesn't touch the chart
        itemHeight: 10, // Set consistent size for legend items
        itemWidth: 10, // Set consistent size for legend items
        icon: 'circle', // Use consistent icon shapes for better alignment
      },
      series: [
        {
          type: "pie",
          radius: ["40%", "70%"],
          center: ["50%", "45%"], // center: ["50%", "45%"], // Adjusted center to move pie chart up
          avoidLabelOverlap: false,
          label: {
            position: "inside",
            show: true,
            fontSize: 10,
            fontWeight: "bold",
            formatter: (params) => {
              return `${params?.percent?.toFixed()}%`;
            },
          },
          labelLine: {
            show: true,
          },
          data: pieChartData.sort((a,b)=>b.value-a.value) || [],
        },
      ],
    },
  };
};

export const calculateChange = (current, previous) => {
  if (previous === null || previous === 0 || current === null || current === 0)
    return 0;
  return (
    ((parseFloat(current) - parseFloat(previous)) / parseFloat(previous)) * 100
  );
};

export const calculateEmissionToRevenueRatio = (emissions, revenue) => {
  if (revenue === null || revenue === 0) return 0;
  return (emissions / revenue).toFixed(2);
};

export const getMonthName = (monthNumber) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[monthNumber - 1] || "";
};

export const getEmissionsForSameMonthAndYear = (
  data,
  month,
  year,
  breakdownType,
  breakdownKey
) => {
  return data
    ?.filter((item) => {
      return (
        // getMonthName(month) === item.month &&
        parseInt(item.year) === Number(year) &&
        item[breakdownType] === breakdownKey
      );
    })
    .reduce((total, item) => total + item.total_co2e_kg, 0);
};
export const getTotalEmissionsItem = (data, year, breakdownKey) => {
  const allitem = data
    ?.filter((item) => {
      return (
        parseInt(item.year) === Number(year)
        // item.item === breakdownKey
      );
    })
    .reduce((total, item) => total + item.total_co2e_kg, 0);
  return allitem;
};
export const getTotalEmissionsSupplier = (data, year, breakdownKey) => {
  const allitem = data
    ?.filter((item) => {
      return (
        parseInt(item.year) === Number(year)
        // item.supplier === breakdownKey
      );
    })
    .reduce((total, item) => total + item.total_co2e_kg, 0);
  return allitem;
};

// Function to aggregate data by item and supplier
export function calculateAggregatedData(data, currentFinancialYear) {
  const aggregatedDataByItem = {};
  const aggregatedDataBySupplier = {};
  data
    ?.filter((i) => i.year === currentFinancialYear)
    ?.forEach((entry) => {
      // Aggregate by item
      if (!aggregatedDataByItem[entry.item]) {
        aggregatedDataByItem[entry.item] = { ...entry };
      } else {
        aggregatedDataByItem[entry.item].total_co2e_kg += entry.total_co2e_kg;
      }

      // Aggregate by supplier
      if (!aggregatedDataBySupplier[entry.supplier]) {
        aggregatedDataBySupplier[entry.supplier] = { ...entry };
      } else {
        aggregatedDataBySupplier[entry.supplier].total_co2e_kg +=
          entry.total_co2e_kg;
      }
    });

  return { aggregatedDataByItem, aggregatedDataBySupplier };
}

export function calculateEmissionByitemandSupplier(
  data,
  currentMonth,
  currentYear
) {
  const Data = calculateAggregatedData(data, currentYear);

  const { aggregatedDataByItem, aggregatedDataBySupplier } = Data;

  const itemsWithMetrics = Object.values(aggregatedDataByItem).map((item) => {
    const previousYearEmissions = getEmissionsForSameMonthAndYear(
      data,
      currentMonth,
      currentYear - 1,
      "item",
      item.item
    );

    return {
      year: currentYear,
      item: item.item,
      total_co2e_kg: parseFloat(item.total_co2e_kg).toFixed(),
      contribution: (
        (parseFloat(item.total_co2e_kg) /
          getTotalEmissionsItem(data, currentYear, item.item)) *
        100
      ).toFixed(),
      emission_to_revenue_ratio: calculateEmissionToRevenueRatio(
        item.total_co2e_kg,
        item.amount_paid
      ),
      change: parseFloat(
        calculateChange(
          getEmissionsForSameMonthAndYear(
            data,
            currentMonth,
            currentYear,
            "item",
            item.item
          ),
          previousYearEmissions
        )
      ).toFixed(),
    };
  });
  const suppliersWithMetrics = Object.values(aggregatedDataBySupplier).map(
    (supplier) => {
      // console.log(getEmissionsForSameMonthAndYear(
      //   data,
      //   currentMonth,
      //   currentYear,
      //   "supplier",
      //   supplier.supplier
      // ),"supplier",data,supplier.supplier)
      const previousYearEmissions = getEmissionsForSameMonthAndYear(
        data,
        currentMonth,
        currentYear - 1,
        "supplier",
        supplier.supplier
      );
      return {
        supplier: supplier.supplier,
        total_co2e_kg: parseFloat(supplier.total_co2e_kg).toFixed(),
        emission_to_revenue_ratio: calculateEmissionToRevenueRatio(
          supplier.total_co2e_kg,
          supplier.amount_paid
        ),
        contribution: (
          (parseFloat(supplier.total_co2e_kg) /
            getTotalEmissionsSupplier(data, currentYear, supplier.supplier)) *
          100
        ).toFixed(),
        change: parseFloat(
          calculateChange(
            getEmissionsForSameMonthAndYear(
              data,
              currentMonth,
              currentYear,
              "supplier",
              supplier.supplier
            ),
            previousYearEmissions
          )
        ).toFixed(),
      };
    }
  );

  const sortedItems = [...itemsWithMetrics].sort(
    (a, b) => b.contribution - a.contribution
  );
  const sortedSuppliers = [...suppliersWithMetrics].sort(
    (a, b) => b.contribution - a.contribution
  );
  // console.log({ sortedItems, sortedSuppliers });
  return {
    sortedItems,
    sortedSuppliers,
  };
}

export const transportationData = [];
export function generateSunburstChartDataCategory4(
  data,
  level1Key,
  level2Key,
  text = "title",
  currentFinancialYear,
  mode_of_travel
) {
  const chartData = {};
  let totalValue = 0;
  const valueKey = "total_co2e_kg";
  // Calculate total value
  if (mode_of_travel) {
    data = data.filter((item) => item.mode_of_travel === mode_of_travel);
  }

  data.forEach((entry) => {
    totalValue += parseFloat(entry[valueKey]);
  });

  data.forEach((entry) => {
    const level1 = entry[level1Key];
    const level2 = entry[level2Key];
    const value = parseFloat(entry[valueKey]);
    const percentage = (value / totalValue) * 100;

    if (!chartData[level1]) {
      chartData[level1] = { value: 0, percentage: 0, children: {} };
    }

    if (!chartData[level1].children[level2]) {
      chartData[level1].children[level2] = { value, percentage };
    } else {
      chartData[level1].children[level2].value += value;
      chartData[level1].children[level2].percentage += percentage;
    }

    // Update L1 total value and percentage
    chartData[level1].value += value;
    chartData[level1].percentage = (chartData[level1].value / totalValue) * 100;
  });

  const echartsData = [];
  Object.keys(chartData).forEach((level1) => {
    const { value, percentage, children } = chartData[level1];
    const level1Data = {
      name: `${level1 || "total"}\n(${percentage.toFixed()}%)`,
      children: [],
      value,
    };
    Object.keys(children).forEach((level2) => {
      const { value: childValue, percentage: childPercentage } =
        children[level2];
      const level2Data = {
        name: `${level2}\n(${childPercentage.toFixed()}%)`,
        value: childValue,
      };
      level1Data.children.push(level2Data);
    });
    echartsData.push(level1Data);
  });

  const sunburstOptions = {
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        // console.log(params)
        return `${
          params.data.name || "Total Emission"
        } <br/> ${params.data.value.toFixed()} Kg Co2e`;
      },
    },
    title: {
      text,
      left: "center",
    },
    series: {
      type: "sunburst",
      data: echartsData,
      radius: [70, "80%"],
      label: {
        show: true,
        formatter: (params) => {
          return `${params.name || ""}`;
        },
        rotate: 45,
        // fontSize: 10,
        fontWeight: 600,
      },
      itemStyle: {
        borderRadius: 5,
        borderWidth: 2,
      },
    },
  };
  // console.log(sunburstOptions)
  return sunburstOptions;
}
export function generateSunburstChartDataCategory6(
  data,
  text = "title",
  level1Key,
  level2Key,
  mode_of_travel,
  currentFinancialYear
) {
  // Filter data based on mode_of_travel and currentFinancialYear
  if (mode_of_travel) {
    data = data.filter((item) => item.mode_of_travel === mode_of_travel);
  }
  if (currentFinancialYear) {
    data = data.filter((item) => item.year === currentFinancialYear);
  }

  const totalTrips = data.length;
  const chartData = {};
  let totalValue = 0;
  const valueKey = "total_co2e_kg";
  const distanceKey = "distance_covered";

  data.forEach((entry) => {
    totalValue += parseFloat(entry[valueKey]);
  });

  data.forEach((entry) => {
    const level1 = entry[level1Key];
    const level2 = entry[level2Key];
    const value = parseFloat(entry[valueKey]);
    const distance = parseFloat(entry[distanceKey]);
    const percentage = (value / totalValue) * 100;

    if (!chartData[level1]) {
      chartData[level1] = {
        value: 0,
        percentage: 0,
        children: {},
        distance: 0,
        trips: 0,
      };
    }
    chartData[level1].trips += 1;

    if (!chartData[level1].children[level2]) {
      chartData[level1].children[level2] = {
        value,
        percentage,
        distance,
        trips: 0,
      };
    } else {
      chartData[level1].children[level2].value += value;
      chartData[level1].children[level2].percentage += percentage;
      chartData[level1].children[level2].distance += distance;
    }
    chartData[level1].children[level2].trips += 1;

    // Update L1 total value, percentage, and distance
    chartData[level1].value += value;
    chartData[level1].percentage = (chartData[level1].value / totalValue) * 100;
    chartData[level1].distance += distance;
  });

  const echartsData = [];
  Object.keys(chartData).forEach((level1) => {
    const { value, percentage, children, distance, trips } = chartData[level1];
    const level1Data = {
      name: `${level1 || "total"}`,
      children: [],
      percentage: `${percentage.toFixed()}%`,
      label: `${level1 || "total"}`,
      value,
      distance,
      trips,
    };
    Object.keys(children).forEach((level2) => {
      const {
        value: childValue,
        percentage: childPercentage,
        distance: childDistance,
        trips: childTrips,
      } = children[level2];
      const level2Data = {
        name: `${level2 || "total"}`,
        value: childValue,
        percentage: `${childPercentage.toFixed()}%`,
        label: `${level2 || "total"}`,
        distance: childDistance,
        trips: childTrips,
      };
      level1Data.children.push(level2Data);
    });
    echartsData.push(level1Data);
  });

  const sunburstOptions = {
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        return `${params.data.label || "Total Emission"} (${
          params.data.percentage
        }) <br/> Emission: ${params.data.value.toFixed()} kg CO2e <br/> Trips: ${
          params.data.trips
        } <br/> Distance: ${params.data.distance} km`;
      },
    },
    title: {
      text: `${text}`,
      left: "center",
    },
    series: {
      type: "sunburst",
      data: echartsData,
      radius: [70, "80%"],
      label: {
        show: true,
        formatter: (params) => {
          return `${params.data.label || ""} (${params.data.percentage || ""})`;
        },
        rotate: 45,
        fontWeight: 600,
      },
      itemStyle: {
        borderRadius: 5,
        borderWidth: 2,
      },
    },
  };

  return sunburstOptions;
}

// export function generateSunburstChartDataCategory4(
//   data,
//   text = "title",
//   level1Key = "mode_of_travel",
//   level2Key = "travel_type",
//   level3Key = "travel_subtype",
//   currentFinancialYear
// ) {
//   const chartData = {};
//   let totalValue = 0;
//   const valueKey = "total_co2e_kg";

//   // Calculate total value
//   data.forEach((entry) => {
//     totalValue += parseFloat(entry[valueKey]);
//   });

//   data.forEach((entry) => {
//     const level1 = entry[level1Key];
//     const level2 = entry[level2Key];
//     const level3 = entry[level3Key];
//     const value = parseFloat(entry[valueKey]);
//     const percentage = (value / totalValue) * 100;

//     if (!chartData[level1]) {
//       chartData[level1] = { value: 0, percentage: 0, children: {} };
//     }

//     if (!chartData[level1].children[level2]) {
//       chartData[level1].children[level2] = { value: 0, percentage: 0, children: {} };
//     }

//     if (!chartData[level1].children[level2].children[level3]) {
//       chartData[level1].children[level2].children[level3] = { value, percentage };
//     } else {
//       chartData[level1].children[level2].children[level3].value += value;
//       chartData[level1].children[level2].children[level3].percentage += percentage;
//     }

//     // Update L2 total value and percentage
//     chartData[level1].children[level2].value += value;
//     chartData[level1].children[level2].percentage = (chartData[level1].children[level2].value / totalValue) * 100;

//     // Update L1 total value and percentage
//     chartData[level1].value += value;
//     chartData[level1].percentage = (chartData[level1].value / totalValue) * 100;
//   });

//   const echartsData = [];
//   Object.keys(chartData).forEach((level1) => {
//     const { value, percentage, children: level2Children } = chartData[level1];
//     const level1Data = {
//       name: `${level1}`,
//       // name: `${level1}\n(${percentage.toFixed()}%)`,
//       per: `${percentage.toFixed()}%`,
//       children: [],
//       value
//     };
//     Object.keys(level2Children).forEach((level2) => {
//       const { value: level2Value, percentage: level2Percentage, children: level3Children } =
//         level2Children[level2];
//       const level2Data = {
//         name: `${level2}`,
//         per: `${level2Percentage.toFixed()}%`,
//         children: [],
//         value: level2Value,
//       };
//       Object.keys(level3Children).forEach((level3) => {
//         const { value: level3Value, percentage: level3Percentage } = level3Children[level3];
//         const level3Data = {
//           name: `${level3}`,
//           per: `${level3Percentage.toFixed()}%`,
//           value: level3Value,
//         };
//         level2Data.children.push(level3Data);
//       });
//       level1Data.children.push(level2Data);
//     });
//     echartsData.push(level1Data);
//   });

//   const sunburstOptions = {
//     toolbox: {
//       show: true,
//       orient: "vertical",
//       left: "right",
//       top: "center",
//       feature: {
//         mark: { show: true },
//         dataView: { show: true, readOnly: true },
//         restore: { show: true },
//         saveAsImage: { show: true },
//       },
//     },
//     tooltip: {
//       trigger: "item",
//       formatter: (params) => {
//         return `${params.data.name || "Total Emission"} (${params.data.per || "0"}) <br/> ${params.data.value.toFixed()} Co2e`;
//       },
//     },
//     title: {
//       text,
//       left: "center",
//     },
//     series: {
//       type: "sunburst",
//       data: echartsData,
//       radius: [70, "80%"],
//       label: {
//         show: true,
//         formatter: (params) => {
//           return `${params.name || ""}`;
//         },
//         rotate: 45,
//         fontWeight: 500,
//       },
//       itemStyle: {
//         borderRadius: 5,
//         borderWidth: 2,
//       },
//     },
//   };

//   return sunburstOptions;
// }

function filterByCurrentYear(data, currentYear) {
  return data.filter((item) => item.year === currentYear);
}

export function generateDonutChartOptions(data, currentYear) {
  //  console.log(data,currentYear)
  const filteredData = filterByCurrentYear(data, Number(currentYear));

  const groupedData = {};
  filteredData.forEach(({ site, source_location, total_co2e_kg }) => {
    const key = `${site}-${source_location}`;
    if (!groupedData[key]) {
      groupedData[key] = { site, source_location, total_co2e_kg: 0 };
    }
    groupedData[key].total_co2e_kg += total_co2e_kg;
  });

  const seriesData = [];
  Object.values(groupedData).forEach(
    ({ site, source_location, total_co2e_kg }) => {
      seriesData.push({
        name: `${site}-${source_location}`,
        value: total_co2e_kg.toFixed(2),
      });
    }
  );

  const options = {
    title: {
      text: " emissions by sites( destination)  and source",
      left: "center",
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      bottom: "5%",
      top: "bottom",
    },
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        restore: { show: true }, // Ensure that the restore feature is enabled
        saveAsImage: { show: true },
      },
    },
    series: [
      {
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },

        labelLine: {
          show: false,
        },
        data: seriesData,
      },
    ],
  };

  return options;
}
export function calculateYOYChangeForCat1Data(data, year) {
  const filteredDataCurrent = data.filter((i) => i.year == year);
  const filteredDataLastYear = data.filter((i) => i.year == year - 1);
  const numberOfItemsCurrent =
    filteredDataCurrent.length > 0
      ? [...new Set(filteredDataCurrent.map((i) => i.item))].length
      : 0;
  const numberOfItemsLastYear =
    filteredDataLastYear.length > 0
      ? [...new Set(filteredDataLastYear.map((i) => i.item))].length
      : 0;
  const numberOfSuppliersCurrent =
    filteredDataCurrent.length > 0
      ? [...new Set(filteredDataCurrent.map((i) => i.supplier))].length
      : 0;
  const numberOfSuppliersLastYear =
    filteredDataLastYear.length > 0
      ? [...new Set(filteredDataLastYear.map((i) => i.supplier))].length
      : 0;
 
  const itemsChangePercentage =
    numberOfItemsLastYear !== 0
      ? ((numberOfItemsCurrent - numberOfItemsLastYear) /
          numberOfItemsLastYear) *
        100
      : 0;
  const suppliersChangePercentage =
    numberOfSuppliersLastYear !== 0
      ? ((numberOfSuppliersCurrent - numberOfSuppliersLastYear) /
          numberOfSuppliersLastYear) *
        100
      : 0;

  const roundeditemsChangePercentage = isFinite(itemsChangePercentage)
    ? Math.floor(itemsChangePercentage)
    : 0;
  const roundedsuppliersChangePercentage = isFinite(suppliersChangePercentage)
    ? Math.floor(suppliersChangePercentage)
    : 0;

  return {
    supplier: {
      currentYear: isFinite(numberOfSuppliersCurrent)
        ? numberOfSuppliersCurrent.toFixed()
        : "0",
      previousYear: isFinite(numberOfSuppliersLastYear)
        ? Math.floor(numberOfSuppliersLastYear)
        : "0",
      percentageChange: roundedsuppliersChangePercentage,
    },
    item: {
      currentYear: isFinite(numberOfItemsCurrent)
        ? numberOfItemsCurrent.toFixed()
        : "0",
      previousYear: isFinite(numberOfItemsLastYear)
        ? Math.floor(numberOfItemsLastYear)
        : "0",
      percentageChange: roundeditemsChangePercentage,
    },
  };
}
