import React, { useState } from 'react'

import { ReactComponent as UserIcon } from "../../app/assets/User.svg";
import { ReactComponent as RequestpanelIcon } from "../../app/assets/PullRequestIcon.svg";
import UserProfile from './UserProfile';
import Header from '../../components/common/Header';
import RequestPanel from './RequestPanel';
function UserProfileMain() {
    const [activeTab, setActiveTab] = useState(1);

  const randerActiveTab = (tabName) => {
    switch (tabName) {
      case 1:
        return <UserProfile />;
      case 2:
        return <RequestPanel />;

      default:
        break;
    }
  };
  return (

    <div className='flex flex-col space-y-2 h-screen justify-start'>
      <Header pageTitle={"User Profile"} PageIcon={UserIcon}/>
      <div className="flex flex-row w-full h-12 justify-between items-center px-2">
        <div className="flex flex-row justify-start items-center">
          <div
            className={`flex flex-row justify-center items-center w-48 h-8 cursor-pointer border border-green-600 rounded-md mr-4 ${
              activeTab === 1
                ? "bg-green-600 text-white"
                : "bg-white text-green-600"
            }`}
            onClick={() => setActiveTab(1)}
          >
            <span className="mr-4">
              <UserIcon
                fill="white"
                // color="white"
                className=" w-4 h-4"
              />
            </span>
            <span className="text-xs">User Profile</span>
          </div>
         
          {/* <div
            className={`flex flex-row justify-center items-center w-48 h-8 cursor-pointer border border-green-600 rounded-md mr-4 ${
              activeTab === 2
                ? "bg-green-600 text-white"
                : "bg-white text-green-600"
            }`}
            onClick={() => setActiveTab(2)}
          >
            <span className="mr-4">
              <RequestpanelIcon fill="white" className="w-4 h-4" />
            </span>
            <span className="text-xs">Request Panel</span>
          </div> */}
          
        </div>
      </div>
      <div>{randerActiveTab(activeTab)}</div>
    </div>
  )
}

export default UserProfileMain
