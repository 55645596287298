import { createSlice } from "@reduxjs/toolkit";

// Assume you have thunks for login and logout (similar to the previous example)
const initialFormValue = {
  treated_period_start: "",
  treated_period_end: "",
  treated_site_id: "",
  waste_material: "",
  waste_material_id: "",
  treated_treatment_mechanism: "",
  treatment_mechanism_id: "",
  treated_proportion_of_waste: "",
  treated_quantity: "",
  treated_unit_id: "",
  treated_amount_spent: "",
  treated_currency_id: "",
  treated_evidence: null,
};
const scope3Category5Slice = createSlice({
  name: "Scope3Category5",
  initialState: {
    scope3Category5RecordsTreated: [
      
    ],
    scope3Category5WasteProduceForm: {},
    scope3Category5WasteTreatedForm: {},
    scope3Category5WasteTreatedFormCopyEdit: {},
    scope3Category5ProducedInputRecords: [
      
    ],
    siteDataForFilter: [],
    WasteMaterialDataForFilter: [],
    WasteMechanismDataForFilter: [],
    wasteMaterial: [],
    sourceTypeData: [],
    lcaActivityData: [],
    formValues: [initialFormValue],
    wasteImpact: [
      {
        id: 1,
        waste_impact: "Hazardous",
      },
      {
        id: 2,
        waste_impact: "Non-Hazardous",
      },
    ],
    filters: null,
    sorting: null,
    treatmentMechanism: [],
    wasteTreatmentMapping: [],
producedQuantityByIndex:{},
    scope3Category5RecordType: 1,
    itemsPerPage: 10,
    totalPages: 1,
    currentPage: 1,
    totalCount: 10,
    siteData: [],
StoreTreated:[],
    unitData: [],
    currencyData: [],
  },
  reducers: {
    setscope3Category5RecordsTreated: (state, action) => {
      state.scope3Category5RecordsTreated = action.payload;
    },
    setscope3Category5ProducedInputRecords: (state, action) => {
      state.scope3Category5ProducedInputRecords = action.payload;
    },
    setscope3Category5WasteProduceForm: (state, action) => {
      return {
        ...state,
        scope3Category5WasteProduceForm: {
          ...state.scope3Category5WasteProduceForm,
          ...action.payload,
        },
      };
    },
    setscope3Category5WasteTreatedForm: (state, action) => {
      return {
        ...state,
        scope3Category5WasteTreatedForm: {
          ...state.scope3Category5WasteTreatedForm,
          ...action.payload,
        },
      };
    },
    setscope3Category5WasteTreatedFormCopyEdit: (state, action) => {
      return {
        ...state,
        scope3Category5WasteTreatedFormCopyEdit: {
          ...state.scope3Category5WasteTreatedFormCopyEdit,
          ...action.payload,
        },
      };
    },
    resetscope3Category5Form: (state, action) => {
      state.scope3Category5WasteTreatedForm = {};
      state.scope3Category5WasteProduceForm = {};
      state.formValues = [initialFormValue];
    },
    resetscope3Category5WasteTreatedForme3Category5Form: (state) => {
      state.scope3Category5WasteTreatedForm = {};
    },
    setEvidence: (state, action) => {
      const { type, evidence } = action.payload;
      if (type === "wasteProduce") {
        state.scope3Category5WasteProduceForm.evidence = evidence;
      } else {
        state.scope3Category5WasteTreatedForm.evidence = evidence;
      }
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setStoreTreated: (state, action) => {
      state.StoreTreated = action.payload;
    },
    setSiteDataForFilter: (state, action) => {
      state.siteDataForFilter = action.payload;
    },
    setWasteMechanismDataForFilter: (state, action) => {
      state.WasteMechanismDataForFilter = action.payload;
    },
    setWasteMaterialDataForFilter: (state, action) => {
      state.WasteMaterialDataForFilter = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    setwasteImpact: (state, action) => {
      state.wasteImpact = action.payload;
    },
    setFormValues: (state, action) => {
      state.formValues = action.payload;
    },
    setProducedQuantityByIndex: (state, action) => {
      state.producedQuantityByIndex = action.payload;
    },
    setTreatmentMechanism: (state, action) => {
      state.treatmentMechanism = action.payload;
    },
    setwasteTreatmentMapping: (state, action) => {
      state.wasteTreatmentMapping = action.payload;
    },

    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },

    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload;
    },
    setSiteData: (state, action) => {
      state.siteData = action.payload;
    },
    setSourceTypeData: (state, action) => {
      state.sourceTypeData = action.payload;
    },
    setLcaActivityData: (state, action) => {
      state.lcaActivityData = action.payload;
    },
    setwasteMaterial: (state, action) => {
      state.wasteMaterial = action.payload;
    },

    setfiltredwasteMaterial: (state, action) => {
      state.filtredwasteMaterial = action.payload;
    },

    setUnitData: (state, action) => {
      state.unitData = action.payload;
    },
    setCurrencyData: (state, action) => {
      state.currencyData = action.payload;
    },
    setdeletes3c9Data: (state, action) => {
      state.scope3Category5ProducedInputRecords =
        state.scope3Category5ProducedInputRecords.filter(
          (i) => i.id != action.payload
        );
    },
  },
});

export const {
  setfiltredwasteMaterial,
  setscope3Category5WasteTreatedForm,
  setscope3Category5WasteTreatedFormCopyEdit,
  resetscope3Category5WasteTreatedForme3Category5Form,
  setdeletes3c9Data,
  setFormValues,
  setwasteMaterial,
  setFilters,setStoreTreated,
  setEvidence,
  setscope3Category5WasteProduceForm,setProducedQuantityByIndex,setSiteDataForFilter,setWasteMaterialDataForFilter,setWasteMechanismDataForFilter,

  setTreatmentMechanism,
  setwasteImpact,
  setscope3Category5RecordsTreated,
  setSorting,
  setCurrentPage,
  setItemsPerPage,

  setTotalCount,
  setTotalPages,
  setCurrencyData,

  setSiteData,
  setUnitData,
  setwasteTreatmentMapping,
  resetscope3Category5Form,

  setscope3Category5ProducedInputRecords,

  setscope3Category5CountryData,
  setLcaActivityData,
  setSourceTypeData,
} = scope3Category5Slice.actions;
export default scope3Category5Slice.reducer;
