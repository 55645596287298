import React from 'react'
import CalendarComponent from './CalendarComponent'


function Reduce() {
  return (
    <div>
     <CalendarComponent/>
    </div>
  )
}

export default Reduce
