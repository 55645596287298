import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { generateSunburstChartDataCategory4,generatePieChartOptions } from "../../VisualizationFeature/Redux/processData/ProcessDataforCategory1";
import { formatText, generateSeriesDataForStackedChart, generateStackedChartOptions, processStackedChartData } from "../../VisualizationFeature/Redux/Processdata";
import ChartComponent from "../../VisualizationFeature/components/ChartComponents";


// import StackedBarChart from "../../VisualizationFeature/components/StackedBarChart";

import { SunburstChart } from "../../VisualizationFeature/components/SunBurstChartEmission";
import PieChart from "../../VisualizationFeature/components/PieChart";

function Category4Main({
  width,
  height,
  filterBy,
  data,
  chartDataArray,
  Options,
  // StackBreakDown,
  piechartDataArray,
  currentFinancialYear,
}) {
  const dispatch=useDispatch()
  const [filtredDataByGlobleFilter, setfiltredDataByGlobleFilter] = useState(
    []
  );
// console.log(piechartDataArray)
  const [typeBreakdown, setTypeBreakdown] = useState("");

  const [options, setOptions] = useState(null); // Initialize options as null


  
  const handleTypeBreakdownChange = (e) => {
    setTypeBreakdown(e.target.value);
  };
 
  
  const updateOptions = (filteredData) => {
    if (filteredData.length > 0) {
      const processed = processStackedChartData(
        filteredData,
        currentFinancialYear,
        typeBreakdown
      );

      const { series, legends } = generateSeriesDataForStackedChart(processed);
      const xAxisData = Object.keys(processed);
      const updatedOptions = generateStackedChartOptions(
        filteredData,
        currentFinancialYear,
        typeBreakdown,
        legends,
        xAxisData,
        series
      );
    
      setOptions(updatedOptions);
    } else {
      // Handle case where filtered data is empty
      setOptions(null);
    }
  };
  useEffect(() => {
    if (data && data.length > 0) {
      const filteredData = data.filter((entry) => {
        for (let key in filterBy) {
          if (filterBy[key] && entry[key] !== filterBy[key]) {
            return false;
          }
        }
        return true;
      });
      setfiltredDataByGlobleFilter(filteredData); // filter data according to globle  filter by site , useType and fuel type
      updateOptions(filteredData);
    }
  }, [data, filterBy, currentFinancialYear]);
  useEffect(() => {
    if (filtredDataByGlobleFilter.length > 0) {
      updateOptions(filtredDataByGlobleFilter);
    }
  }, [filtredDataByGlobleFilter, typeBreakdown, data, currentFinancialYear,dispatch]);

  if (!Array.isArray(data) || data.length === 0) {
    return <div>No data available for Charts</div>;
  }

  return (
    <div>
      <div className="border rounded-md mt-[18px] px-2 py-2  w-full shadow-md">
        <ChartComponent
          height={height}
          width={width}
          BreakDownOptions={Options}
          data={filtredDataByGlobleFilter}
        />
      </div>
      
      <div className="grid grid-cols-2 gap-2  w-full py-3">
     
        {/* {chartDataArray.length &&
          chartDataArray
            .map((item) =>
              generateSunburstChartDataCategory4(
                filtredDataByGlobleFilter,
                item.L1,
                item.L2,
                item.title,
                currentFinancialYear
              )
            )
            ?.map((options, index) => (
              <>

              <div
                key={ index}
                className=" border rounded-md mt-[18px] px-2 py-2  w-full shadow-md"
              >
                {options&&<SunburstChart option={options} />}
              </div>
              </>
            ))} */}
            {piechartDataArray.length &&
          piechartDataArray
            .map((item) =>
              generatePieChartOptions(
                item.title,
                filtredDataByGlobleFilter,
                item.dataKey,
                Number(currentFinancialYear)
              )
            )
            ?.map((options, index) => (
              <div
                key={options?.title?.text + index}
                className="border rounded-md mt-[18px] px-2 py-2 w-full shadow-md"
              >
                {options?.option&&<PieChart option={options?.option} />}
              </div>
            ))}
      </div>
     
      {/* <div className="border rounded-md mt-[18px] px-2 py-2  w-full shadow-md">
        
        <PieChart option={generateDonutChartOptions(filtredDataByGlobleFilter,currentFinancialYear)}/>
      </div> */}
    </div>
  );
}

export default Category4Main;
