import React from 'react'
import { ReactComponent as ReportIcon } from "../../app/assets/ReportingIcon.svg";
import Header from '../../components/common/Header';
function GreenReports() {
  return (
    <div>
<Header pageTitle={"Green reports"} PageIcon={ReportIcon}/>
      
    </div>
  )
}

export default GreenReports
