import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useApi from '../../../../customHooks/useApi'

import { populateSafetyRelatedData } from '../../function/functionProcess'


import GenericTiles from './GenericTile'
import { fetchSocialSafetyRelatedIncidentsInputs } from '../../../../features/social/socialThunk'

function SafetyRelatedIncidents() {
  const {socialSafetyRelatedIncidentsRecords,employementType}=useSelector(state=>state.social)
  const dispatch=useDispatch()
  
  const api =useApi()
  useEffect(()=>{
  //  dispatch(resetSocialFormData({}))
dispatch(fetchSocialSafetyRelatedIncidentsInputs({api,params:{}}))
  },[])
  return (
    <div className='overflow-auto h-full w-full'>
   <GenericTiles data={populateSafetyRelatedData(socialSafetyRelatedIncidentsRecords,employementType)} title="Employee Overview"
  categoryHeaders={["Male", "Female", "Not Disclosed", "Other"]}
  dataKey="Accounting Metric" />
    </div>
  )
}

export default SafetyRelatedIncidents
