export function removeDuplicates(array, key) {
    const uniqueKeys = new Set();
    return array.filter(item => {
      const keyValue = item[key];
      if (uniqueKeys.has(keyValue)) {
        return false;
      }
      uniqueKeys.add(keyValue);
      return true;
    });
  }