import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useApi from '../../../../customHooks/useApi'



import GenericTiles from './GenericTile'
import { fetchSocialTrainingInputs } from '../../../../features/social/socialThunk'
import { populateTrainingData } from '../../function/functionProcess'

function Training() {
  const {socialTrainingRecords,employementType}=useSelector(state=>state.social)
  const dispatch=useDispatch()
  
  const api =useApi()
  useEffect(()=>{
  //  dispatch(resetSocialFormData({}))
dispatch(fetchSocialTrainingInputs({api,params:{}}))
  },[])
  return (
    <div className='overflow-auto h-full w-full'>
   <GenericTiles data={populateTrainingData(socialTrainingRecords,employementType)} title="Employee Overview"
  categoryHeaders={["Male", "Female", "Other", "Not Disclosed"]}
  dataKey=" Metrics" />
    </div>
  )
}

export default Training
