import React, { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../../app/assets/CloseIcon.svg";
import { ReactComponent as WasteTreatedIcon } from "../../../app/assets/WasteTreatedIcon.svg";
import { ReactComponent as WasteProducedIcon } from "../../../app/assets/WasteProducedIcon.svg";
import WasteTreatedFormEditAndCopyForm from "./Category5WasteTreatedFormEditAndCopyForm";
import WasteTreatedForm from "./Category5WasteTreatedForm";
import WasteProducedFormEditAndCopyForm from "./Category5WasteProducedFormEditAndCopyForm";
import WasteProducedForm from "./Category5WasteProducedForm";

const Category5FormPopUp = ({
  closePopup,
  type,
  data,
  handleFormChange,
  files,
  startDate,
  selectedWasteType,
}) => {
  const [activeTab, setActiveTab] = useState("wasteProduced");
  const [isWasteProducedFormValid, setIsWasteProducedFormValid] = useState(false);
  useEffect(() => {
    console.log({type,selectedWasteType,isWasteProducedFormValid})
    if (type !== "newEntry") {
      setIsWasteProducedFormValid(true)
      if (selectedWasteType === 2) {
        setActiveTab("wasteTreated");
      } else {
        setActiveTab("wasteProduced");
      }
    } else {
      if (selectedWasteType === 2 && isWasteProducedFormValid) {
        setActiveTab("wasteTreated");
      } else {
        setActiveTab("wasteProduced");
      }
    }
  }, [selectedWasteType, type]); 
  const renderForm = () => {
    switch (activeTab) {
      case "wasteTreated":
        switch (type) {
          case "newEntry":
            return (
              <WasteTreatedForm
                closePurchasePopupForm={closePopup}
                
              />
            );
          case "edit":
          case "copy":
            return (
              <WasteTreatedFormEditAndCopyForm
                closePurchasePopupForm={closePopup}
                actionType={type}
                data={data}
              />
            );
          default:
            break;
        }
        break;
      case "wasteProduced":
        switch (type) {
          case "newEntry":
            return (
              <WasteProducedForm
                setActiveTab={setActiveTab}
                closePurchasePopupForm={closePopup}
                files={files}
                handleFormChange={handleFormChange}
                startDate={startDate}
                setIsWasteProducedFormValid={setIsWasteProducedFormValid}
              />
            );
          case "edit":
          case "copy":
            return (
              <WasteProducedFormEditAndCopyForm
                closePurchasePopupForm={closePopup}
                actionType={type}
                data={data}
                setActiveTab={setActiveTab}
                setIsWasteProducedFormValid={setIsWasteProducedFormValid}
              />
            );
          default:
            break;
        }
        break;
      default:
        break;
    }
  };
  
  return (
    <div className="mainContainer flex w-full h-screen overflow-hidden justify-center items-center z-[100] fixed bg-black bg-opacity-50 top-0 left-0 bottom-0">
      <div className="flex flex-col justify-start gap-4 w-9/12 text-xs bg-white absolute z-[1000] border border-transparent rounded-t-md">
        <div className="flex flex-col justify-between  w-full bg-gray-50 py-2 px-4 border-b border-gray-300">
          <div className="flex justify-between  w-full bg-gray-50 px-4">
            <span className="cursor-pointer">Waste from Operations</span>
            <span className="cursor-pointer" onClick={closePopup}>
              <CloseIcon />
            </span>
          </div>

          <div className="flex flex-shrink-0 w-fit gap-3 px-2 p-1">
            <button
              className={`flex border border-green-600 items-center rounded-md mr-4 cursor-pointer px-2  flex-shrink-0 h-8 ${
                activeTab === "wasteProduced"
                  ? "border-green-600 focus:bg-green-600 text-white white-svg bg-green-600 transition-all duration-500 ease-in-out transform translate-x-0"
                  : "text-green-600 bg-white border-gray-100 hover:bg-green-600 hover:text-white transition-all duration-500 ease-in-out transform "
              } text-center p-1 px-2`}
              onClick={() => setActiveTab("wasteProduced")}
            >
             <WasteProducedIcon className="fill-current w-6 h-6" />
            <span className="px-2">Waste Produced</span>
            </button>
            <button
              className={`flex border border-green-600 items-center rounded-md mr-4 cursor-pointer px-2  flex-shrink-0 h-8 ${
                activeTab === "wasteTreated"
                  ? "border-green-600 focus:bg-green-600 text-white white-svg bg-green-600 transition-all duration-500 ease-in-out transform translate-x-0"
                  : "text-green-600 bg-white border-gray-100 hover:bg-green-600 hover:text-white transition-all duration-500 ease-in-out transform"
              } text-center`}
              onClick={() => isWasteProducedFormValid && setActiveTab("wasteTreated")}
            > <WasteTreatedIcon className="fill-current w-4 h-4 stroke-inherit" />
              <span className="px-2">Waste Treated</span>
            </button>
          </div>
        </div>
        <div className="px-2 h-[430px] overflow-auto">{renderForm()}</div>
      </div>
    </div>
  );
};

export default Category5FormPopUp;

