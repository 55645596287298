import { useState } from "react";

export const Tooltip = ({ message, children }) => {
    const [visible, setVisible] = useState(false);
  
    return (
      <div className="relative flex flex-col items-center group">
        <div
          className="cursor-pointer"
          onMouseEnter={() => setVisible(true)}
          onMouseLeave={() => setVisible(false)}
        >
          {children}
        </div>
        {visible && (
          <div
            className="absolute bottom-full mb-2 w-48 p-2 text-xs text-center text-white bg-gray-700 rounded shadow-lg"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      </div>
    );
  };