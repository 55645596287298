export function SelectField({
    label,
    name,
    value = "",
    onChange,
    options = [],
    Decoration,
    selectClassName = "",
    decorationClassName = "",
    disabled,
  }) {
    return (
      <div className="flex flex-col items-stretch w-full">
        <label htmlFor={name}>{label}</label>
        <div className="w-full flex">
          <div
            className={`flex items-center rounded-tr-none rounded-br-none rounded  py-3 text-gray-600 bg-gray-100  border border-green-400 border-r-0 peer-focus:border-green-400 peer-focus:bg-white transition-colors duration-300 ${
              disabled ? "bg-gray-200" : ""
            } ${decorationClassName}`}
          >
            {Decoration && <Decoration className="colored-svg mr-4 w-6 h-6 ml-3" />}
          </div>
          <select
      
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            aria-label={label}
            className={`w-full p-3 text-gray-600 bg-gray-100 border border-green-400 border-l-0  focus:border-green-400 focus:bg-white focus:outline-none focus:ring-0 appearance-none   transition-colors duration-300 ${
              disabled ? "bg-gray-200" : ""
            } ${selectClassName}`}
            disabled={disabled}
           
          >
          <option value="">Select option</option>
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
  