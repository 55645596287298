import React from 'react'
import { useSelector } from 'react-redux'
import RequestPanelCard from './components/RequestPanelCard'

function RequesPanel() {
    const {requestPanelData}=useSelector((state)=>state.setting)
function onReject(id,comment)
{
  console.log("regect",comment)    
}
function onGrant(id,comment){
  console.log("grant",comment) 
}
  return (
    <div className='p-4 flex flex-col space-y-2 justify-center items-center'>
      {requestPanelData.map((request,index)=>{
        return(
            <RequestPanelCard key={index} onGrant={onGrant} onReject={onReject} {...request}/>
        )
      })}
    </div>
  )
}

export default RequesPanel
