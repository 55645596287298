import { createSlice } from "@reduxjs/toolkit";
import { disclosures } from "../BRSRContent/DiscloseTablesControl";

const initialState = {
  AllData: {},
  renewableData: {},
  nonrenewableData: {},
  tableDisclosuresControlData: disclosures,
};

const BESRSlice = createSlice({
  name: "BESR",
  initialState,
  reducers: {
    setAllData(state, action) {
      state.AllData = action.payload;
    },
    setTableDisclosuresData(state, action) {
      state.tableDisclosuresControlData = action.payload;
    },
    setRenewableData(state, action) {
      state.renewableData = action.payload;
    },
    setNonRenewableData(state, action) {
      state.nonrenewableData = action.payload;
    },

    handleStatusChange(state, action) {
      const { id, value, isParent, parentId ,subDisclosure} = action.payload;
      const disclosure = findDisclosureById(state.tableDisclosuresControlData, id,subDisclosure);

      if (isParent) {
        // If the parent status is "Irrelevant", set all child disclosures to "Irrelevant"
        disclosure.status = value;
        if (value === "Irrelevant") {
          disclosure[subDisclosure].forEach((child) => {
            child.status = "Irrelevant";
          });
        } else {
          // If the parent status is "Relevant", set all child disclosures to "Relevant"
          disclosure[subDisclosure].forEach((child) => {
            child.status = "Relevant";
          });
        }
      } else {
        // Update the specific child disclosure
        const childDisclosure = disclosure[subDisclosure].find((child) => child.id === id);
        if (childDisclosure) {
          childDisclosure.status = value;
        }
      }

      // After status change, check if the parent should be marked as "Completed"
      updateParentStatus(state.tableDisclosuresControlData, parentId,subDisclosure);
    },

    handleDisclosureTypeChange(state, action) {
      const { id, value, isParent, parentId,subDisclosure } = action.payload;
      const disclosure = findDisclosureById(state.tableDisclosuresControlData, id,subDisclosure);

      if (isParent) {
        // If the parent disclosure type is "Irrelevant", set all child disclosures to "Irrelevant"
        disclosure.disclosure_type = value;
        if (value === "Irrelevant") {
          disclosure[subDisclosure].forEach((child) => {
            child.disclosure_type = "Irrelevant";
          });
        } else {
          // If the parent disclosure type is "Relevant", set all child disclosures to "Relevant"
          disclosure[subDisclosure].forEach((child) => {
            child.disclosure_type = "Relevant";
          });
        }
      } else {
        // Update the specific child disclosure type
        const childDisclosure = disclosure[subDisclosure].find((child) => child.id === id);
        if (childDisclosure) {
          childDisclosure.disclosure_type = value;
          if (value === "Irrelevant") {
            childDisclosure.status = "Completed";
          }
        }
      }

      // After type change, check if the parent should be marked as "Completed"
      updateParentStatus(state.tableDisclosuresControlData, parentId,subDisclosure);
    },
  },
});

// Helper function to find a disclosure by its ID or parent ID
function findDisclosureById(data, id,subDisclosure) {
  for (let disclosure of data) {
    if (disclosure.id === id) return disclosure;
    // Check for child disclosures
    const childDisclosure = disclosure[subDisclosure]?.find((child) => child.id === id);
    if (childDisclosure) return disclosure;
  }
  return null;
}

// Helper function to update the parent status if all children are "Completed"
function updateParentStatus(data, parentId,subDisclosure) {
  const parentDisclosure = findDisclosureById(data, parentId);
  if (!parentDisclosure) return;
console.log()
  const allChildrenCompleted = parentDisclosure[subDisclosure].every(
    (child) => child.status === "Completed"
  );

  // If all children are "Completed", mark the parent as "Completed"
  if (allChildrenCompleted) {
    parentDisclosure.status = "Completed";
  } else {
    parentDisclosure.status = "Pending";
  }
}

export const {
  setAllData,
  setNonRenewableData,
  setRenewableData,
  setTableDisclosuresData,
  handleStatusChange,
  handleDisclosureTypeChange,
} = BESRSlice.actions;

export default BESRSlice.reducer;
