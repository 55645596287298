import React, { useEffect, useState } from "react";
import { ReactComponent as FilterMenuIcon } from "../../../app/assets/FilterMenuIcon.svg";
import { ReactComponent as ElectricityIcon } from "../../../app/assets/ElectricityIcon.svg";
import Header from "../../../components/common/Header";
import Main from "../../VisualizationFeature/components/Main"
import AnalyzeHeader from "../../../components/Analyze/AnalyzeHeader";


import { useDispatch, useSelector } from "react-redux";
import { calculateEnergyUsageChange, calculateRenewableEnergyUsageChange, currentYearLastYearEmissionDetail, getCurrentFinancialYear } from "../../VisualizationFeature/Redux/Processdata";
import Filter from "../../VisualizationFeature/components/Filter";
import { fetchChartElectricityDataThunk } from "../../VisualizationFeature/Redux/chartthunk";
import useApi from "../../../customHooks/useApi";

function AnalyzeScope2() {
  const api=useApi()
  const dispatch = useDispatch();
  const { transactionTypeOptions,financialYear, siteOptions, ElectricitySourceOptions, electricityData, dataForCurrentYearChange } = useSelector(state => state.chart);
  const [currentFinancialYear, setCurrentFinancialYear] = useState(financialYear.length ? financialYear[0] : '');
  const [emissionChange, setEmissonChange] = useState({});
  const [renewableEnergyChange, setrenewableEnergy] = useState({});
  const [EnergyChange, setEnergyChange] = useState({});
  const [StackBreakDown] = useState(["transaction_type", "electricity_source"]);
  const [chartDataArray] = useState([
    { title: "Emissions by Electricity Source", dataKey: "electricity_source" },
    { title: "Emissions by Transaction Type", dataKey: "transaction_type" },
    { title: "Emissions by Facility", dataKey: "site" },
    { title: "Energy Usage", dataKey: "source_type" },
  ]);
  const [BreakDownOptions] = useState([
    { value: "transaction_type", label: "Transaction Type" },
    { value: "site", label: "Facility" },
    { value: "electricity_source", label: "Electricity Source" },
  ]);
  const [filterBy, setFilterBy] = useState({
    site: '',
    fuel_type: '',
    use_type: ''
  });

  const handleFilterChange = (name, value) => {
    if(name==="financialYear"){
      setCurrentFinancialYear(value)
    }else{
      setFilterBy(prevFilterBy => ({
        ...prevFilterBy,
        [name]: value
      }));

    }
  };

  useEffect(() => {
  
   
    setEmissonChange(currentYearLastYearEmissionDetail(dataForCurrentYearChange, Number(currentFinancialYear)));
    setrenewableEnergy(calculateRenewableEnergyUsageChange(dataForCurrentYearChange, Number(currentFinancialYear)));
    setEnergyChange(calculateEnergyUsageChange(dataForCurrentYearChange, Number(currentFinancialYear)));
  }, [electricityData, dataForCurrentYearChange,currentFinancialYear]);
  const emissionData = [
    {
      title: "Emissions",
      info: (emissionChange?.currentYear || 0) + " Co2e",
      time: "",
      status: (emissionChange?.percentageChange || 0) > 0 ? "positive" : "negative",
      per: `${emissionChange?.percentageChange !== Infinity ? `${Math.abs(emissionChange?.percentageChange)||0}% YOY${emissionChange?.percentageChange > 0 ? "↑" : "↓"}` : ""}`, year:currentFinancialYear,
      theme: "red",
    },
    {
      title: "Energy Usage",
      info: EnergyChange?.currentYear + " kWh",
      time: "",
      status: (EnergyChange?.percentageChange || 0) > 0 ? "positive" : "negative",
      per: `${EnergyChange?.percentageChange !== Infinity ? `${Math.abs(EnergyChange?.percentageChange)}% YOY${EnergyChange?.percentageChange > 0 ? "↑" : "↓"}` : ""}`, year:currentFinancialYear,
      theme: "yellow",
    },
  ];

  const energyData = [
    {
      status: (renewableEnergyChange?.percentageChange || 0) > 0 ? "positive" : "negative",
      title: "Renewable Energy Use",
      info: `${renewableEnergyChange?.currentYearPercentage !== Infinity ? `${Math.abs(renewableEnergyChange?.currentYearPercentage)}%` : ""}`,
      percentage: `${renewableEnergyChange?.percentageChange !== Infinity ?
        `${Math.abs(renewableEnergyChange?.percentageChange)}% YOY${renewableEnergyChange?.percentageChange > 0 ? "↑" : "↓"}`
        : ""}`
    }
  ];
useEffect(()=>{
 dispatch(fetchChartElectricityDataThunk({api}))
},[])
useEffect(() => {
  if (financialYear.length > 0) {
    setCurrentFinancialYear(financialYear[0]);
  }
}, [financialYear]);
  return (
    <>
      <Header PageIcon={ElectricityIcon} pageTitle={"Scope 2"} />
      <div className="flex flex-col main-container w-full px-10 py-6">
        <AnalyzeHeader emissionData={emissionData} energyData={energyData} />

        {/* filter */}

        <div className="flex flex-col  rounded-md mt-[18px]  w-full border p-2">
          <div className="flex justify-between py-2 items-center  bg-gray-100 rounded">
            <span className="text-xl px-2"></span>
            <div className="flex justify-between items-center gap-2 px-2">
              <FilterMenuIcon className="cursor-pointer" />
              <Filter
                label="Financial Year"
                options={financialYear}
                selectedValue={currentFinancialYear}
                onChange={(value) => handleFilterChange('financialYear', value)}
              />
              <Filter
                label="Facility"
                options={siteOptions}
                selectedValue={filterBy.site}
                onChange={(value) => handleFilterChange('site', value)}
              />
              
              <Filter
                label="Electricity Source"
                options={ElectricitySourceOptions}
                selectedValue={filterBy.electricity_source}
                onChange={(value) => handleFilterChange('electricity_source', value)}
              />
           
              <Filter
                label="Transaction Type"
                options={transactionTypeOptions}
                selectedValue={filterBy.transaction_type}
                onChange={(value) => handleFilterChange('transaction_type', value)}
              />
            </div>
          </div>
          <div>
            <Main currentFinancialYear={isNaN(Number(currentFinancialYear))?financialYear[0]:currentFinancialYear} height={"500px"} width={"100%"} filterBy={filterBy} data={electricityData} chartDataArray={chartDataArray} Options={BreakDownOptions} StackBreakDown={StackBreakDown}/>
          </div>
        </div>
      </div>
    </>
  );
}

export default AnalyzeScope2;


