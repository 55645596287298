import { createSlice } from "@reduxjs/toolkit";

// Assume you have thunks for login and logout (similar to the previous example)

const scope3Category4Slice = createSlice({
  name: "Scope3Category4",
  initialState: {
    scope3Category4Records: [],
    scope3Category4ItemData: [],
    scope3Category4ItemDataFilter: [],
    scope3Category4Form: {},
    scope3Category4InputRecords: [],
    productionTypeData: [
      { id: 1, production_type: "Closed Loop" },
      { id: 2, production_type: "Re-Used" },
      { id: 3, production_type: "Primary Material Production" },
    ],
    energySourceData: [],
    logisticModeData: [],
    filters: null,
    sorting: null,
    vehicleTypeData: [],
    vehicleSpecificatioonData: [],
    scope3Category4RecordType: 1,
    filtredenergySourceData: [],
    filteredvehicleTypeData: [],
    filtredvehicleSpecificatioonData: [],

    itemsPerPage: 10,
    totalPages: 1,
    currentPage: 1,
    totalCount: 10,
    siteData: [],
    siteDataForFilter: [],
    countryOfOrigin: [
      { fullName: "Afghanistan", sortName: "AF" },
      { fullName: "Albania", sortName: "AL" },
      { fullName: "Algeria", sortName: "DZ" },
      { fullName: "Andorra", sortName: "AD" },
      { fullName: "Angola", sortName: "AO" },
      { fullName: "Antigua and Barbuda", sortName: "AG" },
      { fullName: "Argentina", sortName: "AR" },
      { fullName: "Armenia", sortName: "AM" },
      { fullName: "Australia", sortName: "AU" },
      { fullName: "Austria", sortName: "AT" },
      { fullName: "Azerbaijan", sortName: "AZ" },
      { fullName: "Bahamas", sortName: "BS" },
      { fullName: "Bahrain", sortName: "BH" },
      { fullName: "Bangladesh", sortName: "BD" },
      { fullName: "Barbados", sortName: "BB" },
      { fullName: "Belarus", sortName: "BY" },
      { fullName: "Belgium", sortName: "BE" },
      { fullName: "Belize", sortName: "BZ" },
      { fullName: "Benin", sortName: "BJ" },
      { fullName: "Bhutan", sortName: "BT" },
      { fullName: "Bolivia", sortName: "BO" },
      { fullName: "Bosnia and Herzegovina", sortName: "BA" },
      { fullName: "Botswana", sortName: "BW" },
      { fullName: "Brazil", sortName: "BR" },
      { fullName: "Brunei", sortName: "BN" },
      { fullName: "Bulgaria", sortName: "BG" },
      { fullName: "Burkina Faso", sortName: "BF" },
      { fullName: "Burundi", sortName: "BI" },
      { fullName: "Cabo Verde", sortName: "CV" },
      { fullName: "Cambodia", sortName: "KH" },
      { fullName: "Cameroon", sortName: "CM" },
      { fullName: "Canada", sortName: "CA" },
      { fullName: "Central African Republic", sortName: "CF" },
      { fullName: "Chad", sortName: "TD" },
      { fullName: "Chile", sortName: "CL" },
      { fullName: "China", sortName: "CN" },
      { fullName: "Colombia", sortName: "CO" },
      { fullName: "Comoros", sortName: "KM" },
      { fullName: "Congo (Brazzaville)", sortName: "CG" },
      { fullName: "Congo (Kinshasa)", sortName: "CD" },
      { fullName: "Costa Rica", sortName: "CR" },
      { fullName: "Croatia", sortName: "HR" },
      { fullName: "Cuba", sortName: "CU" },
      { fullName: "Cyprus", sortName: "CY" },
      { fullName: "Czech Republic", sortName: "CZ" },
      { fullName: "Denmark", sortName: "DK" },
      { fullName: "Djibouti", sortName: "DJ" },
      { fullName: "Dominica", sortName: "DM" },
      { fullName: "Dominican Republic", sortName: "DO" },
      { fullName: "Ecuador", sortName: "EC" },
      { fullName: "Egypt", sortName: "EG" },
      { fullName: "El Salvador", sortName: "SV" },
      { fullName: "Equatorial Guinea", sortName: "GQ" },
      { fullName: "Eritrea", sortName: "ER" },
      { fullName: "Estonia", sortName: "EE" },
      { fullName: "Eswatini", sortName: "SZ" },
      { fullName: "Ethiopia", sortName: "ET" },
      { fullName: "Fiji", sortName: "FJ" },
      { fullName: "Finland", sortName: "FI" },
      { fullName: "France", sortName: "FR" },
      { fullName: "Gabon", sortName: "GA" },
      { fullName: "Gambia", sortName: "GM" },
      { fullName: "Georgia", sortName: "GE" },
      { fullName: "Germany", sortName: "DE" },
      { fullName: "Ghana", sortName: "GH" },
      { fullName: "Greece", sortName: "GR" },
      { fullName: "Grenada", sortName: "GD" },
      { fullName: "Guatemala", sortName: "GT" },
      { fullName: "Guinea", sortName: "GN" },
      { fullName: "Guinea-Bissau", sortName: "GW" },
      { fullName: "Guyana", sortName: "GY" },
      { fullName: "Haiti", sortName: "HT" },
      { fullName: "Holy See", sortName: "VA" },
      { fullName: "Honduras", sortName: "HN" },
      { fullName: "Hungary", sortName: "HU" },
      { fullName: "Iceland", sortName: "IS" },
      { fullName: "India", sortName: "IN" },
      { fullName: "Indonesia", sortName: "ID" },
      { fullName: "Iran", sortName: "IR" },
      { fullName: "Iraq", sortName: "IQ" },
      { fullName: "Ireland", sortName: "IE" },
      { fullName: "Israel", sortName: "IL" },
      { fullName: "Italy", sortName: "IT" },
      { fullName: "Jamaica", sortName: "JM" },
      { fullName: "Japan", sortName: "JP" },
      { fullName: "Jordan", sortName: "JO" },
      { fullName: "Kazakhstan", sortName: "KZ" },
      { fullName: "Kenya", sortName: "KE" },
      { fullName: "Kiribati", sortName: "KI" },
      { fullName: "Korea, North", sortName: "KP" },
      { fullName: "Korea, South", sortName: "KR" },
      { fullName: "Kosovo", sortName: "XK" },
      { fullName: "Kuwait", sortName: "KW" },
      { fullName: "Kyrgyzstan", sortName: "KG" },
      { fullName: "Laos", sortName: "LA" },
      { fullName: "Latvia", sortName: "LV" },
      { fullName: "Lebanon", sortName: "LB" },
      { fullName: "Lesotho", sortName: "LS" },
      { fullName: "Liberia", sortName: "LR" },
      { fullName: "Libya", sortName: "LY" },
      { fullName: "Liechtenstein", sortName: "LI" },
      { fullName: "Lithuania", sortName: "LT" },
      { fullName: "Luxembourg", sortName: "LU" },
      { fullName: "Madagascar", sortName: "MG" },
      { fullName: "Malawi", sortName: "MW" },
      { fullName: "Malaysia", sortName: "MY" },
      { fullName: "Maldives", sortName: "MV" },
      { fullName: "Mali", sortName: "ML" },
      { fullName: "Malta", sortName: "MT" },
      { fullName: "Marshall Islands", sortName: "MH" },
      { fullName: "Mauritania", sortName: "MR" },
      { fullName: "Mauritius", sortName: "MU" },
      { fullName: "Mexico", sortName: "MX" },
      { fullName: "Micronesia", sortName: "FM" },
      { fullName: "Moldova", sortName: "MD" },
      { fullName: "Monaco", sortName: "MC" },
      { fullName: "Mongolia", sortName: "MN" },
      { fullName: "Montenegro", sortName: "ME" },
      { fullName: "Morocco", sortName: "MA" },
      { fullName: "Mozambique", sortName: "MZ" },
      { fullName: "Myanmar", sortName: "MM" },
      { fullName: "Namibia", sortName: "NA" },
      { fullName: "Nauru", sortName: "NR" },
      { fullName: "Nepal", sortName: "NP" },
      { fullName: "Netherlands", sortName: "NL" },
      { fullName: "New Zealand", sortName: "NZ" },
      { fullName: "Nicaragua", sortName: "NI" },
      { fullName: "Niger", sortName: "NE" },
      { fullName: "Nigeria", sortName: "NG" },
      { fullName: "North Macedonia", sortName: "MK" },
      { fullName: "Norway", sortName: "NO" },
      { fullName: "Oman", sortName: "OM" },
      { fullName: "Pakistan", sortName: "PK" },
      { fullName: "Palau", sortName: "PW" },
      { fullName: "Palestine", sortName: "PS" },
      { fullName: "Panama", sortName: "PA" },
      { fullName: "Papua New Guinea", sortName: "PG" },
      { fullName: "Paraguay", sortName: "PY" },
      { fullName: "Peru", sortName: "PE" },
      { fullName: "Philippines", sortName: "PH" },
      { fullName: "Poland", sortName: "PL" },
      { fullName: "Portugal", sortName: "PT" },
      { fullName: "Qatar", sortName: "QA" },
      { fullName: "Romania", sortName: "RO" },
      { fullName: "Russia", sortName: "RU" },
      { fullName: "Rwanda", sortName: "RW" },
      { fullName: "Saint Kitts and Nevis", sortName: "KN" },
      { fullName: "Saint Lucia", sortName: "LC" },
      { fullName: "Saint Vincent and the Grenadines", sortName: "VC" },
      { fullName: "Samoa", sortName: "WS" },
      { fullName: "San Marino", sortName: "SM" },
      { fullName: "Sao Tome and Principe", sortName: "ST" },
      { fullName: "Saudi Arabia", sortName: "SA" },
      { fullName: "Senegal", sortName: "SN" },
      { fullName: "Serbia", sortName: "RS" },
      { fullName: "Seychelles", sortName: "SC" },
      { fullName: "Sierra Leone", sortName: "SL" },
      { fullName: "Singapore", sortName: "SG" },
      { fullName: "Slovakia", sortName: "SK" },
      { fullName: "Slovenia", sortName: "SI" },
      { fullName: "Solomon Islands", sortName: "SB" },
      { fullName: "Somalia", sortName: "SO" },
      { fullName: "South Africa", sortName: "ZA" },
      { fullName: "South Sudan", sortName: "SS" },
      { fullName: "Spain", sortName: "ES" },
      { fullName: "Sri Lanka", sortName: "LK" },
      { fullName: "Sudan", sortName: "SD" },
      { fullName: "Suriname", sortName: "SR" },
      { fullName: "Sweden", sortName: "SE" },
      { fullName: "Switzerland", sortName: "CH" },
      { fullName: "Syria", sortName: "SY" },
      { fullName: "Taiwan", sortName: "TW" },
      { fullName: "Tajikistan", sortName: "TJ" },
      { fullName: "Tanzania", sortName: "TZ" },
      { fullName: "Thailand", sortName: "TH" },
      { fullName: "Timor-Leste", sortName: "TL" },
      { fullName: "Togo", sortName: "TG" },
      { fullName: "Tonga", sortName: "TO" },
      { fullName: "Trinidad and Tobago", sortName: "TT" },
      { fullName: "Tunisia", sortName: "TN" },
      { fullName: "Turkey", sortName: "TR" },
      { fullName: "Turkmenistan", sortName: "TM" },
      { fullName: "Tuvalu", sortName: "TV" },
      { fullName: "Uganda", sortName: "UG" },
      { fullName: "Ukraine", sortName: "UA" },
      { fullName: "United Arab Emirates", sortName: "AE" },
      { fullName: "United Kingdom", sortName: "GB" },
      { fullName: "United States", sortName: "US" },
      { fullName: "Uruguay", sortName: "UY" },
      { fullName: "Uzbekistan", sortName: "UZ" },
      { fullName: "Vanuatu", sortName: "VU" },
      { fullName: "Venezuela", sortName: "VE" },
      { fullName: "Vietnam", sortName: "VN" },
      { fullName: "Yemen", sortName: "YE" },
      { fullName: "Zambia", sortName: "ZM" },
      { fullName: "Zimbabwe", sortName: "ZW" },
    ],
    unitData: [],
    currencyData: [],
  },
  reducers: {
    setfiltredenergySourceData: (state, action) => {
      state.filtredenergySourceData = action.payload;
    },
    setfiltredvehicleTypeData: (state, action) => {
      state.filteredvehicleTypeData = action.payload;
    },
    setfiltredvehicleSpecificatioonData: (state, action) => {
      state.filtredvehicleSpecificatioonData = action.payload;
    },
    setscope3Category4Records: (state, action) => {
      state.scope3Category4Records = action.payload.data;
    },
    setscope3Category4InputRecords: (state, action) => {
      state.scope3Category4InputRecords = action.payload;
    },
    setscope3Category4Form: (state, action) => {
      return {
        ...state,
        scope3Category4Form: {
          ...state.scope3Category4Form,
          ...action.payload,
        },
      };
    },
    resetscope3Category4Form: (state, action) => {
      state.scope3Category4Form = {};
    },
    setEvidence: (state, action) => {
      state.scope3Category4Form.evidence = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setSiteDataForFilter: (state, action) => {
      state.siteDataForFilter = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    setlogisticModeData: (state, action) => {
      state.logisticModeData = action.payload;
    },
    setscope3Category4CountryData: (state, action) => {
      state.countryOfOrigin = action.payload;
    },
    setenergySourceData: (state, action) => {
      state.energySourceData = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setproductionTypeData: (state, action) => {
      state.productionTypeData = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload;
    },
    setSiteData: (state, action) => {
      state.siteData = action.payload;
    },
    setvehicleTypeData: (state, action) => {
      state.vehicleTypeData = action.payload;
    },
    setvehicleSpecificatioonData: (state, action) => {
      state.vehicleSpecificatioonData = action.payload;
    },
    setscope3Category4ItemData: (state, action) => {
      state.scope3Category4ItemData = action.payload;
    },
    setscope3Category4ItemDataFilter: (state, action) => {
      state.scope3Category4ItemDataFilter = action.payload;
    },

    setUnitData: (state, action) => {
      state.unitData = action.payload;
    },
    setCurrencyData: (state, action) => {
      state.currencyData = action.payload;
    },
    setdeletes3c4Data: (state, action) => {
      state.scope3Category4InputRecords =
        state.scope3Category4InputRecords.filter((i) => i.id != action.payload);
    },
  },
});

export const {
  resetscope3Category4Form,
  setdeletes3c4Data,
  setscope3Category4ItemData,
  setenergySourceData,
  setFilters,
  setEvidence,
  setscope3Category4Form,
  setvehicleSpecificatioonData,
  setvehicleTypeData,
  setlogisticModeData,
  setscope3Category4Records,
  setSorting,
  setCurrentPage,
  setItemsPerPage,
  setproductionTypeData,
  setTotalCount,
  setscope3Category4ItemDataFilter,
  setTotalPages,
  setCurrencyData,
  setfiltredenergySourceData,
  setfiltredvehicleSpecificatioonData,
  setfiltredvehicleTypeData,

  setSiteData,
  setUnitData,

  setscope3Category4InputRecords,

  setscope3Category4CountryData,
  setSiteDataForFilter,
} = scope3Category4Slice.actions;
export default scope3Category4Slice.reducer;
