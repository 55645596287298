import React, { useState } from 'react'
import { useSelector } from 'react-redux';

function SendEmailForm({handleFormChange,}) {
    let {scope3Category7Form} = useSelector(
        (state) => state.scope3Category7
      );
    const [receiverList, setReceiverList] = useState(
        scope3Category7Form?.receiver_list || ""
      );
      const [emailError, setEmailError] = useState("");
    
      const handleReceiverListChange = (e) => {
        const { value } = e.target;
        setReceiverList(value);
    
        const emails = value.split(",").map((email) => email.trim());
        const invalidEmails = emails.filter((email) => !validateEmail(email));
    
        if (invalidEmails.length > 0) {
          setEmailError(`Invalid emails: ${invalidEmails.join(", ")}`);
        } else {
          setEmailError("");
        }
    
        handleFormChange({
          target: {
            name: "receiver_list",
            value: emails,
          },
        });
      };
    
      const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
      };

      function getCurrentFinancialYear() {
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth() + 1; 
    
        let startYear, endYear;
    
        if (currentMonth >= 4) {
            startYear = currentYear;
            endYear = currentYear + 1;
        } else { 
            startYear = currentYear - 1;
            endYear = currentYear;
        }
    
        return `FY-${startYear}-${endYear.toString().slice(-2)}`;
    }
  return (
    <div className='h-[73vh]'>
          <form
           
              id="purchase-scope3Category7-form"
              className="flex flex-col w-full py-4 px-10"
            //   onSubmit={handlePurchaseFormSubmit}
            >
              <div className="col-span-1 flex flex-col">
                <label htmlFor="receiver_list" className="text-xs py-1.5">
                  Receiver list<span className="text-red-500">*</span>
                </label>
                <textarea
                  name="receiver_list"
                  id="receiver_list"
                  value={receiverList}
                  rows="5"
                  cols="33"
                  onChange={handleReceiverListChange}
                  className="appearance-none block w-full bg-white rounded text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight "
                  placeholder="Emails should be comma-separated"
                  required
                ></textarea>
                {emailError && (
                  <span className="text-red-500 text-xs">{emailError}</span>
                )}
                <span className="text-gray-500 text-xs mt-1">
                  Note: Emails should be comma-separated
                </span>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="period" className="text-xs py-1.5">
                  Period<span className="text-red-500">*</span>
                </label>
                
                <input
                disabled
                  type="text"
                  name="period"
                  id="period"
                  value={getCurrentFinancialYear()}
                 
                  className="appearance-none block w-full  rounded text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight "
                  required
                />
              </div>

              <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-end">
                <div className=" flex flex-col">
                  <button
                    type="submit"
                    disabled={emailError}
                    className={`py-2 px-6 text-white text-sm bg-green-600
                rounded-md`}
                  >
                    Send Form
                  </button>
                </div>
              </div>
            </form>
    </div>
  )
}

export default SendEmailForm
