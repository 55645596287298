import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../../customHooks/useApi';
import { fetchSocialHiringTurnoverInputs } from '../../../../features/social/socialThunk';
import { populateDataHiringAndTurnover } from '../../function/functionProcess';
import HiringAndTurnoverDisplay from '../../../BRSR/BRSRContent/DataView/HiringAndTurnover';

function HiringAndTurnOver() {
  const dispatch = useDispatch();
  const api = useApi();
  const { socialHiringTurnoverRecords, employementType } = useSelector(state => state.social);

  // Memoize the populated data
  const populatedData = useMemo(() => 
    populateDataHiringAndTurnover(socialHiringTurnoverRecords, employementType), 
    [socialHiringTurnoverRecords, employementType]
  );

  useEffect(() => {
    // dispatch(resetSocialFormData({}));
    dispatch(fetchSocialHiringTurnoverInputs({ api, params: {} }));
  }, []);
console.log({populatedData})
  return (
    <div className='overflow-auto h-full w-full p-2'>
      {Object.keys(populatedData).length > 0 ? (
        Object.keys(populatedData).map(year => (
          <HiringAndTurnoverDisplay key={year} data={ populatedData[year] } />
        ))
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
}

export default HiringAndTurnOver;