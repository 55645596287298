
import React from "react";
import Header from "../../components/common/Header";
import { ReactComponent as ElectricityIcon } from "../../app/assets/GeneralInfo.svg";
import { ReactComponent as CompanyIcon } from "../../app/assets/Company.svg";
import { ReactComponent as SiteIcon } from "../../app/assets/Site.svg";
// import { ReactComponent as Employee } from "../../app/assets/Employee.svg";
import { ReactComponent as Growthstock } from "../../app/assets/Growthstock.svg";
import { ReactComponent as Supply } from "../../app/assets/Supply.svg";
import Tabs from "./components/Tabs";
import SiteDetails from "./GeneralTabsContant/SiteDetails";
import PurchasedGoodsandServices from "./GeneralTabsContant/PurchasedGoodsandServices";
import RevenueInput from "./GeneralTabsContant/RevenueInput";
// import EmployeeInclusions from "./GeneralTabsContant/EmployeeInclusions";
import SubsidiaryCompanies from "./GeneralTabsContant/SubsidiaryCompanies";
import CapitalGoods from "./GeneralTabsContant/CapitalGoods";

const tabs = [
  { id: 1, name: "Site Details", icon: SiteIcon, component: SiteDetails },
  { id: 2, name: "Goods and Services", icon: Supply, component: PurchasedGoodsandServices },   //Purchased Goods and Services
  { id: 3, name: "Revenue input", icon: Growthstock, component: RevenueInput },
  // { id: 4, name: "Employee and inclusions", icon: Employee, component: EmployeeInclusions },
  { id: 6, name: "Capital Goods", icon: Growthstock, component: CapitalGoods },
  // { id: 5, name: "Subsidiary and Associated Companies", icon: CompanyIcon, component: SubsidiaryCompanies },
];

function GeneralMain() {
  return (
    <div className="h-screen bg-[#F4FBF8]">
 

      <Header PageIcon={ElectricityIcon} pageTitle={"General Info"} />
      <Tabs tabs={tabs} />
    
    </div>
  );
}

export default GeneralMain;

