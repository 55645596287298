import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useApi from '../../../../customHooks/useApi'
import EmployeeTypeTiles from './Tiles'
import { populateComplaintsData } from '../../function/functionProcess'

import { fetchSocialComplaintsInputs } from '../../../../features/social/socialThunk'
import GenericTiles from './GenericTile'


function ComplaintTracking() {
  const {socialComplaintsRecords,employementType}=useSelector(state=>state.social)
  const dispatch=useDispatch()
  
  const api =useApi()
  useEffect(()=>{
  //  dispatch(resetSocialFormData({}))
dispatch(fetchSocialComplaintsInputs({api,params:{}}))
  },[])
  return (
    <div className='overflow-auto h-full w-full'>
   <GenericTiles data={populateComplaintsData(socialComplaintsRecords,employementType)} title="Employee Overview"
  categoryHeaders={["Filed during the year", "Pending resolution at the end of year", "Remark"]}
  dataKey="Complaint Type " />
    </div>
  )
}

export default ComplaintTracking
