import React, { useState } from "react";

// import { disclosures } from "./DiscloseTablesControl";

import { CommentModal } from "../components/CommentModal";
import { Header } from "../components/Headers";
import { ReportFilters } from "../components/ReportFilters";
import { TableHeader } from "../components/TableHeaders";

import { DisclosureRow } from "../components/DisclosureRow";
import { useDispatch, useSelector } from "react-redux";
import { handleDisclosureTypeChange, handleStatusChange } from "../Redux/BRSRSlices";
// SVG Icons for expand and collapse


const BRSRReport = () => {
  const [expanded, setExpanded] = useState({});
  const [isCommentModalOpen, setCommentModalOpen] = useState(false);
  const [comment, setComment] = useState("");
const disclosures=useSelector(state=>state?.BRSR?.tableDisclosuresControlData)
const dispatch=useDispatch()
  const toggleExpand = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const openCommentModal = () => setCommentModalOpen(true);
  const closeCommentModal = () => setCommentModalOpen(false);
  function handleStatus(e, id, isParent = false,parentId,subDisclosure) {
    const value = e.target.value;
    dispatch(handleStatusChange({ id, value, isParent ,parentId,subDisclosure}));
  }
  
  function handleDisclosureType(e, id, isParent = false,parentId,subDisclosure) {
    const value = e.target.value;
    dispatch(handleDisclosureTypeChange({ id, value, isParent ,parentId,subDisclosure}));
  }
  return (
    <div className="bg-gray-50 w-full h-full">
      <Header title={'BUSINESS RESPONSIBILITY & SUSTAINABILITY REPORT'} description={'BRSR (Business Responsibility and Sustainability Report) is a SEBI mandated ESG Framework that provides a comprehensive disclosure of a company’s environmental, social, and governance (ESG) performanc.'}/>
      <ReportFilters />

      <div className="bg-white rounded-md shadow-md overflow-hidden">
        <table className="w-full  text-left border-collapse">
          <TableHeader />
          <tbody>
            {disclosures.map((disclosure) => (
              <DisclosureRow
                key={disclosure.id}
                disclosure={disclosure}
                expanded={expanded}
                toggleExpand={toggleExpand}
                openCommentModal={openCommentModal}
                handleDiscloserType={handleDisclosureType}
                handleStatus={handleStatus}
              />
            ))}
          </tbody>
        </table>
      </div>
      <CommentModal
        isOpen={isCommentModalOpen}
        comment={comment}
        setComment={setComment}
        closeModal={closeCommentModal}
      />
    </div>
  );
};








export default BRSRReport;
