import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Mail } from "../../app/assets/Mail.svg";
import { ReactComponent as User } from "../../app/assets/User.svg";
import { ReactComponent as Key } from "../../app/assets/Key.svg";
import { ReactComponent as Phone } from "../../app/assets/Phone.svg";
// import { ReactComponent as UserIcon } from "../../app/assets/User.svg";
import userImage from "../../app/assets/Amish.png";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../customHooks/useApi";
import { toast } from "react-toastify";
import { InputField } from "../settings/components/InputField";
import {
  resetuserProfileForm,
  setuserProfileForm,
} from "./Redux/UserProfileSlices";
import { fetchUserProfile, updateUserProfile } from "./Redux/UserProfileThunk";
import { NavLink, useNavigate } from "react-router-dom";
import ChangePassword from "./components/ChangePassword";
import { Uploadprofile } from "../../features/auth/authThunks";

function UserProfile() {
  const api = useApi();
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const { userProfileForm, userProfile } = useSelector(
    (state) => state.userProfile
  );
  const { profile_pic_url } = useSelector(
    (state) => state.userProfile.userProfileForm
  );
  const dispatch = useDispatch();

  const fileInputRef = useRef(null);

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    const res = await dispatch(Uploadprofile({ file, api }));
    console.log(res.payload.path);
    dispatch(
      setuserProfileForm({
        ...userProfileForm,
        profile_pic_url: res?.payload?.path,
      })
    );
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  function onChange(e) {
    const { name, value } = e.target;
    dispatch(setuserProfileForm({ ...userProfileForm, [name]: value }));
  }
  function handleSubmit() {
    // if (userProfileForm.newPassword !== userProfileForm.confirmNewPassword) {
    //   toast("Password Mismatch");
    //   return;
    // }
    const user_id = userProfileForm.user_id;

    dispatch(updateUserProfile({ id: user_id, api }));
  }
  function handleCancel() {
    dispatch(resetuserProfileForm());
    navigate("/dashboard");
  }

  useEffect(() => {
    dispatch(fetchUserProfile({ api }));
  }, []);
  // console.log(userProfileForm, userProfile);
  return (
    <div className="w-full h-full flex flex-col gap-5">
      <div className="p-5  w-full flex-1">
        <div className="flex justify-between w-full">
          <div className="flex justify-center items-center text-left space-x-2">
            <div className="w-24 h-24 object-cover">
              {userProfile?.profile_pic_url ? (
                <img
                  className="rounded-full flex flex-shrink-0"
                  src={userProfile?.profile_pic_url}
                  alt="user"
                />
              ) : (
                <img
                  className="rounded-full flex flex-shrink-0"
                  src={
                    profile_pic_url ||
                    "https://th.bing.com/th/id/OIP.iYpFSu2O2kVP1OptEdJ-uwHaHx?rs=1&pid=ImgDetMain"
                  }
                  alt="user"
                />
              )}
            </div>
            <div>
              <p className="text-[#384D6C] font-semibold">{`${
                userProfileForm?.first_name || userProfile?.first_name || "NA"
              }  ${
                userProfileForm?.last_name || userProfile?.last_name || ""
              }`}</p>
              <p className="text-[#6B7280]">
                {userProfileForm?.designation || userProfile?.designation || ""}
              </p>
              <p className="text-[#6B7280]">
                {userProfileForm?.name || userProfile?.name || ""}
              </p>
            </div>
          </div>
          <div className="p-2 px-4 flex space-x-4 items-center">
            <input
              name="profile_pic_url"
              type="file"
              accept="image/*"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleUpload}
            />
            <button
              onClick={handleButtonClick}
              className="text-white p-2 px-[2vw] flex flex-shrink-0  rounded-md bg-green-600"
            >
              Upload New Photo
            </button>
            {/* <button className="p-2 px-[5vw] flex flex-shrink-0 rounded-md border border-green-500 text-green-500 bg-white">
              Delete
            </button> */}
          </div>
        </div>
        <div className=" mt-4 grid grid-cols-1 md:grid-cols-2 gap-2">
          <InputField
            label={"First Name"}
            name={"first_name"}
            placeholder={"Enter your first name"}
            value={userProfileForm?.first_name}
            onChange={onChange}
            type="text"
            Decoration={""}
            decorationClassName="hidden"
          />
          <InputField
            label={"Last Name"}
            name={"last_name"}
            placeholder={"Enter your Last name"}
            value={userProfileForm?.last_name}
            onChange={onChange}
            type="text"
            Decoration={""}
            decorationClassName="hidden"
          />
          <InputField
            label={"Email Address"}
            name={"email"}
            placeholder={"Enter your email address"}
            value={userProfileForm?.email}
            onChange={onChange}
            type="email"
            Decoration={Mail}
            inputClassName="border-l-0 rounded-tl-none rounded-bl-none"
          />
          <InputField
            label={"Phone Number"}
            name={"mobile_number"}
            placeholder={"Enter your phone Number"}
            value={userProfileForm?.mobile_number}
            onChange={onChange}
            type="tel"
            Decoration={Phone}
            inputClassName="border-l-0 rounded-tl-none rounded-bl-none"
          />
          <InputField
            label={"Designation"}
            name={"designation"}
            placeholder={"Enter your designation"}
            value={userProfileForm?.designation}
            onChange={onChange}
            type="text"
            Decoration={User}
            inputClassName="border-l-0 rounded-tl-none rounded-bl-none"
          />
        </div>
        {/* <div className="mt-4">
          <button
            className="p-2 px-6 rounded-md bg-green-600 text-white"
            onClick={() => setModalOpen(true)}
          >
            ChangePassword ?
          </button>
        </div> */}
        <ChangePassword isModalOpen={isModalOpen} setModalOpen={setModalOpen} />
        {/* <div className="w-full grid grid-cols-2 gap-2 mt-4">
          <InputField
            label={"Current Password"}
            name={"currentPassword"}
            placeholder={"Enter Current Password"}
            value={userProfileForm?.currentPassword}
            onChange={onChange}
            type="password"
            Decoration={Key}
            inputClassName="border-l-0 rounded-tl-none rounded-bl-none"
          />
          <InputField
            label={"New Password"}
            name={"newPassword"}
            placeholder={"Enter New Password"}
            value={userProfileForm?.newPassword}
            onChange={onChange}
            type="password"
            Decoration={Key}
            inputClassName="border-l-0 rounded-tl-none rounded-bl-none"
          />
          <div className="col-span-2">
            <InputField
              label={"Confirm Password"}
              name={"ConfirmPassword"}
              placeholder={"Enter Confirm Password"}
              value={userProfileForm?.ConfirmPassword}
              onChange={onChange}
              type="password"
              Decoration={Key}
              inputClassName="border-l-0 rounded-tl-none rounded-bl-none"
            />
          </div>
        </div> */}
        <div className=" flex justify-end mt-4 space-x-4">
          <button
            className="p-2 px-6 rounded-md bg-green-600 text-white"
            onClick={() => setModalOpen(true)}
          >
            Update Password
          </button>
          <button
            onClick={handleSubmit}
            className="p-2 px-6 rounded-md bg-green-600 text-white"
          >
            Update Changes
          </button>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
