import { ReactComponent as CloseIcon } from "../../../app/assets/CloseIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ReactComponent as InfoIcon } from "../../../app/assets/InfoIcon.svg";
import {
  setSubtypeDataFiltred,
  setfilteredTypeData,
  setfiltredFuelTypeData,
  setscope3Category6Form,
} from "../../../features/scope3/category6/Scope3Category6Slice";
import { toast } from "react-toastify";
import useApi from "../../../customHooks/useApi";
import {
  postscope3Category6Data,
  scope3Category6UploadEvidence,
  updatescope3Category6Data,
} from "../../../features/scope3/category6/Scope3Category6Thunk";
import { removeDuplicates } from "../../../pages/inputScope3/function/removeDuplicates";

const Purchases3c4EditAndCopyForm = ({
  closePurchasePopupForm,
  selectedRowData,
  actionType,
  modeOfTravel,
}) => {
  const api = useApi();

  const {
    modeOfTravelMapping,

    expenseUnit,
    siteData,

    subType,
    subTypeFiltred,
    filteredTypeData,
    filtredFuelTypeData,
    scope3Category6Form,
  } = useSelector((state) => state.scope3Category6);
  const [radiativeForcing, setradiativeForcing] = useState([]);
  const dispatch = useDispatch();
  const [file, setfile] = useState(null);

  const loading = useSelector((state) => state.common.isLoading);

  const handleFormChange = (e) => {
    const { name, value, type } = e.target;
    if (name === "mode_of_travel" && modeOfTravelMapping) {
      const filtered = modeOfTravel?.find(
        (item) => item.mode_of_travel == value
      );
      dispatch(
        setscope3Category6Form({
          ...scope3Category6Form,
          [name]: value,
          // air_conditioned: filtered.air_conditioned,
        })
      );

      // console.log(filtered);
      dispatch(setfilteredTypeData(filtered.travel_type));
      dispatch(setSubtypeDataFiltred([]));
      dispatch(setfiltredFuelTypeData([]));
      setradiativeForcing([]);
    } else if (name === "travel_type" && modeOfTravelMapping) {
      dispatch(
        setscope3Category6Form({
          ...scope3Category6Form,
          [name]: value,
        })
      );

      const filteredSpecs = subType
        .filter((item) => {
          return Number(item.travel_type_id) === Number(value);
        })
        .map((i) => {
          return {
            ...i,
            id: i.travel_subtype_id,
          };
        });
      dispatch(setSubtypeDataFiltred(filteredSpecs));
      dispatch(setfiltredFuelTypeData([]));
      setradiativeForcing([]);
    } else if (type === "file") {
      const files = e.target.files;
      setfile(files);
    } else if (name === "mode_of_travel" && modeOfTravelMapping) {
      let selected = modeOfTravelMapping?.find(
        (item) => item.mode_of_travel === value
      );

      const filtered = modeOfTravel?.find(
        (item) => item.mode_of_travel === value
      );
      dispatch(
        setscope3Category6Form({
          ...scope3Category6Form,
          [name]: value,
          travel_type: "",
          travel_subtype: "",
          air_conditioned: "",
          fuel_type: "",
        })
      );
      dispatch(setfilteredTypeData(filtered.travel_type));
      dispatch(setSubtypeDataFiltred([]));
      dispatch(setfiltredFuelTypeData([]));
      setradiativeForcing([]);
    } else if (name === "travel_type" && modeOfTravelMapping) {
      dispatch(
        setscope3Category6Form({
          ...scope3Category6Form,
          [name]: value,
          travel_subtype: "",
          air_conditioned: "",
          fuel_type: "",
        })
      );
      const filtered = modeOfTravel
        ?.find(
          (item) => item.mode_of_travel === scope3Category6Form.mode_of_travel
        )
        .travel_subtype.filter(
          (item) => Number(item.travel_type_id) === Number(value)
        )
        .map((i) => {
          return {
            ...i,
            id: i.travel_subtype_id,
          };
        });

      dispatch(setSubtypeDataFiltred(filtered));
      dispatch(setfiltredFuelTypeData([]));
      setradiativeForcing([]);
    } else if (
      name === "travel_subtype" &&
      modeOfTravelMapping &&
      scope3Category6Form.mode_of_travel
    ) {
      dispatch(
        setscope3Category6Form({
          ...scope3Category6Form,
          [name]: value,
          air_conditioned: "",
          fuel_type: "",
        })
      );
      const filtered = removeDuplicates(
        modeOfTravelMapping
          ?.filter((item) => {
            if (
              Number(item.travel_subtype_id) === Number(value) &&
              item.mode_of_travel === scope3Category6Form.mode_of_travel &&
              Number(item.travel_type_id) ===
                Number(scope3Category6Form.travel_type)
            ) {
              return true;
            }
          })
          ?.map((i) => {
            return {
              fuel_type: i.fuel_type,
              id: i.fuel_type_id,
            };
          }),
        "id"
      );

      setradiativeForcing([]);
      dispatch(setfiltredFuelTypeData(filtered));
    } else if (name === "fuel_type") {
      dispatch(
        setscope3Category6Form({
          ...scope3Category6Form,
          [name]: value,
          air_conditioned: "",
        })
      );
      const filtredType = modeOfTravelMapping
        ?.filter((item) => {
          if (
            Number(item.travel_subtype_id) ===
              Number(scope3Category6Form.travel_subtype) &&
            item.mode_of_travel === scope3Category6Form.mode_of_travel &&
            Number(item.fuel_type_id) === Number(value)
          ) {
            return true;
          }
        })
        .map((item) => {
          return {
            air_conditioned: item.air_conditioned,
          };
        });

      let radiativeForceData = [
        ...new Set(filtredType.map((i) => i.air_conditioned)),
      ];

      setradiativeForcing(radiativeForceData);
    } else {
      dispatch(
        setscope3Category6Form({ ...scope3Category6Form, [name]: value })
      );
    }
  };
  const handlePurchaseFormSubmit = (e) => {
    e.preventDefault();
    if (!file) {
      toast.warning("Please Upload evidence");
    }
    const form = document.querySelector("#purchase-scope3Category6-form");

    if (form.length && form.checkValidity()) {
      switch (actionType) {
        case "edit":
          dispatch(scope3Category6UploadEvidence({files: file,api})).then((res) => {
            if (res?.payload?.msg === "File uploaded.") {
              dispatch(updatescope3Category6Data({api,id:selectedRowData.id}));
            }
          });
          break;
        case "copy":
          // console.log(file);
          dispatch(scope3Category6UploadEvidence({ files: file, api })).then(
            (res) => {
              if (res?.payload?.msg === "File uploaded.") {
                dispatch(postscope3Category6Data({ api }));
              }
            }
          );
          break;
        default:
          break;
      }
      closePurchasePopupForm();
    } else if (form.length) {
      form?.querySelector('input[type="submit"]').click();
    }
  };

 
  useEffect(() => {
    if (selectedRowData && modeOfTravel) {
      // Find the selected mode of travel
      const filtered = modeOfTravel.find(
        (item) => item.mode_of_travel === selectedRowData.mode_of_travel
      );

      // Filter travel subtype
      const filteredsubtype = filtered?.travel_subtype
        .filter((item) => item.id == selectedRowData.travel_subtype_id)
        .map((i) => ({
          id: i.id,
          travel_subtype: i.travel_subtype,
        }));

      // Filter fuel types
      const filteredsfueltype = filtered?.fuel_types
        ?.filter((item) => item.id == selectedRowData.fuel_type_id)
        .map((i) => ({
          id: i.id,
          fuel_type: i.fuel_type,
        }));

      // Prepare the updated data
      const updatedData = {
        date: selectedRowData?.date,
        mode_of_travel: selectedRowData?.mode_of_travel || "",
        source: selectedRowData?.source || "",
        destination: selectedRowData?.destination || "",
        air_conditioned: selectedRowData?.air_conditioned || "",
        distance: selectedRowData?.distance || "",
        expense: selectedRowData?.expense || "",
        number_of_employees: selectedRowData?.number_of_employees || "",
        travel_type:
          filtered?.travel_type?.find(
            (item) => item.travel_type === selectedRowData.travel_type
          )?.id || "",
        site_id:
          siteData?.find((item) => item.id === selectedRowData.site_id)?.id ||
          "",
        travel_subtype:
          filtered?.travel_subtype?.find(
            (item) => item.travel_subtype === selectedRowData.travel_subtype
          )?.id || "",
        fuel_type:
          filtered?.fuel_types?.find(
            (item) => item.fuel_type === selectedRowData.fuel_type
          )?.id || "",
        expense_unit:
          expenseUnit?.find(
            (item) => item.unit === selectedRowData.expense_unit
          )?.id || "",
        evidence: selectedRowData.evidence,
      };

      // Dispatch the actions
      dispatch(setfilteredTypeData(filtered?.travel_type || []));
      dispatch(setSubtypeDataFiltred(removeDuplicates(filteredsubtype || [])));
      dispatch(
        setfiltredFuelTypeData(removeDuplicates(filteredsfueltype || []))
      );
      setradiativeForcing([selectedRowData?.air_conditioned]);

      dispatch(setscope3Category6Form({ ...updatedData }));
    }
  }, [
    selectedRowData,
    actionType,
    modeOfTravel,
    modeOfTravelMapping,
    siteData,
    expenseUnit,
    dispatch,
  ]);

  return (
    <>
      <div className="mainContainer flex w-full h-screen overflow-hidden justify-center items-center z-[100] fixed bg-black bg-opacity-50 top-0 left-0 bottom-0">
        <div className="flex flex-col justify-center items-center w-9/12 text-xs bg-white absolute z-[1000] border border-transparent rounded-t-md">
          <div className="flex justify-between w-full bg-gray-50 py-2 px-4 border-b border-gray-300 mb-3">
            <span className="cursor-pointer">Business Travel</span>
            <span
              className="cursor-pointer"
              onClick={() => closePurchasePopupForm()}
            >
              <CloseIcon />
            </span>
          </div>
          <form
            style={{
              opacity: loading ? 0.5 : 1,
              pointerEvents: loading ? "none" : "auto",
            }}
            id="purchase-scope3Category6-form"
            className="flex flex-col w-full py-4"
            onSubmit={handlePurchaseFormSubmit}
          >
            <div className="grid grid-cols-4 gap-4 px-8 mb-4 justify-around">
              <div className="col-span-1 flex flex-col">
                <label htmlFor="date" className="text-xs py-1.5">
                  Date<span className="text-red-500">*</span>
                </label>
                <input
                  max={new Date().toISOString().split("T")[0]}
                  type="date"
                  name="date"
                  id="date"
                  value={scope3Category6Form?.date || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>{" "}
              <div className="col-span-1 flex flex-col">
                <label htmlFor="site_id" className="text-xs py-1.5">
                  Facility travelling from{" "}
                  <span className="text-red-500">*</span>
                </label>
                <select
                  name="site_id"
                  value={scope3Category6Form.site_id || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="">Choose the facility</option>
                  {siteData &&
                    siteData.map((site) => (
                      <option key={site.id} value={site.id}>
                        {site.site}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="mode_of_travel" className="text-xs py-1.5">
                  Mode of Travel<span className="text-red-500">*</span>
                </label>
                <select
                  name="mode_of_travel"
                  value={scope3Category6Form.mode_of_travel || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="">Choose the mode of travel</option>
                  {modeOfTravel &&
                    modeOfTravel.map((site) => (
                      <option
                        key={site.id + "_" + site.mode_of_travel}
                        value={site.mode_of_travel}
                      >
                        {site.mode_of_travel}
                      </option>
                    ))}
                </select>
              </div>
              <div className="flex flex-col">
                <label htmlFor="type" className="text-xs py-1.5">
                  Type<span className="text-red-500">*</span>
                </label>
                <select
                  name="travel_type"
                  value={scope3Category6Form.travel_type || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="">Choose the Type</option>
                  {filteredTypeData?.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.travel_type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col">
                <label htmlFor="travel_subtype" className="text-xs py-1.5">
                  Sub Type<span className="text-red-500">*</span>
                </label>
                <select
                  name="travel_subtype"
                  value={scope3Category6Form.travel_subtype || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="">Select Sub type</option>
                  {subTypeFiltred &&
                    subTypeFiltred.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.travel_subtype}
                        </option>
                      );
                    })}
                </select>
              </div>{" "}
              <div className="flex flex-col">
                <label htmlFor="fuel_type" className="text-xs py-1.5">
                  Fuel Type<span className="text-red-500">*</span>
                </label>
                <select
                  name="fuel_type"
                  value={scope3Category6Form.fuel_type || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="">Choose the Fuel type</option>
                  {filtredFuelTypeData?.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.fuel_type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="air_conditioned" className="text-xs py-1.5">
                  Radiative Forcing <span className="text-red-500">*</span>
                </label>
                <select
                  name="air_conditioned"
                  value={scope3Category6Form.air_conditioned || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="">Choose the Radiative Forcing</option>

                  {radiativeForcing &&
                    radiativeForcing.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                </select>
              </div>
              <div className="flex flex-col">
                <label htmlFor="number_of_employees" className="text-xs py-1.5">
                  Number of Passengers <span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  name="number_of_employees"
                  value={scope3Category6Form.number_of_employees || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="source" className="text-xs py-1.5">
                  Source<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="source"
                  value={scope3Category6Form.source || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="destination" className="text-xs py-1.5">
                  Destination<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="destination"
                  value={scope3Category6Form.destination || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="distance"
                  className="text-xs py-1.5 flex justify-between items-center px-2"
                >
                  <span>
                    Distance (km)<span className="text-red-500">*</span>
                  </span>{" "}
                  <a
                    className="flex justify-center gap-1 items-center font-thin text-xs"
                    target="_blank"
                    href="https://www.searates.com/services/distances-time"
                  >
                    <InfoIcon className="w-4 h-4 font-thin cursor-pointer" />
                    Distance calculator
                  </a>
                </label>
                <input
                  type="number"
                  name="distance"
                  step="0.0001"
                  value={scope3Category6Form.distance || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="expense" className="text-xs py-1.5">
                  Expense<span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  name="expense"
                  step="0.0001"
                  value={scope3Category6Form.expense || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="expense_unit" className="text-xs py-1.5">
                  Expense Unit<span className="text-red-500">*</span>
                </label>
                <select
                  name="expense_unit"
                  value={scope3Category6Form?.expense_unit || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                >
                  <option value="" disabled>
                    Choose the expense unit
                  </option>
                  {expenseUnit.map((expense_unit) => (
                    <option key={expense_unit.id} value={expense_unit.id}>
                      {expense_unit.unit}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="evidence" className="text-xs py-1.5">
                  Evidence<span className="text-red-500">*</span>
                </label>
                <input
                  type="file"
                  name="evidence"
                  id="evidence"
                  multiple
                  accept=".jpg, .jpeg, .png, .pdf, .zip"
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
            </div>
            <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
              <div className="w-1/6 flex flex-col">
                <button
                  type="button"
                  disabled={loading}
                  className="py-2 px-6 text-green-600 text-sm rounded-md"
                  onClick={closePurchasePopupForm}
                >
                  Cancel
                </button>
              </div>
              <div className="w-1/6 flex flex-col">
                <button
                  type="submit"
                  disabled={loading}
                  className={`py-2 px-6 text-white text-sm ${
                    loading ? "bg-green-500 cursor-wait" : "bg-green-600"
                  } rounded-md`}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Purchases3c4EditAndCopyForm;
