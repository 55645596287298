import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../appconfig";
import { jwtDecode } from "jwt-decode";
import { clearTokens } from "../features/auth/authSlice";
import { toast } from "react-toastify";
import { refreshTokens } from "../features/auth/authThunks";

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
  timeout: 60000, // 60 seconds
});

const useApi = () => {
  const dispatch = useDispatch();
  // Add a request interceptor to include headers
  api.interceptors.request.use(async (config) => {
    let token = localStorage.getItem("accessToken");
    let refresh_token = localStorage.getItem("refreshToken");
    try {
      if(!token || !refresh_token) {
        window.location.href = "/login";
        throw new Error("Missing token");
      }

      let decodedAccessToken = jwtDecode(token);
  
      let currentTimeInMs = (new Date()).getTime();
      let tokenExpiryTimeInMs = decodedAccessToken.exp * 1000;
  
      let tokenStatus = tokenExpiryTimeInMs <= currentTimeInMs ? -1 : ((tokenExpiryTimeInMs - currentTimeInMs) <= 60000 ? 1 : 0);
  
      if(tokenStatus === 1) {
        // remindme case

        // if(decodedAccessToken.remindMe) {
          let newToken = await dispatch(refreshTokens(refresh_token));
          token = newToken;
        // }
      } else if (tokenStatus === -1) {
        toast.error("Session expired. Redirecting to login page.", {
          theme: "dark",
        });
        dispatch(clearTokens());
        setTimeout(() => {
          window.location.href = "/login";
        }, 2000);
      }
    } catch(error) {
      console.error("Error decoding token: "+token+", reason: "+error.message);
      toast.error("Session expired. Redirecting to login page.", {
        theme: "dark",
      });
      dispatch(clearTokens());
      setTimeout(() => {
        window.location.href = "/login";
      }, 2000);
    }


    config.headers.Authorization = `Bearer ${token}`;

    return config;
  });

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (
        error.response &&
        error.response.status === 401
      ) {
        console.log("Error: ", error);

        if(error?.response?.data?.message) toast.error(error?.response?.data?.message || "Session Expired. Logging out", { theme: "dark" });
        dispatch(clearTokens());
        setTimeout(() => {
          window.location.href = "/login";
        }, 2000)
      } else {
        return Promise.reject(error);
      }
    }
  );

  return api;
};

export default useApi;
