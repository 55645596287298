// AccessDenied.js
import { ReactComponent as Scope3Icon } from "../app/assets/Company.svg";
import React from 'react';
import Header from "../components/common/Header";

const AccessDenied = () => {
  return (
    <div>
    <Header PageIcon={Scope3Icon} pageTitle={"Access Denied"} />
    <div className="flex flex-col items-center justify-center min-h-[87vh] bg-gray-100">

 
      <h1 className="text-4xl font-bold text-red-600">403 - Access Denied</h1>
      <p className="mt-4 text-lg text-gray-700">You do not have permission to view this page.</p>
      <div className="text-6xl">🔒</div>
    </div>
    </div>
  );
};

export default AccessDenied;
