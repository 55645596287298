import { ReactComponent as CloseIcon } from "../../../app/assets/CloseIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  postscope3Category2Data,
  scope3Category2UploadEvidence,
  updatescope3Category2Data,
} from "../../../features/scope3/category2/Scope3Category2Thunk";
import {
  setEvidence,
  setscope3Category2Form,
} from "../../../features/scope3/category2/Scope3Category2Slice";
import { toast } from "react-toastify";
import useApi from "../../../customHooks/useApi";

const Purchases3c2EditAndCopyForm = ({
  closePurchasePopupForm,
  selectedRowData,

  actionType,
}) => {
  const api = useApi();
  const siteOptions = useSelector((state) => state.scope3Category2.siteData);

  const currencyOptions = useSelector(
    (state) => state.scope3Category2.currencyData
  );

  const countryOfOrigin = useSelector(
    (state) => state.scope3Category2.countryOfOrigin
  );
  const [file, setfile] = useState(null);
  const [formData, setFormData] = useState({});
  let formValue = useSelector(
    (state) => state.scope3Category2.scope3Category2Form
  );
  const loading = useSelector((state) => state.common.isLoading);
  const dispatch = useDispatch();
  let scope3Category2ItemData = useSelector(
    (state) => state.scope3Category2.scope3Category2ItemData
  );
  const handleFormChange = (e) => {
    const { name, value, type } = e.target;
    if (name == "item_name") {
      let selected = scope3Category2ItemData.find((item) => item.id == value);
      dispatch(
        setscope3Category2Form({
          ...formValue,
          production_type: selected.production_type,
          item_description: selected.item_description,
          unit: selected.unit,
          item_code: selected.item_code,
          item_name: selected.id,
          supplier: selected.supplier,
          supplier_code: selected.supplier_code,
        })
      );
      setFormData({
        ...formData,
        production_type: selected.production_type,
        item_description: selected.item_description,
        unit: selected.unit,
        item_code: selected.item_code,
        item_name: selected.id,
        supplier: selected.supplier,
        supplier_code: selected.supplier_code,
      });
      // setFormData({ ...formData, item_name: value });
    } else if (type === "file") {
      const files = e.target.files;
      setfile(files);
    } else {
      setFormData({ ...formData, [name]: value });
      dispatch(setscope3Category2Form({ ...formData, [name]: value }));
    }
  };

  const handlePurchaseFormSubmit = (e) => {
    e.preventDefault();
    if (!file) {
      toast.warning("Please Upload evidence");
    }
    const form = document.querySelector("#purchase-Scope3Category2-form");
    if (form.length && form.checkValidity()) {
      switch (actionType) {
        case "copy":
          dispatch(scope3Category2UploadEvidence({ files: file, api })).then(
            (res) => {
              if (res?.payload?.msg === "File uploaded.") {
                dispatch(postscope3Category2Data({ api }));
              }
            }
          );
          break;
        case "edit":
          dispatch(scope3Category2UploadEvidence({files: file, api })).then((res) => {
            if (res?.payload?.msg === "File uploaded.") {
              dispatch(updatescope3Category2Data({ api,id:selectedRowData.id }));
            }
          });
          break;
        default:
          break;
      }
      closePurchasePopupForm();
    } else if (form.length) {
      form.querySelector('input[type="submit"]').click();
    }
  };

  useEffect(() => {
    if (selectedRowData) {
      const updatedData = {
        site: siteOptions.find((item) => item.site == selectedRowData?.site)
          ?.id,
        date_of_purchase: selectedRowData?.date_of_purchase,
        item_name: scope3Category2ItemData.find(
          (item) => item?.item_name == selectedRowData?.item_name
        )?.id,
        item_description: selectedRowData?.item_description,
        item_code: selectedRowData?.item_code,
        supplier: selectedRowData?.supplier,
        supplier_code: selectedRowData?.supplier_code,

        country: selectedRowData?.country,
        item_quantity: selectedRowData?.item_quantity,

        amount_paid: selectedRowData?.amount_paid,
        currency: currencyOptions.find(
          (item) => selectedRowData?.currency === item.currency
        )?.id,
        evidence: selectedRowData?.evidence,
      };
      setFormData(updatedData);
      dispatch(setscope3Category2Form({ ...updatedData }));
    }
  }, [selectedRowData, actionType, dispatch]);

  // console.log(formData)
  return (
    <>
      <div className="mainContainer flex w-full h-screen overflow-hidden justify-center items-center z-[100] fixed bg-black bg-opacity-50 top-0 left-0 bottom-0">
        <div className="flex flex-col justify-center items-center w-9/12 text-xs bg-white absolute z-[1000] border border-transparent rounded-t-md">
          <div className="flex justify-between w-full bg-gray-50 py-2 px-4 border-b border-gray-300 mb-3">
            <span className="cursor-pointer">Capital Goods</span>
            <span
              className="cursor-pointer"
              onClick={() => closePurchasePopupForm()}
            >
              <CloseIcon />
            </span>
          </div>
          <form
            id="purchase-Scope3Category2-form"
            className="flex flex-col w-full py-4"
            onSubmit={handlePurchaseFormSubmit}
          >
            <div className="grid grid-cols-4 gap-4 px-8 mb-4 justify-around">
              <div className="col-span-1 flex flex-col">
                <label htmlFor="date_of_purchase" className="text-xs py-1.5">
                  Date of purchase<span className="text-red-500">*</span>
                </label>
                <input
                  max={new Date().toISOString().split("T")[0]}
                  type="date"
                  name="date_of_purchase"
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  value={formData.date_of_purchase || ""}
                  required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="site" className="text-xs py-1.5">
                  Facility<span className="text-red-500">*</span>
                </label>
                <select
                  name="site"
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                  value={formData.site || ""}
                >
                  <option value="">Choose the facility</option>
                  {siteOptions &&
                    siteOptions.map((site) => (
                      <option key={site.id} value={site.id}>
                        {site.site}
                      </option>
                    ))}
                </select>
              </div>

              <div className="col-span-1 flex flex-col">
                <label htmlFor="item_name" className="text-xs py-1.5">
                  Item name<span className="text-red-500">*</span>
                </label>
                <select
                  name="item_name"
                  value={formData.item_name}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="" disabled>
                    Choose the Item Name
                  </option>
                  {scope3Category2ItemData?.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.item_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="item_description" className="text-xs py-1.5">
                  Item description<span className="text-red-500">*</span>
                </label>
                <textarea
                  name="item_description"
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  value={formData.item_description || ""}
                  required
                ></textarea>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="item_code" className="text-xs py-1.5">
                  Item code
                </label>
                <input
                  type="text"
                  name="item_code"
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  value={formData.item_code || ""}
                  // required
                />
              </div>

              <div className="col-span-1 flex flex-col">
                <label htmlFor="supplier" className="text-xs py-1.5">
                  Supplier name
                </label>
                <input
                  type="text"
                  name="supplier"
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  value={formData.supplier || ""}
                  // required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="supplier_code" className="text-xs py-1.5">
                  Supplier code
                </label>
                <input
                  type="text"
                  name="supplier_code"
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  value={formData.supplier_code || ""}
                  // required
                />
              </div>

              <div className="col-span-1 flex flex-col">
                <label htmlFor="country" className="text-xs py-1.5">
                  Country of origin
                </label>
                <select
                  name="country"
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                  value={formData.country || ""}
                >
                  <option value="">Choose the country of origin</option>
                  {countryOfOrigin?.map((type) => (
                    <option key={type.sortName} value={type.fullName}>
                      {type.fullName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="item_quantity" className="text-xs py-1.5">
                  Item quantity<span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  step={"0.0001"}
                  name="item_quantity"
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  value={formData.item_quantity || ""}
                  required
                />
              </div>

              <div className="col-span-1 flex flex-col">
                <label htmlFor="amount_paid" className="text-xs py-1.5">
                  Amount paid
                </label>
                <input
                  
                  step={"0.0001"}
                  name="amount_paid"
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  value={formData.amount_paid || ""}
                  // required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="currency" className="text-xs py-1.5">
                  Currency
                </label>
                <select
                  disabled={!formValue?.amount_paid}
                  name="currency"
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required={formData.amount_paid}
                  value={formData.currency || ""}
                >
                  <option value="">Choose the currency</option>
                  {currencyOptions.map((currency) => (
                    <option key={currency.id} value={currency.id}>
                      {currency.currency}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="evidence" className="text-xs py-1.5">
                  Evidence
                </label>
                <input
                  type="file"
                  multiple
                  name="evidence"
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
            </div>
            <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
              <div className="w-1/6 flex flex-col">
                <button
                  type="cancel"
                  className="py-2 px-6 text-green-600 text-sm rounded-md"
                  onClick={() => closePurchasePopupForm()}
                  disabled={loading}
                >
                  Cancel
                </button>
              </div>
              <div className="w-1/6 flex flex-col">
                <button
                  type="submit"
                  className={`py-2 px-6 text-white text-sm ${
                    loading ? "bg-green-400" : "bg-green-600"
                  } rounded-md`}
                  disabled={loading}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Purchases3c2EditAndCopyForm;
