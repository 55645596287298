import React, { useEffect, useRef } from 'react';
import ReactEcharts from 'echarts-for-react';

export const SunburstChart = ({ option }) => {
  const chartRef = useRef(null);
// console.log(option)
  // useEffect(() => {
  //   const chartInstance = chartRef.current.getEchartsInstance();

  //   if (chartInstance) {
  //     chartInstance.setOption(option);

  //     // Clean up function to dispose the chart when the component unmounts
  //     return () => {
  //       chartInstance.dispose();
  //     };
  //   }
  // }, [option]);

  return (option&&<ReactEcharts  option={option} style={{ height: '600px', width: '100%' }}  notMerge={true}
  lazyUpdate={true} />);
};
