import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// Main component
export const DataRepresentation = () => {
  // Fetch the permissions data from Redux state
  const data = useSelector(
    (state) => state.setting.adminDashboardForm?.permissions
  );
  const categories = ["data_entry", "visualization", "reporting"];
  const [categoryStatus, setCategoryStatus] = useState({});

  // Effect to initialize categoryStatus once data is available
  useEffect(() => {
    if (data) {
      const initialState = {};
      categories.forEach((category) => {
        if (data[category]) {
          initialState[category] = data[category]?.is_enabled || false;
        }
      });
      setCategoryStatus(initialState);
    }
  }, [data]); // Effect will run when `data` or `categories` change

  // Handle checkbox toggle
  const handleCheckboxChange = (category) => {
    console.log(category);
    setCategoryStatus((prevState) => ({
      ...prevState,
      [category]: !prevState[category], // Toggle the status
    }));
  };

  // If data is not available yet, show a loading state or null
  if (!data || typeof data !== "object" || Object.keys(data).length === 0) {
    return <div>No data</div>;
  }
  return (
    <div className="p-6 bg-gray-100 rounded-lg overflow-auto h-[350px]">
      {/* Header for the table */}
      <div className="grid grid-cols-2 gap-4 mb-4 border-b border-gray-300 pb-2">
        <h2 className="text-xl font-semibold text-gray-800">Functionality</h2>
        <h2 className="text-xl font-semibold text-gray-800">View only</h2>
      </div>

      {/* Category Data Rows */}
      {categories.map((category) => {
        // Skip if category does not exist in data
        const categoryData = data[category];
        if (!categoryData) return null;

        return (
          <div
            key={category}
            className="grid grid-cols-2 gap-4 py-2 border-b border-gray-200"
          >
            {/* Functionality Column */}
            <div className="flex items-center space-x-4">
              {/* Custom Circular Checkbox */}
              <div className="relative">
                <input
                  type="checkbox"
                  // disabled
                  readOnly
                  checked={categoryStatus[category]} // Controlled by state
                  // onChange={() => handleCheckboxChange(category)} // Update state on change
                  className="w-4 h-4"
                  // className="absolute w-0 h-0 opacity-0 peer"
                />
                {/* <div
                  className={`w-5 h-5 rounded-full border-2 border-gray-300 peer-checked:bg-green-600 peer-checked:border-green-600 ${
                    categoryStatus[category] ? "bg-green-600 border-green-600" : ""
                  }`}
                ></div> */}
              </div>
              <h3 className="text-lg font-medium text-gray-700 capitalize">
                {category.replace("_", " ") === "visualization"
                  ? "Analyze"
                  : category.replace("_", " ") === "reporting"
                  ? "Report"
                  : category.replace("_", " ")}
              </h3>
            </div>

            {/* Read Only Column */}
            <div className="flex items-center">
              <span
                className={
                  categoryData?.read_only
                    ? "text-yellow-600 text-lg"
                    : "text-blue-600 text-lg"
                }
              >
                {categoryData?.read_only ? "Yes" : "No"}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DataRepresentation;
