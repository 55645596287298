import React, { useEffect, useState } from "react";
import ConfirmPopup from "./ConfirmPopUp";
import MultiSelectComponent from "./MultiselectComponent";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  setadminDashboardForm,
  setfiltredModules,
  setfiltredSubModules,
} from "../../Redux/SettingSlices";

const TabContentForMainModules = ({
  activeTab,
  roleTypeId,
  selectedEnergy,
  setSelectedEnergy,
  selectedScope3,
  setSelectedScope3,

  onSave,
  handleSubmit,
}) => {
  const { module, subModule, filtredModules,filtredSubModules } = useSelector(
    (state) => state.setting
  );
  const [isUpdated, setIsUpdated] = useState(true);
  const [scope3Options, setScope3Options] = useState(subModule || []);
  const dispatch = useDispatch();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const normalizedRoleTypeId = String(roleTypeId);

  const shouldShowPopup = () => {
    return (
      (activeTab === "roles" && normalizedRoleTypeId === "2") ||
      (activeTab === "dataIn" && normalizedRoleTypeId === "3") ||
      (activeTab === "visualization" &&
        (normalizedRoleTypeId === "5" || normalizedRoleTypeId === "6"))
    );
  };

  const handleEnergyChange = (selected) => {
    setIsUpdated((prev) => !prev);
    setSelectedEnergy(selected);
    dispatch(setfiltredModules(selected));

    // const data = {
    //   modules: selected.map((i) => i.value),
    // };

    // dispatch(
    //   setadminDashboardForm({
    //     permissions: {
    //       ...adminDashboardForm.permissions,
    //       ...data,
    //       management: { ...adminDashboardForm.permissions.management, ...data },
    //       visualization: {
    //         ...adminDashboardForm.permissions.visualization,
    //         ...data,
    //       },
    //       data_entry: { ...adminDashboardForm.permissions.data_entry, ...data },
    //     },
    //   })
    // );
  };

  const handleScope3Change = (selected) => {
    setIsUpdated((prev) => !prev);
    setSelectedScope3(selected);

    dispatch(setfiltredSubModules(selectedScope3));
    // const data = {
    //   submodules: selectedScope3.map((i) => i.value),
    // };

    // dispatch(
    //   setadminDashboardForm({
    //     permissions: {
    //       ...adminDashboardForm.permissions,
    //       ...data,
    //       management: { ...adminDashboardForm.permissions.management, ...data },
    //       visualization: {
    //         ...adminDashboardForm.permissions.visualization,
    //         ...data,
    //       },
    //       data_entry: { ...adminDashboardForm.permissions.data_entry, ...data },
    //     },
    //   })
    // );
  };

  const handleSave = () => {
    if (shouldShowPopup()) {
      setIsPopupOpen(true);
    } else {
      handleSubmit();
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleConfirmPopup = () => {
    const selectedEnergyValues =
      selectedEnergy.length === module.length
        ? [-1]
        : selectedEnergy.map((i) => i.value);

    const selectedScope3Values =
      selectedScope3.length === subModule.length
        ? [-1]
        : selectedScope3.map((i) => i.value);

    const permissionsData = {
      modules: selectedEnergyValues,
      submodules: selectedScope3Values,
    };

    if (
      selectedEnergyValues.length === 0 ||
      selectedScope3Values.length === 0
    ) {
      toast.error("Please select all required fields", { theme: "dark" });
    } else {
      onSave(permissionsData);
    }
    setIsPopupOpen(false);
  };

  useEffect(() => {
    const filteredScope3 = selectedScope3.filter((i) =>
      selectedEnergy.some((energy) => energy.value === i.module)
    );
    const filteredScope3Options = subModule.filter((i) =>
      selectedEnergy.some((energy) => energy.value === i.module)
    );

    setSelectedScope3(filteredScope3);
    setScope3Options(filteredScope3Options);
  }, [isUpdated, selectedEnergy, subModule]);
console.log({filtredModules,filtredSubModules})
  return (
    <div className="flex flex-col justify-between w-full h-full ">
      <div className="mb-2">
        <label className="block mb-2 font-semibold">Module</label>
        <MultiSelectComponent
          options={module}
          selected={selectedEnergy}
          onChange={handleEnergyChange}
          required
        />
      </div>
      <div className="mb-2">
        <label className="block mb-2 font-semibold">Sub Module</label>
        <MultiSelectComponent
          options={scope3Options}
          selected={selectedScope3}
          onChange={handleScope3Change}
          required
        />
      </div>

      <div className="mb-0 w-full flex justify-center flex-1 items-center mt-5 absolute bottom-5">
        <button
          onClick={handleSave}
          className="w-48 bg-green-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-opacity-50"
        >
          {shouldShowPopup() ? "Save changes" : "Next"}
        </button>
      </div>

      {isPopupOpen && (
        <ConfirmPopup
          mainHeading="Are you sure?"
          subHeading="Do you want to save the changes?"
          buttonText1="Cancel"
          buttonText2="Save changes"
          onClose={handleClosePopup}
          onConfirm={handleConfirmPopup}
        />
      )}
    </div>
  );
};

export default TabContentForMainModules;
