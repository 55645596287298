import React from 'react';

const ConfirmPopup = ({ mainHeading, subHeading, buttonText1, buttonText2, onClose, onConfirm }) => {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto flex justify-center items-center">
    <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
      <div className="bg-white  overflow-hidden shadow rounded-3xl max-w-[75%]  flex justify-center items-center p-11 z-50">
        <div className="p-6 w-full text-center flex flex-col space-y-5">
          <h1 className="text-3xl font-bold mb-2">{mainHeading}</h1>
          {/* <p className="text-gray-600 mb-4">Are you sure you want to <strong>{subHeading} ?</strong></p> */}
          <div className="flex justify-between">
            <button
              onClick={onClose}
              className="px-6 py-2 text-red-500 border border-red-500 rounded-lg mr-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
            >
               {buttonText1}
            </button>
            <button
              onClick={onConfirm}
              className="px-6 py-2 bg-green-500 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400"
            >
              {buttonText2}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
