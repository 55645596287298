/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { ReactComponent as Scope3Icon } from "../../app/assets/Capitalventure.svg";
import { ReactComponent as CopyIcon } from "../../app/assets/CopyIcon.svg";
import { ReactComponent as PencilIcon } from "../../app/assets/PencilIcon.svg";
import { ReactComponent as DeleteIcon } from "../../app/assets/DeleteIcon.svg";
import { ReactComponent as AddIconBig } from "../../app/assets/AddIconBig.svg";
import { ReactComponent as DownloadIcon } from "../../app/assets/DownloadIcon.svg";
import { ReactComponent as UserIcon } from "../../app/assets/UserIcon.svg";

import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import {
  resetscope3Category2Form,
  setCurrentPage,
  setFilters,
  setItemsPerPage,
  setscope3Category2Form,
} from "../../features/scope3/category2/Scope3Category2Slice";
import Header from "../../components/common/Header";
import TableMeta from "../../components/common/Table/TableMeta";
import Table from "../../components/common/Table/Table";
import TablePagination from "../../components/common/Table/TablePagination";
import Purchases3c2Form from "../../components/scope3/category2/Purchases3c2Form";
import Purchases3c2EditAndCopyForm from "../../components/scope3/category2/Purchases3c2EditAndCopyForm";
import {
  deletescope3Category2Data,
  fetchCurrencyData,
  fetchSiteData,
  fetchUnitData,
  fetchscope3Category2InputData,
  fetchscope3Category2ItemData,
 
} from "../../features/scope3/category2/Scope3Category2Thunk";
import useApi from "../../customHooks/useApi";
import FilterComponent from "../../components/common/FilterComponent";
import { useSearchParams } from "react-router-dom";

const Scope3Category2 = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const api=useApi()
  const dispatch = useDispatch();
  const scope3Category2InputRecords = useSelector(
    (state) => state.scope3Category2.scope3Category2InputRecords
  );
const [files,setfiles]=useState(null)
  const {itemsPerPage, scope3Category2Form,
    totalPages,
read_only,
totalCount,
currentPage,scope3Category2ItemData,siteDataForFilter,countryOfOrigin,scope3Category2ItemDataFilter
  } = useSelector(
    (state) => state?.scope3Category2
  );
  const [dataRows, setDataRows] = useState([]);
  const [isPurchaseFormOpen, setPurchaseFormOpen] = useState(false);
  const [manualEntry, setManualEntry] = useState(true);
  const [apiIntegration, setApiIntegration] = useState(false);
  const [trendLine, setTrendLine] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [actionType, setActionType] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isEditCopyFormOpen, setIsEditCopyFormOpen] = useState(false);
  const [itemselected, setItemSelected] = useState({});
  const headingsToDataKeyMap = {
    " ": "",
    FACILITY: "site",
    "DATE OF PURCHASE": "date_of_purchase",
    "ITEM NAME": "item_name",
    "ITEM DESCRIPTION": "item_description",
    "ITEM CODE": "item_code",
    "SUPPLIER NAME": "supplier",
    "SUPPLIER CODE": "supplier_code",
    // "PRODUCTION TYPE": "production_type",
    "COUNTRY OF ORIGIN": "country",
    "ITEM QUANTITY": "item_quantity",
    // "QUANTITY UNIT": "unit",
    "AMOUNT PAID": "amount_paid",
    CURRENCY: "currency",
    EVIDENCE: "evidence",
    STATUS: "status",
    // COMMENT: "comment",
    // EDIT: "edit",
    // DELETE: "",
  };
  const filters = [
    // {
    //   name: "date_of_purchase",
    //   type: "date",
    //   label: "Date of purchase",
    //   options:[]
    // },
    {
      name: "site",
      label: "Facility",
      options:
        siteDataForFilter &&
        siteDataForFilter.map((site) => {
          return {
            value: site.id,
            label: site.site,
          };
        }),
    },
    {
      name: "item_name",
      label: "Item name",
      options:
        scope3Category2ItemDataFilter &&
        scope3Category2ItemDataFilter.map((item) => {
          return {
            value: item.id,
            label: item.item_name,
          };
        }),
    },
    {
      name: "item_supplier",
      label: "Item supplier",
      options:
        scope3Category2ItemDataFilter &&
        scope3Category2ItemDataFilter.map((item) => {
          return {
            value: item.supplier,
            label: item.supplier,
          };
        }),
    },
    {
      name: "country",
      label: "Country of origin",
      options:
        countryOfOrigin &&
        countryOfOrigin.map((item) => {
          return {
            value: item.fullName,
            label: item.fullName,
          };
        }),
    },
  ];
  const handleFilterChange = (newFilters) => {
    // fetchData(newFilters);
    dispatch(fetchscope3Category2InputData({ api,params:newFilters }));
    const updatedParams = new URLSearchParams(newFilters).toString();
    dispatch(setFilters(updatedParams))
    setSearchParams(updatedParams);
    // console.log(newFilters);
  };
  if(!read_only){
    headingsToDataKeyMap.EDIT="edit"
    headingsToDataKeyMap.DELETE="delete"
  }
  const handleEdit = (row) => {
    dispatch(resetscope3Category2Form());
    setActionType("edit");
    setSelectedRowData(row);
    setIsEditCopyFormOpen(true);
  };
  const handleDelete = (row) => {
    if(row.id){

      dispatch(deletescope3Category2Data({api,id:row.id}))
    }else{
      toast.error("Failed please try again")
    }
  };

  const handleCopy = (row) => {
    dispatch(resetscope3Category2Form());
    setActionType("copy");
    setSelectedRowData(row);
    setIsEditCopyFormOpen(true);
  };

  const handleCloseEditCopyForms = () => {
    setIsEditCopyFormOpen(false);
  };

  const switchTab = (tabId) => {
    switch (tabId) {
      case 1:
        setManualEntry(true);
        setApiIntegration(false);
        setTrendLine(false);
        break;
      case 2:
        setManualEntry(false);
        setApiIntegration(true);
        setTrendLine(false);
        break;
      case 3:
        setManualEntry(false);
        setApiIntegration(false);
        setTrendLine(true);
        break;
      default:
        setManualEntry(true);
        setApiIntegration(false);
        setTrendLine(false);
        break;
    }
    setSelectedTab(tabId);
  };

  const openPopupForm = () => {
    dispatch(resetscope3Category2Form());
    setPurchaseFormOpen(true);
  };

  const closePurchasePopupForm = () => {
    setPurchaseFormOpen(false);
  };

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const handleItemsPerPage = (itemCount) => {
    dispatch(setItemsPerPage(itemCount));
  };

  const handleFileDownload = (filePath) => {
    if (filePath) {
      const link = document.createElement("a");
      link.href = filePath;
      link.download = "filename.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast("No evidence attached");
    }
  };

  const generateCellHtml = (row, k) => {
    let cellHtml = null;
    switch (k) {
      case "Scope3_type":
        cellHtml = (
          <span className="w-full flex justify-center">
            <span
              className={`px-2 py-1 border border-transparent rounded-md ${
                row["source_type"].toString().toLowerCase() ===
                "Renewable".toLowerCase()
                  ? "bg-green-200 text-green-600"
                  : "bg-red-200 text-red-600"
              }`}
            >
              {row[k]}
            </span>
          </span>
        );
        break;
        case "edit":
        cellHtml = (
          <span className="w-full flex justify-center">
            <PencilIcon
              className="cursor-pointer"
              onClick={() => handleEdit(row)}
            />
          </span>
        );
        break;
        case "delete":
          cellHtml = (
            <span className="w-full flex justify-center">
            <DeleteIcon
              className="cursor-pointer"
              onClick={() => handleDelete(row)}
            />
          </span>
          );
          break;
      case "status":
        cellHtml = (
          <span className="w-full flex justify-center">
            <span
              className={`px-2 py-1 border border-transparent rounded-md ${
                row[k]=== "Accepted"
                  ? "bg-green-200 text-green-600"
                  : row[k] ===
                    "Submitted"
                  ? "bg-blue-200 text-blue-600"
                  : "bg-red-200 text-red-600"
              }`}
            >
              {row[k]}
            </span>
          </span>
        );
        break;
      case "evidence":
        cellHtml = (
          <span className="w-full flex justify-center">
            <DownloadIcon
              className="cursor-pointer fill-green-500"
              onClick={() => handleFileDownload(row[k])}
            />
          </span>
        );
        break;
      case "submitted_by":
      case "approved_by":
        cellHtml = (
          <span className="w-full flex justify-center">
            <img
              src={`${UserIcon}`}
              className="cursor-pointer w-[32px] h-[32px] border border-transparent rounded-[50%]"
              alt={`${k}`}
            />
          </span>
        );
        break;
      default:
        cellHtml = <span className="w-full flex justify-center">{row[k]}</span>;
        break;
    }
    return cellHtml;
  };

  
  const handleFormChange = async (e) => {
    const { name, type, value } = e.target;
    if (name === "item_name" && scope3Category2ItemData) {
      let selected = scope3Category2ItemData?.find((item) => item.id == value);
      setItemSelected(selected, scope3Category2ItemData);
      dispatch(
        setscope3Category2Form({
          ...scope3Category2Form,
          item_name: selected.id,
          production_type: selected.production_type,
          item_description: selected.item_description,
          unit: selected.unit,
          item_code: selected.item_code,
          supplier: selected.supplier,
          supplier_code: selected.supplier_code,
        })
      );
    } else if (type === "file") {
      const files = e.target.files;
      if (!files) {
        toast("Please select a file");
        return;
      }
     setfiles(files)
    } else {
      dispatch(setscope3Category2Form({...scope3Category2Form, [name]: value }));
    }
  };

  // const handleScope3TypeChange = (recordType) => {
  //   dispatch(set1(recordType));
  // };

  useEffect(() => {
    let transformedDataRows = scope3Category2InputRecords?.map((row) => {
      let transformedDataRow = [];
      transformedDataRow.push(
        <span className="w-full flex justify-center">
        <CopyIcon
          className={`${read_only ? 'cursor-not-allowed opacity-15' : 'cursor-pointer'}`}
          onClick={() => !read_only && handleCopy(row)}
        />
      </span>
      );
      Object.values(headingsToDataKeyMap).forEach((k) => {
        if (k.toString().trim() !== "") {
          transformedDataRow.push(generateCellHtml(row, k));
        }
      });
      transformedDataRow.push(
        // <span className="w-full flex justify-center">
        //   <PencilIcon
        //     className="cursor-pointer"
        //     onClick={() => handleEdit(row)}
        //   />
        // </span>,
        // <span className="w-full flex justify-center">
        //   <DeleteIcon
        //     className="cursor-pointer"
        //     onClick={() => handleDelete(row)}
        //   />
        // </span>
      );
      return transformedDataRow;
    });

    setDataRows(transformedDataRows);
  }, [scope3Category2InputRecords]);

  useEffect(() => {
    const filters = Object.fromEntries(searchParams.entries());
    dispatch(fetchscope3Category2InputData({api,params:filters})).then(() => {
    dispatch(fetchscope3Category2ItemData({api}));
    dispatch(fetchCurrencyData({api}));
    dispatch(fetchSiteData({api}));

    dispatch(fetchUnitData({api}));
    });
  }, []);

  return (
    <>
      <Header PageIcon={Scope3Icon} pageTitle={"Capital Goods"} />

      <div className="flex flex-col main-container w-full px-10 py-6">
      <div className="flex  justify-between items-center text-xs">
          <div className="flex">
          
          </div>
          <div className="flex">
            <FilterComponent
              filters={filters}
              onFilterChange={handleFilterChange}
            />
          </div>
        </div>
        <div className="flex flex-col border border-gray-300 rounded-md mt-[10px] relative">
          <TableMeta
            recordType={1}
            totalCount={totalCount}
            openPopUpForm={openPopupForm}
            switchTab={switchTab}
            selectedTab={selectedTab}
          />
          {manualEntry && (
            <>
              <Table
                headings={Object.keys(headingsToDataKeyMap)}
                dataRows={dataRows}
                paginationObject={{
                  paginationEnabled: true,
                  currentPage: currentPage,
                  totalPages: totalPages,
                  itemsPerPage: itemsPerPage,
                  handleItemsPerPage: handleItemsPerPage,
                  handlePageChange: handlePageChange,
                }}
              />
              {!read_only&& <div className="flex  w-fit py-4 px-2 absolute bottom-8 right-6 cursor-pointer">
                <AddIconBig onClick={() => openPopupForm(1)} />
              </div>}
              <TablePagination
                paginationObject={{
                  paginationEnabled: true,
                  currentPage: currentPage,
                  totalPages: totalPages,
                  itemsPerPage: itemsPerPage,
                  handleItemsPerPage: handleItemsPerPage,
                  handlePageChange: handlePageChange,
                }}
              />
            </>
          )}
          {apiIntegration && (
            <>
              <h1>API Integration tab</h1>
            </>
          )}
          {trendLine && (
            <>
              <h1>TrendLine tab</h1>
            </>
          )}
        </div>
      </div>

      {isPurchaseFormOpen && (
        <Purchases3c2Form
        files={files}
          handleFormChange={handleFormChange}
          itemSelected={itemselected}
          closePurchasePopupForm={closePurchasePopupForm}
        />
      )}
      {isEditCopyFormOpen && (
        <Purchases3c2EditAndCopyForm
          closePurchasePopupForm={() => setIsEditCopyFormOpen(false)}
          selectedRowData={selectedRowData}
          handleCloseEditCopyForms={handleCloseEditCopyForms}
          actionType={actionType}
        />
      )}
    </>
  );
};

export default Scope3Category2;
