import { createAsyncThunk } from "@reduxjs/toolkit";

import { setRequestPanelData, setuserProfile } from "./UserProfileSlices";
import { setIsLoading } from "../../../features/common/commonSlice";
import { parseJwt } from "../../../components/common/parseJwt";
import api from "../../../services/api";
import { toast } from "react-toastify";


export const updateUserProfile = createAsyncThunk(
  "userProfile/updateUserProfile",
  async ( {id,api }, { dispatch, getState }) => {
    const data = getState().userProfile.userProfileForm;
  

    dispatch(setIsLoading(true));
    try {
      const response = await api.put(`/user-profile/${id}`, {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        mobile_number:data.mobile_number,
        designation:data.designation,
        profile_pic_url:data.profile_pic_url
      });

      if (response.data.success) {
        toast.success("Profile updated successfully");
      } else {
        throw new Error(response.data.message || "Failed to update profile");
      }
    } catch (error) {
      // Handle update failure
      console.error("Update failed:", error);
      toast.error("Failed to update profile: " + error.message);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchUserProfile = createAsyncThunk(
    "userProfile/fetchUserProfile",
    async ({api}, { dispatch }) => {
    const accessToken=localStorage.getItem("accessToken")
    const payload=parseJwt(accessToken)
    const {user}=payload
   
      dispatch(setIsLoading(true))
      try {
        // Call the login API endpoint with username and password
        const response = await api.get("/user-profile/"+user.id);
  
      //  console.log(response)
       
  dispatch(setuserProfile(response?.data?.data))
        
      } catch (error) {
        // Handle login failure
        console.error(" failed:", error);
        throw error;
      } finally{
        dispatch(setIsLoading(false))
      }
    }
  );
  
  export const fetchRequestPanelData = createAsyncThunk(
    "userProfile/fetchRequestPanelData",
    async ({api}, { getState, dispatch }) => {
      const accessToken = getState().auth.accessToken;

  
       let apiUrl = "/requestPanelData";

      try {
        dispatch(setIsLoading(true));
        const response = await api.get(`${apiUrl}`, {
          // headers: {
          //   Authorization: `Bearer ${accessToken}`,
          // },
        });
  
     
        if (response.status === 200 && response.data.success) {
          // console.log(response);
          dispatch(setRequestPanelData(response.data));
      
          dispatch(setIsLoading(false));
         
        }
  
          return response.data;
      } catch (error) {
        // Handle other API call errors
        console.error("Error fetching data:", error);
        throw error;
      } finally {
        dispatch(setIsLoading(false));
      }
    }
  );
  export const postRequest = createAsyncThunk(
    "userProfile/postRequest",
    async (request, { getState, dispatch }) => {
      const accessToken = getState().auth.accessToken;

  
        let apiUrl = "/user-request";

      try {
        dispatch(setIsLoading(true));
        const response = await api.get(`${apiUrl}`, {
          // headers: {
          //   Authorization: `Bearer ${accessToken}`,
          // },
        });
  
     
        if (response.status === 200 && response.data.success) {
         
      
          dispatch(setIsLoading(false));
       
        }
      } catch (error) {
        // Handle other API call errors
        console.error("Error during requesting:", error);
        throw error;
      } finally {
        dispatch(setIsLoading(false));
      }
    }
  );