import React, { useState } from "react";
import { ReactComponent as BrowserLock } from "../../app/assets/Browser-lock.svg";
import { ReactComponent as LeftArrow } from "../../app/assets/←.svg";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../features/auth/authThunks";
function ForgotPassword() {
  const dispatch=useDispatch()
    const [username,setusername]=useState('')
  const navigate = useNavigate();
  const handleResetPassword = async () => {
    // Check if username is provided
    if (!username) {
      toast.error("Please enter username");
      return;
    }

    try {
      // Dispatch the forgot password action
      const res = await dispatch(forgotPassword({ username }));
      console.log(res)
      if (res?.payload?.success) {
        navigate("/checkInbox");
      } 
    } catch (error) {
      // Handle any unexpected errors
      toast.error("An error occurred. Please try again.");
    }
  };
  return (
    <div className=" h-screen w-full flex justify-center items-center text-center flex-col space-x-4 gap-4">
      <BrowserLock />
      <h1 className="text-center mt-5 text-3xl">Forgot Password ?</h1>
      <p className="lead text-muted text-center font-weight-normal">
        No worries,we'll send you instructions to reset your password on your email
      </p>
      <div className=" flex justify-start flex-col text-left w-1/2">
        <label className="block text-left mb-1" htmlFor="username">
          User name
        </label>
        <input
          className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
          type="text"
          name="username"
          onChange={(e)=>setusername(e.target.value)}
          placeholder="Enter username"
        />
      </div>
      <button
        onClick={handleResetPassword}
        className="p-2 rounded px-3 bg-[#02AB6C] text-white font-bold"
      >
        Reset Password
      </button>
      <Link to={"/login"} className=" flex items-center justify-center gap-3">
        <LeftArrow /> Back to Login
      </Link>
    </div>
  );
}

export default ForgotPassword;
