import React, { useEffect } from 'react'
import { isTokenValid, parseJwt } from '../components/common/parseJwt';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

function HomePage() {
  const navigate=useNavigate();
  const auth = useSelector((state) => state.auth.accessToken);
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
 
    if (auth || accessToken) {
      if (!isTokenValid(accessToken)) {
        navigate("/login");
      } else {
        let result = parseJwt(accessToken);

        navigate("/dashboard");
      }
    } else {
      toast.error("Please Login first");
      navigate("/login");
    }
  }, []);
  return (
    <div>
      home pages
    </div>
  )
}

export default HomePage
