import React from 'react';
import { ReactComponent as CalenderIcon } from "../../../../app/assets/CalenderIcon.svg";
const EmployeeBenefitsDisplay = ({ data,employementType}) => {

  const renderBenefitRow = (benefitType, benefitData) => {
    return (
      <tr key={benefitType} className='text-center'>
        <td className="border border-gray-300 p-2">{benefitType}</td>
        <td className="border border-gray-300 p-2">{benefitData.employeesCovered}</td>
        <td className="border border-gray-300 p-2 capitalize">{benefitData.status}</td>
      </tr>
    );
  };

  return (
    <div className="p-3 border border-gray-300 rounded">
      
      <div className="flex justify-start gap-5 border-b border-gray-300 pb-4 mb-4">
     
<div className="flex items-center gap-2 justify-center">
              
            <CalenderIcon className="w-5 h-5" />
                <label>Financial Year</label>
          <div className="flex items-center gap-2 justify-center border bg-white p-1 px-2 rounded">
            <div className="flex items-center gap-2">
              <select
                className="font-semibold  text-center"
                disabled
              >
                <option selected>{` ${data.financialYear}`}</option>
              </select>
            </div>
            </div>
            </div>
          
       
           
          </div>
      <table className="min-w-full border-collapse border border-gray-200">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-300 p-2">Benefit Type</th>
            <th className="border border-gray-300 p-2">Number of {employementType.split(" ")[1]} covered</th>
            <th className="border border-gray-300 p-2">Deducted and deposited with the authority</th>
          </tr>
        </thead>
        <tbody>
          {/* Render rows for PF, Gratuity, and ESI */}
          {renderBenefitRow('PF', data?.benefits?.pf)}
          {renderBenefitRow('Gratuity', data?.benefits?.gratuity)}
          {renderBenefitRow('ESI', data?.benefits?.esi)}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeBenefitsDisplay;
