import { ReactComponent as CloseIcon } from "../../../app/assets/CloseIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  Scope3UploadEvidence,
  fetchScope3ItemData,
  postScope3Data,
} from "../../../features/scope3/category1/Scope3Thunk";
import useApi from "../../../customHooks/useApi";

const PurchaseScope3Form = ({
  handleFormChange,
  closePurchasePopupForm,
  files,
  itemSelected,
}) => {
  let formValue = useSelector((state) => state.scope3.scope3Form);
  let scope3ItemData = useSelector((state) => state.scope3.scope3ItemData);
  const loading = useSelector((state) => state.common.isLoading);
  const dispatch = useDispatch();
  const api = useApi();
  const currencyOptions = useSelector((state) => state.scope3.currencyData);
  const unitOptions = useSelector((state) => state.scope3.unitData);
  const countryOfOrigin = useSelector((state) => state.scope3.countryOfOrigin);
  const siteOptions = useSelector((state) => state.scope3.siteData);
  const productionTypeOptions = useSelector(
    (state) => state.scope3.productionTypeData
  );

  const handlePurchaseFormSubmit = (e) => {
    e.preventDefault();
    const form = document.querySelector("#purchase-Scope3-form");
    if (form.length && form.checkValidity()) {
    
      if (files) {
        dispatch(Scope3UploadEvidence({api, files}))
          .then((result) => {
            // console.log(result);
            if (result?.payload?.msg === "File uploaded.") {
              dispatch(postScope3Data({api})).then(() => {
                closePurchasePopupForm();
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else if (form.length) {
      form.querySelector('input[type="submit"]').click();
    }
  };
  // useEffect(()=>{
  // dispatch(fetchScope3ItemData())
  // },[])
  console.log(formValue);
  return (
    <>
      <div className="mainContainer flex w-full h-screen overflow-hidden justify-center items-center z-[100] fixed bg-black bg-opacity-50 top-0 left-0 bottom-0">
        <div className="flex flex-col justify-center items-center w-9/12 text-xs bg-white absolute z-[1000] border border-transparent rounded-t-md">
          <div className="flex justify-between w-full bg-gray-50 py-2 px-4 border-b border-gray-300 mb-3">
            <span className="cursor-pointer">Purchased Goods and Services</span>
            <span className="cursor-pointer" onClick={closePurchasePopupForm}>
              <CloseIcon />
            </span>
          </div>
          <form
            style={{
              opacity: loading ? 0.5 : 1,
              pointerEvents: loading ? "none" : "auto",
            }}
            id="purchase-Scope3-form"
            className="flex flex-col w-full py-4"
            onSubmit={handlePurchaseFormSubmit}
          >
            <div className="grid grid-cols-4 gap-4 px-8 mb-4 justify-around">
              <div className="col-span-1 flex flex-col">
                <label htmlFor="date_of_purchase" className="text-xs py-1.5">
                  Purchased date<span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  max={new Date().toISOString().split("T")[0]}
                  name="date_of_purchase"
                  id="date_of_purchase"
                  value={formValue?.date_of_purchase || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="site" className="text-xs py-1.5">
                  Facility<span className="text-red-500">*</span>
                </label>
                <select
                  name="site"
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="">Choose the facility</option>
                  {siteOptions &&
                    siteOptions.map((site) => (
                      <option key={site.id} value={site.id}>
                        {site.site}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="item_name" className="text-xs py-1.5">
                  Item name<span className="text-red-500">*</span>
                </label>
                <select
                  name="item_name"
                  value={formValue?.item_name || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="" disabled>
                    Choose the item name
                  </option>
                  {scope3ItemData?.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.item_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="item_description" className="text-xs py-1.5">
                  Item description<span className="text-red-500">*</span>
                </label>
                <input
                  disabled
                  type="text"
                  name="item_description"
                  id="item_description"
                  value={formValue?.item_description || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="item_code" className="text-xs py-1.5">
                  Item code
                </label>
                <input
                  disabled
                  type="text"
                  name="item_code"
                  id="item_code"
                  value={formValue?.item_code || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="production_type" className="text-xs py-1.5">
                  Production type<span className="text-red-500">*</span>
                </label>
                <select
                  disabled
                  name="production_type"
                  value={formValue?.production_type || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="" disabled>
                    Choose the production type
                  </option>
                  {productionTypeOptions?.map((type) => (
                    <option key={type.id} value={type.production_type}>
                      {type.production_type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="supplier" className="text-xs py-1.5">
                  Supplier name
                </label>
                <input
                  disabled
                  type="text"
                  name="supplier"
                  id="supplier"
                  value={formValue?.supplier || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="supplier_code" className="text-xs py-1.5">
                  Supplier code
                </label>
                <input
                  disabled
                  type="text"
                  name="supplier_code"
                  id="supplier_code"
                  value={formValue?.supplier_code || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>

              <div className="col-span-1 flex flex-col">
                <label htmlFor="country" className="text-xs py-1.5">
                  Country of origin
                </label>
                <select
                  name="country"
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                  value={formValue.country || ""}
                >
                  <option value="">Choose the Country of origin</option>
                  {countryOfOrigin?.map((type) => (
                    <option key={type.sortName} value={type.fullName}>
                      {type.fullName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="item_quantity" className="text-xs py-1.5">
                  Item quantity<span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  step="0.0001"
                  name="item_quantity"
                  id="item_quantity"
                  value={formValue?.item_quantity || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="unit" className="text-xs py-1.5">
                  Quantity unit<span className="text-red-500">*</span>
                </label>
                <select
                  //  disabled
                  name="unit"
                  value={formValue?.unit || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required={formValue?.item_quantity}
                >
                  <option value="" disabled>
                    Choose the quantity unit
                  </option>
                  {unitOptions &&
                    unitOptions.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.unit}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="amount_paid" className="text-xs py-1.5">
                  Amount paid
                </label>
                <input
                  type="number"
                  name="amount_paid"
                  step="0.01" 
                  id="amount_paid"
                  value={formValue?.amount_paid || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="currency" className="text-xs py-1.5">
                  Currency
                </label>
                <select
                  name="currency"
                  disabled={!formValue?.amount_paid}
                  value={formValue?.currency || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required={formValue?.amount_paid}
                >
                  <option value="" disabled>
                    Choose the currency
                  </option>
                  {currencyOptions.map((currency) => (
                    <option key={currency.id} value={currency.id}>
                      {currency.currency}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="evidence" className="text-xs py-1.5">
                  Evidence<span className="text-red-500">*</span>
                </label>
                <input
                  type="file"
                  name="evidence"
                  id="evidence"
                  multiple
                  accept=".jpg, .jpeg, .png, .pdf, .zip"
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
            </div>
            <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
              <div className="w-1/6 flex flex-col">
                <button
                  type="button"
                  disabled={loading}
                  className="py-2 px-6 text-green-600 text-sm rounded-md"
                  onClick={closePurchasePopupForm}
                >
                  Cancel
                </button>
              </div>
              <div className="w-1/6 flex flex-col">
                <button
                  type="submit"
                  disabled={loading}
                  className={`py-2 px-6 text-white text-sm ${
                    loading ? "bg-green-500 cursor-wait" : "bg-green-600"
                  } rounded-md`}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PurchaseScope3Form;
