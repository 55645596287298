import React from "react";

// Sample dynamic data structure
const sampleData = [
  {
    incident: "Injury Frequency Rate (LTIFR per million-person hour worked)",
    categories: [
      { type: "Employees", currentYear: 5, previousYear: 4, materiality: "High, Sector Dependent" },
      { type: "Workers", currentYear: 6, previousYear: 5, materiality: "High, Sector Dependent" },
    ],
  },
  {
    incident: "Total recordable work-related injuries",
    categories: [
      { type: "Employees", currentYear: 10, previousYear: 9, materiality: "High, Sector Dependent" },
      { type: "Workers", currentYear: 12, previousYear: 11, materiality: "High, Sector Dependent" },
    ],
  },
  {
    incident: "No. of fatalities; consequence work-related injury",
    categories: [
      { type: "Employees", currentYear: 1, previousYear: 0, materiality: "High, Sector Dependent" },
      { type: "Workers", currentYear: 0, previousYear: 1, materiality: "High, Sector Dependent" },
    ],
  },
  {
    incident: "No. of fatalities; consequence work-related ill-health (excluding fatalities)",
    categories: [
      { type: "Employees", currentYear: 0, previousYear: 1, materiality: "High, Sector Dependent" },
      { type: "Workers", currentYear: 1, previousYear: 0, materiality: "High, Sector Dependent" },
    ],
  },
];

const SafetyTable = ({ data = sampleData,question }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full border border-gray-300 text-sm font-semibold text-gray-700">
        <thead className="bg-gray-100">
            <tr className=" bg-gray-300">
                <td className="border border-gray-300 px-4 py-2 text-start text-base font-semibold " colSpan={5} >
                {question}
                </td>
            </tr>
          <tr>
            <th className="px-4 py-2 border border-gray-300 text-left bg-gray-100 font-semibold">
              Safety Incident/Number
            </th>
            <th className="px-4 py-2 border border-gray-300 text-left bg-gray-100 font-semibold">
              Category
            </th>
            <th className="px-4 py-2 border border-gray-300 text-center bg-gray-100 font-semibold">
              Current Reporting Year
            </th>
            <th className="px-4 py-2 border border-gray-300 text-center bg-gray-100 font-semibold">
              Previous Reporting Year
            </th>
            {/* <th className="px-4 py-2 border border-gray-300 text-left bg-gray-100 font-semibold">
              Materiality
            </th> */}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <tr>
                <td className="px-4 py-2 border border-gray-300" rowSpan={item.categories.length}>
                  {item.incident}
                </td>
                <td className="px-4 py-2 text-sm border border-gray-300">{item.categories[0].type}</td>
                <td className="px-4 py-2 text-sm border border-gray-300 text-center">{item.categories[0].currentYear}</td>
                <td className="px-4 py-2 text-sm border border-gray-300 text-center">{item.categories[0].previousYear}</td>
                {/* <td className="px-4 py-2 text-sm border border-gray-300">{item.categories[0].materiality}</td> */}
              </tr>
              {item.categories.slice(1).map((category, subIndex) => (
                <tr key={subIndex}>
                  <td className="px-4 py-2 text-sm border border-gray-300">{category.type}</td>
                  <td className="px-4 py-2 text-sm border border-gray-300 text-center">{category.currentYear}</td>
                  <td className="px-4 py-2 text-sm border border-gray-300 text-center">{category.previousYear}</td>
                  {/* <td className="px-4 py-2 border border-gray-300">{category.materiality}</td> */}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SafetyTable;
