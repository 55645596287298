import React, { useState } from "react";
import { ReactComponent as AddUserIcon } from "../../app/assets/AddUserIcon.svg";
import { ReactComponent as CircleQuestionIcon } from "../../app/assets/CircleQuestionMark.svg";
import { ReactComponent as Addicon } from "../../app/assets/AddIcon.svg";
import { ReactComponent as DeleteIcon } from "../../app/assets/DeleteIcon.svg";
import { useNavigate } from "react-router-dom";

function AssignLevelTab() {
  const navigate = useNavigate();
  const [levels, setLevels] = useState([{ id: 1, name: "" }]);

  const addNewLevel = () => {
    const newLevel = { id: levels.length + 1, name: "" };
    setLevels([...levels, newLevel]);
  };

  const handleLevelChange = (id, value) => {
    setLevels((prevLevels) =>
      prevLevels.map((level) =>
        level.id === id ? { ...level, name: value } : level
      )
    );
  };

  const handleDeleteLevel = (id) => {
    setLevels((prevLevels) => prevLevels.filter((level) => level.id !== id));
  };

  const handleSaveLevel = () => {
    navigate("/userasign");
  };

  return (
    <div className="w-full flex justify-center items-center flex-col">
      <div className="w-fit flex justify-center flex-col items-center text-center p-2 mt-10">
        <span>
          <AddUserIcon />
        </span>
        <h1 className="text-3xl flex justify-center items-center">
          Define your Organisation's hierarchy{" "}
          <span className="ml-2">
            <CircleQuestionIcon />
          </span>
        </h1>
        <p className="text-gray-400">
          Choose the level and assign your business structure accordingly
        </p>
      </div>
      {levels.map((level) => (
        <div
          key={level.id}
          className="border border-[#EBEBEB] rounded-lg w-1/2 flex flex-col p-1 bg-white mt-2"
        >
          <div className="p-2 flex space-x-4 items-center text-center">
            <label className="flex cursor-pointer select-none">{`Level-${level.id}`}</label>
            <input
              className="p-2 border-0 border-l-2 border-r-2 flex-shrink-0 flex-1 outline-none h-12" // Set fixed height for the input
              type="text"
              placeholder="Type your level"
              value={level.name}
              onChange={(e) => handleLevelChange(level.id, e.target.value)}
            />
            <button
            disabled={level.id==1}
              className="flex space-x-2 items-center justify-center p-2 text-center"
              onClick={() => handleDeleteLevel(level.id)} // Call handleDeleteLevel with the level id when delete button is clicked
            >
              <DeleteIcon />
            </button>
          </div>
        </div>
      ))}
      <div className="flex justify-start mt-2 w-1/2">
        <button className="flex space-x-2 items-center justify-center text-center p-3 rounded-lg px-4 bg-white float-left border border-[#EBEBEB]" onClick={addNewLevel}>
          <span>Add Level</span>
          <Addicon />
        </button>
      </div>
      <button onClick={handleSaveLevel} className="p-3 px-10 rounded-lg bg-green-500 text-white">Save</button>
    </div>
  );
}

export default AssignLevelTab;
