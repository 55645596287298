import { createAsyncThunk } from "@reduxjs/toolkit";

import { setIsLoading } from "../../common/commonSlice.js";

import { toast } from "react-toastify";


import {
  resetScope3Form,
  setCurrencyData,
  setSiteData,
  setSiteDataForFilter,
  setUnitData,
  setdeletes3c1Data,
  setscope3Form,
  setscope3InputRecords,
  setscope3ItemData,
  setscope3ItemDataFilter,
} from "./Scope3Slice.js";
import { getOrganizationId } from "../../../components/common/parseJwt.js";
import { removeFalsyValues } from "../../../components/common/removeFaslyValues.js";


export const fetchScope3InputData = createAsyncThunk(
  "scope3/fetchScope3InputData",
  async ({api,params}, { _, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id=getOrganizationId()
    let apiUrl = "/scope3/category1/inputdata/"+organization_id;

    try {
      const response = await api.get(`${apiUrl}`,{
        params:removeFalsyValues(params)
      });

      if (response.status === 200 && response.data.success) {
        
        dispatch(setscope3InputRecords(response?.data?.data.map(item=>{
          return {
            ...item,
            currency: item.item_currency_id,
                production_type: item.item_production_type,
                site: item.site_id,
                unit: item.item_unit_id,
                supplier: item.item_supplier,
                supplier_code: item.item_supplier_code,
          }
        })));
        // dispatch(setCurrentPage(response.data.currentPage));
        // dispatch(setItemsPerPage(response.data.itemsPerPage));
        // dispatch(setTotalPages(response.data.totalPages));
        // dispatch(setTotalCount(response.data.totalCount));
        // return response
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchScope3ItemData = createAsyncThunk(
  "scope3/fetchScope3ItemData",
  async ({api}, { _, dispatch }) => {
    dispatch(setIsLoading(true));
    // const accessToken = getState().auth.accessToken;
    const organization_id=getOrganizationId()
    let apiUrl = "/scope3/category1/client_item_supply_chain_master/"+organization_id;

    try {
      const response = await api.get(`${apiUrl}`);

      if (response.status === 200 && response.data.success) {
        // console.log(response)
        dispatch(
          setscope3ItemData(
            response?.data?.data.map((item) => {
              return {
                ...item,
                
              };
            })
          )
        );
        dispatch(setscope3ItemDataFilter(response.data.Filters))
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);


export const fetchSiteData = createAsyncThunk(
  "scope3/fetchSiteData",
  async ({api}, { _, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id=getOrganizationId()
    try {
      const response = await api.get(
        `/scope3/category1/${organization_id}/sites`
      );

      if (response.status === 200 && response.data.success) {
        dispatch(setSiteData(response.data.data));
        dispatch(setSiteDataForFilter(response.data.Filters));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchUnitData = createAsyncThunk(
  "scope3/fetchUnitData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    // const accessToken = getState().auth.accessToken;
    try {
      const response = await api.get(`/scope3/category1/units`);

      if (response.status === 200 && response.data.success) {
        dispatch(setUnitData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchCurrencyData = createAsyncThunk(
  "scope3/fetchCurrencyData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    // const accessToken = getState().auth.accessToken;
    try {
      const response = await api.get(`/scope3/category1/currencies`);

      // console.log(response.data.data);
      if (response.status === 200 && response.data.success) {
        dispatch(setCurrencyData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const postScope3Data = createAsyncThunk(
  "scope3/postScope3Data",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    // const accessToken = getState().auth.accessToken;
    const { id, ...Scope3Form } = getState().scope3.scope3Form;
console.log({Scope3Form})
const organization_id=getOrganizationId()
    try {
      const response = await api.post(
        `/scope3/category1/${organization_id}/data`,
        {
          ...Scope3Form,
          item_currency_id: Scope3Form.currency,
          item_production_type: Scope3Form.production_type,
          site_id: Scope3Form.site,
          item_unit_id: Scope3Form.unit,
          item_supplier: Scope3Form.supplier,
          item_supplier_code: Scope3Form.supplier_code,
        }
      );
      // console.log(response);
      if (response.status === 200 || response.status === 201) {
        dispatch(resetScope3Form());
        dispatch(fetchScope3InputData({api,params:{}}));
        toast.success("Record created successfully", {
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error(error?.response?.data?.error || "Record creation failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const Scope3UploadEvidence = createAsyncThunk(
  "scope3/Scope3UploadEvidence",
  async ({api, files}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    // const accessToken = getState().auth.accessToken;
    const accessToken=localStorage.getItem("accessToken")
    const organization_id=getOrganizationId()
    try {
      const response = await api.post(
        `/scope3/category1/${organization_id}/evidence`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        dispatch(setIsLoading(false));

        dispatch(setscope3Form({ evidence: response?.data?.path }));
        toast.success("Evidence uploded successfully", {
          theme: "dark",
        });
      }
      return response.data
    } catch (error) {
      console.error("Error uploading evidence", error);
      toast.error("uploading evidence failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const updateScope3Data = createAsyncThunk(
  "scope3/updateScope3Data",
  async ({api,id}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    // const accessToken = getState().auth.accessToken;
    const formData = getState().scope3.scope3Form;
    // console.log(formData,getState());
    const organization_id=getOrganizationId()
    try {
      const response = await api.put(
        `/scope3/category1/${organization_id}/data/${id}`,
        {
          ...formData,
          item_currency_id: formData.currency,
          item_production_type: formData.production_type,
          site_id: formData.site,
          item_unit_id: formData.unit,
          item_supplier: formData.supplier,
          item_supplier_code: formData.supplier_code,
        }
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Record updated successfully", {
          theme: "dark",
        });
        dispatch(fetchScope3InputData({api,params:{}}))
      }
    } catch (error) {
      console.error("Error updating data:", error);
      toast.error("Record update failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const deletescope3Category1Data = createAsyncThunk(
  "scope3Category1/deletescope3Category1Data",
  async ({api,id}, { _, dispatch }) => {
    dispatch(setIsLoading(true));
  
    const organization_id=getOrganizationId()
    try {
      const response = await api.delete(
        `/scope3/category1/${organization_id}/data/${id}`
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Record deleted successfully", {
          theme: "dark",
        });
        dispatch(setdeletes3c1Data(id))
      }
    } catch (error) {
      console.error("Error deleting data:", error);
      toast.error("Record delete failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
