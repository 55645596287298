import { createSlice } from "@reduxjs/toolkit";

const socialSlice = createSlice({
  name: "social",
  initialState: {
    socialEmpDemographicsRecords: [],
    socialEmpWellBeingsRecords: [],
    socialRecords: [],
    socialFormData: {},
    formData: {},
    filters: null,
    sorting: null,
    socialRecordType: 1,
    itemsPerPage: 10,
    totalPages: 1,
    currentPage: 1,
    totalCount: 10,
    financialYearData: Array.from(
      { length: new Date().getFullYear() - 1970 + 1 },
      (_, i) => 1970 + i
    ).reverse(),
    siteData: [],
    employementType: "",
    siteDataForFilters: [],
    socialTypeData: [],
    socialComplaintsRecords: [],
    socialTypeDataForFilters: [],
    socialHiringTurnoverRecords: [],
    socialPaternityRecords: [],
    socialRetirementBenefitsRecords: [],
    socialSafetyRelatedIncidentsRecords: [],
    socialTrainingRecords: [],
    socialUnionAssociationParticipationRecords: [],
    socialGovernanceDiversityRecords: [],
    socialRehabilitedRecords: [],
    socialContratualEmpDetailsRecords: [],
    socialOHSSystemCoverageRecords: [],
  },
  reducers: {
    setSocialRecords: (state, action) => {
      state.socialRecords = action.payload;
    },
    setEmployementType: (state, action) => {
      state.employementType = action.payload;
    },
    setSocialEmpDemographicsRecords: (state, action) => {
      state.socialEmpDemographicsRecords = action.payload;
    },
    setSocialEmpWellBeingsRecords: (state, action) => {
      state.socialEmpWellBeingsRecords = action.payload;
    },
    setSocialFormData: (state, action) => {
      return {
        ...state,
        socialFormData: {
          ...state.socialFormData,
          ...action.payload,
        },
      };
    },
    setformData: (state, action) => {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      };
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    resetSocialFormData: (state, action) => {
      state.socialFormData = {};
      state.formData = {};
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    setSocialRecordType: (state, action) => {
      state.socialRecordType = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload;
    },
    setSiteData: (state, action) => {
      state.siteData = action.payload;
    },
    setSiteDataForFilters: (state, action) => {
      state.siteDataForFilters = action.payload;
    },
    setSocialTypeData: (state, action) => {
      state.socialTypeData = action.payload;
    },
    setSocialTypeDataForFilters: (state, action) => {
      state.socialTypeDataForFilters = action.payload;
    },
    deleteSocialRecord: (state, action) => {
      const filteredRecords = state.socialRecords.filter(
        (i) => i.id !== action.payload
      );
      state.socialRecords = filteredRecords;
    },
    setSocialHiringTurnoverRecords: (state, action) => {
      state.socialHiringTurnoverRecords = action.payload;
    },
    setSocialPaternityRecords: (state, action) => {
      state.socialPaternityRecords = action.payload;
    },
    setSocialRetirementBenefitsRecords: (state, action) => {
      state.socialRetirementBenefitsRecords = action.payload;
    },
    setSocialComplaintsRecords: (state, action) => {
      state.socialComplaintsRecords = action.payload;
    },
    setSocialSafetyRelatedIncidentsRecords: (state, action) => {
      state.socialSafetyRelatedIncidentsRecords = action.payload;
    },
    setSocialTrainingRecords: (state, action) => {
      state.socialTrainingRecords = action.payload;
    },
    setSocialUnionAssociationParticipationRecords: (state, action) => {
      state.socialUnionAssociationParticipationRecords = action.payload;
    },
    setSocialGovernanceDiversityRecords: (state, action) => {
      state.socialGovernanceDiversityRecords = action.payload;
    },
    setsocialRehabilitedRecords: (state, action) => {
      state.socialRehabilitedRecords = action.payload;
    },
    setsocialContratualEmpDetailsRecords: (state, action) => {
      state.socialContratualEmpDetailsRecords = action.payload;
    },
    setsocialOHSSystemCoverageRecords: (state, action) => {
      state.socialOHSSystemCoverageRecords = action.payload;
    },
  },
});

export const {
  setFilters,
  setformData,
  deleteSocialRecord,
  setSocialFormData,
  setSocialRecordType,
  setSiteDataForFilters,
  setSocialRecords,
  setSocialEmpDemographicsRecords,
  setSocialEmpWellBeingsRecords,
  setEmployementType,
  setSorting,
  setCurrentPage,
  setItemsPerPage,
  setTotalCount,
  setTotalPages,
  setSocialTypeData,
  setSocialTypeDataForFilters,
  setSiteData,
  resetSocialFormData,
  setSocialHiringTurnoverRecords,
  setSocialPaternityRecords,
  setSocialRetirementBenefitsRecords,
  setSocialSafetyRelatedIncidentsRecords,
  setSocialTrainingRecords,
  setSocialUnionAssociationParticipationRecords,
  setSocialComplaintsRecords,
  setSocialGovernanceDiversityRecords,
  setsocialContratualEmpDetailsRecords,
  setsocialOHSSystemCoverageRecords,setsocialRehabilitedRecords
} = socialSlice.actions;

export default socialSlice.reducer;
