import { ReactComponent as CloseIcon } from "../../../app/assets/CloseIcon.svg";
import { ReactComponent as InfoIcon } from "../../../app/assets/InfoIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  postscope3Category4Data,
  scope3Category4UploadEvidence,
  updatescope3Category4Data,
} from "../../../features/scope3/category4/Scope3Category4Thunk";
import {
  setfiltredenergySourceData,
  setfiltredvehicleSpecificatioonData,
  setfiltredvehicleTypeData,
  setscope3Category4Form,
} from "../../../features/scope3/category4/Scope3Category4Slice";
import { toast } from "react-toastify";
import useApi from "../../../customHooks/useApi";
import { filterEnergySourceType, filterSpecificationsByVehicleType } from "../../../pages/inputScope3/function/filterspecifications";

const Purchases3c4EditAndCopyForm = ({
  closePurchasePopupForm,
  selectedRowData,
  logisticData,
  actionType,
}) => {
  
  const siteOptions = useSelector((state) => state.scope3Category4.siteData);
  const api = useApi();
  const currencyOptions = useSelector(
    (state) => state.scope3Category4.currencyData
  );

  const [file, setfile] = useState(null);
  const [formData, setFormData] = useState({});
  let formValue = useSelector(
    (state) => state.scope3Category4.scope3Category4Form
  );
  const loading = useSelector((state) => state.common.isLoading);
  const dispatch = useDispatch();
  const {
    energySourceData,
    vehicleSpecificatioonData,
    filteredvehicleTypeData,
    filtredenergySourceData,
    filtredvehicleSpecificatioonData,
    logisticModeData,
    vehicleTypeData,
    scope3Category4Form,
    scope3Category4ItemData,
  } = useSelector((state) => state.scope3Category4);

  const unitOptions = useSelector((state) => state.scope3Category4?.unitData);

  const productionTypeOptions = useSelector(
    (state) => state.scope3Category4?.productionTypeData
  );
  const handleFormChange = (e) => {
    const { name, value, type } = e.target;
    if (name === "logistic_mode" && logisticModeData) {
   
      dispatch(
        setscope3Category4Form({
          ...scope3Category4Form,
          [name]: value,
        })
      );
      setFormData({
        ...formData,

        [name]: value,
      });
      const filtered = logisticData?.find(
        (item) => item.logistic_mode === value
      );
      dispatch(setfiltredvehicleTypeData(filtered.vehicle_types));
      dispatch(setfiltredvehicleSpecificatioonData([]))
      dispatch(setfiltredenergySourceData([]));
    } else if (name === "vehicle_type") {
      dispatch(
        setscope3Category4Form({
          ...scope3Category4Form,
          [name]: value,
        })
      );
      setFormData({
        ...formData,

        [name]: value,
      });

      const filteredSpecs = filterSpecificationsByVehicleType(
        logisticData,
        scope3Category4Form.logistic_mode,
        value
      );
      // console.log(logisticData,filteredSpecs,value)
      dispatch(setfiltredvehicleSpecificatioonData(filteredSpecs));
      dispatch(setfiltredenergySourceData([]))
    }else if (name === "vehicle_specification") {
      dispatch(
        setscope3Category4Form({
          ...scope3Category4Form,
          [name]: value,
        })
      );
      setFormData({
        ...formData,

        [name]: value,
      });

      const filteredSpecs = filterEnergySourceType(
        logisticModeData,
        scope3Category4Form.logistic_mode,
        scope3Category4Form.vehicle_type,
        value
      );
    
      dispatch(setfiltredenergySourceData(filteredSpecs))
    } else if (name === "item_carried" && scope3Category4ItemData) {
      let selected=scope3Category4ItemData?.find(item=>item.id==value)
    

      dispatch(
        setscope3Category4Form({ ...scope3Category4Form, [name]: value,
          production_type: selected.production_type,
          supplier: selected.supplier,
          supplier_code: selected.supplier_code,
          supplier_registration_number: selected.supplier_registration_number,
          item_code: selected.item_code,
          item_description: selected.item_description,
         })
      );
      setFormData({
        ...formData,
        [name]: value,
        production_type: selected.production_type,
        supplier: selected.supplier,
        supplier_code: selected.supplier_code,
        supplier_registration_number: selected.supplier_registration_number,
        item_code: selected.item_code,
        item_description: selected.item_description,
      });
    } else if (type === "file") {
      const files = e.target.files;
      setfile(files);
    } else {
      setFormData({ ...formData, [name]: value });
      dispatch(setscope3Category4Form({ ...formData, [name]: value }));
    }
  };
  const handlePurchaseFormSubmit = (e) => {
    e.preventDefault();
    if (!file) {
      toast.warning("Please Upload evidence");
    }
    const form = document.querySelector("#purchase-scope3Category4-form");

    if (form.length && form.checkValidity()) {
      switch (actionType) {
        case "copy":
          dispatch(scope3Category4UploadEvidence({ files: file, api })).then(
            (res) => {
              if (res?.payload?.msg === "File uploaded.") {
                dispatch(postscope3Category4Data({ api }));
              }
            }
          );
          break;
        case "edit":
          // // console.log(actionType);
          dispatch(scope3Category4UploadEvidence({ files: file, api })).then(
            (res) => {
              if (res?.payload?.msg === "File uploaded.") {
                dispatch(
                  updatescope3Category4Data({ api, id: selectedRowData.id })
                );
              }
            }
          );
          break;
        default:
          break;
      }
      closePurchasePopupForm();
    } else if (form.length) {
      form?.querySelector('input[type="submit"]').click();
    }
  };

  useEffect(() => {
    if (selectedRowData) {
      const updatedData = {
        site_id: selectedRowData?.site_id,

        date: selectedRowData?.date,

        currency: currencyOptions?.find(
          (item) => selectedRowData?.currency === item.currency
        )?.id,

        source_location: selectedRowData.source_location,
        destination_location: selectedRowData.destination_location,
        distance: selectedRowData.distance,
        logistic_mode: selectedRowData.logistic_mode,

        vehicle_specification: vehicleSpecificatioonData?.find(
          (item) =>
            selectedRowData.vehicle_specification === item.vehicle_specification
        )?.id,
        vehicle_type: vehicleTypeData?.find(
          (item) => selectedRowData.vehicle_type === item.type
        )?.id,
        volume_mass_carried: selectedRowData.volume_mass_carried,
        volume_mass_unit: unitOptions?.find(
          (item) => selectedRowData.volume_mass_unit === item.unit
        )?.id,
        mass_allocation: selectedRowData.mass_allocation,
        energy_source: energySourceData?.find(
          (item) => selectedRowData.energy_source === item.fuel_type
        )?.id,
        production_type: selectedRowData.production_type,
        logistic_spend: selectedRowData.logistic_spend,
        supplier: selectedRowData.supplier,
        supplier_code: selectedRowData.supplier_code,
        item_code: selectedRowData.item_code,
        item_description: selectedRowData.item_description,
        supplier_registration_number: selectedRowData.supplier_registration_number,
        item_carried: selectedRowData.item_carried_id,
        evidence: selectedRowData.evidence,
      };
      
      setFormData(updatedData);
      dispatch(setscope3Category4Form({ ...formValue, ...updatedData }));
    }
    if (selectedRowData.logistic_mode && logisticData) {
      const filtered = logisticData?.find(
        (item) => item.logistic_mode === selectedRowData.logistic_mode
      );

      dispatch(setfiltredvehicleTypeData(filtered.vehicle_types));
      dispatch(setfiltredvehicleSpecificatioonData(filtered.specifications));
      dispatch(setfiltredenergySourceData(filtered.fuel_types));
    }
  }, [selectedRowData, actionType]);

  // console.log(selectedRowData);
  return (
    <>
      <div className="mainContainer flex w-full h-screen overflow-hidden justify-center items-center z-[100] fixed bg-black bg-opacity-50 top-0 left-0 bottom-0">
        <div className="flex flex-col justify-center items-center w-9/12 text-xs bg-white absolute z-[1000] border border-transparent rounded-t-md">
          <div className="flex justify-between w-full bg-gray-50 py-2 px-4 border-b border-gray-300 mb-3">
            <span className="cursor-pointer">
              Upstream Transportation and Distribution
            </span>
            <span
              className="cursor-pointer"
              onClick={() => closePurchasePopupForm()}
            >
              <CloseIcon />
            </span>
          </div>
          <form
            style={{
              opacity: loading ? 0.5 : 1,
              pointerEvents: loading ? "none" : "auto",
            }}
            id="purchase-scope3Category4-form"
            className="flex flex-col w-full py-4"
            onSubmit={handlePurchaseFormSubmit}
          >
            <div className="grid grid-cols-4 gap-4 px-8 mb-4 justify-around">
              <div className="col-span-1 flex flex-col">
                <label htmlFor="date" className="text-xs py-1.5">
                  Date<span className="text-red-500">*</span>
                </label>
                <input
                  max={new Date().toISOString().split("T")[0]}
                  type="date"
                  name="date"
                  id="date"
                  value={formValue?.date || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="site_id" className="text-xs py-1.5">
                  Facility<span className="text-red-500">*</span>
                </label>
                <select
                  name="site_id"
                  value={formValue.site_id}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="">Choose the facility</option>
                  {siteOptions &&
                    siteOptions.map((site) => (
                      <option key={site.id} value={site.id}>
                        {site.site}
                      </option>
                    ))}
                </select>
              </div>

              <div className="flex flex-col">
                <label htmlFor="source_location" className="text-xs py-1.5">
                  Source location<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="source_location"
                  value={formValue.source_location}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="destination_location"
                  className="text-xs py-1.5"
                >
                  Destination location<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="destination_location"
                  value={formValue.destination_location}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="distance"
                  className="text-xs py-1.5 flex justify-between items-center px-2"
                >
                  <span>
                    Distance (km)<span className="text-red-500">*</span>
                  </span>{" "}
                  <a
                    className="flex justify-center gap-1 items-center font-thin text-xs"
                    target="_blank"
                    href="https://www.searates.com/services/distances-time"
                  >
                    <InfoIcon className="w-4 h-4 font-thin cursor-pointer" />
                    Distance calculator
                  </a>
                </label>
                <input
                  type="number"
                  step="0.0001"
                  name="distance"
                  value={formValue.distance}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="logistic_mode" className="text-xs py-1.5">
                  Logistic mode<span className="text-red-500">*</span>
                </label>
                <select
                  name="logistic_mode"
                  value={formValue.logistic_mode}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="">Select logistic mode</option>
                  {logisticData &&
                    logisticData.map((item, i) => {
                      return (
                        <option key={item?.id || i} value={item.logistic_mode}>
                          {item.logistic_mode}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="flex flex-col">
                <label htmlFor="vehicle_type" className="text-xs py-1.5">
                  Vehicle type<span className="text-red-500">*</span>
                </label>
                <select
                  name="vehicle_type"
                  value={formValue.vehicle_type}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="">Choose the vehicle Type</option>
                  {filteredvehicleTypeData?.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="vehicle_specification"
                  className="text-xs py-1.5"
                >
                  Vehicle specification<span className="text-red-500">*</span>
                </label>
                <select
                  name="vehicle_specification"
                  value={formValue.vehicle_specification}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="">Choose the vehicle specification</option>
                  {filtredvehicleSpecificatioonData?.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.vehicle_specification}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col">
                <label htmlFor="volume_mass_carried" className="text-xs py-1.5">
                  Mass/Volume Carried<span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  step="0.0001"
                  name="volume_mass_carried"
                  value={formValue.volume_mass_carried}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="volume_mass_unit" className="text-xs py-1.5">
                  Mass/Volume unit<span className="text-red-500">*</span>
                </label>
                <select
                  name="volume_mass_unit"
                  value={formValue.volume_mass_unit}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required={formValue.volume_mass_carried}
                >
                  <option value="">Choose the Production Type</option>
                  {unitOptions?.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.unit}
                    </option>
                  ))}
                </select>
              </div>
              <div className="hidden flex-col">
                <label htmlFor="mass_allocation" className="text-xs py-1.5">
                  Mass/Volume allocation (%)
                </label>
                <input
                  type="number"
                  step="0.0001"
                  name="mass_allocation"
                  value={formValue.mass_allocation}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="energy_source" className="text-xs py-1.5">
                  Energy source<span className="text-red-500">*</span>
                </label>
                <select
                  name="energy_source"
                  value={formValue.energy_source}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="">Choose the Energy Source</option>
                  {filtredenergySourceData?.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.fuel_type}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col">
                <label htmlFor="logistic_spend" className="text-xs py-1.5">
                  Logistic spend
                </label>
                <input
                  type="number"
                  step="0.0001"
                  name="logistic_spend"
                  value={formValue.logistic_spend}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>

              <div className="col-span-1 flex flex-col">
                <label htmlFor="currency" className="text-xs py-1.5">
                  Currency
                </label>
                <select
                  name="currency"
                  value={formValue?.currency || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                >
                  <option value="" disabled>
                    Choose the currency
                  </option>
                  {currencyOptions.map((currency) => (
                    <option key={currency.id} value={currency.id}>
                      {currency.currency}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="item_carried" className="text-xs py-1.5">
                  Item carried<span className="text-red-500">*</span>
                </label>
                <select
                  name="item_carried"
                  value={formValue?.item_carried || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="" disabled>
                    Choose the Item Name
                  </option>
                  {scope3Category4ItemData?.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.combination}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="item_description" className="text-xs py-1.5">
                  Item description<span className="text-red-500">*</span>
                </label>
                <input
                  disabled
                  type="text"
                  name="item_description"
                  id="item_description"
                  value={formValue?.item_description || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="item_code" className="text-xs py-1.5">
                  Item code
                </label>
                <input
                  disabled
                  type="text"
                  name="item_code"
                  id="item_code"
                  value={formValue?.item_code || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="production_type" className="text-xs py-1.5">
                  Production type<span className="text-red-500">*</span>
                </label>
                <select
                  disabled
                  name="production_type"
                  value={formValue?.production_type || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                >
                  <option value="" disabled>
                    Choose the production type
                  </option>
                  {productionTypeOptions?.map((type) => (
                    <option key={type.id} value={type.production_type}>
                      {type.production_type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="supplier" className="text-xs py-1.5">
                  Supplier name
                </label>
                <input
                  disabled
                  type="text"
                  name="supplier"
                  id="supplier"
                  value={formValue?.supplier || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="supplier_code" className="text-xs py-1.5">
                  Supplier code
                </label>
                <input
                  disabled
                  type="text"
                  name="supplier_code"
                  id="supplier_code"
                  value={formValue?.supplier_code || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div>
              {/* <div className="col-span-1 flex flex-col">
                <label htmlFor="supplier_registration_number" className="text-xs py-1.5">
                Supplier registration number
                </label>
                <input
                  disabled
                  type="text"
                  name="supplier_registration_number"
                  id="supplier_registration_number"
                  value={formValue?.supplier_registration_number || ""}
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  // required
                />
              </div> */}
              <div className="col-span-1 flex flex-col">
                <label htmlFor="evidence" className="text-xs py-1.5">
                  Evidence<span className="text-red-500">*</span>
                </label>
                <input
                  type="file"
                  name="evidence"
                  id="evidence"
                  multiple
                  accept=".jpg, .jpeg, .png, .pdf, .zip"
                  onChange={handleFormChange}
                  className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                  required
                />
              </div>
            </div>
            <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
              <div className="w-1/6 flex flex-col">
                <button
                  type="button"
                  disabled={loading}
                  className="py-2 px-6 text-green-600 text-sm rounded-md"
                  onClick={closePurchasePopupForm}
                >
                  Cancel
                </button>
              </div>
              <div className="w-1/6 flex flex-col">
                <button
                  type="submit"
                  disabled={loading}
                  className={`py-2 px-6 text-white text-sm ${
                    loading ? "bg-green-500 cursor-wait" : "bg-green-600"
                  } rounded-md`}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Purchases3c4EditAndCopyForm;
