import React, { useEffect } from 'react'
import EmployeeTypeTiles from './Tiles'
import { useDispatch, useSelector } from 'react-redux';
import { fetchSocialEmpDemographicInputs } from '../../../../features/social/socialThunk';
import useApi from '../../../../customHooks/useApi';
import { aggregateDataByEmploymentType } from '../../function/functionProcess';
import GenericTiles from './GenericTileSiteWise';

function EmployeeDemographics() {
  const {socialEmpDemographicsRecords,employementType}=useSelector(state=>state.social)
  // const {isLoading}=useSelector(state=>state.common)
  const dispatch=useDispatch()
  const api =useApi()
  useEffect(()=>{
dispatch(fetchSocialEmpDemographicInputs({api,params:{}}))
  },[])
 
  return (
    <div className='overflow-auto h-full w-full'>
    <GenericTiles data={aggregateDataByEmploymentType(socialEmpDemographicsRecords,employementType)} title="Employee Overview"
  categoryHeaders={["Male", "Female", "Other", "Not Disclosed"]}
  dataKey="Employee Type" />
    </div>
  )
}

export default EmployeeDemographics
