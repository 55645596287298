// File: TableComponent.js

import React from "react";
import { ReactComponent as PencilIcon } from "../../app/assets/PencilIcon.svg";
import { ReactComponent as DeleteIcon } from "../../app/assets/DeleteIcon.svg";
import { ReactComponent as DownloadIcon } from "../../app/assets/DownloadIcon.svg";
import { ReactComponent as CopyIcon } from "../../app/assets/CopyIcon.svg";
import Table from "../../components/common/Table/Table";

const TableComponent = ({
  headings,
  dataRows,
  handleEdit,
  handleDelete,
  handleCopy,
  handleFileDownload,
}) => {
  const generateCellHtml = (row, k) => {
    let cellHtml = null;
    switch (k) {
      case "Scope3_type":
        cellHtml = (
          <span className="w-full flex justify-center">
            <span
              className={`px-2 py-1 border border-transparent rounded-md ${
                row["source_type"].toString().toLowerCase() ===
                "Renewable".toLowerCase()
                  ? "bg-green-200 text-green-600"
                  : "bg-red-200 text-red-600"
              }`}
            >
              {row[k]}
            </span>
          </span>
        );
        break;
      case "edit":
        cellHtml = (
          <span className="w-full flex justify-center">
            <PencilIcon
              className="cursor-pointer"
              onClick={() => handleEdit(row)}
            />
          </span>
        );
        break;
        case "delete":
        cellHtml = (
          <span className="w-full flex justify-center">
            <DeleteIcon
              className="cursor-pointer"
              onClick={() => handleDelete(row)}
            />
          </span>
        );
        break;
      case "status":
        cellHtml = (
          <span className="w-full flex justify-center">
            <span
              className={`px-2 py-1 border border-transparent rounded-md ${
                row[k] === "Accepted"
                  ? "bg-green-200 text-green-600"
                  : row[k] === "Submitted"
                  ? "bg-blue-200 text-blue-600"
                  : "bg-red-200 text-red-600"
              }`}
            >
              {row[k]}
            </span>
          </span>
        );
        break;
      case "evidence":
      case "treated_evidence":
      case "produced_evidence":
        cellHtml = (
          <span className="w-full flex justify-center">
            <DownloadIcon
              className="cursor-pointer fill-green-500"
              onClick={() => handleFileDownload(row[k])}
            />
          </span>
        );
        break;
      case "submitted_by":
      case "copy":
        cellHtml = (
          <span className="w-full flex justify-center">
          <CopyIcon
            className="cursor-pointer"
            onClick={() => handleCopy(row)}
          />
        </span>
        );
        break;
      default:
        cellHtml = <span className="w-full flex justify-center">{row[k]}</span>;
        break;
    }
    return cellHtml;
  };

  return (
    <>
      <Table
      height="62.5vh"
        headings={Object.keys(headings)}
        dataRows={dataRows.map((row) => {
          let transformedDataRow = [];
          // transformedDataRow.push(
          //   <span className="w-full flex justify-center">
          //     <CopyIcon
          //       className="cursor-pointer"
          //       onClick={() => handleCopy(row)}
          //     />
          //   </span>
          // );
          Object.values(headings).forEach((k) => {
            if (k.toString().trim() !== "") {
              transformedDataRow.push(generateCellHtml(row, k));
            }
          });
          return transformedDataRow;
        })}
      />
    </>
  );
};

export default TableComponent;
