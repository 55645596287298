// /features/auth/authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import logo from "../../app/assets/Logo.svg";

// Assume you have thunks for login and logout (similar to the previous example)

const commonSlice = createSlice({
  name: "common",
  initialState: {
    isLoading: false,
    logoPath: logo,
    orgName: "",
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setlogoPath: (state, action) => {
      state.logoPath = action.payload;
    },
  },
});

export const { setIsLoading,setlogoPath } = commonSlice.actions;
export default commonSlice.reducer;
