import React, { useEffect, useState } from 'react';
import ConfirmPopup from './ConfirmPopUp';
import MultiSelectComponent from './MultiselectComponent';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setadminDashboardForm } from '../../Redux/SettingSlices';


const TabContentForSite = ({

  siteOptions,
 
  initialSelectedSites,
 
  onSave
}) => {
 const dispatch=useDispatch()
  const [selectedSites, setSelectedSites] = useState( initialSelectedSites||[]);
  const { adminDashboardForm } = useSelector(
    (state) => state.setting
  );
 

  const handleSiteChange = (selected) => {
    setSelectedSites(selected);
    dispatch(
      setadminDashboardForm({

        permissions: {
          ...adminDashboardForm.permissions,
          sites:
            selected?.map(i=>Number(i.value)).length === siteOptions.length
              ? [-1]
              : selected?.map(i=>Number(i.value)),
        },
      })
    );
  };



  const handleConfirmPopup = () => {
    const data = {
    site:
    selectedSites.length === siteOptions.length
      ?[-1]
      : selectedSites.map((i) => Number(i.value))
     
    };
    
    if(selectedSites.length===0){
      toast.error("Please select site",{theme:"dark"})
      
    }else{

      onSave(data);
    

    }
   
  };

  return (
    <div className="flex flex-col justify-between w-full h-full">
      <div className="mb-2">
        <label className="block mb-2 font-semibold">Sites:</label>
        <MultiSelectComponent options={siteOptions} selected={selectedSites} onChange={handleSiteChange} />
      </div>
     

   

      <div className=" w-full flex justify-center flex-1 items-center mt-5 absolute bottom-5">
        <button onClick={handleConfirmPopup} className="bg-green-500 w-48 text-white py-2 px-4 rounded-md shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-opacity-50">
        Save changes
        </button>
      </div>

    </div>
  );
};

export default TabContentForSite;