import { createAsyncThunk } from "@reduxjs/toolkit";
import { setIsLoading } from "../../common/commonSlice.js";
import { toast } from "react-toastify";
import {
  resetscope3Category9Form,
  setCurrencyData,
  setSiteData,
  setSiteDataForFilter,
  setUnitData,
  setdeletes3c9Data,
  setenergySourceData,
  setlogisticModeData,
  setscope3Category9Form,
  setscope3Category9InputRecords,
  setscope3Category9ItemData,
  setscope3Category9ItemDataFilter,
  setvehicleSpecificatioonData,
  setvehicleTypeData,
} from "./Scope3Category9Slice.js"
import { getOrganizationId } from "../../../components/common/parseJwt.js";
import { removeFalsyValues } from "../../../components/common/removeFaslyValues.js";
export const fetchscope3Category9InputData = createAsyncThunk(
  "scope3Category9/fetchscope3Category9InputData",
  async ({api,params}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id=getOrganizationId()

    let apiUrl = "/scope3/category9/inputdata/"+organization_id;

    try {
      const response = await api.get(`${apiUrl}`,{
        params:removeFalsyValues(params)
      });

// console.log(response)
      if (response.status === 200 && response.data.success) {
        dispatch(setscope3Category9InputRecords(response?.data?.data.map(item=>{
          return {
            ...item,
            // currency: item.item_currency_id,
            //     production_type: item.item_production_type,
            //     site: item.site_id,
            //     unit: item.item_unit_id,
            //     supplier: item.item_supplier,
            //     supplier_code: item.item_supplier_code,
          }
        })));
        // dispatch(setCurrentPage(response.data.currentPage));
        // dispatch(setItemsPerPage(response.data.itemsPerPage));
        // dispatch(setTotalPages(response.data.totalPages));
        // dispatch(setTotalCount(response.data.totalCount));
        // return response.data
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchscope3Category9ItemData = createAsyncThunk(
  "scope3Category9/fetchscope3Category9ItemData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    const organization_id=getOrganizationId()
    let apiUrl = "/scope3/category9/client_item_supply_chain_master/"+organization_id;

    try {
      const response = await api.get(`${apiUrl}`, {
        //   headers: {
        //     Authorization: `Bearer ${accessToken}`,
        //   },
      });

      if (response.status === 200 && response.data.success) {
        dispatch(
          setscope3Category9ItemData(
            response?.data?.data.map((item) => {
              return {
                ...item,
                // item_code:item.organization_item_code,
                // supplier_code:item.organization_supplier_code
                
              };
            })
          )
        );
        dispatch(setscope3Category9ItemDataFilter(response.data.Filters))
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchSiteData = createAsyncThunk(
  "scope3Category9/fetchSiteData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id=getOrganizationId()
    try {
      const response = await api.get(
        `/scope3/category9/${organization_id}/sites`,
        {
          // headers: {
          //   Authorization: `Bearer ${accessToken}`,
          // },
        }
      );

      if (response.status === 200 && response.data.success) {
        dispatch(setSiteData(response.data.data));
        dispatch(setSiteDataForFilter(response.data.Filters));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchUnitData = createAsyncThunk(
  "scope3Category9/fetchUnitData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    try {
      const response = await api.get(`/scope3/category9/units`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setUnitData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchVehicleTypeData = createAsyncThunk(
  "scope3Category9/fetchVehicleTypeData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    try {
      const response = await api.get(`/scope3/category9/vehicle-type`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setvehicleTypeData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchLogisticmodeData = createAsyncThunk(
  "scope3Category9/fetchLogisticmodeData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    try {
      const response = await api.get(`/scope3/category9/logistic-mode-mapping`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });
// console.log(response)
      if (response.status === 200 && response.data.success) {
        dispatch(setlogisticModeData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchEnergySourceTypeData = createAsyncThunk(
  "scope3Category9/fetchEnergySourceTypeData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    try {
      const response = await api.get(`/scope3/category9/energy-source`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setenergySourceData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchVehicleSpecificationData = createAsyncThunk(
  "scope3Category9/fetchVehicleSpecificationData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    try {
      const response = await api.get(`/scope3/category9/vehicle-specification`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setvehicleSpecificatioonData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchCurrencyData = createAsyncThunk(
  "scope3Category9/fetchCurrencyData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    try {
      const response = await api.get(`/scope3/category9/currencies`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      // console.log(response.data.data);
      if (response.status === 200 && response.data.success) {
        dispatch(setCurrencyData(response?.data?.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const postscope3Category9Data = createAsyncThunk(
  "scope3Category9/postscope3Category9Data",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    const { id, ...scope3Category9Form } = getState().scope3Category9.scope3Category9Form;
    const organization_id=getOrganizationId()
    try {
      const response = await api.post(
        `/scope3/category9/${organization_id}/data`,
        {
          ...scope3Category9Form,
          // item_currency_id: scope3Category9Form.currency,
          // item_production_type: scope3Category9Form.production_type,
          // site_id: scope3Category9Form.site,
          // item_unit_id: scope3Category9Form.unit,
          // item_supplier: scope3Category9Form.supplier,
          // item_supplier_code: scope3Category9Form.supplier_code,
        },
        {
          // headers: {
          //   Authorization: `Bearer ${accessToken}`,
          // },
        }
      );
      // console.log(response);
      if (response.status === 200 || response.status === 201) {
        dispatch(resetscope3Category9Form());
        dispatch(fetchscope3Category9InputData({api,params:{}}));
        toast.success("Record created successfully", {
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error(error?.response?.data?.error || "Record creation failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const scope3Category9UploadEvidence = createAsyncThunk(
  "scope3Category9/scope3Category9UploadEvidence",
  async ({files,api}, { _, dispatch }) => {
    dispatch(setIsLoading(true));
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    // const accessToken = getState().auth.accessToken;
    const accessToken=localStorage.getItem("accessToken")
    const organization_id=getOrganizationId()
    try {
      const response = await api.post(
        `/scope3/category9/${organization_id}/evidence`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        dispatch(setIsLoading(false));

        dispatch(setscope3Category9Form({ evidence: response?.data?.path }));
        toast.success("Evidence uploded successfully", {
          theme: "dark",
        });
      }
      return response.data
    } catch (error) {
      console.error("Error uploading evidence", error);
      toast.error("uploading evidence failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const updatescope3Category9Data = createAsyncThunk(
  "scope3Category9/updatescope3Category9Data",
  async ({api,id}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    // const accessToken = getState().auth.accessToken;
    const scope3Category9Form = getState().scope3Category9.scope3Category9Form;
    // console.log(scope3Category9Form, id);
    const organization_id=getOrganizationId()
    try {
      const response = await api.put(
        `/scope3/category9/${organization_id}/data/${id}`,
        scope3Category9Form
     
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Record updated successfully", {
          theme: "dark",
        });
        dispatch(fetchscope3Category9InputData({api,params:{}}))
      }
    } catch (error) {
      console.error("Error updating data:", error);
      toast.error("Record update failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const deletescope3Category9Data = createAsyncThunk(
  "scope3Category9/deletescope3Category9Data",
  async ({api,id}, { _, dispatch }) => {
    dispatch(setIsLoading(true));
  
    const organization_id=getOrganizationId()
    try {
      const response = await api.delete(
        `/scope3/category9/${organization_id}/data/${id}`
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Record deleted successfully", {
          theme: "dark",
        });
        dispatch(setdeletes3c9Data(id))
      }
    } catch (error) {
      console.error("Error deleting data:", error);
      toast.error("Record delete failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
