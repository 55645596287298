// CompensatorySupportTable.js

import React, { useState } from "react";

const CompensatorySupportTable = ({ question }) => {
  const initialMeasures = [
    { id: "A", measure: "Employees", response: "Completed training" },
    { id: "B", measure: "Workers", response: "Rewarded in Q1" },
   
  ];

  const [measures, setMeasures] = useState(initialMeasures);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState("");

  // Handle input change for each row
  const handleInputChange = (id, value) => {
    setMeasures(
      measures.map((item) =>
        item.id === id ? { ...item, response: value } : item
      )
    );
  };

  // Toggle edit mode
  const toggleEdit = () => {
    setIsEditing(true);
    setError("");
  };

  // Handle submit for all responses
  const handleSubmit = () => {
    const allFilled = measures.every((measure) => measure.response.trim() !== "");

    if (allFilled) {
      console.log("Submitted responses:", measures);
      alert("Responses submitted successfully!");
      setIsEditing(false);
      setError("");
    } else {
      setError("Please fill out all fields before submitting.");
    }
  };

  // Handle cancel, which resets to initial state
  const handleCancel = () => {
    setMeasures(initialMeasures);
    setIsEditing(false);
    setError("");
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full border border-gray-300 text-sm text-gray-700">
        <thead className="bg-gray-100">
          <tr className="text-base font-normal px-2 py-1 bg-gray-300">
            <td colSpan={3} className="py-2 px-4 text-left font-semibold">
              {question}
            </td>
          </tr>
          <tr>
            {/* <th className="px-4 py-2 border border-gray-300 text-left  font-semibold">#</th> */}
            {/* <th className="px-4 py-2 border border-gray-300 text-left  font-semibold">Measure</th> */}
            {/* <th className="px-4 py-2 border border-gray-300 text-left bg-gray-100 font-semibold">Response</th> */}
          </tr>
        </thead>
        <tbody>
          {measures.map((measure) => (
            <>
            <tr key={measure.id}>
              <td className="px-4 py-2 border border-gray-300 text-center font-semibold">{measure.id}</td>
              <td className="px-4 py-2 border border-gray-300 font-semibold">{measure.measure}</td>
                
              </tr>
              <tr>
                <td></td>
              <td className="px-4 py-2 border border-gray-300">
                {isEditing ? (
                  <textarea
                  className="px-2 py-1 border border-gray-300 rounded w-full"
                    type="text"
                    value={measure.response}
                    onChange={(e) => handleInputChange(measure.id, e.target.value)}
                    placeholder="Please enter answer"
                    />
                  ) : (
                    <span className={`${
                      measure.response ? "" : "italic text-gray-400"
                      }`}>
                    {measure.response || "No response"}
                  </span>
                )}
              </td>
            </tr>
                </>
          ))}
        </tbody>
      </table>

      {error && (
        <p className="text-red-500 text-sm mt-2">{error}</p>
      )}

      <div className="mt-4 flex space-x-4">
        {!isEditing ? (
          <button
            onClick={toggleEdit}
            className="px-6 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          >
            Edit
          </button>
        ) : (
          <>
            <button
              onClick={handleSubmit}
              className="px-6 py-2 bg-green-500 text-white rounded hover:bg-green-600"
            >
              Submit
            </button>
            <button
              onClick={handleCancel}
              className="px-6 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
            >
              Cancel
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default CompensatorySupportTable;
