import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

const StackedBarChart = ({options,
  freeSize
}) => {

  return (
    <>
      
      <ReactECharts
        option={{ ...options }}
        style={{ height: freeSize?"300px":"500px", width: "100%" }}
      
        notMerge={true}
  lazyUpdate={true}
      />
    </>
  );
};

export default StackedBarChart;
