import React, { useState } from "react";

const GrievanceMechanismTable = ({question}) => {
  // Initial data for the table rows
  const initialData = [
    { id: 1, employeeType: "Permanent Workers", hasMechanism: null, details: "" },
    { id: 2, employeeType: "Other than Permanent Workers", hasMechanism: null, details: "" },
    { id: 3, employeeType: "Permanent Employees", hasMechanism: null, details: "" },
    { id: 4, employeeType: "Other than Permanent Employees", hasMechanism: null, details: "" },
  ];

  const [data, setData] = useState(initialData);

  // Handler to update radio selection and enable/disable details input
  const handleRadioChange = (id, value) => {
    setData((prevData) =>
      prevData.map((row) =>
        row.id === id ? { ...row, hasMechanism: value, details: value === "Yes" ? row.details : "" } : row
      )
    );
  };

  // Handler to update details input
  const handleDetailsChange = (id, value) => {
    setData((prevData) =>
      prevData.map((row) => (row.id === id ? { ...row, details: value } : row))
    );
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = data.every((row) => row.hasMechanism !== null && (row.hasMechanism === "No" || row.details));
    if (isValid) {
      // Submit form logic here
      console.log("Form data:", data);
      alert("Form submitted successfully!");
    } else {
      alert("Please fill all required fields.");
    }
  };

  return (
    <form onSubmit={handleSubmit} className=" w-full mx-auto">
      
      <table className="min-w-full bg-white border border-gray-300">
        <thead className="bg-gray-100 text-gray-700">
          <tr className="bg-gray-300">
            <th colSpan={3} className="border border-gray-300 px-4 py-2">{question}</th>
            
          </tr>
          <tr className="text-center ">
            <th className="border border-gray-300 px-4 py-2  w-1/5">Employee Type</th>
            <th className="border border-gray-300 px-4 py-2  w-1/5">Yes/No</th>
            <th className="border border-gray-300 px-4 py-2">Disclose Details</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id} className="border-b border-gray-300 text-sm text-gray-700">
              <td className="border border-gray-300 px-4 py-2 text-start">{row.employeeType}</td>
              <td className="border border-gray-300 px-4 py-2 text-center">
                <div className="flex justify-center items-center space-x-4">
                  <label className="flex items-center justify-center">
                    <input
                      type="radio"
                      name={`hasMechanism-${row.id}`}
                      value="Yes"
                      checked={row.hasMechanism === "Yes"}
                      onChange={() => handleRadioChange(row.id, "Yes")}
                      className="form-radio text-green-500"
                    />
                    <span className="ml-2">Yes</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name={`hasMechanism-${row.id}`}
                      value="No"
                      checked={row.hasMechanism === "No"}
                      onChange={() => handleRadioChange(row.id, "No")}
                      className="form-radio text-green-500"
                    />
                    <span className="ml-2">No</span>
                  </label>
                </div>
              </td>
              <td className="border border-gray-300 px-4 py-2 text-center">
                {row.hasMechanism === "Yes" && (
                  <textarea
                    type="text"
                    placeholder="Enter details"
                    value={row.details}
                    onChange={(e) => handleDetailsChange(row.id, e.target.value)}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full mt-1"
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4 text-center">
        <button
          type="submit"
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default GrievanceMechanismTable;
