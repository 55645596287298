import React, { useState } from 'react';
import GenericForm from './GenericForm';
import DataDisplay from '../../../pages/BRSR/BRSRContent/DataView/DataDisplay';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../customHooks/useApi';
import { postSocialRehabilitationInputs } from '../../../features/social/socialThunk';

const EmployeeRehabilitationPage = ({closePopup}) => {
    const [selectedTab, setSelectedTab] = useState('form');
    const [formData,setFormData]=useState({})
    const dispatch=useDispatch()
    const api=useApi()
    const {employementType,siteData,financialYearData}=useSelector(state=>state.social)
  const employeeRehabilitationConfig = {
    title: 'Employee Rehabilitation Form',
   siteOptions:siteData,
    financialYearOptions: financialYearData, 
    sections: [
      {
        key: 'employee_rehabilitation',
        label: 'Employee Type',
        rows: [
          { key: 'employees', label: 'Employees' },
          { key: 'workers', label: 'Workers' },
        ], 
        fields: [
          { key: 'affected', label: 'Number of Individuals Affected', type: 'number' },
          { key: 'rehabilitated', label: 'Number of Individuals/Family Members Rehabilitated or Placed in Suitable Employment', type: 'number' },
        ],
      },
    ],
  };
  function handleSubmitData(data) {
  dispatch(postSocialRehabilitationInputs({api,formData:data}))
    setFormData(data) 
    closePopup()
   
  }
  const renderTab = () => {
    switch (selectedTab) {
      case "form":
        return <GenericForm config={employeeRehabilitationConfig} handleSubmitData={handleSubmitData} />;
      case "display":
        return <DataDisplay config={employeeRehabilitationConfig} data={formData}/>;
      default:
        return null;
    }
  };
  return (
    <div className='h-full w-full p-2 px-6 overflow-auto'>
        {renderTab()}
    </div>
  );
};

export default EmployeeRehabilitationPage;
