import React, { useState } from 'react';
import { ReactComponent as RequestedIcon } from "../../../app/assets/PullRequestIcon.svg";

function RequestPanelPopUp({ functionality, module, sub_module, onSubmit,setShowPopup }) {
  const [selectedFunctionality, setSelectedFunctionality] = useState('');
  const [selectedModule, setSelectedModule] = useState('');
  const [selectedSubmodule, setSelectedSubmodule] = useState('');

  const handleFunctionalityChange = (event) => {
    setSelectedFunctionality(event.target.value);
  };

  const handleModuleChange = (event) => {
    setSelectedModule(event.target.value);
  };

  const handleSubmoduleChange = (event) => {
    setSelectedSubmodule(event.target.value);
  };

  const handleSubmit = () => {
    const requestData = {
      functionality: selectedFunctionality,
      module: selectedModule,
      sub_module: selectedSubmodule
    };
    onSubmit(requestData);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="relative bg-white rounded-lg min-w-[50%]">
        <div className="absolute top-0 right-0 m-4 cursor-pointer" onClick={()=>setShowPopup(false)}>
          <span className="text-gray-500 text-lg">×</span>
        </div>
        <h2 className="text-lg p-4 flex items-center justify-start space-x-4">Request Panel <RequestedIcon/></h2>
        <div className="mb-4 py-2 border-t p-8">
          <div className="mb-2">
            <label htmlFor="functionality" className="block text-sm font-medium text-gray-700 mb-2">Functionality</label>
            <select id="functionality" className="w-full border rounded p-2" value={selectedFunctionality} onChange={handleFunctionalityChange}>
              <option value="">Select Functionality</option>
              {functionality && functionality.map(fun => <option key={fun.id} value={fun.id}>{fun.functionality}</option>)}
            </select>
          </div>
          <div className="mb-2">
            <label htmlFor="module" className="block text-sm font-medium text-gray-700 mb-2">Module</label>
            <select id="module" className="w-full border rounded p-2" value={selectedModule} onChange={handleModuleChange}>
              <option value="">Select Module</option>
              {module && module.map(mod => <option key={mod.id} value={mod.id}>{mod.module}</option>)}
            </select>
          </div>
          <div className="mb-2">
            <label htmlFor="sub_module" className="block text-sm font-medium text-gray-700 mb-2">Submodule</label>
            <select id="sub_module" className="w-full border rounded p-2" value={selectedSubmodule} onChange={handleSubmoduleChange}>
              <option value="">Select Submodule</option>
              {sub_module && sub_module.map(subMod => <option key={subMod.id} value={subMod.id}>{subMod.sub_module}</option>)}
            </select>
          </div>
        </div>
        <div className="w-full px-8 p-4">
          <button className="bg-green-600 text-white rounded px-4 py-2" onClick={handleSubmit}>New Request</button>
        </div>
      </div>
    </div>
  );
}

export default RequestPanelPopUp;
