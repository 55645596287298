// app/store.js
import { configureStore } from "@reduxjs/toolkit";
import {thunk} from "redux-thunk"; // Import the 'thunk' function directly
import authReducer from "../features/auth/authSlice";
import commonReducer from "../features/common/commonSlice";
import fuelReducer from "../features/energy/fuel/fuelSlice";
import electricityReducer from "../features/energy/electricity/electricitySlice";
import chartSlice from "../pages/VisualizationFeature/Redux/chartSlice";
import analyzeCategory5Slice from "../pages/VisualizationFeature/Redux/category5/analyzeSlice";
import wasteSlice from "../pages/VisualizationFeature/Redux/waste/analyzeSlice";
import SettingSlices from "../pages/settings/Redux/SettingSlices";
import UserProfileReducer from "../pages/UserProfile/Redux/UserProfileSlices";
import Scope3Cat1Reducer from "../features/scope3/category1/Scope3Slice";
import scope3Category2Reducer from "../features/scope3/category2/Scope3Category2Slice";
import scope3Category4Reducer from "../features/scope3/category4/Scope3Category4Slice";
import scope3Category9Reducer from "../features/scope3/category9/Scope3Category9Slice";
import scope3Category6Reducer from "../features/scope3/category6/Scope3Category6Slice";
import scope3Category5Reducer from "../features/scope3/category5/Scope3Category5Slice";
import scope3Category7Reducer from "../features/scope3/category7/Scope3Category7Slice";
import SocialReducer from "../features/social/socialSlice"
import GeneralSlices from "../pages/GeneralInfo/Redux/GeneralSlices";
import BRSRSlices from "../pages/BRSR/Redux/BRSRSlices";
import { validationMiddleware } from "../middleware/validateMandetoryStepsMiddleware";


const store = configureStore({
  reducer: {
    auth: authReducer,
    fuel: fuelReducer,
    scope3: Scope3Cat1Reducer,
    scope3Category2: scope3Category2Reducer,
    scope3Category4: scope3Category4Reducer,
    scope3Category5: scope3Category5Reducer,
    scope3Category9: scope3Category9Reducer,
    scope3Category6: scope3Category6Reducer,
    scope3Category7: scope3Category7Reducer,
    social:SocialReducer,
    electricity: electricityReducer,
    common: commonReducer,
    BRSR: BRSRSlices,
    general:GeneralSlices,
    chart:chartSlice,
    analyzeCategory5:analyzeCategory5Slice,
    waste:wasteSlice,
    setting:SettingSlices,
    userProfile:UserProfileReducer
    // other reducers...
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(validationMiddleware,thunk),
});

export default store;
