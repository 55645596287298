import React from 'react';

function CommentPopup({ mainText, name, handleChange, onClose, onSubmit, isGranting }) {
  const popupBackground = isGranting ? 'bg-[#EEFFF9]' : 'bg-[#FFF6F6]';
  const submitButtonBackground = isGranting ? 'bg-green-500' : 'bg-red-500';

  return (
    <div className={`fixed z-10 inset-0 overflow-y-auto `}>
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div className={`inline-block align-bottom ${popupBackground} rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full`}>
        <div className="flex  justify-end items-center p-3 border-b border-solid border-gray-200 rounded-t">
            
            <button
              className="  ml-auto bg-transparent flex items-center justify-center text-center  text-gray-500 float-right text-2xl leading-none font-semibold outline-none focus:outline-none"
              onClick={onClose}
            >
              <span className="bg-transparent flex items-center justify-center text-center text-gray-500 h-6 w-6 text-xl  outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <div className={` ${popupBackground} px-4 pt-5 pb-4 sm:p-6 sm:pb-4`}>
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div className="mb-4">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    {mainText}
                  </h3>
                </div>
                <div className="mb-4">
                  <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
                    Comment
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="comment"
                      name="comment"
                      rows={5}
                      className="block p-2 w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      placeholder={`Enter your comment for ${name}...`}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={` ${popupBackground} px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse flex justify-start`}>
            <button
              type="button"
              className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 ${submitButtonBackground} text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
              onClick={onSubmit}
            >
              {isGranting?"Greant access":"Reject aceess"}
            </button>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommentPopup;
