import React from "react";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

const Table = ({ headings, dataRows, paginationObject, height="62.5vh", dashboard=true }) => {

  return (
    <>
      <div className="flex flex-col justify-center mb-4">
        <div className={`overflow-x-auto`} style={{height}}> 
        {/* 62.5 */}
          <table className="min-w-full mx-auto text-left text-sm font-light z-0 ">
            <TableHeader headings={headings} />
            <TableBody dataRows={dataRows} />
          </table>
         {dashboard&& <div className="h-24">

          </div>}
        </div>
      </div>
    </>
  );
};

export default Table;
