// services/api.js
import axios from "axios";
import { BASE_URL} from "../appconfig";
const accessToken=localStorage.getItem("accessToken")
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
    // Authorization: `Basic ${btoa(USERNAME + ":" + PASSWORD)}`,
  },
});

export default api;
