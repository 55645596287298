import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmPopup from "./ConfirmPopUp";
import {
  setadminDashboardForm,
  setIsCreatingNewRole,
} from "../../Redux/SettingSlices";
import { DataRepresentation } from "./DataPreview";
import { jwtDecode } from "jwt-decode";

const TabContentRoles = ({ handleSubmit, setIsCustom, actionType }) => {
  const dispatch = useDispatch();
  const { modules } = localStorage.getItem("accessToken")
    ? jwtDecode(localStorage.getItem("accessToken"))?.member?.access
    : {};

  const shouldEnableReporting = modules.some(
    (module) => module === -1 || module === 4 || module === 5
  );
  const { roles, adminDashboardForm, customRoles, isCreatingNewRole } =
    useSelector((state) => state.setting);
  const { isLoading } = useSelector((state) => state.common); // Loading state from Redux

  const [permissionsData, setPermissionsData] = useState(
    adminDashboardForm || {}
  );
  const [formData, setFormData] = useState({
    role_type_id: "",
    role_alias: "",
    custom_role_id: "",
  });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isRoleAliasRequired, setIsRoleAliasRequired] = useState(false);

  useEffect(() => {
    if (customRoles) {
      const selectedRole = customRoles.find(
        (role) => Number(role.id) === Number(adminDashboardForm.role_id)
      );
      initializeFormData(selectedRole);
      if (selectedRole) {
        updatePermissions(selectedRole);
      }
    }
  }, [customRoles]);

  useEffect(() => {
    dispatch(setIsCreatingNewRole(false));
  }, [formData.role_type_id]);

  useEffect(() => {
    setIsCustom(isCreatingNewRole);
  }, [isCreatingNewRole]);

  const initializeFormData = (selectedRole) => {
    setFormData({
      role_alias: adminDashboardForm.role_alias || "",
      role_type_id: adminDashboardForm.role_type_id || "",
      custom_role_id: selectedRole?.id || "",
    });
  };

  const updatePermissions = ({ permissions, role_alias, role_type_id, id }) => {
    setPermissionsData({ permissions, role_alias, role_type_id });
    // dispatch(setadminDashboardForm({ permissions, role_alias, role_type_id }));
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const deepClone = (obj) => JSON.parse(JSON.stringify(obj));
    const existingPermissions = {
      ...deepClone(adminDashboardForm.permissions),
    };
    if (!existingPermissions?.reporting) {
      existingPermissions.reporting = {
        modules: [-1],
        is_enabled: Number(value) === 5,
        read_only: false,
        submodules: [-1],
      };
    }

    if (Number(value) === 2) {
      //admin
      existingPermissions.admin.is_enabled = true;
      existingPermissions.sites = [-1];
    } else if (Number(value) === 3) {
      //data_entry

      existingPermissions.reporting.is_enabled = false;
      existingPermissions.data_entry.is_enabled = true;
      existingPermissions.data_entry.read_only = false;
      existingPermissions.reporting.read_only = false;
      existingPermissions.admin.is_enabled = false;
      existingPermissions.visualization.is_enabled = false;
      existingPermissions.auditing.is_enabled = false;
      existingPermissions.management.is_enabled = false;
      existingPermissions.management.read_only = false;
      existingPermissions.visualization.read_only = false;
    } else if (Number(value) === 4 || Number(value) === 5) {
      //manager-5   auditor-4
      existingPermissions.visualization.is_enabled = true;
      existingPermissions.reporting.is_enabled =
        Number(value) === 5 && shouldEnableReporting;
        existingPermissions.data_entry.read_only = false;
        existingPermissions.reporting.read_only = false;
      existingPermissions.data_entry.is_enabled = false;
      existingPermissions.admin.is_enabled = false;
      existingPermissions.auditing.is_enabled = false;
      existingPermissions.management.is_enabled = Number(value) === 5;
      existingPermissions.visualization.read_only = false;
    } else if (Number(value) === 6) {
      //viewer
      existingPermissions.visualization.is_enabled = true;
      existingPermissions.reporting.is_enabled = false;
      existingPermissions.data_entry.read_only = true;
      existingPermissions.data_entry.is_enabled = true;
      existingPermissions.admin.is_enabled = false;
      existingPermissions.auditing.is_enabled = false;
      existingPermissions.management.is_enabled = false;
    } else if (Number(value) === 11) {
      //custom role
      existingPermissions.visualization.is_enabled = true;
      existingPermissions.reporting.is_enabled = shouldEnableReporting;
      existingPermissions.data_entry.read_only = true;
      existingPermissions.data_entry.is_enabled = true;
      existingPermissions.admin.is_enabled = false;
      existingPermissions.auditing.is_enabled = false;
      existingPermissions.management.is_enabled = false;
    }
    dispatch(
      setadminDashboardForm({
        ...adminDashboardForm,
        [name]: value,
        permissions: existingPermissions,
      })
    );
    if (name === "role_alias" && value.trim() !== "") {
      setIsRoleAliasRequired(false);
    }
  };

  const handleCustomRoleSelection = (event) => {
    const { value } = event.target;
    if (value === "new") {
      handleNewRoleCreation();
    } else {
      handleExistingRoleSelection(value);
    }
  };

  const handleNewRoleCreation = () => {
    console.log("change new")
    dispatch(setIsCreatingNewRole(true));
    setIsRoleAliasRequired(true);
    setFormData((prevData) => ({
      ...prevData,
      custom_role_id: "",
      role_alias: "",
    }));
    dispatch(setadminDashboardForm({...adminDashboardForm,role_alias:"",role_id:""}))
  };


  const handleExistingRoleSelection = (value) => {
    const selectedRole = customRoles.find((role) => role.id == value);
console.log(selectedRole)
    if (selectedRole) {
      updatePermissions(selectedRole);

      dispatch(
        setadminDashboardForm({
          ...adminDashboardForm,
          permissions: selectedRole.permissions,
          role_alias: selectedRole.role_alias,
          role_id:value
        })
      );
    }
    dispatch(setIsCreatingNewRole(false));
    setIsRoleAliasRequired(false);
    setFormData((prevData) => ({
      ...prevData,
      custom_role_id: value,
      role_alias: selectedRole?.role_alias || "",
    }));
  };

  const togglePopup = () => {
    setIsPopupOpen((prevState) => !prevState);
  };

  const isSaveDisabled = () => {
    if (formData.role_type_id === "11") {
      if (isCreatingNewRole) {
        return formData.role_alias.trim() === "";
      } else {
        return formData.custom_role_id === "";
      }
    }
    return false;
  };
  useEffect(() => {

    dispatch(setadminDashboardForm({ role_id: formData.custom_role_id,role_alias:formData.role_alias }));
  }, [formData.custom_role_id]);
 
  return (
    <div className="flex flex-col justify-start w-full min-h-full overflow-auto">
      <div className="flex justify-start gap-5">
        {/* Role Selection */}
        <div className="mb-4">
          <label htmlFor="roleSelect" className="block mb-2 font-bold">
            Role:
          </label>
          <select
            id="roleSelect"
            name="role_type_id"
            className="block w-full p-2 border-gray-300 rounded-md shadow-sm focus:ring-green-300 focus:border-green-300"
            value={formData.role_type_id}
            onChange={handleFormChange}
          >
            <option value="">Choose the Role</option>
            {roles &&
              roles.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.role_name}
                </option>
              ))}
          </select>
        </div>

        {/* Custom Role Selection */}
        {formData.role_type_id === "11" && (
          <div className="mb-4">
            <label htmlFor="customRoleSelect" className="block mb-2">
              Custom Role (Optional)
            </label>
            {isLoading ? (
              <div>Loading custom roles...</div>
            ) : (
              <select
                id="customRoleSelect"
                name="custom_role_id"
                className="block w-full p-2 border-gray-300 rounded-md shadow-sm focus:ring-green-300 focus:border-green-300"
                value={formData.custom_role_id}
                onChange={handleCustomRoleSelection}
              >
                <option value="">Select Custom Role</option>
                {customRoles &&
                  customRoles.map((customRole) => (
                    <option key={customRole.id} value={customRole.id}>
                      {customRole.role_alias}
                    </option>
                  ))}
                <option value="new">Create New Custom Role</option>
              </select>
            )}

            {/* New Custom Role Input */}
            {isCreatingNewRole && (
              <div className="mt-4">
                <label htmlFor="role_alias" className="block mb-2">
                  New Custom Role Name
                </label>
                <input
                  type="text"
                  name="role_alias"
                  id="role_alias"
                  placeholder="Enter custom role name"
                  className="block w-full p-2 border-gray-300 rounded-md shadow-sm focus:ring-green-300 focus:border-green-300"
                  value={formData.role_alias}
                  onChange={handleFormChange}
                  required={isRoleAliasRequired}
                />
                {isRoleAliasRequired && formData.role_alias.trim() === "" && (
                  <span className="text-red-500 text-sm">
                    Custom role name is required
                  </span>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      {/* Display Permissions Data */}
      {!isCreatingNewRole && (
        <div className="overflow-auto">
          <DataRepresentation />
        </div>
      )}

      {/* Save Button */}
      <div className="w-full flex justify-center flex-1 items-center mt-5 absolute bottom-5">
        <button
          disabled={isSaveDisabled()}
          className={`bg-green-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 w-48 focus:ring-green-300 focus:ring-opacity-50 ${
            isSaveDisabled() ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={handleSubmit}
        >
          Save changes
        </button>
      </div>

      {/* Confirm Popup */}
      {isPopupOpen && (
        <ConfirmPopup
          mainHeading="Are you sure?"
          subHeading="Save the changes"
          buttonText1="Cancel"
          buttonText2="Save changes"
          onClose={togglePopup}
          onConfirm={togglePopup}
        />
      )}
    </div>
  );
};

export default TabContentRoles;
