import React, { useState } from 'react';
import {  useSelector } from 'react-redux';


const RetirementBenefitsForm = ({handleSubmitData}) => {
  const loading = useSelector((state) => state.common.isLoading);
  const {financialYearData}=useSelector(state=>state.social)
  const initialData = {
    financialYear: '',
    benefits: {
      pf: { employeesCovered: '', status: '' },
      gratuity: { employeesCovered: '', status: '' },
      esi: { employeesCovered: '', status: '' },
    },
  };

  const [formData, setFormData] = useState(initialData);

  const handleFinancialYearChange = (e) => {
    setFormData({ ...formData, financialYear: e.target.value });
  };

  const handleEmployeeCoveredChange = (benefitType, value) => {
    setFormData({
      ...formData,
      benefits: {
        ...formData.benefits,
        [benefitType]: {
          ...formData.benefits[benefitType],
          employeesCovered: Number(value),
        },
      },
    });
  };

  const handleStatusChange = (benefitType, value) => {
    setFormData({
      ...formData,
      benefits: {
        ...formData.benefits,
        [benefitType]: {
          ...formData.benefits[benefitType],
          status: value,
        },
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    handleSubmitData(formData);
  };

  return (
    <div className="w-full p-2 px-6 h-full">
      
      <form onSubmit={handleSubmit}>
        {/* Financial Year Input */}
        <div className="mb-6">
          <label className="block text-gray-700">Financial Year</label>
          <select
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
            value={formData.financialYear}
            required
            onChange={handleFinancialYearChange}
          >
            <option value="">Select financial year</option>
           {financialYearData.map((fY) => <option key={fY} value={fY}>{fY}</option>)}
           
           
          </select>
        </div>

        {/* Benefits Section */}
        <div className="mb-6">
          
          <div className="grid grid-cols-3 gap-4 font-semibold text-gray-700 mt-6">
            <div>Benefit Type</div>
            <div>Number of Employees Covered</div>
            <div>Deducted and Deposited with Authority</div>
          </div>

          {/* PF */}
          <div className="grid grid-cols-3 gap-4 mt-4">
            <div className="text-gray-700">PF</div>
            <input
            required
              type="number"
              className="bg-gray-200 text-neutral-700 text-xs py-1.5 px-1 border rounded w-full"
              value={formData.benefits.pf.employeesCovered}
              onChange={(e) => handleEmployeeCoveredChange('pf', e.target.value)}
            />
            <select
            required
              className="bg-gray-200 text-neutral-700 text-xs py-1.5 px-1 border rounded w-full"
              value={formData.benefits.pf.status}
              onChange={(e) => handleStatusChange('pf', e.target.value)}
            >
              <option value="">Select</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
              <option value="NA">NA</option>
            </select>
          </div>

          {/* Gratuity */}
          <div className="grid grid-cols-3 gap-4 mt-4">
            <div className="text-gray-700">Gratuity</div>
            <input
            required
              type="number"
              className="bg-gray-200 text-neutral-700 text-xs py-1.5 px-1 border rounded w-full"
              value={formData.benefits.gratuity.employeesCovered}
              onChange={(e) => handleEmployeeCoveredChange('gratuity', e.target.value)}
            />
            <select
            required
              className="bg-gray-200 text-neutral-700 text-xs py-1.5 px-1 border rounded w-full"
              value={formData.benefits.gratuity.status}
              onChange={(e) => handleStatusChange('gratuity', e.target.value)}
            >
              <option value="">Select</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
              <option value="NA">NA</option>
            </select>
          </div>

          {/* ESI */}
          <div className="grid grid-cols-3 gap-4 mt-4">
            <div className="text-gray-700">ESI</div>
            <input
            required
              type="number"
              className="bg-gray-200 text-neutral-700 text-xs py-1.5 px-1 border rounded w-full"
              value={formData.benefits.esi.employeesCovered}
              onChange={(e) => handleEmployeeCoveredChange('esi', e.target.value)}
            />
            <select
            required
              className="bg-gray-200 text-neutral-700 text-xs py-1.5 px-1 border rounded w-full"
              value={formData.benefits.esi.status}
              onChange={(e) => handleStatusChange('esi', e.target.value)}
            >
              <option value="">Select</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
              <option value="NA">NA</option>
            </select>
          </div>
        </div>

        {/* Submit Button */}
        <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
        <button
          type="submit"
          disabled={loading}
          className={`py-2 px-6 text-white text-sm ${
            loading ? 'bg-green-500 cursor-wait' : 'bg-green-600'
          } rounded-md`}
        >
          Save and Continue
        </button>
      </div>
      </form>
    </div>
  );
};

export default RetirementBenefitsForm;


