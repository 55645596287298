import React, { useEffect, useState } from "react";

import { ReactComponent as FilterMenuIcon } from "../../app/assets/FilterMenuIcon.svg";
import { ReactComponent as WasteIcon } from "../../app/assets/WasteProducedIcon.svg";
import Header from "../../components/common/Header";
import AnalyzeHeader from "../../components/Analyze/AnalyzeHeader";
import { useDispatch, useSelector } from "react-redux";
import { currentYearLastYearEmissionDetail } from "../VisualizationFeature/Redux/processData/ProcessDataforWaste";
import Filter from "../VisualizationFeature/components/Filter";
import useApi from "../../customHooks/useApi";
import WasteMain from "./WasteMain";
import { fetchWasteDataThunk } from "../VisualizationFeature/Redux/waste/analyzeThunk";
import { calculateAggregateWasteMetrics } from "../VisualizationFeature/Redux/processData/ProcessWaste";
function AnalyzeWaste() {
  const dispatch = useDispatch();
  const api = useApi();
  const [StackBreakDown] = useState(["waste_material", "treatment_mechanism"]);
  const {
    wasteTreatmentOptions,
    siteOptions,
    wasteMaterialOptions,
    data,
    dataForCurrentYearChange,
    financialYear,
  } = useSelector((state) => state.waste);
  const [currentFinancialYear, setCurrentFinancialYear] = useState(
    financialYear.length ? financialYear[0] : ""
  );
  const [wasteChange, setWasteChange] = useState({});
  const [chartDataArray] = useState([
    { title: "by Waste Material", dataKey: "waste_material" },
    {
      title: "by Treatment Mechanism",
      dataKey: "treatment_mechanism",
    },
  ]);
  const [fuelBreakDownOptions] = useState([
    { value: "site", label: "Facility" },
    { value: "waste_material", label: "Waste Material" },
    // { value: "treatment_mechanism", label: "Treatment Mechanism" },
  ]);
  const [filterBy, setFilterBy] = useState({
    site: "",
    waste_material: "",
    treatment_mechanism: "",
  });
  const handleFilterChange = (name, value) => {
    if (name === "financialYear") {
      setCurrentFinancialYear(value);
    } else {
      setFilterBy((prevFilterBy) => ({
        ...prevFilterBy,
        [name]: value,
      }));
    }
  };

  const emissionData = [
    {
      title: "Waste Produced",
      info: (wasteChange?.totalProduced?.toFixed() || 0) + " Tonnes",
      time: "",
      status:
        (wasteChange?.changes?.producedChange|| 0) > 0 ? "positive" : "negative",
      per: `${
        wasteChange?.changes?.producedChange!== Infinity
          ? `${Math.abs(wasteChange?.changes?.producedChange)}% YOY${
              wasteChange?.changes?.producedChange> 0 ? "↑" : "↓"
            }`
          : ""
      }`,
      year: currentFinancialYear,
      theme: "red",
    },

    // {
    //   title: "Waste produced intensity",
    //   info: "32",
    //   time: "",
    //   status: "positive",
    //   per: "25",
    //   theme: "green",
    // },
  ];
  
  const energyData = [ {
        status: (wasteChange?.changes?.recoveredPercentageChange || 0) > 0 ? "positive" : "negative",
        title: "Waste Recovered",
        info: `${wasteChange?.recoveredPercentage !== Infinity ? `${Math.abs(wasteChange?.recoveredPercentage)||0}%` : ""}`,
        percentage: `${wasteChange?.changes?.recoveredPercentageChange !== Infinity ?
           `${Math.abs(wasteChange?.changes?.recoveredPercentageChange)||0}% YOY${wasteChange?.changes?.recoveredPercentageChange > 0 ? "↑" : "↓"}`
            : ""}`
    },
    {
        status: (wasteChange?.changes?.treatedPercentageChange || 0) > 0 ? "positive" : "negative",
        title: "Waste Disposed",
        info: `${wasteChange?.treatedPercentage !== Infinity ? `${Math.abs(wasteChange?.treatedPercentage)||0}%` : ""}`,
        percentage: `${wasteChange?.changes?.treatedPercentageChange !== Infinity ?
           `${Math.abs(wasteChange?.changes?.treatedPercentageChange)||0}% YOY${wasteChange?.changes?.treatedPercentageChange > 0 ? "↑" : "↓"}`
            : ""}`
    }
   
];

  useEffect(() => {
    setWasteChange(
      calculateAggregateWasteMetrics(
        dataForCurrentYearChange,
        Number(currentFinancialYear)
      )
    );
  }, [data, dataForCurrentYearChange, currentFinancialYear]);
  useEffect(() => {
    dispatch(fetchWasteDataThunk({ api }));
  }, [dispatch]);
  useEffect(() => {
    if (financialYear.length > 0) {
      setCurrentFinancialYear(financialYear[0]);
    }
  }, [financialYear]);
  return (
    <>
      <Header PageIcon={WasteIcon} pageTitle={"Waste"} />
      <div className="flex flex-col main-container w-full px-10 py-6">
        <AnalyzeHeader emissionData={emissionData} energyData={energyData} />

        {/* filter */}

        <div className="flex flex-col  rounded-md mt-[18px]  w-full border p-2">
          <div className="flex justify-between py-2 items-center  bg-gray-100 rounded">
            <span className="text-xl px-2"></span>

            <div className="flex justify-between items-center gap-2 px-2">
              <FilterMenuIcon className="cursor-pointer" />
              <Filter
                label="Financial Year"
                options={financialYear}
                selectedValue={currentFinancialYear}
                onChange={(value) => handleFilterChange("financialYear", value)}
              />
              <Filter
                label="Facilty"
                options={siteOptions}
                selectedValue={filterBy.site}
                onChange={(value) => handleFilterChange("site", value)}
              />

              <Filter
                label="Waste material"
                options={wasteMaterialOptions}
                selectedValue={filterBy.waste_material}
                onChange={(value) =>
                  handleFilterChange("waste_material", value)
                }
              />

              <Filter
                label="Treatment mechanism"
                options={wasteTreatmentOptions}
                selectedValue={filterBy.treatment_mechanism}
                onChange={(value) =>
                  handleFilterChange("treatment_mechanism", value)
                }
              />
            </div>
          </div>
          <div className="border p-2 mt-2 bg-gray-100 rounded-md">
          <h2 className="text-xl font-bold my-4 w-full text-center">Waste Generated</h2>
          <div className="bg-white rounded-md">
            <WasteMain
              height={"500px"}
              width={"100%"}
              filterBy={filterBy}
              data={data}
              chartDataArray={[
                // { title: "by Waste Material", dataKey: "waste_material" }
               
              ]}
              Options={fuelBreakDownOptions}
              StackBreakDown={["waste_material"]}
              Filterkey={"produced_quantity"}
              currentFinancialYear={Number(currentFinancialYear)}
            />
          </div> 
          </div> 
          <div className="border p-2 mt-2 bg-gray-100 rounded-md">
          <h2 className="text-xl font-bold my-4 w-full text-center">Waste Recovered</h2>
          <div className="bg-white rounded-md">
            <WasteMain
              height={"500px"}
              width={"100%"}
              filterBy={filterBy}
              data={data}
              chartDataArray={chartDataArray}
              Options={fuelBreakDownOptions}
              StackBreakDown={StackBreakDown}
              Filterkey={"recovered_quantity"}
              currentFinancialYear={Number(currentFinancialYear)}
            />
          </div>
          </div>
          <div className="border p-2 mt-2 bg-gray-100 rounded-md">
          <h2 className="text-xl font-bold my-4 w-full text-center">Waste Disposed</h2>
          <div className="bg-white rounded-md">
            <WasteMain
              height={"500px"}
              width={"100%"}
              filterBy={filterBy}
              data={data}
              chartDataArray={chartDataArray}
              Options={fuelBreakDownOptions}
              StackBreakDown={StackBreakDown}
              Filterkey={"treated_quantity"}
              currentFinancialYear={Number(currentFinancialYear)}
            />
          </div>
          </div>
         
        </div>
      </div>
    </>
  );
}

export default AnalyzeWaste;
