import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const EmployeeCommuteForm = () => {
  const dispatch = useDispatch();

  const [employeeName, setEmployeeName] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [joinDate, setjoinDate] = useState("");
  const [description, setDescription] = useState("");
  const [distancePerDay, setDistancePerDay] = useState("");
  const [costPerDay, setCostPerDay] = useState("");
  const [pincode, setpincode] = useState("");
  const [site, setsite] = useState("");
  const [numberOfDays, setNumberOfDays] = useState("");
  const {
    
    siteData,
   
  } = useSelector((state) => state.scope3Category7);
  const [commuteEntries, setCommuteEntries] = useState([
    {
      mode_of_travel: "",
      type: "",
      subtype: "",
      fueltype: "",
      air_conditioned: "",
      site_id:"",
      pincode:""
    },
  ]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedCommuteEntries = [...commuteEntries];
    updatedCommuteEntries[index][name] = value;
    setCommuteEntries(updatedCommuteEntries);
  };

  const handleAddMore = () => {
    setCommuteEntries([
      ...commuteEntries,
      {
        mode_of_travel: "",
        type: "",
        subtype: "",
        fueltype: "",
        air_conditioned: "",
        costPerDay: "",
        distancePerDay: "",
        
      },
    ]);
  };

  const handleRemove = (index) => {
    const updatedCommuteEntries = commuteEntries.filter((_, i) => i !== index);
    setCommuteEntries(updatedCommuteEntries);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      employeeName,
      employeeId,
      description,
      distancePerDay,
      costPerDay,
      numberOfDays,
      commuteEntries,
      site,
      pincode
    };
    console.log(formData); // For testing: output formData to console
    // Example of dispatching an action with Redux
    // dispatch(submitEmployeeCommute(formData));

    // Reset form fields after submission
    setEmployeeName("");
    setEmployeeId("");
    setDescription("");
    setDistancePerDay("");
    setCostPerDay("");
    setNumberOfDays("");
    setCommuteEntries([
      {
       mode_of_travel: "",
      type: "",
      subtype: "",
      fueltype: "",
      air_conditioned: "",
      site_id:"",
      pincode:""
      },
    ]);
  };

  return (
    <div className="w-[60vw] mx-auto p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-2xl font-bold mb-4">Employee Commute Form</h1>

      <form onSubmit={handleSubmit}>
        <div className="w-full">
          <div className="mb-4">
            <label className="block text-gray-700">Name of Employee</label>
            <input
              type="text"
              value={employeeName}
              onChange={(e) => setEmployeeName(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Employee ID</label>
            <input
              type="text"
              value={employeeId}
              onChange={(e) => setEmployeeId(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">
              Joining Date 
            </label>
            <input
              type="date"
              value={joinDate}
              onChange={(e) => setEmployeeId(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>
          <div className="col-span-1 flex flex-col mb-4">
                <label htmlFor="pincode" className="block text-gray-700">
                Pincode travelling from  <span className="text-red-500">*</span>
                </label>
                <input
                
                  type="text"
                  name="pincode"
                  id="pincode"
                  value={pincode}
                  onChange={(e)=>setpincode(e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg"
                  required
                />
              </div>
              <div className="col-span-1 flex flex-col mb-4">
                <label htmlFor="site_id" className="block text-gray-700">
                Facility travelling to <span className="text-red-500">*</span>
                </label>
                <select
                  name="site_id"
                  value={site}
                  onChange={(e)=>setsite(e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg"
                  required
                >
                  <option value="">Choose the facility</option>
                  {siteData &&
                    siteData.map((site) => (
                      <option key={site.id} value={site.id}>
                        {site.site}
                      </option>
                    ))}
                </select>
              </div>
          {commuteEntries.map((entry, index) => (
            <>
              <h1>{index + 1}.</h1>
              <div
                key={index}
                className="mb-6 grid grid-cols-2 gap-4 items-center"
              >
                <div className="mb-4 w-full">
                  <label className="block text-gray-700">Mode of Commute</label>
                  <select
                    name="mode_of_travel"
                    value={entry.mode_of_travel}
                    onChange={(e) => handleInputChange(index, e)}
                    className="w-full px-3 py-2 border rounded-lg"
                    required
                  >
                    <option value="">Select Mode</option>
                    <option value="Air">Air</option>
                    <option value="Sea">Sea</option>
                    <option value="Road">Road</option>
                    <option value="Rail">Rail</option>
                  </select>
                </div>

                <div className="mb-4 w-full">
                  <label className="block text-gray-700">Transport Type</label>
                  <select
                    name="type"
                    value={entry.type}
                    onChange={(e) => handleInputChange(index, e)}
                    className="w-full px-3 py-2 border rounded-lg"
                    required
                  >
                    <option value="">Select Type</option>
                    <option value="Domestic">Domestic</option>
                    <option value="Short-haul">Short-haul</option>
                    <option value="Long-haul">Long-haul</option>
                    <option value="International">International</option>
                    <option value="Ferry">Ferry</option>
                    <option value="Car">Car</option>
                    <option value="Motorbike">Motorbike</option>
                    <option value="Taxis">Taxis</option>
                    <option value="Bus">Bus</option>
                    <option value="Train">Train</option>
                  </select>
                </div>

                <div className="mb-4 w-full">
                  <label className="block text-gray-700">
                    Transport Subtype
                  </label>
                  <select
                    name="subtype"
                    value={entry.subtype}
                    onChange={(e) => handleInputChange(index, e)}
                    className="w-full px-3 py-2 border rounded-lg"
                    required
                  >
                    <option value="">Select Subtype</option>
                    <option value="Average passenger">Average passenger</option>
                    <option value="Economy class">Economy class</option>
                    <option value="Business class">Business class</option>
                    <option value="Premium economy class">
                      Premium economy class
                    </option>
                    <option value="First class">First class</option>
                    <option value="Foot passenger">Foot passenger</option>
                    <option value="Car passenger">Car passenger</option>
                    <option value="Average (all passenger)">
                      Average (all passenger)
                    </option>
                    <option value="Small car">Small car</option>
                    <option value="Medium car">Medium car</option>
                    <option value="Large car">Large car</option>
                    <option value="Small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Regular taxi">Regular taxi</option>
                    <option value="Cab">Cab</option>
                    <option value="Average local bus">Average local bus</option>
                    <option value="Coach">Coach</option>
                    <option value="National rail">National rail</option>
                    <option value="International rail">
                      International rail
                    </option>
                    <option value="Light rail and tram">
                      Light rail and tram
                    </option>
                    <option value="Underground">Underground</option>
                  </select>
                </div>

                <div className="mb-4 w-full">
                  <label className="block text-gray-700">Fuel Type</label>
                  <select
                    name="fueltype"
                    value={entry.fueltype}
                    onChange={(e) => handleInputChange(index, e)}
                    className="w-full px-3 py-2 border rounded-lg"
                    required
                  >
                    <option value="">Select Fuel Type</option>
                    <option value="NA">NA</option>
                    <option value="Diesel">Diesel</option>
                    <option value="Petrol">Petrol</option>
                    <option value="Hybrid">Hybrid</option>
                    <option value="CNG">CNG</option>
                    <option value="LPG">LPG</option>
                    <option value="Unknown">Unknown</option>
                    <option value="Plug-in Hybrid Electric Vehicle">
                      Plug-in Hybrid Electric Vehicle
                    </option>
                    <option value="Battery Electric Vehicle">
                      Battery Electric Vehicle
                    </option>
                  </select>
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700">
                    Distance travelled per day (km)
                  </label>
                  <input
                    type="number"
                    value={entry.distancePerDay}
                    onChange={(e) => setDistancePerDay(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg"
                    required
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700">
                    Cost of travel per day (Rs)
                  </label>
                  <input
                    type="number"
                    value={entry.costPerDay}
                    onChange={(e) => setCostPerDay(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg"
                    required
                  />
                </div>

                {index > 0 && (
                  <div className="flex items-center justify-end mb-4 w-full">
                    <button
                      type="button"
                      onClick={() => handleRemove(index)}
                      className="px-5 py-2 text-white bg-red-500 rounded-lg"
                    >
                      Remove
                    </button>
                  </div>
                )}
              </div>
            </>
          ))}

          <div className="flex items-center justify-end mb-6 w-full">
            <button
              type="button"
              onClick={handleAddMore}
              className="px-5 py-2 text-white bg-green-500 rounded-lg justify-center items-center"
            >
              Add More
            </button>
          </div>
        </div>

        <div className="mb-6">
          {/* <div className="mb-4">
            <label className="block text-gray-700">Description (optional)</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg"
            ></textarea>
          </div> */}

          {/* <div className="mb-4">
            <label className="block text-gray-700">Distance Travelled per Day (km)</label>
            <input
              type="number"
              value={distancePerDay}
              onChange={(e) => setDistancePerDay(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Cost of travel per day (Rs)</label>
            <input
              type="number"
              value={costPerDay}
              onChange={(e) => setCostPerDay(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div> */}

          <div className="mb-4">
            <label className="block text-gray-700">
              Number of days per week
            </label>
            <input
              type="number"
              value={numberOfDays}
              onChange={(e) => setNumberOfDays(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-green-500 text-white py-2 rounded-lg hover:bg-green-600"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default EmployeeCommuteForm;
