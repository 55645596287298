// commonThunks.js
import { createAsyncThunk } from "@reduxjs/toolkit";
// import api from "../../services/api";
import { setIsLoading, setlogoPath } from "./commonSlice";
import { getOrganizationId } from "../../components/common/parseJwt";

export const downloadFileThunk = createAsyncThunk(
  "common/downloadFileThunk",
  async ({ filePath,api }, { getState, rejectWithValue }) => {
    try {
      const reqBody = {
        filePath: filePath
      }
      const accessToken = getState().auth.accessToken;
      const response = await api.post(`/service/download-file`, reqBody, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/octet-stream",
        },
      });

      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = filePath;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const fetchWhitelabelingData = createAsyncThunk(
  "common/fetchWhitelabelingData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id=getOrganizationId()
    let apiUrl = "/white-labeling/"+organization_id;

    try {
      const response = await api.get(`${apiUrl}`);
   
      if (response.status === 200 && response.data.success) {
        dispatch(setlogoPath(response.data.data));
          
      }
      return response.data
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
     
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);