import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  companyProfileForm: {},
  adminDashboardForm: {},
  emailForm: {},
  logoPath: "",
  roles: [],
  Allroles: [],
  isCreatingNewRole: false,
  customRoles: [],
  siteData: [],
  adminDashboardData: [],
  requestPanelData: [
    { user: "Amish", request: "category 1 (Electricity)", id: 1 },
    { user: "Chandra", request: "category 2 (Electricity)", id: 2 },
    { user: "User", request: "category 3 (Electricity)", id: 3 },
  ],
  country: [
    { id: 3, country: "Afghanistan", sortName: "AF" },
    { id: 2, country: "India", sortName: "IN" },
    { id: 1, country: "UnitedState", sortName: "US" },
  ],
  module: [
    { id: 1, module: "Scope 1" },
    { id: 2, module: "Scope 2" },
    { id: 3, module: "Scope 3" },
  ].map((i) => {
    return {
      label: i.module,
      value: i.id,
    };
  }),
  Reportingmodule: [
    { id: 4, module: "Custom Reports" },
    { id: 5, module: "Framework Based Reports" },
    // { id: 6, module: "Offset" },
    // { id: 7, module: "Reduce" },
  ].map((i) => {
    return {
      label: i.module,
      value: i.id,
    };
  }),
  filtredSubModules: [],
  filtredModules: [],
  subModule: [
    {
      id: 1,
      module: 1,
      sub_module: "Fuel",
      active: true,
    },
    {
      id: 2,
      module: 2,
      sub_module: "Electricity",
      active: true,
    },
    {
      id: 7,
      module: 3,
      sub_module: "Business Travel",
      active: true,
    },
    {
      id: 5,
      module: 3,
      sub_module: "Upstream Transportation and Distribution",
      active: true,
    },
    {
      id: 3,
      module: 3,
      sub_module: "Purchased Goods and Services",
      active: true,
    },
    {
      id: 4,
      module: 3,
      sub_module: "Capital Goods",
      active: true,
    },
    {
      id: 6,
      module: 3,
      sub_module: "Downstream Transportation and Distribution",
      active: true,
    },
    {
      id: 8,
      module: 3,
      sub_module: "Employee Commute",
      active: true,
    },
    {
      id: 9,
      module: 3,
      sub_module: "End-of-life treatment of sold products",
      active: true,
    },
    {
      id: 10,
      module: 3,
      sub_module: "Downstream leased assets",
      active: true,
    },
    {
      id: 11,
      module: 3,
      sub_module: "Fuel and Energy related activities",
      active: true,
    },
    {
      id: 12,
      module: 3,
      sub_module: "Upstream leased assets",
      active: true,
    },
    {
      id: 13,
      module: 3,
      sub_module: "Franchises",
      active: true,
    },
    {
      id: 14,
      module: 3,
      sub_module: "Waste from Operations",
      active: true,
    },
  ]
    .sort((a, b) => a.id - b.id)
    .map((i) => {
      return {
        label: i.sub_module,
        value: i.id,
        module: i.module,
      };
    }),
  ReportingsubModule: [
    {
      id: 9,
      module: 4,
      sub_module: "Custom Report",
      active: true,
    },
    {
      id: 10,
      module: 4,
      sub_module: "Green Report",
      active: true,
    },
    {
      id: 11,
      module: 5,
      sub_module: "GRI",
      active: true,
    },
    {
      id: 12,
      module: 5,
      sub_module: "BRSR",
      active: true,
    },
    {
      id: 13,
      module: 5,
      sub_module: "CDP",
      active: true,
    },
  ]
    .sort((a, b) => a.id - b.id)
    .map((i) => {
      return {
        label: i.sub_module,
        value: i.id,
        module: i.module,
      };
    }),
  itemsPerPage: 10,
  totalPages: 1,
  currentPage: 1,
  totalCount: 10,
  CompanyProfile: {},
};

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setCompanyProfileForm(state, action) {
      return {
        ...state,
        companyProfileForm: {
          ...state.companyProfileForm,
          ...action.payload,
        },
      };
    },
    setemailForm(state, action) {
      return {
        ...state,
        emailForm: {
          ...state.emailForm,
          ...action.payload,
        },
      };
    },
    // setadminDashboardForm(state, action) {
    //   console.log(state.adminDashboardForm,action.payload)

    //   return {
    //     ...state,
    //     adminDashboardForm: {
    //       ...state.adminDashboardForm,
    //       ...action.payload,
    //     },
    //   };
    // },
    setadminDashboardForm(state, action) {
      const {
        userName,
        user_id,
        email,
        role_alias,
        role_type_id,
        role_id,
        permissions,
        firstName,
        lastName,
        password,
        confirm_password,
      } = action.payload;

      if (
        userName ||
        user_id ||
        email ||
        role_alias ||
        role_type_id ||
        firstName ||
        lastName ||
        password ||
        confirm_password ||
        role_id
      ) {
        state.adminDashboardForm = {
          ...state.adminDashboardForm,
          userName: userName || state.adminDashboardForm.userName,
          user_id: user_id || state.adminDashboardForm.user_id,
          firstName: firstName || state.adminDashboardForm.firstName,
          lastName: lastName || state.adminDashboardForm.lastName,
          password: password || state.adminDashboardForm.password,
          confirm_password:
            confirm_password || state.adminDashboardForm.confirm_password,
          email: email || state.adminDashboardForm.email,
          role_alias: role_alias ,
          role_type_id: role_type_id || state.adminDashboardForm.role_type_id,
          role_id: role_id ,
        };
      }

      if (permissions) {
        state.adminDashboardForm.permissions = {
          ...state.adminDashboardForm.permissions,
          ...permissions,
        };
      }
    },

    setAdminDashboardData(state, action) {
      state.adminDashboardData = action.payload;
    },
    setRequestPanelData(state, action) {
      state.requestPanelData = action.payload;
    },
    setfiltredSubModules(state, action) {
      state.filtredSubModules = action.payload;
    },
    setfiltredModules(state, action) {
      state.filtredModules = action.payload;
    },
    setSubModules(state, action) {
      state.subModule = action.payload;
      // state.ReportingsubModule=action.payload.filter(i=>[4,5,-1].includes(Number(i.module)))
    },
    setModules(state, action) {
      state.module = action.payload;
      // state.Reportingmodule=action.payload.filter(i=>[4,5,-1].includes(i.id))
    },
    setsiteData(state, action) {
      state.siteData = action.payload;
    },
    resetCompanyProfileForm(state, action) {
      state.companyProfileForm = {};
    },
    resetAdminDashboardData(state, action) {
      state.adminDashboardForm = {};
    },
    setCompanyProfile(state, action) {
      state.companyProfileForm = action.payload;
      state.CompanyProfile = action.payload;
    },
    setrole(state, action) {
      state.roles = action.payload;
    },
    setAllrole(state, action) {
      state.Allroles = action.payload;
    },
    setIsCreatingNewRole(state, action) {
      state.isCreatingNewRole = action.payload;
    },
    setcustomRoles(state, action) {
      state.customRoles = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload;
    },
  },
});

export const {
  setAdminDashboardData,
  setCompanyProfile,
  setadminDashboardForm,
  setCompanyProfileForm,
  setcustomRoles,
  setrole,
  setRequestPanelData,
  setsiteData,
  resetCompanyProfileForm,
  setCurrentPage,
  setemailForm,
  setItemsPerPage,
  setIsCreatingNewRole,
  setTotalCount,
  resetAdminDashboardData,
  setTotalPages,
  setfiltredModules,
  setfiltredSubModules,
  setModules,
  setSubModules,
  setAllrole,
} = settingSlice.actions;
export default settingSlice.reducer;
