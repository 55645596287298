import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { setadminDashboardForm, setfiltredModules, setfiltredSubModules } from "../../Redux/SettingSlices";
import { useDispatch, useSelector } from "react-redux";
import { createMember, updateMember } from "../../Redux/SettingThunk";
import useApi from "../../../../customHooks/useApi";
import TabContent from "./TabContent";
import TabContentForMainModules from "./TabContentForMainModules";

const TabContentForSubModule = ({
  handleSubmit,
  actionType,
  roleTypeId,onClose,
  activeTab,
}) => {
  const api=useApi()
  const dispatch = useDispatch();
  const { module, subModule, adminDashboardForm } = useSelector(
    (state) => state.setting
  );

  const [selectedEnergy, setSelectedEnergy] = useState([]);
  const [selectedScope3, setSelectedScope3] = useState([]);
  async function handleMemberCreation() {
    if (Number(roleTypeId) === 6) {
      handleSubmit();
      return;
    }
    if (actionType === "newEntry") {
      await dispatch(createMember({ api }));
      onClose()
    } else {
      await dispatch(updateMember({ api }));
      onClose()
    }
    handleSubmit();
  }

  // Handle Data In save action
  const handleDataInSave = (data) => {


    dispatch(
      setadminDashboardForm({
        permissions: {
          ...adminDashboardForm.permissions,
          data,
        },
      })
    );

    if (
      adminDashboardForm?.role_type_id &&
      adminDashboardForm?.permissions?.sites?.length > 0 &&
      adminDashboardForm?.permissions?.modules?.length > 0 &&
      adminDashboardForm?.permissions?.submodules?.length > 0 &&
      data?.modules?.length > 0 &&
      data?.submodules?.length > 0
    ) {
      handleMemberCreation();
    } else {
      toast.error("Please fill all required tabs details", { theme: "dark" });
    }
  };

  useEffect(() => {
    if (module.length > 0 && subModule.length > 0) {
      const existingData = adminDashboardForm?.permissions || {};

      const initializeSelectedModules = module.filter((i) =>
        existingData.modules?.includes(-1)
          ? true
          : existingData.modules?.includes(Number(i.value))
      );
      const initializeSelectedSubmodules = subModule.filter((i) =>
        existingData.submodules?.includes(-1)
          ? true
          : existingData.submodules?.includes(Number(i.value))
      );

      setSelectedEnergy(initializeSelectedModules);
      setSelectedScope3(initializeSelectedSubmodules);
      dispatch(setfiltredModules(initializeSelectedModules))
      dispatch(setfiltredSubModules(initializeSelectedSubmodules))
      // Update the form state with the existing data
      if (actionType !== "newEntry") {
        dispatch(
          setadminDashboardForm({
            permissions: {
              ...adminDashboardForm.permissions,
            
                modules:
                  initializeSelectedModules.length === module.length
                    ? [-1]
                    : initializeSelectedModules.map((i) => i.value),
                submodules:
                  initializeSelectedSubmodules.length === subModule.length
                    ? [-1]
                    : initializeSelectedSubmodules.map((i) => i.value),
               
               
              },
            
          })
        );
      }
    }
  }, [module, subModule, actionType, dispatch]);
  useEffect(() => {
    dispatch(setfiltredModules(selectedEnergy))
    dispatch(setfiltredSubModules(selectedScope3))
    const data = {
      modules: selectedEnergy.map((i) => i.value),
      submodules: selectedScope3.map((i) => i.value),
    };
  

    dispatch(
      setadminDashboardForm({
        permissions: {
          ...adminDashboardForm.permissions,
           ...data,
           management: {...adminDashboardForm.permissions.management,},
           visualization: {...adminDashboardForm.permissions.visualization,},
           data_entry:{...adminDashboardForm.permissions.data_entry,}

        },
      })
    );
  }, [selectedEnergy, selectedScope3]);
  return (
    <div className="flex flex-col justify-start w-full min-h-full overflow-auto">
      <div className="mb-2">
    
      <TabContentForMainModules
        activeTab={activeTab}
        roleTypeId={roleTypeId}
        selectedEnergy={selectedEnergy}
        setSelectedEnergy={setSelectedEnergy}
        selectedScope3={selectedScope3}
        setSelectedScope3={setSelectedScope3}
        
        energyOptions={module}
        scope3Options={subModule}
        onSave={handleDataInSave}
        handleSubmit={handleSubmit}
      />
    </div>  
    </div>
  );
};

export default TabContentForSubModule;
