
import { useDispatch, useSelector } from "react-redux";
import TabContent from "./TabContent";
import { setadminDashboardForm, setfiltredModules, setfiltredSubModules } from "../../Redux/SettingSlices";
import { useEffect, useState } from "react";
import { createMember, updateMember } from "../../Redux/SettingThunk";
import useApi from "../../../../customHooks/useApi";
import { toast } from "react-toastify";

const TabContentDataIn = ({
  handleSubmit,
  actionType,
  roleTypeId,
  activeTab,onClose,
}) => {
  const api = useApi();
  const dispatch = useDispatch();
  const { module, subModule, adminDashboardForm } = useSelector(
    (state) => state.setting
  );
  const [isEnabled, setIsEnabled] = useState( false);
  const [isReadOnly, setIsReadOnly] = useState( false);
  const [selectedEnergy, setSelectedEnergy] = useState([]);
  const [selectedScope3, setSelectedScope3] = useState([]);

  const additionalDataOptions = [
    { label: "Water and Affluents", value: "water and affluents" },
    { label: "Waste", value: "waste" },
  ];

  // Function to handle member creation or update
  async function handleMemberCreation() {
    if (Number(roleTypeId) === 6) {
      handleSubmit();
      return;
    }
    if (actionType === "newEntry") {
      await dispatch(createMember({ api }));
   
    } else {
      await dispatch(updateMember({ api }));

    }
    handleSubmit();
    onClose()  }

  // Handle Data In save action
  const handleDataInSave = (data) => {
    const updatedData = {
      ...data,
      // read_only: Number(roleTypeId) === 6,
      // is_enabled:true
    };

    dispatch(
      setadminDashboardForm({
        permissions: {
          ...adminDashboardForm.permissions,
          data_entry: updatedData,
        },
      })
    );

    if (
      adminDashboardForm?.role_type_id &&
      adminDashboardForm?.permissions?.sites?.length > 0 &&
      adminDashboardForm?.permissions?.modules?.length > 0 &&
      adminDashboardForm?.permissions?.submodules?.length > 0 &&
      data?.modules?.length > 0 &&
      data?.submodules?.length > 0
    ) {
      handleMemberCreation();
    } else {
      toast.error("Please fill all required tabs details", { theme: "dark" });
    }
  };

  useEffect(() => {
    if (module.length > 0 && subModule.length > 0) {
      const existingData = adminDashboardForm?.permissions?.data_entry || {};

      const initializeSelectedModules = module.filter((i) =>
        existingData.modules?.includes(-1)
          ? true
          : existingData.modules?.includes(Number(i.value))
      );
      const initializeSelectedSubmodules = subModule.filter((i) =>
        existingData.submodules?.includes(-1)
          ? true
          : existingData.submodules?.includes(Number(i.value))
      );

      setSelectedEnergy(initializeSelectedModules);
      setSelectedScope3(initializeSelectedSubmodules);
      // dispatch(setfiltredModules(initializeSelectedModules))
      // dispatch(setfiltredSubModules(initializeSelectedSubmodules))
      // Update the form state with the existing data
      if (actionType !== "newEntry") {
        dispatch(
          setadminDashboardForm({
            permissions: {
              ...adminDashboardForm.permissions,
              data_entry: {
                modules: initializeSelectedModules.map((i) => i.value),
                submodules: initializeSelectedSubmodules.map((i) => i.value),
                is_enabled: existingData.is_enabled,
                read_only: existingData.read_only || false,
              },
            },
          })
        );
      }
    }
  }, [module, subModule, actionType, dispatch]);
  useEffect(() => {
    const data = {
      modules: selectedEnergy.map((i) => i.value),
      submodules: selectedScope3.map((i) => i.value),
      is_enabled:isEnabled,
      read_only:Number(roleTypeId) === 6||isReadOnly
    };
    // console.log(data);

    dispatch(
      setadminDashboardForm({
        permissions: {
          ...adminDashboardForm.permissions,
          data_entry: data,
        },
      })
    );
  }, [selectedEnergy, selectedScope3,isEnabled,isReadOnly]);
  return (
    <div className="flex flex-col justify-start w-full min-h-full overflow-auto">
      <TabContent
        isEnabled={isEnabled}
        setIsEnabled={setIsEnabled}
        isReadOnly={isReadOnly}
        setIsReadOnly={setIsReadOnly}
        actionType={actionType}
        activeTab={activeTab}
        roleTypeId={roleTypeId}
        selectedEnergy={selectedEnergy}
        setSelectedEnergy={setSelectedEnergy}
        selectedScope3={selectedScope3}
        setSelectedScope3={setSelectedScope3}
        initialAdditionalData={{
          "water and affluents": false,
          waste: false,
        }}
        initialIsEnabled={
          adminDashboardForm?.permissions?.data_entry?.is_enabled
        }
        initialIsReadonly={
          adminDashboardForm?.permissions?.data_entry?.read_only || false
        }
        additionalDataOptions={additionalDataOptions}
        energyOptions={module}
        scope3Options={subModule}
        onSave={handleDataInSave}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default TabContentDataIn;
