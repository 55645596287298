import React from 'react'
import Header from '../../components/common/Header'
import { ReactComponent as ReportIcon } from "../../app/assets/ReportingIcon.svg";
function CustomReports() {
  return (
    <div>
      <Header pageTitle={"Custom reports"} PageIcon={ReportIcon}/>
    </div>
  )
}

export default CustomReports
