import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  siteDetailsData: [
   
  ],
  ownershipsStatusData: [
    {
      id: 1,
      ownership_status: "Leased",
    },
    {
      id: 2,

      ownership_status: "Rented-Standalone",
    },
    {
      id: 3,

      ownership_status: "Rented-Shared Space",
    },
    {
      id: 4,

      ownership_status: "Rented-Co Working Space",
    },
    {
      id: 5,

      ownership_status: "Self owned",
    },
  ],
  siteTypeData: [
    {
      id: 1,
      site_type: "Plant",
    },
    {
      id: 2,
      site_type: "Factory",
    },
    {
      id: 3,
      site_type: "Office",
    },
    {
      id: 4,
      site_type: "Warehouse",
    },
  ],
  PurchasedGoodsandServicesData: [],
  CapitalGoodsData: [],
  revenueData: [
    {
      month: "Jan",
      quarter: "1",
      year: 2023,
      business_unit: "Beauty & Wellbeing",
      product_id: "34562",
      product: "Shampoo",
      nic_code_d: "28441",
      revenue: 200,
    },
  ],
  employeeInclusionsData: [
    {
      type: "Permanent Employee",
      male: 150,
      female: 120,
      other_gender: 5,
      total_employees: 275,
    },
  ],
  subsidiaryAndAssociatedCompanyData: [
    {
      name_of_entity: "XYZ Holding Company",
      entity_type: "Holding",
      percent_share_held: 100,
      industry: "Investment",
      industry_eeio_emission_factor: 0.25,
      participant_in_decisionmaking: "Yes",
      considered_in_current_years_reporting: "Yes",
    },
  ],
  formData: {},
  SupplyChainInsertDataForm: {},
  productionTypeData: [
    { id: 1, production_type: "Closed Loop" },
    { id: 2, production_type: "Re-Used" },
    { id: 3, production_type: "Primary Material Production" },
  ],
  PurchasedGoodsandServicesItemData: [],
  CapitalGoodsItemData: [],
  unitData: [],
  supplierTier: [
    { id: 1, supplier_tier: "1" },
    { id: 2, supplier_tier: "2" },
    { id: 3, supplier_tier: "3" },
  ],
  itemsPerPage: 10,
  totalPages: 1,
  currentPage: 1,
  totalCount: 10,
};

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setsiteDetailsData(state, action) {
      state.siteDetailsData = action.payload;
    },
    setPurchasedGoodsandServicesData(state, action) {
      state.PurchasedGoodsandServicesData = action.payload;
    },
    setCapitalGoodsItemData(state, action) {
      state.CapitalGoodsItemData = action.payload;
    },
    setCapitalGoodsData(state, action) {
      state.CapitalGoodsData = action.payload;
    },
    setPurchasedGoodsandServicesItemData(state, action) {
      state.PurchasedGoodsandServicesItemData = action.payload;
    },
    setrevenueData(state, action) {
      state.revenueData = action.payload;
    },
    setownershipsStatusData(state, action) {
      state.ownershipsStatusData = action.payload;
    },
    setsiteTypeData(state, action) {
      state.siteTypeData = action.payload;
    },
    setunitData(state, action) {
      state.unitData = action.payload;
    },
    setformData: (state, action) => {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      };
    },
    setemployeeInclusionsData(state, action) {
      state.employeeInclusionsData = action.payload;
    },
    setsubsidiaryAndAssociatedCompanyData(state, action) {
      state.subsidiaryAndAssociatedCompanyData = action.payload;
    },

    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload;
    },
    resetFormData: (state, action) => {
      
      state.formData = {};
    
    },
  },
});

export const {
  setsiteDetailsData,
  setownershipsStatusData,
  setsiteTypeData,
  setCapitalGoodsItemData,
  setPurchasedGoodsandServicesItemData,
  setPurchasedGoodsandServicesData,
  setemployeeInclusionsData,
  setrevenueData,
  resetFormData,
  setformData,
  setCapitalGoodsData,
  setCurrentPage,
  setItemsPerPage,
  setTotalCount,
  setsubsidiaryAndAssociatedCompanyData,
  setTotalPages,
  setunitData,
} = generalSlice.actions;
export default generalSlice.reducer;
