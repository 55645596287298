import { createAsyncThunk } from "@reduxjs/toolkit";
import { setIsLoading } from "../../../features/common/commonSlice";
import {
  setAdminDashboardData,
  setAllrole,
  setCompanyProfile,
  setcustomRoles,
  setModules,
  setRequestPanelData,
  setrole,
  setsiteData,
  setSubModules,
} from "./SettingSlices";
// import api from "../../../services/api";
import { toast } from "react-toastify";
import {
  getOrganizationId,
  parseJwt,
} from "../../../components/common/parseJwt";
import { USER_MANAGEMENT_URL } from "../../../appconfig";
import axios from "axios";

export const updateCompanyProfile = createAsyncThunk(
  "setting/updateCompanyProfile",
  async ({ id, data, api, step_id }, { dispatch, getState }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await api.put(`/company-profile/${id}`, {
        step_id,
        name: data?.company_name || null,
        logo_path: data?.logo_path || null,
        corporate_office: data?.corporate_office || null,
        country_of_registration: data?.country_of_registration || null,
        fiscal_year: data?.fiscal_year || null,
        industry: data?.industry || null,
        listed_year: data?.listed_year || null,
        number_of_employee: data?.number_of_employee || null,
        registration_number: data?.registration_number || null,
        stock_exchange: data?.stock_exchange || null,
        website: data?.website || null,
        year_of_incorporation: data?.year_of_incorporation || null,
      });

      if (response.data.success) {
        toast.success("Profile updated successfully");
      } else {
        throw new Error(response.data.message || "Failed to update profile");
      }
    } catch (error) {
      // Handle update failure
      console.error("Update failed:", error);
      toast.error("Failed to update profile: " + error.message);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchSiteData = createAsyncThunk(
  "setting/fetchSiteData",
  async ({ api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id = getOrganizationId();
    try {
      const response = await api.get(`/energy/fuel/${organization_id}/sites`);

      //   set all data fuelREcords, pageDetails, totalCount etc.

      if (response.status === 200 && response.data.success) {
        dispatch(setsiteData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors

      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
// http://localhost:8080/api/v1/company-profile/1
export const fetchCompanyProfile = createAsyncThunk(
  "setting/fetchCompanyProfile",
  async ({ api }, { dispatch }) => {
    const accessToken = localStorage.getItem("accessToken");
    const payload = parseJwt(accessToken);
    const { user } = payload;
    // console.log(payload)
    dispatch(setIsLoading(true));
    try {
      // Call the login API endpoint with Companyname and password
      const response = await api.get("/company-profile/" + user.id);

      const data = response?.data?.data;
      // console.log(data)
      dispatch(
        setCompanyProfile({
          ...data,
          company_name: data["organization.name"],
          logo_path: data?.whitelabllingdata?.logo_path,
        })
      );
    } catch (error) {
      // Handle login failure
      console.error(" failed:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchRole = createAsyncThunk(
  "setting/fetchRole",
  async ({ api }, { dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      // Call the login API endpoint with Companyname and password
      const response = await api.get("/role");

      const data = response?.data?.data;
      const allRolesData = response?.data?.allRoles;
      // console.log(data)
      dispatch(
        setrole(
          data.filter(
            (i) => i.role_name !== "Super Admin" && i.role_name !== "Admin"
          )
        )
      );
      dispatch(
        setAllrole(
          allRolesData.filter(
            (i) =>
              i.role_alias !== "Super Admin" &&
              i.role_alias !== "Admin" &&
              i.role_alias !== "Owner" &&
              i.role_type_id != 2&&i.role_type_id!=1
          )
        )
      );
    } catch (error) {
      // Handle login failure
      console.error(" failed:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const createComapnyProfile = createAsyncThunk(
  "setting/createPassword",
  async ({ data, api }, { dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      // Call the login API endpoint with Companyname and password
      const response = await api.post("/create-comapny-profile", data);

      if (response.success) {
        toast("Profile created successfully");
      }
    } catch (error) {
      // Handle login failure
      console.error(" failed:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const createMember = createAsyncThunk(
  "setting/createMember",
  async ({ api }, { dispatch, getState }) => {
    const accessToken = localStorage.getItem("accessToken");
    const data = getState().setting.adminDashboardForm;

    dispatch(setIsLoading(true));
    // https://user-management-be-xwyd.onrender.com/api/v1/member/create

    try {
      const response = await axios.post(
        `${USER_MANAGEMENT_URL}/member/create`,
        { ...data, is_active: true, role_id: data.role_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      // console.log(response)
      if (response.data.success) {
        toast.success("Member created successfully");
        dispatch(fetchAdminDashboardData({ api }));
      }
    } catch (error) {
      // Handle login failure
      console.error(" failed:", error);
      toast.error(error?.response?.data?.error || "failed", { theme: "dark" });
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const updateMember = createAsyncThunk(
  "setting/updateMember",
  async ({ api }, { dispatch, getState }) => {
    const accessToken = localStorage.getItem("accessToken");
    const data = getState().setting.adminDashboardForm;
    function removeFalseyValues(obj) {
      return Object.fromEntries(
        Object.entries(obj).filter(([key, value]) => Boolean(value))
      );
    }

    dispatch(setIsLoading(true));

    try {
      const response = await axios.post(
        `${USER_MANAGEMENT_URL}/member/update-permissions`,
        removeFalseyValues({
          ...data,
          userId: data.user_id,
          // role_id: data.role_id,
          role_alias:data.role_type_id==11?data.role_alias:"",
          role_id:data.role_type_id==11?data.role_id:""
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      // console.log(response);
      if (response.data.success) {
        toast.success(
          response?.data?.data?.message || "Member updated successfully"
        );
        dispatch(fetchAdminDashboardData({ api }));
      }
    } catch (error) {
      // Handle login failure
      console.error(" failed:", error);
      toast.error(error?.response?.data?.error || "failed");
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const deleteMember = createAsyncThunk(
  "setting/deleteMember",
  async ({ api, id }, { dispatch, getState }) => {
    dispatch(setIsLoading(true));

    try {
      const response = await api.delete(`/member/${id}`);
      // console.log(response);
      if (response.data.success) {
        toast.success(
          response?.data?.data?.message || "Member deleted successfully"
        );
        dispatch(fetchAdminDashboardData({ api }));
      }
    } catch (error) {
      // Handle login failure
      console.error(" failed:", error);
      toast.error(error?.response?.data?.error || "failed");
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchAdminDashboardData = createAsyncThunk(
  "setting/fetchAdminDashboardData",
  async ({ api }, { getState, dispatch }) => {
    const accessToken = getState().auth.accessToken;

    let apiUrl = "/members";

    try {
      dispatch(setIsLoading(true));
      const response = await api.get(`${apiUrl}`);

      //   set all data fuelREcords, pageDetails, totalCount etc.
console.log(response)
      if (response.status === 200 && response.data.success) {
        dispatch(
          setAdminDashboardData(
            response?.data?.data.filter((i) => i.role_type_id != 2)
          )
        );
        // dispatch(setCurrentPage(response.data.currentPage));
        // dispatch(setItemsPerPage(response.data.itemsPerPage));
        // dispatch(setTotalPages(response.data.totalPages));
        // dispatch(setTotalCount(response.data.totalCount));
        dispatch(setIsLoading(false));
      }

      return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchCustomRoles = createAsyncThunk(
  "setting/fetchCustomRoles",
  async ({ api }, { getState, dispatch }) => {
    let apiUrl = "/custom-roles";

    try {
      dispatch(setIsLoading(true));
      const response = await api.get(`${apiUrl}`);

      // console.log(response);
      if (response.status === 200 && response.data.success) {
        dispatch(setcustomRoles(response.data.data));

        dispatch(setIsLoading(false));
        // console.log(response);
      }

      return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchModule = createAsyncThunk(
  "setting/fetchModule",
  async ({ api }, { getState, dispatch }) => {
    let apiUrl = "/modules";

    try {
      dispatch(setIsLoading(true));
      const response = await api.get(`${apiUrl}`);

      // console.log(response);
      if (response.status === 200 && response.data.success) {
        dispatch(
          setModules(
            response.data.data
              .map((i) => {
                return {
                  label: i.module,
                  value: i.id,
                };
              })
              .filter((i) => ![6, 7, 4, 5].includes(Number(i.value)))
          )
        );

        dispatch(setIsLoading(false));
      }

      return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchSubModule = createAsyncThunk(
  "setting/fetchSubModule",
  async ({ api }, { getState, dispatch }) => {
    let apiUrl = "/sub-modules";

    try {
      dispatch(setIsLoading(true));
      const response = await api.get(`${apiUrl}`);
      if (response.status === 200 && response.data.success) {
        dispatch(
          setSubModules(
            response.data.data
              .sort((a, b) => a.id - b.id)
              .map((i) => {
                return {
                  label: i.sub_module,
                  value: i.id,
                  module: Number(i.module),
                };
              })
          )
        );
        console.log(
          response.data.data
            .sort((a, b) => a.id - b.id)
            .map((i) => {
              return {
                label: i.sub_module,
                value: i.id,
                module: i.module,
              };
            })
        );
        dispatch(setIsLoading(false));
      }

      return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchRequestPanelData = createAsyncThunk(
  "setting/fetchRequestPanelData",
  async ({ api }, { getState, dispatch }) => {
    const accessToken = getState().auth.accessToken;

    let apiUrl = "/requestPanelData";

    try {
      dispatch(setIsLoading(true));
      const response = await api.get(`${apiUrl}`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      if (response.status === 200 && response.data.success) {
        // console.log(response);
        dispatch(setRequestPanelData(response.data));

        dispatch(setIsLoading(false));
      }

      return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
