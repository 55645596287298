import { createAsyncThunk } from "@reduxjs/toolkit";
import { setIsLoading } from "../../common/commonSlice.js";
import { toast } from "react-toastify";
import {
  resetscope3Category6Form,
  setSiteData,
  setSiteDataForFilter,
  setSubtypeData,
  setdeletes3c6Data,
  setexpenseUnit,
 

  setfuelType,

  setmodeOfTravelMappingData,

  setscope3Category6Form,
  setscope3Category6InputRecords,
 
  setsource,
  settypeData,
 
 
} from "./Scope3Category6Slice.js"
import { getOrganizationId } from "../../../components/common/parseJwt.js";
import { removeFalsyValues } from "../../../components/common/removeFaslyValues.js";
export const fetchSiteData = createAsyncThunk(
  "scope3Category6/fetchSiteData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id=getOrganizationId()
    try {
      const response = await api.get(
        `/scope3/category6/${organization_id}/sites`,
        {
          // headers: {
          //   Authorization: `Bearer ${accessToken}`,
          // },
        }
      );

      if (response.status === 200 && response.data.success) {
        dispatch(setSiteData(response.data.data));
        dispatch(setSiteDataForFilter(response.data.Filters));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchscope3Category6InputData = createAsyncThunk(
  "scope3Category6/fetchscope3Category6InputData",
  async ({api,params}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id=getOrganizationId()

    let apiUrl = "/scope3/category6/inputdata/"+organization_id;

    try {
      const response = await api.get(`${apiUrl}`,{
        params:removeFalsyValues(params)
      });

// console.log(response)
      if (response.status === 200 && response.data.success) {
        dispatch(setscope3Category6InputRecords(response?.data?.data.map(item=>{
          return {
            ...item,
            // ExpenseUnit: item.item_ExpenseUnit_id,
            //     production_type: item.item_production_type,
            //     site: item.site_id,
            //     unit: item.item_unit_id,
            //     supplier: item.item_supplier,
            //     supplier_code: item.item_supplier_code,
          }
        })));
        // dispatch(setCurrentPage(response.data.currentPage));
        // dispatch(setItemsPerPage(response.data.itemsPerPage));
        // dispatch(setTotalPages(response.data.totalPages));
        // dispatch(setTotalCount(response.data.totalCount));
        // return response.data
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);


export const fetchSourceData = createAsyncThunk(
  "scope3Category6/fetchSourceData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id=getOrganizationId()
    try {
      const response = await api.get(
        `/scope3/category6/${organization_id}/sites`,
        {
          // headers: {
          //   Authorization: `Bearer ${accessToken}`,
          // },
        }
      );

      if (response.status === 200 && response.data.success) {
        dispatch(setsource(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);


export const fetchFuelTypeData = createAsyncThunk(
  "scope3Category6/fetchFuelTypeData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`/scope3/category6/fuel-type`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });
// console.log(response)
      if (response.status === 200 && response.data.success) {
        dispatch(setfuelType(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);


export const fetchExpenseUnitData = createAsyncThunk(
  "scope3Category6/fetchExpenseUnitData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`/scope3/category6/expense-unit`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      // console.log(response.data);
      if (response.status === 200 && response.data.success) {
        dispatch(setexpenseUnit(response?.data?.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchTravelTypeData = createAsyncThunk(
  "scope3Category6/fetchTravelTypeData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`/scope3/category6/travel-type`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      // console.log(response.data);
      if (response.status === 200 && response.data.success) {
        dispatch(settypeData(response?.data?.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchTravelSubTypeData = createAsyncThunk(
  "scope3Category6/fetchTravelSubTypeData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`/scope3/category6/travel-sub-type`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      
      if (response.status === 200 && response.data.success) {
        dispatch(setSubtypeData(response?.data?.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchmodeOfTravelMappingData = createAsyncThunk(
  "scope3Category6/fetchModeOfTravelData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`/scope3/category6/mode-of-travel-mapping`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      
      if (response.status === 200 && response.data.success) {
        dispatch(setmodeOfTravelMappingData(response?.data?.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const postscope3Category6Data = createAsyncThunk(
  "scope3Category6/postscope3Category6Data",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const { id, ...scope3Category6Form } = getState().scope3Category6.scope3Category6Form;
    const organization_id=getOrganizationId()
    try {
      const response = await api.post(
        `/scope3/category6/${organization_id}/data`,
        {
          ...scope3Category6Form,
          currency_id: scope3Category6Form.expense_unit,
          radiative_force:scope3Category6Form.air_conditioned,
          fuel_type_id: scope3Category6Form.fuel_type,
          travel_type_id: scope3Category6Form.travel_type,
          travel_subtype_id: scope3Category6Form.travel_subtype,
          no_of_passenger: scope3Category6Form.number_of_employees,
          distance_covered: scope3Category6Form.distance,
        
        },
       
      );
      // console.log(response);
      if (response.status === 200 || response.status === 201) {
        dispatch(resetscope3Category6Form());
        dispatch(fetchscope3Category6InputData({api,params:{}}));
        toast.success("Record created successfully", {
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error(error?.response?.data?.error || "Record creation failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const scope3Category6UploadEvidence = createAsyncThunk(
  "scope3Category6/scope3Category6UploadEvidence",
  async ({files,api}, { _, dispatch }) => {
    dispatch(setIsLoading(true));
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    
    const accessToken=localStorage.getItem("accessToken")
    const organization_id=getOrganizationId()
    try {
      const response = await api.post(
        `/scope3/category6/${organization_id}/evidence`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        dispatch(setIsLoading(false));

        dispatch(setscope3Category6Form({ evidence: response?.data?.path }));
        toast.success("Evidence uploded successfully", {
          theme: "dark",
        });
      }
      return response.data
    } catch (error) {
      console.error("Error uploading evidence", error);
      toast.error("uploading evidence failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const updatescope3Category6Data = createAsyncThunk(
  "scope3Category6/updatescope3Category6Data",
  async ({api,id}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const scope3Category6Form  = getState().scope3Category6.scope3Category6Form;
    // console.log(scope3Category6Form, id);
    const organization_id=getOrganizationId()
    try {
      const response = await api.put(
        `/scope3/category6/${organization_id}/data/${id}`,
        {
          ...scope3Category6Form,
          currency_id: scope3Category6Form.expense_unit,
          radiative_force:scope3Category6Form.air_conditioned,
          fuel_type_id: scope3Category6Form.fuel_type,
          travel_type_id: scope3Category6Form.travel_type,
          travel_subtype_id: scope3Category6Form.travel_subtype,
          no_of_passenger: scope3Category6Form.number_of_employees,
          distance_covered: scope3Category6Form.distance,
        
        },
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Record updated successfully", {
          theme: "dark",
        });
        dispatch(fetchscope3Category6InputData({api,params:{}}))
      }
    } catch (error) {
      console.error("Error updating data:", error);
      toast.error("Record update failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const deletescope3Category6Data = createAsyncThunk(
  "scope3Category6/deletescope3Category6Data",
  async ({api,id}, { _, dispatch }) => {
    dispatch(setIsLoading(true));
  
    const organization_id=getOrganizationId()
    try {
      const response = await api.delete(
        `/scope3/category6/${organization_id}/data/${id}`
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Record deleted successfully", {
          theme: "dark",
        });
        dispatch(setdeletes3c6Data(id))
      }
    } catch (error) {
      console.error("Error deleting data:", error);
      toast.error("Record delete failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);