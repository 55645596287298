export const CommentModal = ({ isOpen, comment, setComment, closeModal }) => {
    if (!isOpen) return null;
    return (
      <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
        <div className="bg-white p-6 rounded-md shadow-md max-w-md w-full">
          <h2 className="text-lg font-semibold mb-4">Add Comment</h2>
          <textarea
            className="w-full p-2 border rounded-md focus:outline-none"
            rows="4"
            placeholder="Enter your comment here..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
          <div className="flex justify-end space-x-2 mt-4">
            <button
              onClick={closeModal}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
            >
              Cancel
            </button>
            <button
              onClick={closeModal}
              className="bg-green-500 text-white px-4 py-2 rounded-md"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  };