import React from 'react'

function WasteProducedTable({tableData}) {

  return (
    <div className="overflow-x-auto">
      <table className="table-auto w-full">
        <thead className="bg-gray-200 sticky top-0">
          <tr>
            <th className="px-4 py-2">Site</th>
            <th className="px-2 py-2">Produced (tonnes)</th>
            <th className="px-2 py-2">Disposed (tonnes)</th>
            <th className="px-2 py-2">Recovered (tonnes)</th>
            <th className="px-2 py-2">Disposed %</th>
            <th className="px-2 py-2">Recovered %</th>
            <th className="px-2 py-2">Disposed % <br/>YOY Change </th>
            <th className="px-2 py-2">Recovered % <br/> YOY Change</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(tableData).map((key, index) => {
            const wasteTreatedPercentageChangeClass = tableData[key].wasteTreatedPercentageChange < 0 ? 'text-green-500' : 'text-red-500'
            const wasteRecoveredPercentageChangeClass = tableData[key].wasteRecoveredPercentageChange < 0 ? 'text-green-500' : 'text-red-500'
            return (
              <tr key={index}>
                <td className="px-4 py-2 border text-center overflow-ellipsis whitespace-nowrap">{key}</td>
                <td className="px-4 py-2 border text-center">{tableData[key].wasteProduced?.toFixed()}</td>
                <td className="px-4 py-2 border text-center">{tableData[key].wasteTreated?.toFixed()}</td>
                <td className="px-4 py-2 border text-center">{tableData[key]?.wasteRecovered?.toFixed()}</td>
                <td className="px-4 py-2 border text-center">{tableData[key].wasteTreatedPercentage}</td>
                <td className="px-4 py-2 border text-center">{tableData[key].wasteRecoveredPercentage}</td>
                <td className={`px-4 py-2 border text-center ${wasteTreatedPercentageChangeClass}`}>{tableData[key].wasteTreatedPercentageChange}</td>
                <td className={`px-4 py-2 border text-center ${wasteRecoveredPercentageChangeClass}`}>{tableData[key].wasteRecoveredPercentageChange}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default WasteProducedTable
