
import { ReactComponent as AddIconBig } from "../../app/assets/AddIconBig.svg";
import React, { useEffect, useState } from "react";
import { ReactComponent as RecyclingIcon } from "../../app/assets/RecyclingIcon.svg";
import { ReactComponent as WasteTreatedIcon } from "../../app/assets/WasteTreatedIcon.svg";
import { ReactComponent as WasteProducedIcon } from "../../app/assets/WasteProducedIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  resetscope3Category5Form,
  setCurrentPage,
  setFilters,
  setFormValues,
  setItemsPerPage,
  setscope3Category5RecordsTreated,
  setscope3Category5WasteProduceForm,
  // setscope3Category5Form,
} from "../../features/scope3/category5/Scope3Category5Slice";
import Header from "../../components/common/Header";
import TableMeta from "../../components/common/Table/TableMeta";
import TablePagination from "../../components/common/Table/TablePagination";
import {
  deletescope3Category5Data,
  fetchCurrencyData,
  fetchLcaActivityMaster,
  fetchscope3Category5InputData,
  fetchSiteData,
  fetchSourceTypeMaster,
  fetchUnitData,
  fetchWasteMaterial,
  fetchWasteTreatment,
  fetchWasteTreatmentMapping,
  updatescope3Category5Data,
} from "../../features/scope3/category5/Scope3Category5Thunk";
import useApi from "../../customHooks/useApi";
import Category5FormPopUp from "../../components/scope3/category5/Category5FormPopUp";
import TableComponent from "./TableComponent";
import { useSearchParams } from "react-router-dom";
import FilterComponent from "../../components/common/FilterComponent";

const Scope3Category5 = () => {
  const [apiIntegration, setApiIntegration] = useState(false);
  const [trendLine, setTrendLine] = useState(false);
  const read_only = useSelector((state) => state?.auth?.read_only);
  const [searchParams, setSearchParams] = useSearchParams();
  const api = useApi();
  const dispatch = useDispatch();
  const { scope3Category5RecordsTreated, scope3Category5ProducedInputRecords,StoreTreated,WasteMechanismDataForFilter ,siteDataForFilter,WasteMaterialDataForFilter} =
    useSelector((state) => state?.scope3Category5);
  const { itemsPerPage, totalPages, totalCount, currentPage } = useSelector(
    (state) => state?.scope3Category5
  );

  const [files, setFiles] = useState(null);
  const [manualEntry, setManualEntry] = useState(true);
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedWasteType, setSelectedWasteType] = useState(1);
  const [actionType, setActionType] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isCategory5FormPopupOpen, setIsCategory5FormPopupOpen] =
    useState(false);
    const filters = [
      {
        name: "produced_site_id",
        label: "Facility",
        options:
          siteDataForFilter &&
          siteDataForFilter.map((site) => ({
            value: site.id,
            label: site.site,
          })),
      },
      {
        name: "produced_waste_material_id",
        label: "Waste Material",
        options:
          WasteMaterialDataForFilter &&
          WasteMaterialDataForFilter.map((item) => ({
            value: item.id,
            label: item.waste_material,
          })),
      },
      ...(selectedWasteType === 2
        ? [
            {
              name: "treatment_mechanism_id",
              label: "Waste Treatment Mechanism",
              options:
                WasteMechanismDataForFilter &&
                WasteMechanismDataForFilter.map((item) => ({
                  value: item.id,
                  label: item.treated_treatment_mechanism,
                })),
            },
          ]
        : []),
    ];
  const headingsToDataKeyMap = {
    " ": "copy",
    "PERIOD START": "produced_period_start",
    "PERIOD END": "produced_period_end",
    FACILITY: "site",
    "WASTE MATERIAL": "produced_waste_material",
    "WASTE IMPACT": "produced_waste_impact",
    " QUANTITY": "produced_quantity",
    UNIT: "unit",
    // "AMOUNT SPENT": "amount_spent",
    STATUS: "status",
    EVIDENCE: "produced_evidence",
    // EDIT: "edit",
    // DELETE: "",
  };
  if (!read_only) {
    headingsToDataKeyMap.EDIT = "edit";
    headingsToDataKeyMap.DELETE = "delete";
  }
  const headingsToDataKeyMapforTreated = {
    // " ": "copy",
    "PERIOD START": "treated_period_start",
    "PERIOD END": "treated_period_end",
    FACILITY: "site",
    "WASTE MATERIAL": "waste_material",
    "TREATMENT MECHANISM": "treatment_mechanism",
    "EMISSION FACTOR DATABASE": "source_type",
    "LCA ACTIVITY": "lca_activity",
    "PROPORTION OF WASTE TREATED": "treated_proportion_of_waste",
    "TREATED QUANTITY": "treated_quantity",
    UNIT: "treated_unit",
    "AMOUNT SPENT": "treated_amount_spent",
    CURRENCY: "currency",
    STATUS: "status",
    EVIDENCE: "treated_evidence",
    // EDIT: "edit",
    // DELETE: "",
  };
  if (!read_only) {
    headingsToDataKeyMapforTreated.EDIT = "edit";
    headingsToDataKeyMapforTreated.DELETE = "delete";
  }
  const handleDelete = (row) => {
   

    if (selectedWasteType === 2) {
      if (row.id && row.sub_id) {
       console.log(row)
        dispatch(setscope3Category5WasteProduceForm(scope3Category5ProducedInputRecords.find(i=>i.id==row.id)))
        dispatch(
          updatescope3Category5Data({
            api,
            id: row.id,
            formValues:scope3Category5RecordsTreated.filter(i=>i.id==row.id&&i.sub_id!=row.sub_id),
          })
        );
      } else {
        toast.error("Failed please try again");
      }
    } else if (selectedWasteType === 1) {
      if (row.id) {
        dispatch(deletescope3Category5Data({ api, id: row.id }));
      } else {
        toast.error("Failed please try again");
      }
    } else {
      toast.error("Invalid request");
    }
  };

  const handleEdit = (row) => {
    dispatch(resetscope3Category5Form());

      if (row.id) {
        dispatch(
          setFormValues([
            ...scope3Category5RecordsTreated.filter((i) => i.id == row.id),
          ])
        );
        dispatch(setscope3Category5WasteProduceForm(scope3Category5ProducedInputRecords.find(i=>i.id==row.id)))
      } else {
        toast.error("Failed please try again");
      }
    setActionType("edit");
    setSelectedRowData(row);
    setIsCategory5FormPopupOpen(true);
  };

  const handleCopy = (row) => {
    dispatch(resetscope3Category5Form());
    if (row.id) {
      dispatch(
        setFormValues([
          ...scope3Category5RecordsTreated.filter((i) => i.id == row.id),
        ])
      );
      dispatch(setscope3Category5WasteProduceForm(scope3Category5ProducedInputRecords.find(i=>i.id==row.id)))
    } else {
      toast.error("Failed please try again");
    }
    setActionType("copy");
    setSelectedRowData(row);
    setIsCategory5FormPopupOpen(true);
  };

  const openPopupForm = () => {
    dispatch(resetscope3Category5Form());
  
    setActionType("newEntry");
    setIsCategory5FormPopupOpen(true);
  };

  const closePurchasePopupForm = () => {
    dispatch(resetscope3Category5Form());
    setIsCategory5FormPopupOpen(false);
  };

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const handleItemsPerPage = (itemCount) => {
    dispatch(setItemsPerPage(itemCount));
  };
  const switchTab = (tabId) => {
    switch (tabId) {
      case 1:
        setManualEntry(true);
        setApiIntegration(false);
        setTrendLine(false);
        break;
      case 2:
        setManualEntry(false);
        setApiIntegration(true);
        setTrendLine(false);
        break;
      case 3:
        setManualEntry(false);
        setApiIntegration(false);
        setTrendLine(true);
        break;
      default:
        setManualEntry(true);
        setApiIntegration(false);
        setTrendLine(false);
        break;
    }
    setSelectedTab(tabId);
  };

  const handleFileDownload = (filePath) => {

    if (filePath) {
      const link = document.createElement("a");
      link.href = filePath;
      link.download = "filename.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast("No evidence attached");
    }
  };
  const handleFilterChange = async(newFilters) => {
    // fetchData(newFilters);
    if(!newFilters.treatment_mechanism_id==""){
      dispatch(setscope3Category5RecordsTreated(StoreTreated.filter(i=>i.treatment_mechanism_id==newFilters.treatment_mechanism_id)))
     return
    }else{

      dispatch(fetchscope3Category5InputData({ api, params: newFilters }));
    }
    const updatedParams = new URLSearchParams(newFilters).toString();
    dispatch(setFilters(updatedParams))
    setSearchParams(updatedParams);
    // console.log(newFilters);
  };
  const handleFormChange = async (e) => {
    // const { name, type, value } = e.target;
    // if (type === "file") {
    //   const files = e.target.files[0];
    //   setFiles(files);
    //   dispatch(setscope3Category5Form({ name, value: files }));
    // } else {
    //   dispatch(setscope3Category5Form({ name, value }));
    // }
  };


  const handleWasteTypeChange = (type) => {
    setSearchParams(new URLSearchParams());
    setSelectedWasteType(type);
    handleFilterChange({})
  };

  useEffect(() => {
    const filters = Object.fromEntries(searchParams.entries());
    const initializeData = async () => {
      if(!filters.treatment_mechanism_id&&!filters.treatment_mechanism_id==""){

        await dispatch(fetchscope3Category5InputData({ api,params:filters }));
      }
      await dispatch(fetchUnitData({ api }));
      await dispatch(fetchWasteMaterial({ api }));
      await dispatch(fetchSiteData({ api }));
      await dispatch(fetchCurrencyData({ api }));
      await dispatch(fetchWasteTreatment({ api }));
      await dispatch(fetchWasteTreatmentMapping({ api }));
      await dispatch(fetchSourceTypeMaster({ api }));
      await dispatch(fetchLcaActivityMaster({ api }));
    };

    initializeData();
  }, [dispatch, api]);

  return (
    <div className="flex flex-col main-container w-full min-h-screen">
      <Header
        pageTitle="Waste"
        PageIcon={RecyclingIcon}
        onClick={openPopupForm}
      />
      <div className="flex flex-col main-container w-full px-10 py-6 h-full">
        <div className="flex justify-between items-center text-xs">
          <div className="flex">
            <button
              className={`flex  items-center border border-green-600 rounded-md mr-4 cursor-pointer px-2 flex-shrink-0 h-8 ${
                selectedWasteType === 1
                  ? "border-green-600 focus:bg-green-600 text-white white-svg bg-green-600"
                  : "text-green-600 bg-white border-gray-100 hover:bg-green-600 hover:text-white hover-white-svg"
              }`}
              onClick={() => handleWasteTypeChange(1)}
            >
              <WasteProducedIcon className="fill-current w-6 h-6" />
              <span className="px-2">Waste Produced</span>
            </button>
            <button
              className={`flex border border-green-600 items-center rounded-md mr-4 cursor-pointer px-2  flex-shrink-0 h-8 ${
                selectedWasteType === 2
                  ? "border-green-600 focus:bg-green-600 text-white white-svg bg-green-600"
                  : "text-green-600 bg-white border-gray-100 hover:bg-green-600 hover:text-white hover-white-svg"
              }`}
              onClick={() => handleWasteTypeChange(2)}
            >
              <WasteTreatedIcon className="fill-current w-4 h-4 stroke-gray-400" />
              <span className="px-2">Waste Treated</span>
            </button>
          </div>
          <div className="flex  justify-between items-center text-xs">
          <div className="flex"></div>
          <div className="flex">
            <FilterComponent
              filters={filters}
              onFilterChange={handleFilterChange}
            />
          </div>
        </div>
        </div>
        <div className="flex flex-col border border-gray-300 rounded-md mt-[10px] relative">
          <TableMeta
            recordType={1}
            totalCount={totalCount}
            openPopUpForm={openPopupForm}
            switchTab={switchTab}
            selectedTab={selectedTab}
          />

          {manualEntry && (
            <>
              {" "}
              {selectedWasteType === 1 && (
                <TableComponent
                  headings={headingsToDataKeyMap}
                  dataRows={scope3Category5ProducedInputRecords}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  handleCopy={handleCopy}
                  handleFileDownload={handleFileDownload}
                />
              )}
              {selectedWasteType === 2 && (
                <TableComponent
                  headings={headingsToDataKeyMapforTreated}
                  dataRows={scope3Category5RecordsTreated}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  handleCopy={handleCopy}
                  handleFileDownload={handleFileDownload}
                />
              )}
              <div className="flex w-fit py-4 px-2 absolute bottom-8 right-6 cursor-pointer">
                <AddIconBig onClick={() => openPopupForm(1)} />
              </div>
            </>
          )}

          {apiIntegration && (
            <>
              <h1>API Integration tab</h1>
            </>
          )}
          {trendLine && (
            <>
              <h1>TrendLine tab</h1>
            </>
          )}
          <TablePagination
            paginationObject={{
              paginationEnabled: true,
              currentPage: currentPage,
              totalPages: totalPages,
              itemsPerPage: itemsPerPage,
              handleItemsPerPage: handleItemsPerPage,
              handlePageChange: handlePageChange,
            }}
          />
        </div>
      </div>
      {isCategory5FormPopupOpen && (
        <Category5FormPopUp
          selectedWasteType={selectedWasteType}
          handleFormChange={handleFormChange}
          file={files}
          type={actionType}
          data={selectedRowData}
          closePopup={closePurchasePopupForm}
        />
      )}
    </div>
  );
};

export default Scope3Category5;
