import React from "react";
import { ReactComponent as CalenderIcon } from "../../../../app/assets/CalenderIcon.svg";
import { ReactComponent as SitesIcon } from "../../../../app/assets/SitesIcon.svg";

const HiringAndTurnoverDisplay = ({ data }) => {
  if (!data) {
    return <div>No data available</div>;
  }

  const { financialYear, sites } = data;

  // Renders the table for a specific site's categories
  const renderTableSection = (categories, siteData) => (
    <table className="min-w-full border-collapse border border-gray-200 mt-4">
      <thead>
        <tr className="bg-gray-200">
          <th className="border border-gray-300 p-2" rowSpan="2">
            Age Group
          </th>
          {categories.map((category) => (
            <th key={category} className="border border-gray-300 p-2 capitalize" colSpan="3">
              {category}
            </th>
          ))}
        </tr>
        <tr className="bg-gray-200">
          {categories.flatMap((_, index) => [
            <th key={`recruits-${index}`} className="border border-gray-300 p-2">
              New recruits
            </th>,
            <th key={`left-${index}`} className="border border-gray-300 p-2">
              Employees left
            </th>,
            <th key={`total-${index}`} className="border border-gray-300 p-2">
              Total Employees
            </th>,
          ])}
        </tr>
      </thead>
      <tbody>
        {["<20", "20-30", "31-50", ">50"].map((ageGroup) => (
          <tr key={ageGroup}>
            <td className="border border-gray-300 p-2">{ageGroup} years</td>
            {categories.flatMap((category, i) => [
              <td key={`${category}-${ageGroup}-recruits-${i}`} className="border border-gray-300 p-2 text-center">
                {siteData?.[category]?.[ageGroup]?.recruits || 0}
              </td>,
              <td key={`${category}-${ageGroup}-left-${i}`} className="border border-gray-300 p-2 text-center">
                {siteData?.[category]?.[ageGroup]?.left || 0}
              </td>,
              <td key={`${category}-${ageGroup}-total-${i}`} className="border border-gray-300 p-2 text-center">
                {siteData?.[category]?.[ageGroup]?.total || 0}
              </td>,
            ])}
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className="p-4 border rounded">

      {/* Render Data for Each Site */}
      {Object.entries(sites).map(([site, siteData]) => (
        <div key={site} className="mb-8">
      <div className="flex justify-start gap-5  border-b-2 pb-4">
      <div className="flex items-center gap-2 justify-center">
                {/* <SitesIcon className="w-6 h-6" /> */}
            <CalenderIcon className="w-5 h-5" />
                <label>Financial Year</label>
          <div className="flex items-center gap-2 justify-center border bg-white p-1 px-2 rounded">
            <div className="flex items-center gap-2">
              <select
                className="font-semibold  text-center"
                disabled
              >
                <option selected>{` ${financialYear}`}</option>
              </select>
            </div>
            </div>
            </div>
            {site && (
              <div className="flex items-center gap-2 justify-center">
                <SitesIcon className="w-6 h-6" />
                <label>Facility</label>
                 <div className="flex items-center gap-2 justify-center border bg-white p-1 px-2 rounded w-24">
                <select
                  className="font-semibold  text-center w-full"
                  disabled
                >
                  <option selected>{` ${site}`}</option>
                </select>
              </div>
              </div>
             
            )}
          {/* </div> */}
      </div>
          {renderTableSection(["male", "female"], siteData)}
          {renderTableSection(["other", "notDisclosed"], siteData)}
        </div>
      ))}
    </div>
  );
};

export default HiringAndTurnoverDisplay;
