import React from "react";
import { ReactComponent as ReportIcon } from "../../app/assets/ReportingIcon.svg";
import Header from "../../components/common/Header";
import GRIReport from "./GRIReport";
function GRI() {
  return (
    <div className="min-h-screen w-full ">
      <Header pageTitle={"GRI"} PageIcon={ReportIcon} />
      <div className="flex justify-center items-center p-2 px-4 w-full"><GRIReport/></div>
    </div>
  );
}

export default GRI;
