import React, { useState } from 'react';


const QuestionAnswerBox = ({ question, onSubmit }) => {
  const [answers, setAnswers] = useState({});


  const handleAnswerChange = (question, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [question]: value,
    }));
  };

 
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(answers);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
   
        <div  className="flex flex-col space-y-2">
          <label className="text-gray-700 text-base font-semibold bg-gray-300 p-4">{question}</label>
          <textarea
            value={answers[question] || ''}
            onChange={(e) => handleAnswerChange(question, e.target.value)}
            placeholder="Enter your answer here"
            className="border rounded-md p-2 text-gray-700 focus:outline-none resize-none"
            rows="4"
          />
        </div>
      
      <button
        type="submit"
        className="bg-green-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-green-600"
      >
        Submit
      </button>
    </form>
  );
};


export default QuestionAnswerBox;
