import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  category1Data: [],
  Scope3Category2Data: [],
  Scope3Category4Data: [],
  Scope3Category9Data: [],
  Scope3Category6Data: [],
  Scope3Category7Data: [],
  financialYear: [],
  s3c1financialYear: [],
  s3c4financialYear: [],
  s3c6financialYear: [],
  s3c7financialYear: [],
  s3c9financialYear: [],
  electricityData: [],
  overAllData: [],
  dataForCurrentYearChange: [],
  Scope3Category2DataForCurrentYearChange: [],
  Scope3Category4DataForCurrentYearChange: [],
  Scope3Category6DataForCurrentYearChange: [],
  Scope3Category7DataForCurrentYearChange: [],
  Scope3Category9DataForCurrentYearChange: [],
  siteOptions: [],
  moduleOptions: [],
  travelTypeOptions: [],
  travelSubTypeOptions: [],
  modeOfTravelOptions: [],
  transactionTypeOptions: [],
  ElectricitySourceOptions: [],
  sub_moduleOptions: [],
  s3c1siteOptions: [],
  s3c4siteOptions: [],
  s3c6siteOptions: [],
  s3c7siteOptions: [],
  s3c7pincodeOptions: [],
  s3c9siteOptions: [],
  s3c9siteOptions: [],
  supplierOptions: [],
  itemOptions: [],
  logisticModeOptions:[],
  fuelTypeOptions: [],
  filter: {},
  useTypeOptions: [],
};

const chartSlice = createSlice({
  name: "chart",
  initialState,
  reducers: {
    setFuelTypeOptions(state, action) {
      state.fuelTypeOptions = action.payload;
    },
    setSiteOptions(state, action) {
      state.siteOptions = action.payload;
    },
    settravelTypeOptions(state, action) {
      state.travelTypeOptions = action.payload;
    },
    settravelSubTypeOptions(state, action) {
      state.travelSubTypeOptions = action.payload;
    },
    setModeOfTravelOptions(state, action) {
      state.modeOfTravelOptions = action.payload;
    },
    sets3c1SiteOptions(state, action) {
      state.s3c1siteOptions = action.payload;
    },
    sets3c4SiteOptions(state, action) {
      state.s3c4siteOptions = action.payload;
    },
    sets3c6SiteOptions(state, action) {
      state.s3c6siteOptions = action.payload;
    },
    sets3c7SiteOptions(state, action) {
      state.s3c7siteOptions = action.payload;
    },
    sets3c7pincodeOptions(state, action) {
      state.s3c7pincodeOptions = action.payload;
    },
    sets3c9SiteOptions(state, action) {
      state.s3c9siteOptions = action.payload;
    },
    setSupplierOptions(state, action) {
      state.supplierOptions = action.payload;
    },
    setItemOptions(state, action) {
      state.itemOptions = action.payload;
    },
    setlogisticModeOptions(state, action) {
      state.logisticModeOptions = action.payload;
    },
    setElectricitySourceOptions(state, action) {
      state.ElectricitySourceOptions = action.payload;
    },
    setTransactionTypeOptions(state, action) {
      state.transactionTypeOptions = action.payload;
    },
    setSubModuleOptions(state, action) {
      state.sub_moduleOptions = action.payload;
    },
    setModuleOptions(state, action) {
      state.moduleOptions = action.payload;
    },
    setUseTypeOptions(state, action) {
      state.useTypeOptions = action.payload;
    },
    setFinancialYear(state, action) {
      state.financialYear = action.payload;
    },
    setS3c1FinancialYear(state, action) {
      state.s3c1financialYear = action.payload;
    },
    setS3c4FinancialYear(state, action) {
      state.s3c4financialYear = action.payload;
    },
    setS3c9FinancialYear(state, action) {
      state.s3c9financialYear = action.payload;
    },
    setS3c6FinancialYear(state, action) {
      state.s3c6financialYear = action.payload;
    },
    setS3c7FinancialYear(state, action) {
      state.s3c7financialYear = action.payload;
    },
    setfuelData(state, action) {
      state.data = action.payload;
    },
    setcategory1Data(state, action) {
      state.category1Data = action.payload;
    },
    setScope3Category2Data(state, action) {
      state.Scope3Category2Data = action.payload;
    },
    setScope3Category6Data(state, action) {
      state.Scope3Category6Data = action.payload;
    },
    setScope3Category7Data(state, action) {
      state.Scope3Category7Data = action.payload;
    },
    setScope3Category2DataForCurrentYearChange(state, action) {
      state.Scope3Category2DataForCurrentYearChange = action.payload;
    },
    setScope3Category4Data(state, action) {
      state.Scope3Category4Data = action.payload;
    },
    setScope3Category4DataForCurrentYearChange(state, action) {
      state.Scope3Category4DataForCurrentYearChange = action.payload;
    },
    setScope3Category9Data(state, action) {
      state.Scope3Category9Data = action.payload;
    },
    setScope3Category9DataForCurrentYearChange(state, action) {
      state.Scope3Category9DataForCurrentYearChange = action.payload;
    },
    setScope3Category6DataForCurrentYearChange(state, action) {
      state.Scope3Category6DataForCurrentYearChange = action.payload;
    },
    setScope3Category7DataForCurrentYearChange(state, action) {
      state.Scope3Category7DataForCurrentYearChange = action.payload;
    },
    setElecricityData(state, action) {
      state.electricityData = action.payload;
    },
    setOverAllData(state, action) {
      state.overAllData = action.payload;
    },
    setdataForCurrentYearChange(state, action) {
      state.dataForCurrentYearChange = action.payload;
    },

    setfuelFilter(state, action) {
      state.filter = { ...state.filter, ...action.payload };
    },
  },
});

export const {
  setdataForCurrentYearChange,setlogisticModeOptions,
  setItemOptions,
  setSupplierOptions,
  setScope3Category6Data,
  setS3c6FinancialYear,
  setScope3Category6DataForCurrentYearChange,
  setScope3Category2Data,
  setScope3Category2DataForCurrentYearChange,
  sets3c6SiteOptions,
  sets3c7SiteOptions,
  setS3c7FinancialYear,
  setScope3Category7Data,
  setScope3Category7DataForCurrentYearChange,
  sets3c7pincodeOptions,
  setfuelFilter,
  setFuelTypeOptions,
  setS3c1FinancialYear,
  sets3c1SiteOptions,
  setSiteOptions,
  setUseTypeOptions,
  setcategory1Data,
  setfuelData,
  setSubModuleOptions,
  setModuleOptions,
  setOverAllData,
  setElecricityData,
  setTransactionTypeOptions,
  setElectricitySourceOptions,
  setFinancialYear,
  setS3c4FinancialYear,
  setScope3Category4Data,
  setScope3Category4DataForCurrentYearChange,
  sets3c4SiteOptions,
  setS3c9FinancialYear,
  setScope3Category9Data,
  setScope3Category9DataForCurrentYearChange,
  sets3c9SiteOptions,
  setModeOfTravelOptions,
  settravelSubTypeOptions,
  settravelTypeOptions,
} = chartSlice.actions;

export default chartSlice.reducer;
