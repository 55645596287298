export const ProgressBar = ({ label = "Report Completion Status", progress=100, height = 'h-5', bgColor = 'bg-blue-500', textColor = 'text-white' }) => {
    return (
      <div className="w-full space-y-1">
        <div className="text-gray-700 font-medium">{label}</div>
        <div className={`w-full ${height} bg-gray-200 rounded-full overflow-hidden`}>
          <div
            className={`flex items-center justify-center p-1 text-sm ${height} ${bgColor} ${textColor} font-semibold`}
            style={{ width: `${progress}%` }}
          >
            {progress}%
          </div>
        </div>
      </div>
    );
  };