import React, { useState } from "react";

// SVG Icons for expand and collapse
const ExpandIcon = () => (
  <svg className="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 7L15 12L10 17" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const CollapseIcon = () => (
  <svg className="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 10L12 15L17 10" stroke="#000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const GRIReport = () => {
  const [expanded, setExpanded] = useState({});
  const [isCommentModalOpen, setCommentModalOpen] = useState(false);
  const [comment, setComment] = useState("");

  const toggleExpand = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const openCommentModal = () => setCommentModalOpen(true);
  const closeCommentModal = () => setCommentModalOpen(false);

  return (
    <div className="bg-gray-50 w-full h-full">
      <Header />
      <ReportFilters />

      <ul className="bg-white rounded-md shadow-md overflow-hidden p-4 space-y-4 ">
        <ListHeader />
        {disclosures.map((disclosure) => (
          <DisclosureItem
            key={disclosure.id}
            disclosure={disclosure}
            expanded={expanded}
            toggleExpand={toggleExpand}
            openCommentModal={openCommentModal}
          />
        ))}
      </ul>

      <CommentModal isOpen={isCommentModalOpen} comment={comment} setComment={setComment} closeModal={closeCommentModal} />
    </div>
  );
};

const Header = () => (
  <header className="mb-8">
    <p className="text-gray-600">Global Reporting Initiative</p>
    <p className="text-sm text-gray-500 mt-1">
      Independent, not-for-profit organization that helps organizations report on their sustainability performance.
    </p>
  </header>
);

const ReportFilters = () => (
  <div className="flex items-center justify-between mb-6">
    <InputField label="Report year" />
    <InputField label="Base year" />
    <div className="flex items-center space-x-4">
      <div className="w-48 bg-gray-200 rounded-full h-2.5">
        <div className="bg-green-600 h-2.5 rounded-full" style={{ width: "30%" }}></div>
      </div>
      <button className="bg-green-500 text-white px-4 py-2 rounded-md">Download Index</button>
      <button className="bg-green-500 text-white px-4 py-2 rounded-md">PDF</button>
    </div>
  </div>
);

const InputField = ({ label }) => (
  <div className="flex flex-col">
    <label className="text-sm font-medium mb-2">{label}</label>
    <div className="flex items-center space-x-4">
      <input type="month" className="border rounded-md p-2 w-40 text-gray-700 focus:outline-none" />
      <span className="text-gray-500">to</span>
      <input type="month" className="border rounded-md p-2 w-40 text-gray-700 focus:outline-none" />
    </div>
  </div>
);

const ListHeader = () => (
  <li className="grid grid-cols-5 gap-x-2 md:gap-x-4 bg-gray-100 py-4 rounded-md px-2">
    <span className="font-medium text-gray-600 text-start">Disclosure Code</span>
    <span className="font-medium text-gray-600 text-center">Disclosure</span>
    <span className="font-medium text-gray-600 text-center">Disclosure Type</span>
    <span className="font-medium text-gray-600 text-center">Status</span>
    <span className="font-medium text-gray-600 text-center">Comment</span>
  </li>
);

const DisclosureItem = ({ disclosure, expanded, toggleExpand, openCommentModal }) => {
  const isExpanded = expanded[disclosure.id];
  return (
    <>
      <li className="border-b p-4 cursor-pointer transition-all duration-300 w-full" >
        <div className=" items-center grid grid-cols-5 ">
          <div className="flex items-start  ">
            <span onClick={() => toggleExpand(disclosure.id)} className="mr-2 flex items-center">
              {isExpanded ? <CollapseIcon className="mr-1" /> : <ExpandIcon className="mr-1" />}
              {/* <span className="text-xs">{isExpanded ? "Collapse" : "Expand"}</span> */}
              {disclosure.id}
            </span>
           
          </div>
          <span className="flex flex-shrink-0 text-center w-full">{disclosure.title}</span>
          <div className="flex items-center justify-center">
            <select className="border-none rounded-md p-2 w-36">
              <option>Relevant</option>
              <option>Irrelevant</option>
            </select>
            </div>
          <div className="flex items-center justify-center">
            <select className="border-none rounded-md p-2 w-36">
              <option>Pending</option>
              <option>Completed</option>
            </select>
            </div>
          <div className="flex items-center justify-center">
            <button
              onClick={(e) => {
                e.stopPropagation();
                openCommentModal();
              }}
              className="bg-green-500 text-white px-2 py-1 rounded w-36"
            >
              Comment
            </button>
          </div>
        </div>
      </li>
      {isExpanded &&
        disclosure.subDisclosure &&
        disclosure.subDisclosure.map((sub) => (
          <DisclosureSubItem key={sub.id} sub={sub} expanded={expanded} toggleExpand={toggleExpand} />
        ))}
    </>
  );
};

const DisclosureSubItem = ({ sub, expanded, toggleExpand }) => {
  const isExpanded = expanded[sub.id];
  return (
    <>
      <li className="border-b p-2 transition-all duration-300 bg-gray-100" >
        <div className="grid grid-cols-5 items-center">
          <div className="flex items-start ">
            <span onClick={() => toggleExpand(sub.id)} className="mr-2 flex items-center">
              {isExpanded ? <CollapseIcon className="mr-1" /> : <ExpandIcon className="mr-1" />}
              {sub.id}
            </span>
           
          </div>
          <span className="flex flex-shrink-0 text-center  w-full">{sub.title}</span>
          <div className="flex items-center justify-center">
            <select className="border-none rounded-md p-2 w-36">
              <option>Relevant</option>
              <option>Irrelevant</option>
            </select>
            </div>
          <div className="flex items-center justify-center">
            <select className="border-none rounded-md p-2 w-36">
              <option>Pending</option>
              <option>Completed</option>
            </select>
            </div>
          <span></span>
        </div>
      </li>
      {isExpanded && (
        <li className="border-b bg-gray-100 p-4 pl-14">
          <span>{sub.details}</span>
        </li>
      )}
    </>
  );
};

const CommentModal = ({ isOpen, comment, setComment, closeModal }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
      <div className="bg-white p-6 rounded-md shadow-md max-w-md w-full">
        <h2 className="text-lg font-semibold mb-4">Add Comment</h2>
        <textarea
          className="w-full p-2 border rounded-md focus:outline-none"
          rows="4"
          placeholder="Enter your comment here..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        ></textarea>
        <div className="flex justify-end space-x-2 mt-4">
          <button onClick={closeModal} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md">
            Cancel
          </button>
          <button onClick={closeModal} className="bg-green-500 text-white px-4 py-2 rounded-md">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

// Sample Data
const disclosures = [
  {
    id: "2-7",
    title: "General Disclosures 2021 - Employees",
    irrelevant: true,
    status: "Completed",
    details: "Report the total number of employees, and a breakdown by gender and region",
    subDisclosure: [
      {
        id: "2-7-a",
        title: "Full Time Employee Demographics by Gender",
        irrelevant: false,
        status: "Pending",
        details: "Details for full-time employees by gender and region.",
      },
      {
        id: "2-7-b",
        title: "Employee distribution based on employment type",
        irrelevant: false,
        status: "Pending",
        details: "Breakdown of employment types (full-time, part-time, etc.) by gender and region.",
      },
    ],
  },
];

export default GRIReport;


