
import { createSlice } from "@reduxjs/toolkit";

// Assume you have thunks for login and logout (similar to the previous example)

const electricitySlice = createSlice({
  name: "electricity",
  initialState: {
    electricityRecords: [ ],
    electricityForm: {},
    electricityInputRecords: [],
    ElectricitySourceFilter:[],
    filters: null,
    sorting: null,
    transaction_type: [],
    electricityRecordType: 1,
    itemsPerPage: 10,
    totalPages: 1,
    currentPage: 1,
    totalCount: 10,
    siteDataForFilter: [],
    electricityTransactionTypeData: [],
    electricitySourcesData: [],
    sourceTypeData: ["Non Renewable","Renewable"],
    useTypeData: [],
    electricity_boardData:[
      {"id": 1, "electricity_board": "Himachal Pradesh State Electricity Board Limited"},
      {"id": 2, "electricity_board": "Tata Power Delhi Distribution Limited"},
      {"id": 3, "electricity_board": "BSES Rajdhani Power Limited"},
      {"id": 4, "electricity_board": "BSES Yamuna Power Limited"},
      {"id": 5, "electricity_board": "New Delhi Municipal Corporation"},
      {"id": 6, "electricity_board": "Uttar haryana Bijli Vitran Nigam"},
      {"id": 7, "electricity_board": "Dakshin Haryana Bijli Vitran Nigam"},
      {"id": 8, "electricity_board": "Uttarakhand Power Corporation Limited"},
      {"id": 9, "electricity_board": "Punjab State Power Corporation Limited"},
      {"id": 10, "electricity_board": "Purvanchal Vidyut Vitran Nigam Ltd."},
      {"id": 11, "electricity_board": "Paschimanchal Vidyut Vitran Limited"},
      {"id": 12, "electricity_board": "Madhyanchal Vidyut Vitran Limited"},
      {"id": 13, "electricity_board": "Dhakshinachal Vidyut Vitran Limited"},
      {"id": 14, "electricity_board": "Electricity Department, UT of Chandigarh"},
      {"id": 15, "electricity_board": "Power Development Department Jammu & Kashmir"},
      {"id": 16, "electricity_board": "Manipur State Power Distribution Company Ltd"},
      {"id": 17, "electricity_board": "Department of Power, Arunachal Pradesh"},
      {"id": 18, "electricity_board": "Department of Power, Nagaland"},
      {"id": 19, "electricity_board": "Sikkim Power Development Corporation Limited"},
      {"id": 20, "electricity_board": "Meghalaya Energy Distribution Corporation Limited"},
      {"id": 21, "electricity_board": "Power & Electricity Department, Government of Mizoram"},
      {"id": 22, "electricity_board": "North Bihar Power Distribution Company Limited"},
      {"id": 23, "electricity_board": "South Bihar Power Distribution Company Limited"},
      {"id": 24, "electricity_board": "Assam Power Distribution Company Limited"},
      {"id": 25, "electricity_board": "Tripura State Electricity Corporation Limited"},
      {"id": 26, "electricity_board": "Kerala State Electricity Board Limited"},
      {"id": 27, "electricity_board": "Chamundeshwari Electricity Supply Corporation Limited"},
      {"id": 28, "electricity_board": "Gulbarga Electricity Supply Company Limited"},
      {"id": 29, "electricity_board": "Bangalore Electricity Supply Company Limited"},
      {"id": 30, "electricity_board": "Mangalore Electricity Supply Company Limited"},
      {"id": 31, "electricity_board": "Hubli Electricity Supply Company Limited"},
      {"id": 32, "electricity_board": "Telangana State Southern Power Distribution Company Ltd"},
      {"id": 33, "electricity_board": "Telangana State Northern Power Distribution Company Ltd"},
      {"id": 34, "electricity_board": "Electricity Department, UT of Lakshadweep"},
      {"id": 35, "electricity_board": "Electricity Department, UT of Puducherry"},
      {"id": 36, "electricity_board": "Electricity Department, UT of Andaman & Nicobar"},
      {"id": 37, "electricity_board": "Southern Power Distribution Company of A.P. Limited"},
      {"id": 38, "electricity_board": "Andhra Pradesh Eastern Power Distribution Company Ltd"},
      {"id": 39, "electricity_board": "Tamil Nadu Generation & Distribution Corporation Limited"},
      {"id": 40, "electricity_board": "Uttar Gujarat Vij Company Limited"},
      {"id": 41, "electricity_board": "Madhya Gujarat Vij Company Limited"},
      {"id": 42, "electricity_board": "Paschim Gujarat Vij Company Limited"},
      {"id": 43, "electricity_board": "Dakshin Gujarat Vij Company Limited"},
      {"id": 44, "electricity_board": "Electricity Department, Government of Goa"},
      {"id": 45, "electricity_board": "Madhya Pradesh Madhya Kshetra Vidyut Vitran Company Limited"},
      {"id": 46, "electricity_board": "MP Paschim Kshetra Vidyut Vitran Company Limited"},
      {"id": 47, "electricity_board": "MP Poorv Kshetra Vidyut Vitran Company Limited"},
      {"id": 48, "electricity_board": "Jaipur Vidyut Vitran Nigam Limited"},
      {"id": 49, "electricity_board": "Ajmer Vidyut Vitran Nigam Limited"},
      {"id": 50, "electricity_board": "Jodhpur Vidyut vitran Nigam Limited"},
      {"id": 51, "electricity_board": "Maharashtra State Electricity Distribution Co. Ltd."},
      {"id": 52, "electricity_board": "Brihmanmumbai Electric Supply Company"},
      {"id": 53, "electricity_board": "Chhattisgarh State Power Distribution Company Ltd."},
      {"id": 54, "electricity_board": "Dadra & Nagar Haveli Power Distribution Corporation Ltd"},
      {"id": 55, "electricity_board": "Electricity Department, UT of Daman & Diu"},
      {"id": 56, "electricity_board": "West Bengal State Electricity Distribution Company Limited"},
      {"id": 57, "electricity_board": "Durgapur Project Limited"},
      {"id": 58, "electricity_board": "Jharkhand Bijli Vitran Nigam Limited"},
      {"id": 59, "electricity_board": "North Eastern Supply Company Limited"},
      {"id": 60, "electricity_board": "Southern Electricity Supply Company Limited"},
      {"id": 61, "electricity_board": "Central Electricity Supply Company Limited"},
      {"id": 62, "electricity_board": "Western Electricity Supply Company of Odisha Limited"}
  ]
  ,
    unitData: [],
    currencyData: [],
  },
  reducers: {
    setElectricityRecords: (state, action) => {
      state.electricityRecords = action.payload.data;
    },
    setElectricityInputRecords: (state, action) => {
      state.electricityInputRecords = action.payload;
    },
    setElectricityForm: (state, action) => {
      return {
        ...state,
        electricityForm: {
          ...state.electricityForm,
          ...action.payload,
        },
      };
    },
    resetElectricityForm: (state, action) => {
      state.electricityForm = {};
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    setElectricityTransactionTypeData: (state, action) => {
      state.electricityTransactionTypeData = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload;
    },
    setSiteData: (state, action) => {
      state.siteData = action.payload;
    },
    setSiteDataForFilter: (state, action) => {
      state.siteDataForFilter = action.payload;
    },
   
    setElectricitySourceTypeData: (state, action) => {
      state.electricitySourcesData = action.payload;
    },
    setElectricitySourceFilter: (state, action) => {
      state.ElectricitySourceFilter = action.payload;
    },
    setUseTypeData: (state, action) => {
      state.useTypeData = action.payload;
    },
    setUnitData: (state, action) => {
      state.unitData = action.payload;
    },
    setCurrencyData: (state, action) => {
      
      state.currencyData = action.payload;
    },
    addElectricityData: (state, action) => {
      // const newRecords = [...state.electricityRecords];
      // newRecords.push(action.payload);
      // state.electricityRecords = newRecords;
    },
    setdeleteElectricityData: (state, action) => {
      state.electricityRecords=state.electricityRecords.filter(i=>i.id!=action.payload)
    }
  },
});

export const {
  addElectricityData,setdeleteElectricityData,setSiteDataForFilter,
  setFilters,
  setElectricityForm,
  setElectricityRecordType,
  setElectricityRecords,
  setSorting,
  setCurrentPage,
  setItemsPerPage,
  setTotalCount,
  setTotalPages,
  setCurrencyData,
  setElectricityTypeData,
  setSiteData,
  setUnitData,
  setUseTypeData,
  setElectricityInputRecords,
  setElectricitySourceTypeData,
  setElectricityTransactionTypeData,
  resetElectricityForm,setElectricitySourceFilter,
} = electricitySlice.actions;
export default electricitySlice.reducer;
