
import React, { useState } from 'react';
import GenericForm from './GenericForm';
import DataDisplay from '../../../pages/BRSR/BRSRContent/DataView/DataDisplay';
import { useDispatch, useSelector } from 'react-redux';
import { postSocialPaternityInputs } from '../../../features/social/socialThunk';
import useApi from '../../../customHooks/useApi';

const PaternityForm = ({closePopup}) => {
  const [selectedTab, setSelectedTab] = useState('form');
  const {employementType,siteData,financialYearData}=useSelector(state=>state.social)
  const api=useApi()
  const dispatch=useDispatch()
  const [formData,setFormData]=useState({})
  const parentalLeaveConfig = {
    title: 'Parental Leave Form',
    financialYearOptions: financialYearData, // Adjust the years as needed
    siteOptions: siteData,
    isSiteOption:true,
    sections: [
      {
        key: 'metrics',
        label: 'Parental Leave Metrics',
        rows: [
          { key: 'entitled_to_leave', label: 'Number of Employees entitled to Parental Leave' },
          { key: 'took_leave', label: 'Number of Employees who took Parental Leave' },
          { key: 'returned_after_leave', label: 'Number of Employees that returned to work in the reporting period after Parental Leave ended' },
          { key: 'returned_still_employed', label: 'Number of Employees that returned to work after Parental Leave ended that were still Employed 12 months after their return to work' },
        ],
        fields: [
          { key: 'male', label: 'Male', type: 'number' },
          { key: 'female', label: 'Female', type: 'number' },
          { key: 'other', label: 'Other', type: 'number' },
          { key: 'not disclosed', label: 'Not Disclosed', type: 'number' },
        ],
      },
    ],
  };
  function handleSubmitData(data) {
    dispatch(postSocialPaternityInputs({api,formData:data,employementType
    }))
    closePopup()
    setFormData(data) 
    // console.log(data)
  }
  const renderTab = () => {
    switch (selectedTab) {
      case "form":
        return <GenericForm config={parentalLeaveConfig

        } handleSubmitData={handleSubmitData} />;
      case "display":
        return <DataDisplay config={parentalLeaveConfig

        } data={formData}/>;
      default:
        return null;
    }
  };
  return (
    <div className='h-full w-full p-2 px-6 overflow-auto'>
        {renderTab()}
    </div>
  );
};

export default PaternityForm;
