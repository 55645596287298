import React, { useState } from "react";
import { ReactComponent as DownloadIcon } from "../../../app/assets/DownloadIcon.svg";

function Tabs({ tabs }) {
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const renderActiveTab = () => {
    const activeTabData = tabs.find(tab => tab.id === activeTab);
    if (activeTabData) {
      const TabComponent = activeTabData.component;
      return <TabComponent />;
    }
    return null;
  };

  return (
  <div >

 
      <div className="flex flex-row w-full h-12 justify-between items-center py-2  mt-3">
      
          <div className="flex flex-row justify-start items-center ml-4">
            {tabs.map(tab => (
              <div
                key={tab.id}
                className={`flex flex-row justify-center items-center w-fit px-4  h-8 cursor-pointer border border-green-600 rounded-md mr-4 ${
              activeTab === tab.id
                ? "bg-[#02AB6C] text-white"
                : "bg-white text-green-600"
            }`}
            onClick={() => setActiveTab(tab.id)}
              >
                <tab.icon className="w-4 h-4 mr-2 fill-current" />
                <span className="text-xs">{tab.name}</span>
              </div>
            ))}
         
         
        </div> <div className="flex flex-row justify-end items-center">
        
          <a href="./savedFile.csv" download className="hidden" >

          <div  className="flex flex-row justify-center items-center w-28 h-8 cursor-pointer border border-green-600 rounded-md text-green-600 hover:bg-[#02AB6C] hover:text-white mr-2">
          

            <span className="mr-2">
              <DownloadIcon className=" fill-current w-6 h-6" />
            </span>
            <span className="text-xs">Download</span>
           
          </div> 

          </a>
        </div> </div>
        <div className="flex-1 overflow-y-auto p-4">{renderActiveTab()}</div>
     
    </div>
  );
}

export default Tabs;
