import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useApi from '../../../../customHooks/useApi'

import { populateComplaintsData, populateRehabilitation, populateRehabilitationOHS } from '../../function/functionProcess'

import {  fetchSocialRehabilitationInputs } from '../../../../features/social/socialThunk'
import GenericTiles from './GenericTile'


function EmployeeRehabilitationPage() {
  const {socialRehabilitedRecords,employementType}=useSelector(state=>state.social)
  const dispatch=useDispatch()
  
  const api =useApi()
  useEffect(()=>{
  //  dispatch(resetSocialFormData({}))
dispatch(fetchSocialRehabilitationInputs({api,params:{}}))
  },[])
  return (
    <div className='overflow-auto h-full w-full'>
       {/* {Object.keys(populatedData).length > 0 ? (
        Object.keys(populatedData).map(year => (
          <GenericTiles key={year} data={ populatedData[year] } />
        ))
      ) : (
        <p>No data available</p>
      )} */}
   <GenericTiles data={populateRehabilitation(socialRehabilitedRecords,employementType)} title="Employee Overview"
  categoryHeaders={["Number of Individual affected", "Number of Individual/Family member rehabilitated or placed in suitable employment"]}
  dataKey="Employee Type " />
    </div>
  )
}

export default EmployeeRehabilitationPage
