import Header from "../../components/common/Header";
import { ReactComponent as ElectricityIcon } from "../../app/assets/ElectricityIcon.svg";
import { ReactComponent as CopyIcon } from "../../app/assets/CopyIcon.svg";
import { ReactComponent as DownloadIcon } from "../../app/assets/DownloadIcon.svg";
import { ReactComponent as PencilIcon } from "../../app/assets/PencilIcon.svg";
import { ReactComponent as DeleteIcon } from "../../app/assets/DeleteIcon.svg";

import { ReactComponent as AddIconBig } from "../../app/assets/AddIconBig.svg";
import UserIcon from "../../app/assets/UserIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import {
  resetElectricityForm,
  setCurrentPage,
  setElectricityForm,
  setFilters,
  setItemsPerPage,
} from "../../features/energy/electricity/electricitySlice";

import Table from "../../components/common/Table/Table";
import TablePagination from "../../components/common/Table/TablePagination";
import PurchaseElecricityForm from "../../components/energy/electricity/PurchaseElectricityForm";

import TableMeta from "../../components/common/Table/TableMeta";
import PurchaseElectricityEditAndCopyForm from "../../components/energy/electricity/PurchaseElectricityEditAndCopyForm";

import {
  deleteElectricityData,
  fetchCurrencyData,
  fetchElectricityInputData,
  fetchElectricitySourcesTypeData,
  fetchSiteData,
  fetchTransactionTypeData,
  fetchUnitData,
} from "../../features/energy/electricity/electricityThunk";
import { toast } from "react-toastify";
import useApi from "../../customHooks/useApi";
import FilterComponent from "../../components/common/FilterComponent";
import { useSearchParams } from "react-router-dom";

const ElectricityPage = () => {
  const { read_only } = useSelector((state) => state.auth);

  const {
    electricityRecordType,
    siteDataForFilter,
    electricityRecords,
    itemsPerPage,
    totalPages,
    totalCount,
    currentPage,
    sourceTypeData,
    ElectricitySourceFilter,
    transaction_type,
  } = useSelector((state) => state.electricity);
  const [searchParams, setSearchParams] = useSearchParams();

  const [dataRows, setDataRows] = useState([]);
  const [files, setFiles] = useState(null);
  const [isPurchaseFormOpen, setPurchaseFormOpen] = useState(false);
  const api = useApi();
  const [startDate, setStartDate] = useState("");
  const [manualEntry, setManualEntry] = useState(true);
  const [apiIntegration, setApiIntegration] = useState(false);
  const [trendLine, setTrendLine] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isEditCopyFormOpen, setIsEditCopyFormOpen] = useState(false);
  const handleCloseEditCopyForms = () => {
    setIsEditCopyFormOpen(false);
  };
  const filters = [
    // {
    //   name: "bill_date",
    //   label: "Bill date",
    //   options: [],
    //   type: "date",
    // },
    {
      name: "site",
      label: "Facility",
      options:
        siteDataForFilter &&
        siteDataForFilter.map((site) => {
          return {
            value: site.id,
            label: site.site,
          };
        }),
    },
    {
      name: "electricity_source",
      label: "Electricity source",
      options:
        ElectricitySourceFilter &&
        ElectricitySourceFilter.map((item) => {
          return {
            value: item.id,
            label: item.electricity_source,
          };
        }),
    },
    {
      name: "source_type",
      label: "Source type",
      options:
        sourceTypeData &&
        sourceTypeData.map((item) => {
          return {
            value: item,
            label: item,
          };
        }),
    },
    {
      name: "transaction_type",
      label: "Transaction type",
      options: ["Captive", "Purchased"].map((item) => {
        return {
          value: item,
          label: item,
        };
      }),
    },
  ];
  const handleFilterChange = (newFilters) => {
    const updatedParams = new URLSearchParams(newFilters).toString();
    setSearchParams(updatedParams);
    dispatch(setFilters(updatedParams))
 
    dispatch(fetchElectricityInputData({ api, params: newFilters }));
    // console.log(newFilters);
  };
  const dispatch = useDispatch();

  //  Handle edit , add and copy
  const openPopupForm = () => {
    dispatch(resetElectricityForm());

    setPurchaseFormOpen(true);
  };
  const handleDelete = (row) => {
    if (row.id) {
      dispatch(deleteElectricityData({ api, id: row.id }));
    } else {
      toast.error("Delete failed please try again");
    }
  };
  const closePurchasePopupForm = () => {
    setPurchaseFormOpen(false);
  };
  const handleEdit = (row) => {
    setStartDate(row["bill_start"]); // for seting start date in form

    setActionType("edit");
    setSelectedRowData(row); // Set the selected row data
    setIsEditCopyFormOpen(true);
  };
  const handleCopy = (selectedData) => {
    setStartDate(selectedData["bill_start"]);
    setSelectedRowData(selectedData); // Set the selected row dat
    setActionType("copy");
    setIsEditCopyFormOpen(true);
  };

  // maping Table head
  const headingsToDataKeyMap = {
    " ": "",
    "BILL DATE": "bill_date",
    "BILLING START PERIOD": "bill_start",
    "BILLING END PERIOD": "bill_end",
    FACILITY: "site",
    "ELECTRICITY SOURCE": "electricity_source",
    "SOURCE TYPE": "source_type",
    "TRANSACTION TYPE": "transaction_type",
    "ELECTRICITY BOARD": "electricity_board",
    "UNIT USED": "unit_used",
    UNIT: "unit",
    "AMOUNT PAID": "amount_paid",
    CURRENCY: "currency",
    "EMISSION FACTOR": "emission_factor",
    EVIDENCE: "evidence",
    STATUS: "status",
    // FEEDBACK: "feedback",
    // "SUBMITTED BY": "submitted_by",
    // "APPROVED BY": "approved_by",
    // EDIT: "edit",
    // DELETE: "delete",
  };
  if (!read_only) {
    headingsToDataKeyMap.EDIT = "edit";
    headingsToDataKeyMap.DELETE = "delete";
  }
  const switchTab = (tabId) => {
    switch (tabId) {
      case 1:
        setManualEntry(true);
        setApiIntegration(false);
        setTrendLine(false);
        break;
      case 2:
        setManualEntry(false);
        setApiIntegration(true);
        setTrendLine(false);
        break;
      case 3:
        setManualEntry(false);
        setApiIntegration(false);
        setTrendLine(true);
        break;
      default:
        setManualEntry(true);
        setApiIntegration(false);
        setTrendLine(false);
        break;
    }
    setSelectedTab(tabId);
  };
  const [paginationConfig, setPaginationConfig] = useState({
    paginationEnabled: true,
    currentPage: 1,
    totalPage: 1,
    itemsPerPage: 10,
    handleItemsPerPage: null,
    handlePageChange: null,
  });

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const handleItemsPerPage = (itemCount) => {
    dispatch(setItemsPerPage(itemCount));
  };

  const handleFileDownload = (filePath) => {
    if (filePath) {
      const link = document.createElement("a");
      link.href = filePath;

      link.download = "filename.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast("No evidance atteched");
    }
  };

  const generateCellHtml = (row, k) => {
    let cellHtml = null;
    switch (k) {
      case "electricity_resource":
        cellHtml = (
          <span className="w-full flex justify-center">
            <span
              className={`px-2 py-1 border border-transparent rounded-md ${
                row["electricity_source"] === "wind"
                  ? "bg-green-200 text-green-600"
                  : row["electricity_source"] === "hydro"
                  ? "text-blue-800 bg-blue-200"
                  : row["electricity_source"] === "solar"
                  ? "text-yellow-700 bg-yellow-200"
                  : row["electricity_source"] === "gas" ||
                    row["electricity_source"] === "diesel"
                  ? "text-red-600 bg-red-200"
                  : row["electricity_source"] === "mixed renewable"
                  ? "bg-green-200 text-green-600"
                  : "text-black bg-gray-200"
              }`}
            >
              {row[k]}
            </span>
          </span>
        );
        break;
      case "status":
        cellHtml = (
          <span className="w-full flex justify-center">
            <span
              className={`px-2 py-1 border border-transparent rounded-md ${
                row[k] === "Accepted"
                  ? "bg-green-600 text-white"
                  : "bg-red-200 text-red-600"
              }`}
            >
              {row[k] || "Pending"}
            </span>
          </span>
        );
        break;
      case "evidence":
        cellHtml = (
          <span className="w-full flex justify-center">
            <DownloadIcon
              className="cursor-pointer fill-green-500"
              onClick={() => handleFileDownload(row[k])}
            />
          </span>
        );
        break;
      case "submitted_by":
      case "approved_by":
        cellHtml = (
          <span className="w-full flex justify-center">
            <img
              src={`${UserIcon}`}
              className="cursor-pointer w-[32px] h-[32px] border border-transparent rounded-[50%]"
              alt={`${k}`}
            />
          </span>
        );
        break;
      case "edit":
        cellHtml = (
          <span className="w-full flex justify-center">
            <PencilIcon
              className="cursor-pointer"
              onClick={() => handleEdit(row)}
            />
          </span>
        );
        break;
      case "delete":
        cellHtml = (
          <span className="w-full flex justify-center">
            <DeleteIcon
              className="cursor-pointer"
              onClick={() => handleDelete(row)}
            />
          </span>
        );
        break;
      default:
        cellHtml = <span className="w-full flex justify-center">{row[k]}</span>;
        break;
    }
    return cellHtml;
  };

  useEffect(() => {
    let transformedDataRows =
      electricityRecords &&
      electricityRecords?.map((row) => {
        let transformedDataRow = [];
        transformedDataRow.push(
          <span className="w-full flex justify-center">
            <CopyIcon
              className={`${
                read_only ? "cursor-not-allowed opacity-15" : "cursor-pointer"
              }`}
              onClick={() => !read_only && handleCopy(row)}
            />
          </span>
          //    <span className="w-full flex justify-center">
          //    <PencilIcon className="cursor-pointer" onClick={()=>handleEdit(row)} />
          //  </span>,
          //  <span className="w-full flex justify-center">
          //    <DeleteIcon className="cursor-pointer" onClick={()=>handleDelete(row)} />
          //  </span>
        );
        Object.values(headingsToDataKeyMap).forEach((k) => {
          if (k.toString().trim() !== "") {
            transformedDataRow.push(generateCellHtml(row, k));
          }
        });
        return transformedDataRow;
      });

    setDataRows(transformedDataRows);
    setPaginationConfig({
      ...paginationConfig,
      currentPage: currentPage,
      totalPages: totalPages,
      itemsPerPage: itemsPerPage,
      handleItemsPerPage: handleItemsPerPage,
      handlePageChange: handlePageChange,
    });
  }, [electricityRecords]);

  useEffect(() => {}, [itemsPerPage, currentPage]);
  useEffect(() => {
    const filters = Object.fromEntries(searchParams.entries());
    async function initializeData() {
      await dispatch(fetchElectricityInputData({ api, params: filters }));
      await dispatch(fetchCurrencyData({ api }));
      await dispatch(fetchSiteData({ api }));
      // dispatch(fetchTransactionTypeData({ electricity_source_id:"", api }));
      await dispatch(fetchElectricitySourcesTypeData({ api }));
    }
    initializeData();
  }, []);
  //  handle add ,edit and copy electricityform
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    // Calendar Validation
    if (name == "electricity_source") {
      dispatch(fetchTransactionTypeData({ electricity_source_id: value, api }));
      dispatch(fetchUnitData({ id: value, api }));
    }
    if (name === "bill_start") {
      setStartDate(value);
    }
    if (name === "evidence") {
      const files = e.target.files;
      setFiles(files);
    }
    //  implemented other input for electricity_board
    if (value === "other") {
      setIsOtherSelected(true);
      dispatch(setElectricityForm({ [name]: "" }));
    } else if (
      value !== "other" &&
      e.target.tagName === "SELECT" &&
      name === "electricity_board"
    ) {
      setIsOtherSelected(false);
    }
    dispatch(setElectricityForm({ [name]: value }));
  };
  return (
    <>
      <Header PageIcon={ElectricityIcon} pageTitle={"Electricity"} />

      <div className="flex flex-col main-container w-full px-10 py-6">
        <div className="flex  justify-between items-center text-xs">
          <div className="flex"></div>
          <div className="flex">
            <FilterComponent
              filters={filters}
              onFilterChange={handleFilterChange}
            />
          </div>
        </div>
        <div className="flex flex-col border border-gray-300 rounded-md mt-[18px] relative">
          <TableMeta
            recordType={electricityRecordType}
            totalCount={totalCount}
            openPopUpForm={openPopupForm}
            switchTab={switchTab}
            selectedTab={selectedTab}
          />
          {manualEntry && (
            <>
              {dataRows && (
                <Table
                  // height="71.9vh"
                  headings={Object.keys(headingsToDataKeyMap)}
                  dataRows={dataRows}
                  paginationObject={paginationConfig}
                />
              )}
              {!read_only && (
                <div className="flex  w-fit py-4 px-2 absolute bottom-8 right-6 cursor-pointer">
                  {/* Add */}
                  <AddIconBig
                    onClick={() => openPopupForm(electricityRecordType)}
                  />
                </div>
              )}
              {paginationConfig &&
                paginationConfig?.paginationEnabled &&
                paginationConfig?.handleItemsPerPage &&
                typeof paginationConfig?.handleItemsPerPage === "function" &&
                paginationConfig?.handlePageChange &&
                typeof paginationConfig?.handlePageChange === "function" && (
                  <TablePagination paginationObject={paginationConfig} />
                )}{" "}
            </>
          )}

          {apiIntegration && (
            <>
              <h1>API Integration tab</h1>
            </>
          )}
          {trendLine && (
            <>
              <h1>TrendLine tab</h1>
            </>
          )}
        </div>
      </div>
      {isPurchaseFormOpen && (
        <PurchaseElecricityForm
          files={files}
          handleFormChange={handleFormChange}
          closePurchasePopupForm={closePurchasePopupForm}
          isOtherSelected={isOtherSelected}
          startDate={startDate}
        />
      )}
      {isEditCopyFormOpen && (
        <PurchaseElectricityEditAndCopyForm
          selectedRowData={selectedRowData}
          closePurchasePopupForm={handleCloseEditCopyForms}
          actionType={actionType}
          startDate={startDate}
        />
      )}
    </>
  );
};

export default ElectricityPage;
