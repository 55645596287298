import React, { useEffect, useState } from "react";
import Header from "../../components/common/Header";
import AnalyzeHeader from "../../components/Analyze/AnalyzeHeader";
import { ReactComponent as Scope3Icon } from "../../app/assets/Category4.svg";
import { ReactComponent as FilterMenuIcon } from "../../app/assets/FilterMenuIcon.svg";

import {
  currentYearLastYearEmissionDetail,
  getCurrentFinancialYear,
} from "../VisualizationFeature/Redux/Processdata";
import { useDispatch, useSelector } from "react-redux";
import { fetchChartScope3Category9DataThunk } from "../VisualizationFeature/Redux/chartthunk";
import Filter from "../VisualizationFeature/components/Filter";
import Category9Main from "./scope3/Category9Main";
import useApi from "../../customHooks/useApi";

function AnalyzeScope3Category9() {
  const api = useApi();
  const dispatch = useDispatch();
  const [emissionChange, setEmissonChange] = useState({});

  const {
    s3c9financialYear,
    s3c9siteOptions,
    supplierOptions,
    itemOptions,
    logisticModeOptions,
    Scope3Category9Data,
    Scope3Category9DataForCurrentYearChange,
  } = useSelector((state) => state.chart);
  const [currentFinancialYear, setCurrentFinancialYear] = useState(
    s3c9financialYear.length ? s3c9financialYear[0] : ""
  );
  const [filterBy, setFilterBy] = useState({
    supplier: "",
    item: "",
    logistic_mode: "",
    site: "",
  });
  const [StackBreakDown] = useState(["item"]);
  const [BreakDownOptions] = useState([
    { value: "site", label: "Site" },
    { value: "item", label: "Item name" },
    { value: "logistic_mode", label: "Logistic mode" },
  ]);
  const [chartDataArray] = useState([
    {
      title: "Emissions by Logistic Mode and Vehicle Type",
      L1: "logistic_mode",
      L2: "vehicle_type",
    },
    {
      title: "Emissions by Fuel Type and Logistic Mode ",
      L1: "energy_source",
      L2: "logistic_mode",
    },
    {
      title: "Emissions by Supplier and Item",
      L1: "supplier",
      L2: "item_carried",
    },
    {
      title: "Emissions by Item and Supplier",
      L1: "item_carried",
      L2: "supplier",
    },
  ]);
  const [piechartDataArray] = useState([

    { title: "Emissions by Source Location", dataKey: "source_location" },

    { title: "Emissions by Facility", dataKey: "site" },
    

    { title: "Emissions by Logistic Mode ", dataKey: "logistic_mode" },
    { title: "Emissions by Vehicle Type", dataKey: "vehicle_type" },
    {
      title: "Emissions by Vehicle Specification",
      dataKey: "vehicle_specification",
    },
    { title: "Emissions by Fuel Type", dataKey: "energy_source" },
    { title: "Emissions by Item", dataKey: "item_carried" },
    { title: "Emissions by  Supplier", dataKey: "supplier" },

  ]);

  const emissionData = [
    {
      title: "Emissions",
      info: (emissionChange?.currentYear || 0) + " Kg Co2e",
      time: "",
      status:
        (emissionChange?.percentageChange || 0) > 0 ? "positive" : "negative",
      per: `${
        emissionChange?.percentageChange !== Infinity
          ? `${Math.abs(emissionChange?.percentageChange) || 0}% YOY${
              emissionChange?.percentageChange > 0 ? "↑" : "↓"
            }`
          : ""
      }`,
      year: currentFinancialYear,
      theme: "red",
    },
  ];

  const handleFilterChange = (name, value) => {
    if (name === "financialYear") {
      setCurrentFinancialYear(value);
    } else {
      setFilterBy((prevFilterBy) => ({
        ...prevFilterBy,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    setEmissonChange(
      currentYearLastYearEmissionDetail(
        Scope3Category9DataForCurrentYearChange,
        Number(currentFinancialYear)
      )
    );
  }, [Scope3Category9Data, currentFinancialYear]);
  useEffect(() => {
    dispatch(fetchChartScope3Category9DataThunk({ api }));
  }, []);
  useEffect(() => {
    if (s3c9financialYear.length > 0) {
      setCurrentFinancialYear(s3c9financialYear[0]);
    }
  }, [s3c9financialYear]);
  return (
    <>
      <Header
        PageIcon={Scope3Icon}
        pageTitle={"Downstream Transportation and Distribution"}
      />
      <div className="flex flex-col main-container w-full px-10 py-6">
        <AnalyzeHeader emissionData={emissionData} energyData={[]} />
        <div className="flex flex-col  rounded-md mt-[18px]  w-full border p-2">
          <div className="flex justify-between py-2 items-center  bg-gray-100 rounded">
            <span className="text-xl px-2"></span>
            <div className="flex justify-between items-center gap-2 px-2">
              <FilterMenuIcon className="cursor-pointer" />
              <Filter
                label="Financial Year"
                options={s3c9financialYear}
                selectedValue={currentFinancialYear}
                onChange={(value) => handleFilterChange("financialYear", value)}
              />
              <Filter
                label="Facility"
                options={s3c9siteOptions}
                selectedValue={filterBy.site}
                onChange={(value) => handleFilterChange("site", value)}
              />
               <Filter
                label="Logistic mode"
                options={logisticModeOptions}
                selectedValue={filterBy.logistic_mode}
                onChange={(value) => handleFilterChange("logistic_mode", value)}
              />
              <Filter
                label="Item"
                options={itemOptions}
                selectedValue={filterBy.item}
                onChange={(value) => handleFilterChange("item", value)}
              />
             
              <Filter
                label="Supplier"
                options={supplierOptions}
                selectedValue={filterBy.supplier}
                onChange={(value) => handleFilterChange("supplier", value)}
              />
            </div>
          </div>
          <div>
            <Category9Main
              piechartDataArray={piechartDataArray}
              StackBreakDown={StackBreakDown}
              Options={BreakDownOptions}
              currentFinancialYear={
                isNaN(Number(currentFinancialYear))
                  ? s3c9financialYear[0]
                  : currentFinancialYear
              }
              height={"500px"}
              width={"100%"}
              filterBy={filterBy}
              data={Scope3Category9Data}
              chartDataArray={chartDataArray}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AnalyzeScope3Category9;
