import React from "react";


const RegisterSuccessPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="max-w-md p-8 bg-white rounded-lg shadow-lg text-center">
        <p className="text-2xl font-semibold mb-4">
          Organization account is created, as per your request.
        </p>
        <p className="text-lg mb-4">
          Please provide the username and password to the organization. On
          first login, the organization will get an option to reset the
          password.
        </p>
       
      </div>
    </div>
  );
};

export default RegisterSuccessPage;
