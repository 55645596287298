import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useApi from '../../../../customHooks/useApi'

import { populateContractual } from '../../function/functionProcess'

import {  fetchSocialContratualEmpDetailsInputs } from '../../../../features/social/socialThunk'
import GenericTiles from './GenericTile'
import ContractualTable from '../../../BRSR/BRSRContent/DataView/DisplayContrsactual'


function EmployeeContractualPage() {
  const {socialContratualEmpDetailsRecords,employementType}=useSelector(state=>state.social)
  const dispatch=useDispatch()
    // Memoize the populated data
    const populatedData = useMemo(() => 
      populateContractual(socialContratualEmpDetailsRecords, employementType), 
      [socialContratualEmpDetailsRecords, employementType]
    );
  const api =useApi()
  useEffect(()=>{
  //  dispatch(resetSocialFormData({}))
dispatch(fetchSocialContratualEmpDetailsInputs({api,params:{}}))
  },[])
  return (
    <div className='overflow-auto h-full w-full'>
     <ContractualTable data={socialContratualEmpDetailsRecords} />
    </div>
  )
}

export default EmployeeContractualPage
