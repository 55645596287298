import { createSlice } from "@reduxjs/toolkit";

// Assume you have thunks for login and logout (similar to the previous example)

const scope3Category7Slice = createSlice({
  name: "Scope3Category7",
  initialState: {
    modeOfTravel: [
   
      { id: 3, mode_of_travel: "Road" },
      { id: 4, mode_of_travel: "Rail" },
    ],
    scope3Category7ItemData: [],
    scope3Category7Form: {},
    scope3Category7InputRecords: [],
    typeData: [],
    subType: [],
    siteData: [],
    siteDataForFilter: [],
    pincodes: [],
    modeOfTravelMapping: [],
    subTypeFiltred: [],
    filtredFuelTypeData: [],
    filteredTypeData: [],
    airConditioned: true,
    fuelType: [],

    filters: null,
    sorting: null,

    scope3Category7RecordType: 1,
    itemsPerPage: 10,
    totalPages: 1,
    currentPage: 1,
    totalCount: 10,
    source: "",
    destination: "",
    expenseUnit: [],
  },
  reducers: {
    setscope3Category7InputRecords: (state, action) => {
      state.scope3Category7InputRecords = action.payload;
    },
    setscope3Category7Form: (state, action) => {
      return {
        ...state,
        scope3Category7Form: {
          ...state.scope3Category7Form,
          ...action.payload,
        },
      };
    },
    resetscope3Category7Form: (state, action) => {
      state.scope3Category7Form = {};
    },
    setEvidence: (state, action) => {
      state.scope3Category7Form.evidence = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },

   
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPincodes: (state, action) => {
      state.pincodes = action.payload;
    },
    settypeData: (state, action) => {
      state.typeData = action.payload;
    },
    setSubtypeData: (state, action) => {
      state.subType = action.payload;
    },
    setmodeOfTravelMappingData: (state, action) => {
      state.modeOfTravelMapping = action.payload;
    },
    setSubtypeDataFiltred: (state, action) => {
      state.subTypeFiltred = action.payload;
    },
    setfiltredFuelTypeData: (state, action) => {
      state.filtredFuelTypeData = action.payload;
    },
    setfilteredTypeData: (state, action) => {
      state.filteredTypeData = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload;
    },
    setsource: (state, action) => {
      state.source = action.payload;
    },
    setdestination: (state, action) => {
      state.destination = action.payload;
    },
    setfuelType: (state, action) => {
      state.fuelType = action.payload;
    },

    setexpenseUnit: (state, action) => {
      state.expenseUnit = action.payload;
    },
    setSiteData: (state, action) => {
      state.siteData = action.payload;
    },
    setSiteDataForFilter: (state, action) => {
      state.siteDataForFilter = action.payload;
    },
    setdeletes3c7Data: (state, action) => {
      state.scope3Category7InputRecords = state.scope3Category7InputRecords.filter(
        (i) => i.id != action.payload
      );
    },
  },
});

export const {
  resetscope3Category7Form,setdeletes3c7Data,
  setscope3Category7ItemData,setPincodes,
  setSubtypeDataFiltred,
  setmodeOfTravelMappingData,
  setfuelType,
  setSubtypeData,
  setFilters,
  setEvidence,
  setscope3Category7Form,
setSiteData,
  setscope3Category7Records,
  setSorting,
  setCurrentPage,
  setItemsPerPage,
  settypeData,
  setTotalCount,
  setTotalPages,
  setsource,
  setexpenseUnit,
  setscope3Category7InputRecords,
  setscope3Category7CountryData,setdestination,setfilteredTypeData,setfiltredFuelTypeData,setSiteDataForFilter
} = scope3Category7Slice.actions;
export default scope3Category7Slice.reducer;
