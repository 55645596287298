import { createAsyncThunk } from "@reduxjs/toolkit";
import { getOrganizationId } from "../../../../components/common/parseJwt";
import {
  setCat5Data,
  setdataForCurrentYearChange,
  setFinancialYear,
  setSiteOptions,
  setwasteMaterialOptions,
  setwasteTreatmentOptions,
} from "./analyzeSlice";
import { setIsLoading } from "../../../../features/common/commonSlice";
import { toast } from "react-toastify";

export const fetchCat5DataThunk = createAsyncThunk(
  "analyzeCategory5/fetchCat5DataThunk",
  async ({ api }, { getState, dispatch }) => {
    const organization_id = getOrganizationId();
    const apiUrl = `/scope3/category5/data/${organization_id}`;
    dispatch(setIsLoading(true));
    try {
      dispatch(setIsLoading(true));

      const response = await api.get(`${apiUrl}`);
      const data = response?.data?.data.map((item) => {
        // const data=sample.map((item) => {

        return {
          ...item,
          site: item?.site,
          year: Number(item.year),
          quarter: Number(item.quarter),
          usage_in_kwh:0,
          produced_quantity:Number(item.produced_quantity),
          treated_quantity:Number(item.treated_quantity),
          total_co2e_kg: Number(item.total_kg_co2e_per_unit
          ),
        };
      });
      dispatch(setIsLoading(false));

      const siteOptions = Array.from(new Set(data?.map((entry) => entry.site)));
      const wasteOptions = Array.from(
        new Set(data?.map((entry) => entry.waste_material))
      );
      const treatmentOptions = Array.from(
        new Set(data?.map((entry) => entry.treatment_mechanism))
      );
      const financialYearOptions = Array.from(
        new Set(data?.map((entry) => entry.year))
      );
      dispatch(setwasteMaterialOptions(wasteOptions));
      dispatch(setwasteTreatmentOptions(treatmentOptions.filter(i=>i)));

      dispatch(setdataForCurrentYearChange(data));
      dispatch(setCat5Data(data));

      dispatch(setSiteOptions(siteOptions));
      dispatch(setFinancialYear(financialYearOptions.sort((a, b) => b - a)));
    } catch (error) {
      dispatch(setIsLoading(false));
      console.error("Error fetching chart data:", error);
      toast.error(
        error?.response?.data?.message || "Error getting information"
      );
      return error?.response?.data;
    }
  }
);
