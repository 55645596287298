import React, { useState } from 'react';
import GenericForm from './GenericForm';
import DataDisplay from '../../../pages/BRSR/BRSRContent/DataView/DataDisplay';
import { useDispatch, useSelector } from 'react-redux';
import { postSocialOhsSystemCoverageNonEmployeesInputs } from '../../../features/social/socialThunk';
import useApi from '../../../customHooks/useApi';

const OHSForm  = ({closePopup}) => {
  const [selectedTab, setSelectedTab] = useState('form');
  const dispatch=useDispatch()
  const {employementType,siteData,financialYearData}=useSelector(state=>state.social)
  const api=useApi()
  const [formData,setFormData]=useState({})
  const config = {
    siteOptions: siteData, 
    isSiteOption:true,
    financialYearOptions: financialYearData,
    sections: [
      {
        key: "ohsCoverage",
        label: "OHS System Coverage for Non-Employees",
        rows: [
          {
            label: "Number of Employees covered by System",
            key: "Number of Employees covered by System",
          },
          {
            label: "Number of Employees covered by System that have been internally audited",
            key: "Number of Employees covered by System that have been internally audited",
          },
          {
            label: "Number of Employees covered by System who are covered by such a system that has been audited or certified by an external party",
            key:
              "Number of Employees covered by System who are covered by such a system that has been audited or certified by an external party",
          },
        ],
        fields: [
          {
            key: "count",
            label: "Count",
            type: "number", // Input type
            placeholder: "Enter count",
          },
        ],
      },
    ],
  };

  
  function handleSubmitData(data) {
    setFormData(data) 
    // console.log(data)
    dispatch(postSocialOhsSystemCoverageNonEmployeesInputs({api,formData:data}))
    // setSelectedTab('display');
    closePopup()
  }
  const renderTab = () => {
    switch (selectedTab) {
      case "form":
        return <GenericForm config={config

        } handleSubmitData={handleSubmitData} />;
      case "display":
        return <DataDisplay config={config

        } data={formData}/>;
      default:
        return null;
    }
  };
  return (
    <div className='h-full w-full p-2 px-6 overflow-auto'>
        {renderTab()}
    </div>
  );
};

export default OHSForm;