import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useApi from '../../../../customHooks/useApi'

import {  populateParentalLeaveData, transformApiData } from '../../function/functionProcess'

import {  fetchSocialPaternityInputs } from '../../../../features/social/socialThunk'
import GenericTiles from './GenericTileSiteWise'

function Paternity() {
  const {socialPaternityRecords,employementType}=useSelector(state=>state.social)
  const dispatch=useDispatch()
  
  const api =useApi()
  useEffect(()=>{
  
dispatch(fetchSocialPaternityInputs({api,params:{}}))
  },[])
  return (
    <div className='overflow-auto h-full w-full'>
   <GenericTiles data={populateParentalLeaveData(socialPaternityRecords,employementType)} title="Employee Overview"
  categoryHeaders={["Male", "Female", "Other", "Not Disclosed"]}
  dataKey="Paternity Leave Metrics" />
    </div>
  )
}

export default Paternity
