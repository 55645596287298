import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import {  fetchSocialOhsSystemCoverageNonEmployeesInputs } from '../../../../features/social/socialThunk';
import useApi from '../../../../customHooks/useApi';
import { transformDataOHS } from '../../function/functionProcess';
import GenericTiles from './GenericTileSiteWise';

function SocialOhsSystemCoverageNonEmployees() {
  const {socialOHSSystemCoverageRecords,employementType}=useSelector(state=>state.social)
  // const {isLoading}=useSelector(state=>state.common)
  const dispatch=useDispatch()
  const api =useApi()
  useEffect(()=>{
dispatch(fetchSocialOhsSystemCoverageNonEmployeesInputs({api,params:{}}))
  },[])
 
  return (
    <div className='overflow-auto h-full w-full'>
    <GenericTiles data={transformDataOHS(socialOHSSystemCoverageRecords,employementType)} title="Employee Overview"
  categoryHeaders={["Count"]}
  dataKey="Metric" />
    </div>
  )
}

export default SocialOhsSystemCoverageNonEmployees
