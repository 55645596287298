export const TableHeader = () => (
    <thead>
      <tr className="bg-gray-100">
        <th className="p-4 text-left text-gray-600">Disclosure Code</th>
        <th className="p-2 py-2 text-left text-gray-600 ">Disclosure</th>
        <th className="p-4 text-center text-gray-600">Disclosure Type</th>
        <th className="p-4 text-center text-gray-600">Status</th>
        <th className="p-4 text-left text-gray-600">Comment</th>
        <th className="p-2 px-2 text-center text-gray-600"></th>
      </tr>
    </thead>
  );