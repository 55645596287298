import { createAsyncThunk } from "@reduxjs/toolkit";
import { setIsLoading } from "../../common/commonSlice.js";
import { toast } from "react-toastify";
import {
  resetscope3Category7Form,
  setPincodes,
  setSiteData,
  setSiteDataForFilter,
  setSubtypeData,
  setdeletes3c7Data,
  setexpenseUnit,
  setfuelType,
  setmodeOfTravelMappingData,
  setscope3Category7Form,
  setscope3Category7InputRecords,
  setsource,
  settypeData,
} from "./Scope3Category7Slice.js";
import { getOrganizationId } from "../../../components/common/parseJwt.js";
import { removeFalsyValues } from "../../../components/common/removeFaslyValues.js";


export const fetchSiteData = createAsyncThunk(
  "scope3Category7/fetchSiteData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id=getOrganizationId()
    try {
      const response = await api.get(
        `/scope3/category7/${organization_id}/sites`,
        {
          // headers: {
          //   Authorization: `Bearer ${accessToken}`,
          // },
        }
      );

      if (response.status === 200 && response.data.success) {
        dispatch(setSiteData(response.data.data));
        dispatch(setSiteDataForFilter(response.data.Filters));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchscope3Category7InputData = createAsyncThunk(
  "scope3Category7/fetchscope3Category7InputData",
  async ({ api,params }, { getState, dispatch }) => {
    const pincodes=getState().scope3Category7.pincodes
    dispatch(setIsLoading(true));
    const organization_id = getOrganizationId();

    let apiUrl = "/scope3/category7/inputdata/" + organization_id;

    try {
      const response = await api.get(`${apiUrl}`,{
        params:removeFalsyValues(params)
      });

      if (response.status === 200 && response.data.success) {
        let maped=response?.data?.data.map((item) => {
              return {
                id:item.id,
                "pincode":item.pincode,
                joining_date:item.joining_date,
                site:item.site,
                status: item.status,
                expense: item.expense,
                distance: item.distance,
                employee_id: item.employee_id,
                evidence: item.evidence,
                fuel_type: item.fuel_type,
                travel_type: item.travel_type,
                expense_unit: item.expense_unit,
                financial_year: item.financial_year,
                mode_of_travel: item.mode_of_travel,
                travel_subtype: item.travel_subtype,
                distance_covered: item.distance_covered,
                number_of_working_days: item.number_of_working_days,
                comments: item.comments,
              };
            })
        dispatch(
          setscope3Category7InputRecords(
            maped
          )
        );
        if(pincodes.length==0){
          dispatch(setPincodes([...new Set(maped.map(i=>i.pincode))]))
        }
        // dispatch(setCurrentPage(response.data.currentPage));
        // dispatch(setItemsPerPage(response.data.itemsPerPage));
        // dispatch(setTotalPages(response.data.totalPages));
        // dispatch(setTotalCount(response.data.totalCount));
        // return response.data
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchscope3Category7ScaledData = createAsyncThunk(
  "scope3Category7/fetchscope3Category7ScaledData",
  async ({ api,financialYear }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id = getOrganizationId();

    let apiUrl = "/scope3/category7/AllScaleddata/" + organization_id;

    try {
      const response = await api.get(`${apiUrl}`,{
        params:{
          financialYear
        }
      });

      if (response.status === 200 && response.data.success) {
        // console.log(response)
        toast.success("Data scaled successfull")
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("failed to fetch")
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchSourceData = createAsyncThunk(
  "scope3Category7/fetchSourceData",
  async ({ api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id = getOrganizationId();
    try {
      const response = await api.get(
        `/scope3/category7/${organization_id}/sites`
      );

      if (response.status === 200 && response.data.success) {
        dispatch(setsource(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchFuelTypeData = createAsyncThunk(
  "scope3Category7/fetchFuelTypeData",
  async ({ api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`/scope3/category7/fuel-type`, {
       
      });
     
      if (response.status === 200 && response.data.success) {
        dispatch(setfuelType(response.data.data));
      }

      //   return response.data;
    } catch (error) {
     
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchExpenseUnitData = createAsyncThunk(
  "scope3Category7/fetchExpenseUnitData",
  async ({ api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`/scope3/category7/expense-unit`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setexpenseUnit(response?.data?.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchTravelTypeData = createAsyncThunk(
  "scope3Category7/fetchTravelTypeData",
  async ({ api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`/scope3/category7/travel-type`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      if (response.status === 200 && response.data.success) {
        dispatch(settypeData(response?.data?.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchTravelSubTypeData = createAsyncThunk(
  "scope3Category7/fetchTravelSubTypeData",
  async ({ api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`/scope3/category7/travel-sub-type`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setSubtypeData(response?.data?.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchmodeOfTravelMappingData = createAsyncThunk(
  "scope3Category7/fetchModeOfTravelData",
  async ({ api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await api.get(
        `/scope3/category7/mode-of-travel-mapping`,
        {
          // headers: {
          //   Authorization: `Bearer ${accessToken}`,
          // },
        }
      );

      if (response.status === 200 && response.data.success) {
        
        dispatch(setmodeOfTravelMappingData(response?.data?.data?.filter(i=>{
          return i.mode_of_travel==="Road"||i.mode_of_travel==="Rail"
        })));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const postscope3Category7Data = createAsyncThunk(
  "scope3Category7/postscope3Category7Data",
  async ({ api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const { id, ...scope3Category7Form } =
      getState().scope3Category7.scope3Category7Form;
    const organization_id = getOrganizationId();
    try {
      const response = await api.post(
        `/scope3/category7/${organization_id}/data`,
        {
          ...scope3Category7Form,
          currency_id: scope3Category7Form.expense_unit,
          fuel_type_id: scope3Category7Form.fuel_type,
          travel_type_id: scope3Category7Form.travel_type,
          travel_subtype_id: scope3Category7Form.travel_subtype,
          no_of_passenger: scope3Category7Form.number_of_employees,
          distance_covered: scope3Category7Form.distance,
        }
      );
      // console.log(response);
      if (response.status === 200 || response.status === 201) {
        dispatch(resetscope3Category7Form());
        dispatch(fetchscope3Category7InputData({ api ,params:{}}));
        toast.success("Record created successfully", {
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error(error?.response?.data?.error || "Record creation failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const scope3Category7UploadEvidence = createAsyncThunk(
  "scope3Category7/scope3Category7UploadEvidence",
  async ({ files, api }, { _, dispatch }) => {
    dispatch(setIsLoading(true));
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    const accessToken = localStorage.getItem("accessToken");
    const organization_id = getOrganizationId();
    try {
      const response = await api.post(
        `/scope3/category7/${organization_id}/evidence`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        dispatch(setIsLoading(false));

        dispatch(setscope3Category7Form({ evidence: response?.data?.path }));
        toast.success("Evidence uploded successfully", {
          theme: "dark",
        });
      }
      return response.data;
    } catch (error) {
      console.error("Error uploading evidence", error);
      toast.error("uploading evidence failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const updatescope3Category7Data = createAsyncThunk(
  "scope3Category7/updatescope3Category7Data",
  async ({ api,id }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const scope3Category7Form  =
      getState().scope3Category7.scope3Category7Form;
    // console.log(scope3Category7Form, id);
    const organization_id = getOrganizationId();
    try {
      const response = await api.put(
        `/scope3/category7/${organization_id}/data/${id}`,
        {
          ...scope3Category7Form,
          currency_id: scope3Category7Form.expense_unit,
          fuel_type_id: scope3Category7Form.fuel_type,
          travel_type_id: scope3Category7Form.travel_type,
          travel_subtype_id: scope3Category7Form.travel_subtype,
          no_of_passenger: scope3Category7Form.number_of_employees,
          distance_covered: scope3Category7Form.distance,
        }
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Record updated successfully", {
          theme: "dark",
        });
        dispatch(fetchscope3Category7InputData({api,params:{}}))
      }
    } catch (error) {
      console.error("Error updating data:", error);
      toast.error("Record update failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const deletescope3Category7Data = createAsyncThunk(
  "scope3Category7/deletescope3Category7Data",
  async ({api,id}, { _, dispatch }) => {
    dispatch(setIsLoading(true));
  
    const organization_id=getOrganizationId()
    try {
      const response = await api.delete(
        `/scope3/category7/${organization_id}/data/${id}`
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Record deleted successfully", {
          theme: "dark",
        });
        dispatch(setdeletes3c7Data(id))
      }
    } catch (error) {
      console.error("Error deleting data:", error);
      toast.error("Record delete failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);