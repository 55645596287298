import CommonLeftBlock from "../../components/auth/CommonLeftBlock";
import RegisterForm from "../../components/auth/RegisterForm";


const RegisterPage = () => {
  return (
    <div className="flex flex-col lg:flex-row w-full">
      <div className="lg:w-1/2">
        <CommonLeftBlock />
      </div>

      <RegisterForm/>
    </div>
  );
};

export default RegisterPage;
