import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useApi from '../../../../customHooks/useApi'

import { transformApiData } from '../../function/functionProcess'

import { fetchSocialEmpWellBeingInputs } from '../../../../features/social/socialThunk'
import GenericTiles from './GenericTile'

function EmployeeWellBeing() {
  const {socialEmpWellBeingsRecords,employementType,socialFormData}=useSelector(state=>state.social)
  const dispatch=useDispatch()
  
  const api =useApi()
  useEffect(()=>{
  //  dispatch(resetSocialFormData({}))
dispatch(fetchSocialEmpWellBeingInputs({api,params:{}}))
  },[])
  return (
    <div className='overflow-auto h-full w-full'>
   <GenericTiles data={transformApiData(socialEmpWellBeingsRecords,employementType)} title="Employee Overview"
  categoryHeaders={["Male", "Female", "Other", "Not Disclosed"]}
  dataKey="Number of employees covered by" />
    </div>
  )
}

export default EmployeeWellBeing
