import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {ReactComponent as AttechedIcon} from "../../../app/assets/AttechedIcon.svg"
import {
  scope3Category5UploadEvidence
} from "../../../features/scope3/category5/Scope3Category5Thunk";
import { setscope3Category5WasteProduceForm } from "../../../features/scope3/category5/Scope3Category5Slice";
import { toast } from "react-toastify";
import useApi from "../../../customHooks/useApi";


const Category5WasteProducedFormEditAndCopyForm = ({
  actionType,setActiveTab
}) => {
  const api = useApi();
  const dispatch = useDispatch();
  const [wastetreatedQuantitySum, setWastetreatedQuantitySum] = useState(0);
  const {
    siteData,
    scope3Category5RecordsTreated,
    unitData,
    wasteMaterial,
    wasteImpact,
    wasteTreatmentMapping,
  } = useSelector((state) => state.scope3Category5);
  const formValue = useSelector(
    (state) => state.scope3Category5.scope3Category5WasteProduceForm
  );
  
  const loading = useSelector((state) => state.common.isLoading);
  const [treated, setTreated] = useState([]);

  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({});

  const handleFormChange = async (e) => {
    const { name, value, type, files } = e.target;
    console.log({name})
    if (name === "produced_evidence") {
      const selectedFiles = files;
      try {
        const res = await dispatch(
          scope3Category5UploadEvidence({ api, files: selectedFiles })
        );
        if (res?.payload?.path) {
          setFile(res.payload.path);
          dispatch(
            setscope3Category5WasteProduceForm({
              produced_evidence: res.payload.path,
            })
          );
        } else {
          toast.error("Failed to upload evidence");
        }
      } catch (error) {
        console.error("File upload error:", error);
      }
    } else {
      console.log({name})
      const updatedValue = name === "produced_waste_material_id"
      ? wasteMaterial.find((item) => parseInt(item.id )=== parseInt(value))?.waste_material || value
      : value;

    const updatePayload = name === "produced_waste_material_id"
      ? { produced_waste_material: updatedValue, [name]: value }
      : { [name]: value };
      const updatedFormData = { ...formValue, ...updatePayload };
     
      dispatch(setscope3Category5WasteProduceForm(updatedFormData));
    }
  };

  const handlePurchaseFormSubmit = (e) => {
    e.preventDefault();
    const form = document.querySelector("#purchase-Scope3Category5-form");

    if (form.checkValidity()) {
      if (wastetreatedQuantitySum > formValue.produced_quantity) {
        toast.error("Treated quantity exceeds produced quantity", {
          theme: "dark",
        });
        return;
      }
      if (actionType === "edit") {
        setActiveTab("wasteTreated")
 
      } else if (actionType === "copy") {
        setActiveTab("wasteTreated")
      }

    } else {
      form.querySelector('input[type="submit"]').click();
    }
  };

  useEffect(() => {
    if (scope3Category5RecordsTreated) {
     
      const treatedAllrest = scope3Category5RecordsTreated?.filter(
        (i) => Number(i.id) === Number(formValue.id)
      );
     
      setWastetreatedQuantitySum(
        treatedAllrest?.reduce(
          (acc, curr) => acc + Number(curr.treated_quantity),
          0
        )
      );
    }
  }, [dispatch]);

  useEffect(() => {
    setTreated(
      wasteTreatmentMapping
        .filter(
          (t) =>
            Number(t.waste_material_id) ===
            Number(formValue.produced_waste_material_id)
        )
        .map((i) => {
          return {
            id: i.waste_treatment_mechanism_id,
            waste_treatment_mechanism: i.waste_treatment_mechanism,
          };
        })
    );
  }, [formValue.produced_waste_material_id, wasteTreatmentMapping]);

  return (
    <>
      <div className="flex flex-col justify-center items-center rounded-t-md text-xs">
        <form
          id="purchase-Scope3Category5-form"
          className="flex flex-col w-full py-4"
          style={{
            opacity: loading ? 0.5 : 1,
            pointerEvents: loading ? "none" : "auto",
          }}
          onSubmit={handlePurchaseFormSubmit}
        >
          <div className="grid grid-cols-4 gap-4 px-8 mb-4 justify-around">
            <div className="col-span-1 flex flex-col">
              <label htmlFor="produced_period_start" className="text-xs py-1.5">
                Period Start<span className="text-red-500">*</span>
              </label>
              <input
                max={new Date().toISOString().split("T")[0]}
                type="date"
                name="produced_period_start"
                id="produced_period_start"
                value={formValue.produced_period_start || ""}
                onChange={handleFormChange}
                className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                required
              />
            </div>
            <div className="col-span-1 flex flex-col">
              <label htmlFor="produced_period_end" className="text-xs py-1.5">
                Period End<span className="text-red-500">*</span>
              </label>
              <input
                max={new Date().toISOString().split("T")[0]}
                type="date"
                name="produced_period_end"
                id="produced_period_end"
                value={formValue.produced_period_end || ""}
                onChange={handleFormChange}
                className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                min={formValue.produced_period_start || ""}
                required
              />
            </div>
            <div className="col-span-1 flex flex-col">
              <label htmlFor="produced_site_id" className="text-xs py-1.5">
                Site<span className="text-red-500">*</span>
              </label>
              <select
                name="produced_site_id"
                onChange={handleFormChange}
                className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                required
                value={formValue.produced_site_id || ""}
              >
                <option value="" disabled>
                  Choose the site
                </option>
                {siteData &&
                  siteData.map((site) => (
                    <option key={site.id} value={site.id}>
                      {site.site}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-span-1 flex flex-col">
              <label
                htmlFor="produced_waste_material"
                className="text-xs py-1.5"
              >
                Waste Material<span className="text-red-500">*</span>
              </label>
              <select
                name="produced_waste_material_id"
                onChange={handleFormChange}
                className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                required
                value={formValue.produced_waste_material_id || ""}
              >
                <option value="" disabled>
                  Choose the waste material
                </option>
                {wasteMaterial &&
                  wasteMaterial.map((i) => (
                    <option key={i.id} value={i.id}>
                      {i.waste_material}
                    </option>
                  ))}
              </select>
            </div>
            {
            // formValue.produced_waste_material_id !== data?.produced_waste_material_id && (
            // <div className="col-span-1 flex flex-col">
            //   <label
            //     htmlFor={`treatment_mechanism_id_${"index"}`}
            //     className="text-xs py-1.5"
            //   >
            //     Treatment Mechanism<span className="text-red-500">*</span>
            //   </label>
            //   <select
            //     name="treatment_mechanism_id"
            //     id={`treatment_mechanism_id_${"index"}`}
            //     value={formValue.treatment_mechanism_id || ""}
            //     onChange={(e) => handleFormChange(e)}
            //     className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
            //     required
            //   >
            //     <option value="">Choose the treatment mechanism</option>
            //     {/* .filter((i) => data?.waste_material_id == i.waste_material_id) */}
            //     {treated &&
            //       treated.map((mechanism) => (
            //         <option key={mechanism.id} value={mechanism.id}>
            //           {mechanism.waste_treatment_mechanism}
            //         </option>
            //       ))}
            //   </select>
            // </div>)
            }
            <div className="col-span-1 flex flex-col">
              <label htmlFor="produced_waste_impact" className="text-xs py-1.5">
                Waste Impact <span className="text-red-500">*</span>
              </label>
              <select
                name="produced_waste_impact"
                onChange={handleFormChange}
                className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                required
                value={formValue.produced_waste_impact || ""}
              >
                <option value="" disabled>
                  Choose the Waste Impact
                </option>
                {wasteImpact &&
                  wasteImpact.map((i) => (
                    <option key={i.id} value={i.waste_impact}>
                      {i.waste_impact}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-span-1 flex flex-col">
              <label htmlFor="produced_quantity" className="text-xs py-1.5">
                Produced Quantity
              </label>
              <input
                type="number"
                name="produced_quantity"
                value={formValue.produced_quantity || ""}
                onChange={(e) => {
                  // if (wastetreatedQuantitySum>e.target.value) {
                  //   toast.error("Please select valid quantity can't be less than "+wastetreatedQuantitySum);
                    
                  // }
                  handleFormChange(e);
                }}
                className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                placeholder="Type the quantity"
                min={wastetreatedQuantitySum}
              />
            </div>
            <div className="col-span-1 flex flex-col">
              <label htmlFor="produced_unit_id" className="text-xs py-1.5">
                Unit
              </label>
              <select
                name="produced_unit_id"
                value={formValue.produced_unit_id || ""}
                onChange={handleFormChange}
                className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
              >
                <option value="" disabled>
                  Choose the unit
                </option>
                {unitData &&
                  unitData.map((unit, index) => (
                    <option key={unit.id} value={unit.id}>
                      {unit.unit}
                    </option>
                  ))}
              </select>
            </div>

            {/* <div className="col-span-1 flex flex-col">
              <label htmlFor="produced_evidence" className="text-xs py-1.5 flex items-center">
                Evidence <span className="text-red-500">*</span>
                {formValue.produced_evidence && <a href={formValue.produced_evidence} target="_blank" className="ml-2 text-blue-600 underline">View</a>}
                
              </label> 
              <input
                disabled={loading}
                type="file"
                name="produced_evidence"
                multiple
                accept=".jpg, .jpeg, .png, .pdf, .zip"
                onChange={handleFormChange}
                required={!formValue.produced_evidence}
                className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
              />
            </div> */}
         <div className=" flex flex-col">
        <label htmlFor={`produced_evidence`} className="text-xs py-1.5 flex flex-col">
          <span>Evidence <span className="text-red-500">*</span></span> 
          {formValue.produced_evidence ? (
            <p className="text-green-600 mt-1 underline cursor-pointer">
              Evidence Uploaded
            </p>
          ) : (
           ""
          )}
        </label>
        <input
          required={!formValue.produced_evidence}
          type="file"
          name="produced_evidence"
          id={`produced_evidence`}
          multiple
          accept=".jpg, .jpeg, .png, .pdf, .zip"
          onChange={handleFormChange}
          className={`appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none ${formValue.produced_evidence ? 'hidden' : ''}`}
        />
      </div>
          </div>

          <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
            {/* <div className="w-1/6 flex flex-col">
              <button
                disabled={loading}
                type="button"
                className="py-2 px-6 text-green-600 text-sm rounded-md"
                onClick={() => closePurchasePopupForm()}
              >
                Cancel
              </button>
            </div> */}
            <div className="w-1/6 flex flex-col">
              <button
                type="submit"
                disabled={loading}
                className={`py-2 px-6 text-white text-sm ${
                  loading ? "bg-green-500 cursor-wait" : "bg-green-600"
                } rounded-md`}
              >
                Save and Continue
              </button>
            </div>
          </div>
        </form>
      </div>
      {/* </div> */}
    </>
  );
};

export default Category5WasteProducedFormEditAndCopyForm;
