import { createAsyncThunk } from "@reduxjs/toolkit";

import { setIsLoading } from "../../common/commonSlice.js";

import { toast } from "react-toastify";



import {
  resetscope3Category2Form,
  setCurrencyData,
  setSiteData,
  setSiteDataForFilter,
  setUnitData,
  setdeletes3c2Data,
  setscope3Category2Form,
  setscope3Category2InputRecords,
  setscope3Category2ItemData,
  setscope3Category2ItemDataFilter,
} from "./Scope3Category2Slice.js"
import { getOrganizationId } from "../../../components/common/parseJwt.js";
import { removeFalsyValues } from "../../../components/common/removeFaslyValues.js";
export const fetchscope3Category2InputData = createAsyncThunk(
  "scope3Category2/fetchscope3Category2InputData",
  async ({api,params}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    const organization_id=getOrganizationId()
    let apiUrl = "/scope3/category2/inputdata/"+organization_id;

    try {
      const response = await api.get(`${apiUrl}` ,{
        params:removeFalsyValues(params)
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setscope3Category2InputRecords(response?.data?.data.map(item=>{
          return {
            ...item,
            currency: item.item_currency_id,
                production_type: item.item_production_type,
                site: item.site_id,
                unit: item.item_unit_id,
                supplier: item.item_supplier,
                supplier_code: item.item_supplier_code,
          }
        })));
        // dispatch(setCurrentPage(response.data.currentPage));
        // dispatch(setItemsPerPage(response.data.itemsPerPage));
        // dispatch(setTotalPages(response.data.totalPages));
        // dispatch(setTotalCount(response.data.totalCount));
        // return response
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchscope3Category2ItemData = createAsyncThunk(
  "scope3Category2/fetchscope3Category2ItemData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    const organization_id=getOrganizationId()
    let apiUrl = "/scope3/category2/client-item-capialAndgood-master/"+organization_id;

    try {
      const response = await api.get(`${apiUrl}`, {
        //   headers: {
        //     Authorization: `Bearer ${accessToken}`,
        //   },
      });

      if (response.status === 200 && response.data.success) {
        dispatch(
          setscope3Category2ItemData(
            response?.data?.data.map((item) => {
              return {
                ...item,
                item_code:item.organization_item_code,
                supplier_code:item.organization_supplier_code
                
              };
            })
          )
        );
        dispatch(setscope3Category2ItemDataFilter(response.data.Filters))
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchSiteData = createAsyncThunk(
  "scope3Category2/fetchSiteData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
   
    const organization_id=getOrganizationId()
    try {
      const response = await api.get(
        `/scope3/category2/${organization_id}/sites`,
        {
          // headers: {
          //   Authorization: `Bearer ${accessToken}`,
          // },
        }
      );

      if (response.status === 200 && response.data.success) {
        dispatch(setSiteData(response.data.data));
        dispatch(setSiteDataForFilter(response.data.Filters));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchUnitData = createAsyncThunk(
  "scope3Category2/fetchUnitData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    try {
      const response = await api.get(`/scope3/category2/units`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setUnitData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchCurrencyData = createAsyncThunk(
  "scope3Category2/fetchCurrencyData",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    try {
      const response = await api.get(`/scope3/category2/currencies`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setCurrencyData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const postscope3Category2Data = createAsyncThunk(
  "scope3Category2/postscope3Category2Data",
  async ({api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id=getOrganizationId()
    const { id, ...scope3Category2Form } = getState().scope3Category2.scope3Category2Form;
    // const params = getState().scope3Category2.params||{};
// console.log(scope3Category2Form)
    try {
      const response = await api.post(
        `/scope3/category2/${organization_id}/data`,
        {
          ...scope3Category2Form,
          item_currency_id: scope3Category2Form.currency,
          item_production_type: scope3Category2Form.production_type,
          site_id: scope3Category2Form.site,
          item_unit_id: scope3Category2Form.unit,
          item_supplier: scope3Category2Form.supplier,
          item_supplier_code: scope3Category2Form.supplier_code,
        },
        {
          // headers: {
          //   Authorization: `Bearer ${accessToken}`,
          // },
        }
      );
      // console.log(response);
      if (response.status === 200 || response.status === 201) {
        dispatch(resetscope3Category2Form());
        dispatch(fetchscope3Category2InputData({api,params:{}}));
        toast.success("Record created successfully", {
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error(error?.response?.data?.error || "Record creation failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const scope3Category2UploadEvidence = createAsyncThunk(
  "scope3Category2/scope3Category2UploadEvidence",
  async ({files,api}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    // const accessToken = getState().auth.accessToken;
    const accessToken=localStorage.getItem("accessToken")
    const organization_id=getOrganizationId()
    try {
      const response = await api.post(
        `/scope3/category2/${organization_id}/evidence`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        dispatch(setIsLoading(false));

        dispatch(setscope3Category2Form({ evidence: response?.data?.path }));
        toast.success("Evidence uploded successfully", {
          theme: "dark",
        });
      }
      return response.data
    } catch (error) {
      console.error("Error uploading evidence", error);
      toast.error("uploading evidence failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const updatescope3Category2Data = createAsyncThunk(
  "scope3Category2/updatescope3Category2Data",
  async ({api,id}, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    const scope3Category2Form = getState().scope3Category2.scope3Category2Form;
    const organization_id=getOrganizationId()

    try {
      const response = await api.put(
        `/scope3/category2/${organization_id}/data/${id}`,
        scope3Category2Form
        // {
        //   headers: {
        //     Authorization: `Bearer ${accessToken}`,
        //   },
        // }
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Record updated successfully", {
          theme: "dark",
        });
        dispatch(fetchscope3Category2InputData({api,params:{}}))
      }
    } catch (error) {
      console.error("Error updating data:", error);
      toast.error("Record update failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const deletescope3Category2Data = createAsyncThunk(
  "scope3Category2/deletescope3Category2Data",
  async ({api,id}, { _, dispatch }) => {
    dispatch(setIsLoading(true));
  
    const organization_id=getOrganizationId()
    try {
      const response = await api.delete(
        `/scope3/category2/${organization_id}/data/${id}`
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Record deleted successfully", {
          theme: "dark",
        });
        dispatch(setdeletes3c2Data(id))
      }
    } catch (error) {
      console.error("Error deleting data:", error);
      toast.error("Record delete failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);