// Fuel thunk
import { createAsyncThunk } from "@reduxjs/toolkit";

import { setIsLoading } from "../../common/commonSlice";
import {
  resetFuelForm,
  setCurrencyData,
  setFuelForm,
  setFuelRecords,
  setFuelTypeData,
  setSiteData,
  setSiteDataForFilters,
  setUnitData,
  setUseTypeData,
  setdeleteFuelData,
  setfuelTypeDataForFilters,
} from "./fuelSlice";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../appconfig";
import { getOrganizationId } from "../../../components/common/parseJwt";
function removeFalsyValues(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value));
}

export const fetchFuelData = createAsyncThunk(
  "fuel/fetchFuelData",
  async ({ api, params }, { getState, dispatch }) => {
    const organization_id = getOrganizationId();
    const fuelRecordType = getState().fuel.fuelRecordType;
    let apiUrl = "";
    if (fuelRecordType === 1) {
      apiUrl = `/energy/fuel/${organization_id}/purchased-fuel`;
    } else {
      apiUrl = `/energy/fuel/${organization_id}/inventory-fuel`;
    }
    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`${apiUrl}`, {
        params: removeFalsyValues(params),
      });

      //   set all data fuelREcords, pageDetails, totalCount etc.
      if (response.status === 200 && response.data.success) {
        dispatch(
          setFuelRecords(
            response.data?.data.map((i) => {
              return {
                ...i,
                source_type:
                  i.source_type === "non-renewable"
                    ? "Non-Renewable"
                    : "Renewable",
              };
            })
          )
        );
        // dispatch(setCurrentPage(response.data.currentPage));
        // dispatch(setItemsPerPage(response.data.itemsPerPage));
        // dispatch(setTotalPages(response.data.totalPages));
        // dispatch(setTotalCount(response.data.totalCount));
        // dispatch(setIsLoading(false));
        // console.log(response)
      }

      return response.data;
    } catch (error) {
      // Handle other API call errors
      // toast(error.message||"Error getting fuel information");
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchSiteData = createAsyncThunk(
  "fuel/fetchSiteData",
  async ({ api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id = getOrganizationId();
    try {
      const response = await api.get(`/energy/fuel/${organization_id}/sites`);
      if (response.status === 200 && response.data.success) {
        dispatch(setSiteData(response.data.data));
        dispatch(setSiteDataForFilters(response.data.Filters));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors

      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchFuelTypeData = createAsyncThunk(
  "fuel/fetchFuelTypeData",
  async ({ api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`/energy/fuel/fuel-types`);

      //   set all data fuelREcords, pageDetails, totalCount etc.
      // console.log(response)
      if (response.status === 200 && response.data.success) {
        dispatch(setFuelTypeData(response.data.data));
        dispatch(setfuelTypeDataForFilters(response.data.Filters));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors

      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchUseTypeData = createAsyncThunk(
  "fuel/fetchUseTypeData",
  async ({ api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`/energy/fuel/use-types`);

      //   set all data fuelREcords, pageDetails, totalCount etc.

      if (response.status === 200 && response.data.success) {
        dispatch(setUseTypeData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchUnitData = createAsyncThunk(
  "fuel/fetchUnitData",
  async ({ api, value }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    // const accessToken = getState().auth.accessToken;

    try {
      const response = await api.get(`/energy/fuel/units/${value}`);

      //   set all data fuelREcords, pageDetails, totalCount etc.
      // console.log(response)
      if (response.status === 200 && response.data.success) {
        dispatch(setUnitData(response?.data?.data));
      }

      return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchCurrencyData = createAsyncThunk(
  "fuel/fetchCurrencyData",
  async ({ api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`/energy/fuel/currencies`);

      //   set all data fuelREcords, pageDetails, totalCount etc.

      if (response.status === 200 && response.data.success) {
        dispatch(setCurrencyData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const postFuelData = createAsyncThunk(
  "fuel/postFuelData",
  async ({ api }, { getState, dispatch }) => {
    const { id, ...fuelForm } = getState().fuel.fuelForm;

    const fuel_record_type = getState().fuel.fuelRecordType;
    const organization_id = getOrganizationId();
    dispatch(resetFuelForm());

    dispatch(setIsLoading(true));
    // ))
    try {
      const response = await api.post(
        `/energy/fuel/${organization_id}/data/${fuel_record_type}`,
        Object.fromEntries(
          Object.entries(fuelForm).filter(([_, value]) => value !== "")
        )
      );

      if (
        (response.status === 200 || response.status === 201) &&
        response.data.success
      ) {
        toast.success("Record created successfully", {
          theme: "dark",
        });
        dispatch(fetchFuelData({ api, params: {} }));
      }
    } catch (error) {
      // Handle other API call errors
      console.error("Error posting data:", error);
      toast.error(error?.response?.data?.error || "Record created failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const updateFuelData = createAsyncThunk(
  "fuel/updateFuelData",
  async ({ api, id }, { getState, dispatch }) => {
    const fuelForm = getState().fuel.fuelForm;
    const fuel_record_type = getState().fuel.fuelRecordType;
    const organization_id = getOrganizationId();
    dispatch(resetFuelForm());

    dispatch(setIsLoading(true));
    try {
      const response = await api.put(
        `/energy/fuel/${organization_id}/data/${id}`,
        fuelForm
      );

      if (
        (response.status === 200 || response.status === 201) &&
        response.data.success
      ) {
        toast.success("Record Updated successfully", {
          theme: "dark",
        });
        dispatch(fetchFuelData({ api }));
      }
    } catch (error) {
      // Handle other API call errors
      console.error("Error posting data:", error);
      toast.error(error?.response?.data?.error || "Record created failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const deleteFuelData = createAsyncThunk(
  "fuel/deleteFuelData",
  async ({ api, id }, { getState, dispatch }) => {
    const fuelForm = getState().fuel.fuelForm;
    // const fuel_record_type = getState().fuel.fuelRecordType;
    const organization_id = getOrganizationId();

    dispatch(setIsLoading(true));
    try {
      // /energy/fuel/:organization_id/data/:id
      const response = await api.delete(
        `/energy/fuel/${organization_id}/data/${id}`,
        fuelForm
      );

      if (
        (response.status === 200 || response.status === 201) &&
        response.data.success
      ) {
        toast.success("Record Deleted successfully", {
          theme: "dark",
        });
        // dispatch(fetchFuelData({api}));
        dispatch(setdeleteFuelData(id));
      }
    } catch (error) {
      // Handle other API call errors
      console.error("Error posting data:", error);
      toast.error(error?.response?.data?.error || "Record deleting failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchUploadFuelEvidence = createAsyncThunk(
  "fuel/fetchUploadFuelEvidence",
  async ({ api, files }, { getState, dispatch }) => {
    try {
      dispatch(setIsLoading(true));
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      const organization_id = getOrganizationId();
      const accessToken = localStorage.getItem("accessToken");
      // console.log(files)
      const fuel_record_type = getState().fuel.fuelRecordType;
      const response = await api.post(
        `${BASE_URL}/energy/fuel/${organization_id}/data/${fuel_record_type}/evidence`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        dispatch(setIsLoading(false));

        dispatch(setFuelForm({ evidence: response?.data?.path }));
        toast.success("Evidence uploded successfully");
        return response.data;
      } else {
        dispatch(setIsLoading(false));

        toast("Evidence Upload Failed");
        throw new Error("Failed to upload files");
      }
    } catch (error) {
      dispatch(setIsLoading(false));
      toast(error?.response?.data?.error || "Evidence Upload Failed");

      console.log("Error:", error);
      throw error;
    }
  }
);
