import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage, setformData, setItemsPerPage } from "../Redux/GeneralSlices";
import UserIcon from "../../../app/assets/UserIcon.svg";
import { ReactComponent as CopyIcon } from "../../../app/assets/CopyIcon.svg";
import { ReactComponent as PencilIcon } from "../../../app/assets/PencilIcon.svg";
import { ReactComponent as AddIconBig } from "../../../app/assets/AddIconBig.svg";
import { ReactComponent as DownloadIcon } from "../../../app/assets/DownloadIcon.svg";
import { toast } from "react-toastify";
import Table from "../../../components/common/Table/Table";
import TablePagination from "../../../components/common/Table/TablePagination";
import RevenueInsertDataForm from "../Forms/RevenueInsertDataForm";
import RevenueInsertDataFormEditCopy from "../Forms/RevenueInsertDataFormEditCopy";

function RevenueInput() {
  const [isPurchaseFormOpen, setPurchaseFormOpen] = useState(false);
  const [actionType, setActionType] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isEditCopyFormOpen, setIsEditCopyFormOpen] = useState(false);
  const [dataRows, setDataRows] = useState([]);
  const data = useSelector((state) => state.general?.revenueData);
  const itemsPerPage = useSelector((state) => state.general.itemsPerPage);
  const totalPages = useSelector((state) => state.general.totalPages);
  const totalCount = useSelector((state) => state.general.totalCount);
  const currentPage = useSelector((state) => state.general.currentPage);
  const dispatch = useDispatch();
  const headingsToDataKeyMap = {
    "": "",
    Year: "year",
    QUARTER: "quarter",
    // "Business Unit": "business_unit",
    // "Product ID": "product_id",
    // Product: "product",
    // "NIC Code (D)": "nic_code_d",
    "Revenue (in Cr)": "revenue",
    Edit: "",
  };

  const [paginationConfig, setPaginationConfig] = useState({
    paginationEnabled: true,
    currentPage: 1,
    totalPage: 1,
    itemsPerPage: 10,
    handleItemsPerPage: null,
    handlePageChange: null,
  });

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const handleItemsPerPage = (itemCount) => {
    dispatch(setItemsPerPage(itemCount));
  };

  const handleFileDownload = (filePath) => {
    // dispatch(downloadFileThunk({ filePath }));
    if (filePath) {
      const link = document.createElement("a");
      link.href = filePath;

      link.download = "filename.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast("No evidance atteched");
    }
  };

  const generateCellHtml = (row, k) => {
    let cellHtml = null;
    switch (k) {
      case "fuel_type":
        cellHtml = (
          <span className="w-full flex justify-center">
            <span
              className={`px-2 py-1 border border-transparent rounded-md ${
                row["source_type"].toString().toLowerCase() ===
                "Renewable".toLowerCase()
                  ? "bg-green-200 text-green-600"
                  : "bg-red-200 text-red-600"
              }`}
            >
              {row[k]}
            </span>
          </span>
        );
        break;
      case "status":
        cellHtml = (
          <span className="w-full flex justify-center">
            <span
              className={`px-2 py-1 border border-transparent rounded-md ${
                row[k].toString().toLowerCase() === "Accepted".toLowerCase()
                  ? "bg-green-200 text-green-600"
                  : row[k].toString().toLowerCase() ===
                    "Submitted".toLowerCase()
                  ? "bg-blue-200 text-blue-600"
                  : "bg-red-200 text-red-600"
              }`}
            >
              {row[k]}
            </span>
          </span>
        );
        break;
      case "evidence":
        cellHtml = (
          <span className="w-full flex justify-center">
            <DownloadIcon
              className="cursor-pointer"
              onClick={() => handleFileDownload(row[k])}
            />
          </span>
        );
        break;
      case "submitted_by":
      case "approved_by":
        cellHtml = (
          <span className="w-full flex justify-center">
            <img
              src={`${UserIcon}`}
              className="cursor-pointer w-[32px] h-[32px] border border-transparent rounded-[50%]"
              alt={`${k}`}
            />
          </span>
        );
        break;
      default:
        cellHtml = <span className="w-full flex justify-center">{row[k]}</span>;
        break;
    }
    return cellHtml;
  };
  const handleCloseEditCopyForms = () => {
    setIsEditCopyFormOpen(false);
  };
  const closePurchasePopupForm = () => {
    setPurchaseFormOpen(false);
  };

  useEffect(() => {
    let transformedDataRows = data.map((row) => {
      let transformedDataRow = [];
      transformedDataRow.push(
        <span className="w-full flex justify-center">
          <CopyIcon
            className="cursor-pointer"
            onClick={() => handleCopy(row)}
          />
        </span>
      );
      Object.values(headingsToDataKeyMap).forEach((k) => {
        if (k.toString().trim() !== "") {
          transformedDataRow.push(generateCellHtml(row, k));
        }
      });
      transformedDataRow.push(
        <span className="w-full flex justify-center">
          <PencilIcon
            className="cursor-pointer"
            onClick={() => handleEdit(row)}
          />
        </span>
      );
      return transformedDataRow;
    });

    setDataRows(transformedDataRows);
    setPaginationConfig({
      ...paginationConfig,
      currentPage: currentPage,
      totalPages: totalPages,
      itemsPerPage: itemsPerPage,
      handleItemsPerPage: handleItemsPerPage,
      handlePageChange: handlePageChange,
    });
  }, [data]);

  const openPopupForm = () => {
 
      setPurchaseFormOpen(true);
 
  };
  const handleEdit = (row) => {
   
    setActionType("edit");
    setSelectedRowData(row);
    setIsEditCopyFormOpen(true);
  };

  const handleCopy = (row) => {
  
    setActionType("copy");
    setSelectedRowData(row);
    setIsEditCopyFormOpen(true); // Open the copy form
  };
  function handleFormChange(e) {
    const { name, value } = e.target;
    dispatch(setformData({ [name]: value }));
  }
  return (
    <div>
      <>
        <Table
          // height={"62.5vh"}
          headings={Object.keys(headingsToDataKeyMap)}
          dataRows={dataRows}
          paginationObject={paginationConfig}
        />
        <div className="flex w-fit py-4 px-2 absolute bottom-8 right-6 cursor-pointer">
          {/* Add */}
          <AddIconBig onClick={() => openPopupForm()} />
        </div>
        {paginationConfig &&
          paginationConfig?.paginationEnabled &&
          paginationConfig?.handleItemsPerPage &&
          typeof paginationConfig?.handleItemsPerPage === "function" &&
          paginationConfig?.handlePageChange &&
          typeof paginationConfig?.handlePageChange === "function" && (
            <TablePagination paginationObject={paginationConfig} />
          )}

        {isPurchaseFormOpen && (
          <RevenueInsertDataForm
            handleFormChange={handleFormChange}
            handleCloseForm={closePurchasePopupForm}
          />
        )}
        {isEditCopyFormOpen && (
          <RevenueInsertDataFormEditCopy
            handleCloseForm={() => setIsEditCopyFormOpen(false)}
            selectedRowData={selectedRowData}
            handleCloseEditCopyForms={handleCloseEditCopyForms}
            actionType={actionType}
          />
        )}
      </>
    </div>
  );
}

export default RevenueInput;
