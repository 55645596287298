import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as KeyIcon } from "../../../../app/assets/AccessKeyPermissions.svg";
import { ReactComponent as UserIcon } from "../../../../app/assets/UserIconGray.svg";
import { ReactComponent as DataIcon } from "../../../../app/assets/Data.svg";
import { ReactComponent as EditIconPencil } from "../../../../app/assets/Home.svg";
import { ReactComponent as BarGraph } from "../../../../app/assets/Bargraph.svg";
import { ReactComponent as ReportIcon } from "../../../../app/assets/ReportIcon.svg";
import TabContentRoles from "./TabContentRoles";
import TabContentDataIn from "./TabContentDataInput";
import TabContentReporting from "./TabContentReporting";
import TabContentAnalyze from "./TabContentAnalyze";
import TabContentSite from "./TabContentSite";
import {
  createMember,
  fetchCustomRoles,
  fetchSiteData,
  updateMember,
} from "../../Redux/SettingThunk";
import useApi from "../../../../customHooks/useApi";
import { setadminDashboardForm } from "../../Redux/SettingSlices";
import TabContentForSubModule from "./TabContentForModuleSubmodule";
import { jwtDecode } from "jwt-decode";

const initialTabsConfig = {
  roles: {
    id: "roles",
    label: "Roles",
    icon: (isActive) => <UserIcon fill={isActive ? "#FFFFFF" : "#858585"} />,
  },
  site: {
    id: "site",
    label: "Site",
    icon: (isActive) => (
      <EditIconPencil fill={isActive ? "#FFFFFF" : "#858585"} />
    ),
  },
  // modules: {
  //   id: "modules",
  //   label: "Modules",
  //   icon: (isActive) => <Puzzal fill={isActive ? "#FFFFFF" : "#858585"} />,
  // },
  dataIn: {
    id: "dataIn",
    label: "Data In",
    icon: (isActive) => <DataIcon fill={isActive ? "#FFFFFF" : "#858585"} />,
  },
  analyze: {
    id: "visualization",
    label: "Analyze",
    icon: (isActive) => <BarGraph fill={isActive ? "#FFFFFF" : "#858585"} />,
  },
  report: {
    id: "reporting",
    label: "Report",
    icon: (isActive) => <ReportIcon fill={isActive ? "#FFFFFF" : "#858585"} />,
  },
};

export const permissionsConfig = {
  admin: { is_enabled: false },
  modules: [-1],
  auditing: {
    modules: [-1],
    is_enabled: false,
    read_only: false,
    submodules: [-1],
  },
  data_entry: {
    modules: [-1],
    is_enabled: false,
    read_only: false,
    submodules: [-1],
  },
  management: {
    modules: [-1],
    is_enabled: false,
    read_only: false,
    submodules: [-1],
  },
  reporting: {
    modules: [-1],
    is_enabled: false,
    read_only: false,
    submodules: [-1],
  },
  submodules: [-1],
  sites: [],
  visualization: {
    modules: [-1],
    is_enabled: false,
    read_only: false,
    submodules: [-1],
  },
};

const Popup = ({ onClose, actionType }) => {
  const { modules, submodules } = localStorage.getItem("accessToken")
    ? jwtDecode(localStorage.getItem("accessToken"))?.member?.access
    : {};

  const [activeTab, setActiveTab] = useState("roles");
  const [isCustom, setIsCustom] = useState(false);
  const dispatch = useDispatch();
  const { adminDashboardForm, isCreatingNewRole } = useSelector(
    (state) => state.setting
  );
  const api = useApi();

  const [tabs, setTabs] = useState(() =>
    Object.entries(initialTabsConfig).map(([key, tab]) => ({
      ...tab,
      is_enabled: key === "roles",
    }))
  );

  const shouldEnableReporting = modules.some(
    (module) => module === -1 || module === 4 || module === 5
  );
  useEffect(() => {
    const roleTypeId = Number(adminDashboardForm.role_type_id);
    const enabledTabs = {
      2: ["roles"],
      // 3: ["roles", "site", "modules", "dataIn"],
      // 4: ["roles", "site", "modules", "visualization"],
      // 5: ["roles", "site", "modules", "visualization", "reporting"],
      // 6: ["roles", "site", "modules", "dataIn", "visualization"],
      // 11: ["roles", "site", "modules", "dataIn", "visualization", "reporting"],
      3: ["roles", "site", "dataIn"],
      4: ["roles", "site", "visualization"],
      5: ["roles", "site", "visualization"],
      6: ["roles", "site", "dataIn", "visualization"],
      11: ["roles", "site", "dataIn", "visualization"],
      default: ["roles"],
    };
    if (shouldEnableReporting) {
      enabledTabs[5].push("reporting");
      enabledTabs[11].push("reporting");
    }

    setTabs((prevTabs) =>
      prevTabs.map((tab) => ({
        ...tab,
        is_enabled: enabledTabs[roleTypeId]?.includes(tab.id) || false,
      }))
    );

    setIsCustom(roleTypeId === 2);
    if (roleTypeId === 11) {
      dispatch(fetchCustomRoles({ api }));
    }
  }, [adminDashboardForm.role_type_id]);

  useEffect(() => {
    dispatch(fetchSiteData({ api }));
    const deepClone = (obj) => JSON.parse(JSON.stringify(obj));
    if (!adminDashboardForm.permissions) {
      let permissions = deepClone(permissionsConfig);
      const updatedPermissionsConfig = {
        ...permissions,
        modules,
        submodules,
        data_entry: { ...permissions.data_entry, modules, submodules },
        auditing: { ...permissions.auditing, modules, submodules },
        management: { ...permissions.management, modules, submodules },
        reporting: {
          ...permissions.reporting,
          modules: shouldEnableReporting ? modules : [],
          submodules: shouldEnableReporting ? submodules : [],
        },
        visualization: { ...permissions.visualization, modules, submodules },
      };

      dispatch(
        setadminDashboardForm({
          ...adminDashboardForm,
          permissions: updatedPermissionsConfig,
        })
      );
    }
  }, [dispatch]);

  const handleSubmit = () => {
    const roleTypeId = Number(adminDashboardForm.role_type_id);

    // If role_type_id is 2 (admin), submit data directly after roles tab
    if (roleTypeId === 2 && activeTab === "roles") {
      // Set permissions true for admin and submit
      dispatch(
        setadminDashboardForm({
          ...adminDashboardForm,
          permissions: {
            ...adminDashboardForm.permissions,
            admin: { is_enabled: roleTypeId === 2 },
          },
        })
      );

      if (actionType === "newEntry") {
        dispatch(createMember({ api }));
      } else if (actionType === "update") {
        dispatch(updateMember({ api }));
      }

      onClose(); // Close the popup after submission
      return;
    } else if (
      roleTypeId === 11 &&
      activeTab === "roles" &&
      !isCreatingNewRole &&
      actionType === "newEntry"
    ) {
      // Set permissions true for admin and submit
      dispatch(
        setadminDashboardForm({
          ...adminDashboardForm,
          permissions: {
            ...adminDashboardForm.permissions,
            admin: { is_enabled: roleTypeId === 2 },
          },
        })
      );

      if (actionType === "newEntry") {
        dispatch(createMember({ api }));
      } else if (actionType === "update") {
        dispatch(updateMember({ api }));
      }

      onClose(); // Close the popup after submission
      return;
    }

    // Tab switching logic for other roles
    const tabSwitching = {
      roles: {
        3: "site",
        4: "site",
        5: "site",
        6: "site",
        11: "site",
      },
      // site: {
      //   3: "modules",
      //   4: "modules",
      //   5: "modules",
      //   6: "modules",
      //   11: "modules",
      // },
      site: {
        3: "dataIn",
        4: "visualization",
        5: "visualization",
        6: "dataIn",
        11: "dataIn",
      },
      dataIn: {
        6: "visualization",
        11: "visualization",
      },
      visualization: {
        5: shouldEnableReporting ? "reporting" : "roles",
        11: shouldEnableReporting ? "reporting" : "roles",
      },
    };

    const nextTab = tabSwitching[activeTab]?.[roleTypeId] || "roles";
    setActiveTab(nextTab);
  };

  const renderTab = () => {
    const tabContentProps = {
      handleSubmit,
      isCustom,
      setIsCustom,
      setActiveTab,
      activeTab,
      actionType,
      roleTypeId: adminDashboardForm.role_type_id,
      onClose,
    };

    switch (activeTab) {
      case "roles":
        return <TabContentRoles {...tabContentProps} />;
      case "dataIn":
        return <TabContentDataIn {...tabContentProps} />;
      case "site":
        return <TabContentSite {...tabContentProps} />;
      case "modules":
        return <TabContentForSubModule {...tabContentProps} />;
      case "visualization":
        return <TabContentAnalyze {...tabContentProps} />;
      case "reporting":
        return <TabContentReporting {...tabContentProps} />;
      default:
        return null;
    }
  };
  
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-3xl sm:w-full min-h-fit relative">
          <div className="flex justify-between items-center p-3 border-b border-solid border-gray-200 rounded-t">
            <h3 className="font-semibold flex space-x-3">
              Permissions <KeyIcon className="ml-3" />
            </h3>
            <button
              className="ml-auto bg-transparent flex items-center justify-center text-center text-gray-500 float-right text-2xl leading-none font-semibold outline-none focus:outline-none"
              onClick={onClose}
            >
              <span className="bg-transparent flex items-center justify-center text-center text-gray-500 h-6 w-6 text-xl outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <div>
                  <nav className={`flex justify-between `}>
                    {tabs.map((tab) => (
                      <button
                        key={tab.id}
                        disabled={
                          !isCustom &&
                          actionType === "newEntry" &&
                          adminDashboardForm.role_type_id == 11
                            ? true
                            : !tab.is_enabled && actionType !== "newEntry"
                        }
                        onClick={() => tab.is_enabled && setActiveTab(tab.id)}
                        className={`${
                          activeTab === tab.id
                            ? "bg-green-500 text-white"
                            : "text-[#858585]"
                        } ${
                          tab.is_enabled
                            ? "cursor-pointer"
                            : "cursor-not-allowed text-[#c7c5c5] opacity-55"
                        } p-1 px-6 rounded-full flex  items-center  flex-shrink-0`}
                      >
                        <span className={`mr-2`}>
                          {tab.icon(activeTab === tab.id)}
                        </span>
                        {tab.label}
                      </button>
                    ))}
                  </nav>
                </div>

                <div className="mt-4 w-full h-[500px]">{renderTab()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
