import InputField from "./InputField";
import { ProgressBar } from "./ProgressBar";
import { ReactComponent as DownloadIcon } from "../../../app/assets/DownloadIcon.svg";
export const ReportFilters = () => (
    <div className="flex items-center justify-between mb-6">
      {/* Report Year Input */}
      <InputField label="Report year" />
      {/* Base Year Input */}
      <InputField label="Base year" />
  
      {/* Progress and Download Button */}
      <div className="flex items-center space-x-4">
        <ProgressBar/>
        
        <button className="bg-green-500 text-white px-4 py-2 rounded-md flex justify-center items-center gap-1">
           Index<span><DownloadIcon className="text-white fill-white"/></span>
        </button>
        <button className="bg-green-500 text-white px-4 py-2 rounded-md flex justify-center items-center gap-1">
        PDF<span>  <DownloadIcon className="text-white fill-white"/></span>
        </button>
      </div>
    </div>
  );