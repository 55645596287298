

import React, { useState } from 'react';


import ContractualEmployeeForm from './ContractualEmployeeForm';
import EmployeeDataDisplay from '../../../pages/BRSR/BRSRContent/DataView/HiringAndTurnover';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../customHooks/useApi';
import { postSocialContratualEmpDetailsInputs } from '../../../features/social/socialThunk';

const ContractualEmployee = ({closePopup}) => {
  const [selectedTab, setSelectedTab] = useState('form');
  const [formData,setFormData]=useState({})
const dispatch=useDispatch()
const api=useApi()
  const {employementType,siteData,financialYearData}=useSelector(state=>state.social)
  function handleSubmitData(data) {
    setFormData(data) 
   dispatch(postSocialContratualEmpDetailsInputs({api,formData:data}))
   closePopup()
  }

  const renderTab = () => {
    switch (selectedTab) {
      case "form":
        return <ContractualEmployeeForm onSubmit={handleSubmitData}  siteOptions={siteData} yearOptions={financialYearData}/>;
      case "display":
        return <EmployeeDataDisplay data={formData} />;
      default:
        return null;
    }
  };
  return (
    <div className='h-full w-full overflow-auto p-2 px-6 py-3'>
        {renderTab()}
    </div>
  );
};

export default ContractualEmployee;

