export const disclosures = [
    {
      id: "Principle-1",
      guidance:"In case the entity desires to disclose any benefits other than those specified in this field, additional columns may be added for such disclosures.",

      title:
        "Ensure Accountability and Ethical Practices",
      disclosure_type: "Relevant",
      status: "Completed",
      details:
      "PRINCIPLE 1 Businesses should conduct and govern themselves with integrity, and in a manner that is Ethical, Transparent and Accountable.",
      subDisclosure_essential: [
       
        ],
        subDisclosure_leadership:[
       
      ],
    },
    {
      id: "Principle-2",
      guidance:"In case the entity desires to disclose any benefits other than those specified in this field, additional columns may be added for such disclosures.",

      title:
        "Deliver Goods and Services Sustainably and Safely",
      disclosure_type: "Relevant",
      status: "Completed",
      details:
      "PRINCIPLE 2 Businesses should provide goods and services in a manner that is sustainable and safe",
      subDisclosure_essential: [
       
        ],
        subDisclosure_leadership:[
       
      ],
    },
   
    {
      id: "Principle-3",
      guidance:"In case the entity desires to disclose any benefits other than those specified in this field, additional columns may be added for such disclosures.",

      title:
        "Respect and Promote Employee Well Being",
      disclosure_type: "Relevant",
      status: "Completed",
      details:
      "Report the total number of employees, and a breakdown by gender and region",
      subDisclosure_essential: [
          {
            guidance:"In case the entity desires to disclose any benefits other than those specified in this field, additional columns may be added for such disclosures.",
            id: "P3 E1",
            title: "Employee Well-being Disclosures",
            disclosure_type: "Relevant",
            status: "Pending",
            details: "Details for full-time employees by gender and region.",
            table:1,
        },
        {
        guidance:"The entity shall disclose relevant data of % of employees & workers covered under various schemes such as PF, Gratuity, ESI, & any other employee Fund for current & previous FYs as per the BRSR format",
          id: "P3 E2",
          title:
            "Retirement benefits disclosure",
          disclosure_type: "Relevant",
          status: "Pending",
          details:
            "Breakdown of employment types (full-time, part-time, etc.) by gender and region. Breakdown of employment types (full-time, part-time, etc.) by gender and region.Breakdown of employment types (full-time, part-time, etc.) by gender and region.Breakdown of employment types (full-time, part-time, etc.) by gender and region.Breakdown of employment types (full-time, part-time, etc.) by gender and region.Breakdown of employment types (full-time, part-time, etc.) by gender and region.Breakdown of employment types (full-time, part-time, etc.) by gender and region.",
            table:2
        },
        {
        guidance:"The entity shall disclose whether the organization's premises/office spaces are accessible to differently-abled employees & workers, as per the requirements of the Rights of Persons with Disabilities Act, 2016 or not. The entity is required to disclose the actions being taken by it ensure such compliance.",
          id: "P3 E3",
          title:
            "Accessibility Compliance for Differently-Abled Employees",
          disclosure_type: "Relevant",
          status: "Pending",
          details:
            "Are the premises/offices of the entity accessible to differently-abled employees & workers, as per the requirements of the Rights of Persons with Disabilities Act, 2016? If not, whether any steps are being taken by the entity in this regard.",
            table:3
        },
        {
        guidance:"The entity shall disclose whether it has an equal opportunity policy & also share the weblink of the policy.",
          id: "P3 E4",
          title:
            "Equal Opportunity Policy Compliance",
          disclosure_type: "Relevant",
          status: "Pending",
          details:
            "Does the entity have an equal opportunity policy as per the Rights of Persons with Disabilities Act, 2016? If so, provide a web link to the policy.",
            table:3
        },
        {
        guidance:"The entity shall disclose no. of employees returned post parental leaves & rate of retention on gender basis as well as in totality. Retention rate determines who returned to work after parental leave ended & were still employed 12 months later.",
          id: "P3 E5",
          title:
            "Return to Work & Retention Rates Post Parental Leave",
          disclosure_type: "Relevant",
          status: "Pending",
          details:
            "Return to work & Retention rates of permanent employees & workers that took parental leave based on gender-male & female & in totality",
            table:4
        },
        {
        guidance:"The entity shall disclose whether there is a grievance redressal mechanism in place for permanent as well as contractual employees & workers. Also, the entity shall share the details of the Grievance Redressal Mechanism for permanent & temporary employees & workers in brief.",
          id: "P3 E6",
          title:
            "Grievance Redressal Mechanism Disclosure",
          disclosure_type: "Relevant",
          status: "Pending",
          details:
            "Is there a mechanism available to receive & redress grievances for the following permanent & temporary categories of employees & workers? If yes, give details of the mechanism in brief for all the above-mentioned categories.",
            table:5
        },
        {
        guidance:"The entity shall disclose number & percentage of employees as well as workers on basis of gender & in totality as members of associations/unions.",
          id: "P3 E7",
          title:
            " Employee and Worker Association/Union Membership",
          disclosure_type: "Relevant",
          status: "Pending",
          details:
"Disclose No. & percentage of Membership of total permanent male & female both categories employees & workers in association(s) or Unions recognized by the listed entity for both current & previous Financial Years." ,
           table:6    },
        {
        guidance:"Number & percentage of employees & workers who attended training in totality & on a gender basis. The entity can further disclose such data based on types of training: • General training on health & safety • Training on specific work-related hazards hazardous activities, or hazardous situations • Training on mental health • Internal training courses for skill upgradation • Funding support for external training or education",
          id: "P3 E8",
          title:
            "Spending on Health, Safety, and Skill Upgradation for Employees and Workers",
          disclosure_type: "Relevant",
          status: "Pending",
          details:
"Disclose No. & percentage of Membership of total permanent male & female both categories employees & workers in association(s) or Unions recognized by the listed entity for both current & previous Financial Years." ,
           table:7    },
        {
        guidance:"The entity shall disclose no. & % of employees & workers whose performance & career development reviews have been conducted by the entity for current & previous FYs.",
          id: "P3 E9",
          title:
            "Details of performance & career development reviews of employees & worker",
          disclosure_type: "Relevant",
          status: "Pending",
          details:
"Details of performance & career development reviews of employees & workers on a gender-male & female & in totality for both current & previous financial years." ,
           table:8    },
        {
        guidance:"• The entity shall disclose whether it has a health & safety management system in place with its coverage. • Also disclose details/process/steps & frequency of conducting safety/hazard/risk identification. • Disclose the processes in place for employees & workers to report work-related hazards & to protect/remove themselves. • Also, disclose whether it has provided non-occupational medical & healthcare services to its employees/workers.",
          id: "P3 E10",
          title:
            "Health and safety management system",
          disclosure_type: "Relevant",
          status: "Pending",
          details:
"Health and safety management system" ,
           table:9   },
        {
        guidance:"The entity shall disclose numerical data of LTIFR in per 1 million-person hour worked; fatalities etc. Recordable work-related injury or ill-health results in any of the following: death, days away from work, restricted work or transfer to another job, medical treatment beyond first aid, or loss of consciousness. The entity shall report the report the number of fatalities of employees / workers during the reporting period & the prior year, as a result of work-related injury. Details on high consequence work-related injury or ill-health (excluding fatalities)— rendering person unfit for job done previously",
          id: "P3 E11",
          title:
            "Details of safety-related incidents for both employees & workers",
          disclosure_type: "Relevant",
          status: "Pending",
          details:
"Details of safety-related incidents for both employees & workers in current & previous FYs for:" ,
           table:10   },
        {
        guidance:"Under this disclosure, the entity shall report the measures taken to prevent or mitigate significant negative health & safety impacts that are directly linked to its operations,products or services. Also indicate whether these measures are taken for a specific set of activities employees,  workers or facilities of the entity",
          id: "P3 E12",
          title:
            "Workplace Safety & Health Initiatives",
          disclosure_type: "Relevant",
          status: "Pending",
          details:
"Describe the measures taken by the entity to ensure a safe & healthy workplace." ,
           table:11   },
        {
        guidance:"The entity shall disclose number of complaints relating to working conditions as well as on health & safety as under for the current as well as previous financial years: • Filed during the year • Pending resolution at the year end • Remarks",
          id: "P3 E13",
          title:
            "Health & Safety Complaints: FY Comparison",
          disclosure_type: "Relevant",
          status: "Pending",
          details:
"Number of Complaints filed & pending along with remarks on working conditions & health & safety made by employees & workers for current & previous FYs." ,
           table:12   },
        {
        guidance:"The entity shall disclose the percentage of plants/offices assessed with respect to health & safety practices as well as working conditions, separately, along with the details of the Assessing party.",
          id: "P3 E14",
          title:
            "Current FY Health & Safety Assessment Rate for Facilities",
          disclosure_type: "Relevant",
          status: "Pending",
          details:
"Disclose % of your plants & offices that were assessed (by the entity/ statutory authorities/ third parties) in the current FY for health & safety practices & working conditions" ,
           table:13  },
        {
        guidance:"Shall share a brief of assessment results as per question No. 14 along with details of any/all corrective actions underway/taken.",
          id: "P3 E15",
          title:
            "Health & Safety Corrections and Risk Mitigations",
          disclosure_type: "Relevant",
          status: "Pending",
          details:
"Provide details of any corrective action taken or underway to address safety-related incidents (if any) & on significant risks/concerns arising from assessments of health & safety practices & working conditions." ,
           table:14  },
        ],
        subDisclosure_leadership:[
        {
        guidance:"The entity can disclose in Yes/No format whether it has such policy",
          id: "P3 L1",
          title:"Compensatory Support in Event of Employee or Worker Death",
          disclosure_type: "Relevant",
          status: "Pending",
          details:
"Does the entity extend any life insurance or any compensatory package in the event of death of (A) Employees (Y/N) (B) Workers (Y/N)." ,
           table:15 },
        {
        guidance:"The Entity may disclose if it has a procurement policy in place w.r.t. value chain partners which inter alia provides for a checklist comprising abovementioned condition.",
          id: "P3 L2",
          title:
            "Safeguarding Statutory Compliance in the Value Chain",
          disclosure_type: "Relevant",
          status: "Pending",
          details:
"Provide the measures undertaken by the entity to ensure that statutory dues have been deducted & deposited by the value chain partners." ,
           table:3 },
        {
        guidance:"The entity shall disclose the following data for employees & workers separately, for previous & current financial year: • Total no. of affected employees/ workers • No. of employees/workers that are rehabilitated & placed in suitable employment or whose family members have been placed in suitable employment Of the employees / workers having suffered high consequence injuries / ill-health, the entity shall disclose the number of employees/ workers rehabilitated or placed in suitable employment during the reporting period. This disclosure can also include family members of the affected employees / workers, who have been placed in suitable employment.",
          id: "P3 L3",
          title:
            "Employee and Worker Placement Post-Injury/Ill-Health",
          disclosure_type: "Relevant",
          status: "Pending",
          details:
"Provide the number of employees/workers having suffered high consequence work-related injury/ill-health/fatalities (as reported in Q11 of Essential Indicators above), who have been are rehabilitated & placed in suitable employment or whose family members have been placed in suitable employment for both employee & workers categories for current & previous FYs." ,
           table:3 },
        {
        guidance:"The entity can disclose in Yes/No format whether it has such programs.",
          id: "P3 L4",
          title:
            "Transition Assistance Programs for Continued Employability",
          disclosure_type: "Relevant",
          status: "Pending",
          details:
"Does the entity provide transition assistance programs to facilitate continued employability & the management of career endings resulting from retirement or termination of employment? (Yes/ No)." ,
           table:3 },
        {
        guidance:"The entity shall disclose the percentage of value chain partners assessed with respect to health & safety practices as well as working conditions",
          id: "P3 L5",
          title:
            "Value Chain Health & Safety Assessment Coverage",
          disclosure_type: "Relevant",
          status: "Pending",
          details:
"Provide details of any corrective actions taken or underway to address significant risks/concerns arising from assessments of health & safety practices & working conditions of value chain partners." ,
           table:3 },
        {
        guidance:"The entity can disclose details of action/measures taken or underway by/for value chain partners to address risk/concerns during assessments.",
          id: "P3 L6",
          title:
            "Corrective Actions for Value Chain Safety",
          disclosure_type: "Relevant",
          status: "Pending",
          details:
"Provide details of any corrective actions taken or underway to address significant risks/concerns arising from assessments of health & safety practices & working conditions of value chain partners." ,
           table:3 },
      ],
    },
  ];