
import React, { useState } from 'react';
import GenericForm from './GenericForm';
import DataDisplay from '../../../pages/BRSR/BRSRContent/DataView/DataDisplay';
import useApi from '../../../customHooks/useApi';
import { useDispatch, useSelector } from 'react-redux';
import { postSocialSafetyRelatedIncidentsInputs } from '../../../features/social/socialThunk';

const WorkplaceSafetyMetricsForm = ({closePopup}) => {
  const {employementType,financialYearData}=useSelector(state=>state.social)
  const api=useApi()
  const dispatch=useDispatch()
  const [selectedTab, setSelectedTab] = useState('form');
  const [formData,setFormData]=useState({})
  const config = {
   
    financialYearOptions:financialYearData, // Example financial year options
    sections: [
      {
        key: 'workplaceMetrics',
        label: 'Workplace Metrics',
        rows: [
          {
            key: 'fatalities',
            label: 'Number of Fatalities',
          },
          {
            key: 'workRelatedInjuries',
            label: 'Total Recordable Work-related Injuries',
          },
          {
            key: 'highConsequenceInjuries',
            label: 'High Consequence Work-related Injury or Ill-health (Excluding Fatalities)',
          },
          {
            key: 'ltifr',
            label: 'Lost Time Injury Frequency Rate (LTIFR) (per one million-person hours worked)',
          },
        ],
        fields: [
          // Fields for Number of Fatalities
          {
            key: 'male',
            label: 'Male',
            type: 'number', // Numeric input for counts
         
          },
          {
            key: 'female',
            label: 'Female',
            type: 'number',
         
          },
          {
            key: 'other',
            label: 'Other',
            type: 'number',
           
          },
          {
            key: 'not disclosed',
            label: 'Not Disclosed',
            type: 'number',
          
          },
  
         
         
        ],
      },
    ],
  };
  

  
  function handleSubmitData(data) {
 dispatch(postSocialSafetyRelatedIncidentsInputs({api,formData:data,employementType}) )
    setFormData(data) 
    closePopup()
   
  }
  const renderTab = () => {
    switch (selectedTab) {
      case "form":
        return <GenericForm config={config} handleSubmitData={handleSubmitData} />;
      case "display":
        return <DataDisplay config={config} data={formData}/>;
      default:
        return null;
    }
  };
  return (
    <div className='h-full w-full p-2 px-6 overflow-auto'>
        {renderTab()}
    </div>
  );
};

export default WorkplaceSafetyMetricsForm;
