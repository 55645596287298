/* eslint-disable react-hooks/exhaustive-deps */
import Header from "../../components/common/Header";
import UserIcon from "../../app/assets/UserIcon.svg";
import { ReactComponent as FuelIcon } from "../../app/assets/FuelIcon.svg";
import { ReactComponent as PurchasedIcon } from "../../app/assets/PurchasedIcon.svg";

import { ReactComponent as CopyIcon } from "../../app/assets/CopyIcon.svg";
import { ReactComponent as PencilIcon } from "../../app/assets/PencilIcon.svg";
import { ReactComponent as DeleteIcon } from "../../app/assets/DeleteIcon.svg";
import { ReactComponent as AddIconBig } from "../../app/assets/AddIconBig.svg";
import { ReactComponent as DownloadIcon } from "../../app/assets/DownloadIcon.svg";
import Table from "../../components/common/Table/Table";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  resetFuelForm,
  setCurrentPage,
  setFilters,
  setFuelForm,
  setFuelRecordType,
  setItemsPerPage,
} from "../../features/energy/fuel/fuelSlice";
import {
  deleteFuelData,
  fetchCurrencyData,
  fetchFuelData,
  fetchFuelTypeData,
  fetchSiteData,
  fetchUnitData,
  fetchUseTypeData,
} from "../../features/energy/fuel/fuelThunk";
import TableMeta from "../../components/common/Table/TableMeta";
import PurchaseFuelForm from "../../components/energy/fuel/PurchaseFuelForm";
import TablePagination from "../../components/common/Table/TablePagination";

import { toast } from "react-toastify";
import PurchaseFuelEditAndCopyForm from "../../components/energy/fuel/PurchaseFuelEditAndCopyForm";
import useApi from "../../customHooks/useApi";
import FilterComponent from "../../components/common/FilterComponent";
import { useSearchParams } from "react-router-dom";

const FuelPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const {read_only}=useSelector(state=>state.auth)
  const headingsToDataKeyMap = {
    " ": "",
    "BILL DATE": "bill_date",
    FACILITY: "site",
    "FUEL TYPE": "fuel_type",
    "SOURCE TYPE": "source_type",
    "USE TYPE": "use_type",
    "QUANTITY USED": "quantity",
    UNIT: "unit",
    "AMOUNT PAID": "amount_paid",
    CURRENCY: "currency",
    "HEAT CONTENT OF THE FUEL": "heat_content_of_fuel",
    "CARBON CONTENT OF THE FUEL": "carbon_content_of_fuel",
    "EMISSION FACTOR": "ef_of_fuel",
    EVIDENCE: "evidence",
    STATUS: "status",
    // FEEDBACK: "comments",
    // "SUBMITTED BY": "submitted_by",
    // "APPROVED BY": "approved_by",
    // EDIT: "edit",
    // DELETE: "",
  };
  if(!read_only){
    headingsToDataKeyMap.EDIT="edit"
    headingsToDataKeyMap.DELETE="delete"
  }
  const api = useApi();
  
  const {
    fuelRecords,
    fuelRecordType,
    itemsPerPage,
    totalPages,
    totalCount,
    currentPage,
    fuelForm,fuelTypeDataForFilters,sourceTypeData,useTypeData,siteDataForFilters
  } = useSelector((state) => state.fuel);


  const [files, setfiles] = useState(null);
  const [dataRows, setDataRows] = useState([]);
  const [isPurchaseFormOpen, setPurchaseFormOpen] = useState(false);
  const [manualEntry, setManualEntry] = useState(true);
  const [apiIntegration, setApiIntegration] = useState(false);
  const [trendLine, setTrendLine] = useState(false);
  const [update, setupdate] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isEditCopyFormOpen, setIsEditCopyFormOpen] = useState(false);


  const filters = [
    // {
    //   name: "bill_date",
    //   label: "Bill date",
    //   options:[],
    //   type:"date"
    // },
    {
      name: "site",
      label: "Facility",
      options:siteDataForFilters&&siteDataForFilters.map(site=>{
        return {
          value:site.id,
          label:site.site
        }
      }),
    },
    {
      name: "fuel_type",
      label: "Fuel type",
      options:fuelTypeDataForFilters&&fuelTypeDataForFilters.map(i=>{
        return {
          value:i.id,
          label:i.fuel_type
        }
      }),
    },
    {
      name: "source_type",
      label: "Source type",
      options: sourceTypeData&&sourceTypeData.map(source_type=>{
        return {
          value:source_type,
          label:source_type
        }
      }),
    },
    {
      name: "use_type",
      label: "Use type",
      options: useTypeData&&useTypeData.map(use_type=>{
        return {
          value:use_type.id,
          label:use_type.use_type
        }
      }),
    },
  ];
// console.log(useSelector((state) => state.fuel))
  // Function to handle editing a row
  const handleEdit = (row) => {
    dispatch(resetFuelForm());
    setActionType("edit");
    setSelectedRowData(row);
    setIsEditCopyFormOpen(true);
  };
  const handleDelete = (row) => {
    if (row.id) {
      dispatch(deleteFuelData({ api, id: row.id }));
    } else {
      toast.error("Delete failed please try again");
    }
  };

  const handleCopy = (row) => {
    dispatch(resetFuelForm());
    setActionType("copy");
    setSelectedRowData(row);
    setIsEditCopyFormOpen(true); // Open the copy form
  };

  // Function to close edit and copy forms
  const handleCloseEditCopyForms = () => {
    setIsEditCopyFormOpen(false);
  };

  const switchTab = (tabId) => {
    switch (tabId) {
      case 1:
        setManualEntry(true);
        setApiIntegration(false);
        setTrendLine(false);
        break;
      case 2:
        setManualEntry(false);
        setApiIntegration(true);
        setTrendLine(false);
        break;
      case 3:
        setManualEntry(false);
        setApiIntegration(false);
        setTrendLine(true);
        break;
      default:
        setManualEntry(true);
        setApiIntegration(false);
        setTrendLine(false);
        break;
    }
    setSelectedTab(tabId);
  };

  const openPopupForm = (fuelRecordType) => {
    if (fuelRecordType === 1) {

      setPurchaseFormOpen(true);
    } else {
    }
  };

  const closePurchasePopupForm = () => {
    setPurchaseFormOpen(false);
  };

  const [paginationConfig, setPaginationConfig] = useState({
    paginationEnabled: true,
    currentPage: 1,
    totalPage: 1,
    itemsPerPage: 10,
    handleItemsPerPage: null,
    handlePageChange: null,
  });

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const handleItemsPerPage = (itemCount) => {
    dispatch(setItemsPerPage(itemCount));
  };

  const handleFileDownload = (filePath) => {
    // dispatch(downloadFileThunk({ filePath }));
    if (filePath) {
      const link = document.createElement("a");
      link.href = filePath;

      link.download = "filename.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast("No evidance atteched");
    }
  };

  const generateCellHtml = (row, k) => {
    let cellHtml = null;
    switch (k) {
      case "fuel_type":
        cellHtml = (
          <span className="w-full flex justify-center">
            <span
              className={`px-2 py-1 border border-transparent rounded-md ${
                row["source_type"].toString().toLowerCase() ===
                "Renewable".toLowerCase()
                  ? "bg-green-200 text-green-600"
                  : "bg-red-200 text-red-600"
              }`}
            >
              {row[k]}
            </span>
          </span>
        );
        break;
      case "edit":
        cellHtml = (
          <span className="w-full flex justify-center">
            <PencilIcon
            
              className="cursor-pointer"
              onClick={() => handleEdit(row)}
            />
          </span>
        );
        break;
        case "delete":
        cellHtml = (
          <span className="w-full flex justify-center">
          <DeleteIcon
            className="cursor-pointer"
            onClick={() => handleDelete(row)}
          />
        </span>
        );
        break;
      case "status":
        cellHtml = (
          <span className="w-full flex justify-center">
            <span
              className={`px-2 py-1 border border-transparent rounded-md ${
                row[k] === "Accepted"
                  ? "bg-green-200 text-green-600"
                  : row[k] === "Submitted"
                  ? "bg-blue-200 text-blue-600"
                  : "bg-red-200 text-red-600"
              }`}
            >
              {row[k]}
            </span>
          </span>
        );
        break;
      case "evidence":
        cellHtml = (
          <span className="w-full flex  justify-center">
            <DownloadIcon
              className="cursor-pointer fill-green-500"
              onClick={() => handleFileDownload(row[k])}
            />
          </span>
        );
        break;
      case "submitted_by":
      case "approved_by":
        cellHtml = (
          <span className="w-full flex justify-center">
            <img
              src={`${UserIcon}`}
              className="cursor-pointer w-[32px] h-[32px] border border-transparent rounded-[50%]"
              alt={`${k}`}
            />
          </span>
        );
        break;
      default:
        cellHtml = <span className="w-full flex justify-center">{row[k]}</span>;
        break;
    }
    return cellHtml;
  };

  const handleFormChange = async (e) => {
    const { name, type, value, checked } = e.target;

    if (name === "fuel_type") {
      dispatch(fetchUnitData({ value, api }));

      dispatch(setFuelForm({ ...fuelForm, ["unit"]: "" }));
    }
    if (type === "file") {
      const files = e.target.files;
      if (!files) {
        toast("Please select a file");
        return;
      }
      setfiles(files);
    } else {
      dispatch(setFuelForm({ [name]: value }));
    }
  };

  const handleFuelTypeChange = (recordType) => {
    dispatch(setFuelRecordType(recordType));
  };

  useEffect(() => {
    let transformedDataRows = fuelRecords.map((row) => {
      let transformedDataRow = [];
      
      transformedDataRow.push(
        <span className="w-full flex justify-center">
        <CopyIcon
          className={`${read_only ? 'cursor-not-allowed opacity-15' : 'cursor-pointer'}`}
          onClick={() => !read_only && handleCopy(row)}
        />
      </span>
      
      );
      Object.values(headingsToDataKeyMap).forEach((k) => {
        if (k.toString().trim() !== "") {
          transformedDataRow.push(generateCellHtml(row, k));
        }
      });
      transformedDataRow.push(
        // <span className="w-full flex justify-center">
        //   <PencilIcon className="cursor-pointer" onClick={()=>handleEdit(row)} />
        // </span>,
        // <span className="w-full flex justify-center">
        //   <DeleteIcon
        //     className="cursor-pointer"
        //     onClick={() => handleDelete(row)}
        //   />
        // </span>
      );
      return transformedDataRow;
    });

    setDataRows(transformedDataRows);
    setPaginationConfig({
      ...paginationConfig,
      currentPage: currentPage,
      totalPages: totalPages,
      itemsPerPage: itemsPerPage,
      handleItemsPerPage: handleItemsPerPage,
      handlePageChange: handlePageChange,
    });
  }, [fuelRecords]);


  const handleFilterChange = (newFilters) => {
    const updatedParams = new URLSearchParams(newFilters).toString();
    dispatch(setFilters(updatedParams))
    setSearchParams(updatedParams);
    setupdate(!update)
    
  
  };
  useEffect(() => {
   
    async function initializeData() {
      await dispatch(fetchCurrencyData({ api }));
      await dispatch(fetchSiteData({ api }));
    
      await dispatch(fetchFuelTypeData({ api }));
      // await // dispatch(fetchUnitData());
      await dispatch(fetchUseTypeData({ api }));
    
    }

    initializeData();
  }, []);
useEffect(()=>{
 const filters = Object.fromEntries(searchParams.entries()); 
 dispatch(fetchFuelData({ api,params:filters }));
},[update])
  return (
    <>
      <Header PageIcon={FuelIcon} pageTitle={"Fuel"} />

      <div className="flex flex-col main-container w-full px-10 py-6">
        <div className="flex  justify-between items-center text-xs">
          <div className="flex">
            <button
              className={`flex border items-center rounded-md mr-4 cursor-pointer px-2  ${
                fuelRecordType === 1
                  ? "border-green-600 focus:bg-green-600 text-white white-svg bg-green-600"
                  : "text-green-600 bg-white border-gray-100 hover:bg-green-600 hover:text-white hover-white-svg"
              }`}
              onClick={() => handleFuelTypeChange(1)}
            >
              <PurchasedIcon />
              <span className="px-2">Purchased fuel</span>
            </button>
            {/* <button
              className={`flex border items-center rounded-md mr-4 cursor-pointer px-2 ${
                fuelRecordType === 2
                  ? "border-green-600 focus:bg-green-600 text-white white-svg bg-green-600"
                  : "text-green-600 bg-white border-gray-100 hover:bg-green-600 hover:text-white hover-white-svg"
              }`}
              onClick={() => handleFuelTypeChange(2)}
            >
              <InventoryIcon />
              <span className="px-2">Inventory fuel</span>
            </button> */}
          </div>
          <div className="flex mb-3">
            <FilterComponent
              filters={filters}
              onFilterChange={handleFilterChange}
            />
          </div>
        </div>
        <div className="flex flex-col border border-gray-300 rounded-md mt-[10px] relative">
          <TableMeta
            recordType={fuelRecordType}
            totalCount={totalCount}
            openPopUpForm={openPopupForm}
            switchTab={switchTab}
            selectedTab={selectedTab}
          />
          {manualEntry && (
            <>
              <Table
                // height="68.5vh"
                headings={Object.keys(headingsToDataKeyMap)}
                dataRows={dataRows}
                paginationObject={paginationConfig}
              />
              {/* <div className="flex w-full py-4 px-2">
                <div
                  className="flex gap-2 bg-green-600 font-bold font-helvetica text-black text-opacity-50 border border-green-200 hoer:border-green-600 py-2 px-4 cursor-pointer rounded-2xl bg-opacity-[12%] items-center justify-center"
                  onClick={() => openPopupForm(fuelRecordType)}
                >
                  Add
                  <AddIconBig />
                </div>
              </div> */}
             {!read_only&& <div className="flex  w-fit py-4 px-2 absolute bottom-8 right-6 cursor-pointer">
                {/* Add */}
                <AddIconBig onClick={() => openPopupForm(fuelRecordType)} />
              </div>}
              {paginationConfig &&
                paginationConfig?.paginationEnabled &&
                paginationConfig?.handleItemsPerPage &&
                typeof paginationConfig?.handleItemsPerPage === "function" &&
                paginationConfig?.handlePageChange &&
                typeof paginationConfig?.handlePageChange === "function" && (
                  <TablePagination paginationObject={paginationConfig} />
                )}
            </>
          )}
          {apiIntegration && (
            <>
              <h1>API Integration tab</h1>
            </>
          )}
          {trendLine && (
            <>
              <h1>TrendLine tab</h1>
            </>
          )}
        </div>
      </div>

      {isPurchaseFormOpen && (
        <PurchaseFuelForm
          files={files}
          handleFormChange={handleFormChange}
          closePurchasePopupForm={closePurchasePopupForm}
        />
      )}
      {isEditCopyFormOpen && (
        <PurchaseFuelEditAndCopyForm
          selectedRowData={selectedRowData}
          handleCloseEditCopyForms={handleCloseEditCopyForms}
          actionType={actionType}
        />
      )}
    </>
  );
};

export default FuelPage;
