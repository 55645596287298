import React, { useState } from "react";
import { ReactComponent as CalenderIcon } from "../../../../app/assets/CalenderIcon.svg";
import { ReactComponent as SitesIcon } from "../../../../app/assets/SitesIcon.svg";
const ContractTableWithTiles = ({ data }) => {
  
  // Extract unique years and sites
  const uniqueYears = [...new Set(data.map((item) => item.financial_year))];
  const uniqueSites = [...new Set(data.map((item) => item.site))];

  return (
    <div className="p-3 space-y-6">
      {uniqueYears.map((year) => (
        <div key={year}>
          {uniqueSites.map((site) => {
            const filteredData = data.filter(
              (item) => item.financial_year === year && item.site === site
            );

            // Only render the tile if there is data for the year and site
            if (filteredData.length === 0) return null;

            return (
              <div
                key={`${year}-${site}`}
                className="border border-gray-300 p-3  rounded"
              >
                <div className="flex justify-start gap-5 border-b border-gray-300 pb-4 mb-4 ">
                {/* <div className="flex justify-start gap-5 border-b border-gray-300 pb-4 "> */}
<div className="flex items-center gap-2 justify-center">
                {/* <SitesIcon className="w-6 h-6" /> */}
            <CalenderIcon className="w-5 h-5" />
                <label>Financial Year</label>
          <div className="flex items-center gap-2 justify-center border bg-white p-1 px-2 rounded">
            <div className="flex items-center gap-2">
              <select
                className="font-semibold  text-center"
                disabled
              >
                <option selected>{` ${year}`}</option>
              </select>
            </div>
            </div>
            </div>
            {site && (
              <div className="flex items-center gap-2 justify-center">
                <SitesIcon className="w-6 h-6" />
                <label>Facility</label>
                 <div className="flex items-center gap-2 justify-center border bg-white p-1 px-2 rounded w-24">
                <select
                  className="font-semibold  text-center w-full"
                  disabled
                >
                  <option selected>{` ${site}`}</option>
                </select>
              </div>
              </div>
             
            )}
          {/* </div> */}
                </div>
                {/* Table */}
                <div className="overflow-x-auto">
                  <table className="table-auto w-full border-collapse border border-gray-300">
                    <thead className="bg-gray-100">
                      <tr className="bg-gray-200">
                        <th className="border border-gray-300 px-4 py-2 text-left w-1/3">
                          Contract Type
                        </th>
                        <th className="border border-gray-300 px-4 py-2 text-left w-1/2 max-w-[300px]">
                          Contract Detail
                        </th>
                        <th className="border border-gray-300 px-4 py-2 text-left w-1/3">
                          Type of Work
                        </th>
                        <th className="border border-gray-300 px-4 py-2 text-left w-1/3">
                          Number of Employees
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.map((item) => (
                        <tr key={item.id} className="hover:bg-gray-50">
                          <td className="border border-gray-300 px-4 py-2">
                            {item.contract_type || "N/A"}
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            {item.contact_detail || "N/A"}
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            {item.type_of_work || "N/A"}
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            {item.no_of_emp || "0"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default ContractTableWithTiles;
